var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "body", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { align: "center" } },
        [
          _c("v-col", [
            _c("h1", { staticStyle: { "font-weight": "400" } }, [
              _vm._v("Logger"),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "v-container",
        { attrs: { "grid-list-md": "", "text-xs-center": "" } },
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", lg: "3", xl: "3" } },
                    [
                      _c(
                        "v-menu",
                        {
                          attrs: {
                            "close-on-content-click": false,
                            "nudge-right": 40,
                            transition: "scale-transition",
                            "offset-y": "",
                            "min-width": "auto",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "v-text-field",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          attrs: {
                                            label: "Start Date",
                                            "prepend-icon": "mdi-calendar",
                                            readonly: "",
                                          },
                                          model: {
                                            value: _vm.dropDown.dateStart,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.dropDown,
                                                "dateStart",
                                                $$v
                                              )
                                            },
                                            expression: "dropDown.dateStart",
                                          },
                                        },
                                        "v-text-field",
                                        attrs,
                                        false
                                      ),
                                      on
                                    )
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _c("v-date-picker", {
                            on: { change: _vm.runCustomQuery },
                            model: {
                              value: _vm.dropDown.dateStart,
                              callback: function ($$v) {
                                _vm.$set(_vm.dropDown, "dateStart", $$v)
                              },
                              expression: "dropDown.dateStart",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", lg: "3", xl: "3" } },
                    [
                      _c(
                        "v-menu",
                        {
                          attrs: {
                            "close-on-content-click": false,
                            "nudge-right": 40,
                            transition: "scale-transition",
                            "offset-y": "",
                            "min-width": "auto",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "v-text-field",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          attrs: {
                                            label: "End Date",
                                            "prepend-icon": "mdi-calendar",
                                            readonly: "",
                                          },
                                          model: {
                                            value: _vm.dropDown.dateEnd,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.dropDown,
                                                "dateEnd",
                                                $$v
                                              )
                                            },
                                            expression: "dropDown.dateEnd",
                                          },
                                        },
                                        "v-text-field",
                                        attrs,
                                        false
                                      ),
                                      on
                                    )
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _c("v-date-picker", {
                            on: { change: _vm.runCustomQuery },
                            model: {
                              value: _vm.dropDown.dateEnd,
                              callback: function ($$v) {
                                _vm.$set(_vm.dropDown, "dateEnd", $$v)
                              },
                              expression: "dropDown.dateEnd",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", lg: "3", xl: "3" } },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.actorType,
                          "item-text": "actorType",
                          "item-value": "actorType",
                          label: "actor_type",
                          "persistent-hint": "",
                          "return-object": "",
                          outlined: "",
                        },
                        on: { change: _vm.runCustomQuery },
                        model: {
                          value: _vm.dropDown.actorType,
                          callback: function ($$v) {
                            _vm.$set(_vm.dropDown, "actorType", $$v)
                          },
                          expression: "dropDown.actorType",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", lg: "3", xl: "3" } },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.actorId,
                          "item-text": "actorId",
                          "item-value": "actorId",
                          label: "actor_id",
                          "persistent-hint": "",
                          "return-object": "",
                          outlined: "",
                        },
                        on: { change: _vm.runCustomQuery },
                        model: {
                          value: _vm.dropDown.actorId,
                          callback: function ($$v) {
                            _vm.$set(_vm.dropDown, "actorId", $$v)
                          },
                          expression: "dropDown.actorId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", lg: "3", xl: "3" } },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.severity,
                          "item-text": "severity",
                          "item-value": "severity",
                          label: "severity",
                          "persistent-hint": "",
                          "return-object": "",
                          outlined: "",
                        },
                        on: { change: _vm.runCustomQuery },
                        model: {
                          value: _vm.dropDown.severity,
                          callback: function ($$v) {
                            _vm.$set(_vm.dropDown, "severity", $$v)
                          },
                          expression: "dropDown.severity",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", lg: "3", xl: "3" } },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.appId,
                          "item-text": "appId",
                          "item-value": "appId",
                          label: "appId",
                          "persistent-hint": "",
                          "return-object": "",
                          outlined: "",
                        },
                        on: { change: _vm.runCustomQuery },
                        model: {
                          value: _vm.dropDown.appId,
                          callback: function ($$v) {
                            _vm.$set(_vm.dropDown, "appId", $$v)
                          },
                          expression: "dropDown.appId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", lg: "3", xl: "3" } },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.method,
                          "item-text": "method",
                          "item-value": "method",
                          label: "method",
                          "persistent-hint": "",
                          "return-object": "",
                          outlined: "",
                        },
                        on: { change: _vm.runCustomQuery },
                        model: {
                          value: _vm.dropDown.method,
                          callback: function ($$v) {
                            _vm.$set(_vm.dropDown, "method", $$v)
                          },
                          expression: "dropDown.method",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", lg: "3", xl: "3" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          label: "Limit",
                          placeholder: "100",
                          outlined: "",
                          color: "primary",
                        },
                        on: { change: _vm.runCustomQuery },
                        model: {
                          value: _vm.dropDown.limit,
                          callback: function ($$v) {
                            _vm.$set(_vm.dropDown, "limit", $$v)
                          },
                          expression: "dropDown.limit",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", lg: "3", xl: "3" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "ma-1",
                          staticStyle: { color: "black" },
                          attrs: { color: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.reset()
                            },
                          },
                        },
                        [_vm._v(" Reset ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "my-0" },
        [
          _c(
            "v-col",
            [
              _c("v-data-table", {
                staticClass: "elevation-1",
                attrs: {
                  headers: _vm.loggerLabels,
                  items: _vm.loggerData,
                  "items-per-page": 10,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-navigation-drawer",
        {
          attrs: { fixed: "", right: "", temporary: "", width: "360" },
          model: {
            value: _vm.drawer,
            callback: function ($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer",
          },
        },
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                { attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "mb-2", attrs: { align: "right" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function ($event) {
                              _vm.drawer = false
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { light: "" } }, [
                            _vm._v("mdi-close-circle "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("p", { staticStyle: { "text-align": "center" } }, [
                _vm._v("No content"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }