<template>
  <v-data-table
    :no-data-text="noDataMsg"
    :headers="labels"
    :items="trucks"
    class="dark-background"
  >
    <template v-slot:[`item.status`]="{ item }">
      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <td style="color: #888888" :class="item.status">
            <v-icon id="icon" color="#888888" large v-on="on">
              mdi-truck</v-icon
            >
          </td>
        </template>
        <span>Offline</span>
      </v-tooltip>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <td
        style="color: #43b02a; cursor: pointer"
        @click="() => viewTruck(item)"
      >
        VIEW
      </td>
    </template>
  </v-data-table>
</template>

<script>
import { getIcon } from '@/utilities/ExternalHealth.js';
export default {
  name: 'NotInUseTableTemplate',
  props: {
    trucks: {
      type: Array,
      default() {
        return [];
      },
    },
    actions: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      labels: [
        { text: 'Truck Name', value: 'truckNumber' },
        { text: 'Status', value: 'status' },
        { text: 'Current Location', value: 'currentLocation' },
        { text: 'Last Communication', value: 'lastCommunication' },
      ],
      noDataMsg: 'There are no current trucks for this category',
      getIcon,
    };
  },
  mounted() {
    if (this.actions) {
      this.labels.push({ text: 'Actions', value: 'actions' });
    }
  },
  methods: {
    viewTruck(truck) {
      this.$emit('viewClicked', truck);
    },
  },
};
</script>
<style scoped></style>
