<template>
  <div v-if="authenticated" id="app">
    <v-app id="inspire" dark>
      <template v-if="tacDialog">
        <div class="text-center">
          <v-dialog
            v-model="tacDialog"
            v-click-outside="() => (clickedOutside = true)"
            width="500"
            persistent
          >
            <v-card>
              <v-card-title class="text-h5 primary pa-3" style="color: black">
                Hyliion Hub Beta
              </v-card-title>

              <v-card-text class="pa-3">
                You are about to access a Beta version of the Hyliion Hub. Your
                company has signed a Hub Beta Agreement with Hyliion for access
                to this application during your Extended Fleet Trial. All the
                terms laid out in the Hub Beta Agreement apply while using this
                product.
                <span v-if="clickedOutside" class="color-red pt-1"
                  >You must accept the Terms and Conditions in order to
                  continue</span
                >
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  style="color: black"
                  @click="clickAcceptTAC()"
                >
                  I accept
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </template>
      <template v-else>
        <v-navigation-drawer
          class="shade"
          :mini-variant.sync="mini"
          clipped
          permanent
          app
        >
          <v-list-item-group active-class="bg-active">
            <div v-for="(item, i) in generateRoutes" :key="i">
              <v-list-item :to="item.to" router exact dark>
                <v-list-item-action>
                  <v-icon class="active">{{ item.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider v-if="checkDivisibleByFour(i)" :key="`divider-${i}`" />
            </div>
          </v-list-item-group>
        </v-navigation-drawer>
        <v-app-bar clipped-left fixed app>
          <v-app-bar-nav-icon @click.stop="closeOpenDrawer()" />
          <v-img
            class="mx-2"
            src="../public/images/logos/hub.svg"
            max-width="130px"
            max-height="32px"
            contain
            @click.stop="closeOpenDrawer()"
          ></v-img>
          <v-app-bar-nav-icon @click.stop="closeOpenDrawer()">
            <v-icon>{{
              mini ? 'mdi-chevron-right' : 'mdi-chevron-left'
            }}</v-icon>
          </v-app-bar-nav-icon>
          <v-spacer />
          <v-tabs v-if="isTruckDetails" v-model="tab">
            <v-tab :to="`/health/${detailsID}/details`">Details</v-tab>
            <v-tab :to="`/health/${detailsID}/dtc`">DTC</v-tab>
            <v-tab :to="`/health/${detailsID}/service`">Service</v-tab>
            <v-tab :to="`/health/${detailsID}/map`">Map</v-tab>
          </v-tabs>
          <v-menu
            offset-y
            transition="slide-x-transition"
            bottom
            right
            style="z-index: 13"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                plain
                :class="!isHyliionUser ? 'non-hyliion' : ''"
                v-on="on"
              >
                <v-avatar size="36px" color="primary">
                  <span
                    class="pb-1"
                    style="color: black"
                    v-text="getUserInitials()"
                  ></span>
                </v-avatar>
                <span class="pl-1">{{ userName }}</span>
              </v-btn>
            </template>
            <v-list v-if="isHyliionUser" dense>
              <v-list-item-group>
                <v-list-item
                  v-for="(option, index) in settingsItems"
                  :key="index"
                  @click="selectSettingOption(option.title)"
                  ><v-list-item-icon class="mr-2">
                    <v-icon style="opacity: 0.6">{{ option.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      class="text-body-1 opacity: 0.6"
                      style="line-height: 1.5rem"
                    ></v-list-item-title
                    >{{ option.title }}
                  </v-list-item-content></v-list-item
                >
              </v-list-item-group>
            </v-list>
          </v-menu>
        </v-app-bar>
        <v-main class="secondary">
          <global-snack />
          <router-view v-if="userRoles"> </router-view>
          <v-btn
            fab
            dark
            class="fbtn"
            :class="mini ? 'mini-fbtn' : 'expanded-fbtn'"
            style="background: #2b8f14"
            @click="showFeedback = true"
          >
            <v-icon style="color: black">mdi-forum-outline</v-icon>
          </v-btn>
          <!-- Removing Text becuase of menu list growing so long, will re-visit in the future-->
          <!-- <p v-if="!mini" class="feedbackLabel">Give Us Feedback!</p> -->
          <FeedbackModal
            :show="showFeedback"
            :toggle="toggleFeedback"
          ></FeedbackModal>
          <LiveFleetView
            v-if="showLiveFleetView"
            @close="showLiveFleetView = false"
          />
        </v-main>
      </template>
    </v-app>
  </div>
</template>

<script>
import axios from 'axios';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { lastSeen, acceptTAC, getPref } from '@/api/external/userManagement';
import FeedbackModal from './utilities/FeedbackModal';
import GlobalSnack from './utilities/GlobalSnack';
import LiveFleetView from './utilities/LiveFleetView';
import { permissions } from '@/api/permissions';

export default {
  name: 'App',
  components: { FeedbackModal, GlobalSnack, LiveFleetView },
  data: () => ({
    mini: true,
    showFeedback: false,
    showLiveFleetView: false,
    items: [
      {
        icon: 'mdi-view-dashboard',
        title: 'Dashboard',
        value: 'route.dashboard',
        to: '/dashboard',
      },
      {
        icon: 'mdi-map',
        title: 'Map',
        value: 'route.map',
        to: '/map',
      },
      {
        icon: 'mdi-pulse',
        title: 'Health',
        value: 'route.health',
        to: '/health',
      },
      {
        icon: 'mdi-alarm-light',
        title: 'DTC Dashboard',
        value: 'route.dtc_dashboard',
        to: '/dtc-dashboard',
      },
      {
        icon: 'mdi-road-variant',
        title: 'Trip Reports',
        value: 'route.trip-reports',
        to: '/trips',
      },
      {
        icon: 'mdi-map-marker-circle',
        title: 'GeoZones',
        value: 'route.geozones',
        to: '/geozone',
      },
      {
        icon: 'mdi-bell',
        title: 'Alerts',
        value: 'route.alerts',
        to: '/alerts',
      },
      {
        icon: 'mdi-truck-flatbed',
        title: 'Configurations',
        value: 'route.configuration',
        to: '/configuration-management',
      },
      {
        icon: 'mdi-account-group',
        title: 'User Management',
        value: 'route.user-management',
        to: '/user-management',
      },
      {
        icon: 'mdi-chart-line',
        title: 'Study Board',
        value: 'route.study',
        to: '/studyBoard',
      },
      {
        icon: 'mdi-chart-donut',
        title: 'Data Dictionary',
        value: 'route.data-dictionary',
        to: '/DataDictionary',
      },
      {
        icon: 'mdi-cloud',
        title: 'Cloud Health',
        value: 'route.cloud-health',
        to: '/cloud',
      },
      {
        icon: 'mdi-flag-checkered',
        title: 'ERX Miles',
        value: 'route.erxmiles',
        to: '/erxmiles',
        // customer success level 1 & superuser
      },
      {
        icon: 'mdi-cog',
        title: 'Manufacturing',
        value: 'route.manufacturing',
        to: '/manufacturing',
      },
      // {
      //   icon: 'mdi-view-dashboard',
      //   title: 'Dashboard',
      //   value: 'route.customer_dashboard',
      //   to: '/customer/dashboard',
      // },
      // Temp: Making required role 'admin' so users dont have access to these tabs and will not display by default
      {
        icon: 'mdi-gauge-low',
        title: 'Performance',
        value: 'external_permissions.performance',
        to: '/customer/performance',
      },
      {
        icon: 'mdi-pulse',
        title: 'Health',
        value: 'external_permissions.health',
        to: '/customer/health',
      },
      {
        icon: 'mdi-truck',
        title: 'Trucks',
        value: 'route.customer_trucks',
        to: '/customer/trucks',
      },
      {
        icon: 'mdi-home-city',
        title: 'Groups',
        value: 'route.customer_trucks',
        to: '/customer/groups',
      },
      {
        icon: 'mdi-cog',
        title: 'Settings',
        value: '*',
        to: '/customer/settings',
      },
    ],
    settingsItems: [
      { title: 'Profile', icon: 'mdi-account' },
      // TODO: Remove all instances of Settings component since it has been moved to the settings tab
      // { title: 'Settings', icon: 'mdi-cog' },
      // { title: 'Sign out', icon: 'mdi-logout' },
    ],
    oid: '',
    right: true,
    tacDialog: false,
    clickedOutside: false,
    authenticated: false,
    tab: 0,
    isHyliionUser: false,
    selectedCompanyPermissions: {},
  }),
  computed: {
    ...mapGetters({
      userRoles: 'getUserRoles',
      detailsID: 'getDetailsID',
      userName: 'getUserName',
      companies: 'getCompanies',
      selectedCompany: 'getSelectedLiveFleet',
    }),
    currentRouteName() {
      return this.$route.name;
    },
    generateRoutes() {
      return this.items.filter(
        (i) => this.userRoles.includes(i.value) || i.value === '*'
      );
    },
    isTruckDetails() {
      let res = false;
      const names = ['TruckDetails', 'TruckDTC', 'TruckService', 'TruckMap'];
      names.forEach((name) => this.currentRouteName === name && (res = true));
      return res;
    },
    hasLiveViewAccess() {
      return (
        this.userRoles && this.userRoles.includes(permissions.liveFleetView)
      );
    },
  },
  async created() {
    await this.authenticate();
    if (!this.companies.length) {
      await this.asyncRetrieveCompanies(); //Runs initial retrieval company call
    }
    // Display User Menu if Hyliion User
    let hyliion = this.companies.find((c) => c.id === 4);
    if (this.companies.length > 1 && hyliion) {
      this.isHyliionUser = true;
      if (this.hasLiveViewAccess) {
        this.settingsItems.splice(1, 0, {
          title: 'Live Fleet View',
          icon: 'mdi-eye-check-outline',
        });
      }
      // If user is in live view
      if (this.selectedCompany) {
        this.selectedCompanyPermissions =
          this.selectedCompany.external_permissions;
      } else {
        this.selectedCompanyPermissions = hyliion.external_permissions;
      }
    } else {
      this.selectedCompanyPermissions = this.companies[0].external_permissions;
    }
    this.checkCompanyPermissions(); // Adds roles based on permissions from company
    let { data } = await getPref('user_preferences');
    if (data) {
      delete data.preferences.isHyllionAPI;
      this.updateUserPreferences({
        ...data.preferences,
      });
    }
    // Set Intervals to retrieve company data every 5 minutes, subject to change
    window.setInterval(() => {
      this.asyncRetrieveCompanies(true); // Sends payload to require company api call to be hit
    }, 300000);
  },
  methods: {
    ...mapMutations([
      'updateUserRoles',
      'authenticateUser',
      'updateUserPreferences',
    ]),
    ...mapActions(['asyncRetrieveCompanies']),
    closeOpenDrawer: function () {
      this.mini = !this.mini;
    },
    authenticate: async function () {
      let vm = this;
      try {
        let auth = await this.$msal.authenticate();
        let accounts = auth.accounts;
        this.oid = accounts[0].localAccountId;
        this.authenticateUser({
          user: accounts[0].name,
          email: accounts[0].username,
          oid: this.oid,
        });
        this.authenticated = true;
        axios.interceptors.request.use(
          async (request) => {
            let reqURL = new URL(request.url);
            let isHyliionRequest =
              reqURL.hostname === 'hyliionapi.hyliion.com' ||
              reqURL.hostname === 'hyliionapi-dev.hyliion.com' ||
              reqURL.hostname === 'localhost' ||
              reqURL.hostname === 'adxdataexport.azurewebsites.net' || // ADX Export Azure Function
              reqURL.hostname === '127.0.0.1';
            if (isHyliionRequest) {
              let msalInstance = vm.$msal;
              let account = msalInstance.getAllAccounts()[0];
              const tokenResponse = await msalInstance.getSilentToken(
                account,
                msalInstance.config.auth.scopes
              );
              request.headers.authorization = `Bearer ${tokenResponse.accessToken}`;
            }
            return request;
          },
          (error) => {
            console.log(error);
            return Promise.reject(error);
          }
        );
        const { data } = await lastSeen();
        if (!data) {
          this.tacDialog = true;
        }
      } catch (e) {
        // eslint-disable-next-line
        console.log('Error authenticating user', e);
      }
    },
    checkCompanyPermissions() {
      // Manually add roles based on permissions from company
      if (this.selectedCompanyPermissions) {
        let roles = this.selectedCompanyPermissions;
        if (roles.health) {
          if (!this.userRoles.includes('external_permissions.health')) {
            this.updateUserRoles('external_permissions.health');
          }
        }
        if (roles.performance) {
          if (!this.userRoles.includes('external_permissions.performance')) {
            this.updateUserRoles('external_permissions.performance');
          }
        }
      }
    },
    clickAcceptTAC() {
      this.tacDialog = false;
      acceptTAC();
    },
    getUserInitials() {
      const name = this.userName.split(' ');
      return `${name[0].charAt(0)}${name[1] ? name[1].charAt(0) : ''}`;
    },
    selectSettingOption(title) {
      if (title === 'Profile') {
        this.$router.push(`user-management/${this.oid}`);
      } else if (title === 'Settings') {
        this.$router.push(`/settings`);
      } else if (title === 'Live Fleet View') {
        this.showLiveFleetView = true;
      }
    },
    toggleFeedback() {
      this.showFeedback = !this.showFeedback;
    },
    checkDivisibleByFour(i) {
      // Currently spaces every 4 items
      return Number.isInteger((i + 1) / 4);
    },
  },
  unmounted() {
    clearInterval();
  },
};
</script>
<style>
#inspire {
  background-color: #1a1a1a;
}
</style>
<style scoped>
/* .feedbackLabel {
  position: fixed;
  left: 270px;
  bottom: 30px;
  color: white;
  z-index: 5;
  display: none;
} */
.fbtn {
  position: fixed;
  left: 30px;
  bottom: 30px;
  z-index: 15;
}
.non-hyliion {
  pointer-events: none;
}
/* @media only screen and (min-height: 2000px) {
  .feedbackLabel {
    left: 100px;
    display: inline;
  }
} */
.mini-fbtn {
  left: 40px;
}
.expanded-fbtn {
  left: 220px;
}
</style>
