<template>
  <div>
    <v-row class="mr-3">
      <v-col>
        <h1 style="font-weight: 400">DTC History</h1>
      </v-col>
      <v-col align="right">
        <v-btn
          v-if="dtcs.length"
          color="#D8D8D8"
          class="mr-1"
          style="color: black; height: 48px"
          @click="exportCSV"
        >
          <download-csv id="csv" :name="dtcFileName" :data="dtcs">
            <v-icon id="csv2">mdi-download</v-icon>
            Download CSV
          </download-csv>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3" xs="12" sm="12" md="12" lg="3" xl="3">
        <v-text-field
          v-if="dtcs.length"
          v-model="searchText"
          placeholder="Search"
          solo
          prepend-inner-icon="mdi-magnify"
          background-color="shade"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-skeleton-loader
      v-if="loading"
      type="table"
      elevation="2"
    ></v-skeleton-loader>
    <v-data-table
      v-else
      :headers="headers"
      :items="dtcs"
      :items-per-page="15"
      :expanded.sync="expanded"
      item-key="id"
      :search="searchText"
      class="elevation-1"
      show-expand
    >
      <template v-slot:item.name="{ item }">
        <a
          v-if="item.workbook_link !== '---'"
          :href="item.workbook_link"
          target="_blank"
          >{{ item.name }}</a
        >
        <div v-if="item.workbook_link === '---'">{{ item.name }}</div>
      </template>
      <template v-slot:expanded-item="{ item }">
        <td
          :colspan="headers.length + 1"
          style="background-color: #272727; padding: 0"
        >
          <v-data-table
            :headers="expandedHeaders"
            :items="item.causes"
            :hide-default-footer="true"
            :items-per-page="-1"
            class="elevation-1 shade"
          />
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { compareDates } from '@/utilities/dateFunctions';
import dateFormat from 'dateformat';
import { getSpnfmiStates, getDtcErxHistoryTable } from '@/api/dtc';
import { erxDTCLookup } from '@/utilities/health';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      expanded: [],
      searchText: '',
      headers: [
        { text: '', value: 'data-table-expand' },
        {
          text: 'Activation Time',
          value: 'activation_time',
        },
        {
          text: 'Deactivation Time',
          value: 'deactivation_time',
        },
        { text: 'Name', value: 'name' },
        { text: 'Description', value: 'description' },
        { text: 'Source Address', value: 'source' },
        { text: 'Source Address Component', value: 'sa_component' },
        { text: 'SPN', value: 'spn' },
        { text: 'FMI', value: 'fmi' },
        { text: 'Occurence Count', value: 'occurrence_count' },
        {
          text: 'Component',
          value: 'component',
        },
      ],
      expandedHeaders: [
        { text: 'Possible Causes', value: 'name' },
        { text: 'Description', value: 'description' },
      ],
      dtcs: [],
      dtcsLength: 0,
      loading: true,
      dtcFileName: 'dtc_history.csv',
    };
  },
  computed: {
    ...mapGetters({
      spnfmiStates: 'getSpnfmiStates',
      getSelectedTruck: 'getTruck',
    }),
  },
  async created() {
    if (!this.spnfmiStates.length) {
      const { data } = await getSpnfmiStates();
      await this.asyncUpdateSpnfmiStates(data);
    }
  },
  async mounted() {
    const vm = this;
    const res = await getDtcErxHistoryTable(vm.$route.params.id);
    this.dtcs = res.data;
    this.dtcsLength = this.dtcs ? this.dtcs.length : 0;
    this.dtcs.forEach((t) => {
      t.activation_time = t.activation_time
        ? dateFormat(t.activation_time, 'mm/dd/yy HH:MM:ss Z', true)
        : '';
      t.deactivation_time = t.deactivation_time
        ? dateFormat(t.deactivation_time, 'mm/dd/yy HH:MM:ss Z', true)
        : '';
    });
    this.dtcs.sort((a, b) => {
      return compareDates(b.activation_time, a.activation_time);
    });
    this.dtcs = this.mapDtcDetails(this.dtcs);
    // Modify the dtcFileName to include the truck number (C1, C9, F2, etc.).
    const truck = this.getSelectedTruck;
    this.dtcFileName = `dtc_history_${truck.number}.csv`;
    this.loading = false;
  },
  methods: {
    exportCSV(event) {
      if (event.target.id != 'csv' && event.target.id != 'csv2') {
        document.getElementById('csv').click();
      }
    },
    mapDtcDetails(dtc) {
      let { dtcs, icons } = erxDTCLookup(
        dtc.length ? dtc : [],
        this.spnfmiStates
      );
      this.icons = icons;
      return dtcs;
    },
    customSort(items, index, isDesc) {
      items.sort((a, b) => {
        if (index[0] === 'activation_time') {
          if (!isDesc[0]) {
            return compareDates(a.activation_time, b.activation_time);
          } else {
            return compareDates(b.activation_time, a.activation_time);
          }
        } else if (index[0] === 'deactivation_time') {
          if (!isDesc[0]) {
            return compareDates(a.deactivation_time, b.deactivation_time);
          } else {
            return compareDates(b.deactivation_time, a.deactivation_time);
          }
        } else {
          if (!isDesc[0]) {
            return a[index] < b[index] ? -1 : 1;
          } else {
            return b[index] < a[index] ? -1 : 1;
          }
        }
      });
      return items;
    },
  },
};
</script>
