<template>
  <div>
    <div ref="scatterTT" class="scatterTT">
      <v-container>
        <v-card>
          <v-row>
            <v-card-title class="ttTitle"> Journey Quick View</v-card-title>
            <v-spacer></v-spacer>
            <span class="ttX" @click="closeTooltip"
              ><v-icon>mdi-close</v-icon></span
            >
          </v-row>
          <v-divider></v-divider>
          <v-row style="margin: 20px">
            <v-col>
              <span style="color: #888888">Journey</span><br />
              {{ journeyName }}
            </v-col>
            <v-col>
              <span style="color: #888888">MPGe</span><br />
              {{ mpge }}
            </v-col>
          </v-row>
          <v-row style="margin: 20px">
            <v-col>
              <span style="color: #888888">Truck</span><br />
              {{ truckName }}
            </v-col>
            <v-col>
              <span style="color: #888888">Cruise</span><br />
              {{ cruise }}%
            </v-col>
          </v-row>
          <v-row style="margin: 20px">
            <v-col>
              <span style="color: #888888">Idle</span><br />
              {{ idle }}%
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row style="margin-right: 20px; margin-top: 10px">
            <v-spacer />
            <v-col class="pt-0 mr-1" cols="3">
              <v-btn color="secondary" @click="closeTooltip">Close</v-btn>
            </v-col>
            <v-col class="pt-0 mr-1" cols="4">
              <v-btn color="primary" @click="viewMore">View Journey</v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-container>
    </div>
    <v-container ref="scatterRoot" fluid>
      <v-row justify="space-between">
        <v-btn-toggle
          v-if="selectedMetric"
          v-model="selectedMetric"
          color="primary"
          mandatory
          @change="changeMetric"
        >
          <v-btn
            :style="
              selectedMetric === 'mpge'
                ? 'background-color: #2B8F14; color: black'
                : ''
            "
            value="mpge"
            >MPGe</v-btn
          >
          <v-btn
            :style="
              selectedMetric === 'idle'
                ? 'background-color: #2B8F14; color: black'
                : ''
            "
            value="idle"
            >Idle</v-btn
          >
          <v-btn
            :style="
              selectedMetric === 'cruise'
                ? 'background-color: #2B8F14; color: black'
                : ''
            "
            value="cruise"
            >Cruise</v-btn
          >
        </v-btn-toggle>
        <!-- <v-btn-toggle v-model="range" color="primary" mandatory>
          <v-btn value="month">Month</v-btn>
          <v-btn value="week">Week</v-btn>
        </v-btn-toggle> -->
      </v-row>
      <v-row>
        <v-col cols="12" class="px-0">
          <template>
            <section class="charts">
              <highcharts
                v-if="!chartLoading"
                ref="scatter"
                :options="options"
              ></highcharts>
              <v-sheet v-else class="pa-3" rounded>
                <v-skeleton-loader
                  class="mx-auto"
                  type="list-item"
                ></v-skeleton-loader>
                <v-skeleton-loader
                  class="mx-auto"
                  type="image"
                ></v-skeleton-loader>
              </v-sheet>
            </section>
          </template>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import { getTimeZoneOffset } from '@/utilities/dateFunctions.js';

export default {
  name: 'ScatterPlot',
  props: ['setJourneys', 'sendExtremes', 'setTrucks', 'company'],
  data() {
    return {
      extremes: null,
      selectedMetric: null,
      mpge: null,
      idle: null,
      cruise: null,
      truckName: '-',
      totalMassAverage: null,
      journeyName: '-',
      currentDatum: null,
      truck: null,
      // range: 'week',
      chartLoading: true,
      options: null,
      ttOpen: false,
      bounds: null,
      startDate: null,
      journeys: null,
      series: [],
      // match css value; used for calculations & clickoutside logic
      tooltipWidth: 481,
      shapes: ['circle', 'square', 'diamond', 'triangle', 'triangle-down'],
      shapesIndex: 0,
      markers: [],
      initLoad: true,
      truckSet: new Set(),
      journeyType: 'automated',
    };
  },
  computed: {
    ...mapGetters({
      metric: 'getMetric',
      trucks: 'getAllTrucks',
      journeyData: 'getJourneyData',
      geozones: 'getGeozones',
      companyPreferences: 'getCompanyPreferences',
      userPreferences: 'getUserPreferences',
    }),
  },
  watch: {
    // range() {
    //   this.setChartZoom();
    // },
  },
  async mounted() {
    this.selectedMetric = this.metric;
    await this.populateChart();
    this.setupClickOutside();
  },

  methods: {
    ...mapMutations(['updateCurrentScreen', 'updateMetric']),
    changeMetric(value) {
      // this.range = 'week';
      if (this.journeys) this.configureData();
      this.updateMetric(value);
      this.updateCurrentScreen({ scatterMetric: this.metric });
    },
    // show tooltip (tt) in location of click event
    navigate(e, truck) {
      let data = e.point?.data;
      this.ttOpen = true;
      this.truck = truck;
      this.currentDatum = e;
      this.mpge = data.statistics.milesPerDGE;
      this.idle = data.statistics.percentIdle;
      this.cruise = data.statistics.percentCruise;
      this.truckName = truck.name;
      // Journey Name Check
      if (!data.names?.assignedName) {
        // Default to Automated
        this.journeyName = data.names?.cityNames ? data.names?.cityNames : '-';
        if (this.journeyType === 'destination') {
          // If the fleet journey setting is destination, display destination journey name
          if (data.names?.geozonesName) {
            this.journeyName = data.names?.geozonesName;
          }
        }
      } else {
        // Retrieves Assigned name for journey
        this.journeyName = data.names?.assignedName;
      }
      this.totalMassAverage = '-';
      let tt = this.$refs.scatterTT;
      const tooltipHeight = 415;
      let left = null;
      // show tooltip on left side if overflow on the right
      if (e.clientX + this.tooltipWidth > window.innerWidth) {
        left = e.clientX - this.tooltipWidth;
        tt.style.left = left + 'px';
      } else {
        left = e.clientX;
        tt.style.left = left + 'px';
      }
      tt.style.display = 'block';
      tt.style.bottom = window.innerHeight - e.clientY + 2 + 'px';
      // define bounds of tooltip for clickOutside() logic
      // bounds = [top, right, bottom, left]
      this.bounds = [
        window.innerHeight - e.clientY + 2 + tooltipHeight,
        left + this.tooltipWidth,
        window.innerHeight - e.clientY + 2,
        left,
      ];
    },
    async populateChart() {
      if (this.companyPreferences?.journey_setting === 'destination') {
        this.journeyType = 'destination';
      }
      this.journeys = this.journeyData; // Gets populated from parent
      this.configureData();
    },
    closeTooltip() {
      let tt = this.$refs.scatterTT;
      tt.style.display = 'none';
      this.ttOpen = false;
    },
    viewMore() {
      // We will continue to build this object with data needed to details as we get more info on what is required
      let journeyData = {
        truck: this.truck,
        data: this.currentDatum.point.data,
      };
      this.$emit('journeySelected', journeyData);
    },
    // this is a custom function that will close the tooltip if the click is
    // outside of it; HOWEVER, it will make an exception for clicks made in
    // the very bottom right or bottom left corner of the tooltip, since this
    // type of click is necessary to open the tooltip; this prevents a bug
    // where the tooltip auto closes everytime the users attempts to open it
    setupClickOutside() {
      const vm = this;
      const tooltip = this.$refs.scatterTT;
      function clickOutside(event) {
        try {
          const [x, y] = [event.clientX, event.clientY];
          // logic: if NOT((stricly inside) OR ((x is good) AND (y is good)))
          if (
            !(
              // strictly inside logic
              (
                tooltip == event.target ||
                tooltip.contains(event.target) ||
                // x coordinate logic
                ((Math.abs(vm.bounds[3] - x) < 3 ||
                  Math.abs(vm.bounds[3] - x + vm.tooltipWidth) < 3) &&
                  // y coordinate logic
                  Math.abs(window.innerHeight - vm.bounds[2] - y + 2) < 4)
              )
            )
          ) {
            vm.closeTooltip();
          }
        } catch (e) {
          if (!(e instanceof TypeError)) throw e; // typeError expected on page load
        }
      }
      document.body.addEventListener('click', clickOutside);
    },
    // configure chart data based on the selected metric
    configureData() {
      const vm = this;
      this.chartLoading = true;
      let data;
      let [chartTitle, yAxisLabel] = [null, null];
      if (this.selectedMetric === 'cruise') {
        chartTitle = 'Truck Cruise % per Journey';
        yAxisLabel = '% Cruise';
      } else if (this.selectedMetric === 'idle') {
        chartTitle = 'Truck Idle % per Journey';
        yAxisLabel = '% Idle';
      } else {
        chartTitle = 'Truck MPGe per Journey';
        yAxisLabel = 'MPGe';
      }
      let count = 0; // Using a count instead of indexes since some truck can possibly be skipped
      for (const [i, j] of this.journeys.entries()) {
        data = [];
        let truck = this.trucks.find((t) => t.id === parseInt(j.truckId));
        if (!truck) {
          continue; // Skips trucks that dont exist in the truck list(Filtered out via is_sim = true)
        }
        for (const jj of j.journeys) {
          if (jj.type === 3 || jj.type === 4) {
            continue; // Skips any journey that is a pre journey(3) or an unfinished journey(4)
          }
          let row = {};
          row.x = jj.start;
          // Sets Start Date Extreme
          if (!this.startDate) {
            this.startDate = jj.start;
          } else {
            if (this.startDate > jj.start) this.startDate = jj.start;
          }
          if (this.selectedMetric === 'cruise') {
            row.y = Math.round(jj.statistics.percentCruise);
          } else if (this.selectedMetric === 'idle') {
            row.y = Math.round(jj.statistics.percentIdle);
          } else {
            row.y = Math.round(jj.statistics.milesPerDGE * 10) / 10;
          }
          row.idle = String(Math.round(jj.statistics.percentIdle)) + '%';
          row.cruise = String(Math.round(jj.statistics.percentCruise)) + '%';
          row.data = jj;
          data.push(row);
        }
        this.series.push(this.setSeries(truck.number, j, data, count));
        count++;
      }
      // chart data not populating correctly unless options are initialized here
      this.options = {
        credits: { enabled: false },
        chart: {
          backgroundColor: '#1E1E1E',
          type: 'scatter',
          zoomType: 'x',
          height: `${Math.max(window.innerHeight - 560, 475)}px`, // Fills rest of page
        },
        title: {
          text: 'Truck MPGe per journey',
          style: {
            color: '#FFFFFF',
            fontFamily: 'Rawline',
            fontWeight: 'bold',
          },
        },
        subtitle: {
          // text: 'Source: Heinz  2003',
        },
        xAxis: {
          type: 'datetime',
          title: {
            enabled: true,
            text: 'Time',
            style: {
              color: '#FFFFFF',
              fontFamily: 'Rawline',
              fontWeight: 'bold',
            },
          },
          gridLineWidth: 1,
          gridLineColor: 'rgba(255, 255, 255, 0.08)',
          events: {
            afterSetExtremes: function (event) {
              // On manual user zoom only, broadcast newly defined extremes to Performance.vue scope
              if (event.trigger && event.trigger == 'zoom') {
                vm.setExtremes(event.min, event.max);
              }
            },
          },
        },
        yAxis: {
          type: 'linear',
          gridLineWidth: 1,
          gridLineColor: 'rgba(255, 255, 255, 0.08)',
          title: {
            text: 'MPGe',
            style: {
              color: '#FFFFFF',
              fontFamily: 'Rawline',
              fontWeight: 'bold',
            },
          },
        },
        legend: {
          enabled: true,
          backgroundColor: '#1E1E1E',
          align: 'left',
          labelFormatter: function () {
            return this.name + ' (click to hide)';
          },
          itemStyle: {
            color: '#E0E0E0',
            fontWeight: 'bold',
            fontSize: 10,
          },
        },
        plotOptions: {
          scatter: {
            cursor: 'pointer',
            marker: {
              radius: 5,
              states: {
                hover: {
                  enabled: true,
                  lineColor: 'rgb(100,100,100)',
                },
              },
            },
            states: {
              hover: {
                marker: {
                  enabled: false,
                },
              },
            },
          },
          series: {
            // reconfigure truck set when hidie/show truck series fires
            events: {
              hide: function () {
                if (!this.chart.lbl) {
                  vm.configureTrucks();
                }
              },
              show: function () {
                if (!this.chart.lbl) {
                  vm.configureTrucks();
                }
              },
            },
          },
        },
        tooltip: {
          enabled: false,
        },
        exporting: {
          enabled: false,
        },
        series: this.series,
      };
      this.options.series.data = data;
      this.options.yAxis.title.text = yAxisLabel;
      this.options.title.text = chartTitle;
      this.chartLoading = false;
      // Set's initial zoomed boundaries for the beggining of the month
      if (this.initLoad) {
        setTimeout(() => {
          vm.setChartZoom();
          this.initLoad = false;
        }, 200);
      }
    },
    setChartZoom() {
      const scatter = this.$refs.scatter?.chart;
      if (scatter) {
        // Commented out custom extremes
        // if (this.range === 'week') start = now - 86400000 * 30;
        // // Temp, showing last 30 days
        // else if (this.range === 'month') start = now - 86400000 * 30;
        const now = Date.now();
        const date = new Date(this.startDate);
        const start = new Date(
          date.getFullYear(),
          date.getMonth(),
          1
        ).getTime(); // Gets begining of month of oldest journey
        scatter.xAxis[0].setExtremes(start, now);

        // set the timezone to use for the scatter chart
        const tz = this.userPreferences.timeZonePref.canonical;
        const tzOffset = getTimeZoneOffset(start, tz);
        scatter.update({
          time: { timezoneOffset: tzOffset },
        });

        this.setExtremes(start, now); // send extremes to Performance.vue scope
        this.updateCurrentScreen({
          scatterRange: 'Default: Beginning of Year',
        });
      }
    },
    setSeries(name, journey, data, i) {
      let color, symbol;
      let truck = {
        name: name || '',
        id: journey.truckId,
        companyId: this.company.id,
      };
      if (this.initLoad) {
        // Creates instances of unique color/symbols for initial chart rendering
        color = `hsla(${~~(360 * Math.random())},  70%,  72%, 1)`;
        if (this.shapesIndex > this.shapes.length - 1) {
          this.shapesIndex = 0;
        }
        symbol = this.shapes[this.shapesIndex++];
        this.markers.push({
          color,
          symbol,
        });
      } else {
        color = this.markers[i].color;
        symbol = this.markers[i].symbol;
      }
      let series = {
        events: {
          click: (event) => {
            this.navigate(event, truck);
          },
        },
        name: name || journey.truckId,
        id: journey.truckId,
        color,
        data: data,
        marker: {
          symbol,
        },
      };

      return series;
    },
    // send set of currently visible trucks to Performance.vue scope
    configureTrucks() {
      const truckSet = new Set();
      for (const truck of this.$refs.scatter.chart.series) {
        if (truck.visible) truckSet.add(truck.userOptions.id);
      }
      this.truckSet = truckSet;
      // this.setTrucks(truckSet); Disabling hide/show Performance Bar update for Beta 1.2 Filters
    },
    setExtremes(start, end) {
      this.extremes = [start, end]; // set in local state
      if (!this.initLoad) this.sendExtremes(start, end); // send to performance.vue state
    },
  },
};
</script>

<style scoped>
.scatterTT {
  display: none;
  position: fixed;
  width: 481px;
  height: 415px;
  /* background: #1A1A1A; */
  /* border-radius: 4px; */
  z-index: 1;
}
.ttTitle {
  font-size: 20px;
  letter-spacing: 0.15px;
  line-height: 28px;
  font-weight: 500;
  margin-top: -1px;
  margin-left: 5px;
}
.titleBar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.ttX {
  cursor: pointer;
  margin: 20px;
}
</style>
