<template>
  <v-container fluid>
    <v-sheet class="my-10" color="grey darken-3" elevation="1">
      <v-row>
        <v-col class="pl-10">
          <h3>EX</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pl-10"
          >New DTCs <br />Select the severity of alerts you would like to
          subscribe to.<br />
          <v-radio-group v-model="newDtcRadio" row @change="newDtcChange">
            <v-radio label="None" value="None"></v-radio>
            <v-radio label="All" value="All"></v-radio>
            <v-radio label="High" value="High"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col
          >Pre/Pending DTC<br />Select the severity of alerts you would like to
          subscribe to.<br />
          <v-radio-group v-model="preDtcRadio" row @change="pendingDtcChange">
            <v-radio label="None" value="None"></v-radio>
            <v-radio label="All" value="All"></v-radio>
            <v-radio label="High" value="High"></v-radio> </v-radio-group
        ></v-col> </v-row
    ></v-sheet>
    <v-sheet class="my-10" color="grey darken-3" elevation="1">
      <v-row>
        <v-col class="pl-10">
          <h3>ERX</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pl-10"
          >New DTCs
          <v-radio-group v-model="ERXDtcRadio" row @change="ERXDtcChange">
            <v-radio label="None" value="None"></v-radio>
            <v-radio label="All" value="All"></v-radio>
          </v-radio-group>
        </v-col> </v-row
    ></v-sheet>
    <v-row class="my-0">
      <v-col>
        <v-data-table
          :headers="geoZoneAlertLabels"
          :items="geoZoneAlertSubs"
          :items-per-page="15"
          @click:row="handleClickRow"
        >
          <template v-slot:[`item.alert_on`]="{ item }">
            <span
              v-if="
                (item.is_enter == null && item.is_exit == null) ||
                (item.is_enter == 'false' && item.is_exit == 'false')
              "
            >
              None
            </span>
            <span v-if="item.is_enter == 'true' && item.is_exit == 'false'">
              Enter
            </span>
            <span v-if="item.is_enter == 'false' && item.is_exit == 'true'">
              Exit
            </span>
            <span v-if="item.is_enter == 'true' && item.is_exit == 'true'">
              Enter/Exit
            </span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-menu style="top: 312px; left: 300px" offset-y>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">mdi-dots-vertical</v-icon>
              </template>
              <v-list>
                <!-- TODO: rename route.title?? -->
                <v-list-item
                  v-for="(action, index) in actionItems"
                  :key="index"
                  :class="action.title"
                  @click="subscribeGeozone(item.id, action.title)"
                  >{{ action.title }}</v-list-item
                >
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-col>
      <v-col>
        <div id="map">
          <div id="mapContainer" ref="hereMap" :style="mapStyle"></div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { getGeozones } from '@/api/geozones';
import {
  getAlertSubscriptions,
  getAlertsOperations,
  subscribeToAlert,
  unsubscribeToAlert,
  getGeozoneSubs,
  addGeozoneAlert,
} from '@/api/alerts';
import { mapGetters } from 'vuex';
export default {
  name: 'SubscribeAlerts',
  data() {
    return {
      newDtcRadio: null,
      preDtcRadio: null,
      ERXDtcRadio: null,
      alertsOperations: [],
      alertsSubscriptions: [],
      alerts: [],
      geoZoneAlertLabels: [
        {
          text: 'Name',
          align: 'left',
          value: 'name',
          sortable: true,
        },
        {
          text: 'Location',
          align: 'left',
          value: 'description',
          sortable: false,
        },
        {
          text: 'Alert On',
          align: 'left',
          value: 'alert_on',
          sortable: false,
        },
        {
          text: 'Actions',
          align: 'left',
          value: 'actions',
          sortable: false,
        },
      ],
      geoZonesList: [],
      geoZoneAlertSubs: [],
      hereMap: null,
      hereUI: null,
      platform: null,
      apikey: null,
      actionItems: [
        { title: 'None' },
        { title: 'Enter' },
        { title: 'Exit' },
        { title: 'Enter/Exit' },
      ],
    };
  },
  computed: {
    ...mapGetters({
      HERE_MAP_KEY: 'getMapKey',
    }),
    mapStyle() {
      return `height: ${window.innerHeight - 460}px; width: 100%`;
    },
    mapHeight() {
      if (this.elevationProcessed && this.showElevation) {
        return Math.max(window.innerHeight - 66 - 270, 270);
      } else {
        return window.innerHeight - 66;
      }
    },
  },
  async mounted() {
    try {
      this.apikey = this.HERE_MAP_KEY;
      const platform = new window.H.service.Platform({
        apikey: this.apikey,
      });
      this.platform = platform;
      this.initializeHereMap();

      let alertsSubscriptions;
      await Promise.allSettled([
        getAlertSubscriptions(),
        getAlertsOperations(),
        getGeozoneSubs(),
      ]).then((data) => {
        data.forEach((d) => {
          if (d.status === 'rejected') {
            console.error(d.reason);
          }
        });
        alertsSubscriptions = data[0].value;
        this.alertsOperations = data[1].value;
        this.geoZoneAlertSubs = data[2].value;
      });
      this.initAlerts(alertsSubscriptions);

      await this.getList();
    } catch (e) {
      console.log(e, 'error in retrieving the alerts subscriptions');
    }
  },
  methods: {
    initializeHereMap() {
      const mapContainer = this.$refs.hereMap;
      const H = window.H;
      // Obtain the default map types from the platform object
      var maptypes = this.platform.createDefaultLayers();

      // Instantiate (and display) a map object:
      var map = new H.Map(mapContainer, maptypes.vector.normal.map, {
        zoom: 4.5,
        center: {
          lat: 39.8283,
          lng: -98.5795,
        },
      });
      this.hereMap = map;

      addEventListener('resize', () => map.getViewPort().resize());

      // add behavior control
      new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
      // add UI
      H.ui.UI.createDefault(map, maptypes);
      this.hereUI = H.ui.UI.createDefault(map, maptypes);
    },
    initAlerts(alertsSubscriptions) {
      this.alertsSubscriptions = alertsSubscriptions.data;
      let dtcCond = this.alertsSubscriptions.filter((condition) => {
        return (
          condition.trigger_source == 'DTC' && condition.subscribed == true
        );
      });
      if (dtcCond.length === 0) {
        this.newDtcRadio = 'None';
      } else if (dtcCond.length == 2) {
        this.newDtcRadio = 'All';
      } else {
        this.newDtcRadio = 'High';
      }

      let preDtcCond = this.alertsSubscriptions.filter((condition) => {
        return (
          condition.trigger_source == 'PreDTC' && condition.subscribed == true
        );
      });

      if (preDtcCond.length == 0) {
        this.preDtcRadio = 'None';
      } else if (preDtcCond.length == 2) {
        this.preDtcRadio = 'All';
      } else {
        this.preDtcRadio = 'High';
      }

      let ERXdtcCond = this.alertsSubscriptions.filter((condition) => {
        return (
          condition.trigger_source == 'ERX DTC' && condition.subscribed == true
        );
      });
      if (ERXdtcCond.length === 0) {
        this.ERXDtcRadio = 'None';
      } else if (ERXdtcCond.length == 1) {
        this.ERXDtcRadio = 'All';
      }
    },
    handleClickRow(value) {
      let coordinates = { lat: value.latitude, lng: value.longitude };
      this.goToCoordinates(this.hereMap, coordinates);
    },
    goToCoordinates(map, coordinates) {
      map.setCenter(coordinates);
      map.setZoom(8);
    },
    async subscribeGeozone(id, action) {
      action = action.toUpperCase();
      if (action === 'ENTER/EXIT') {
        action = 'ENTER-EXIT';
      }
      await addGeozoneAlert(id, action);
      this.geoZoneAlertSubs = await getGeozoneSubs();
    },
    //GEOZONE METHODS
    async getList() {
      //remove all objects
      if (this.hereMap) {
        this.hereUI
          .getBubbles()
          .forEach((bub) => this.hereUI.removeBubble(bub));
        this.hereMap.removeObjects(this.hereMap.getObjects());
      }

      try {
        this.geoZonesList = await getGeozones();
        this.geoZonesList = this.geoZonesList.data;
        //iterate through geozones list
        //run addCircleToMap on each
        for (let geozone of this.geoZonesList) {
          this.addCircleToMap(
            { lat: geozone.latitude, lng: geozone.longitude },
            geozone.radius_meters,
            geozone
          );
        }
      } catch (e) {
        console.log(e);
      }
    },
    addCircleToMap(coordinates, radius_meters) {
      const vm = this;
      const H = window.H;
      vm.hereGroup = new H.map.Group();

      let circle = new H.map.Circle(
        // The central point of the circle
        coordinates,
        // The radius of the circle in meters
        radius_meters,
        {
          style: {
            background: '#82B1FF',
            opacity: 0.3,
            border: '2px solid #2196F3',
          },
        }
      );

      vm.hereGroup.addObject(circle);
      this.hereMap.addObject(this.hereGroup);
    },
    async newDtcChange() {
      switch (this.newDtcRadio) {
        case 'None':
          this.alertsSubscriptions.forEach(async (condition) => {
            if (
              condition.trigger_source == 'DTC' &&
              condition.subscribed == true
            ) {
              await unsubscribeToAlert(condition.condition_id)
                .then((response) => {
                  if (response.status === 200) {
                    condition.subscribed = false;
                  }
                })
                .catch((error) => {
                  console.error('Axios error at unsubscribeToAlert: ' + error);
                });
            }
          });
          break;
        case 'All':
          this.alertsSubscriptions.forEach(async (condition) => {
            if (condition.trigger_source == 'DTC') {
              await subscribeToAlert(condition.condition_id)
                .then((response) => {
                  if (response.status === 201) {
                    condition.subscribed = true;
                  }
                })
                .catch((error) => {
                  console.error('Axios error at subscribeToAlert: ' + error);
                });
            }
          });
          break;
        case 'High':
          this.alertsSubscriptions.forEach(async (condition) => {
            if (condition.trigger_source == 'DTC') {
              if (condition.threshold_value == 1) {
                await subscribeToAlert(condition.condition_id)
                  .then((response) => {
                    if (response.status === 201) {
                      condition.subscribed = true;
                    }
                  })
                  .catch((error) => {
                    console.error('Axios error at subscribeToAlert: ' + error);
                  });
              } else {
                await unsubscribeToAlert(condition.condition_id)
                  .then((response) => {
                    if (response.status === 200) {
                      condition.subscribed = false;
                    }
                  })
                  .catch((error) => {
                    console.error(
                      'Axios error at unsubscribeToAlert: ' + error
                    );
                  });
              }
            }
          });
          break;
      }
    },
    async pendingDtcChange() {
      switch (this.preDtcRadio) {
        case 'None':
          this.alertsSubscriptions.forEach(async (condition) => {
            if (
              condition.trigger_source == 'PreDTC' &&
              condition.subscribed == true
            ) {
              await unsubscribeToAlert(condition.condition_id)
                .then((response) => {
                  if (response.status === 200) {
                    condition.subscribed = false;
                  }
                })
                .catch((error) => {
                  console.error('Axios error at unsubscribeToAlert: ' + error);
                });
            }
          });
          break;
        case 'All':
          this.alertsSubscriptions.forEach(async (condition) => {
            if (condition.trigger_source == 'PreDTC') {
              await subscribeToAlert(condition.condition_id)
                .then((response) => {
                  if (response.status === 201) {
                    condition.subscribed = true;
                  }
                })
                .catch((error) => {
                  console.error('Axios error at unsubscribeToAlert: ' + error);
                });
            }
          });
          break;
        case 'High':
          this.alertsSubscriptions.forEach(async (condition) => {
            if (condition.trigger_source == 'PreDTC') {
              if (condition.threshold_value == 1) {
                await subscribeToAlert(condition.condition_id)
                  .then((response) => {
                    if (response.status === 201) {
                      condition.subscribed = true;
                    }
                  })
                  .catch((error) => {
                    console.error(
                      'Axios error at unsubscribeToAlert: ' + error
                    );
                  });
              } else {
                await unsubscribeToAlert(condition.condition_id)
                  .then((response) => {
                    if (response.status === 200) {
                      condition.subscribed = false;
                    }
                  })
                  .catch((error) => {
                    console.error(
                      'Axios error at unsubscribeToAlert: ' + error
                    );
                  });
              }
            }
          });
          break;
      }
    },
    async ERXDtcChange() {
      switch (this.ERXDtcRadio) {
        case 'None':
          this.alertsSubscriptions.forEach(async (condition) => {
            if (
              condition.trigger_source == 'ERX DTC' &&
              condition.subscribed == true
            ) {
              await unsubscribeToAlert(condition.condition_id)
                .then((response) => {
                  if (response.status === 200) {
                    condition.subscribed = false;
                  }
                })
                .catch((error) => {
                  console.error('Axios error at unsubscribeToAlert: ' + error);
                });
            }
          });
          break;
        case 'All':
          this.alertsSubscriptions.forEach(async (condition) => {
            if (condition.trigger_source == 'ERX DTC') {
              await subscribeToAlert(condition.condition_id)
                .then((response) => {
                  if (response.status === 201) {
                    condition.subscribed = true;
                  }
                })
                .catch((error) => {
                  console.error('Axios error at subscribeToAlert: ' + error);
                });
            }
          });
          break;
      }
    },
  },
};
</script>
<style scoped></style>
