<template>
  <v-container class="body" grid-list-md text-xs-center>
    <v-row align="center">
      <v-col cols="2"><v-spacer /></v-col>
      <v-col class="mb-1" cols="12" lg="4" xl="4">
        <h1 style="font-weight: 400">ADX Data</h1>
      </v-col>
    </v-row>
    <!-- LOADER -->
    <loader v-if="truckSelection.length == 0" />
    <!-- DATA SELECTION - DROPDOWN -->
    <v-row class="mb-2">
      <v-col cols="2"><v-spacer /></v-col>
      <!-- TRUCK SELECTION - DROPDOWN -->
      <v-col cols="12" lg="4" xl="4">
        <v-select
          v-model="dropDown.truckSelection"
          :items="truckSelection"
          :loading="isSelectLoading"
          :item-text="getFieldText"
          :item-value="getFieldText"
          label="Truck Selection"
          persistent-hint
          return-object
          outlined
          :rules="[(v) => !!v || 'Required!']"
          required
          @click="exportClicked = false"
        ></v-select>
      </v-col>
      <v-col cols="1"><v-spacer /></v-col>
      <v-col cols="2">
        Signal Name Source
        <v-radio-group v-model="dropDown.signalNameSource" inline>
          <v-radio label="ADX Names" value="ADX"></v-radio>
          <v-radio label="DBC Names" value="DBC"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="2">
        File Export Type
        <v-radio-group v-model="dropDown.fileExportType" inline>
          <v-radio label="CSV" value="CSV"></v-radio>
          <v-radio label="CSV-MF4" value="CSV-MF4"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <!-- START - DATE PICKER -->
    <v-row>
      <v-col cols="2"><v-spacer /></v-col>
      <v-col class="py-0" cols="12" lg="4" xl="4">
        <v-datetime-picker
          v-model="dropDown.startDate"
          :label="startDateLabel"
          @input="saveDates"
        >
        </v-datetime-picker>
      </v-col>

      <!-- END - DATE PICKER -->
      <v-col class="py-0" cols="12" lg="4" xl="4">
        <v-datetime-picker
          v-model="dropDown.endDate"
          :label="endDateLabel"
          @input="saveDates"
        >
        </v-datetime-picker>
      </v-col>
    </v-row>

    <!-- SIGNALS - MULTI SELECT -->
    <!-- <v-row>
        <v-col cols="12" offset="2" lg="8" xl="8">
          <v-select
            v-model="dropDown.selectedSignals"
            :items="viewableSignals"
            :loading="isSelectLoading"
            label="Signals"
            multiple
            chips
            height="56px"
            outlined
            :rules="[(v) => !!v || 'Required!']"
            required
          >
            <template v-slot:prepend-item>
              <v-list-item @click="toggleSelectAllSignals">
                <v-list-item-action>
                  <v-icon :color="allSignalsSelected ? 'primary' : ''">
                    {{ signalIcon }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title> Select All </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
            </template>
            <template v-slot:selection="{ item }">
              <v-chip readonly>{{ item }}</v-chip>
            </template>
          </v-select>
        </v-col>
      </v-row> -->
    <!-- SIGNAL GRANULARITY - DROPDOWN -->
    <v-row>
      <v-col cols="2"><v-spacer /></v-col>
      <v-col class="py-0" cols="12" lg="8" xl="8">
        <v-select
          v-model="dropDown.pickedGranularity"
          :items="getGranularity"
          item-text="granularity"
          item-value="granularity"
          label="Granularity"
          persistent-hint
          return-object
          outlined
          :rules="[(v) => !!v || 'Required!']"
          required
          @click="exportClicked = false"
        ></v-select>
      </v-col>
    </v-row>

    <!-- MESSAGES -->
    <v-row>
      <v-col cols="2"><v-spacer /></v-col>
      <v-col class="py-0" cols="12" lg="8" xl="8">
        <p v-if="dropDown.truckSelection == undefined" style="color: #43b02a">
          Please select a truck.
        </p>
        <!--          <p v-if="dropDown.selectedSignals.length == 0" style="color: #43b02a">-->
        <!--            Please select at least one signal.-->
        <!--          </p>-->
        <div style="display: flex; align-items: baseline">
          <p :style="displayRowCountColor">
            {{ displayRowCountText }}
          </p>
          <div v-if="isRowCountLoading == true" style="margin-left: 20px">
            <v-progress-circular
              model-value="100"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
        </div>
      </v-col>
    </v-row>
    <!-- PROGRESS/EXPORT BUTTON -->
    <v-row>
      <v-col><v-spacer /></v-col>
      <v-col class="py-0" cols="12" lg="3" xl="3">
        <div v-if="isDataLoading == true" justify="center" align="center">
          <v-progress-circular
            model-value="100"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </div>
        <!-- EXPORT - BUTTON -->
        <v-btn
          :disabled="
            dropDown.truckSelection === undefined ||
            // dropDown.selectedSignals.length === 0 ||
            count > maxRowsThreshold ||
            count == 0 ||
            exportClicked === true
          "
          color="primary"
          block
          plain
          @click="exportADXCSV"
        >
          <v-icon class="pr-1">mdi-download</v-icon>
          Export
        </v-btn>
      </v-col>
      <v-col><v-spacer /></v-col>
    </v-row>
  </v-container>
</template>
<script>
import loader from '@/utilities/loader';
import dateFormat from 'dateformat';
import { compareDates } from '@/utilities/dateFunctions';
import { getDropDown, getADXRowCount, postADXData } from '@/api/adx';
import { mapActions } from 'vuex';

export default {
  components: { loader },
  data() {
    return {
      dropDown: {
        dataSelection: 'ERX',
        truckSelection: undefined,
        startDate: this.getDateStart(),
        endDate: this.getDateEnd(),
        signalNameSource: 'ADX',
        fileExportType: 'CSV',
        // selectedSignals: [],
        pickedGranularity: '1min',
      },
      dataSelection: ['ERX', 'EX'],
      startDate: null,
      endDate: null,
      startDateLabel: null,
      endDateLabel: null,
      granularity: [],
      truckSelection: [],
      // viewableSignals: [],
      // dataForDownload: [],
      // unitsForDownload: [],
      isSelectLoading: false,
      isDataLoading: false,
      dropDownData: [],
      exportClicked: false,
      count: -1,
      maxRowsThreshold: 306000, // 8.5 hours of data at 100ms
      isRowCountLoading: false,
    };
  },
  computed: {
    getGranularity() {
      return ['1min', '100ms'];
    },
    displayRowCountText() {
      if (this.isRowCountLoading) {
        return 'Calculating Row Count: ?';
      } else {
        let rowCountText = `Calculated Row Count: `;
        rowCountText +=
          this.count >= 0 ? this.count.toLocaleString('en-US') : '';
        return rowCountText;
      }
    },
    displayRowCountColor() {
      if (this.count > this.maxRowsThreshold) {
        return 'color: Red';
      } else if (this.count <= 0) {
        return 'color: #D3D3D3';
      } else {
        return 'color: #43b02a';
      }
    },
  },
  // allSignalsSelected() {
  //   return (
  //     this.dropDown.selectedSignals.length == this.viewableSignals.length
  //   );
  // },
  // signalIcon() {
  //   if (this.allSignalsSelected) return 'mdi-close-box';
  //   else return 'mdi-checkbox-blank-outline';
  // },
  watch: {
    'dropDown.startDate': function () {
      this.calculateCount();
    },
    'dropDown.endDate': function () {
      this.calculateCount();
    },
    'dropDown.pickedGranularity': function () {
      this.calculateCount();
    },
    'dropDown.truckSelection': function () {
      this.calculateCount();
    },
  },
  async mounted() {
    this.dropDownData = await getDropDown(this.dropDown);
    this.populateDropdown(this.dropDownData.data);
    this.modifyDateStartEnd(this.getDateStart(), this.getDateEnd());
    await this.calculateCount();
    // this.selectAllSignals();
  },
  methods: {
    ...mapActions(['updateSnack']),
    async calculateCount() {
      if (
        this.dropDown.truckSelection &&
        this.dropDown.startDate &&
        this.dropDown.endDate &&
        this.dropDown.pickedGranularity
      ) {
        this.count = 0;

        this.isRowCountLoading = true;
        this.count = await getADXRowCount(
          this.dropDown.truckSelection.id,
          this.dropDown.startDate,
          this.dropDown.endDate,
          this.dropDown.pickedGranularity
        );
        this.isRowCountLoading = false;
      }
    },
    saveDates() {
      if (compareDates(this.dropDown.endDate, this.dropDown.startDate) < 0) {
        const tempDate = this.dropDown.startDate;
        this.dropDown.startDate = this.dropDown.endDate;
        this.dropDown.endDate = tempDate;
      }
      this.modifyDateStartEnd(this.dropDown.startDate, this.dropDown.endDate);
      this.exportClicked = false;
    },
    async runCustomQuery() {
      this.truckSelection = [];
      this.dropDown.selectedSignals = [];
      // this.viewableSignals = [];
      this.pickedGranularity = '1min';
      this.populateDropdown(this.dropDownData.data);
    },
    getFieldText(item) {
      return `${item.company_name} - ${item.truck_name}`;
    },
    populateDropdown(data) {
      this.truckSelection =
        data[`${this.dropDown.dataSelection}`].truckSelection;
      if (this.$route.query.truck) {
        const truck = this.$route.query.truck;
        this.dropDown.startDate = this.$route.query.startDate;
        this.dropDown.endDate = this.$route.query.endDate;
        this.dropDown.truckSelection = this.truckSelection.find(
          (truckElement) => {
            return truckElement.id === parseInt(truck);
          }
        );
      } else {
        this.startDate = data.startDate;
        this.endDate = data.endDate;
      }
      // this.viewableSignals =
      //   data[`${this.dropDown.dataSelection}`].viewableSignals;
    },
    // selectAllSignals() {
    //   this.dropDown.selectedSignals = this.viewableSignals;
    // },
    async search() {
      this.isDataLoading = true;
      this.exportClicked = true;
      let payload = {};

      payload.dataSelection = 'ERX';
      payload.startDate = this.dropDown.startDate;
      payload.endDate = this.dropDown.endDate;
      // payload.selectedSignals = this.dropDown.selectedSignals;
      payload.pickedGranularity = this.dropDown.pickedGranularity;
      payload.truckSelectionId = this.dropDown.truckSelection.id;
      payload.signalNameSource = this.dropDown.signalNameSource;
      payload.fileExportType = this.dropDown.fileExportType;

      await postADXData(payload)
        .then((response) => {
          if (response.status === 201) {
            this.isDataLoading = false;
            this.updateSnack({
              type: 'warning',
              message: 'No Data Returned for this query!',
            });
            return;
          }
          this.createDownload(response);
          this.updateSnack({
            type: 'success',
            message: 'Data exported successfully!',
          });

          this.isDataLoading = false;
          this.exportClicked = false;
        })
        .catch(() => {
          this.isDataLoading = false;
          this.updateSnack({
            type: 'error',
            message: 'An Error Has Occured!',
          });
        });
    },
    getDateStart() {
      let days = 1;
      var date = new Date();
      let last = new Date(date.getTime() - days * 60 * 60 * 1000);
      return dateFormat(last.setHours(0, 0, 0, 1), 'yyyy-mm-dd HH:MM');
    },
    getDateEnd() {
      let today = new Date();
      return dateFormat(today.setHours(23, 59, 59, 999), 'yyyy-mm-dd HH:MM');
    },
    modifyDateStartEnd(date1, date2) {
      this.startDateLabel =
        'Select Start Time (UTC: ' +
        dateFormat(date1, 'yyyy-mm-dd HH:MM', true) +
        ')';
      this.endDateLabel =
        'Select End Time (UTC: ' +
        dateFormat(date2, 'yyyy-mm-dd HH:MM', true) +
        ')';
    },
    // toggleSelectAllSignals() {
    //   if (this.allSignalsSelected) {
    //     this.dropDown.selectedSignals = [];
    //   } else {
    //     this.dropDown.selectedSignals = this.viewableSignals.slice();
    //   }
    // },
    async exportADXCSV() {
      if (!this.exportClicked) {
        this.exportClicked = true;
        await this.search();
      }
    },
    createDownload(response) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      // Parse the filename from the Content-Disposition header
      const contentDisposition = response.headers['content-disposition'];
      let filename = 'signalData.csv'; // default filename in case we can't extract it
      if (contentDisposition) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        let matches = filenameRegex.exec(contentDisposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, '');
        }
      }
      link.setAttribute('download', filename); // or any other extension
      document.body.appendChild(link);
      link.click();
    },
  },
};
</script>
