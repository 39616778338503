import { render, staticRenderFns } from "./EditUser.vue?vue&type=template&id=523bc96c&scoped=true&"
import script from "./EditUser.vue?vue&type=script&lang=js&"
export * from "./EditUser.vue?vue&type=script&lang=js&"
import style0 from "./EditUser.vue?vue&type=style&index=0&id=523bc96c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "523bc96c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('523bc96c')) {
      api.createRecord('523bc96c', component.options)
    } else {
      api.reload('523bc96c', component.options)
    }
    module.hot.accept("./EditUser.vue?vue&type=template&id=523bc96c&scoped=true&", function () {
      api.rerender('523bc96c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Users/EditUser.vue"
export default component.exports