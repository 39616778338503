<template>
  <div>
    <v-row
      ><v-col>{{ items.length }} results</v-col
      ><v-col align="right">
        <v-btn icon @click="usageDrawer = !usageDrawer"
          ><v-icon>mdi mdi-dots-vertical</v-icon></v-btn
        >
      </v-col></v-row
    >
    <v-skeleton-loader
      v-if="loading"
      type="table"
      elevation="2"
    ></v-skeleton-loader>
    <v-data-table
      v-else
      :headers="headers"
      :items="items"
      :items-per-page="25"
      class="elevation-1 locked-table-style"
    >
      <template v-slot:[`item.companyNumber`]="{ item }">
        <th class="body-5">{{ item.truck_name }}</th>
        <div class="body-6">{{ item.company }}</div>
      </template>
      <template v-for="h in headers" v-slot:[`header.${h.value}`]="{ header }">
        <template v-if="header.hasOptions">
          <span :key="h.text">{{ checkUserPrefs(header) }}</span>
        </template>
        <template v-else
          ><span :key="h.text">{{ header.text }}</span></template
        >
      </template>
    </v-data-table>
    <v-navigation-drawer
      v-model="usageDrawer"
      class="shade"
      right
      temporary
      fixed
      :width="360"
    >
      <v-container>
        <v-row>
          <v-col cols="12">
            <template class="mx-auto column-list">
              <v-list>
                <v-subheader>Select to display Usage Columns</v-subheader>
                <v-list-item-group active-class="green--text" multiple>
                  <draggable v-model="headersDup" v-bind="dragOptions">
                    <template v-for="(column, index) in headersDup">
                      <v-list-item
                        v-if="column.text.length"
                        :key="column.value"
                        :disabled="!column.hideable"
                        @click="updateColumn(column, index)"
                      >
                        <v-list-item-action class="mr-5">
                          <v-icon v-if="!column.visible" color="grey">
                            mdi-square-outline
                          </v-icon>
                          <v-icon v-else color="primary">
                            mdi-checkbox-marked
                          </v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>{{
                            checkUserPrefs(column)
                          }}</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-icon v-if="!column.hideable"> mdi-lock </v-icon>
                          <v-icon v-else> mdi-menu </v-icon>
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                  </draggable>
                </v-list-item-group>
              </v-list>
              <v-divider></v-divider>
              <v-btn
                class="center mt-3"
                text
                color="primary"
                @click="resetUsageDefaults"
                >Reset Defaults</v-btn
              >
            </template>
          </v-col>
        </v-row>
      </v-container>
    </v-navigation-drawer>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import { mapGetters } from 'vuex';

export default {
  components: { draggable },
  props: ['loading', 'items'],
  data() {
    return {
      usageDrawer: false,
      headers: [
        {
          text: 'Truck # / Customer',
          width: '1%',
          align: 'left',
          sortable: true,
          value: 'companyNumber',
          visible: true,
          hideable: true,
        },
        {
          text: 'Date',
          align: 'center',
          value: 'Day',
          sortable: true,
          visible: true,
          hideable: true,
        },
        {
          text: 'Total Distance (km)',
          altText: 'Total Distance (mi)',
          hasOptions: true,
          type: 'regionPref',
          align: 'center',
          value: 'distanceKM',
          sortable: true,
          visible: false,
          hideable: true,
        },
        {
          text: 'EV km',
          altText: 'EV miles',
          hasOptions: true,
          type: 'regionPref',
          align: 'center',
          value: 'evKM',
          sortable: true,
          visible: false,
          hideable: true,
        },
        {
          text: 'Gen-on Distance (km)',
          altText: 'Gen-on Distance (mi)',
          hasOptions: true,
          type: 'regionPref',
          align: 'center',
          value: 'genOnDist',
          sortable: true,
          visible: false,
          hideable: true,
        },
        {
          text: 'Fuel Used (kg)',
          altText: 'Fuel Used (lb)',
          hasOptions: true,
          type: 'regionPref',
          align: 'center',
          value: 'fuelUsedKg',
          sortable: true,
          visible: false,
          hideable: true,
        },
        {
          text: 'Diesel Volume Equivalent (L)',
          altText: 'Diesel Volume Equivalent (gal)',
          hasOptions: true,
          type: 'regionPref',
          align: 'center',
          value: 'DGL',
          sortable: true,
          visible: false,
          hideable: true,
        },
        {
          text: 'Efficiency per Diesel equivalent (km/L)',
          altText: 'Efficiency per Diesel equivalent (mi/gal)',
          hasOptions: true,
          type: 'regionPref',
          align: 'center',
          value: 'kmPerL',
          sortable: true,
          visible: false,
          hideable: true,
        },
        {
          text: 'avg Moving Weight (kg)',
          altText: 'avg Moving Weight (lb)',
          hasOptions: true,
          type: 'regionPref',
          align: 'center',
          value: 'avgMovingWeightKG',
          sortable: true,
          visible: false,
          hideable: true,
        },

        {
          text: 'Start Odometer (km)',
          altText: 'Start Odometer (mi)',
          hasOptions: true,
          type: 'regionPref',
          align: 'center',
          value: 'startOdometerKM',
          sortable: true,
          visible: false,
          hideable: true,
        },
        {
          text: 'End Odometer (km)',
          altText: 'End Odometer (mi)',
          hasOptions: true,
          type: 'regionPref',
          align: 'center',
          value: 'endOdometerKM',
          sortable: true,
          visible: false,
          hideable: true,
        },
      ],
      headersDup: [],
    };
  },
  computed: {
    ...mapGetters({
      userRoles: 'getUserRoles',
      userPreferences: 'getUserPreferences',
    }),
    dragOptions() {
      return {
        animation: 100,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      };
    },
  },
  async created() {
    this.headersDup = [...this.headers];
    this.$emit('headers', { headers: this.headers, type: 'ERX' });
  },
  methods: {
    resetUsageDefaults() {
      this.headersDup.forEach((o) => {
        o.visible = true;
      });
      this.headers = this.headersDup;
    },
    updateColumn: function (column, index) {
      this.headersDup[index].visible = !column.visible;
      this.updateHeaders(column, column.visible, this.headersDup, this.headers);
    },
    updateHeaders: function (column, visible, dataHeadersDup, dataHeaders) {
      if (visible) {
        const length = dataHeaders.length;
        const label = dataHeadersDup.find((lb) => lb.text === column.text);
        const index = dataHeadersDup.findIndex((lb) => lb.text === column.text);
        if (index >= length) {
          // Keeps menu at the end of the columns
          dataHeaders.splice(dataHeaders.length - 1, 0, label);
        } else {
          dataHeaders.splice(index, 0, label);
        }
      } else {
        dataHeaders = dataHeaders.filter((hl) => hl.text !== column.text);
      }

      this.headersDup = dataHeadersDup;
      this.headers = dataHeaders;
    },
    checkUserPrefs(h) {
      if (h.type === 'regionPref') {
        if (this.userPreferences.regionPref === 'metric') {
          return h.text;
        } else {
          return h.altText;
        }
      } else {
        return h.text;
      }
    },
  },
};
</script>
