var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "460", persistent: "" },
      model: {
        value: _vm.openDialog,
        callback: function ($$v) {
          _vm.openDialog = $$v
        },
        expression: "openDialog",
      },
    },
    [
      _vm.loading ? _c("loader") : _vm._e(),
      _c(
        "v-card",
        { staticClass: "modal-bg-color" },
        [
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  attrs: { "lazy-validation": "" },
                  model: {
                    value: _vm.valid,
                    callback: function ($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid",
                  },
                },
                [
                  _c(
                    "v-row",
                    { staticClass: "pl-4", attrs: { align: "center" } },
                    [
                      _c("v-col", { attrs: { align: "left", cols: "4" } }, [
                        _c("h3", [_vm._v("Add User")]),
                      ]),
                      _c("v-col", [_c("v-spacer")], 1),
                      _c(
                        "v-col",
                        { attrs: { align: "right", cols: "2" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "" },
                              on: {
                                click: function ($event) {
                                  _vm.openDialog = false
                                },
                              },
                            },
                            [
                              _c("v-icon", { attrs: { light: "" } }, [
                                _vm._v("mdi-close-circle "),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "py-0" },
                        [
                          _c("v-text-field", {
                            attrs: {
                              rules: [(v) => !!v || "Required!"],
                              label: "Full Name",
                              outlined: "",
                            },
                            model: {
                              value: _vm.user.full_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.user, "full_name", $$v)
                              },
                              expression: "user.full_name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "py-0" },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Email",
                              outlined: "",
                              rules: [
                                _vm.emailRules,
                                (v) => !!v || "Required!",
                              ].flat(),
                              required: "",
                            },
                            model: {
                              value: _vm.user.email,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.user,
                                  "email",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "user.email",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.isHyliion
                    ? [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { staticClass: "py-0" },
                              [
                                _c("v-select", {
                                  attrs: {
                                    items: _vm.companies,
                                    "item-text": "name",
                                    label: "Employer Company",
                                    rules: [(v) => !!v || "Required!"],
                                    outlined: "",
                                    "return-object": "",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.selectId($event, "employer")
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { staticClass: "py-0" },
                              [
                                _c("v-checkbox", {
                                  staticClass: "ma-0",
                                  attrs: {
                                    label: "Has All Company Access",
                                    color: "primary",
                                    "hide-details": "",
                                  },
                                  model: {
                                    value: _vm.user.has_all_company_access,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.user,
                                        "has_all_company_access",
                                        $$v
                                      )
                                    },
                                    expression: "user.has_all_company_access",
                                  },
                                }),
                                _c("v-select", {
                                  attrs: {
                                    disabled: _vm.user.has_all_company_access,
                                    items: _vm.companies,
                                    "item-text": "name",
                                    multiple: "",
                                    label: "Company Access",
                                    outlined: "",
                                    "return-object": "",
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.selectId($event, "company")
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "py-0" },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.groups,
                              rules: [(v) => !!v || "Required!"],
                              "item-text": "displayName",
                              label: "Role",
                              outlined: "",
                              "return-object": "",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.selectId($event, "groups")
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "pt-0 hidden-xs-and-down" },
                        [_c("v-spacer")],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-0 mr-1",
                          attrs: { align: "center", cols: "3" },
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { plain: "", color: "primary" },
                              on: {
                                click: function ($event) {
                                  _vm.openDialog = false
                                },
                              },
                            },
                            [_vm._v("Cancel")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-0 mr-1",
                          attrs: { align: "center", cols: "3" },
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticStyle: { color: "black" },
                              attrs: { color: "primary", disabled: !_vm.valid },
                              on: { click: _vm.addNewUser },
                            },
                            [_vm._v("Submit")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }