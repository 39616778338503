<!-- Global loading component -->
<template>
  <v-progress-circular
    class="loader"
    color="primary"
    :size="70"
    :width="7"
    indeterminate
  ></v-progress-circular>
</template>

<script>
export default {
  name: 'Loader',
};
</script>

<style scoped>
/* centered global loader */
.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  height: auto;
  width: auto;
  z-index: 1000;
  transform: translate(-50%, -50%);
}
</style>
