<template>
  <v-container fluid>
    <v-card elevation="0" style="background-color: #272727">
      <v-card-title class="text-h5">Fleet</v-card-title>
      <v-skeleton-loader
        v-if="loading"
        style="padding: 17px"
        type="article, article, article, article, article "
      ></v-skeleton-loader>
      <div v-else class="fleetSettingContent">
        <!-- Temp: Hiding Preference -->
        <!-- <div class="setting">
        <div class="settingText">
          <div class="settingTitle">Fuel Type</div>
          <div class="settingDescription">
            Select the type of natural gas you are using. This will be used in
            the emissions calculations.
          </div>
        </div>
        <div class="fieldContainer">
          <v-btn-toggle v-model="ng_type" class="tfield">
            <v-btn
              value="CNG"
              :style="
                ng_type === 'CNG'
                  ? 'background-color: #2B8F14; color: black'
                  : ''
              "
              >CNG</v-btn
            >
            <v-btn
              value="RNG"
              :style="
                ng_type === 'RNG'
                  ? 'background-color: #2B8F14; color: black'
                  : ''
              "
              >RNG</v-btn
            >
          </v-btn-toggle>
        </div>
      </div> -->
        <div class="setting">
          <div class="settingText">
            <div class="settingTitle">Natural Gas Fuel Cost</div>
            <div class="settingDescription">
              Denote the average price you pay for Natural Gas. This is used to
              calculate fuel savings.
            </div>
          </div>
          <div class="fieldContainer">
            <v-text-field
              v-model="ng_price"
              :disabled="readOnly"
              outlined
              label="Price ($)"
              class="tfield"
            ></v-text-field>
          </div>
        </div>
        <div style="margin: 17px">
          <v-expansion-panels v-model="panels" flat>
            <v-expansion-panel style="background-color: #1a1a1a">
              <v-expansion-panel-header
                class="settingTitle"
                style="margin-left: -8px"
                >Natural Gas Energy Conversion Factor</v-expansion-panel-header
              >
              <v-expansion-panel-content
                class="settingDescription"
                style="margin-left: -8px"
              >
                Select whether your Natural Gas fuel cost is based on a DGE or
                GGE conversion.
                <v-radio-group v-model="esg_ge" :disabled="readOnly">
                  <div class="radioLine">
                    <v-radio key="0" value="dge">
                      <template v-slot:label>
                        <span class="settingDescription">
                          <strong>$ / DGE</strong><br />
                          Diesel Gallon Equivalent
                        </span>
                      </template>
                    </v-radio>
                  </div>
                  <div class="radioLine">
                    <v-radio key="1" value="gge">
                      <template v-slot:label>
                        <span class="settingDescription">
                          <strong>$ / GGE</strong><br />
                          Gasoline Gallon Equivalent
                        </span>
                      </template>
                    </v-radio>
                  </div>
                </v-radio-group>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
        <div class="setting">
          <div class="settingText">
            <div class="settingTitle">Comparison Fuel Used</div>
            <div class="settingDescription">
              Select the fuel you currently use from the drop down. This will be
              used as a comparison in emissions calculations.
            </div>
          </div>
          <div class="fieldContainer">
            <v-select
              v-model="esg_fuel_used"
              :disabled="readOnly"
              :items="fuelItems"
              outlined
              label="Fuel Used"
              class="tfield"
            ></v-select>
          </div>
        </div>
        <div class="setting">
          <div class="settingText">
            <div class="settingTitle">Comparison Fuel Cost ($/gal)</div>
            <div class="settingDescription">
              Enter the average price per gallon for the comparison fuel
              selected above. This will be used in fuel savings calculations.
            </div>
          </div>
          <div class="fieldContainer">
            <v-text-field
              v-model="esg_fuel_cost"
              :disabled="readOnly"
              outlined
              label="Price ($)"
              class="tfield"
            ></v-text-field>
          </div>
        </div>
        <div class="setting">
          <div class="settingText">
            <div class="settingTitle">Comparison Fuel MPG</div>
            <div class="settingDescription">
              Enter the average mpg for the comparison fuel selected above. This
              will be used in fuel savings calculations.
            </div>
          </div>
          <div class="fieldContainer">
            <v-text-field
              v-model="esg_fuel_mpg"
              :disabled="readOnly"
              outlined
              label="MPG"
              class="tfield"
            ></v-text-field>
          </div>
        </div>
        <div style="margin: 17px">
          <v-expansion-panels v-model="panels2" flat>
            <v-expansion-panel style="background-color: #1a1a1a">
              <v-expansion-panel-header
                class="settingTitle"
                style="margin-left: -8px"
                >Journey Definition</v-expansion-panel-header
              >
              <v-expansion-panel-content
                class="settingDescription"
                style="margin-left: -8px"
              >
                Select the definition of a journey that most aligns to your
                business model. If you change the selection here, the
                information presented in the Performance screen will update to
                align.
                <v-banner v-if="!hasGeozones" class="pl-0">
                  <v-icon slot="icon" color="#FB8C00" size="30">
                    mdi-help-circle-outline </v-icon
                  ><span class="journeySwitchText"
                    >Switching journey definitions not available</span
                  ><br />
                  <span class="learnMoreText" @click="journeySwitching = true"
                    >Learn More</span
                  ></v-banner
                >
                <v-radio-group v-model="journey_setting" :disabled="readOnly">
                  <div class="radioLine">
                    <v-radio key="0" value="automated">
                      <template v-slot:label>
                        <span class="settingDescription">
                          <strong>Automated</strong><br />
                          An automated journey ends when the ERX truck is
                          offline for more than 10 hours. This setting is most
                          appropriate for long-haul journeys that do not have a
                          consistently defined route.
                        </span>
                      </template>
                    </v-radio>
                  </div>
                  <div class="radioLine">
                    <v-radio
                      key="1"
                      value="destination"
                      :class="[!hasGeozones ? 'disabled' : '']"
                      :disabled="!hasGeozones"
                    >
                      <template v-slot:label>
                        <span class="settingDescription">
                          <strong>Destination</strong><br />
                          A destination journey starts when the ERX truck leaves
                          one geozone and ends when it enters another geozone.
                          This setting is most appropriate for journeys that
                          involve multiple pick-ups and drop-offs, such as
                          deliveries to different customers.
                        </span>
                      </template>
                    </v-radio>
                  </div>
                </v-radio-group>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
        <!-- Temp: Hiding Preference -->
        <!-- <div class="setting">
        <div class="settingText">
          <div class="settingTitle">Speed-Selective Cruise Control</div>
          <div class="settingDescription">
            This value represents the minimum speed required to be considered
            for the cruise control calculation. Any time spent below this speed
            will not contribute to the cruise control usage percentage.
          </div>
        </div>
        <div class="fieldContainer">
          <v-text-field
            v-model="ss_cruise_control"
            :disabled="readOnly"
            outlined
            label="Speed Threshold (MPH)"
            class="tfield"
          ></v-text-field>
        </div>
      </div> -->
        <div class="actionBtns">
          <v-btn
            :disabled="readOnly"
            style="margin-right: 20px; color: #2b8f14"
            @click="syncSettings"
            >Cancel</v-btn
          >
          <v-btn
            color="primary"
            :disabled="readOnly"
            style="color: black"
            @click="saveChanges"
            >Save changes</v-btn
          >
        </div>
        <div></div>
      </div>
    </v-card>
    <v-dialog v-model="journeySwitching" width="400">
      <v-card>
        <v-container>
          <v-row align="center" class="pl-4">
            <v-col cols="1"><v-icon>mdi-help-circle-outline</v-icon></v-col>
            <v-col align="left" cols="10">Journey Switching Unavailable</v-col>
          </v-row>
          <v-row>
            <v-col cols="1"><v-spacer /></v-col
            ><v-col>
              We cannot switch to Destination journey because you do not have
              any geozones created. Geozones are required for this journey
              definition.
            </v-col></v-row
          >

          <v-row class="my-2">
            <v-col class="hidden-xs-and-down"><v-spacer /></v-col>
            <v-col class="mr-2" align="center" cols="3"
              ><v-btn
                color="primary"
                style="color: black"
                @click="journeySwitching = false"
                >Close</v-btn
              ></v-col
            >
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {
  getCompanyPreferences,
  patchCompanyPreferences,
  getCompanyGeozones,
} from '@/api/external/company.js';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { permissions } from '@/api/permissions';

export default {
  name: 'FleetSettings',
  props: {
    companyId: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      // form models, in order
      ng_type: 'CNG', //Temp: Hidden
      ng_price: null,
      esg_ge: 'dge',
      esg_fuel_used: 'Diesel',
      esg_fuel_cost: null,
      esg_fuel_mpg: null,
      journey_setting: null,
      ss_cruise_control: null, //Temp: Hidden
      // end of form models
      panels: 0,
      panels2: 0,
      fuelItems: ['Diesel', 'Biodiesel B5'],
      // object detailing current fleet settings in DB
      dbSettings: {},
      hasGeozones: false,
      journeySwitching: false,
      readOnly: true,
      loading: true,
    };
  },
  computed: {
    ...mapGetters({
      userRoles: 'getUserRoles',
    }),
  },
  async mounted() {
    if (
      this.userRoles &&
      this.userRoles.includes(permissions.externalFleetConfig)
    ) {
      //Requires role for fleet edtiability
      this.readOnly = false;
    }
    try {
      await this.fetchSettings();
      await this.checkGeozones();
    } catch (e) {
      this.updateSnack({
        type: 'error',
        message: 'There was an issue fetching fleet settings',
      });
    }
    this.loading = false;
  },
  methods: {
    ...mapMutations(['assignCompanyPreferences', 'updateJourneyData']),
    ...mapActions(['updateSnack']),
    async fetchSettings() {
      const res = await getCompanyPreferences(this.companyId);
      this.dbSettings = res.data[0];
      this.syncSettings();
    },
    // sync model vars to dbSettings
    syncSettings() {
      this.ng_type = this.dbSettings?.ng_type;
      this.ng_price = this.dbSettings?.cng_price;
      this.esg_ge = this.dbSettings?.esg_ge;
      this.esg_fuel_used = this.dbSettings?.esg_fuel_used;
      this.esg_fuel_cost = this.dbSettings?.esg_fuel_cost;
      this.esg_fuel_mpg = this.dbSettings?.esg_fuel_mpg;
      this.journey_setting = this.dbSettings?.journey_setting || 'automated'; // Default to automated if no selection is present from fleet
      this.ss_cruise_control = this.dbSettings?.ss_cruise_control;
    },
    async checkGeozones() {
      let res = await getCompanyGeozones(this.companyId, true);
      if (res.data.length) {
        this.hasGeozones = true;
      }
    },
    async saveChanges() {
      try {
        const new_settings = this.buildNewSettings();
        await patchCompanyPreferences(new_settings);
        this.dbSettings = new_settings;
        if (new_settings?.journey_setting) {
          this.assignCompanyPreferences(new_settings); // Temp: does not give all permissions needed to store at the moment
          this.updateJourneyData([]); // Resets journey data
        }
        this.updateSnack({
          type: 'success',
          message: 'Successfully update fleet settings!',
        });
      } catch {
        this.updateSnack({
          type: 'error',
          message: 'There was an error updating fleet settings',
        });
      }
    },
    // build new settings object, shouldn't have null values
    buildNewSettings() {
      let new_settings = { company_id: this.companyId };
      if (this.ng_type) new_settings.ng_type = this.ng_type;
      if (this.ng_price) new_settings.cng_price = this.ng_price;
      if (this.esg_ge) new_settings.esg_ge = this.esg_ge;
      if (this.esg_fuel_used) new_settings.esg_fuel_used = this.esg_fuel_used;
      if (this.esg_fuel_cost) new_settings.esg_fuel_cost = this.esg_fuel_cost;
      if (this.esg_fuel_mpg) new_settings.esg_fuel_mpg = this.esg_fuel_mpg;
      if (this.journey_setting)
        new_settings.journey_setting = this.journey_setting;
      if (this.ss_cruise_control)
        new_settings.ss_cruise_control = this.ss_cruise_control;
      return new_settings;
    },
  },
};
</script>

<style scoped>
.setting {
  background-color: #1a1a1a;
  margin: 17px;
  padding: 17px;
  border-radius: 3px;
  display: flex;
  align-items: center;
}
.settingText {
  width: 85%;
}
.settingDescription {
  display: block;
  color: var(--dark-main-color, #e0e0e0);
  /* 16 sp • Body 1 */
  font-family: Rawline;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.44px;
}
.tfield {
  margin-left: 5px;
  margin-bottom: -30px;
}
.radioLine {
  /* display: flex; */
  margin-bottom: 15px;
}
.actionBtns {
  padding: 17px;
}

.disabled {
  opacity: 0.5;
}
.journeySwitchText {
  color: #fb8c00;
  font-weight: bold;
}

.learnMoreText {
  color: #43b02a;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}
::v-deep .v-banner__wrapper {
  padding-left: 0px !important;
  padding-top: 16px !important;
  padding-bottom: 16px !important;
  border-left: thin solid rgba(255, 255, 255, 0.12);
  border-bottom: none !important;
}
::v-deep .v-avatar.v-banner__icon {
  margin-right: 0px;
  color: green !important;
}
</style>
