<template>
  <v-dialog v-model="openDialog" class="dialog" width="460">
    <loader v-if="loading" />
    <v-card>
      <v-container>
        <v-row align="center" class="pl-4">
          <v-col align="left" cols="6"><h3>Change Truck Name</h3></v-col>
          <v-col><v-spacer /></v-col>
          <v-col align="right" cols="2">
            <v-btn icon @click="openDialog = false">
              <v-icon light>mdi-close-circle </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="ma-2">
          <v-col align="center" cols="12">
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field
                v-model="truckName"
                outlined
                :rules="[rules.required]"
                label="Truck Name"
                required
              ></v-text-field>
            </v-form>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0 hidden-xs-and-down"><v-spacer /></v-col>
          <v-col class="pt-0 mr-1" align="center" cols="3"
            ><v-btn plain color="primary" @click="openDialog = false"
              >Cancel</v-btn
            ></v-col
          >
          <v-col class="pt-0 mr-1" align="center" cols="3"
            ><v-btn
              color="primary"
              style="color: black"
              :disabled="!valid || loading"
              @click="changeTruckName"
              >Submit</v-btn
            ></v-col
          >
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import loader from '@/utilities/loader';
import { updateTruckName } from '@/api/external/truck';

import { mapActions } from 'vuex';
export default {
  name: 'TruckNameChange',
  components: { loader },
  props: {
    truckId: {
      required: true,
      type: Number,
    },
    companyId: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      openDialog: false,
      truckName: null,
      valid: false,
      loading: false,
      rules: {
        required: (value) => !!value || 'Field is required',
      },
    };
  },
  computed: {},
  watch: {
    // Watches for the change in openDialog variable to emit the false variable back to parent to reset
    openDialog: function (newValue) {
      if (!newValue) {
        this.openDialog = false;
        this.$emit('close', false);
      }
    },
  },
  async mounted() {
    this.openDialog = true;
  },
  methods: {
    ...mapActions({
      updateSnack: 'updateSnack',
    }),
    async changeTruckName() {
      try {
        // Checks validation
        if (!this.$refs.form.validate()) {
          return;
        }
        this.loading = true;
        let body = {
          truck_id: this.truckId,
          company_id: this.companyId,
          truck_name: this.truckName,
        };
        await updateTruckName(body);
        this.alertResponse('success', 'Successfully updated truck name!', body);
      } catch {
        this.alertResponse(
          'error',
          'Something went wrong, please try again later!'
        );
      }
    },
    alertResponse(type, message, data = null) {
      this.loading = false;
      this.updateSnack({
        type,
        message,
      });
      if (type !== 'error') this.$emit('close', data);
    },
  },
};
</script>

<style scoped></style>
