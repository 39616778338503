<template>
  <v-dialog v-model="openDialog" class="dialog" width="750">
    <loader v-if="loading" />
    <alert
      v-if="showAlert"
      :alert-type="alertType"
      :alert-message="alertMessage"
    />
    <v-card class="pa-4">
      <v-file-input
        type="file"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        truncate-length="30"
        label="Upload DTC File"
        @change="selectDTCFile"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import * as XLSX from 'xlsx';

import { upsertDTCs } from '../../api/dataDictionary';

import loader from '../../utilities/loader';
import alert from '../../utilities/alert';
export default {
  name: 'UploadDTCS',
  components: { loader, alert },
  props: {},
  data() {
    return {
      openDialog: false,
      loading: false,
      showAlert: false,
      alertType: '',
      alertMessage: '',
    };
  },
  watch: {
    // Watches for the change in openDialog variable to emit the false variable back to parent to reset
    openDialog: function (newValue) {
      if (!newValue) {
        this.openDialog = false;
        this.$emit('closed', false);
      }
    },
  },
  mounted() {
    this.openDialog = true;
  },
  methods: {
    async selectDTCFile(file) {
      this.loading = true;
      try {
        if (file) {
          const res = await this.formatFile(file);
          const dtcs = [];
          let ecu;
          // Reset Code
          dtcs.push({
            dtc_hex: '0x00000000',
            priority: '',
            ecu: 'Hyliion System',
            component: 'Hyliion System',
            failure_mode: 'Clear All DTCs',
            hmi_string: 'Clear All DTCs',
            proposed_driver_alert_string: '',
            diagnostic_type: '',
            diagnostic_unit: '',
            threshold: '',
            fail_timers: '',
            fail_count_limit: 0,
            trip_count_limit: 0,
          });

          res.forEach((f, i) => {
            if (i > 2) {
              if (f[3]) {
                ecu = f[3];
              }
              dtcs.push({
                dtc_hex: f[1],
                priority: f[2],
                ecu: f[3] ? f[3] : ecu,
                component: f[4],
                failure_mode: f[5],
                hmi_string: f[6],
                proposed_driver_alert_string: f[7],
                diagnostic_type: f[8],
                diagnostic_unit: f[9] ? f[9] : '',
                threshold: f[10] ? f[10] : '',
                fail_timers: f[11],
                fail_count_limit: parseInt(f[12]) ? parseInt(f[12]) : 0,
                trip_count_limit: parseInt(f[13]) ? parseInt(f[13]) : 0,
              });
            }
          });
          await upsertDTCs(dtcs);
          this.updateComponents('success', 'DTC File Uploaded Successfully!');
          this.alertTimeout(true);
        }
      } catch (e) {
        this.updateComponents(
          'error',
          'There was an issue uploading DTC file!'
        );
        this.alertTimeout(true);
      }
    },
    async formatFile(file) {
      return new Promise((resolve) => {
        const reader = new FileReader();

        reader.onload = (e) => {
          /* Parse data */
          const bstr = e.target.result;
          const wb = XLSX.read(bstr, { type: 'binary' });
          /* Get worksheet */
          const wsname = wb.SheetNames[1]; // GET 1.65 HMI String List
          const ws = wb.Sheets[wsname];
          /* Convert array of arrays */
          resolve(XLSX.utils.sheet_to_json(ws, { header: 1 }));
        };
        reader.readAsBinaryString(file);
      });
    },
    updateComponents(alertType, alertMessage) {
      this.showAlert = true;
      this.loading = false;
      this.alertType = alertType;
      this.alertMessage = alertMessage;
    },
    alertTimeout(closeDialog) {
      setTimeout(() => {
        this.showAlert = false;
        if (closeDialog) this.openDialog = false;
      }, 2000);
    },
  },
};
</script>
<style scoped>
.dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  height: auto;
  width: auto;
  z-index: 1;
  transform: translate(-50%, -50%);
}
</style>
