import axios from 'axios';
const BASE_URL = process.env.VUE_APP_API_PATH_BASE;

async function getPref(appId) {
  return await axios.get(BASE_URL + `external/user_management/appId/${appId}`);
}

async function putPref(appId, prefVisible) {
  await axios.put(
    BASE_URL + `external/user_management/appId/${appId}`,
    prefVisible
  );
}

async function lastSeen() {
  return await axios.put(BASE_URL + `external/user_management/last_seen`);
}

async function acceptTAC() {
  return await axios.put(BASE_URL + `external/user_management/accept_tac`);
}

export { getPref, putPref, lastSeen, acceptTAC };
