var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "exTable" } },
    [
      _c(
        "v-navigation-drawer",
        {
          staticClass: "shade",
          attrs: { right: "", temporary: "", fixed: "", width: "360" },
          model: {
            value: _vm.drawer,
            callback: function ($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer",
          },
        },
        [
          _c(
            "v-row",
            { staticClass: "my-2 pl-5 pr-3", attrs: { align: "center" } },
            [
              _c("v-col", { attrs: { align: "left", cols: "9" } }, [
                _vm._v("Customize Columns:"),
              ]),
              _c(
                "v-col",
                { attrs: { align: "right", cols: "3" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function ($event) {
                          _vm.drawer = false
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { light: "" } }, [
                        _vm._v("mdi-close-circle "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  [
                    _c(
                      "v-list",
                      { staticClass: "drawer-list" },
                      [
                        _c("v-subheader", [
                          _vm._v(
                            "Select to display Columns and Drag to relocate"
                          ),
                        ]),
                        _c(
                          "v-list-item-group",
                          {
                            attrs: { multiple: "" },
                            model: {
                              value: _vm.labels,
                              callback: function ($$v) {
                                _vm.labels = $$v
                              },
                              expression: "labels",
                            },
                          },
                          [
                            _c(
                              "draggable",
                              _vm._b(
                                {
                                  on: { change: _vm.dragLabels },
                                  model: {
                                    value: _vm.healthLabelsDup,
                                    callback: function ($$v) {
                                      _vm.healthLabelsDup = $$v
                                    },
                                    expression: "healthLabelsDup",
                                  },
                                },
                                "draggable",
                                _vm.dragOptions,
                                false
                              ),
                              [
                                _vm._l(
                                  _vm.healthLabelsDup,
                                  function (column, index) {
                                    return [
                                      column.text.length &&
                                      column.text !== "Actions"
                                        ? _c(
                                            "v-list-item",
                                            {
                                              key: column.value,
                                              attrs: {
                                                disabled: !column.hideable,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.updateColumn(
                                                    column,
                                                    index
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-list-item-action",
                                                { staticClass: "mr-5" },
                                                [
                                                  !column.visible
                                                    ? _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color: "grey",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " mdi-square-outline "
                                                          ),
                                                        ]
                                                      )
                                                    : _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color: "primary",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " mdi-checkbox-marked "
                                                          ),
                                                        ]
                                                      ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  _c(
                                                    "v-list-item-title",
                                                    {
                                                      staticClass: "light-text",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.checkUserPrefs(
                                                            column
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-list-item-action",
                                                [
                                                  !column.hideable
                                                    ? _c(
                                                        "v-icon",
                                                        {
                                                          staticClass:
                                                            "light-text",
                                                        },
                                                        [_vm._v(" mdi-lock ")]
                                                      )
                                                    : _c(
                                                        "v-icon",
                                                        {
                                                          staticClass:
                                                            "light-text",
                                                        },
                                                        [_vm._v(" mdi-menu ")]
                                                      ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  }
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("v-divider"),
                    _c(
                      "v-row",
                      { staticClass: "pt-6" },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "py-0 pr-0 pl-7",
                            attrs: { cols: "5" },
                          },
                          [_c("v-spacer")],
                          1
                        ),
                        _c(
                          "v-col",
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: { color: "primary", outlined: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.resetColumns()
                                  },
                                },
                              },
                              [_vm._v("Reset Default")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { staticClass: "pa-0" },
            [
              _c("v-col", { staticClass: "pa-0" }, [
                _vm._v(_vm._s(_vm.truckData.length) + " results"),
              ]),
              _c(
                "v-col",
                { staticClass: "pa-0", attrs: { align: "right" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function ($event) {
                          _vm.drawer = !_vm.drawer
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi mdi-dots-vertical")])],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "pa-0", attrs: { cols: "12" } },
                [
                  _vm.loading
                    ? _c("v-skeleton-loader", {
                        attrs: { type: "table", elevation: "2" },
                      })
                    : _c("v-data-table", {
                        staticClass:
                          "elevation-1 secondary locked-table-style row-height-50",
                        attrs: {
                          headers: _vm.healthLabels,
                          items: _vm.truckData,
                          search: _vm.searchText,
                          "item-key": "id",
                          "custom-sort": _vm.healthTableCustomSort,
                        },
                        on: { "click:row": _vm.setTruckDetails },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: `item.name`,
                              fn: function ({ item }) {
                                return [
                                  _c("th", { staticClass: "body-5" }, [
                                    _vm._v(_vm._s(item.number)),
                                  ]),
                                  _c("div", { staticClass: "body-6" }, [
                                    _vm._v(_vm._s(item.company)),
                                  ]),
                                ]
                              },
                            },
                            _vm._l(_vm.healthLabels, function (h) {
                              return {
                                key: `header.${h.value}`,
                                fn: function ({ header }) {
                                  return [
                                    h.value === "address"
                                      ? _c(
                                          "v-tooltip",
                                          {
                                            key: h.text,
                                            attrs: { right: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function ({ on }) {
                                                    return [
                                                      _c(
                                                        "span",
                                                        { key: h.text },
                                                        [
                                                          _vm._v(
                                                            _vm._s(header.text)
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          {
                                                            staticStyle: {
                                                              "padding-left":
                                                                "4px",
                                                              color: "#979797",
                                                            },
                                                            attrs: {
                                                              small: "",
                                                            },
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _vm._v(
                                                            "mdi-information-outline"
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [
                                            _c("b", [
                                              _vm._v("Color Representations:"),
                                            ]),
                                            _c("br"),
                                            _c("b", [_vm._v("GREEN:")]),
                                            _vm._v(
                                              " GPS timestamps from HMI and Kontron Match "
                                            ),
                                            _c("br"),
                                            _c("b", [_vm._v("YELLOW:")]),
                                            _vm._v(
                                              " GPS timestamps from HMI and Kontron are inconsistent "
                                            ),
                                            _c("br"),
                                            _c("b", [_vm._v("RED:")]),
                                            _vm._v(
                                              " GPS from either HMI or Kontron are not working "
                                            ),
                                            _c("br"),
                                          ]
                                        )
                                      : h.value === "last_error_shut_down"
                                      ? _c(
                                          "v-tooltip",
                                          {
                                            key: h.value,
                                            attrs: { right: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function ({ on }) {
                                                    return [
                                                      _c(
                                                        "span",
                                                        { key: h.text },
                                                        [
                                                          _vm._v(
                                                            _vm._s(header.text)
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          {
                                                            staticStyle: {
                                                              "padding-left":
                                                                "4px",
                                                              color: "#979797",
                                                            },
                                                            attrs: {
                                                              small: "",
                                                            },
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _vm._v(
                                                            "mdi-information-outline"
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [
                                            _c("b", [
                                              _vm._v(
                                                "Red text indicates that the truck has most likely had Multiple restarts within a short period of time"
                                              ),
                                            ]),
                                          ]
                                        )
                                      : header.hasOptions
                                      ? [
                                          _c("span", { key: h.text }, [
                                            _vm._v(
                                              _vm._s(_vm.checkUserPrefs(header))
                                            ),
                                          ]),
                                        ]
                                      : [
                                          _c("span", { key: h.text }, [
                                            _vm._v(_vm._s(header.text)),
                                          ]),
                                        ],
                                  ]
                                },
                              }
                            }),
                            {
                              key: `item.status`,
                              fn: function ({ item }) {
                                return [
                                  _c(
                                    "td",
                                    {
                                      staticClass: "text-xs-center narrow-col",
                                    },
                                    [
                                      _c(
                                        "button",
                                        { class: _vm.statusColor(item.status) },
                                        [
                                          _vm._v(
                                            " " + _vm._s(item.status) + " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                            {
                              key: `item.dtc`,
                              fn: function ({ item }) {
                                return [
                                  _c(
                                    "td",
                                    {
                                      staticClass: "text-right",
                                      class: _vm.dtcColor(item.dtc, "dtc"),
                                    },
                                    [_vm._v(" " + _vm._s(item.dtc) + " ")]
                                  ),
                                ]
                              },
                            },
                            {
                              key: `item.pre_dtc`,
                              fn: function ({ item }) {
                                return [
                                  _c(
                                    "td",
                                    {
                                      staticClass: "text-right",
                                      class: _vm.dtcColor(
                                        item.pre_dtc,
                                        "pre_dtc"
                                      ),
                                    },
                                    [_vm._v(" " + _vm._s(item.pre_dtc) + " ")]
                                  ),
                                ]
                              },
                            },
                            {
                              key: `item.address`,
                              fn: function ({ item }) {
                                return [
                                  _c(
                                    "td",
                                    {
                                      class: _vm.getTimeDiff(
                                        item.position_time,
                                        item.timeUpdatedRaw
                                      ),
                                    },
                                    [_vm._v(" " + _vm._s(item.address) + " ")]
                                  ),
                                ]
                              },
                            },
                            {
                              key: `item.last_error_shut_down`,
                              fn: function ({ item }) {
                                return [
                                  _c(
                                    "td",
                                    { class: _vm.checkMatchTimes(item) },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(item.last_error_shut_down) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                            {
                              key: `item.actions`,
                              fn: function ({ item }) {
                                return [
                                  _c(
                                    "v-menu",
                                    {
                                      staticStyle: {
                                        top: "312px",
                                        left: "300px",
                                      },
                                      attrs: { "offset-y": "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on }) {
                                              return [
                                                _c("v-icon", _vm._g({}, on), [
                                                  _vm._v("mdi-dots-vertical"),
                                                ]),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _c(
                                        "v-list",
                                        _vm._l(
                                          _vm.actionItems,
                                          function (route, index) {
                                            return _c(
                                              "v-list-item",
                                              {
                                                key: index,
                                                class: route.title,
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.setRoute(
                                                      item,
                                                      route.title
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(_vm._s(route.title))]
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }