import axios from 'axios';

const BASE_URL = process.env.VUE_APP_API_PATH_BASE;

async function getMillion(tz) {
  let queryString = tz ? `?tz=${tz}` : '';
  const res = await axios.get(BASE_URL + `million${queryString}`);
  return res.data;
}

async function getOdometer() {
  const res = await axios.get(BASE_URL + 'million/odometer');
  return res.data[0].sum_totaldistancemiles;
}

async function getMillionGraph() {
  const res = await axios.get(BASE_URL + 'million/graph');
  return res.data;
}

async function getCategories() {
  const res = await axios.get(BASE_URL + 'million/categories');
  return res.data;
}

async function getOffset() {
  const res = await axios.get(BASE_URL + 'million/offset');
  return res.data[0].offset_miles;
}
async function updateOffset(new_offset) {
  // remove commas from input
  new_offset = parseInt(String(new_offset).split(',').join(''));
  const body = { offset: new_offset };
  const res = await axios.put(BASE_URL + 'million/offset', body);
  return res.data;
}

export {
  getMillion,
  getMillionGraph,
  getCategories,
  getOffset,
  updateOffset,
  getOdometer,
};
