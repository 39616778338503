var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pa-0", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { align: "center" } },
        [
          _c(
            "v-btn",
            {
              style: _vm.cogStyle,
              attrs: { icon: "", tile: "", large: "" },
              on: {
                click: function ($event) {
                  return _vm.openDrawerCogButton()
                },
              },
            },
            [
              _c("v-icon", { staticStyle: { opacity: "0.6" } }, [
                _vm._v("mdi-cog"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "data", attrs: { id: "id-td-MapView" } }, [
        _c("div", { attrs: { id: "map" } }, [
          _c("div", {
            ref: "hereMap",
            style: `height: ${_vm.mapHeight}px; width: 100%`,
            attrs: { id: "mapContainer" },
          }),
        ]),
      ]),
      _c(
        "v-navigation-drawer",
        {
          staticClass: "shade",
          attrs: { fixed: "", persistent: "", right: "", width: "360" },
          model: {
            value: _vm.listDrawer,
            callback: function ($$v) {
              _vm.listDrawer = $$v
            },
            expression: "listDrawer",
          },
        },
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                { attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "mb-2", attrs: { align: "right" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function ($event) {
                              _vm.listDrawer = false
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { light: "" } }, [
                            _vm._v("mdi-close-circle "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.showList == true
                ? _c(
                    "div",
                    [
                      _c("h1", [_vm._v("Geo-Zones")]),
                      _c("div", { staticClass: "my-2" }),
                      _c(
                        "v-tabs",
                        {
                          attrs: {
                            "fixed-tabs": "",
                            "background-color": "#272727",
                            dark: "",
                          },
                          model: {
                            value: _vm.tab,
                            callback: function ($$v) {
                              _vm.tab = $$v
                            },
                            expression: "tab",
                          },
                        },
                        _vm._l(_vm.tabItems, function (item) {
                          return _c("v-tab", { key: item.tab }, [
                            _vm._v(" " + _vm._s(item.tab) + " "),
                          ])
                        }),
                        1
                      ),
                      _c(
                        "v-tabs-items",
                        {
                          model: {
                            value: _vm.tab,
                            callback: function ($$v) {
                              _vm.tab = $$v
                            },
                            expression: "tab",
                          },
                        },
                        _vm._l(_vm.tabItems, function (item) {
                          return _c(
                            "v-tab-item",
                            { key: item.tab },
                            [
                              _c("v-card", { attrs: { flat: "" } }, [
                                item.content === "list"
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "v-container",
                                          { staticClass: "shade" },
                                          [
                                            _c("v-select", {
                                              attrs: {
                                                items: _vm.companies,
                                                name: "company",
                                                "item-text": "company_name",
                                                label: "Select Company",
                                                "return-object": "",
                                                outlined: "",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  _vm.populateTable()
                                                  _vm.getList()
                                                },
                                              },
                                              model: {
                                                value: _vm.companySelected,
                                                callback: function ($$v) {
                                                  _vm.companySelected = $$v
                                                },
                                                expression: "companySelected",
                                              },
                                            }),
                                            _c("p", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.geoZonesSelectedList
                                                    .length
                                                ) + " Results"
                                              ),
                                            ]),
                                            _vm.geoZonesSelectedList.length ==
                                              0 && _vm.companySelected != null
                                              ? _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      "v-container",
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            staticClass:
                                                              "d-flex justify-center py-10",
                                                            staticStyle: {
                                                              "font-size":
                                                                "50px",
                                                              color: "#2196f3",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "mdi-alert-circle "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "p",
                                                          {
                                                            attrs: {
                                                              "text-align:": "",
                                                              center: "",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "No Active Geo-Zones!"
                                                            ),
                                                          ]
                                                        ),
                                                        _c("p", [
                                                          _vm._v(
                                                            " They company you selected has no active Geo-Zones to view in the list. "
                                                          ),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      "v-row",
                                                      { staticClass: "my-0" },
                                                      [
                                                        _c(
                                                          "v-col",
                                                          [
                                                            _c("v-data-table", {
                                                              staticClass:
                                                                "elevation-0 shade",
                                                              attrs: {
                                                                headers:
                                                                  _vm.geoZonesLabels,
                                                                items:
                                                                  _vm.geoZonesSelectedList,
                                                                "items-per-page": 10,
                                                              },
                                                              on: {
                                                                "click:row":
                                                                  _vm.handleClickRow,
                                                              },
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: `item.edit`,
                                                                      fn: function ({
                                                                        item,
                                                                      }) {
                                                                        return [
                                                                          item.is_creator ==
                                                                          true
                                                                            ? _c(
                                                                                "v-btn",
                                                                                {
                                                                                  staticStyle:
                                                                                    {
                                                                                      "margin-right":
                                                                                        "0.5rem",
                                                                                      "background-color":
                                                                                        "#272727",
                                                                                      "border-radius":
                                                                                        "5px",
                                                                                    },
                                                                                  attrs:
                                                                                    {
                                                                                      icon: "",
                                                                                      tile: "",
                                                                                      large:
                                                                                        "",
                                                                                    },
                                                                                  on: {
                                                                                    click:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.listTabPencilButton(
                                                                                          item
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-icon",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "mdi-pencil"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              )
                                                                            : _c(
                                                                                "v-btn",
                                                                                {
                                                                                  staticStyle:
                                                                                    {
                                                                                      "margin-right":
                                                                                        "0.5rem",
                                                                                      "background-color":
                                                                                        "#272727",
                                                                                      "border-radius":
                                                                                        "5px",
                                                                                    },
                                                                                  attrs:
                                                                                    {
                                                                                      icon: "",
                                                                                      tile: "",
                                                                                      large:
                                                                                        "",
                                                                                    },
                                                                                  on: {
                                                                                    click:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.listTabPencilButton(
                                                                                          item
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-icon",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "mdi-lock"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                        ]
                                                                      },
                                                                    },
                                                                  ],
                                                                  null,
                                                                  true
                                                                ),
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                item.content === "create"
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "v-container",
                                          { staticClass: "shade" },
                                          [
                                            _c(
                                              "v-form",
                                              {
                                                ref: "form",
                                                refInFor: true,
                                                attrs: {
                                                  "lazy-validation": "",
                                                },
                                                model: {
                                                  value: _vm.valid,
                                                  callback: function ($$v) {
                                                    _vm.valid = $$v
                                                  },
                                                  expression: "valid",
                                                },
                                              },
                                              [
                                                _c("v-select", {
                                                  attrs: {
                                                    items: _vm.companies,
                                                    name: "company",
                                                    "item-text": "company_name",
                                                    label: "Select Company",
                                                    "return-object": "",
                                                    outlined: "",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      _vm.populateTable()
                                                      _vm.getList()
                                                    },
                                                  },
                                                  model: {
                                                    value: _vm.companySelected,
                                                    callback: function ($$v) {
                                                      _vm.companySelected = $$v
                                                    },
                                                    expression:
                                                      "companySelected",
                                                  },
                                                }),
                                                _vm.companySelected == "All" ||
                                                _vm.companySelected == null
                                                  ? _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          "v-container",
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                staticClass:
                                                                  "d-flex justify-center py-10",
                                                                staticStyle: {
                                                                  "font-size":
                                                                    "50px",
                                                                  color: "red",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "mdi-alert-octagon "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "p",
                                                              {
                                                                attrs: {
                                                                  "text-align:":
                                                                    "",
                                                                  center: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "No Company selected!"
                                                                ),
                                                              ]
                                                            ),
                                                            _c("p", [
                                                              _vm._v(
                                                                " Select a company from the company dropdown above to create a Geo-zone. "
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.companySelected != "All" &&
                                                _vm.companySelected != null
                                                  ? _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          "v-radio-group",
                                                          {
                                                            model: {
                                                              value: _vm.shape,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.shape =
                                                                    $$v
                                                                },
                                                              expression:
                                                                "shape",
                                                            },
                                                          },
                                                          [
                                                            _c("v-radio", {
                                                              attrs: {
                                                                label:
                                                                  "Circle Geo-Zone",
                                                                value: "circle",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _vm.shape == null &&
                                                        _vm.companySelected !=
                                                          "All" &&
                                                        _vm.companySelected !=
                                                          null
                                                          ? _c(
                                                              "v-sheet",
                                                              {
                                                                staticClass:
                                                                  "my-10",
                                                                attrs: {
                                                                  color:
                                                                    "grey darken-3",
                                                                  elevation:
                                                                    "1",
                                                                  height: "140",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-container",
                                                                  {
                                                                    staticClass:
                                                                      "black",
                                                                  },
                                                                  [
                                                                    _c("p", [
                                                                      _vm._v(
                                                                        "How to Create a Zone"
                                                                      ),
                                                                    ]),
                                                                    _c("p", [
                                                                      _vm._v(
                                                                        " Click and drag on the map to create a circle area then increase or decrease the size of the Geo-Zone area you want. "
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e(),
                                                _vm.shape == "circle" &&
                                                _vm.companySelected != "All" &&
                                                _vm.companySelected != null
                                                  ? _c(
                                                      "div",
                                                      [
                                                        _c("p", [
                                                          _vm._v("Circle Zone"),
                                                        ]),
                                                        _c("v-text-field", {
                                                          attrs: {
                                                            rules: [
                                                              (v) =>
                                                                !!v ||
                                                                "Required!",
                                                            ],
                                                            label: "Name",
                                                            outlined: "",
                                                            required: "",
                                                          },
                                                          model: {
                                                            value: _vm.name,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.name = $$v
                                                            },
                                                            expression: "name",
                                                          },
                                                        }),
                                                        _c("v-text-field", {
                                                          attrs: {
                                                            label:
                                                              "Description",
                                                            outlined: "",
                                                            required: "",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.description,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.description =
                                                                $$v
                                                            },
                                                            expression:
                                                              "description",
                                                          },
                                                        }),
                                                        _c("v-text-field", {
                                                          attrs: {
                                                            rules: [
                                                              (v) =>
                                                                !!v ||
                                                                "Required!",
                                                            ],
                                                            disabled:
                                                              _vm.is_service_station,
                                                            label: "Latitude",
                                                            outlined: "",
                                                            required: "",
                                                          },
                                                          model: {
                                                            value: _vm.latitude,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.latitude =
                                                                _vm._n($$v)
                                                            },
                                                            expression:
                                                              "latitude",
                                                          },
                                                        }),
                                                        _c("v-text-field", {
                                                          attrs: {
                                                            rules: [
                                                              (v) =>
                                                                !!v ||
                                                                "Required!",
                                                            ],
                                                            disabled:
                                                              _vm.is_service_station,
                                                            label: "Longitude",
                                                            outlined: "",
                                                            required: "",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.longitude,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.longitude =
                                                                _vm._n($$v)
                                                            },
                                                            expression:
                                                              "longitude",
                                                          },
                                                        }),
                                                        _c("v-text-field", {
                                                          attrs: {
                                                            rules: [
                                                              (v) =>
                                                                !!v ||
                                                                "Required!",
                                                            ],
                                                            label:
                                                              "Radius in Meters",
                                                            outlined: "",
                                                            required: "",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.radius_meters,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.radius_meters =
                                                                _vm._n($$v)
                                                            },
                                                            expression:
                                                              "radius_meters",
                                                          },
                                                        }),
                                                        _c("v-checkbox", {
                                                          attrs: {
                                                            label:
                                                              "Set as Landmark",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.is_landmark,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.is_landmark =
                                                                $$v
                                                            },
                                                            expression:
                                                              "is_landmark",
                                                          },
                                                        }),
                                                        _c("v-checkbox", {
                                                          attrs: {
                                                            label:
                                                              "Service Station",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.is_service_station,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.is_service_station =
                                                                $$v
                                                            },
                                                            expression:
                                                              "is_service_station",
                                                          },
                                                        }),
                                                        _vm.is_service_station
                                                          ? [
                                                              _c(
                                                                "v-text-field",
                                                                {
                                                                  attrs: {
                                                                    label:
                                                                      "Service Station Name",
                                                                    rules: [
                                                                      (v) =>
                                                                        !!v ||
                                                                        "Required!",
                                                                    ],
                                                                    outlined:
                                                                      "",
                                                                    required:
                                                                      "",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .service_station
                                                                        .name,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.service_station,
                                                                          "name",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "service_station.name",
                                                                  },
                                                                }
                                                              ),
                                                              _c(
                                                                "v-text-field",
                                                                {
                                                                  attrs: {
                                                                    label:
                                                                      "Service Station Address",
                                                                    rules: [
                                                                      (v) =>
                                                                        !!v ||
                                                                        "Required!",
                                                                    ],
                                                                    outlined:
                                                                      "",
                                                                    required:
                                                                      "",
                                                                  },
                                                                  on: {
                                                                    change:
                                                                      _vm.searchCoords,
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .service_station
                                                                        .address,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.service_station,
                                                                          "address",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "service_station.address",
                                                                  },
                                                                }
                                                              ),
                                                              _c("v-textarea", {
                                                                attrs: {
                                                                  label:
                                                                    "Additional Info",
                                                                  outlined: "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .service_station
                                                                      .additional_info,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.service_station,
                                                                        "additional_info",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "service_station.additional_info",
                                                                },
                                                              }),
                                                              _c(
                                                                "v-text-field",
                                                                {
                                                                  attrs: {
                                                                    label:
                                                                      "Network Name",
                                                                    rules: [
                                                                      (v) =>
                                                                        !!v ||
                                                                        "Required!",
                                                                    ],
                                                                    outlined:
                                                                      "",
                                                                    required:
                                                                      "",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .service_station
                                                                        .network
                                                                        .name,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm
                                                                            .service_station
                                                                            .network,
                                                                          "name",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "service_station.network.name",
                                                                  },
                                                                }
                                                              ),
                                                              _c("v-textarea", {
                                                                attrs: {
                                                                  label:
                                                                    "Network Description",
                                                                  outlined: "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .service_station
                                                                      .network
                                                                      .description,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .service_station
                                                                          .network,
                                                                        "description",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "service_station.network.description",
                                                                },
                                                              }),
                                                            ]
                                                          : _vm._e(),
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            staticClass:
                                                              "center",
                                                            attrs: {
                                                              color: "primary",
                                                              disabled:
                                                                !_vm.valid,
                                                              block: "",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.createTabAddButton()
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("Add")]
                                                        ),
                                                      ],
                                                      2
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm.showList == false
                ? _c(
                    "div",
                    [
                      _c(
                        "v-container",
                        [
                          _c("h1", [_vm._v("Edit Circle Zone")]),
                          _c("div", { staticClass: "my-5" }),
                          _c(
                            "div",
                            { staticClass: "text-right" },
                            [
                              _c(
                                "v-dialog",
                                {
                                  attrs: { width: "500" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function ({ on, attrs }) {
                                        return [
                                          _vm.is_creator == true
                                            ? _c(
                                                "v-btn",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass:
                                                        "primary--text",
                                                      attrs: {
                                                        color: "#272727",
                                                        dark: "",
                                                        elevation: "0",
                                                      },
                                                    },
                                                    "v-btn",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [_vm._v(" DELETE ")]
                                              )
                                            : _c(
                                                "v-btn",
                                                {
                                                  staticClass: "white--text",
                                                  attrs: {
                                                    color: "#757575",
                                                    dark: "",
                                                    elevation: "0",
                                                  },
                                                },
                                                [_vm._v(" DELETE ")]
                                              ),
                                        ]
                                      },
                                    },
                                  ]),
                                  model: {
                                    value: _vm.mainDrawerDeleteDialog,
                                    callback: function ($$v) {
                                      _vm.mainDrawerDeleteDialog = $$v
                                    },
                                    expression: "mainDrawerDeleteDialog",
                                  },
                                },
                                [
                                  _c(
                                    "v-card",
                                    [
                                      _c(
                                        "v-card-title",
                                        { staticClass: "text-h5" },
                                        [_vm._v(" Delete Circle Zone ")]
                                      ),
                                      _vm.delete_allowed == true
                                        ? _c(
                                            "div",
                                            [
                                              _c("v-card-text", [
                                                _vm._v(
                                                  " Are you sure you want to delete the selected zone? "
                                                ),
                                              ]),
                                            ],
                                            1
                                          )
                                        : _c(
                                            "div",
                                            [
                                              _c("v-card-text", [
                                                _vm._v(
                                                  " Delete is not allowed. "
                                                ),
                                              ]),
                                              _c("v-card-text", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.message) +
                                                    " "
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                      _c("v-divider"),
                                      _c(
                                        "v-card-actions",
                                        [
                                          _c("v-spacer"),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "primary",
                                                text: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.mainDrawerDeleteDialog = false
                                                },
                                              },
                                            },
                                            [_vm._v(" CLOSE ")]
                                          ),
                                          _vm.delete_allowed == true
                                            ? _c(
                                                "div",
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        color: "primary",
                                                        text: "",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.editFormDeleteButton()
                                                        },
                                                      },
                                                    },
                                                    [_vm._v(" AGREE ")]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "my-5" }),
                          _c(
                            "v-form",
                            {
                              ref: "editForm",
                              attrs: { "lazy-validation": "" },
                              model: {
                                value: _vm.valid,
                                callback: function ($$v) {
                                  _vm.valid = $$v
                                },
                                expression: "valid",
                              },
                            },
                            [
                              _vm.is_creator == false
                                ? _c(
                                    "v-container",
                                    { staticClass: "black my-6" },
                                    [
                                      _vm._v(
                                        " Editing this Geozone is only allowed for the creator. "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Name",
                                      outlined: "",
                                      required: "",
                                    },
                                    model: {
                                      value: _vm.name,
                                      callback: function ($$v) {
                                        _vm.name = $$v
                                      },
                                      expression: "name",
                                    },
                                  }),
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Description",
                                      outlined: "",
                                      required: "",
                                    },
                                    model: {
                                      value: _vm.description,
                                      callback: function ($$v) {
                                        _vm.description = $$v
                                      },
                                      expression: "description",
                                    },
                                  }),
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Latitude",
                                      disabled: _vm.is_service_station,
                                      outlined: "",
                                      required: "",
                                    },
                                    model: {
                                      value: _vm.latitude,
                                      callback: function ($$v) {
                                        _vm.latitude = $$v
                                      },
                                      expression: "latitude",
                                    },
                                  }),
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Longitude",
                                      disabled: _vm.is_service_station,
                                      outlined: "",
                                      required: "",
                                    },
                                    model: {
                                      value: _vm.longitude,
                                      callback: function ($$v) {
                                        _vm.longitude = $$v
                                      },
                                      expression: "longitude",
                                    },
                                  }),
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Radius in Meters",
                                      outlined: "",
                                      required: "",
                                    },
                                    model: {
                                      value: _vm.radius_meters,
                                      callback: function ($$v) {
                                        _vm.radius_meters = $$v
                                      },
                                      expression: "radius_meters",
                                    },
                                  }),
                                  _c("v-checkbox", {
                                    attrs: { label: "Set as Landmark" },
                                    model: {
                                      value: _vm.is_landmark,
                                      callback: function ($$v) {
                                        _vm.is_landmark = $$v
                                      },
                                      expression: "is_landmark",
                                    },
                                  }),
                                  _c("v-checkbox", {
                                    attrs: { label: "Service Station" },
                                    model: {
                                      value: _vm.is_service_station,
                                      callback: function ($$v) {
                                        _vm.is_service_station = $$v
                                      },
                                      expression: "is_service_station",
                                    },
                                  }),
                                  _vm.is_service_station
                                    ? [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: "Service Station Name",
                                            rules: [(v) => !!v || "Required!"],
                                            outlined: "",
                                            required: "",
                                          },
                                          model: {
                                            value: _vm.service_station.name,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.service_station,
                                                "name",
                                                $$v
                                              )
                                            },
                                            expression: "service_station.name",
                                          },
                                        }),
                                        _c("v-text-field", {
                                          attrs: {
                                            label: "Service Station Address",
                                            rules: [(v) => !!v || "Required!"],
                                            outlined: "",
                                            required: "",
                                          },
                                          on: { change: _vm.searchCoords },
                                          model: {
                                            value: _vm.service_station.address,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.service_station,
                                                "address",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "service_station.address",
                                          },
                                        }),
                                        _c("v-textarea", {
                                          attrs: {
                                            label: "Additional Info",
                                            outlined: "",
                                          },
                                          model: {
                                            value:
                                              _vm.service_station
                                                .additional_info,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.service_station,
                                                "additional_info",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "service_station.additional_info",
                                          },
                                        }),
                                        _c("v-text-field", {
                                          attrs: {
                                            label: "Network Name",
                                            rules: [(v) => !!v || "Required!"],
                                            outlined: "",
                                            required: "",
                                          },
                                          model: {
                                            value:
                                              _vm.service_station.network.name,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.service_station.network,
                                                "name",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "service_station.network.name",
                                          },
                                        }),
                                        _c("v-textarea", {
                                          attrs: {
                                            label: "Network Description",
                                            outlined: "",
                                          },
                                          model: {
                                            value:
                                              _vm.service_station.network
                                                .description,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.service_station.network,
                                                "description",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "service_station.network.description",
                                          },
                                        }),
                                      ]
                                    : _vm._e(),
                                  _vm.is_creator == true &&
                                  _vm.update_warning == true
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "v-dialog",
                                            {
                                              attrs: { width: "500" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({
                                                      on,
                                                      attrs,
                                                    }) {
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                staticClass:
                                                                  "center",
                                                                attrs: {
                                                                  color:
                                                                    "primary",
                                                                  type: "submit",
                                                                  block: "",
                                                                },
                                                              },
                                                              "v-btn",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [_vm._v(" SAVE ")]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                2070218663
                                              ),
                                              model: {
                                                value:
                                                  _vm.mainDrawerUpdateDialog,
                                                callback: function ($$v) {
                                                  _vm.mainDrawerUpdateDialog =
                                                    $$v
                                                },
                                                expression:
                                                  "mainDrawerUpdateDialog",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-card",
                                                [
                                                  _c(
                                                    "v-card-title",
                                                    { staticClass: "text-h5" },
                                                    [
                                                      _vm._v(
                                                        " Save Circle Zone "
                                                      ),
                                                    ]
                                                  ),
                                                  _c("v-card-text", [
                                                    _vm._v(
                                                      " Are you sure you want to update the selected zone? "
                                                    ),
                                                  ]),
                                                  _c("v-card-text", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(_vm.message) +
                                                        " "
                                                    ),
                                                  ]),
                                                  _c("v-divider"),
                                                  _c(
                                                    "v-card-actions",
                                                    [
                                                      _c("v-spacer"),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            color: "primary",
                                                            text: "",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.mainDrawerUpdateDialog = false
                                                            },
                                                          },
                                                        },
                                                        [_vm._v(" CLOSE ")]
                                                      ),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            color: "primary",
                                                            text: "",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.editFormUpdateAgreeButton()
                                                            },
                                                          },
                                                        },
                                                        [_vm._v(" AGREE ")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm.is_creator == true &&
                                      _vm.update_warning == false
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "center",
                                              attrs: {
                                                color: "primary",
                                                block: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.editFormSaveButton()
                                                },
                                              },
                                            },
                                            [_vm._v(" SAVE ")]
                                          ),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "div",
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "center",
                                              attrs: {
                                                color: "#757575",
                                                type: "submit",
                                                block: "",
                                              },
                                            },
                                            [_vm._v(" SAVE ")]
                                          ),
                                        ],
                                        1
                                      ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "center primary--text",
                                      attrs: {
                                        color: "#272727",
                                        type: "submit",
                                        block: "",
                                        elevation: "0",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.editFormCancelButton()
                                        },
                                      },
                                    },
                                    [_vm._v("CANCEL")]
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-navigation-drawer",
        {
          staticClass: "shade",
          attrs: { fixed: "", persistent: "", right: "", width: "360" },
          model: {
            value: _vm.editDrawer,
            callback: function ($$v) {
              _vm.editDrawer = $$v
            },
            expression: "editDrawer",
          },
        },
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                { attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "mb-2", attrs: { align: "right" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function ($event) {
                              _vm.editDrawer = false
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { light: "" } }, [
                            _vm._v("mdi-close-circle "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("h1", [_vm._v("Geo-Zones")]),
              _c("v-container", [
                _c(
                  "div",
                  { staticClass: "text-right" },
                  [
                    _c(
                      "v-dialog",
                      {
                        attrs: { width: "500" },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _vm.is_creator == true
                                  ? _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "primary--text",
                                            attrs: {
                                              color: "#272727",
                                              dark: "",
                                              elevation: "0",
                                            },
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [_vm._v(" DELETE ")]
                                    )
                                  : _c(
                                      "v-btn",
                                      {
                                        staticClass: "white--text",
                                        attrs: {
                                          color: "#757575",
                                          dark: "",
                                          elevation: "0",
                                        },
                                      },
                                      [_vm._v(" DELETE ")]
                                    ),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.editDrawerDeleteDialog,
                          callback: function ($$v) {
                            _vm.editDrawerDeleteDialog = $$v
                          },
                          expression: "editDrawerDeleteDialog",
                        },
                      },
                      [
                        _c(
                          "v-card",
                          [
                            _c("v-card-title", { staticClass: "text-h5" }, [
                              _vm._v(" Delete Circle Zone "),
                            ]),
                            _vm.delete_allowed == true
                              ? _c(
                                  "div",
                                  [
                                    _c("v-card-text", [
                                      _vm._v(
                                        " Are you sure you want to delete the selected zone? "
                                      ),
                                    ]),
                                  ],
                                  1
                                )
                              : _c(
                                  "div",
                                  [
                                    _c("v-card-text", [
                                      _vm._v(" Delete is not allowed. "),
                                    ]),
                                    _c("v-card-text", [
                                      _vm._v(" " + _vm._s(_vm.message) + " "),
                                    ]),
                                  ],
                                  1
                                ),
                            _c("v-divider"),
                            _c(
                              "v-card-actions",
                              [
                                _c("v-spacer"),
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { color: "primary", text: "" },
                                    on: {
                                      click: function ($event) {
                                        _vm.editDrawerDeleteDialog = false
                                      },
                                    },
                                  },
                                  [_vm._v(" CLOSE ")]
                                ),
                                _vm.delete_allowed == true
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              color: "primary",
                                              text: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.editDrawerDeleteButton()
                                              },
                                            },
                                          },
                                          [_vm._v(" AGREE ")]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "my-5" }),
                _c(
                  "form",
                  {
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  },
                  [
                    _vm.is_creator == false
                      ? _c("v-container", { staticClass: "black my-6" }, [
                          _vm._v(
                            " Editing this Geozone is only allowed for the creator. "
                          ),
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      [
                        _c("v-text-field", {
                          attrs: { label: "Name", outlined: "", required: "" },
                          model: {
                            value: _vm.name,
                            callback: function ($$v) {
                              _vm.name = $$v
                            },
                            expression: "name",
                          },
                        }),
                        _c("v-text-field", {
                          attrs: {
                            label: "Description",
                            outlined: "",
                            required: "",
                          },
                          model: {
                            value: _vm.description,
                            callback: function ($$v) {
                              _vm.description = $$v
                            },
                            expression: "description",
                          },
                        }),
                        _c("v-text-field", {
                          attrs: {
                            label: "Latitude",
                            outlined: "",
                            required: "",
                          },
                          model: {
                            value: _vm.latitude,
                            callback: function ($$v) {
                              _vm.latitude = $$v
                            },
                            expression: "latitude",
                          },
                        }),
                        _c("v-text-field", {
                          attrs: {
                            label: "Longitude",
                            outlined: "",
                            required: "",
                          },
                          model: {
                            value: _vm.longitude,
                            callback: function ($$v) {
                              _vm.longitude = $$v
                            },
                            expression: "longitude",
                          },
                        }),
                        _c("v-text-field", {
                          attrs: {
                            label: "Radius in Meters",
                            outlined: "",
                            required: "",
                          },
                          model: {
                            value: _vm.radius_meters,
                            callback: function ($$v) {
                              _vm.radius_meters = $$v
                            },
                            expression: "radius_meters",
                          },
                        }),
                        _c("v-checkbox", {
                          attrs: { label: "Set as Landmark" },
                          model: {
                            value: _vm.is_landmark,
                            callback: function ($$v) {
                              _vm.is_landmark = $$v
                            },
                            expression: "is_landmark",
                          },
                        }),
                        _vm.is_creator == true && _vm.update_warning == true
                          ? _c(
                              "div",
                              [
                                _c(
                                  "v-dialog",
                                  {
                                    attrs: { width: "500" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass: "center",
                                                      attrs: {
                                                        color: "primary",
                                                        type: "submit",
                                                        block: "",
                                                      },
                                                    },
                                                    "v-btn",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [_vm._v(" SAVE ")]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2070218663
                                    ),
                                    model: {
                                      value: _vm.editDrawerUpdateDialog,
                                      callback: function ($$v) {
                                        _vm.editDrawerUpdateDialog = $$v
                                      },
                                      expression: "editDrawerUpdateDialog",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-card",
                                      [
                                        _c(
                                          "v-card-title",
                                          { staticClass: "text-h5" },
                                          [_vm._v(" Save Circle Zone ")]
                                        ),
                                        _c("v-card-text", [
                                          _vm._v(
                                            " Are you sure you want to update the selected zone? "
                                          ),
                                        ]),
                                        _c("v-card-text", [
                                          _vm._v(
                                            " " + _vm._s(_vm.message) + " "
                                          ),
                                        ]),
                                        _c("v-divider"),
                                        _c(
                                          "v-card-actions",
                                          [
                                            _c("v-spacer"),
                                            _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  color: "primary",
                                                  text: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.editDrawerUpdateDialog = false
                                                  },
                                                },
                                              },
                                              [_vm._v(" CLOSE ")]
                                            ),
                                            _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  color: "primary",
                                                  text: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.editDrawerUpdateAgreeButton()
                                                  },
                                                },
                                              },
                                              [_vm._v(" AGREE ")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm.is_creator == true &&
                            _vm.update_warning == false
                          ? _c(
                              "div",
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "center",
                                    attrs: {
                                      color: "primary",
                                      type: "submit",
                                      block: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.editDrawerSaveButton()
                                      },
                                    },
                                  },
                                  [_vm._v("SAVE ")]
                                ),
                              ],
                              1
                            )
                          : _c(
                              "div",
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "center",
                                    attrs: {
                                      color: "#757575",
                                      type: "submit",
                                      block: "",
                                    },
                                  },
                                  [_vm._v(" SAVE ")]
                                ),
                              ],
                              1
                            ),
                        _c(
                          "v-btn",
                          {
                            staticClass: "center primary--text",
                            attrs: {
                              color: "#272727",
                              type: "submit",
                              block: "",
                              elevation: "0",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.editDrawerCancelButton()
                              },
                            },
                          },
                          [_vm._v("CANCEL")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }