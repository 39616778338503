<template>
  <v-card>
    <v-card-title>Add Issues</v-card-title>
    <v-card-text>Please Add Issue Details</v-card-text>
    <v-divider></v-divider>
    <v-form ref="form" v-model="formValid" lazy-validation>
      <v-container>
        <v-row>
          <v-col sm="3" class="formLabel">Plan:</v-col>
          <v-col>
            <v-text-field
              v-model="currentPlan"
              class="formInput"
              outlined
              label="Currently selected plan"
              disabled
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="3" class="formLabel">Vehicle:</v-col>
          <v-col sm="9">
            <v-select
              v-model="selectedVehicle"
              :items="vehicleList"
              :rules="reqRules"
              class="formInput"
              outlined
              label="Choose Vehicle"
              required
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="3" class="formLabel">HW/SW:</v-col>
          <v-col sm="9">
            <v-select
              v-model="hw_sw"
              :items="hwswList"
              :rules="reqRules"
              class="formInput"
              outlined
              label="Choose HW or SW"
              required
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="3" class="formLabel">Title:</v-col>
          <v-col sm="9">
            <v-text-field
              v-model="issue_title"
              :rules="reqRules"
              class="formInput"
              outlined
              label="Add Title"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="3" class="formLabel">Details:</v-col>
          <v-col sm="9">
            <v-text-field
              v-model="issue_details"
              :rules="reqRules"
              class="formInput"
              outlined
              label="Add Details"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="3" class="formLabel">Next Steps:</v-col>
          <v-col sm="9">
            <v-text-field
              v-model="next_steps"
              class="formInput"
              outlined
              label="Add Next Steps"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="3" class="formLabel">Status:</v-col>
          <v-col sm="9">
            <v-select
              v-model="chosenStatus"
              :items="status"
              :rules="reqRules"
              class="formInput"
              outlined
              label="Choose Status"
              required
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="3" class="formLabel">Comments:</v-col>
          <v-col sm="9">
            <v-text-field
              v-model="issue_comments"
              class="formInput"
              outlined
              label="Add Comments"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="3" class="formLabel">Found Date: </v-col>
          <v-col sm="9">
            <v-text-field
              v-model="found_date"
              v-mask="'##/##/####'"
              :rules="dateRules"
              label="Start Date (MM/DD/YYYY)"
              class="formInput"
              type="text"
              outlined
              append-icon="mdi-calendar"
              required
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="3" class="formLabel">Resolved Date: </v-col>
          <v-col sm="9">
            <v-text-field
              v-model="resolved_date"
              v-mask="'##/##/####'"
              :rules="dateRules"
              label="Start Date (MM/DD/YYYY)"
              class="formInput"
              type="text"
              outlined
              append-icon="mdi-calendar"
              required
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="3" class="formLabel">JIRA Link:</v-col>
          <v-col sm="9">
            <v-text-field
              v-model="jira_link"
              class="formInput"
              outlined
              label="Add JIRA Link"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="cancel"> Cancel </v-btn>
        <v-btn :disabled="!formValid" color="primary" @click="submitIssue">
          Add Issues
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import _ from 'lodash';
export default {
  name: 'AddIssues',
  props: {
    dialog: Boolean,
    addIssueItem: { type: Function, default: null, required: true },
    issueStatus: { type: Array, default: null, required: true },
    toggle: { type: Function, default: null, required: true },
    planItem: { type: Object, default: null, required: true },
    reloadIssues: { type: Function, default: null, required: true },
  },
  data() {
    return {
      addDisabled: true,
      chosenStatus: '',
      currentPlan: '',
      dateRules: [
        (v) => !!v || 'Date is required',
        (v) =>
          (v && v.length === 10) || 'Delivery date must be mm/dd/yyyy (10)',
      ],
      formValid: false,
      found_date: '',
      hw_sw: '',
      hwswList: ['HW', 'SW'],
      issue_comments: '',
      issue_details: '',
      issue_title: '',
      jira_link: '',
      next_steps: '',
      reqRules: [(v) => !!v || 'Required!'],
      resolved_date: '',
      selectedVehicle: '',
      status: [],
      truck_number: null,
      vehicleList: ['Pilot'],
    };
  },
  watch: {
    formValid: function () {
      this.formValid = !(
        this.issue_title === '' &&
        this.issue_details === '' &&
        this.found_date === '' &&
        this.resolved_date === ''
      );
    },
    dialog: function (val) {
      if (val) {
        this.buildLists();
      }
    },
  },
  mounted() {
    this.buildLists();
  },
  methods: {
    buildLists: function () {
      const vm = this;
      vm.currentPlan = vm.planItem.name;
      vm.truck_number = vm.planItem.truck_number;
      vm.vehicleList.push(vm.planItem.truck_number);
      vm.status = [];
      _.forEach(vm.issueStatus, function (o) {
        vm.status.push(o.status);
      });
    },
    convertEpochTime: function (setDate) {
      if (setDate) {
        const newTime = new Date(setDate).getTime() / 1000;
        return _.round(newTime, 0);
      } else {
        return null;
      }
    },
    cancel() {
      this.$refs.form.reset();
      this.toggle();
    },
    findStatusID: function () {
      const vm = this;
      let tmpObj = _.find(vm.issueStatus, function (o) {
        return o.status === vm.chosenStatus;
      });
      return tmpObj.id;
    },
    submitIssue: async function () {
      let issueObj = {
        vehicle: this.selectedVehicle,
        hw_sw: this.hw_sw,
        issue_status: this.findStatusID(this.chosenStatus),
        title: this.issue_title,
        details: this.issue_details,
        next_steps: this.next_steps,
        issue_comments: this.issue_comments,
        found_date: this.convertEpochTime(this.found_date),
        resolved_date: this.convertEpochTime(this.resolved_date),
        jira_link: this.jira_link,
      };
      await this.addIssueItem(issueObj);
      this.cancel();
    },
  },
};
</script>

<style scoped>
.formLabel {
  display: block;
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;
}
.formInput {
  margin-bottom: -20px;
  /* padding: -10px; */
}
</style>
