<template>
  <v-container align="center">
    <div style="margin-top: -20px">
      <div class="test-style">
        <div class="chart-area">
          <highcharts
            v-if="statusLoaded"
            id="donut1"
            ref="donutChartA"
            :options="statusOptions"
          ></highcharts>
          <v-progress-circular
            v-else
            indeterminate
            :size="120"
            :width="10"
            style="position: relative; top: 38px; left: 38px"
          ></v-progress-circular>
        </div>
        <div class="legend-area">
          <div class="graph-title">Status</div>
          <ul class="graph-legend">
            <li><span class="dot bg-red"></span> {{ active }} Active DTCs</li>
            <li>
              <span class="dot bg-orange"></span> {{ pending }} Pending DTCs
            </li>
            <li><span class="dot bg-green"></span> {{ normal }} Normal</li>
            <li><span class="dot bg-gray"></span> {{ noData }} No Data</li>
          </ul>
        </div>
      </div>
      <div class="test-style">
        <div class="chart-area">
          <highcharts
            v-if="stateLoaded"
            id="donut1"
            ref="donutChartA"
            :options="stateOptions"
          ></highcharts>
          <v-progress-circular
            v-else
            indeterminate
            :size="120"
            :width="10"
            style="position: relative; top: 38px; left: 38px"
          ></v-progress-circular>
        </div>
        <div class="legend-area">
          <div class="graph-title">State</div>
          <ul class="graph-legend">
            <li><span class="dot bg-green"></span> {{ moving }} Moving</li>
            <li><span class="dot bg-orange"></span> {{ idle }} Idle</li>
            <li><span class="dot bg-gray"></span> {{ offline }} Offline</li>
            <li><span class="dot bg-darkGray"></span> {{ noData }} No Data</li>
          </ul>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import { getStatusGraph, getLastSpeed } from '@/api/trucks';

export default {
  name: 'PieChartsVue',
  props: {},
  data() {
    return {
      statusLoaded: false,
      stateLoaded: false,
      active: null,
      pending: null,
      normal: null,
      offline: null,
      idle: null,
      moving: null,
      noData: null,
      statusOptions: {
        chart: {
          type: 'pie',
          backgroundColor: '#272727',
          width: 200,
          height: 200,
          margin: 0,
          padding: 0,
          spacingTop: 0,
          spacingBottom: 0,
          spacingLeft: 0,
          spacingRight: 0,
        },
        title: null,
        yAxis: {
          title: null,
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          pie: {
            slicedOffset: 0,
            size: '100%',
            dataLabels: {
              enabled: false,
            },
            colors: ['#ff0000', '#fb8c00', '#43b02a', '#888888'],
            borderColor: null,
          },
        },
        tooltip: {
          formatter: function () {
            return '<b>' + this.point.name + '</b>: ' + this.y + ' %';
          },
        },
        series: [
          {
            name: 'Browsers',
            align: 'left',
            data: null,
            size: '60%',
            innerSize: '80%',
            showInLegend: true,
            dataLabels: {
              enabled: false,
            },
          },
        ],
        credits: {
          enabled: false,
        },
      },
      stateOptions: {
        chart: {
          type: 'pie',
          backgroundColor: '#272727',
          width: 200,
          height: 200,
          margin: 0,
          padding: 0,
          spacingTop: 0,
          spacingBottom: 0,
          spacingLeft: 0,
          spacingRight: 0,
        },
        title: null,
        yAxis: {
          title: null,
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          pie: {
            slicedOffset: 0,
            size: '100%',
            dataLabels: {
              enabled: false,
            },
            colors: ['#43b02a', '#fb8c00', '#888888', '#4d4d4d'],
            borderColor: null,
          },
        },
        tooltip: {
          formatter: function () {
            return '<b>' + this.point.name + '</b>: ' + this.y + ' %';
          },
        },
        series: [
          {
            name: 'Browsers',
            align: 'left',
            data: null,
            size: '60%',
            innerSize: '80%',
            showInLegend: true,
            dataLabels: {
              enabled: false,
            },
          },
        ],
        credits: {
          enabled: false,
        },
      },
    };
  },
  async mounted() {
    await this.loadStateGraph();
    await this.loadStatusGraph();
  },
  methods: {
    async loadStatusGraph() {
      const res = await getStatusGraph();
      this.statusOptions.series[0].data = [
        ["Active DTC's", res.data.active],
        ["Pending DTC's", res.data.pending],
        ['Normal', res.data.normal - this.noData],
        ['No Data', this.noData],
      ];
      this.active = res.data.active;
      this.pending = res.data.pending;
      this.normal = res.data.normal - this.noData;
      this.statusLoaded = true;
    },
    async loadStateGraph() {
      const res = await getLastSpeed('ERX');
      const oneDayAgo = Date.parse(new Date()) - 24 * 3600000;
      let [moving, offline, idle, noData] = [0, 0, 0, 0];
      for (const truck of res.data) {
        if (truck.speed_current > 0) {
          moving += 1;
        } else if (Date.parse(truck.timestamp) > oneDayAgo) {
          idle += 1;
        } else if (!truck.timestamp) {
          noData += 1;
        } else {
          offline += 1;
        }
      }
      this.stateOptions.series[0].data = [
        ['Moving', moving],
        ['Idle', idle],
        ['Offline', offline],
        ['No Data', noData],
      ];
      this.offline = offline;
      this.idle = idle;
      this.moving = moving;
      this.noData = noData;
      this.stateLoaded = true;
    },
  },
};
</script>

<style scoped>
.test-style {
  width: 350px;
  height: 200px;
  display: block;
  position: relative;
  float: left;
}
.block-bg {
  background-color: #272727;
}
.chart-area {
  display: block;
  position: relative;
  width: 165px;
  float: left;
  margin: 0;
  padding: 0;
}
.legend-area {
  display: block;
  position: relative;
  transform: translateY(30%);
  width: 145px;
  float: left;
  margin: 0 0 0 10px;
  margin-top: -27px;
  padding: 0;
}
.graph-title {
  font-family: 'Rawline', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 36px;
  color: #ffffff;
  margin-bottom: 10px;
}
ul.graph-legend {
  list-style-type: none;
  padding: 0;
}
ul.graph-legend > li {
  margin-bottom: 4px;
}
.dot {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  opacity: 1;
  display: block;
  position: relative;
  margin-right: 6px;
  padding: 0;
  float: left;
  top: 4px;
}
</style>
