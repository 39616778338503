<template>
  <v-container fluid :class="!dashboardActive && 'pa-0'">
    <v-navigation-drawer
      v-model="drawer"
      right
      temporary
      fixed
      :width="360"
      class="shade"
    >
      <v-container>
        <v-row align="center">
          <v-col align="right" class="mb-2">
            <v-btn icon @click="closeSideDrawer">
              <v-icon light>mdi-close-circle </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-expansion-panels v-model="panel" multiple>
          <v-expansion-panel class="shade">
            <v-expansion-panel-header
              >Display Settings</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <p>Fueling Stations</p>
              <v-checkbox
                v-model="selectedStations"
                label="CNG stations"
                value="cng"
                color="primary"
                :disabled="playbackMode"
              >
              </v-checkbox>
              <p>Icon Size</p>
              <v-radio-group v-model="iconSize" :disabled="playbackMode">
                <v-radio label="Small" value="small"></v-radio>
                <v-radio label="Large" value="large"></v-radio>
              </v-radio-group>
              <v-row>
                <v-col>
                  Marker Clustering<br />
                  <span class="description">
                    The number on a cluster indicates how many markers it
                    contains.
                  </span>
                </v-col>
                <v-col cols="2">
                  <v-switch
                    v-model="clustering"
                    :disabled="playbackMode"
                  ></v-switch>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  Truck Timelapse<br />
                  <span class="description">
                    View routes and movement of trucks on a selected day.
                  </span>
                </v-col>
              </v-row>
              <br />
              <v-row>
                <v-col>
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        label="Select Date"
                        append-icon="mdi-calendar"
                        outlined
                        readonly
                        v-bind="attrs"
                        :value="formatDate"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date"
                      color="primary"
                      @input="
                        menu2 = false;
                        changedManually = !changedManually;
                      "
                    ></v-date-picker>
                  </v-menu>
                  <div v-if="!validDate" id="dateWarning">
                    Please select a valid date
                  </div>
                  <div v-if="errorMessage.length != 0" :class="errorStatus">
                    {{ errorMessage }}
                  </div>
                </v-col>
              </v-row>
              <v-row style="margin-top: -30px">
                <v-col cols="1">
                  <v-progress-circular
                    v-if="!positionsLoaded && validDate"
                    :size="22"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                  <v-icon
                    v-else-if="!playing"
                    color="primary"
                    class="iconButton"
                    :disabled="playDisabled"
                    @click="playPressed"
                    >mdi-play</v-icon
                  >
                  <v-icon
                    v-else-if="playing"
                    color="primary"
                    class="iconButton"
                    @click="pausePlayback"
                    >mdi-pause</v-icon
                  >
                </v-col>
                <v-col>
                  <v-slider
                    v-model="sliderValue"
                    max="1440"
                    ticks
                    step="5"
                    style="margin-left: 5px"
                  ></v-slider>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel class="shade">
            <v-expansion-panel-header>Route Planning</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-alert v-if="routingAlert" type="error">
                {{ routingAlert }}
              </v-alert>
              <form @submit.prevent="">
                <v-text-field
                  v-model="routeStart"
                  label="Start Address"
                  outlined
                ></v-text-field>
                <v-text-field
                  v-model="routeEnd"
                  label="End Address"
                  outlined
                ></v-text-field>
                <p v-if="routingMessage != ''" class="error">
                  {{ routingMessage }}
                </p>
                <v-checkbox
                  v-model="showElevation"
                  label="Elevation Profile"
                  style="margin-top: -10px"
                ></v-checkbox>
                <v-radio-group v-model="trailerConfig" class="trailerConfig">
                  <v-radio
                    key="1"
                    value="trailer"
                    label="With Trailer"
                  ></v-radio>
                  <v-radio key="0" value="bobtail" label="Bobtail"></v-radio>
                </v-radio-group>
                <v-progress-circular
                  v-if="routingLoading"
                  indeterminate
                  color="primary"
                  class="center"
                ></v-progress-circular>
                <v-btn
                  v-else
                  color="primary"
                  type="submit"
                  class="center"
                  block
                  :loading="routeLoading"
                  @click="mapRoute"
                  >Route</v-btn
                >
              </form>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-container>
    </v-navigation-drawer>
    <!-- <v-text-field
      v-if="!dashboardActive"
      solo
      label="Search by Customer or Truck"
      prepend-inner-icon="mdi-magnify"
      style="
        position: absolute;
        top: 24px;
        left: 24px;
        z-index: 1;
        width: 300px;
        text-align: center;
      "
    ></v-text-field> -->
    <v-btn icon tile large :style="cogStyle" @click="drawer = true"
      ><v-icon style="opacity: 0.6">mdi-cog</v-icon></v-btn
    >
    <div v-if="!dashboardActive && !playbackMode" :style="datetimeStyle">
      <span class="datetimeLabel">Date / Time</span><br />
      <div class="datetime">
        <span class="datetimeLine">
          {{ mapDate }}
        </span>
        <span>
          {{ mapTime }}
        </span>
      </div>
    </div>
    <div v-else-if="playbackMode" :style="playbackStyle">
      <div style="display: flex; justify-content: space-between">
        <span class="datetimeLabel">Date / Time</span>
        <span style="cursor: pointer" @click="clear">Clear</span>
      </div>
      <div class="playbackDatetime">
        <span class="datetimeLine">
          {{ mapDate }}
        </span>
        <span>
          {{ mapTime }}
        </span>
      </div>
      <v-slider
        v-model="sliderValue"
        max="1440"
        ticks
        step="5"
        style="margin-left: 5px"
      ></v-slider>
      <div class="controlsBar">
        <v-progress-circular
          v-if="noYesterday"
          :size="22"
          indeterminate
          color="primary"
        ></v-progress-circular>
        <v-icon v-else color="primary" class="iconButton" @click="skipPrevious"
          >mdi-skip-previous</v-icon
        >
        <div v-if="speedMultiplier < 0" class="rewindSpeed">
          {{ Math.abs(speedMultiplier) }}x
        </div>
        <v-icon color="primary" class="iconButton" @click="rewind"
          >mdi-rewind</v-icon
        >
        <v-progress-circular
          v-if="!positionsLoaded && validDate"
          :size="22"
          indeterminate
          color="primary"
        ></v-progress-circular>
        <v-icon
          v-else-if="!playing"
          color="primary"
          class="iconButton"
          :disabled="playDisabled"
          @click="playPressed"
          >mdi-play</v-icon
        >
        <v-icon
          v-else-if="playing"
          color="primary"
          class="iconButton"
          @click="pausePlayback"
          >mdi-pause</v-icon
        >
        <div v-if="speedMultiplier > 1" class="forwardSpeed">
          {{ speedMultiplier }}x
        </div>
        <v-icon color="primary" class="iconButton" @click="fastForward"
          >mdi-fast-forward</v-icon
        >
        <v-progress-circular
          v-if="noTomorrow"
          :size="22"
          indeterminate
          color="primary"
        ></v-progress-circular>
        <v-icon v-else color="primary" class="iconButton" @click="skipNext"
          >mdi-skip-next</v-icon
        >
      </div>
    </div>

    <loader v-if="false" />
    <v-progress-circular
      v-if="loadingMarkers"
      class="loader"
      color="primary"
      :size="70"
      :width="7"
      indeterminate
    ></v-progress-circular>
    <div id="id-td-MapView" class="data">
      <div id="map">
        <div
          id="mapContainer"
          ref="hereMap"
          :style="`height: ${mapHeight}px; width: 100%`"
        ></div>
      </div>
    </div>
    <div style="margin-right: 270px">
      <ElevationGraph
        v-if="elevationProcessed && showElevation"
        :data="elevationData"
      />
    </div>
    <ClassPie
      v-if="pieLoaded && showElevation"
      class="pieChart"
      :data="pieData"
      :labels="labels"
    />
    <v-sheet v-else-if="elevationProcessed && showElevation" color="darken-2">
      <v-skeleton-loader
        class="pieChart"
        max-width="270"
        type="list-item-two-line, card"
      ></v-skeleton-loader>
    </v-sheet>
  </v-container>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import {
  getLastSpeed,
  getTruckData,
  getLatestDTCs,
  getADXLocations,
  getERXDTCs,
} from '@/api/trucks';
import {
  getCoordinates,
  getCNGStations,
  getElevation,
  getRouteClassification,
} from '@/api/maps';
import { getGeozones } from '@/api/geozones';
import dateFormat from 'dateformat';
import ElevationGraph from './ElevationGraph';
import ClassPie from './ClassPie';

export default {
  name: 'Map',
  components: {
    ElevationGraph,
    ClassPie,
  },
  props: {
    dashboardActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loadingMarkers: true,
      firstLoad: true,
      locationLookup: null,
      locationInterval: null,
      today: new Date(),
      pieLoaded: false,
      speedMultiplier: 1,
      changedManually: false,
      firstClose: true,
      routeLoading: false,
      elevationData: null,
      elevationProcessed: false,
      showElevation: true,
      menu2: false,
      routingAlert: null,
      currentPolyline: null,
      trailerConfig: 'trailer',
      drawer: false,
      hereRouter: null,
      hereUI: null,
      hereGroup: null,
      errorMessage: '',
      errorStatus: 'warning',
      clusterLayer: null,
      hereMap: null,
      apikey: null,
      platform: null,
      routingMessage: '',
      routingLoading: false,
      positionsLoaded: true,
      processedPositions: [],
      processedPositionsTomorrow: [],
      processedPositionsYesterday: [],
      lastCurrent: null,
      lastSelected: null,
      validDate: true,
      playing: false,
      menu: false,
      date: null,
      sliderValue: 1440,
      routeStart: '',
      routeEnd: '',
      // set default open panels
      panel: [0, 1],
      clustering: false,
      iconSize: 'small',
      movingTrucks: [],
      onlineTrucks: [],
      cngStations: [],
      selectedStations: [],
      cngGroup: [],
      authConfig: {},
      authenticated: false,
      active: 0,
      center: {
        lat: 39.8283,
        lng: -98.5795,
      },
      hqLocation: { lat: 30.502378, lng: -97.800371 },
      markers: {},
      markerCluster: null,
      truckUpdateCounter: 0,
      truckData: [],
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      maxRecordAgeSeconds: 7776000, // Setting this to 3 months to match the old API
      intervalID: null,
      truckInfo: {},
      currentTrucks: null,
      tomorrowTrucks: null,
      pieData: [],
      labels: [],
      yesterdayTrucks: null,
    };
  },
  computed: {
    ...mapGetters({
      userRoles: 'getUserRoles',
      storeHealthTrucks: 'getHealthTrucks',
      HERE_MAP_KEY: 'getMapKey',
    }),
    dateLabel() {
      if (this.date) {
        let year, month, day;
        [year, month, day] = this.date.split('-');
        return month + '/' + day + '/' + year;
      } else {
        return 'Select Date to View';
      }
    },
    mapHeight() {
      if (this.elevationProcessed && this.showElevation) {
        return Math.max(window.innerHeight - 66 - 270, 270);
      } else {
        return window.innerHeight - 66;
      }
    },
    datetimeBottom() {
      if (this.elevationProcessed && this.showElevation) {
        // elevation profile graph hieght + 24
        return 294;
      } else {
        return 24;
      }
    },
    datetimeStyle() {
      return `
        width: 200px;
        font-size: 15px;
        position: absolute;
        left: 24px;
        background-color: #1e1e1e;
        border-radius: 5px;
        padding: 12px;
        z-index: 5;
        box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
        0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
        bottom: ${this.datetimeBottom}px
      `;
    },
    playbackStyle() {
      return `
        width: 352px;
        font-size: 15px;
        position: absolute;
        left: 24px;
        background-color: #1e1e1e;
        border-radius: 5px;
        padding: 12px;
        z-index: 5;
        box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
        0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
        bottom: ${this.datetimeBottom}px
      `;
    },
    cogStyle() {
      if (this.dashboardActive) {
        return `
          position: relative;
          float: right;
          margin-top: 23px;
          margin-right: 23px;
          margin-bottom: -80px;
          width: 41px;
          height: 41px;
          background-color: #1a1a1a;
          border-radius: 5px;
          z-index: 1;`;
      } else {
        return `
          position: absolute;
          right: 24px;
          top: 24px;
          width: 41px;
          height: 41px;
          background-color: #1a1a1a;
          border-radius: 5px;
          z-index: 1;
        `;
      }
    },
    mapDate() {
      if (this.date) {
        return dateFormat(this.date, 'shortDate', true);
      } else {
        return dateFormat('shortDate');
      }
    },
    mapTime() {
      let hour, minute;
      if (this.date) {
        // sliderValue is the minute of the day
        hour = Math.floor(Math.min(this.sliderValue, 1440) / 60);
        minute = Math.round(
          (Math.min(this.sliderValue, 1440) / 60 - hour) * 60
        );
      } else {
        hour = this.today.getUTCHours();
        minute = this.today.getUTCMinutes();
      }
      // date is arbitrary but required by dateFormat; hardcoding here so never null
      return dateFormat(`1/1/22 ${hour}:${minute} GMT`, 'h:MM TT Z', true);
    },
    playDisabled() {
      if (!this.validDate || this.date === null) {
        return true;
      } else {
        return false;
      }
    },
    playbackMode() {
      return this.date && this.validDate;
    },
    formatDate() {
      return this.date ? dateFormat(this.date, 'paddedShortDate', true) : null;
    },
    noYesterday() {
      return (
        !this.processedPositionsYesterday ||
        this.processedPositionsYesterday.length === 0
      );
    },
    noTomorrow() {
      return (
        !this.processedPositionsTomorrow ||
        this.processedPositionsTomorrow.length === 0
      );
    },
  },
  watch: {
    authenticated: function (newVal, oldVal) {
      if (oldVal === false && newVal === true) {
        const vm = this;
        // This filters out the trucks that haven't been updated in the set amount of time.
        const maxRecordDate = new Date();
        const maxRecordDateSeconds = Math.round(
          maxRecordDate.getTime() / 1000 - vm.maxRecordAgeSeconds
        );
        vm.populateTrucks(maxRecordDateSeconds);
        // vm.setUpdateInterval(60000);
      }
    },
    selectedStations: function () {
      this.selectCNGMarkers();
    },
    iconSize: function () {
      this.lastSelected = null;
      this.lastCurrent = null;
      // rebuild map markers when iconSize changes
      this.destroyClusters().then(() => this.buildMapMarkers());
    },
    clustering: function () {
      this.lastSelected = null;
      this.lastCurrent = null;
      // rebuild when clustering changes
      this.destroyClusters().then(() => this.buildMapMarkers());
      this.updateLocations(); // change update interval
    },
    sliderValue: function () {
      if (this.date) {
        const H = window.H;
        // stop playback on today's date
        if (
          this.sliderValue >= 1440 &&
          Date.parse(this.date) + 86400000 > Date.parse(new Date()) &&
          this.speedMultiplier > 0
        ) {
          this.pausePlayback();
        }
        // transition to tomorrow's data if moving forward
        else if (this.sliderValue >= 1440 && this.speedMultiplier > 0) {
          this.skipNext();
        }
        // transition to yesterday's data if moving backward
        else if (this.sliderValue <= 0 && this.speedMultiplier < 0) {
          this.sliderValue = 1440;
          this.skipPrevious();
        }

        // avoid console errors if api returns empty
        if (this.errorMessage.length > 0) {
          return;
        }
        // NOTES - OPTIMIZATION: preprocess all truck position data; add data to an object with key lookup of time_id
        // (# of minute in the day); value in object will be an object of lat, lng data
        // Additional note: the below method deletes a marker & creates a new marker to update the location. Simply reassigning
        // the position data causes bugs with the HERE maps platform.
        for (const truck of this.processedPositions) {
          // if truck exists at this time, do stuff
          if (truck[this.sliderValue]) {
            // find current truck on map; remove it
            if (this.markers[truck[this.sliderValue].id]) {
              // destroy this marker
              this.hereMap.removeObject(
                this.markers[truck[this.sliderValue].id]
              );
              delete this.markers[truck[this.sliderValue].id];
            }
            // build new map icon with green healthStatus
            const domIcon = new H.map.DomIcon(`
            <div class="hymarker" style="display:block;position:relative;width:30px;height:43px;cursor: pointer;
              background-image:url(../images/icons/small/Healthy_Idle.svg);z-index:10;background-size:cover;
              margin-left: -12px; margin-top: -36px;">
            </div>`);
            // just display the truck ID if truck isn't in this.truckInfo/this.truckData
            let infoContent;
            try {
              const infoWidth = this.getInfoWidth(
                this.truckInfo[truck[this.sliderValue].id].company,
                this.truckInfo[truck[this.sliderValue].id].name,
                this.truckInfo[truck[this.sliderValue].id].status
              );
              infoContent = `
              <div style="width: ${infoWidth}px; color: #ffffff">
                <span style="color: #757575"> Company: </span> ${
                  this.truckInfo[truck[this.sliderValue].id].company
                }<br>
                <span style="color: #757575"> Truck Name: </span> ${
                  this.truckInfo[truck[this.sliderValue].id].name
                }<br>
                <span style="color: #757575"> DTC: </span> ${
                  this.truckInfo[truck[this.sliderValue].id].status
                }
              </div>`;
            } catch {
              // case should only happen on dev site;
              // truck is in production data but not dev data => thus have truck ID and no name
              infoContent = `
              <div style="color: white;">
                Truck ID: ${truck[this.sliderValue].id}
              </div>`;
            }
            const newMarker = new H.map.DomMarker(truck[this.sliderValue].pos, {
              icon: domIcon,
              data: {
                truck_id: truck[this.sliderValue].id,
                info: infoContent,
                markerType: 'truck',
              },
            });
            this.hereMap.addObject(newMarker);
            this.markers[truck[this.sliderValue].id] = newMarker;
          }
        }
      }
    },
    // start VCR load from scratch if date changed manually
    changedManually: async function () {
      clearInterval(this.locationInterval);
      await this.initialDayLoad();
    },
    drawer: function () {
      // change map bounds to fit route line when drawer is closed
      // for the first time after route created (this is when the map resizes properly)
      if (
        this.elevationProcessed &&
        this.showElevation &&
        this.firstClose &&
        !this.drawer
      ) {
        _.delay(() => {
          this.hereMap.getViewModel().setLookAtData({
            bounds: this.currentPolyline.getBoundingBox(),
          });
        }, 500);
        this.firstClose = false;
      }
    },
  },
  mounted() {
    const accounts = this.$msal.getAllAccounts();
    if (accounts.length === 0) {
      console.log('Not authenticated');
      this.$msal.authenticate().then((this.authenticated = true));
    } else {
      this.authenticated = true;
      console.log('authenticated');
    }

    this.initializeHereMap();
    this.buildMovingOnlineTrucks();
    this.loadFuelingStations();
  },
  beforeDestroy() {
    clearInterval(this.locationInterval);
    clearInterval(this.intervalID);
  },
  methods: {
    ...mapActions(['fetchHealthTrucks', 'updateSnack']),
    async initializeHereMap() {
      this.apikey = this.HERE_MAP_KEY;
      const platform = new window.H.service.Platform({
        apikey: this.apikey,
      });
      this.platform = platform;
      const mapContainer = this.$refs.hereMap;
      const H = window.H;
      // Obtain the default map types from the platform object
      const maptypes = this.platform.createDefaultLayers();
      // Instantiate (and display) a map object:
      const map = new H.Map(mapContainer, maptypes.vector.normal.map, {
        zoom: 4.5,
        center: this.center,
      });
      this.hereMap = map;
      addEventListener('resize', () => map.getViewPort().resize());
      // add behavior control
      new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
      // add UI
      this.hereUI = H.ui.UI.createDefault(map, maptypes);
      // get an instance of the routing service version 8
      this.hereRouter = platform.getRoutingService(null, 8);
      // select UI buttons for positioning
      const zoom = this.hereUI.getControl('zoom');
      const mapSettings = this.hereUI.getControl('mapsettings');
      zoom.setAlignment('right-top');
      mapSettings.setAlignment('right-top');
      // style buttons manually to match cog
      const htmlZoom = zoom.getElement();
      htmlZoom.style.background = '#303030';
      htmlZoom.style.marginTop = '65px';
      htmlZoom.children[0].style.background = '#1a1a1a';
      htmlZoom.children[1].style.background = '#1a1a1a';
      htmlZoom.children[0].firstChild.firstChild.firstChild.style.fill =
        '#9e9e9e';
      htmlZoom.children[1].firstChild.firstChild.style.fill = '#9e9e9e';
      const htmlSetttings = mapSettings.getElement();
      htmlSetttings.firstChild.style.background = '#1a1a1a';
      htmlSetttings.firstChild.firstChild.firstChild.style.fill = '#9e9e9e';
      // remove satellite view option
      htmlSetttings.children[1].firstChild.children[1].remove();
      htmlSetttings.children[1].children[1].remove();
    },
    updateAuthConfig: async function () {
      const vm = this;
      const msalInstance = vm.$msal;
      let tokenResponse = {};
      let account = msalInstance.getAllAccounts()[0];
      if (!account) {
        console.log('No account found');
        await this.$msal.authenticate();
        account = msalInstance.getAllAccounts()[0];
      }
      tokenResponse = await msalInstance.getSilentToken(
        account,
        msalInstance.config.auth.scopes
      );
      vm.authConfig = {
        headers: { Authorization: 'Bearer ' + tokenResponse.accessToken },
        crossdomain: true, // This should probably be removed in production
      };
    },
    isNullOrUndefined: function (o) {
      return o === null || o === undefined;
    },
    cleanMarkers: function () {
      if (this.hereMap) {
        this.hereMap.removeObjects(this.hereMap.getObjects());
        this.markers = {};
      }
    },
    cleanTruckMarkers() {
      if (this.hereGroup && this.hereMap) {
        this.hereMap.removeObject(this.hereGroup);
        this.hereGroup = null;
      }
    },
    sortTruckArray: function (a, b) {
      return a.company.localeCompare(b.company) || a.number - b.number;
    },
    // second comparison function required for markers in HERE format
    sortTruckArrayB(a, b) {
      return (
        a.data.company.localeCompare(b.data.company) ||
        a.data.number - b.data.number
      );
    },
    closeSideDrawer() {
      this.drawer = false;
    },
    populateTrucks: async function (timeInterval = null) {
      const vm = this;
      // await vm.updateAuthConfig();
      try {
        // const response = timeInterval
        //   ? await getInvervalTrucks(timeInterval)
        //   : await getHealthTrucks();
        // remove interval feature for now to see all trucks;

        // get health trucks from store if they exist there
        let promises = [getLatestDTCs(), getADXLocations(), getERXDTCs()];
        let healthTrucks, dtcData, locations, erxDtcs;
        if (this.storeHealthTrucks) {
          healthTrucks = _.cloneDeep(this.storeHealthTrucks);
        } else {
          promises.push(this.fetchHealthTrucks());
        }
        await Promise.allSettled(promises).then((results) => {
          results[0].status === 'fulfilled'
            ? (dtcData = results[0].value.data)
            : console.log(results[0].reason);
          results[1].status === 'fulfilled'
            ? (locations = results[1].value.data)
            : console.log(results[1].reason);
          results[2].status === 'fulfilled'
            ? (erxDtcs = results[2].value.data)
            : console.log(results[2].reason);
          if (results[3]) {
            results[3].status === 'fulfilled'
              ? (healthTrucks = _.cloneDeep(vm.storeHealthTrucks))
              : console.log(results[3].reason);
          }
        });

        // create lookup for locations
        let locationLookup = {};
        locations.forEach((truck) => (locationLookup[truck.truck_id] = truck));
        this.locationLookup = locationLookup;

        const dataLength = healthTrucks.length;
        if (dataLength > 0) {
          vm.truckData.length = 0;
          vm.truckData = healthTrucks.slice();
          for (const truck of vm.truckData) {
            vm.$set(truck, 'name', truck.number);
            vm.$set(truck, 'address', truck.address);
            // set dtc statuses based on truck type
            if (truck.truck_type.includes('EX')) {
              const dtcs = dtcData.filter((d) => d.truck_id === truck.id);
              if (dtcs.length) {
                for (const d of dtcs) {
                  if (d.dtc_active && !d.cleared) {
                    vm.$set(truck, 'dtc', true);
                    break;
                  } else if (
                    (d.fail_count > 0 || d.trip_count > 0) &&
                    !d.cleared
                  ) {
                    vm.$set(truck, 'pre_dtc', true);
                  }
                }
              } else {
                vm.$set(truck, 'dtc', false);
                vm.$set(truck, 'pre_dtc', false);
              }
            } else if (truck.truck_type.includes('ERX')) {
              for (const dtc of erxDtcs) {
                if (dtc.truck_id === truck.id) {
                  vm.$set(truck, 'dtc', true);
                  break;
                }
              }
            }

            // This is set from the Health Monitor table by clicking on the status icon.
            vm.$set(truck, 'status', truck.status);
            // Decide the icon to show in truck status column/field based on the truck status.

            // Using $set above, adds reactive properties of to the nested object in the
            // truckData array.  So in this case set is being used to the status property
            // for the truckData objects is reactive and we can change the icon of in the
            //  status column.
            // when the status of the truck changes by binding a class based on the status of
            // the truck.

            // From the official Vue documentation see section on Change Detection Caveats:
            // https://vuejs.org/v2/guide/reactivity.html#Change-Detection-Caveats and
            // https://vuejs.org/v2/guide/list.html#Array-Change-Detection

            // Also see the accepted answers to the SO questions below:
            // https://stackoverflow.com/questions/44800470/vue-js-updated-array-item-value-doesnt-update-in-page   see accetped answer

            // https://stackoverflow.com/questions/42807888/vuejs-and-vue-set-update-array
          }
          this.truckUpdateCounter++;
          await this.buildMovingOnlineTrucks().then(() => {
            if (vm.clusterLayer) vm.destroyClusters();
            vm.cleanTruckMarkers();
            vm.buildMapMarkers(null);
          });
          if (this.firstLoad) {
            vm.displayLandmarks();
            vm.firstLoad = false;
          }
        }
      } catch (error) {
        this.loadingMarkers = false;
        vm.updateSnack({
          model: true,
          type: 'error',
          message: 'There was an error populating the map with trucks',
        });
        console.error('Axios error on populateTrucks() ==> ' + error);
      }
      this.buildTruckInfo();
    },
    buildMapMarkers: async function (item) {
      const H = window.H;
      const vm = this;

      vm.cleanTruckMarkers();
      if (vm.truckUpdateCounter < 2) {
        vm.truckData.sort(vm.sortTruckArray);
      }
      const testLength = vm.truckData.length;
      let testLat = 0;
      let testLng = 0;

      vm.hereGroup = new H.map.Group();

      for (let i = 0; i < testLength; i++) {
        const id = vm.truckData[i].id;
        if (
          !this.locationLookup[id] ||
          (vm.locationLookup[id].latitude === 0 &&
            vm.locationLookup[id].longitude === 0)
        ) {
          continue; // skip if 0,0 coordinates or no adx location
        } else {
          testLat = this.locationLookup[id].latitude;
          testLng = this.locationLookup[id].longitude;
        }
        const tmpPos = { lat: testLat, lng: testLng };

        // set moving & online status
        let movingStatus = 'Idle';
        let iconName = null;
        let healthColor;
        let healthStatus;
        let dtcStatus;

        if (vm.movingTrucks.indexOf(id) >= 0) {
          movingStatus = 'Moving';
        }

        // offline if no position data in past 24 hours
        const now = Date.parse(new Date());
        if (Date.parse(vm.locationLookup[id].timestamp) < now - 86400000) {
          iconName = 'Off-line.svg';
          healthColor = '#9E9E9E';
          healthStatus = 'Offline';
        }

        if (!vm.truckData[i].dtc && !vm.truckData[i].pre_dtc) {
          dtcStatus = 'Normal';
          if (healthStatus !== 'Offline') {
            healthStatus = 'Healthy';
            healthColor = '#4caf50';
          }
        } else if (vm.truckData[i].pre_dtc && !vm.truckData[i].dtc) {
          dtcStatus = 'Warning';
          healthStatus = 'Hazard';
          healthColor = '#F9A825';
          iconName = null;
        } else {
          dtcStatus = 'Fault';
          healthStatus = 'Error';
          healthColor = '#D50000';
          iconName = null;
        }

        // use white text on error icon
        let textColor = 'black';
        if (!iconName) {
          textColor = healthStatus === 'Error' ? 'white' : 'black';
          iconName = `${healthStatus}_${movingStatus}.svg`;
        }

        let width;
        if (this.iconSize === 'large') {
          width = 39 + this.getTextWidth(vm.truckData[i].name);
        } else {
          width = 32;
        }

        let markerContent;
        if (this.iconSize === 'small') {
          markerContent = `
          <div class="hymarker" style="display:block;position:relative;width:30px;height:43px;cursor:pointer;
            background-image:url(../images/icons/small/${iconName});z-index:10;background-size:cover;
            margin-left: -${width / 2}px; margin-top: -36px;">
          </div>`;
        } else {
          markerContent = this.getMarkerContent(
            healthStatus,
            movingStatus,
            width,
            vm.truckData[i].name,
            true
          );
        }

        const infoWidth = this.getInfoWidth(
          vm.truckData[i].company,
          vm.truckData[i].name,
          dtcStatus,
          dateFormat(vm.locationLookup[id].timestamp, 'm/d/yy h:MM:ss Z', true)
        );

        // prettier-ignore
        const infoContent = `
        <div style="width: ${infoWidth}px; color: #ffffff; font-weight: 100">
          <span style="color: #757575"> Company: </span> ${vm.truckData[i].company}<br>
          <span style="color: #757575"> Truck Name: </span> ${vm.truckData[i].name}<br>
          <span style="color: #757575"> DTC: </span> ${dtcStatus}<br>
          <span style="color: #757575"> Last Updated: </span> ${dateFormat(vm.locationLookup[id].timestamp, 'm/d/yy h:MM:ss Z', true)}
        </div>`;

        // Add new marker to map
        const domIcon = new H.map.DomIcon(markerContent);
        const newMarker = new H.map.DomMarker(tmpPos, {
          icon: domIcon,
          data: {
            healthStatus: healthStatus,
            healthColor: healthColor,
            company: vm.truckData[i].company,
            truck_id: id,
            number: vm.truckData[i].number,
            name: vm.truckData[i].name,
            movingStatus: movingStatus,
            info: infoContent,
            markerType: 'truck',
          },
        });
        this.hereGroup.addObject(newMarker);
        // set z-index based on health status & truck_type
        if (
          vm.truckData[i].truck_type &&
          vm.truckData[i].truck_type.includes('ERX')
        ) {
          newMarker.setZIndex(99);
        } else if (iconName === 'Off-line.svg') {
          newMarker.setZIndex(97);
        } else if (healthStatus === 'Healthy') {
          newMarker.setZIndex(96);
        } else if (healthStatus === 'Hazard') {
          newMarker.setZIndex(98);
        } else if (healthStatus === 'Error') {
          newMarker.setZIndex(100);
        }

        if (testLat !== 0 && testLng !== 0) {
          if (item && item === vm.truckData[i].company) {
            vm.markers[id] = newMarker;
          }
          if (!item || item === 'All Trucks') {
            vm.markers[id] = newMarker;
          }
          if (!vm.isNullOrUndefined(vm.markers[id])) {
            vm.$set(vm.markers[id], 'status', dtcStatus);
          }
        }
      }

      this.hereMap.addObject(this.hereGroup);

      if (this.clustering) {
        this.createClusters();
      }
      if (this.selectedStations.indexOf('cng') >= 0 && this.cngGroup) {
        this.hereMap.addObject(this.cngGroup);
      }
      vm.setupClickableMarkers();
      vm.updateLocations();
      this.loadingMarkers = false;
      return false;
    },
    async selectCNGMarkers() {
      // update if 'cng' in this.selectedStations
      if (this.selectedStations.indexOf('cng') >= 0) {
        this.hereMap.addObject(this.cngGroup);
        this.setupCNGCursor();
      } else {
        this.hereMap.removeObject(this.cngGroup);
      }
    },
    async loadFuelingStations() {
      const cng_stations = await getCNGStations();
      const H = window.H;
      this.cngGroup = new H.map.Group();
      const cngIcon = new H.map.Icon('../images/icons/fuel.png');
      cng_stations.data.fuel_stations.forEach((station) => {
        const marker = new H.map.Marker(
          { lat: station.latitude, lng: station.longitude },
          {
            icon: cngIcon,
            data: {
              id: station.id,
              date_last_confirmed: station.date_last_confirmed,
              access_days_time: station.access_days_time,
              cards_accepted: station.cards_accepted,
              access_code: capitalize(station.access_code),
              access_detail_code: station.access_detail_code,
              cng_psi: station.cng_psi,
              cng_vehicle_class: station.cng_vehicle_class,
              fuel_type_code: station.fuel_type_code,
              city: station.city,
              state: station.state,
              street_address: station.street_address,
              zip: station.zip,
              markerType: 'station',
            },
          }
        );
        this.cngGroup.addObject(marker);
      });
      function capitalize(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      }
    },
    async buildMovingOnlineTrucks() {
      const res = await getLastSpeed();
      this.movingTrucks = [];
      this.onlineTrucks = [];

      res.data.forEach((truck) => {
        if (truck.speed_current > 0) {
          this.movingTrucks.push(truck.truck_id);
        } else {
          this.onlineTrucks.push(truck.truck_id);
        }
      });
    },
    createClusters() {
      const vm = this;
      const H = window.H;

      // create data points for clusterer to digest; pass in optional marker data
      const dataPoints = Object.values(this.markers).map((marker) => {
        const dp = new H.clustering.DataPoint(
          marker.a.lat,
          marker.a.lng,
          null,
          marker.data
        );
        return dp;
      });

      this.cleanTruckMarkers();

      const clusteredDataProvider = new H.clustering.Provider(dataPoints, {
        clusteringOptions: {
          // max radius of the neighborhood
          eps: 32,
          minWeight: 2,
        },
        theme: {
          // render cluster icons
          getClusterPresentation: function (cluster) {
            // Use cluster weight to change the icon size
            const weight = cluster.getWeight();
            let worstStatus = 'Healthy';
            // go through data points in each cluster and set the worst status and worst color
            cluster.forEachDataPoint((dp) => {
              if (worstStatus != 'Error') {
                if (dp.a.data.healthStatus === 'Hazard') {
                  worstStatus = dp.a.data.healthStatus;
                } else if (dp.a.data.healthStatus === 'Error') {
                  worstStatus = dp.a.data.healthStatus;
                }
              }
            });
            // build icon in CSS for dynamic width
            const width = vm.getTextWidth(`${weight} Trucks`);
            const markerContent = vm.getMarkerContent(
              worstStatus,
              'Idle',
              width,
              weight,
              false
            );
            // Create an icon
            const clusterIcon = new H.map.DomIcon(markerContent);
            // Create a marker for the cluster
            const clusterMarker = new H.map.DomMarker(cluster.getPosition(), {
              icon: clusterIcon,
              // Set min/max zoom with values from the cluster, otherwise
              // clusters will be shown at all zoom levels
              min: cluster.getMinZoom(),
              max: cluster.getMaxZoom(),
            });
            // Bind cluster data to the marker
            clusterMarker.setData(cluster);
            // establish z indexing order
            if (worstStatus === 'Healthy') {
              clusterMarker.setZIndex(101);
            } else if (worstStatus === 'Hazard') {
              clusterMarker.setZIndex(102);
            } else if (worstStatus === 'Error') {
              clusterMarker.setZIndex(103);
            }
            return clusterMarker;
          },
          // render icons for non-clustered markers
          getNoisePresentation: function (noisePoint) {
            let iconName, markerContent, noiseIcon;
            if (vm.iconSize === 'small') {
              if (noisePoint.a.data.healthStatus === 'Offline') {
                iconName = 'Off-line.svg';
              } else {
                iconName = `${noisePoint.a.data.healthStatus}_${noisePoint.a.data.movingStatus}.svg`;
              }
              markerContent = `
                <div class="hymarker" style="display:block;position:relative;width:30px;height:43px; cursor: pointer;
                  background-image:url(../images/icons/small/${iconName});z-index:10;background-size:cover;
                  margin-left: -12px; margin-top: -36px;">
                </div>`;
            } else {
              const width = 39 + vm.getTextWidth(noisePoint.a.data.name);
              markerContent = vm.getMarkerContent(
                noisePoint.a.data.healthStatus,
                noisePoint.a.data.movingStatus,
                width,
                noisePoint.a.data.name,
                true
              );
            }
            // create dom icon and dom marker
            noiseIcon = new H.map.DomIcon(markerContent);
            const noiseMarker = new H.map.DomMarker(noisePoint.getPosition(), {
              icon: noiseIcon,
              // Use min zoom from a noise point to show it correctly at certain zoom levels
              min: noisePoint.getMinZoom(),
            });
            // Bind noise point data to the marker:
            noiseMarker.setData(noisePoint);
            return noiseMarker;
          },
        },
      });
      const clusteringLayer = new H.map.layer.ObjectLayer(
        clusteredDataProvider
      );
      this.clusterLayer = clusteringLayer;
      this.hereMap.addLayer(clusteringLayer);
    },
    async destroyClusters() {
      this.hereMap.removeLayer(this.clusterLayer);
    },
    playPressed() {
      this.playing = true;
      this.setPlayback(1);
    },
    pausePlayback() {
      clearInterval(this.intervalID);
      this.playing = false;
    },
    getTextWidth(string) {
      const font = '12px RawlineRegular, sans-serif';
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      context.font = font;
      const width = context.measureText(string.trim()).width;
      return Math.ceil(width);
    },
    getBubbleTextWidth(string) {
      const font = '14px Lucida Grande, Arial, Helvetica';
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      context.font = font;
      const width = context.measureText(string.trim()).width;
      return Math.ceil(width);
    },
    processPositions(truckPositions, day) {
      let uniqueTrucks = new Set();
      let truckObject = {};
      let processedPositions = [];
      day = day.toLowerCase();

      if (truckPositions.data.length === 0 && day === 'today') {
        this.errorStatus = 'warning';
        this.errorMessage = 'No data from this date. Select again.';
      } else {
        this.errorMessage = '';
      }

      for (const entry of truckPositions.data) {
        if (uniqueTrucks.has(entry.truck_id)) {
          addEntry(entry);
        } else {
          // new Truck; push previous truckObject to final array & reset
          if (Object.keys(truckObject).length !== 0) {
            processedPositions.push(truckObject);
          }
          uniqueTrucks.add(entry.truck_id);
          truckObject = {};
          addEntry(entry);
        }
      }
      // push final truckObject to array
      processedPositions.push(truckObject);

      function addEntry(entry) {
        // parse timestamp
        const timestamp = entry.timestamp.slice(11, 19);
        const [hr, min] = timestamp.split(':');
        const minuteStamp = parseInt(hr) * 60 + parseInt(min);
        // add  entry to object
        if (minuteStamp in truckObject) {
          console.log("WARNING: duplicate minuteStamps. This shouldn't happen");
        } else {
          truckObject[minuteStamp] = {
            id: entry.truck_id,
            pos: {
              lat: entry.latitude,
              lng: entry.longitude,
            },
          };
        }
      }

      if (day === 'today') {
        this.currentTrucks = uniqueTrucks;
      } else if (day === 'tomorrow') {
        this.tomorrowTrucks = uniqueTrucks;
      } else {
        this.yesterdayTrucks = uniqueTrucks;
      }
      return processedPositions;
    },
    async mapRoute() {
      this.routeLoading = true;
      this.routingAlert = null;
      this.elevationProcessed = false;
      this.firstClose = true;
      this.pieLoaded = false;
      const vm = this;
      let start, end, elevations;
      async function findCoordinates(_callback) {
        try {
          start = await getCoordinates(vm.routeStart);
          end = await getCoordinates(vm.routeEnd);
          start = start.data.items[0].position;
          end = end.data.items[0].position;
          _callback();
        } catch (e) {
          console.log('Error on HERE getCoordinates =>', e);
          vm.routingAlert =
            'Error fetching coordinates. Please enter a valid address or city name';
        }
      }

      findCoordinates(async () => {
        // call getElevation upon successful completion of getCoordinates();
        try {
          const res = await getElevation(start, end);
          elevations = window.H.util.flexiblePolyline.decode(
            res.data.routes[0].sections[0].polyline
          );
          this.buildElevationGraph(elevations.polyline);
        } catch (e) {
          console.log('Error on createElevation() =>', e);
          vm.routingAlert =
            'There was an issue creating the elevation profile. Please check inputs.';
        }
        // call createPolyline upon successful completion of getCoordinates();
        try {
          this.createPolyline(start, end);
        } catch (e) {
          console.log('Error on createPolyline() =>', e);
          vm.routingAlert =
            'There was an issue creating the polyline. Please check inputs.';
        }
        this.routeLoading = false;
      });
    },
    createPolyline(start, end) {
      const vm = this;
      if (vm.currentPolyline) {
        vm.hereMap.removeObject(vm.currentPolyline);
        vm.currentPolyline = null;
      }
      // set up dimensions parameters: bobtail vs. with trailer
      let height, width, length;
      if (vm.trailerConfig === 'bobtail') {
        height = '4.06'; // units in meters
        width = '2.44';
        length = '7.32';
      } else {
        height = '4.11';
        width = '2.6';
        length = '21.34'; // = 70 ft
      }

      // call the route and map it
      const routingParameters = {
        routingMode: 'fast',
        transportMode: 'truck',
        height: `${height}`,
        width: `${width}`,
        length: `${length}`,
        origin: `${start.lat},${start.lng}`,
        destination: `${end.lat},${end.lng}`,
        return: 'polyline',
      };

      // Define a callback function to process the routing response:
      const onResult = function (result) {
        const H = window.H;
        // ensure that at least one route was found
        if (result.routes.length) {
          result.routes[0].sections.forEach((section) => {
            // Create a linestring to use as a point source for the route line
            let linestring = H.geo.LineString.fromFlexiblePolyline(
              section.polyline
            );
            // Create a polyline to display the route:
            let routeLine = new H.map.Polyline(linestring, {
              style: { strokeColor: 'blue', lineWidth: 3 },
            });
            // Add the route polyline
            vm.hereMap.addObject(routeLine);
            vm.currentPolyline = routeLine;
            // Set the map's viewport to make the whole route visible:
            vm.hereMap.getViewModel().setLookAtData({
              bounds: routeLine.getBoundingBox(),
            });
          });
        }
      };

      vm.hereRouter.calculateRoute(routingParameters, onResult, (error) => {
        vm.routingAlert = `Something went wrong with the server: ${error}`;
      });
    },
    getMarkerContent(healthStatus, movingStatus, width, text, pointer) {
      // set health color & check input
      let healthColor;
      if (healthStatus === 'Healthy') {
        healthColor = '#4caf50';
      } else if (healthStatus === 'Hazard') {
        healthColor = '#F9A825';
      } else if (healthStatus === 'Error') {
        healthColor = '#D50000';
      } else if (healthStatus === 'Offline') {
        healthColor = '#9E9E9E';
      } else {
        throw `Invalid healthStatus given to getMarkerContent();\nhealthStatus: ${healthStatus}`;
      }
      // check movingStatus input
      if (movingStatus != 'Moving' && movingStatus != 'Idle') {
        throw `Invalid movingStatus given to getMarkerContent();\nmovingStatus: ${movingStatus}`;
      }
      // set cursor behavior
      const cursor = pointer ? 'cursor:pointer;' : '';
      // prettier-ignore
      const markerContent = `
        <div style="margin-left: -${(width / 2) + 1}px;margin-top: -36px;${cursor}">
          <div style="position: absolute; left: ${width / 2 - 6.5}px; top: 30px; ">
            <div class="hymarker" style="display:block;position:absolute;width:13px;height:9px;
              background-image:url(../images/icons/ticks/${healthStatus}_tick.svg);z-index:3000;background-size:cover;">
            </div>
          </div>
          <div style="position: absolute;width: ${width + 2}px;height: 30px;background: #f4f4f4;border-radius: 4px;z-index: 1;
            margin-top:1px;">
            <div style="position: absolute;width: ${width}px;height: 28px;margin-top: 1px;margin-left: 1px;
              background: ${healthColor};border-radius: 4px;z-index: 1;">
              <span style="position: absolute;padding-top: 4px;padding-left:5px;"z-index: 1;>
                <img src="../images/icons/${movingStatus}.svg" />
              </span>
              <span style="color: #c02537 ; position: absolute;font-size: 12px;font-weight: bold;color: black;margin-top: 5px;
                margin-left: 30px;z-index: 1;">
                ${text}
              </span>
            </div>
          </div>
        </div>`;
      return markerContent;
    },
    setupClickableMarkers() {
      const vm = this;
      this.hereMap.addEventListener('tap', (event) => {
        // remove all open bubbles
        this.hereUI
          .getBubbles()
          .forEach((bub) => this.hereUI.removeBubble(bub));
        // if target has no data (i.e. is the map or a cluster) return
        let bubble;
        if (
          !event.target.getData ||
          (event.target.data?.a && !event.target.data?.a.data)
        ) {
          return;
        } else if (
          event.target.data?.markerType === 'truck' ||
          event.target.data?.a.data.markerType === 'truck'
        ) {
          const info =
            event.target.data.markerType === 'truck'
              ? event.target.getData().info
              : event.target.data.a.data.info;

          bubble = new window.H.ui.InfoBubble(event.target.getGeometry(), {
            content: `
                  ${info}
                  <hr style="border: 1px solid #888888; margin-top: 7px; margin-bottom: 7px;">
                  <div style="width: 150px; margin-left: -23px; margin-bottom: -10px; text-align: left;">
                    <ul style="list-style: none;">
                      <li class="health">
                        <a>
                        <i class="mdi mdi-link"
                          style="filter: invert(49%) sepia(72%) saturate(368%) hue-rotate(72deg) brightness(103%) contrast(93%);
                            font-size: 23px; position: relative; top: 4px;">
                        </i>
                        &nbspHealth Detail
                        </a>
                      </li>
                    </ul>
                  </div>`,
          });
        } else if (event.target.data?.markerType === 'station') {
          const stationData = event.target.getData();
          bubble = new window.H.ui.InfoBubble(event.target.getGeometry(), {
            // prettier-ignore
            content: `
                <div style="width: 450px; margin-left: -20px; color: white; display: flex; flex-direction: row; flex-wrap: wrap;">
                  <div style="display: flex; flex-direction: column; flex-basis: 100%; flex: 1">
                    <ul style="list-style: none">
                      <li>
                        <i class="mdi mdi-gas-station"
                          style="font-size: 23px; position: relative; top: 4px;">
                        </i>
                        <span style="color: #757575">Fuel Available</span>
                        <span style="position: relative; left: 30px"><br>${stationData.fuel_type_code ? stationData.fuel_type_code : 'Unknown'}</span>
                      </li>
                    </ul>
                    <ul style="list-style: none">
                      <li>
                        <i class="mdi mdi-truck-flatbed"
                          style="font-size: 23px; position: relative; top: 4px;">
                        </i>
                        <span style="color: #757575">Vehicle Accessibility</span>
                        <span style="position: relative; left: 30px"><br>Yes</span>
                      </li>
                    </ul>
                    <ul style="list-style: none">
                      <li>
                        <i class="mdi mdi-clock-time-four-outline"
                          style="font-size: 23px; position: relative; top: 4px;">
                        </i>
                        <span style="color: #757575">Hours</span>
                        <span style="position: relative; left: 30px"><br>${stationData.access_days_time ? stationData.access_days_time : 'Uknown'}</span>
                      </li>
                    </ul>
                    <ul style="list-style: none">
                      <li>
                        <i class="mdi mdi-calendar-range"
                          style="font-size: 23px; position: relative; top: 4px;">
                        </i>
                        <span style="color: #757575">Last Confirmed</span>
                        <span style="position: relative; left: 30px"><br>${stationData.date_last_confirmed ? dateFormat(stationData.date_last_confirmed, 'paddedShortDate') : 'Unknown'}</span>
                      </li>
                    </ul>
                  </div>
                  <div style="display: flex; flex-direction: column; flex-basis: 100%; flex: 1">
                    <ul style="list-style: none">
                      <li>
                        <i class="mdi mdi-gauge"
                          style="font-size: 23px; position: relative; top: 4px;">
                        </i>
                        <span style="color: #757575">Fill Pressure (psi)</span>
                        <span style="position: relative; left: 30px"><br>${stationData.cng_psi ? stationData.cng_psi : 'Unknown'}</span>
                      </li>
                    </ul>
                    <ul style="list-style: none">
                      <li>
                        <i class="mdi mdi-earth"
                          style="font-size: 23px; position: relative; top: 4px;">
                        </i>
                        <span style="color: #757575">Public / Private <br></span>
                        <span style="position: relative; left: 30px">${stationData.access_code ? stationData.access_code : 'Unknown'}</span>
                      </li>
                    </ul>
                    <ul style="list-style: none">
                      <li>
                        <i class="mdi mdi-credit-card-outline"
                          style="font-size: 23px; position: relative; top: 4px;">
                        </i>
                        <span style="color: #757575">Payment</span>
                        <span style="position: relative; left: 30px"><br>${stationData.access_detail_code ? stationData.access_detail_code : 'Unknown'}</span>
                      </li>
                    </ul>
                    <ul style="list-style: none">
                      <li>
                        <i class="mdi mdi-map-marker"
                          style="font-size: 23px; position: relative; top: 4px;">
                        </i>
                        <span style="color: #757575">Address</span>
                        <span style="position: relative; left: 30px">
                        <br>${stationData.street_address ? stationData.street_address : 'Unknown'}
                        <br>${stationData.city}, ${stationData.state} ${stationData.zip}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
            `,
          });
        }

        if (bubble) this.hereUI.addBubble(bubble);
        if (event.target.data?.markerType === 'truck') {
          // (below bubble method only works after bubble is added to UI)
          const bubbleHTML = bubble.getContentElement();
          // connect links to functions
          bubbleHTML
            .getElementsByClassName('health')[0]
            .addEventListener('click', healthPressed);
        }
        async function healthPressed() {
          vm.$router.push(`health/${event.target.data.truck_id}/details`);
        }
      });
      this.setupCNGCursor();
    },
    // build truck info Object for O(1) lookup time in timeTravel
    buildTruckInfo() {
      this.truckData.forEach((truck) => {
        this.truckInfo[truck.id] = {
          status: truck.status,
          company: truck.company,
          name: truck.name,
        };
      });
    },
    getInfoWidth(company, name, status, timestamp = null) {
      return Math.max(
        // + 26 to accomodate X in top right corner
        this.getBubbleTextWidth(`Company: ${company}`) + 26,
        this.getBubbleTextWidth(`Truck Name: ${name}`),
        this.getBubbleTextWidth(`DTC: ${status}`),
        timestamp ? this.getBubbleTextWidth(`Last Updated: ${timestamp}`) : 0
      );
    },
    // make cursor pointer on cng hover
    setupCNGCursor() {
      this.hereMap.addEventListener(
        'pointermove',
        (event) => {
          if (event.target instanceof window.H.map.Marker) {
            this.hereMap.getViewPort().element.style.cursor = 'pointer';
          } else {
            this.hereMap.getViewPort().element.style.cursor = 'auto';
          }
        },
        false
      );
    },
    buildElevationGraph(elevations) {
      let elevationData = [];
      let classificationData = [];
      for (let i = 0; i < elevations.length - 1; i++) {
        const prevDistance =
          i === 0 ? 0 : elevationData[elevationData.length - 1][0];
        const distance = this.distance(
          elevations[i][0], // lat1
          elevations[i][1], // lng1
          elevations[i + 1][0], // lat2
          elevations[i + 1][1] // lng2
        );
        elevationData.push([
          prevDistance + distance,
          elevations[i][2] * 3.28084, // m to ft
        ]);
        classificationData.push({
          lat: elevations[i][0],
          lng: elevations[i][1],
          elev: elevations[i][2], // m
          dist: (prevDistance + distance) * 1609.34, // mi to m
        });
      }
      this.elevationData = elevationData;
      this.elevationProcessed = true;

      if (this.currentPolyline) {
        this.hereMap.getViewModel().setLookAtData({
          bounds: this.currentPolyline.getBoundingBox(),
        });
      }
      this.buildPie(classificationData);
    },
    // calculate distance b/w 2 points on earth
    distance(lat1, lng1, lat2, lng2) {
      lng1 = (lng1 * Math.PI) / 180;
      lng2 = (lng2 * Math.PI) / 180;
      lat1 = (lat1 * Math.PI) / 180;
      lat2 = (lat2 * Math.PI) / 180;
      // Haversine formula
      let dlon = lng2 - lng1;
      let dlat = lat2 - lat1;
      let a =
        Math.pow(Math.sin(dlat / 2), 2) +
        Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(dlon / 2), 2);
      let c = 2 * Math.asin(Math.sqrt(a));
      // Radius of earth in miles
      let r = 3956;
      // calculate the result
      return c * r;
    },
    async initialDayLoad() {
      const today = Date.parse(new Date());
      let inputDate = Date.parse(this.date);
      let endDate = inputDate + 86400000;
      this.validDate = inputDate <= today;
      // turn off clustering
      this.clustering = false;
      // reset values
      this.processedPositions = [];
      this.processedPositionsTomorrow = [];
      this.processedPositionsYesterday = [];
      this.positionsLoaded = false;
      this.errorMessage = '';
      this.speedMultiplier = 1;
      this.pausePlayback();
      // fetch & process data
      if (this.validDate) {
        let positions;
        try {
          positions = await getTruckData(inputDate, endDate);
        } catch (e) {
          console.log('error on getTruckData:', e);
          this.errorStatus = 'error';
          this.errorMessage =
            'There was an issue with the server. Try again later';
          this.positionsLoaded = true;
        }
        this.processedPositions = this.processPositions(positions, 'today');
        this.cleanMarkers();
        this.sliderValue = 0;
        this.positionsLoaded = true;
      }
      this.adjacentDayLoad('tomorrow');
      this.adjacentDayLoad('yesterday');
    },
    // load then process tomorrow or yesterday's data
    async adjacentDayLoad(day) {
      let currentDate = Date.parse(this.date);
      let startDate, endDate, positions;
      day = day.toLowerCase();
      if (day === 'tomorrow') {
        startDate = currentDate + 86400000;
        endDate = startDate + 86400000;
      } else if (day === 'yesterday') {
        startDate = currentDate - 86400000;
        endDate = currentDate;
      } else {
        console.error('Error calling adjacentDayLoad(); check params');
        return;
      }

      try {
        positions = await getTruckData(startDate, endDate);
      } catch (e) {
        console.log(`error on getTruckData for ${day}'s data:`, e);
      }

      if (day === 'tomorrow') {
        this.processedPositionsTomorrow = this.processPositions(positions, day);
      } else {
        this.processedPositionsYesterday = this.processPositions(
          positions,
          day
        );
      }
    },
    fastForward() {
      this.playing = false;
      if (this.speedMultiplier <= 1) {
        this.setPlayback(2);
      } else if (this.speedMultiplier === 2) {
        this.setPlayback(4);
      } else {
        this.setPlayback(2);
      }
    },
    rewind() {
      this.playing = false;
      if (this.speedMultiplier === -1) {
        this.setPlayback(-2);
      } else if (this.speedMultiplier === -2) {
        this.setPlayback(-4);
      } else {
        this.setPlayback(-1);
      }
    },
    skipNext() {
      this.cleanMarkers();
      // reassign data structures; today is now yesterday; tomorrow is now today;
      this.processedPositionsYesterday = this.processedPositions;
      this.yesterdayTrucks = this.currentTrucks;
      this.processedPositions = this.processedPositionsTomorrow;
      this.processedPositionsTomorrow = [];
      this.currentTrucks = this.tomorrowTrucks;
      this.tomorrowTrucks = null;
      this.date = dateFormat(
        Date.parse(this.date) + 86400000,
        'yyyy-mm-dd',
        true
      );
      this.sliderValue = 0;
      // load data for new tomorrow
      this.adjacentDayLoad('tomorrow');
    },
    skipPrevious() {
      this.cleanMarkers();
      // reassign data structures; today is now tomorrow; yesterday is now today;
      this.processedPositionsTomorrow = this.processedPositions;
      this.tomorrowTrucks = this.currentTrucks;
      this.processedPositions = this.processedPositionsYesterday;
      this.processedPositionsYesterday = [];
      this.currentTrucks = this.yesterdayTrucks;
      this.yesterdayTrucks = null;
      this.date = dateFormat(
        Date.parse(this.date) - 86400000,
        'yyyy-mm-dd',
        true
      );
      // load data for new yesterday
      this.adjacentDayLoad('yesterday');
    },
    setPlayback(speedMultiplier) {
      this.speedMultiplier = speedMultiplier; // track multiplier for rewind & fast-forward features
      clearInterval(this.intervalID);
      this.intervalID = setInterval(() => {
        if (this.sliderValue === 1440 && speedMultiplier > 0) {
          this.sliderValue = 0;
        }
        this.sliderValue += 5 * speedMultiplier;
      }, 100);
    },
    clear() {
      this.date = null;
      this.pausePlayback();
      this.cleanMarkers();
      this.populateTrucks();
    },
    async buildPie(inputData) {
      this.labels = [];
      this.pieData = [];
      let classifications = {
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
      };
      const res = await getRouteClassification(inputData);
      for (const item of res.data) {
        let level = Math.round(item.classification);
        if (level === 0) level = 1;
        if (level === 6) level = 5;
        classifications[level] += item.segment_length_mi;
      }
      for (const key in classifications) {
        if (classifications[key] != 0) {
          this.pieData.push(Math.round(classifications[key] * 10) / 10); // nearest tenth
          this.labels.push('class ' + key);
        }
      }
      this.pieLoaded = true;
    },
    updateLocations() {
      clearInterval(this.locationInterval);
      // decreasing frequency since adx is very slow right now
      const frequency = this.clustering ? 90000 : 90000;
      this.locationInterval = setInterval(() => {
        this.today = new Date();
        this.populateTrucks();
      }, frequency);
    },
    async displayLandmarks() {
      const H = window.H;
      const res = await getGeozones();
      for (const lm of res.data) {
        if (lm.shape.toLowerCase() === 'polygon') {
          const lineString = new H.geo.LineString(
            lm.coordinates_array,
            'values lat lng alt'
          );

          this.hereMap.addObject(
            new H.map.Polygon(lineString, {
              style: {
                fillColor: 'rgba(251,140,0,0.3)',
                strokeColor: 'rgba(33, 150, 243, 0.3)',
                lineWidth: 2,
                zIndex: -1,
              },
            })
          );
        } else if (lm.shape.toLowerCase() === 'circle') {
          this.hereMap.addObject(
            new H.map.Circle(
              { lat: lm.latitude, lng: lm.longitude },
              lm.radius_meters,
              {
                style: {
                  fillColor: 'rgba(251,140,0,0.3)',
                  strokeColor: 'rgba(33, 150, 243, 0.3)',
                  lineWidth: 2,
                  zIndex: -1,
                },
              }
            )
          );
        } else if (lm.shape.toLowerCase() === 'polyline') {
          // create polyline
          // add in later story
        }
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#map {
  width: 100% !important;
  height: 100%;
}
#selectDate {
  display: block;
  margin: auto;
  margin-top: 8px;
  margin-bottom: 10px;
}
.iconButton {
  cursor: pointer;
}
#dateWarning {
  color: red;
  text-align: center;
}
.center {
  display: block;
  margin: auto;
}
.trailerConfig {
  margin-top: 0px;
}
.datetimeLabel {
  font-weight: 700;
}
.mapDate {
  padding-right: 10px;
}
.datetime {
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
}
.datetimeLine {
  padding-right: 7px;
}
.playbackDatetime {
  padding-top: 5px;
}
.description {
  color: gray;
}
.pieChart {
  position: absolute;
  bottom: 0px;
  right: 0px;
  height: 270px;
  width: 270px;
}
.controlsBar {
  display: flex;
  justify-content: space-between;
  margin-top: -15px;
}
.rewindSpeed {
  position: absolute;
  bottom: 30px;
  left: 95px;
  font-size: small;
}
.forwardSpeed {
  position: absolute;
  bottom: 30px;
  left: 245px;
  font-size: small;
}
.loader {
  position: absolute;
  z-index: 100;
  top: 47%;
  left: 50%;
}
</style>

<!-- global style changes required to change HERE infoBubble CSS -->
<style>
.H_ib_body {
  background: #1a1a1a;
  border-radius: 4px;
}
.H_ib_close .H_btn {
  color: white;
  background-color: white;
  fill: white;
}
.H_ib_close {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(257deg)
    brightness(116%) contrast(101%);
}
.H_ib.H_ib_top .H_ib_tail::after {
  border-color: #1a1a1a transparent;
}
</style>
