var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      staticClass: "dialog",
      attrs: { width: "750" },
      model: {
        value: _vm.openDialog,
        callback: function ($$v) {
          _vm.openDialog = $$v
        },
        expression: "openDialog",
      },
    },
    [
      _vm.loading ? _c("loader") : _vm._e(),
      _vm.showAlert
        ? _c("alert", {
            attrs: {
              "alert-type": _vm.alertType,
              "alert-message": _vm.alertMessage,
            },
          })
        : _vm._e(),
      _c(
        "v-card",
        { staticClass: "pa-4" },
        [
          _c("v-file-input", {
            attrs: {
              type: "file",
              accept:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              "truncate-length": "30",
              label: "Upload DTC File",
            },
            on: { change: _vm.selectDTCFile },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }