import dateFormat from 'dateformat';
import _ from 'lodash';
const KPL_TO_MPG = 2.35215;
const KPKG_TO_MPLB = 3.548; // => km/kg to mi/lb
const KM_TO_MI = 1.60934;
const KG_TO_LBS = 2.20462;
const L_TO_GAL = 1 / 3.78541;
const MPS_TO_FPS = 3.28084;
const regionalSetting = (setting, data, type, table = false, precision = 0) => {
  // Formats Date Preference
  if (type === 'date') {
    return dateFormat(data, setting);
  }
  // Only applies to (Table) data for null or undefined values
  if (
    table &&
    (data === null ||
      data === undefined ||
      isNaN(parseInt(data)) ||
      !isFinite(data))
  ) {
    return '—';
  }
  // Only applies to data for null or undefined values
  if (data === null || data === undefined) {
    return null;
  }

  if (data === 0 || data === '0' || !isFinite(data)) {
    // If data is 0, or malformed return 0
    return 0;
  }

  // Checks if data is showing up as NaN that is not supposed to be a string. Date has been moved up top to prevent issues
  if (type !== 'odometer' && type !== 'weight' && isNaN(data)) {
    return 0;
  }
  // Formats Fuel Economy Preference(Temporarily Integrating this with regional preferences)
  // if (type === 'fuel_economy') {
  //   if (setting === 'mpge') {
  //     return Math.round(data * KPL_TO_MPG * 100) / 100; // kpl -> mpg conversion
  //   } else {
  //     return Math.round(data * 100) / 100;
  //   }
  // }

  // Formats Regional Preference
  if (setting === 'us/imperial') {
    if (type === 'speed' || type === 'distance') {
      return _.round(data / KM_TO_MI, precision); // Km -> Mi conversion
    } else if (type === 'distance_s') {
      return _.round(data * MPS_TO_FPS, precision); // m/s^2 -> ft/s^2 conversion
    } else if (type === 'odometer') {
      return _.round(data / KM_TO_MI, precision)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ','); // Km -> Mi conversion
    } else if (type === 'temperature') {
      return _.round(data * (9 / 5) + 32, precision); // Celcuis to Fahrenheit conversion
    } else if (type === 'weight') {
      return _.round(data * KG_TO_LBS, precision).toLocaleString(); // kg -> lbs converion (String)
    } else if (type === 'weight_n') {
      // use weight_n in areas such as TripGraphERX where calculations are needed
      return _.round(data * KG_TO_LBS, precision);
    } else if (type === 'volume') {
      return _.round(data * L_TO_GAL, precision);
    } else if (type === 'fuel_economy') {
      return _.round(data * KPL_TO_MPG, precision);
    } else if (type === 'distance/weight') {
      return _.round(data * KPKG_TO_MPLB, precision);
    }
  } else if (setting === 'metric') {
    if (type === 'odometer') {
      return _.round(data, precision).toLocaleString();
    } else {
      return _.round(data, precision);
    }
  }
  return data;
};

export { regionalSetting };
