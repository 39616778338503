import axios from 'axios';

const BASE_URL = process.env.VUE_APP_API_PATH_BASE;

async function getTriggers() {
  return await axios.get(BASE_URL + `studies/trigger_library/all/triggers`);
}

async function getTriggerOperators() {
  return await axios.get(BASE_URL + `studies/triggers/trigger_operators`);
}

async function getTriggerTypes() {
  return await axios.get(BASE_URL + `studies/triggers/trigger_types`);
}

async function updateTriggers(id, data) {
  return await axios.patch(BASE_URL + `studies/trigger_library/${id}`, data);
}

async function addStudy(data) {
  return await axios.post(BASE_URL + `studies/`, data);
}

async function updateStudy(data) {
  return await axios.patch(BASE_URL + `studies/`, data);
}

async function removeStudy(id) {
  return await axios.delete(BASE_URL + `studies/${id}`);
}

async function addTrigger(data) {
  return await axios.post(BASE_URL + `studies/trigger_library/`, data);
}

async function getVisibility() {
  return await axios.get(BASE_URL + `studies/study_visibility`);
}

async function getStudies(isPublic) {
  return await axios.get(BASE_URL + `studies/?only_public=${isPublic}`);
}

async function getServiceStudies(truck_type) {
  return await axios.get(
    BASE_URL + `studies/service_study?truck_type=${truck_type}`
  );
}

async function getCandlestick(queryString, truck_type = 'EX') {
  let ttString = '';
  if (truck_type?.toUpperCase() === 'ERX') ttString = '/erx';
  return await axios.get(
    BASE_URL + `telemetry/time_series/candle_stick${ttString}${queryString}`
  );
}

async function getZeroBasedLine(queryString) {
  return await axios.get(
    BASE_URL + `telemetry/time_series/zero_based_line${queryString}`
  );
}

async function getAbsoluteTimeGraph(queryString, truck_type = null) {
  let truck_type_str = '';
  if (truck_type?.toUpperCase() === 'ERX') truck_type_str = '/erx';
  return await axios.get(
    BASE_URL +
      `telemetry/time_series/absolute_time_graph${truck_type_str}${queryString}`
  );
}

export {
  getTriggers,
  getTriggerOperators,
  getTriggerTypes,
  getCandlestick,
  getZeroBasedLine,
  getAbsoluteTimeGraph,
  addStudy,
  addTrigger,
  updateTriggers,
  updateStudy,
  removeStudy,
  getVisibility,
  getStudies,
  getServiceStudies,
};
