var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-progress-circular", {
    staticClass: "loader",
    attrs: { color: "primary", size: 70, width: 7, indeterminate: "" },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }