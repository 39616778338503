<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <div class="perfBar">
          <v-row>
            <v-col xl="8" sm="12">
              <v-tabs
                id="external-toolbar-tab"
                v-model="healthTab"
                background-color="secondary"
                grow
                height="162px"
              >
                <v-tab v-for="item in healthTabItems" :key="item.title">
                  <template v-if="item.tooltip">
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <span :key="item.title" :class="item.class"
                          >{{ item.title }}<br />
                          <h2 class="pt-1">{{ item.count }}</h2></span
                        >

                        <v-icon
                          style="
                            padding-left: 4px;
                            margin-bottom: 30px;
                            color: #979797;
                          "
                          small
                          v-bind="attrs"
                          v-on="on"
                          >mdi-information</v-icon
                        >
                      </template>
                      <span>{{ item.info }}</span>
                    </v-tooltip>
                  </template>
                  <template v-else>
                    <span :class="item.class">
                      {{ item.title }}
                      <br />
                      <h2 class="pt-1">{{ item.count }}</h2>
                    </span>
                  </template>
                </v-tab>
              </v-tabs>
            </v-col>
          </v-row>
        </div>
        <v-tabs-items v-model="healthTab">
          <v-tab-item v-for="item in healthTabItems" :key="item.tab">
            <v-card flat class="dark-background">
              <faulted-table
                v-if="item.content === 'faulted'"
                :faulted-trucks="faultedTrucks"
                :toggle-details="toggleDetails"
                :set-details="setDetails"
              />
              <in-shop-table
                v-if="item.content === 'inShop'"
                :in-shop-trucks="inShopTrucks"
                :toggle-details="toggleDetails"
                :set-details="setDetails"
              />
              <not-in-use-table
                v-if="item.content === 'notInUse'"
                :not-in-use-trucks="notInUseTrucks"
                :toggle-details="toggleDetails"
                :set-details="setDetails"
              />
              <normal-table
                v-if="item.content === 'normalOperations'"
                :normal-trucks="normalTrucks"
                :toggle-details="toggleDetails"
                :set-details="setDetails"
              />
              <!-- <v-row v-if="item.content === 'preventativeMaintenance'">
                <v-col cols="2" align="left"></v-col>
                <v-col id="TruckGraphic" cols="10" align="left"></v-col>
                <v-col cols="2" align="left"></v-col>
              </v-row> -->
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FaultedTable from './FaultedTable.vue';
import InShopTable from './InShopTable.vue';
import NotInUseTable from './NotInUseTable.vue';
import NormalTable from './NormalTable.vue';

import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'ExternalHealth',
  components: {
    'faulted-table': FaultedTable,
    'in-shop-table': InShopTable,
    'not-in-use-table': NotInUseTable,
    'normal-table': NormalTable,
  },
  props: {
    truckData: {
      type: Array,
      required: true,
    },
    toggleDetails: {
      type: Function,
      default: () => {},
    },
    setDetails: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      healthTab: 0,
      faultedTrucks: [],
      notInUseTrucks: [],
      inShopTrucks: [],
      normalTrucks: [],
      healthTabItems: [
        {
          title: 'Faulted',
          content: 'faulted',
          info: 'Trucks that have an active fault but still in operation.',
          count: 0,
          tooltip: true,
          class: 'left',
        },
        {
          title: 'In Shop',
          info: 'Trucks that are within a defined geozone for a service station.',
          content: 'inShop',
          count: 0,
          tooltip: true,
          class: 'left',
        },
        {
          title: 'Not In Use',
          content: 'notInUse',
          info: 'Trucks that have not been online for over 48 hours.',
          count: 0,
          tooltip: true,
          class: 'left',
        },
        {
          title: 'Normal Operation',
          content: 'normalOperations',
          info: 'Trucks operating without any issues.',
          count: 0,
          tooltip: true,
          class: 'left',
        },
        // {
        //   title: 'Preventative Maintenance',
        //   content: 'preventativeMaintenance',
        //   count: 'Coming Soon',
        //   class: 'center',
        // },
      ],
    };
  },
  computed: {
    ...mapGetters({
      spnfmiStates: 'getSpnfmiStates',
      userPreferences: 'getUserPreferences',
    }),
  },
  watch: {
    healthTab() {
      switch (this.healthTab) {
        case 0:
          this.updateCurrentScreen({ healthTab: 'Faulted' });
          break;
        case 1:
          this.updateCurrentScreen({ healthTab: 'In Shop' });
          break;
        case 2:
          this.updateCurrentScreen({ healthTab: 'Not in Use' });
          break;
        case 3:
          this.updateCurrentScreen({ healthTab: 'Normal Operation' });
          break;
        case 4:
          this.updateCurrentScreen({ healthTab: 'Preventative Maintenance' });
          break;
      }
    },
  },
  created() {
    this.tallyHealthToolbar();
  },
  methods: {
    ...mapMutations(['updateCurrentScreen']),
    tallyHealthToolbar() {
      let faulted = 0;
      let in_shop = 0;
      let not_in_use = 0;
      let normal = 0;
      this.truckData.forEach((t) => {
        switch (t.type) {
          case 'fault':
            this.faultedTrucks.push(t);
            faulted++;
            break;
          case 'inShop':
            this.inShopTrucks.push(t);
            in_shop++;
            break;
          case 'notInUse':
            this.notInUseTrucks.push(t);
            not_in_use++;
            break;
          case 'normal':
            this.normalTrucks.push(t);
            normal++;
        }
      });
      this.healthTabItems[0].count = faulted;
      this.healthTabItems[1].count = in_shop;
      this.healthTabItems[2].count = not_in_use;
      this.healthTabItems[3].count = normal;
    },
  },
};
</script>

<style>
#TruckGraphic {
  width: 1869px;
  height: 800px;
  background: url('../../../../public/images/assets/TruckGraphic.png') no-repeat;
}
.left {
  text-align: left;
}
.center {
  text-align: center;
}
/* Custom toolbar config*/
#external-toolbar-tab .v-tab--active {
  background-color: #272727;
}

#external-toolbar-tab .v-tabs-slider-wrapper {
  top: 0 !important;
}
/* removes unwanted prefix gap in v-tabs  */
.v-slide-group__prev {
  display: none !important;
}
</style>

<style scoped>
.perfBar {
  background-color: #272727;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
  padding: 10px;
  min-height: 182px;
}
</style>
