<template>
  <v-container fluid>
    <v-row class="ml-2">
      <v-col cols="3">
        <h1 class="centerText">Settings</h1>
      </v-col>
      <v-col align="center" cols="6"
        ><LiveFleetViewBanner
          v-if="selectedCompany && liveView"
          :fleet-info="selectedCompany"
          @closed="liveView = false"
      /></v-col>
    </v-row>
    <v-row class="ml-2">
      <v-col xl="2" lg="2" md="10" sm="10" xs="10">
        <v-tabs v-model="tab" vertical hide-slider>
          <v-tab
            v-for="item in items"
            :key="item.tab"
            class="settings-tabs"
            style="justify-content: flex-start; max-width: 100%"
          >
            <v-icon left> {{ item.icon }} </v-icon>
            {{ item.tab }}
          </v-tab>
        </v-tabs>
      </v-col>
      <v-col xl="10" lg="10" md="10" sm="10" xs="10" class="pt-0 pl-0">
        <FleetSettings v-if="tab === 0 && companyId" :company-id="companyId" />
        <!-- Temp: 1.1 Hiding Individual View from Customer-->
        <IndividualSettings v-if="tab === 1 && isHyliion" />
        <!-- <UserManagementSettings v-if="tab === 2" /> -->
      </v-col>
    </v-row>
    <br />
  </v-container>
</template>

<script>
import FleetSettings from './FleetSettings.vue';
import IndividualSettings from './IndividualSettings.vue';
// import UserManagementSettings from './UserManagementSettings.vue';
import LiveFleetViewBanner from '@/utilities/LiveFleetViewBanner.vue';

import { mapGetters } from 'vuex';
export default {
  name: 'ExternalSettings',
  components: {
    FleetSettings,
    IndividualSettings,
    // UserManagementSettings, // Will uncomment when ready
    LiveFleetViewBanner,
  },
  data() {
    return {
      tab: null,
      items: [
        { tab: 'Fleet', icon: 'mdi-truck' },
        // { tab: 'User Management', icon: 'mdi-account-group' },
      ],
      liveView: false,
      companyId: null,
      isHyliion: false,
    };
  },
  computed: {
    ...mapGetters({
      selectedCompany: 'getSelectedLiveFleet',
      companies: 'getCompanies',
    }),
  },
  mounted() {
    this.setCompany();
  },
  methods: {
    setCompany() {
      // Hyliion Users
      if (this.companies.length > 1 && this.companies.find((c) => c.id === 4)) {
        if (this.selectedCompany) {
          // If user has Live Fleet View selected
          this.companyId = this.selectedCompany.id;
          if (this.selectedCompany.id !== 4) {
            this.liveView = true;
          } else {
            // Temp: 1.1 Only showing Individual view for Hyliion Users w/o Fleet View
            this.items.push({ tab: 'Individual', icon: 'mdi-account' });
            this.isHyliion = true;
          }
        } else {
          this.companyId = 4; // Default to Hyliion Company
          // Temp: 1.1 Only showing Individual view for Hyliion Users w/o Fleet View
          this.items.push({ tab: 'Individual', icon: 'mdi-account' });
          this.isHyliion = true;
        }
      } else {
        // External Users
        this.companyId = this.companies[0].id;
      }
    },
  },
};
</script>

<style scoped>
.settings-tabs.v-tab--active {
  background-color: white;
  color: black !important;
}
</style>
