var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "trucks" } },
    [
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "py-0" },
                [
                  _c("v-data-table", {
                    staticClass:
                      "elevation-1 secondary locked-table-style row-height-50",
                    attrs: {
                      headers: _vm.headers,
                      items: _vm.exTrucks,
                      search: _vm.searchText,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: `item.name`,
                          fn: function (props) {
                            return [
                              _c("th", { staticClass: "body-5" }, [
                                _vm._v(_vm._s(props.item.number)),
                              ]),
                              _c("div", { staticClass: "body-6" }, [
                                _vm._v(_vm._s(props.item.company)),
                              ]),
                            ]
                          },
                        },
                        {
                          key: `item.actions`,
                          fn: function ({ item }) {
                            return [
                              _c(
                                "v-menu",
                                {
                                  staticStyle: { top: "312px", left: "300px" },
                                  attrs: { "offset-y": "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on }) {
                                          return [
                                            _c("v-icon", _vm._g({}, on), [
                                              _vm._v("mdi-dots-vertical"),
                                            ]),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _c(
                                    "v-list",
                                    _vm._l(
                                      _vm.actionItems,
                                      function (route, index) {
                                        return _c(
                                          "v-list-item",
                                          {
                                            key: index,
                                            class: route.title,
                                            on: {
                                              click: function ($event) {
                                                return _vm.setRoute(
                                                  item,
                                                  route.title
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(_vm._s(route.title))]
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }