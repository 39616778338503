import axios from 'axios';

const BASE_URL = process.env.VUE_APP_API_PATH_BASE;
// USING EXTERNAL/CUSTOMER API ROUTE
async function getPref(appId) {
  return await axios.get(BASE_URL + `preferences/appId/${appId}`);
}
// USING EXTERNAL/CUSTOMER API ROUTE
async function putPref(appId, prefVisible) {
  await axios.put(BASE_URL + `preferences/appId/${appId}`, prefVisible);
}

export { getPref, putPref };
