<template>
  <div>
    <faulted-table v-if="type === 'fault'" :trucks="trucks" :actions="false" />
    <in-shop-table
      v-else-if="type === 'inShop'"
      :trucks="trucks"
      :actions="false"
    />
    <not-in-use-table
      v-else-if="type === 'notInUse'"
      :trucks="trucks"
      :actions="false"
    />
    <normal-table
      v-else-if="type === 'normal'"
      :trucks="trucks"
      :actions="false"
    />
  </div>
</template>

<script>
import FaultedTableTemplate from './TableUtils/FaultedTableTemplate';
import NotInUseTableTemplate from './TableUtils/NotInUseTableTemplate';
import InShopTableTemplate from './TableUtils/InShopTableTemplate';
import NormalTableTemplate from './TableUtils/NormalTableTemplate';
export default {
  name: 'ExternalTruckDetail',
  components: {
    'faulted-table': FaultedTableTemplate,
    'not-in-use-table': NotInUseTableTemplate,
    'in-shop-table': InShopTableTemplate,
    'normal-table': NormalTableTemplate,
  },
  props: {
    truck: {
      type: Object,
      default: null,
    },
    map: {
      type: Object,
      default: null,
    },

    mapTrucks: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      trucks: [],
      type: null,
    };
  },
  computed: {},
  watch: {
    truck() {
      this.setTruck();
    },
  },
  mounted() {
    this.setTruck();
  },
  methods: {
    setTruck() {
      if (this.truck) {
        this.trucks = [this.truck];
        this.type = this.truck.type;
        for (const marker of this.mapTrucks) {
          if (this.truck.truckID === marker.data.truck_id) {
            this.map.setCenter(marker.a);
            this.map.setZoom(10);
            marker.setZIndex(100);
          }
        }
      }
    },
  },
};
</script>
