<template>
  <div v-if="exists">
    <div
      v-if="showBtn"
      class="closeBtn"
      style="cursor: pointer"
      @click="toggle"
    >
      <slot name="icon"
        ><v-icon style="margin-bottom: 6px">mdi-close-circle</v-icon></slot
      >
      Close
    </div>
    <div class="bottomDrawer" :style="drawerStyle">
      <slot name="default"> </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BottomDrawer',
  props: {
    model: {
      type: Boolean,
      default: false,
    },
    toggle: {
      type: Function,
      default: () => (this.model = !this.model),
    },
    type: {
      type: String,
      default: () => 'default',
    },
  },
  data() {
    return {
      exists: true,
    };
  },
  computed: {
    drawerStyle() {
      if (this.type.toLowerCase() === 'detail') {
        return {
          height: this.model ? '300px' : '0px',
        };
      } else if (this.model === false) {
        return {
          height: this.model ? '90%' : '300px',
          overflowY: 'hidden', // hide scroll when default drawer is closed
        };
      } else {
        return {
          height: this.model ? '90%' : '300px',
        };
      }
    },
    showBtn() {
      // destroy btn on false for type detail
      if (this.type.toLowerCase() === 'detail') {
        return this.model;
      } else {
        return true;
      }
    },
  },
  watch: {
    model() {
      if (this.type === 'default') return;
      // destroy DOM content on false for type detail
      if (this.type.toLowerCase() === 'detail') {
        if (this.model === false) {
          const vm = this;
          setTimeout(() => (vm.exists = false), 200);
        } else {
          this.exists = true;
        }
      }
    },
  },
};
</script>

<style scoped>
.bottomDrawer {
  position: absolute;
  bottom: 0;
  width: 100%;
  overflow: hidden;
  height: 300px; /* initially */
  background: #1a1a1a;
  z-index: 15;
  transition: all 0.2s;
  overflow-y: auto;
}
.closeBtn {
  position: absolute;
  bottom: 300px;
  width: 94px;
  right: 48px;
  border-radius: 4px;
  margin-bottom: -4px;
  /* overflow: hidden; */
  height: 50px;
  background: #1a1a1a;
  z-index: 10;
  padding: 10px;
  text-align: center;
}
</style>
