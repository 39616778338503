<!-- DOCUMENTATION

  To invoke this component anywhere in the app, simply
  call the updateSnack() ACTION with vuex. The snack will 
  auto-dissapear after a few seconds

  EX:
    import { mapActions } from 'vuex'

    methods: {
      ...mapActions(['updateSnack']),
    },

    mounted() {

        this.updateSnack({
          model: true, // optional; will default to true if undefined
          type: 'success', // possible types are 'success', 'warning', 'info', 'error', null
          message: 'Desired snackbar message here' 
        });

    }



-->
<template v-if="snack.model">
  <v-snackbar v-model="snack.model" top :color="snack.type" :timeout="-1">
    <v-icon class="snackIcon">{{ feedbackIcon }}</v-icon>
    {{ snack.message }}
    <template v-slot:action="{ attrs }">
      <v-btn v-bind="attrs" text @click="closeSnack"> Close </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'SnackBar',
  computed: {
    ...mapGetters({
      snack: 'getSnack',
    }),
    feedbackIcon() {
      if (this.snack.type?.toLowerCase() === 'success') {
        return 'mdi-check-circle-outline';
      } else if (this.snack.type?.toLowerCase() === 'warning') {
        return 'mdi-alert-outline';
      } else if (this.snack.type?.toLowerCase() === 'info') {
        return 'mdi-information-outline';
      } else if (this.snack.type?.toLowerCase() === 'error') {
        return 'mdi-alert-circle-outline';
      } else return '';
    },
  },
  methods: {
    ...mapActions({
      updateSnack: 'updateSnack',
    }),
    closeSnack() {
      this.updateSnack({
        model: false,
      });
    },
  },
};
</script>

<style scoped>
.snackIcon {
  margin-top: -7px;
  margin-right: 3px;
}
</style>
