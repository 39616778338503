<template>
  <v-dialog v-model="show" width="800" persistent>
    <v-card>
      <v-card-title> {{ cardTitle }}</v-card-title>
      <v-card-text>Enter Graph parameters below</v-card-text>
      <v-divider></v-divider>
      <v-form ref="form" v-model="formValid">
        <v-container>
          <v-row>
            <v-col sm="2" class="formLabel">Name</v-col>
            <v-col sm="10">
              <v-text-field
                v-model="name"
                class="formInput"
                outlined
                label="Name"
                :rules="rules.required"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="2" class="formLabel"> Description: </v-col>
            <v-col>
              <v-textarea
                v-model="description"
                class="formInput"
                outlined
                label="Description"
                required
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="2" class="formLabel"> Graph Type: </v-col>
            <v-col sm="10">
              <v-select
                v-model="selectedGraph"
                class="formInput"
                :items="graphTypes"
                outlined
                label="Graph Type"
                :rules="rules.required"
                required
              ></v-select>
            </v-col>
          </v-row>
          <div
            v-if="selectedGraph === 'Candlestick' || selectedGraph === 'Jitter'"
            class="candlestickForm"
          >
            <v-row>
              <v-col sm="2" class="formLabel">Signal:</v-col>
              <v-col sm="10">
                <v-combobox
                  v-model="selectedSignal"
                  class="formInput"
                  outlined
                  :items="signals"
                  return-object
                  item-text="signal"
                  label="Signal"
                  :error-messages="signalMessage"
                  :rules="rules.required"
                ></v-combobox>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="2" class="formLabel"> Start Trigger: </v-col>
              <v-col sm="10">
                <v-select
                  v-model="selectedStart"
                  class="formInput"
                  return-object
                  :items="triggers"
                  item-text="name"
                  outlined
                  label="Start"
                  :rules="rules.required"
                  required
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="2" class="formLabel"> End Trigger: </v-col>
              <v-col sm="10">
                <v-select
                  v-model="selectedEnd"
                  class="formInput"
                  return-object
                  :items="triggers"
                  item-text="name"
                  item-value="id"
                  outlined
                  label="End"
                  :rules="rules.required"
                  required
                ></v-select>
              </v-col>
            </v-row>
            <v-row v-if="selectedEnd === 'Duration'" align-self="start">
              <v-col sm="2" class="formLabel"> Seconds: </v-col>
              <v-col sm="3">
                <v-text-field
                  v-model="seconds"
                  class="formInput"
                  outlined
                  type="number"
                  label="Seconds"
                  :rules="rules.required"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="selectedGraph === 'Jitter (ERX only)'">
              <v-col sm="2" class="formLabel"> Signal Plotted: </v-col>
              <v-col sm="10">
                <v-radio-group v-model="jitterSignal">
                  <v-radio label="Max" value="max"></v-radio>
                  <v-radio label="Min" value="min"></v-radio>
                  <v-radio label="Max & Min" value="maxmin"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </div>
          <div
            v-if="selectedGraph === 'Absolute Time Line Graph'"
            class="candlestickForm"
          >
            <v-row>
              <v-col sm="2" class="formLabel">Signals:</v-col>
              <v-col sm="10">
                <v-combobox
                  v-model="selectedSignals"
                  class="formInput"
                  outlined
                  multiple
                  :items="signals"
                  return-object
                  item-text="signal"
                  label="Choose one or more signals"
                  :rules="rules.signalRequired"
                  required
                ></v-combobox>
              </v-col>
            </v-row>
          </div>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close"> Cancel </v-btn>
          <v-btn color="primary" :disabled="!formValid" @click="addGraph">
            {{ submitText }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AddGraph',
  props: {
    graphs: {
      type: Array,
      default: () => [],
    },
    triggers: {
      type: Array,
      default: () => [],
    },
    signals: {
      type: Array,
      default: () => [],
    },
    // object of the graph to be edited
    // presence determines whether text is for edit graph or add graph
    editGraph: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      show: true,
      dialog: true,
      selectedGraph: null,
      signalMessage: '',
      formValid: false,
      addDisabled: true,
      name: null,
      description: null,
      seconds: null,
      selectedSignals: [],
      selectedSignal: null,
      selectedStart: null,
      selectedEnd: null,
      // endTriggers: [{ id: -1, name: 'Duration' }], // support for duration might be added in future story
      rules: {
        required: [(v) => !!v || 'This field is required'],
        signalRequired: [
          (s) => s.length > 0 || 'At least one signal is required',
        ],
      },
      graphTypes: ['Candlestick', 'Absolute Time Line Graph', 'Jitter'],
    };
  },
  computed: {
    cardTitle() {
      return this.editGraph ? 'Edit Graph' : 'Add Graph';
    },
    submitText() {
      return this.editGraph ? 'Save' : 'Add Graph';
    },
  },
  watch: {
    show() {
      if (this.show) this.setFields();
      else this.$emit('close');
    },
  },
  mounted() {
    this.setFields();
  },
  methods: {
    addGraph() {
      let signals, start_trigger, end_trigger;
      if (
        this.selectedGraph === 'Candlestick' ||
        this.selectedGraph === 'Jitter'
      ) {
        signals = [this.selectedSignal].map((s) => s.id);
        start_trigger = this.selectedStart.id;
        end_trigger = this.selectedEnd.id;
      } else {
        signals = this.selectedSignals.map((s) => s.id);
        start_trigger = null;
        end_trigger = null;
      }

      // EDIT or ADD graph
      if (this.editGraph) {
        const graphIds = this.graphs.map((g) => g.id);
        const i = graphIds.indexOf(this.editGraph.id);
        // vuetify data-table requires object properties be augmented
        // instead of whole object replaced for reactive behavior
        this.graphs[i].name = this.name;
        this.graphs[i].description = this.description;
        this.graphs[i].graph_type = this.selectedGraph;
        this.graphs[i].signals = signals;
        this.graphs[i].start_trigger = start_trigger;
        this.graphs[i].end_trigger = end_trigger;
      } else {
        this.graphs.push({
          // temp id so we can still delete graphs by filtering by id
          id: Math.floor(Math.random() * Date.now()),
          name: this.name,
          description: this.description,
          graph_type: this.selectedGraph,
          signals: signals,
          start_trigger: start_trigger,
          end_trigger: end_trigger,
        });
      }
      this.close();
    },
    close() {
      this.$refs.form.reset();
      this.show = false;
    },
    // set up fields if editGraph object is present
    setFields() {
      if (this.editGraph) {
        this.name = this.editGraph.name;
        this.description = this.editGraph.description;
        this.selectedGraph = this.editGraph.graph_type;
        if (
          this.selectedGraph === 'Candlestick' ||
          this.selectedGraph === 'Jitter'
        ) {
          this.selectedSignal = this.signals.filter((s) =>
            this.editGraph.signals.includes(s.id)
          )[0];
        } else {
          this.selectedSignals = this.signals.filter((s) =>
            this.editGraph.signals.includes(s.id)
          );
        }

        this.selectedStart = this.triggers.filter(
          (t) => t.id === this.editGraph.start_trigger
        )[0];
        this.selectedEnd = this.triggers.filter(
          (t) => t.id === this.editGraph.end_trigger
        )[0];
      }
    },
  },
};
</script>

<style scoped>
.formLabel {
  display: block;
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;
}
.formInput {
  margin-bottom: -20px;
}
.candlestickForm {
  margin-top: 10px;
}
</style>
