<template>
  <v-container grid-list-md fluid>
    <v-navigation-drawer
      v-model="drawer"
      right
      temporary
      fixed
      :width="360"
      class="shade"
    >
      <v-container>
        <v-row align="center">
          <v-col align="right" class="mb-2">
            <v-btn icon @click="drawer = false">
              <v-icon light>mdi-close-circle </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-expansion-panels v-model="panel" multiple>
          <v-expansion-panel class="shade">
            <v-expansion-panel-header>Generate Report</v-expansion-panel-header>
            <v-expansion-panel-content>
              <p>Truck Type:</p>
              <v-radio-group v-model="truckType" :disabled="truckTypeDisabled">
                <v-radio key="0" label="EX" value="EX"></v-radio>
                <v-radio key="1" label="ERX" value="ERX"></v-radio>
              </v-radio-group>
              <p>One row per:</p>
              <v-radio-group v-model="rowDef">
                <v-radio :key="0" :label="'Day'" :value="0"></v-radio>
                <v-radio :key="1" :label="'Week'" :value="1"></v-radio>
                <v-radio :key="2" :label="'Month'" :value="2"></v-radio>
              </v-radio-group>
              <div class="center">
                <p>Date Range:</p>
              </div>
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="dates"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateInput"
                    v-mask="'##/##/#### - ##/##/####'"
                    type="text"
                    outlined
                    append-icon="mdi-calendar"
                    v-bind="attrs"
                    @click:append="on.click"
                  >
                  </v-text-field>
                </template>
                <v-date-picker v-model="dates" range>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="saveDates"> OK </v-btn>
                </v-date-picker>
              </v-menu>
              <div id="dateGuide">MM/DD/YYYY - MM/DD/YYYY</div>
              <v-row>
                <v-col>
                  <v-select
                    v-model="company"
                    :items="companies"
                    item-text="name"
                    label="Company"
                    outlined
                    return-object
                  ></v-select>
                </v-col>
              </v-row>
              <v-select
                v-model="selectedTrucks"
                :items="viewableTrucks"
                label="Trucks"
                multiple
                chips
                height="56px"
                outlined
              >
                <template v-slot:prepend-item>
                  <v-list-item @click="toggleSelectAllTrucks">
                    <v-list-item-action>
                      <v-icon :color="allTrucksSelected ? 'primary' : ''">
                        {{ truckIcon }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> Select All </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
                <template v-slot:selection="{ item }">
                  <v-chip readonly>{{ item }}</v-chip>
                </template>
              </v-select>
              <v-progress-circular
                v-if="reportLoading"
                indeterminate
                color="primary"
                class="center"
              ></v-progress-circular>
              <v-btn
                v-else
                color="primary"
                class="center"
                :disabled="disabledStatus"
                @click="generateReport"
                >Generate Report</v-btn
              >
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- ESG panel will be added back later -->
          <!-- <v-expansion-panel class="shade">
            <v-expansion-panel-header>ESG</v-expansion-panel-header>
            <v-expansion-panel-content id="esgPanel">
              <div class="buttonDiv">
                <v-btn color="primary" @click="resetDefaults"
                  >Reset Defaults</v-btn
                >
              </div>
              <div class="tableDiv">
                <v-data-table
                  :headers="esgHeaders"
                  :items="esgItems"
                  hide-default-footer
                  class="shade"
                >
                  <template v-slot:top>
                    <v-dialog v-model="dialog" max-width="500px">
                      <form @submit.prevent="save">
                        <v-card>
                          <v-card-title>
                            <span class="text-h5">{{ formTitle }}</span>
                          </v-card-title>
                          <v-card-text>
                            <v-container>
                              <v-row>
                                <h1 class="center">{{ editedItem.fuel }}</h1>
                              </v-row>
                              <br /><br /><br />
                              <v-row>
                                <v-text-field
                                  v-model="editedItem.fe"
                                  :label="feLabel"
                                  outlined
                                  :rules="[numberValidation]"
                                >
                                </v-text-field>
                              </v-row>
                              <br /><br />
                              <v-row>
                                <v-text-field
                                  v-model="editedItem.wtw"
                                  label="WTW"
                                  outlined
                                  :rules="[numberValidation]"
                                >
                                </v-text-field>
                              </v-row>
                            </v-container>
                          </v-card-text>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="close">
                              Cancel
                            </v-btn>
                            <v-btn color="blue darken-1" text type="submit">
                              Save
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </form>
                    </v-dialog>
                  </template>
                  <template v-slot:[`item.edit`]="{ item }">
                    <v-icon small class="mr-2" @click="editItem(item)">
                      mdi-pencil
                    </v-icon>
                  </template>
                </v-data-table>
              </div>
              <div class="wtw">* Well to Wheel in gCO2e/MJ</div>
            </v-expansion-panel-content>
          </v-expansion-panel> -->
        </v-expansion-panels>
      </v-container>
    </v-navigation-drawer>
    <v-row v-if="errorMessage">
      <v-col>
        <v-alert type="error"> {{ errorMessage }} </v-alert>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="10">
        <h1 style="font-weight: 400">
          {{ truckTypeFinal }} Trip Reports {{ readableRange }}
        </h1>
      </v-col>
      <v-col align="right">
        <v-btn
          icon
          tile
          large
          style="background-color: #1a1a1a; border-radius: 5px"
          @click="drawer = true"
          ><v-icon style="opacity: 0.6">mdi-cog</v-icon></v-btn
        ></v-col
      >
    </v-row>
    <v-col v-if="truckTypeFinal === 'ERX'" style="margin-bottom: -20px">
      <v-card
        style="text-align: center; color: white"
        class="pa-2 error"
        elevation="4"
      >
        <span
          >WARNING: There are known issues with ERX cloud data at this time, and
          as a result the aggregations are unreliable</span
        >
      </v-card>
    </v-col>
    <v-col>
      <v-row class="mt-4">
        <v-col cols="4" xs="12" sm="12" md="12" lg="4" xl="4">
          <v-tabs fixed-tabs background-color="secondary">
            <v-tab @click="changeTab('Usage')">Usage</v-tab>
            <!-- this will be added back later -->
            <!-- <v-tab v-if="showESG" @click="changeTab('ESG')">ESG</v-tab> -->
            <v-tab @click="changeTab('Graphs')">Graphs</v-tab>
          </v-tabs>
        </v-col>
        <v-col class="hidden-sm-and-down"><v-spacer /></v-col>

        <v-btn
          v-if="exportData && switchTab != 'Graphs'"
          color="primary"
          style="color: black; margin-top: 18px"
          @click="exportCSV"
        >
          <download-csv
            v-if="exportData"
            id="csv"
            :name="truckTypeFinal === 'EX' ? 'ex_trips.csv' : 'erx_trips.csv'"
            :data="exportData"
          >
            <v-icon id="csv2" class="pr-1">mdi-download</v-icon>
          </download-csv>
          Export
        </v-btn>
        <v-col
          v-if="switchTab === 'Graphs'"
          md="12"
          lg="3"
          align="right"
          style="margin-top: -10px"
        >
          <v-select
            v-if="truckTypeFinal === 'EX'"
            v-model="selectedGraphs"
            :items="viewableGraphs"
            label="View Chart"
            multiple
            chips
            height="68px"
            outlined
          >
            <template v-slot:prepend-item>
              <v-list-item ripple @mousedown.prevent @click="toggleSelectAll">
                <v-list-item-action>
                  <v-icon :color="allSelected ? 'primary' : ''">
                    {{ icon }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title> Select All </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
            </template>
            <template v-slot:selection="{ item }">
              <v-chip readonly @click="chipClicked(item)" @click.stop>{{
                item
              }}</v-chip>
            </template>
          </v-select>
          <v-select
            v-if="truckTypeFinal === 'ERX'"
            v-model="erxSelectedGraphs"
            :items="erxViewableGraphs"
            label="View Chart"
            multiple
            chips
            height="68px"
            outlined
          >
            <template v-slot:prepend-item>
              <v-list-item
                ripple
                @mousedown.prevent
                @click="erxToggleSelectAll"
              >
                <v-list-item-action>
                  <v-icon :color="erxAllSelected ? 'primary' : ''">
                    {{ erxIcon }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title> Select All </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
            </template>
            <template v-slot:selection="{ item }">
              <v-chip readonly @click="chipClicked(item)" @click.stop>{{
                item
              }}</v-chip>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <!-- keep alive to save cache of column selection when switching b/w tabs -->
      <v-col>
        <KeepAlive v-if="truckTypeFinal === 'EX'">
          <component
            :is="switchTab === 'Usage' && 'TripUsage'"
            :items="tData"
            :loading="reportLoading"
            @headers="getHeaders"
          ></component>
        </KeepAlive>
        <KeepAlive v-else>
          <component
            :is="switchTab === 'Usage' && 'TripUsageERX'"
            :items="tData"
            :loading="reportLoading"
            @headers="getHeaders"
          ></component>
        </KeepAlive>
      </v-col>
      <v-col>
        <KeepAlive>
          <component
            :is="switchTab === 'ESG' && 'TripESG'"
            :items="tData"
            :loading="reportLoading"
          ></component>
        </KeepAlive>
      </v-col>
      <v-col v-if="switchTab === 'Graphs'">
        <!-- fixme: in future update TripGraphEX story: change selected-graphs from array to set -->
        <TripGraphEX
          v-if="truckTypeFinal === 'EX'"
          :data="graphData"
          :selected-graphs="selectedGraphs"
          :loading="graphLoading"
          :toggle-loading="toggleGraphLoading"
        />
        <TripGraphERX
          v-else
          :data="graphData"
          :graphs="new Set(erxSelectedGraphs)"
          :loading="reportLoading"
        />
      </v-col>
    </v-col>
  </v-container>
</template>

<script>
import _ from 'lodash';
import { getTripReport, getTripGraph, getCompanyFleets } from '@/api/trips';
import dateFormat from 'dateformat';
import { getCompanies, getFleets } from '@/api/company';
import TripGraphEX from './TripGraphEX';
import TripGraphERX from './TripGraphERX';
import TripUsage from './TripUsage';
import TripUsageERX from './TripUsageERX';
import TripESG from './TripESG';
import { mapGetters, mapActions } from 'vuex';
import { regionalSetting } from '@/utilities/userSettings';
import { getStartEnd, compareDates } from '@/utilities/dateFunctions';

export default {
  components: { TripGraphEX, TripGraphERX, TripUsage, TripESG, TripUsageERX },
  data() {
    return {
      blockTruckID: false,
      typeObject: null,
      exportData: null,
      showESG: false,
      exHeaders: [],
      erxHeaders: [],
      fleetData: null,
      truckType: 'EX',
      truckTypeFinal: 'EX',
      truckTypeDisabled: false,
      reverseTruckLookup: null,
      selectedTrucks: [],
      viewableTrucks: [],
      fleets: null,
      dateInput: null,
      readableRange: null,
      errorMessage: null,
      graphLoading: false,
      graphData: null,
      drawer: false,
      feLabel: null,
      dialog: false,
      editedIndex: -1,
      company: '',
      companies: [],
      switchTab: 'Usage',
      editedItem: {
        fuel: '',
        fe: 0,
        wtw: 0,
      },
      defaultItem: {
        fuel: '',
        fe: 0,
        wtw: 0,
      },
      formTitle: null,
      truckLookup: null,
      companyLookup: null,
      revCompanyLookup: null,
      menu: false,
      dates: [],
      rowDef: 0,
      reportLoading: true,
      panel: [0],
      selectedGraphs: ['distance', 'usageTime', 'fuelUsage', 'power', 'soc'],
      viewableGraphs: ['distance', 'usageTime', 'fuelUsage', 'power', 'soc'],
      erxSelectedGraphs: ['Usage'],
      erxViewableGraphs: ['Usage', 'Cruise Control', 'BMS', 'Axle', 'Retarder'],
      esgHeaders: [
        {
          text: 'Fuel',
          value: 'fuel',
        },
        {
          text: 'FE',
          value: 'fe',
        },
        {
          text: 'WTW*',
          value: 'wtw',
        },
        {
          text: 'Edit',
          value: 'edit',
        },
      ],
      esgItems: [
        {
          fuel: 'RNG',
          fe: '',
          wtw: -5.85,
        },
        {
          fuel: 'CNG/RNG Blend',
          fe: '',
          wtw: 36.68,
        },
        {
          fuel: 'CNG',
          fe: '',
          wtw: 79.21,
        },
        {
          fuel: 'Diesel (moving)',
          fe: '6.7',
          wtw: 100.45,
        },
        {
          fuel: 'Diesel (Idling/APU)',
          fe: '0.8',
          wtw: 100.45,
        },
        {
          fuel: 'Electricity',
          fe: '2',
          wtw: 97.54,
        },
        {
          fuel: 'Hydrogen (optimal)',
          fe: '14',
          wtw: 124.7,
        },
      ],
      tData: [],
      numberValidation: (value) => (isNaN(value) ? 'Invalid number' : true),
    };
  },
  computed: {
    ...mapGetters({
      userRoles: 'getUserRoles',
      userPreferences: 'getUserPreferences',
      storeHealthTrucks: 'getHealthTrucks',
    }),
    dateLabel() {
      if (this.dates.length > 1) {
        let yearA, monthA, dayA;
        [yearA, monthA, dayA] = this.dates[0].split('-');
        const dateA = monthA + '/' + dayA + '/' + yearA;

        let yearB, monthB, dayB;
        [yearB, monthB, dayB] = this.dates[1].split('-');
        const dateB = monthB + '/' + dayB + '/' + yearB;
        return dateA + ' - ' + dateB;
      } else {
        return 'Select';
      }
    },
    disabledStatus() {
      // if (this.dates.length < 2 || this.company.length === 0) {
      if (this.dates.length < 2) {
        return true;
      } else {
        return false;
      }
    },
    allSelected() {
      return this.selectedGraphs.length == this.viewableGraphs.length;
    },
    erxAllSelected() {
      return this.erxSelectedGraphs.length == this.erxViewableGraphs.length;
    },
    icon() {
      if (this.allSelected) return 'mdi-close-box';
      else return 'mdi-checkbox-blank-outline';
    },
    erxIcon() {
      if (this.erxAllSelected) return 'mdi-close-box';
      else return 'mdi-checkbox-blank-outline';
    },
    allTrucksSelected() {
      return this.selectedTrucks.length == this.viewableTrucks.length;
    },
    truckIcon() {
      if (this.allTrucksSelected) return 'mdi-close-box';
      else return 'mdi-checkbox-blank-outline';
    },
  },
  watch: {
    company() {
      // filter to intersection of truckType & company trucks
      let one, two, intersection;
      if (this.truckType === 'EX') {
        one = this.typeObject.EX.slice();
      } else {
        one = this.typeObject.ERX.slice();
      }
      two = this.fleets[this.company.id];
      intersection = one.filter((id) => two.includes(id));
      const namedTrucks = intersection.map((id) => this.truckLookup[id]);
      this.viewableTrucks = namedTrucks;
      this.selectedTrucks = namedTrucks.slice();

      // preselect truck_type based on company
      if (!this.fleetData) console.error('fleet data not loaded');
      else if (this.fleetData[this.company.id] === 'EX') {
        this.truckType = 'EX';
        this.truckTypeDisabled = true;
      } else if (this.fleetData[this.company.id] === 'ERX') {
        this.truckType = 'ERX';
        this.truckTypeDisabled = true;
      } else {
        this.truckTypeDisabled = false;
      }
    },
    truckType() {
      // filter to intersection of truckType & company trucks
      let one, two, intersection;
      if (this.company) {
        if (this.truckType === 'EX') {
          one = this.typeObject.EX.slice();
        } else {
          one = this.typeObject.ERX.slice();
        }
        two = this.fleets[this.company.id];
        intersection = one.filter((id) => two.includes(id));
      } else {
        // if company not yet selected, intersection is all trucks of selected truck_type
        if (this.truckType === 'EX') intersection = this.typeObject.EX.slice();
        else intersection = this.typeObject.ERX.slice();
      }
      const namedTrucks = intersection.map((id) => this.truckLookup[id]);
      this.viewableTrucks = namedTrucks;
      this.selectedTrucks = namedTrucks.slice();
    },
    dialog(val) {
      val || this.close();
    },
    dates() {
      // sync with this.dateInput
      this.dateInput = '';
      for (let i = 0; i < this.dates.length; i++) {
        this.dateInput += dateFormat(this.dates[i], 'mm/dd/yyyy', true);
        if (i === 0) this.dateInput += ' - ';
      }
    },
    dateInput() {
      // autocomplete logic
      if (parseInt(this.dateInput[0]) > 1) {
        this.dateInput = '0' + this.dateInput + '/';
      }
      if (parseInt(this.dateInput[3]) > 3) {
        this.dateInput =
          this.dateInput.substr(0, 2) + '/0' + this.dateInput[3] + '/';
      }
      if (parseInt(this.dateInput[13]) > 1) {
        this.dateInput =
          this.dateInput.substr(0, 12) + ' 0' + this.dateInput[13] + '/';
      }
      if (parseInt(this.dateInput[16]) > 3) {
        this.dateInput =
          this.dateInput.substr(0, 15) + '/0' + this.dateInput[16] + '/';
      }
      // sync with this.dates
      if (this.dateInput.length === 23) {
        let [start, end] = this.dateInput.split('-');
        this.dates = [
          dateFormat(start, 'yyyy-mm-dd', true),
          dateFormat(end, 'yyyy-mm-dd', true),
        ];
      }
    },
    truckLookup() {
      let trucks;
      if (this.truckType === 'EX') {
        trucks = this.typeObject.EX.slice();
      } else {
        trucks = this.typeObject.ERX.slice();
      }
      let namedTrucks = trucks.map((id) => this.truckLookup[id]);
      this.viewableTrucks = namedTrucks;
      this.selectedTrucks = namedTrucks.slice();
    },
  },
  async mounted() {
    this.getFleetData();
    await this.fetchFleets();
    await this.fetchCompanies();

    const today = dateFormat(Date.parse(new Date()), 'yyyy-mm-dd');
    const sevenDaysAgo = dateFormat(
      Date.parse(new Date()) - 86400000 * 7,
      'yyyy-mm-dd'
    );
    this.dates = [sevenDaysAgo, today];
    this.rowDef = 0;

    if (this.$route.params.id) {
      for (const truck of this.healthTrucks) {
        if (truck.id == this.$route.params.id) {
          this.company = {
            name: truck.company,
            id: this.revCompanyLookup[truck.company],
          };
          if (truck.truck_type.includes('EX')) {
            this.truckType = 'EX';
          } else {
            this.truckType = 'ERX';
          }

          break;
        }
      }

      setTimeout(() => {
        this.selectedTrucks = [this.truckLookup[this.$route.params.id]];
        this.generateReport();
      }, 200);
    } else {
      // generic initial load
      this.blockTruckID = true;
      this.generateReport();
    }
  },

  methods: {
    ...mapActions(['fetchHealthTrucks']),
    saveDates: function () {
      if (compareDates(this.dates[0], this.dates[1]) < 0) {
        this.$refs.menu.save(this.dates);
      } else {
        const orderedDates = [this.dates[1], this.dates[0]];
        this.dates = orderedDates;
        this.$refs.menu.save(this.dates);
      }
    },
    changeTab(tab) {
      this.switchTab = tab;
    },
    metersToMiles: function (meterDist) {
      return _.round(meterDist / 1609.344, 6);
    },
    milesPerGallon: function (meterDist, fuelUsed) {
      let mpg;
      const rawMiles = _.round(meterDist / 1609.344, 6);

      if (fuelUsed > 0) {
        mpg = _.round(rawMiles / fuelUsed, 2);
      } else {
        mpg = 0;
      }
      return mpg;
    },
    calculatedMPG: function (distance, fuelUsed) {
      let mpg;
      if (fuelUsed > 0) {
        mpg = _.round(distance / fuelUsed, 2);
      } else {
        mpg = 0;
      }
      return mpg;
    },
    calcFuelSavings: function (fuelSaved, fuelUsed) {
      let factor = (fuelSaved / fuelUsed) * 100;
      return _.round(factor, 2);
    },
    calcFuelSavingsMotor: function (plusSOC, apuFuelSaved, fuelSavedMotor) {
      let factor = (plusSOC * 0.003) / fuelSavedMotor;
      return _.round(factor * 100, 2);
    },
    calcFuelSavingsAPUMotor: function (plusSOC, apuFuelSaved, fuelSaved) {
      let factor =
        (plusSOC * 0.003 + apuFuelSaved) / (fuelSaved + apuFuelSaved);
      return _.round(factor * 100, 2);
    },
    calcIdleApuRatio: function (idle, apu) {
      let number = idle > 0 && apu > 0 ? _.round(idle / apu, 3) : 0;
      return number;
    },
    resetDefaults() {
      // reset wtw defaults
      this.esgItems[0].wtw = -5.85;
      this.esgItems[1].wtw = 36.68;
      this.esgItems[2].wtw = 79.21;
      this.esgItems[3].wtw = 100.45;
      this.esgItems[4].wtw = 100.45;
      this.esgItems[5].wtw = 97.54;
      this.esgItems[6].wtw = 124.7;
      // reset fe defaults
      this.esgItems[0].fe = '';
      this.esgItems[1].fe = '';
      this.esgItems[2].fe = '';
      this.esgItems[3].fe = 6.7;
      this.esgItems[4].fe = 0.8;
      this.esgItems[5].fe = 2;
      this.esgItems[6].fe = 14;
    },
    async generateReport() {
      this.reportLoading = true;
      this.graphLoading = true;
      this.graphData = null;
      this.exportData = null;
      this.tData = [];
      let res, graphRes;
      this.readableRange =
        '(' +
        dateFormat(this.dates[0], 'm/d/yyyy', true) +
        ' - ' +
        dateFormat(this.dates[1], 'm/d/yyyy', true) +
        ')';
      this.truckType === 'ERX' ? (this.showESG = true) : (this.showESG = false);
      this.truckTypeFinal = this.truckType; // separate variable so title only changes on generateReport() call
      // start date is always the earlier date
      const [startDate, endDate] = getStartEnd(
        this.dates,
        this.userPreferences.timeZonePref.canonical
      );
      // construct list of truck id's
      let truckIDs;
      // use blockTruckID to block truckID input on initial page load
      if (!this.allTrucksSelected && !this.blockTruckID) {
        truckIDs = [];
        this.selectedTrucks.forEach((truck) =>
          truckIDs.push(this.reverseTruckLookup[truck])
        );
      }

      let promises = [
        getTripReport(
          this.rowDef,
          startDate,
          endDate,
          this.company?.id,
          truckIDs,
          this.truckType,
          false,
          this.userPreferences.timeZonePref.canonical
        ).catch((e) => {
          this.errorMessage = 'getTripReport: ' + e;
        }),
      ];
      if (this.truckType.toUpperCase() === 'ERX') {
        promises.push(
          getTripReport(
            this.rowDef,
            startDate,
            endDate,
            this.company?.id,
            truckIDs,
            this.truckType,
            true,
            this.userPreferences.timeZonePref.canonical
          ).catch((e) => {
            this.errorMessage = 'getTripReport: ' + e;
          })
        );
      } else {
        promises.push(
          getTripGraph(
            this.rowDef,
            startDate,
            endDate,
            this.company?.id,
            truckIDs,
            this.userPreferences.timeZonePref.canonical
          ).catch((e) => {
            this.errorMessage = 'getTripGraph: ' + e;
          })
        );
      }

      [res, graphRes] = await Promise.allSettled(promises);

      if (this.errorMessage) {
        this.reportLoading = false;
        this.graphLoading = false;
        return;
      }

      let transformedData = this.processData(res.value.data);
      this.processExportData(transformedData);

      if (this.truckType === 'ERX') {
        this.graphData = this.processData(graphRes.value.data, true);
      } else {
        this.graphData = graphRes.value.data;
      }
      // populate the trip reports table;
      this.tData = transformedData;
      this.reportLoading = false;
      this.blockTruckID = false;
    },
    // function to create a lookup table of truck_id's and names
    async createTruckLookup() {
      // get health trucks if not already in store
      if (!this.storeHealthTrucks) await this.fetchHealthTrucks();
      let trucks = _.cloneDeep(this.storeHealthTrucks);
      const lookupTable = {};
      const reverseLookup = {};
      this.healthTrucks = trucks;
      // create type object as well; list of trucks of given type
      let typeObj = {
        EX: [],
        ERX: [],
      };
      trucks.forEach((truck) => {
        lookupTable[truck.id] = truck.number;
        reverseLookup[truck.number] = truck.id;
        if (truck.truck_type.includes('EX')) typeObj.EX.push(truck.id);
        else if (truck.truck_type.includes('ERX')) typeObj.ERX.push(truck.id);
      });
      this.typeObject = typeObj;
      this.truckLookup = lookupTable;
      this.reverseTruckLookup = reverseLookup;
    },
    editItem(item) {
      // should we be persisting selections per user for these edits in the future?
      if (item.fuel === 'Diesel (moving)') {
        this.feLabel = 'Fuel Economy (MPG)';
      } else if (item.fuel === 'Diesel (Idling/APU)') {
        this.feLabel = 'Fuel Economy (gal/hr)';
      } else if (item.fuel === 'Electricity') {
        this.feLabel = 'Fuel Economy (kWh/mile)';
      } else if (item.fuel === 'Hydrogen (optimal)') {
        this.feLabel = 'Fuel Economy (MPG)';
      } else {
        this.feLabel = 'Fuel Economy';
      }

      this.editedIndex = this.esgItems.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      if (!(isNaN(this.editedItem.fe) || isNaN(this.editedItem.wtw))) {
        if (this.editedIndex > -1) {
          Object.assign(this.esgItems[this.editedIndex], this.editedItem);
        } else {
          this.esgItems.push(this.editedItem);
        }
        this.close();
      }
    },
    // input min, output hr
    addDurations(a, b) {
      return a / 60 + b / 60;
    },
    chipClicked(chip) {
      if (chip === 'Usage') chip = 'chart1';
      else if (chip === 'Cruise Control') chip = 'chart8';
      else if (chip === 'BMS') chip = 'chart11';
      else if (chip === 'Axle') chip = 'chart14';
      else if (chip === 'Retarder') chip = 'chart17';
      document.getElementById(chip).scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    },
    toggleSelectAll() {
      if (this.allSelected) {
        this.selectedGraphs = [];
      } else {
        this.selectedGraphs = this.viewableGraphs.slice();
      }
    },
    erxToggleSelectAll() {
      if (this.erxAllSelected) {
        this.erxSelectedGraphs = [];
      } else {
        this.erxSelectedGraphs = this.erxViewableGraphs.slice();
      }
    },
    processData(res, graph = false) {
      let transformedData = [];
      // define fuel constants
      const dieselFC = 146.52; // MJ/gal
      const electricFC = 3.6; // MJ/kWh
      const hydrogenFC = 120; // MJ/kg
      // process data to prepare for v-data-table
      for (let row of res) {
        if (!graph)
          row.Day = regionalSetting(
            this.userPreferences.datePref,
            row.Day,
            'date'
          );

        if (this.truckType === 'ERX') {
          const genOn = Math.round(row.distanceKM - row.evKM);
          // ESG rows
          row.hrMoving = row.secMoving / 3600;
          row.hrIdle = row.secIdle / 3600;
          // calculate MT gCO2e columns
          row.idleDieselMT = Math.round(
            (row.secIdle / 3600) * // seconds to hr
              parseFloat(this.esgItems[4].fe) *
              parseFloat(this.esgItems[4].wtw)
          ).toLocaleString('en-US');
          row.movingDieselMT = Math.round(
            (row.kmTraveled / 1.609 / parseFloat(this.esgItems[3].fe)) * // km to mi
              dieselFC *
              parseFloat(this.esgItems[3].wtw)
          ).toLocaleString('en-US');
          row.dieselMT = Math.round(
            (row.secIdle / 3600) *
              parseFloat(this.esgItems[4].fe) *
              parseFloat(this.esgItems[4].wtw) +
              (row.kmTraveled / 1.609 / parseFloat(this.esgItems[3].fe)) *
                53.6 *
                parseFloat(this.esgItems[3].wtw)
          ).toLocaleString('en-US');
          row.electricityMT = Math.round(
            (row.kmTraveled / 1.609 / parseFloat(this.esgItems[5].fe)) *
              electricFC *
              parseFloat(this.esgItems[5].wtw)
          ).toLocaleString('en-US');
          row.movingHydrogenMT = Math.round(
            (row.kmTraveled / 1.609 / parseFloat(this.esgItems[6].fe)) *
              hydrogenFC *
              parseFloat(this.esgItems[6].wtw)
          ).toLocaleString('en-US');
          row.idleHydrogenMT = Math.round(
            (row.secIdle / 3600) *
              parseFloat(this.esgItems[6].fe) *
              parseFloat(this.esgItems[6].wtw)
          ).toLocaleString('en-US');
          row.hydrogenMT = Math.round(
            (row.kmTraveled / 1.609 / parseFloat(this.esgItems[6].fe)) *
              hydrogenFC *
              parseFloat(this.esgItems[6].wtw) +
              (row.secIdle / 3600) *
                parseFloat(this.esgItems[6].fe) *
                parseFloat(this.esgItems[6].wtw)
          ).toLocaleString('en-US');
          // ESG end

          row.kgGridAndSOCAdjustedDeltaFuel = regionalSetting(
            this.userPreferences.regionPref,
            row.kgGridAndSOCAdjustedDeltaFuel,
            'weight',
            true,
            1
          );
          row.startOdometer = regionalSetting(
            this.userPreferences.regionPref,
            row.startOdometer,
            'odometer',
            true,
            1
          );
          row.endOdometer = regionalSetting(
            this.userPreferences.regionPref,
            row.endOdometer,
            'odometer',
            true,
            1
          );
          row.kmDeltaDistance = regionalSetting(
            this.userPreferences.regionPref,
            row.kmDeltaDistance,
            'weight',
            true,
            1
          );
          row.kmTraveled = regionalSetting(
            this.userPreferences.regionPref,
            row.kmTraveled,
            'distance',
            true,
            2
          );
          row.kmEVDistance = regionalSetting(
            this.userPreferences.regionPref,
            row.kmEVDistance,
            'distance',
            true,
            2
          );
          row.kmEVPerKwh = regionalSetting(
            this.userPreferences.regionPref,
            row.kmEVPerKwh,
            'distance',
            true,
            2
          );
          row.kmEVPerSOCPct = regionalSetting(
            this.userPreferences.regionPref,
            row.kmEVPerSOCPct,
            'distance',
            true,
            2
          );
          row.kmPerDGE = regionalSetting(
            this.userPreferences.regionPref,
            row.kmPerDGE,
            'distance',
            true,
            2
          );
          row.kmPerGridAndSOCAdjustedDGE = regionalSetting(
            this.userPreferences.regionPref,
            row.kmPerGridAndSOCAdjustedDGE,
            'distance',
            true,
            2
          );
          row.kmPerGridAndSOCAdjustedKg = regionalSetting(
            this.userPreferences.regionPref,
            row.kmPerGridAndSOCAdjustedKg,
            'distance/weight',
            true,
            2
          );
          row.kmPerKg = regionalSetting(
            this.userPreferences.regionPref,
            row.kmPerKg,
            'distance/weight',
            true,
            2
          );
          row.kmPerSOCAdjustedDGE = regionalSetting(
            this.userPreferences.regionPref,
            row.kmPerSOCAdjustedDGE,
            'distance',
            true,
            2
          );
          row.kmPerSOCAdjustedKg = regionalSetting(
            this.userPreferences.regionPref,
            row.kmPerSOCAdjustedKg,
            'distance/weight',
            true,
            2
          );
          row.totalEVSOCPct = Math.round(row.totalEVSOCPct);
          // round value if not null or undefined
          row.hrMoving &&
            (row.hrMoving = this.convertMinutes(row.secMoving / 60));
          row.hrIdle && (row.hrIdle = this.convertMinutes(row.secIdle / 60));
          row.hrOff && (row.hrOff = this.convertMinutes(row.secOff / 60));
          row.hrAccessory &&
            (row.hrAccessory = this.convertMinutes(row.secAccessory / 60));
          row.hrCrank && (row.hrCrank = this.convertMinutes(row.secCrank / 60));
          row.kgFuelUsed = regionalSetting(
            this.userPreferences.regionPref,
            row.kgFuelUsed,
            'weight',
            true,
            1
          );
          row.minSOC && (row.minSOC = Math.round(row.minSOC * 100) / 100);
          row.maxSOC && (row.maxSOC = Math.round(row.maxSOC * 100) / 100);
          row.avgSOC && (row.avgSOC = Math.round(row.avgSOC * 100) / 100);
          row.kmAbove90Kph = regionalSetting(
            this.userPreferences.regionPref,
            row.kmAbove90Kph,
            'distance',
            true,
            2
          );
          row.kmInCruise = regionalSetting(
            this.userPreferences.regionPref,
            row.kmInCruise,
            'distance',
            true,
            2
          );
          row.maxAccel = regionalSetting(
            this.userPreferences.regionPref,
            row.maxAccel,
            'distance_s',
            true,
            2
          );
          row.maxKph = regionalSetting(
            this.userPreferences.regionPref,
            row.maxKph,
            'distance',
            true,
            2
          );
          row.minKph = regionalSetting(
            this.userPreferences.regionPref,
            row.minKph,
            'distance',
            true,
            2
          );

          // currently used below
          row.evKM = regionalSetting(
            this.userPreferences.regionPref,
            row.evKM,
            'distance',
            true,
            1
          );
          row.avgMovingWeightKG = regionalSetting(
            this.userPreferences.regionPref,
            row.avgMovingWeightKG,
            'weight',
            true,
            1
          );
          row.fuelUsedKg = regionalSetting(
            this.userPreferences.regionPref,
            row.fuelUsedKg,
            'weight',
            true,
            1
          );
          row.startOdometerKM = regionalSetting(
            this.userPreferences.regionPref,
            row.startOdometerKM,
            'distance',
            true,
            1
          );

          row.endOdometerKM = regionalSetting(
            this.userPreferences.regionPref,
            row.endOdometerKM,
            'distance',
            true,
            1
          );

          row.distanceKM = regionalSetting(
            this.userPreferences.regionPref,
            row.distanceKM,
            'distance',
            true,
            1
          );

          row.DGL = regionalSetting(
            this.userPreferences.regionPref,
            row.DGL,
            'volume',
            true,
            1
          );
          row.kmPerL = regionalSetting(
            this.userPreferences.regionPref,
            row.kmPerL,
            'fuel_economy',
            true,
            2
          );
          row.genOnDist = regionalSetting(
            this.userPreferences.regionPref,
            genOn,
            'distance',
            true,
            1
          );
        } else {
          const idleFuelUsage =
            row.totalFuelUsageLiters - row.movingFuelUsageLiters;
          row.hrMoving = Math.round((row.movingTimeMinutes / 60) * 100) / 100;
          row.hrIdle = Math.round((row.idleTimeMinutes / 60) * 100) / 100;
          // convert others to desired format after esg calculations;
          row.movingTime = row.movingTimeMinutes
            ? this.convertMinutes(row.movingTimeMinutes)
            : '0:00';
          row.idleTime = row.idleTimeMinutes
            ? this.convertMinutes(row.idleTimeMinutes)
            : '0:00';
          row.apuTime = row.apuTimeMinutes
            ? this.convertMinutes(row.apuTimeMinutes)
            : '0:00';
          row.startOdometer = regionalSetting(
            this.userPreferences.regionPref,
            row.startOdometer,
            'odometer',
            true,
            1
          );
          row.endOdometer = regionalSetting(
            this.userPreferences.regionPref,
            row.endOdometer,
            'odometer',
            true,
            1
          );
          row.kmDistance = regionalSetting(
            this.userPreferences.regionPref,
            row.kmDistance,
            'distance',
            true,
            2
          );
          row.idleFuelUsage = regionalSetting(
            this.userPreferences.regionPref,
            idleFuelUsage,
            'volume',
            true,
            1
          );
          row.movingFuelUsageLiters = regionalSetting(
            this.userPreferences.regionPref,
            row.movingFuelUsageLiters,
            'volume',
            true,
            1
          );
          row.totalFuelUsageLiters = regionalSetting(
            this.userPreferences.regionPref,
            row.totalFuelUsageLiters,
            'volume',
            true,
            1
          );
          row.powerInKwh &&
            (row.powerInKwh = Math.round(row.powerInKwh * 100) / 100);
          row.powerOutKwh &&
            (row.powerOutKwh = Math.round(row.powerOutKwh * 100) / 100);
          row.estMovingKPL = regionalSetting(
            this.userPreferences.regionPref,
            row.estMovingKPL,
            'fuel_economy',
            true,
            1
          );
          row.estTotalKPL = regionalSetting(
            this.userPreferences.regionPref,
            row.estTotalKPL,
            'fuel_economy',
            true,
            1
          );
          row.socMin && (row.socMin = Math.round(row.socMin * 100) / 100);
          row.socMax && (row.socMax = Math.round(row.socMax * 100) / 100);
          row.dataQuality &&
            (row.dataQuality = Math.round(row.dataQuality * 10) / 10);
        }
        row.truck_name = this.truckLookup[row.truck_id];
        row.company = this.companyLookup[row.company_id];

        transformedData.push(row);
      }
      return transformedData;
    },
    toggleGraphLoading() {
      this.graphLoading = !this.graphLoading;
    },
    createCompanyLookup() {
      let lookup = {};
      let revLookup = {};
      for (const co of this.companies) {
        lookup[co.id] = co.name;
        revLookup[co.name] = co.id;
      }
      this.companyLookup = lookup;
      this.revCompanyLookup = revLookup;
    },
    async getFleetData() {
      let res;
      try {
        res = await getCompanyFleets();
      } catch (e) {
        console.error('trips/companyFleetTypes', e);
      }
      this.fleetData = {};
      res.data.forEach(
        (co) => (this.fleetData[co.company_id] = co.fleet_types)
      );
    },
    async fetchCompanies() {
      await this.createTruckLookup();
      try {
        let companies = [];
        const res = await getCompanies(); // TEMP: We will create a global state call to manage list of companies user has access to.
        res.data.forEach((co) => {
          // only include companies that have fleets
          if (this.fleets[co.id]) {
            // only include companies whose trucks we have data for
            let count = 0;
            for (const truck of this.fleets[co.id]) {
              if (this.truckLookup[truck]) {
                count += 1;
                break;
              }
            }
            if (count > 0) {
              companies.push(co);
            }
          }
        });

        this.companies = companies;
        this.createCompanyLookup();
      } catch (e) {
        console.error('/company', e);
      }
    },
    async fetchFleets() {
      try {
        let fleetDict = {};
        const res = await getFleets();
        for (const row of res.data) {
          fleetDict[row.company_id] = row.fleet;
        }
        this.fleets = fleetDict;
      } catch (e) {
        console.error('/company/fleet', e);
      }
    },
    toggleSelectAllTrucks() {
      if (this.allTrucksSelected) {
        this.selectedTrucks = [];
      } else {
        this.selectedTrucks = this.viewableTrucks.slice();
      }
    },
    convertMinutes(minutes) {
      const hours = Math.floor(minutes / 60);
      minutes = String(Math.round(minutes - hours * 60));
      minutes.length === 1 && (minutes = '0' + minutes);
      return `${hours}:${minutes}`;
    },
    processExportData(res) {
      let isMetric =
        this.userPreferences.regionPref === 'us/imperial' ? true : false;
      let deepCopy = JSON.parse(JSON.stringify(res));
      let orderedExport = [];
      if (this.truckType === 'EX') {
        deepCopy.forEach((row) => {
          let newRow = {}; // Specific column order for export based on table
          newRow.Day = row.Day;
          newRow.truck_name = this.truckLookup[row.truck_id];
          newRow.company_name = this.companyLookup[row.company_id];

          this.exHeaders.forEach((h, i) => {
            if (i > 1 && h.value in row) {
              if (isMetric && h.hasOptions) {
                newRow[h.altText] = row[h.value];
              } else {
                newRow[h.text] = row[h.value];
              }
            }
          });
          newRow['Data Quality'] = row.dataQuality;
          orderedExport.push(newRow);
        });
      } else {
        for (const row of deepCopy) {
          row.truck_name = this.truckLookup[row.truck_id];
          row.company_name = this.companyLookup[row.company_id];
          row.minsAbove90Kph = row.secAbove90Kph / 60;
          delete row.secAbove90Kph;
          row.minsIdle = row.secIdle / 60;
          delete row.secIdle;
          row.minsMoving = row.secMoving / 60;
          delete row.secMoving;
          row.minsAccessory = row.secAccessory / 60;
          delete row.secAccessory;
          row.minsCrank = row.secCrank / 60;
          delete row.secCrank;
          row.minsOff = row.secOff / 60;
          delete row.secOff;
          row.minsInCruise = row.secInCruise / 60;
          delete row.secInCruise;
          row.minsAboveSetSpeed = row.secAboveSetSpeed / 60;
          delete row.secAboveSetSpeed;
          row.minsBelowSetSpeed = row.secBelowSetSpeed / 60;
          delete row.secBelowSetSpeed;
          row.minsMovingRtrdLowPower = row.secMovingRtrdLowPower / 60;
          delete row.secMovingRtrdLowPower;
          row.minsMovingRtrdHighPower2 = row.secMovingRtrdHighPower2 / 60;
          delete row.secMovingRtrdHighPower2;
          row.minsMovingRtrdHighPower3 = row.secMovingRtrdHighPower3 / 60;
          delete row.secMovingRtrdHighPower3;
          // create ordered row
          let newRow = {};
          newRow.Day = row.Day;
          newRow.truck_name = this.truckLookup[row.truck_id];
          newRow.company_name = this.companyLookup[row.company_id];
          for (const [key, value] of Object.entries(row)) {
            let h = this.erxHeaders.find((h) => h.value === key);
            if (
              h &&
              h.hasOptions &&
              this.userPreferences.regionPref === 'us/imperial'
            ) {
              newRow[h.altText] = value;
            } else {
              newRow[key] = value;
            }
          }
          orderedExport.push(newRow);
        }
      }
      this.exportData = orderedExport;
    },
    exportCSV(event) {
      if (event.target.id != 'csv' && event.target.id != 'csv2') {
        document.getElementById('csv').click();
      }
    },
    getHeaders({ headers, type }) {
      if (type === 'EX') {
        this.exHeaders = headers;
      }
      if (type === 'ERX') {
        this.erxHeaders = headers;
      }
    },
  },
};
</script>

<style scoped>
.placeholder {
  text-align: center;
}
.center {
  display: block;
  margin: auto;
}
.buttonDiv {
  display: flex;
  justify-content: space-between;
}
.tableDivEdit {
  border: solid 1px #2196f3;
  margin-top: 10px;
}
.tableDiv {
  margin-top: 10px;
}
.colCheckbox {
  margin: 0px;
  /* negative margin to change style without globally changing
  vuetify scss styles */
  margin-bottom: -10px;
  padding: 0px;
}
.wtw {
  padding-top: 25px;
  font-size: 13px;
}
#esgPanel::v-deep .v-expansion-panel-content__wrap {
  padding: 0 20px 16px;
}
#dateGuide {
  margin-top: -27px;
  margin-bottom: 20px;
  margin-left: 10px;
  font-size: 13px;
  color: rgb(171, 171, 171);
}
</style>
