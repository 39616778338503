var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c("v-card-title", [_vm._v("Add Step")]),
      _c("v-card-text", [
        _vm._v("Fill in Step name and choose a plan category"),
      ]),
      _c("v-divider"),
      _c(
        "v-form",
        {
          ref: "form",
          attrs: { "lazy-validation": "" },
          model: {
            value: _vm.formValid,
            callback: function ($$v) {
              _vm.formValid = $$v
            },
            expression: "formValid",
          },
        },
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "formLabel", attrs: { sm: "3" } },
                    [_vm._v("Name")]
                  ),
                  _c(
                    "v-col",
                    { attrs: { sm: "9" } },
                    [
                      _c("v-text-field", {
                        staticClass: "formInput",
                        attrs: {
                          rules: _vm.nameRules,
                          outlined: "",
                          label: "Name",
                          required: "",
                        },
                        model: {
                          value: _vm.name,
                          callback: function ($$v) {
                            _vm.name = $$v
                          },
                          expression: "name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "formLabel", attrs: { sm: "3" } },
                    [_vm._v("Category: ")]
                  ),
                  _c(
                    "v-col",
                    [
                      _c("v-select", {
                        staticClass: "formInput",
                        attrs: {
                          items: _vm.testCategories,
                          rules: _vm.catRules,
                          outlined: "",
                          label: "Categories",
                          required: "",
                        },
                        model: {
                          value: _vm.stepCategory,
                          callback: function ($$v) {
                            _vm.stepCategory = $$v
                          },
                          expression: "stepCategory",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", text: "" },
                  on: { click: _vm.cancel },
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { disabled: !_vm.formValid, color: "primary" },
                  on: { click: _vm.exportStep },
                },
                [_vm._v(" Add Step ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }