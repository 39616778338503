var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("v-data-table", {
        attrs: {
          headers: _vm.truckLabels,
          items: _vm.truckData,
          search: _vm.searchText,
          loading: _vm.loading,
          "loading-text": "Loading... Please wait",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }