import axios from 'axios';

const BASE_URL = process.env.VUE_APP_API_PATH_BASE;

async function getUsers(oid) {
  return await axios.get(BASE_URL + `user_management/${oid ? oid : ''}`);
}

async function getGroups() {
  return await axios.get(BASE_URL + `user_management/groups`);
}

async function addUser(user) {
  return await axios.post(BASE_URL + `user_management/`, user);
}

async function updateUser(oid, user) {
  return await axios.put(BASE_URL + `user_management/update_user/${oid}`, user);
}

async function deleteUser(oid) {
  return await axios.delete(BASE_URL + `user_management/${oid}`);
}
// USING EXTERNAL/CUSTOMER API ROUTE
async function lastSeen() {
  return await axios.put(BASE_URL + `user_management/last_seen`);
}
// USING EXTERNAL/CUSTOMER API ROUTE
async function acceptTAC() {
  return await axios.put(BASE_URL + `user_management/accept_tac`);
}

export {
  getUsers,
  getGroups,
  addUser,
  updateUser,
  deleteUser,
  lastSeen,
  acceptTAC,
};
