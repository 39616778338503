var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "px-0 pt-0", attrs: { fluid: "" } },
    [
      _c(
        "v-btn",
        {
          staticClass: "toggleMap",
          attrs: { icon: "", tile: "", large: "" },
          on: { click: _vm.toggleDrawer },
        },
        [
          _c("v-icon", { staticStyle: { opacity: "0.6" } }, [
            _vm._v(_vm._s(_vm.toggleMapIcon)),
          ]),
        ],
        1
      ),
      _c("HereMap", {
        attrs: {
          "cog-present": false,
          set: _vm.setMap,
          "set-u-i": _vm.setUI,
          "map-height": _vm.mapHeight,
          "show-nav": !_vm.drawer,
          "show-traffic": false,
        },
      }),
      _c(
        "bottom-drawer",
        {
          attrs: { model: _vm.drawer, toggle: _vm.toggleDrawer },
          scopedSlots: _vm._u([
            {
              key: "icon",
              fn: function () {
                return [_c("v-icon", [_vm._v("mdi-map-minus")])]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "ml-3 pb-0", attrs: { cols: "3" } },
                    [
                      _c("h1", { staticStyle: { "font-weight": "400" } }, [
                        _vm._v("Health"),
                      ]),
                    ]
                  ),
                  _c(
                    "v-col",
                    { attrs: { align: "left" } },
                    [
                      _vm.liveView
                        ? _c("LiveFleetViewBanner", {
                            attrs: { "fleet-info": _vm.selectedCompany },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _vm.ready
                        ? _c("tab-view", {
                            attrs: {
                              "truck-data": _vm.healthTrucks,
                              "toggle-details": _vm.toggleDetailsDrawer,
                              "set-details": _vm.setTruckDetails,
                            },
                          })
                        : !_vm.ready && !_vm.error
                        ? _c("v-skeleton-loader", {
                            attrs: { type: "table-thead", elevation: "2" },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "bottom-drawer",
        {
          attrs: {
            type: "detail",
            model: _vm.detailsDrawer,
            toggle: _vm.toggleDetailsDrawer,
          },
        },
        [
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c("truck-detail", {
                attrs: {
                  truck: _vm.truckDetails,
                  map: _vm.hereMap,
                  "map-trucks": _vm.mapTrucks,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }