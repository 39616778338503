<template>
  <v-dialog v-model="editTriggerDialog" persistent width="750">
    <loader v-if="loading" />
    <v-card>
      <v-container fluid>
        <h3>Edit Trigger</h3>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field
            v-model="name"
            placeholder="Name"
            :rules="nameRules"
            style="margin-top: 15px; margin-bottom: -25px"
            required
            outlined
          >
            Name</v-text-field
          >
          <v-radio-group v-model="publicOrPrivate" row mandatory required>
            <v-radio label="Public" value="public"></v-radio>
            <v-radio label="Private" value="private"></v-radio>
          </v-radio-group>
          <v-textarea
            v-model="description"
            label="Description"
            outlined
          ></v-textarea>
          <div style="display: flex; justify-content: right">
            <v-btn class="mr-2" @click="editTriggerDialog = false"
              >Cancel</v-btn
            >
            <v-btn color="primary" :disabled="!valid" @click="submitTrigger()"
              >Save</v-btn
            >
          </div>
        </v-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import loader from '@/utilities/loader';
import { updateTriggers } from '@/api/study';
import { mapActions } from 'vuex';

export default {
  name: 'EditTrigger',
  components: { loader },
  props: {
    // eslint-disable-next-line
    editTriggerSelect: Object,
  },
  data() {
    return {
      loading: false,
      editTriggerDialog: false,
      publicOrPrivate: null,
      valid: false,
      name: '',
      nameRules: [(v) => !!v || 'Name is required'],
      description: '',
      triggers: [],
    };
  },
  watch: {
    // Watches for the change in editTriggerDialog variable to emit the false variable back to parent to reset
    editTriggerDialog: function (newValue) {
      if (!newValue) {
        this.editTriggerDialog = false;
        this.$emit('closed', false);
      }
    },
  },
  async mounted() {
    this.editTriggerDialog = true;
    await this.parseSelected();
  },
  methods: {
    ...mapActions({
      updateSnack: 'updateSnack',
    }),
    parseSelected() {
      this.name = this.editTriggerSelect?.name;
      this.description = this.editTriggerSelect?.description;
      this.publicOrPrivate = this.editTriggerSelect?.private;
    },
    async submitTrigger() {
      // Checks validation
      if (!this.$refs.form.validate()) {
        return;
      }
      const fields = {
        name: this.name,
        description: this.description,
        private: this.publicOrPrivate === 'private',
      };
      const trigger_id = this.editTriggerSelect.id;
      try {
        await updateTriggers(trigger_id, fields);
        this.$emit('refresh');
        this.updateSnack({
          type: 'success',
          message: 'Successfully updated trigger!',
        });
      } catch (e) {
        this.updateSnack({
          type: 'error',
          message: 'error updating trigger!',
        });
      }
    },
  },
};
</script>

<style scoped>
.dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  height: auto;
  width: auto;
  z-index: 1;
  transform: translate(-50%, -50%);
}
</style>
