import dateFormat from 'dateformat';
import _ from 'lodash';
import { sourceAddresses } from './constants';

export const iotCleanup = (dataTrucks, iotDeviceData, truckType, isConfig) => {
  _.forEach(dataTrucks, function (o) {
    let deviceMatch = iotDeviceData.filter((i) => i.tags.truck_id === o.id);
    if (deviceMatch.length) {
      if (truckType === 'EX') {
        const reported = deviceMatch[0]?.properties?.reported;
        o.TruckType = reported?.TruckType;
        o.HwType = reported?.HwType;
        o.ApuPresent = reported?.ApuPresent;
        o.VIN = reported?.VIN;
        o.mcom_algo_version = reported.mcom_algo_version;
        o.mcom_gps_version = reported.mcom_gps_version;
        o.mcom_config_version = reported.mcom_config_version;
        o.keyscalar_version = reported.keyscalar_version;
        o.python_azure_version = reported.python_azure_version;
        o.embedded_iot_version = reported.embedded_iot_version;
        o.gmqtt_version = reported.gmqtt_version;
        o.python_version = reported.python_version;
        o.dtc_iot_diag_version = reported.dtc_iot_diag_version;
      } else {
        let erxType = o.truck_type.includes('C') ? 'C' : 'B'; // Differentiates Sample B and C
        let foundersType = o.truck_type.includes('F');
        const properties = deviceMatch[0]?.properties;

        if (erxType === 'C' || foundersType) {
          o.software_status = properties?.reported['software-update-status'] // Temp: Will add logic in future to determine status
            ? properties?.reported['software-update-status']['current-step'] // Breaks if software-update-status does not exist
            : null;
          o.hdp_version = properties?.reported['erx-binary-version'];
          o.hcu_version = properties?.reported['hcu-version'];
          o.sgw_version = properties?.reported['sgw-version'];
          o.target_package = properties?.desired['erx-binary-version'];
        } else {
          o.software_status = null; // Does not exist for Sample B trucks
          o.hdp_version = properties?.reported?.hdp_software_version;
          o.hcu_version = properties?.reported['hcu-version'];
          o.sgw_version = properties?.reported['sgw-version'];
          o.target_package = properties?.desired?.hdp_software_version;
        }
      }
    }
    if (isConfig) {
      o.number = o.name ? o.name.replace(o.company, '').trim() : '';
    }
  });
  return dataTrucks;
};

const hyliionIcons = [
  { icon: 'stop_vehicle_grey', tooltip: 'Stop Vehicle' },
  { icon: 'limited_performance_grey', tooltip: 'Limited Performance' },
  { icon: 'rbs_grey', tooltip: 'RBS' },
  { icon: 'low_voltage_grey', tooltip: 'Low Voltage' },
  { icon: 'high_voltage_grey', tooltip: 'High Voltage' },
  { icon: 'charge_grey', tooltip: 'Charge' },
  { icon: 'brake_compressor_grey', tooltip: 'Brake Compressor' },
  { icon: 'genset_grey', tooltip: 'Genset' },
  { icon: 'hvac_grey', tooltip: 'HVAC' },
  { icon: 'hyliion_controller_grey', tooltip: 'Hyliion Controller' },
  { icon: 'thermal_systems_grey', tooltip: 'Thermal Systems' },
  { icon: 'e_axles_grey', tooltip: 'E-Axles' },
  { icon: 'power_steering_grey', tooltip: 'Power Steering' },
];

const filterHyliionIcons = (indicator, icons) => {
  let indicatorArray = indicator.primary_icon_name.split('_');
  let secondaryIndicator = indicator.secondary_icon_name
    ? indicator.secondary_icon_name.split('_')
    : null;

  icons = icons.map((ele) => {
    let iconArray = ele.icon.split('_');
    let length = iconArray.length;
    let existingIconColor = iconArray[length - 1];
    if (secondaryIndicator?.length && iconArray[0] === secondaryIndicator[0]) {
      let proposedIconColor = secondaryIndicator[length - 1];
      if (
        iconArray[length - 1] === 'grey' ||
        (iconArray[length - 1] === 'amber' && proposedIconColor === 'red')
      ) {
        return {
          icon: indicator.secondary_icon_name,
          tooltip: indicator?.secondary_indicator?.split('-')[0],
        };
      }
    }
    if (iconArray[0] === indicatorArray[0]) {
      let proposedIconColor = indicatorArray[length - 1];
      // If the two values are the same or if the existing state is already red then remains the same
      if (
        existingIconColor === proposedIconColor ||
        existingIconColor === 'red'
      ) {
        return ele;
        // Whenever it's Red then return Red
      }
      if (proposedIconColor === 'amber' || proposedIconColor === 'red') {
        return {
          icon: indicator.primary_icon_name,
          tooltip: indicator?.primary_indicator?.split('-')[0],
        };
      }
    }
    return ele;
  });
  return icons;
};

export const erxDTCLookup = (dtcs, spnfmiStates) => {
  let icons = hyliionIcons;
  // Test data needed here for reference since the spnfmi combos don't currently match
  let count = 0;
  dtcs = dtcs
    .map((t) => {
      t.id = count++; // Unique key for expandable sync
      t.timestamp = dateFormat(t.timestamp, 'mm/dd/yy HH:MM:ss Z', true);
      let data = spnfmiStates.filter((s) => s.spnfmi === `${t.spn}-${t.fmi}`);
      t.indicator_data = data[0] || null;
      t.description = data[0]?.description || 'N/A';
      t.name = data[0]?.dtc_name || '';
      t.workbook_link = data[0]?.workbook_link || '---';
      t.component = data[0]?.component || '---';
      if (_.isBoolean(data[0]?.show_to_customer)) {
        t.show_to_customer = data[0]?.show_to_customer ? 'Yes' : 'No';
      } else {
        t.show_to_customer = 'N/A';
      }
      t.source = t.source !== null ? t.source.toString(16).toUpperCase() : '—'; // Converts to HEX
      // If Hyliion Fault and has a Primary Indicator Icon
      let indicator = t.indicator_data;
      if (indicator && indicator?.primary_icon_name?.length) {
        t.name = indicator.dtc_name;
        t.description = indicator.description;
        t.sa_component = 'HCU';
        icons = filterHyliionIcons(indicator, icons);
      } else {
        let component = sourceAddresses.find((f) => f.source === t.source);
        if (component) {
          if (component.display) {
            t.sa_component = component.component;
          } else {
            // If display is false for source
            return {}; // Returns empty
          }
        } else {
          t.sa_component = 'Non-Hyliion';
        }
        if (!t.name.length) t.name = 'Non-Hyliion Fault';
      }
      // Changing source name after calculations
      if (t.source !== '—') {
        let hex = t.source;
        // Prepending the string with a 0x0 if the source address is only one digit
        t.source = hex.length === 1 ? '0x0' + hex : '0x' + hex;
      }
      return t;
    })
    .filter((d) => !_.isEmpty(d));
  return { dtcs, icons };
};
