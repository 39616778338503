var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { ref: "scatterTT", staticClass: "scatterTT" },
        [
          _c(
            "v-container",
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-row",
                    [
                      _c("v-card-title", { staticClass: "ttTitle" }, [
                        _vm._v(" Journey Quick View"),
                      ]),
                      _c("v-spacer"),
                      _c(
                        "span",
                        { staticClass: "ttX", on: { click: _vm.closeTooltip } },
                        [_c("v-icon", [_vm._v("mdi-close")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-row",
                    { staticStyle: { margin: "20px" } },
                    [
                      _c("v-col", [
                        _c("span", { staticStyle: { color: "#888888" } }, [
                          _vm._v("Journey"),
                        ]),
                        _c("br"),
                        _vm._v(" " + _vm._s(_vm.journeyName) + " "),
                      ]),
                      _c("v-col", [
                        _c("span", { staticStyle: { color: "#888888" } }, [
                          _vm._v("MPGe"),
                        ]),
                        _c("br"),
                        _vm._v(" " + _vm._s(_vm.mpge) + " "),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticStyle: { margin: "20px" } },
                    [
                      _c("v-col", [
                        _c("span", { staticStyle: { color: "#888888" } }, [
                          _vm._v("Truck"),
                        ]),
                        _c("br"),
                        _vm._v(" " + _vm._s(_vm.truckName) + " "),
                      ]),
                      _c("v-col", [
                        _c("span", { staticStyle: { color: "#888888" } }, [
                          _vm._v("Cruise"),
                        ]),
                        _c("br"),
                        _vm._v(" " + _vm._s(_vm.cruise) + "% "),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticStyle: { margin: "20px" } },
                    [
                      _c("v-col", [
                        _c("span", { staticStyle: { color: "#888888" } }, [
                          _vm._v("Idle"),
                        ]),
                        _c("br"),
                        _vm._v(" " + _vm._s(_vm.idle) + "% "),
                      ]),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-row",
                    {
                      staticStyle: {
                        "margin-right": "20px",
                        "margin-top": "10px",
                      },
                    },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-col",
                        { staticClass: "pt-0 mr-1", attrs: { cols: "3" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "secondary" },
                              on: { click: _vm.closeTooltip },
                            },
                            [_vm._v("Close")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "pt-0 mr-1", attrs: { cols: "4" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: { click: _vm.viewMore },
                            },
                            [_vm._v("View Journey")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-container",
        { ref: "scatterRoot", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { attrs: { justify: "space-between" } },
            [
              _vm.selectedMetric
                ? _c(
                    "v-btn-toggle",
                    {
                      attrs: { color: "primary", mandatory: "" },
                      on: { change: _vm.changeMetric },
                      model: {
                        value: _vm.selectedMetric,
                        callback: function ($$v) {
                          _vm.selectedMetric = $$v
                        },
                        expression: "selectedMetric",
                      },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          style:
                            _vm.selectedMetric === "mpge"
                              ? "background-color: #2B8F14; color: black"
                              : "",
                          attrs: { value: "mpge" },
                        },
                        [_vm._v("MPGe")]
                      ),
                      _c(
                        "v-btn",
                        {
                          style:
                            _vm.selectedMetric === "idle"
                              ? "background-color: #2B8F14; color: black"
                              : "",
                          attrs: { value: "idle" },
                        },
                        [_vm._v("Idle")]
                      ),
                      _c(
                        "v-btn",
                        {
                          style:
                            _vm.selectedMetric === "cruise"
                              ? "background-color: #2B8F14; color: black"
                              : "",
                          attrs: { value: "cruise" },
                        },
                        [_vm._v("Cruise")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "px-0", attrs: { cols: "12" } },
                [
                  [
                    _c(
                      "section",
                      { staticClass: "charts" },
                      [
                        !_vm.chartLoading
                          ? _c("highcharts", {
                              ref: "scatter",
                              attrs: { options: _vm.options },
                            })
                          : _c(
                              "v-sheet",
                              { staticClass: "pa-3", attrs: { rounded: "" } },
                              [
                                _c("v-skeleton-loader", {
                                  staticClass: "mx-auto",
                                  attrs: { type: "list-item" },
                                }),
                                _c("v-skeleton-loader", {
                                  staticClass: "mx-auto",
                                  attrs: { type: "image" },
                                }),
                              ],
                              1
                            ),
                      ],
                      1
                    ),
                  ],
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }