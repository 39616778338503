var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [_c("highcharts", { ref: "elevation", attrs: { options: _vm.options } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }