<template>
  <v-container fluid>
    <loader v-if="loading" />
    <v-card elevation="0" style="background-color: #272727">
      <v-card-title class="text-h5">Individual</v-card-title>
      <div class="setting">
        <div class="settingText">
          <div class="settingTitle">System Units</div>
          <div class="settingDescription">
            Select preferred measurement units.
          </div>
        </div>
        <div class="fieldContainer">
          <v-btn-toggle v-model="systemUnits" class="tfield">
            <v-btn
              value="metric"
              :style="
                systemUnits === 'metric'
                  ? 'background-color: #2B8F14; color: black'
                  : ''
              "
              >metric</v-btn
            >
            <v-btn
              value="us/imperial"
              :style="
                systemUnits === 'us/imperial'
                  ? 'background-color: #2B8F14; color: black'
                  : ''
              "
              >us/imperial</v-btn
            >
          </v-btn-toggle>
        </div>
      </div>
      <div class="setting">
        <div class="settingText">
          <div class="settingTitle">Fuel economy units</div>
          <div class="settingDescription">
            Select preferred units for fuel economy.
          </div>
        </div>
        <div class="fieldContainer">
          <v-btn-toggle v-model="fuelEconomyPref" class="tfield">
            <v-btn
              value="mpge"
              :style="
                fuelEconomyPref === 'mpge'
                  ? 'background-color: #2B8F14; color: black'
                  : ''
              "
              >mpge</v-btn
            >
            <v-btn
              value="km/l-e"
              :style="
                fuelEconomyPref === 'km/l-e'
                  ? 'background-color: #2B8F14; color: black'
                  : ''
              "
              >km/l-e</v-btn
            >
            <v-btn
              value="l-e/100"
              :style="
                fuelEconomyPref === 'l-e/100'
                  ? 'background-color: #2B8F14; color: black'
                  : ''
              "
              >l-e/100</v-btn
            >
          </v-btn-toggle>
        </div>
      </div>
      <div class="setting">
        <div class="settingText">
          <div class="settingTitle">Date Format</div>
          <div class="settingDescription">Select your desired date format.</div>
        </div>
        <div class="fieldContainer">
          <v-select
            v-model="datePref"
            :items="dateItems"
            outlined
            class="tfield"
          ></v-select>
        </div>
      </div>
      <div class="setting">
        <div class="settingText">
          <div class="settingTitle">Time Format</div>
          <div class="settingDescription">Select your desired time format.</div>
        </div>
        <div class="fieldContainer">
          <v-select
            v-model="timePref"
            :items="timeItems"
            outlined
            class="tfield"
          ></v-select>
        </div>
      </div>
      <div class="setting">
        <div class="settingText">
          <div class="settingTitle">Time Zone</div>
          <div class="settingDescription">Select your desired time zone.</div>
        </div>
        <div class="fieldContainer">
          <v-select
            v-model="timeZonePref"
            :items="timeZoneItems"
            item-text="utc"
            return-object
            outlined
            class="tfield"
          ></v-select>
        </div>
      </div>
      <div class="actionBtns">
        <v-btn
          style="margin-right: 20px; color: #2b8f14"
          @click="syncUserPreferences"
          >Cancel</v-btn
        >
        <v-btn color="primary" style="color: black" @click="updateUserPrefs"
          >Save changes</v-btn
        >
      </div>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';
import loader from '@/utilities/loader';
import { putPref } from '@/api/preferences';

export default {
  name: 'IndividualSettings',
  components: { loader },
  data() {
    return {
      systemUnits: 'metric',
      fuelEconomyPref: 'mpge',
      datePref: 'mm/dd/yyyy',
      dateItems: ['mm/dd/yyyy', 'dd/mm/yy', 'yy/mm/dd', 'mmmm d, yy'],
      timePref: '12 hour (e.g. 1:00p.m.)',
      timeItems: ['12 hour (e.g. 1:00p.m.)', '24 hour (e.g. 13:00)'],
      timeZonePref: {
        utc: '(UTC-06:00) Central Time(US & Canada)',
        canonical: 'America/Chicago',
      },
      timeZoneItems: [
        {
          utc: '(UTC) Dublin, Edinburgh, Lisbon, London',
          canonical: 'Europe/London',
        },
        {
          utc: '(UTC-04:00) Atlantic Time (Canada)',
          canonical: 'America/Moncton',
        },
        {
          utc: '(UTC-05:00) Eastern Time(US & Canada)',
          canonical: 'America/New_York',
        },
        {
          utc: '(UTC-06:00) Central Time(US & Canada)',
          canonical: 'America/Chicago',
        },
        {
          utc: '(UTC-07:00) Mountain Time(US & Canada)',
          canonical: 'America/Denver',
        },
        {
          utc: '(UTC-08:00) Pacific Time(US & Canada)',
          canonical: 'America/Los_Angeles',
        },
        {
          utc: '(UTC-09:00) Alaska',
          canonical: 'America/Anchorage',
        },
      ],
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      userPreferences: 'getUserPreferences',
    }),
  },
  watch: {
    '$store.state.user.preferences': function () {
      this.syncUserPreferences();
    },
  },
  mounted() {
    this.syncUserPreferences();
  },
  methods: {
    ...mapMutations(['updateUserPreferences']),
    ...mapActions(['updateSnack']),
    syncUserPreferences() {
      this.systemUnits = this.userPreferences.regionPref;
      this.fuelEconomyPref = this.userPreferences.fuelEconomyPref;
      this.datePref = this.userPreferences.datePref;
      this.timePref = this.userPreferences.timePref;
      this.timeZonePref = this.userPreferences.timeZonePref;
    },
    async updateUserPrefs() {
      try {
        this.loading = true;
        const preferences = {
          regionPref: this.systemUnits,
          fuelEconomyPref: this.fuelEconomyPref,
          datePref: this.datePref,
          timePref: this.timePref,
          timeZonePref: this.timeZonePref,
        };
        await putPref('user_preferences', preferences);
        this.updateUserPreferences({ ...preferences });
        this.loading = false;
        this.updateSnack({
          type: 'success',
          message: 'Successfully updated user settings!',
        });
      } catch (e) {
        this.updateSnack({
          type: 'error',
          message: 'Something went wrong, please try again later!',
        });
      }
    },
  },
};
</script>

<style scoped>
.setting {
  background-color: #1a1a1a;
  margin: 17px;
  padding: 17px;
  border-radius: 3px;
  display: flex;
  align-items: center;
}
.settingText {
  width: 85%;
}
.settingDescription {
  display: block;
  color: var(--dark-main-color, #e0e0e0);
  /* 16 sp • Body 1 */
  font-family: Rawline;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.44px;
}
.tfield {
  margin-left: 5px;
  margin-bottom: -30px;
}
.radioLine {
  /* display: flex; */
  margin-bottom: 15px;
}
.actionBtns {
  padding: 17px;
}
</style>
