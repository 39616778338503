<template>
  <div>
    <v-alert v-if="errorMessage" type="error">
      {{ errorMessage }}
    </v-alert>
    <p v-if="!data || data.length === 0" style="text-align: center">No Data</p>
    <highcharts
      v-if="data && showCharts && selectedGraphs.includes('distance')"
      id="distance"
      ref="distance"
      class="graph"
      :options="distanceOptions"
    ></highcharts>
    <highcharts
      v-if="data && showCharts && selectedGraphs.includes('usageTime')"
      id="usageTime"
      ref="usageTime"
      class="graph"
      :options="usageTimeOptions"
    ></highcharts>
    <highcharts
      v-if="data && showCharts && selectedGraphs.includes('fuelUsage')"
      id="fuelUsage"
      ref="fuelUsage"
      class="graph"
      :options="fuelUsageOptions"
    ></highcharts>
    <highcharts
      v-if="data && showCharts && selectedGraphs.includes('power')"
      id="power"
      ref="power"
      class="graph"
      :options="powerOptions"
    ></highcharts>
    <highcharts
      v-if="data && showCharts && selectedGraphs.includes('soc')"
      id="soc"
      ref="soc"
      class="graph"
      :options="socOptions"
    ></highcharts>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { regionalSetting } from '@/utilities/userSettings';
export default {
  name: 'TripGraphs',
  props: {
    data: Array,
    selectedGraphs: Array,
    loading: Boolean,
    toggleLoading: Function,
  },
  data() {
    return {
      isMetric: true,
      errorMessage: null,
      xMin: null,
      xMax: null,
      zoomedChart: null,
      showCharts: false,
      usageTimeOptions: {
        chart: {
          backgroundColor: '#1E1E1E',
          type: 'column',
          zoomType: 'x',
          name: '',
          events: {
            selection: (e) => {
              const chartName = e.target.title.textStr;
              if (e.xAxis) {
                this.zoomedChart = chartName;
                this.xMin = e.xAxis[0].min;
                this.xMax = e.xAxis[0].max;
                this.zoomCharts(true);
              } else {
                this.zoomedChart = '';
                this.xMin = null;
                this.xMax = null;
                this.zoomCharts(false);
              }
            },
          },
        },
        rangeSelector: {
          selected: 1,
        },
        title: {
          text: 'Usage Time',
          style: {
            color: '#FFFFFF',
          },
        },
        tooltip: {
          shared: true,
          backgroundColor: '#363636',
          borderRadius: 8,
          borderColor: '#363636',
          style: {
            color: '#FFFFFF',
          },
        },
        legend: {
          itemStyle: {
            color: '#FFFFFF',
            fontWeight: 'bold',
          },
        },
        plotOptions: {
          series: {
            stacking: 'normal',
            borderColor: '#1E1E1E',
          },
        },
        xAxis: {
          type: 'datetime',
          title: {
            text: 'Time',
            style: {
              color: '#FFFFFF',
            },
          },
          labels: {
            step: 1,
            style: {
              color: '#FFFFFF',
            },
          },
          lineColor: 'rgba(255, 255, 255, 0.24)',
        },
        yAxis: [
          {
            gridLineColor: ' rgba(255, 255, 255, 0.12)',
            title: {
              text: 'Duration (hrs)',
            },
            labels: {
              format: '{value}',
              style: {
                color: 'white',
              },
            },
          },
        ],
        setOptions: {
          width: '100%',
        },
        series: [],
      },
      distanceOptions: {
        chart: {
          backgroundColor: '#1E1E1E',
          type: 'column',
          zoomType: 'x',
          name: '',
          events: {
            selection: (e) => {
              const chartName = e.target.title.textStr;
              if (e.xAxis) {
                this.zoomedChart = chartName;
                this.xMin = e.xAxis[0].min;
                this.xMax = e.xAxis[0].max;
                this.zoomCharts(true);
              } else {
                this.zoomedChart = '';
                this.xMin = null;
                this.xMax = null;
                this.zoomCharts(false);
              }
            },
          },
        },
        rangeSelector: {
          selected: 1,
        },
        title: {
          text: 'Distance',
          style: {
            color: '#FFFFFF',
          },
        },
        tooltip: {
          shared: true,
          backgroundColor: '#363636',
          borderRadius: 8,
          borderColor: '#363636',
          style: {
            color: '#FFFFFF',
          },
        },
        legend: {
          itemStyle: {
            color: '#FFFFFF',
            fontWeight: 'bold',
          },
        },
        plotOptions: {
          series: {
            stacking: 'normal',
            borderColor: '#1E1E1E',
          },
        },
        xAxis: {
          type: 'datetime',
          title: {
            text: 'Time',
            style: {
              color: '#FFFFFF',
            },
          },
          labels: {
            step: 1,
            style: {
              color: '#FFFFFF',
            },
          },
          lineColor: 'rgba(255, 255, 255, 0.24)',
        },
        yAxis: [
          {
            gridLineColor: ' rgba(255, 255, 255, 0.12)',
            title: {
              text: `Miles Traveled mi`,
            },
            labels: {
              format: '{value}',
              style: {
                color: 'white',
              },
            },
          },
        ],
        setOptions: {
          width: '100%',
        },
        series: [],
      },
      fuelUsageOptions: {
        chart: {
          backgroundColor: '#1E1E1E',
          type: 'line',
          zoomType: 'x',
          name: '',
          events: {
            selection: (e) => {
              const chartName = e.target.title.textStr;
              if (e.xAxis) {
                this.zoomedChart = chartName;
                this.xMin = e.xAxis[0].min;
                this.xMax = e.xAxis[0].max;
                this.zoomCharts(true);
              } else {
                this.zoomedChart = '';
                this.xMin = null;
                this.xMax = null;
                this.zoomCharts(false);
              }
            },
          },
        },
        rangeSelector: {
          selected: 1,
        },
        title: {
          text: 'Fuel Usage',
          style: {
            color: '#FFFFFF',
          },
        },
        tooltip: {
          shared: true,
          backgroundColor: '#363636',
          borderRadius: 8,
          borderColor: '#363636',
          style: {
            color: '#FFFFFF',
          },
        },
        legend: {
          itemStyle: {
            color: '#FFFFFF',
            fontWeight: 'bold',
          },
        },
        xAxis: {
          type: 'datetime',
          title: {
            text: 'Time',
            style: {
              color: '#FFFFFF',
            },
          },
          labels: {
            step: 1,
            style: {
              color: '#FFFFFF',
            },
          },
          gridLineColor: 'red',
          lineColor: 'rgba(255, 255, 255, 0.12)',
        },
        yAxis: [
          {
            labels: {
              format: '{value}',
              style: {
                color: 'white',
              },
            },
            title: {
              text: `MPG`,
            },
            gridLineColor: 'rgba(255, 255, 255, 0.12)',
          },
          {
            labels: {
              format: '{value}',
              style: {
                color: 'white',
              },
            },
            title: {
              text: `Fuel Usage gal`,
            },
            gridLineColor: 'rgba(255, 255, 255, 0.12)',
            opposite: true,
          },
        ],
        setOptions: {
          width: '100%',
        },
        series: [],
      },
      socOptions: {
        chart: {
          backgroundColor: '#1E1E1E',
          type: 'line',
          zoomType: 'x',
          name: '',
          events: {
            selection: (e) => {
              const chartName = e.target.title.textStr;
              if (e.xAxis) {
                this.zoomedChart = chartName;
                this.xMin = e.xAxis[0].min;
                this.xMax = e.xAxis[0].max;
                this.zoomCharts(true);
              } else {
                this.zoomedChart = '';
                this.xMin = null;
                this.xMax = null;
                this.zoomCharts(false);
              }
            },
          },
        },
        rangeSelector: {
          selected: 1,
        },
        title: {
          text: 'State of Charge',
          style: {
            color: '#FFFFFF',
          },
        },
        tooltip: {
          shared: true,
          backgroundColor: '#363636',
          borderRadius: 8,
          borderColor: '#363636',
          style: {
            color: '#FFFFFF',
          },
        },
        legend: {
          itemStyle: {
            color: '#FFFFFF',
            fontWeight: 'bold',
          },
        },
        xAxis: {
          type: 'datetime',
          title: {
            text: 'Time',
            style: {
              color: '#FFFFFF',
            },
          },
          labels: {
            step: 1,
            style: {
              color: '#FFFFFF',
            },
          },
          gridLineColor: 'red',
          lineColor: 'rgba(255, 255, 255, 0.12)',
        },
        yAxis: [
          {
            labels: {
              format: '{value}',
              style: {
                color: 'white',
              },
            },
            title: {
              text: 'SOC %',
            },
            gridLineColor: 'rgba(255, 255, 255, 0.12)',
          },
        ],
        setOptions: {
          width: '100%',
        },
        series: [],
      },
      powerOptions: {
        chart: {
          backgroundColor: '#1E1E1E',
          type: 'line',
          zoomType: 'x',
          name: '',
          events: {
            selection: (e) => {
              const chartName = e.target.title.textStr;
              if (e.xAxis) {
                this.zoomedChart = chartName;
                this.xMin = e.xAxis[0].min;
                this.xMax = e.xAxis[0].max;
                this.zoomCharts(true);
              } else {
                this.zoomedChart = '';
                this.xMin = null;
                this.xMax = null;
                this.zoomCharts(false);
              }
            },
          },
        },
        rangeSelector: {
          selected: 1,
        },
        title: {
          text: 'Power',
          style: {
            color: '#FFFFFF',
          },
        },
        tooltip: {
          shared: true,
          backgroundColor: '#363636',
          borderRadius: 8,
          borderColor: '#363636',
          style: {
            color: '#FFFFFF',
          },
        },
        legend: {
          itemStyle: {
            color: '#FFFFFF',
            fontWeight: 'bold',
          },
        },
        xAxis: {
          type: 'datetime',
          title: {
            text: 'Time',
            style: {
              color: '#FFFFFF',
            },
          },
          labels: {
            step: 1,
            style: {
              color: '#FFFFFF',
            },
          },
          gridLineColor: 'red',
          lineColor: 'rgba(255, 255, 255, 0.12)',
        },
        yAxis: [
          {
            labels: {
              format: '{value}',
              style: {
                color: 'white',
              },
            },
            title: {
              text: 'Power kW',
            },
            gridLineColor: 'rgba(255, 255, 255, 0.12)',
          },
        ],
        setOptions: {
          width: '100%',
        },
        series: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      userRoles: 'getUserRoles',
      userPreferences: 'getUserPreferences',
    }),
  },
  watch: {
    data: function () {
      if (this.data && this.data.length > 0) {
        this.populateCharts();
      }
    },
  },
  created() {
    this.isMetric = this.userPreferences.regionPref === 'metric' ? true : false;
  },
  mounted() {
    if (this.data && this.data.length > 0) {
      this.populateCharts();
    }
  },
  methods: {
    async populateCharts() {
      let movingTime = [];
      let idleTime = [];
      let apuTime = [];
      let fuelUsage = [];
      let distance = [];
      let mmpg = [];
      let tmpg = [];
      let powerIn = [];
      let powerOut = [];
      let minSOC = [];
      let maxSOC = [];
      let avgSOC = [];

      const first_truck = this.data[0].truck_id;

      // process the data to feed into highcharts
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i].truck_id != first_truck) {
          break;
        }
        //Usage
        movingTime.push([
          Date.parse(this.data[i].Day),
          Math.round(this.data[i].movingTime * 100) / 100,
        ]);
        idleTime.push([
          Date.parse(this.data[i].Day),
          Math.round(this.data[i].idleTime * 100) / 100,
        ]);
        apuTime.push([
          Date.parse(this.data[i].Day),
          Math.round(this.data[i].apuTime * 100) / 100,
        ]);
        //Distance
        distance.push([
          Date.parse(this.data[i].Day),
          regionalSetting(
            this.userPreferences.regionPref,
            this.data[i].distance,
            'distance',
            2
          ),
        ]);
        //Fuel Usage
        fuelUsage.push([
          Date.parse(this.data[i].Day),
          regionalSetting(
            this.userPreferences.regionPref,
            this.data[i].totalFuelUsage,
            'volume',
            1
          ),
        ]);
        mmpg.push([
          Date.parse(this.data[i].Day),
          regionalSetting(
            this.userPreferences.regionPref,
            this.data[i].estMovingKPL,
            'fuel_economy',
            2
          ),
        ]);
        tmpg.push([
          Date.parse(this.data[i].Day),
          regionalSetting(
            this.userPreferences.regionPref,
            this.data[i].estTotalKPL,
            'fuel_economy',
            2
          ),
        ]);
        //Power
        powerIn.push([
          Date.parse(this.data[i].Day),
          Math.round(this.data[i].powerIn * 100) / 100,
        ]);
        powerOut.push([
          Date.parse(this.data[i].Day),
          Math.round(this.data[i].powerOut * 100) / 100,
        ]);
        minSOC.push([
          Date.parse(this.data[i].Day),
          Math.round(this.data[i].socMin * 100) / 100,
        ]);
        maxSOC.push([
          Date.parse(this.data[i].Day),
          Math.round(this.data[i].socMax * 100) / 100,
        ]);
        avgSOC.push([
          Date.parse(this.data[i].Day),
          Math.round(this.data[i].socAvg * 100) / 100,
        ]);
      }
      if (this.isMetric) {
        this.distanceOptions.yAxis[0].title.text = 'Kilometers Traveled km';
        this.fuelUsageOptions.yAxis[1].title.text = 'KPL';
        this.fuelUsageOptions.yAxis[0].title.text = 'Fuel Usage L';
      }
      const colors = ['#DF1278', '#0070F2', '#188918', '#07838F', '#78909C'];
      const colors2 = ['#FA4F96', '#1B90FF', '#36A41D', '#04ACA7', '#90A4AE'];
      const colors3 = ['#FE83AE', '#4DB1FF', '#5DC122', '#00CEAC', '#B0BEC5'];
      const colors5 = ['#FECBDA', '#A6E0FF', '#BDE986', '#64EDD2', '#ECEFF1'];

      // feed into highcharts
      this.usageTimeOptions.series = [
        {
          type: 'column',
          name: 'Moving Time',
          data: movingTime,
          color: colors[0],
        },
        {
          type: 'column',
          name: 'Idle Time',
          data: idleTime,
          color: colors[1],
        },
        {
          type: 'column',
          name: 'Apu Time',
          data: apuTime,
          color: colors[2],
        },
      ];
      this.distanceOptions.series = [
        {
          type: 'line',
          name: `${this.isMetric ? 'Kilometers Traveled' : 'Miles Traveled'}`,
          data: distance,
          color: colors[2],
        },
      ];
      this.socOptions.series = [
        {
          type: 'line',
          name: 'min SOC',
          data: minSOC,
          color: colors[0],
        },
        {
          type: 'line',
          name: 'max SOC',
          data: maxSOC,
          color: colors[1],
        },
        {
          type: 'line',
          name: 'avg SOC',
          data: avgSOC,
          color: colors[2],
        },
      ];
      this.powerOptions.series = [
        {
          type: 'line',
          name: 'Power In',
          data: powerIn,
          color: colors[0],
        },
        {
          type: 'line',
          name: 'Power Out',
          data: powerOut,
          color: colors[1],
        },
      ];
      this.fuelUsageOptions.series = [
        {
          type: 'line',
          name: `${this.isMetric ? 'Fuel Used L' : 'Fuel Used Gal'}`,
          data: fuelUsage,
          color: colors[0],
          yAxis: 0,
        },
        {
          type: 'line',
          name: `${this.isMetric ? 'MKPL' : 'MMPG'}`,
          data: mmpg,
          color: colors[1],
          yAxis: 1,
        },
        {
          type: 'line',
          name: `${this.isMetric ? 'TKPL' : 'TMPG'}`,
          data: tmpg,
          color: colors[2],
          yAxis: 1,
        },
      ];
      this.showCharts = true;
    },
    zoomCharts: function (state) {
      const vm = this;
      _.forEach(vm.charts, function (o) {
        if (state) {
          for (const graph of vm.graphs) {
            const chartName = vm.chartNames[graph];
            vm.$refs[chartName].chart.showResetZoom();
          }
          o.xAxis[0].setExtremes(vm.xMin, vm.xMax);
        } else {
          o.xAxis[0].setExtremes(null, null);
        }
      });
    },
  },
};
</script>

<style scoped>
.graph {
  margin-bottom: 30px;
}
</style>
