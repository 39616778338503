<template>
  <v-dialog v-model="openDialog" class="dialog" width="750">
    <loader v-if="loading" />
    <alert
      v-if="showAlert"
      :alert-type="alertType"
      :alert-message="alertMessage"
    />
    <v-card>
      <v-container>
        <v-row class="ma-2">
          <v-col align="center" cols="12" sm="6" md="6" lg="6" xl="6">
            <div class="font-weight-bold text-h5">Selected Trucks</div>
            <v-card align="center" class="padding margin">
              <v-chip
                v-for="(truck, index) in trucks"
                :key="index"
                class="margin"
                color="primary"
                >{{ truck.name }}</v-chip
              >
            </v-card>
          </v-col>
          <v-col align="center" cols="12" sm="6" md="6" lg="6" xl="6">
            <div class="font-weight-bold text-h5">
              Select Configuration to assign trucks
            </div>
            <div class="font-weight-bold">
              Note: The configuration will be assigned to all trucks selected.
            </div>
            <v-combobox
              v-model="selectedConfig"
              class="ma-1"
              :items="configurations"
              item-text="config_name"
              item-value="id"
              return-object
              clearable
              hide-selected
              outlined
              label="Select Configuration"
            ></v-combobox>
          </v-col>
        </v-row>
        <v-row class="ma-2">
          <v-col class="hidden-xs-and-down"><v-spacer /></v-col>
          <v-col align="center" cols="12" sm="4" md="4" lg="4" xl="4"
            ><v-btn @click="openDialog = false">Dismiss</v-btn></v-col
          >
          <v-col align="center" cols="12" sm="4" md="4" lg="4" xl="4"
            ><v-btn :disabled="!selectedConfig" @click="updateTrucks"
              >Update Trucks Configuration</v-btn
            ></v-col
          >
          <v-col class="hidden-xs-and-down"><v-spacer /></v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import loader from '@/utilities/loader';
import alert from '@/utilities/alert';
import {
  getAllConfigurations,
  assignTrucksToConfiguration,
} from '@/api/configurations';
export default {
  name: 'UpdateTruckConfig',
  components: { loader, alert },
  props: {
    selectedTrucks: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      openDialog: false,
      trucks: this.selectedTrucks,
      selectedConfig: '',
      loading: false,
      showAlert: false,
      alertType: '',
      alertMessage: '',
      configurations: [],
    };
  },
  watch: {
    openDialog: function (newValue) {
      if (!newValue) {
        this.openDialog = false;
        this.$emit('closed', false);
      }
    },
  },
  async mounted() {
    this.openDialog = true;
    try {
      const { data } = await getAllConfigurations();
      this.configurations = data;
    } catch (e) {
      console.log(e);
    }
  },
  methods: {
    async updateTrucks() {
      this.loading = true;
      try {
        const data = {
          config_id: this.selectedConfig['id'].toString(),
          truck_ids: this.selectedTrucks.map((el) => el['id']),
        };

        let res = await assignTrucksToConfiguration(data);

        if (res.status === 204) {
          setTimeout(async () => {
            this.loading = false;
            this.showAlert = true;
            this.alertMessage = 'Trucks Assigned Successfully!';
            this.alertType = 'success';
            this.$emit('refresh');
            this.selectedConfig = '';
            this.alertTimeout(true);
          }, 1000);
        }
      } catch (e) {
        this.alertMessage = 'There was an issue updating trucks!';
        if (e.response?.status === 409)
          this.alertMessage =
            'The Truck Types do not match the Software Truck Type';
        this.loading = false;
        this.showAlert = true;
        this.alertType = 'error';
        this.alertTimeout(false);
      }
    },
    alertTimeout(closeDialog) {
      setTimeout(() => {
        this.showAlert = false;
        this.openDialog = closeDialog ? false : true;
      }, 2000);
    },
  },
};
</script>
<style scoped>
.dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  height: auto;
  width: auto;
  z-index: 1;
  transform: translate(-50%, -50%);
}
@media only screen and (min-width: 960px) {
  .padding {
    padding: 1rem;
  }
  .margin {
    margin: 0.2rem;
  }
}
.full-padding {
  padding: 1rem;
}
.full-margin {
  margin: 0.2rem;
}
</style>
