<template>
  <v-container fluid>
    <v-row class="pa-0"
      ><v-col class="pa-0">{{ caseData.length }} results</v-col>

      <v-col class="pa-0" align="right">
        <v-btn
          v-if="userCanDownloadVinComparisons"
          color="primary"
          style="color: black"
          class="mx-1"
          @click="exportCSV"
        >
          <download-csv id="csv" name="truck_comparisons.csv" :data="assets">
            <v-icon id="csv2">mdi-download</v-icon>
            Download Vin Comparisons
          </download-csv>
        </v-btn>

        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="userCanAddCase"
              color="primary"
              rounded
              fab
              small
              style="color: black"
              v-bind="attrs"
              v-on="on"
              @click="addCase = true"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Add case</span>
        </v-tooltip>
      </v-col></v-row
    >
    <v-row>
      <v-col class="pa-0" cols="12">
        <v-data-table
          :loading="loading"
          :no-data-text="noDataMsg"
          :headers="labels"
          :items="caseData"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-menu style="top: 312px; left: 300px" offset-y>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">mdi-dots-vertical</v-icon>
              </template>
              <v-list dense>
                <v-list-item-group>
                  <v-list-item
                    v-for="(action, index) in actionItems"
                    :key="index"
                    :class="action.title"
                    @click="setAction(item, action.title)"
                    ><v-list-item-icon class="mr-2">
                      <v-icon v-text="action.icon"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        class="pr-2"
                        v-text="action.title"
                      ></v-list-item-title> </v-list-item-content
                  ></v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu> </template
        ></v-data-table> </v-col
    ></v-row>
    <AddCase
      v-if="addCase"
      :asset-id="assetId"
      :vin="vin"
      @closed="closeCase"
      @refresh="refreshData"
    />
    <v-dialog v-model="openDetails" class="dialog" width="750">
      <v-card>
        <v-card-text>
          <v-row
            v-for="(value, propertyName, index) in caseDetail"
            :key="index"
          >
            <v-col
              ><strong>{{ propertyName }}</strong
              >: {{ value }}</v-col
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import dateFormat from 'dateformat';
import { getCases, getAssets } from '@/api/salesforce';
import { mapGetters } from 'vuex';
import AddCase from './AddCase';
export default {
  name: 'Cases',
  components: { AddCase },
  props: {
    vin: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      caseDetail: {},
      openDetails: false,
      loading: false,
      assets: [],
      labels: [
        { text: 'Case #', value: 'CaseNumber' },
        { text: 'Type', value: 'Type' },
        { text: 'Priority', value: 'Priority' },
        { text: 'Status', value: 'Status' },
        { text: 'Subject', value: 'Subject' },
        { text: 'Date/Time Created', value: 'CreatedDate' },
        { text: 'Date/Time Closed', value: 'ClosedDate' },
        { text: 'Actions', value: 'actions', align: 'right' },
      ],
      actionItems: [{ title: 'Case Details', icon: 'mdi-list-box-outline' }],
      assetId: '',
      caseData: [],
      noDataMsg: 'There are no recent cases for this truck',
      addCase: false,
      valid: false,
    };
  },
  computed: {
    ...mapGetters({
      userRoles: 'getUserRoles',
    }),
    userCanAddCase() {
      return this.userRoles && this.userRoles.includes('admin');
    },
    userCanDownloadVinComparisons() {
      return this.userRoles && this.userRoles.includes('admin');
    },
  },
  async mounted() {
    await this.getCasesByTruck();
    if (this.userCanDownloadVinComparisons) {
      await this.getAssetsForExport();
    }
  },
  methods: {
    async getCasesByTruck() {
      try {
        let res = await getCases(this.vin);
        if (res.data.length) {
          res.data.forEach((d) => {
            d.CreatedDate = dateFormat(
              new Date(d.CreatedDate),
              'mm/dd/yyyy hh:MM TT'
            );
            d.ClosedDate = d.ClosedDate
              ? dateFormat(new Date(d.ClosedDate), 'mm/dd/yyyy hh:MM TT')
              : '';
          });
          this.caseData = res.data;
          this.assetId = this.caseData[0]['AssetId'];
        }
        this.loading = false;
      } catch (e) {
        console.log('There was an issue retrieving cases');
        this.loading = false;
      }
    },
    async getAssetsForExport() {
      try {
        let { data } = await getAssets();
        this.assets = data;
      } catch (e) {
        console.log('Error grabbing assets');
      }
    },
    async addNewCase() {
      if (this.assetId) {
        this.newCase.assetId = this.assetId;
      }
    },
    async setAction(item) {
      this.caseDetail = item;
      this.openDetails = true;
    },
    closeCase() {
      this.addCase = false;
    },
    async refreshData() {
      this.loading = true;
      await this.getCasesByTruck();
    },
    exportCSV(event) {
      if (event.target.id != 'csv' && event.target.id != 'csv2') {
        document.getElementById('csv').click();
      }
    },
  },
};
</script>
<style scoped>
.dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  height: auto;
  width: auto;
  z-index: 1;
  transform: translate(-50%, -50%);
}
@media only screen and (min-width: 960px) {
  .padding {
    padding: 1rem;
  }
  .margin {
    margin: 0.2rem;
  }
}
</style>
