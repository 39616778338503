<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="px-0 my-1">
        <template>
          <section>
            <highcharts
              :constructor-type="'ganttChart'"
              :options="chartOptions"
            ></highcharts>
          </section>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Highcharts from 'highcharts';
import Gantt from 'highcharts/modules/gantt';

Gantt(Highcharts);
export default {
  name: 'Gantt',
  components: {},
  props: {
    title: {
      type: String,
      required: true,
    },
    journeyStart: {
      type: Number,
      required: true,
    },
    journeyEnd: {
      type: Number,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    tzOffset: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      // https://jsfiddle.net/gh/get/library/pure/highcharts/highcharts/tree/master/samples/gantt/demo/custom-labels
      // https://codesandbox.io/s/highcharts-vue-demo-lxgm2?file=/src/components/Chart.vue:202-1311
      // https://jsfiddle.net/gh/get/library/pure/highcharts/highcharts/tree/master/samples/gantt/demo/styled-mode
      // https://jsfiddle.net/gh/get/library/pure/highcharts/highcharts/tree/master/samples/gantt/demo/left-axis-table
      chartOptions: {
        credits: { enabled: false },
        chart: {
          type: 'gantt',
          backgroundColor: '#272727',
          style: {
            color: '#FFFFFF',
            fontFamily: 'Rawline',
            fontWeight: 'bold',
          },
        },
        // https://jsfiddle.net/gh/get/library/pure/highcharts/highcharts/tree/master/samples/highcharts/time/timezoneoffset/
        time: {
          timezoneOffset: this.tzOffset,
        },
        title: {
          text: this.title,
        },
        xAxis: [
          {
            min: this.journeyStart,
            max: this.journeyEnd,
            type: 'datetime',
            // https://api.highcharts.com/gantt/xAxis.dateTimeLabelFormats
            dateTimeLabelFormats: {
              minute: '%l:%M %P',
              hour: '%l:%M %P',
            },
            tickInterval: 30 * 60 * 1000, // https://api.highcharts.com/gantt/xAxis.tickInterval
            units: [['minute', [30]]], // https://api.highcharts.com/gantt/xAxis.units
            labels: {
              style: {
                color: 'white',
              },
            },
            lineColor: '#272727',
            tickColor: '#a3a3a3',
          },
          {
            min: this.journeyStart,
            max: this.journeyEnd,
            type: 'datetime',
            dateTimeLabelFormats: {
              day: '%b %e',
            },
            tickInterval: 24 * 3600 * 1000,
            labels: {
              style: {
                color: 'white',
              },
            },
            gridLineColor: '#272727',
            lineColor: '#272727',
            tickColor: '#272727',
          },
        ],
        yAxis: {
          categories: this.categories,
          labels: {
            style: {
              color: 'white',
            },
          },
          gridLineColor: '#272727',
          lineColor: '#272727',
          tickColor: '#272727',
        },
        series: [
          {
            name: 'Journey',
            borderRadius: 0,
            borderWidth: 0,
            data: this.data,
            turboThreshold: 2000, // Supports longer journey data
            borderColor: '#272727',
          },
        ],
        exporting: {
          enabled: false,
        },
      },
    };
  },
  watch: {
    tzOffset(tzOffset) {
      this.chartOptions.time = { timezoneOffset: tzOffset };
    },
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped>
.highcharts-yaxis-grid .highcharts-grid-line {
  stroke-width: 0px;
  stroke: rgba(255, 255, 255, 0.12);
}

.highcharts-xaxis-grid {
  stroke-width: 1px;
  stroke: rgba(255, 255, 255, 0.12);
}
</style>
