<template>
  <div class="outerDiv">
    <div class="mainDiv">
      <div class="topRow">
        <div class="title">
          <div class="truck">
            Truck
            <div v-if="nameEdit" class="editButton">
              <v-btn
                icon
                plain
                color="#43b02a"
                class="pb-1"
                @click="$emit('openNameChange')"
              >
                <v-icon>mdi-pencil</v-icon><b>Edit</b></v-btn
              >
            </div>
          </div>
          <div class="truckNumber">
            {{ truckName }}
          </div>
        </div>
        <img src="../../../../../public/images/assets/Truck.png" />
      </div>
      <div class="flexCol">
        <div class="flexRow">
          <div class="statContainer">
            <div class="statIconContainer">
              <v-icon class="statIcon" color="primary" large
                >mdi-gas-station</v-icon
              >
            </div>
            <div class="statLabel">
              <div class="statText">Fuel Economy</div>
              <div class="statValue">{{ data?.fuelEconomy || '—' }} mpge</div>
            </div>
          </div>
          <div class="statContainer">
            <div class="statIconContainer">
              <v-icon class="statIcon" color="#F2B046" large>mdi-alert</v-icon>
            </div>
            <div class="statLabel">
              <div class="statText">Journey Faults</div>
              <div class="statValue">
                {{ data?.journeyFaults !== null ? data?.journeyFaults : '—' }}
              </div>
            </div>
          </div>
        </div>
        <div class="flexRow">
          <div class="statContainer">
            <div class="statIconContainer">
              <svg
                style="display: block; margin: auto; margin-top: 20%"
                xmlns="http://www.w3.org/2000/svg"
                width="33"
                height="33"
                viewBox="0 0 33 33"
                fill="none"
              >
                <path
                  d="M9.5006 20.5005L12 12.5"
                  stroke="#2196F3"
                  stroke-width="1.5"
                />
                <path
                  d="M17.5 9.5L23.5 15"
                  stroke="#2196F3"
                  stroke-width="1.5"
                />
                <path
                  d="M15.5625 12L21.0664 17"
                  stroke="#2196F3"
                  stroke-width="1.5"
                />
                <circle cx="23" cy="4" r="2" fill="#2196F3" />
                <circle cx="8" cy="25" r="6" fill="#2196F3" />
                <circle cx="13" cy="8" r="6" fill="#2196F3" />
                <circle cx="25" cy="20" r="6" fill="#2196F3" />
              </svg>
            </div>
            <div class="statLabel">
              <div class="statText">N<sub>2</sub>0 Reduction</div>
              <div class="statValue">
                {{ data?.noxReduction !== null ? data?.noxReduction : '—' }}
                lbs
              </div>
            </div>
          </div>
          <div class="statContainer">
            <div class="statIconContainer">
              <v-icon class="statIcon" color="primary" large>mdi-leaf</v-icon>
            </div>
            <div class="statLabel">
              <div class="statText">CO<sub>2</sub> Reduction</div>
              <div class="statValue">
                {{ data?.co2Reduction !== null ? data?.co2Reduction : '—' }}
                lbs
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VehicleStatistics',
  props: {
    data: {
      required: true,
      type: Object,
      default: () => {},
    },
    truckName: {
      required: true,
      type: String,
    },
    nameEdit: {
      required: true,
      type: Boolean,
    },
  },
};
</script>

<style scoped>
.outerDiv {
  /* match ~half .title margin-bottom to appropriately offset 
  component*/
  margin-top: 25px;
  margin-bottom: 25px;
}
.mainDiv {
  background-color: #1a1a1a;
  width: 500px;
  border-radius: 8px;
  display: grid;
  box-shadow: 0px 1px 32px 0px rgba(0, 0, 0, 0.12),
    0px 12px 24px 0px rgba(0, 0, 0, 0.12), 0px 8px 16px 0px rgba(0, 0, 0, 0.12);
}
.topRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.title {
  padding: 20px;
  margin-top: 17px;
}
.truck {
  font-weight: bold !important;
  display: flex;
  justify-content: flex-start;
  color: var(--dark-main-color, #e0e0e0);

  /* 20 sp • H6 Headline */
  font-family: Rawline;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 140% */
  letter-spacing: 0.15px;
}
.truckNumber {
  color: var(--dark-base-chart-title, rgba(255, 255, 255, 0.87));
  font-family: Rawline;
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  max-width: 200px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: clip;
}
.editButton {
  margin-left: 1.3em;
  margin-top: -4px;
}
.truckModel {
  color: var(--dark-base-chart-subtitle, rgba(255, 255, 255, 0.6));

  /* Chart Subtitle */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.25px;
}
.flexCol {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 186px;
}
.flexRow {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.statContainer {
  width: 200px;
  display: flex;
  flex-direction: row;
}
.statIconContainer {
  display: block;
  width: 57px;
  height: 57px;
  border-radius: 8px;
  border: 1px solid #888;
}
.statIcon {
  display: block;
  margin: auto;
  margin-left: 17.8%;
  /* display: flex;
  justify-content: center; */
}
.statLabel {
  margin-left: 20px;
}
.statText {
  color: rgba(224, 224, 224, 0.6);
  /* 16 sp • Body 1 */
  font-family: Rawline;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.44px;
  flex-wrap: wrap;
}
.statValue {
  color: var(--white, #fff);
  /* 24 sp • H5 Headline */
  font-family: Rawline;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>
