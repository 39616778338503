var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", width: "750" },
      model: {
        value: _vm.openDialog,
        callback: function ($$v) {
          _vm.openDialog = $$v
        },
        expression: "openDialog",
      },
    },
    [
      _vm.loading ? _c("loader") : _vm._e(),
      _c(
        "v-card",
        [
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  attrs: { "lazy-validation": "" },
                  model: {
                    value: _vm.valid,
                    callback: function ($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid",
                  },
                },
                [
                  _c("h3", [_vm._v("New Trigger")]),
                  _c(
                    "v-text-field",
                    {
                      staticStyle: {
                        "margin-top": "15px",
                        "margin-bottom": "-25px",
                      },
                      attrs: {
                        placeholder: "Name",
                        rules: _vm.nameRules,
                        required: "",
                        outlined: "",
                      },
                      model: {
                        value: _vm.name,
                        callback: function ($$v) {
                          _vm.name = $$v
                        },
                        expression: "name",
                      },
                    },
                    [_vm._v(" Name")]
                  ),
                  _c(
                    "v-radio-group",
                    {
                      attrs: { row: "", mandatory: "", required: "" },
                      model: {
                        value: _vm.publicOrPrivate,
                        callback: function ($$v) {
                          _vm.publicOrPrivate = $$v
                        },
                        expression: "publicOrPrivate",
                      },
                    },
                    [
                      _c("v-radio", {
                        attrs: { label: "Public", value: "public" },
                      }),
                      _c("v-radio", {
                        attrs: { label: "Private", value: "private" },
                      }),
                    ],
                    1
                  ),
                  _c("v-textarea", {
                    attrs: { label: "Description", outlined: "" },
                    model: {
                      value: _vm.description,
                      callback: function ($$v) {
                        _vm.description = $$v
                      },
                      expression: "description",
                    },
                  }),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "pb-0",
                          attrs: {
                            cols: "12",
                            xl: "6",
                            lg: "6",
                            md: "6",
                            sm: "6",
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.signalDBNames,
                              label: "Left Signal DB Name",
                              outlined: "",
                            },
                            on: { change: _vm.filterSignals },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "mt-0" },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-0",
                          attrs: {
                            cols: "12",
                            xl: "4",
                            lg: "4",
                            md: "4",
                            sm: "4",
                          },
                        },
                        [
                          _c("v-combobox", {
                            attrs: {
                              items: _vm.signals,
                              "item-text": "signal",
                              label: "Left Signal",
                              outlined: "",
                              required: "",
                              rules: [(v) => !!v || "Required!"],
                              "return-object": "",
                            },
                            on: { change: _vm.filterData },
                            model: {
                              value: _vm.leftSignal,
                              callback: function ($$v) {
                                _vm.leftSignal = $$v
                              },
                              expression: "leftSignal",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-0",
                          attrs: {
                            cols: "12",
                            xl: "4",
                            lg: "4",
                            md: "4",
                            sm: "4",
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.operators,
                              "item-text": "name",
                              label: "Operator",
                              outlined: "",
                              required: "",
                              rules: [(v) => !!v || "Required!"],
                              "return-object": "",
                            },
                            model: {
                              value: _vm.operator,
                              callback: function ($$v) {
                                _vm.operator = $$v
                              },
                              expression: "operator",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-0",
                          attrs: {
                            cols: "12",
                            xl: "4",
                            lg: "4",
                            md: "4",
                            sm: "4",
                          },
                        },
                        [
                          _vm.rightFieldType === "signal"
                            ? _c("v-combobox", {
                                attrs: {
                                  items: _vm.signals,
                                  "item-text": "signal",
                                  label: "Right Signal",
                                  outlined: "",
                                  required: "",
                                  "return-object": "",
                                  rules: [(v) => !!v || "Required!"],
                                },
                                model: {
                                  value: _vm.rightSignal,
                                  callback: function ($$v) {
                                    _vm.rightSignal = $$v
                                  },
                                  expression: "rightSignal",
                                },
                              })
                            : _vm.rightFieldType === "enums"
                            ? _c("v-combobox", {
                                attrs: {
                                  items: _vm.signalEnums,
                                  "item-text": (signal) =>
                                    `${signal.value} - ${signal.key}`,
                                  label: "Enums",
                                  outlined: "",
                                  required: "",
                                  "return-object": "",
                                  rules: [(v) => !!v || "Required!"],
                                },
                                model: {
                                  value: _vm.rightEnums,
                                  callback: function ($$v) {
                                    _vm.rightEnums = $$v
                                  },
                                  expression: "rightEnums",
                                },
                              })
                            : _vm.rightFieldType === "value"
                            ? _c("v-text-field", {
                                attrs: {
                                  placeholder: "Value",
                                  outlined: "",
                                  required: "",
                                  rules: [(v) => !!v || "Required!"],
                                },
                                model: {
                                  value: _vm.rightValue,
                                  callback: function ($$v) {
                                    _vm.rightValue = $$v
                                  },
                                  expression: "rightValue",
                                },
                              })
                            : _vm.rightFieldType === "distance_m"
                            ? _c("v-text-field", {
                                attrs: {
                                  placeholder: "Distance(m)",
                                  outlined: "",
                                  required: "",
                                  rules: [(v) => !!v || "Required!"],
                                },
                                model: {
                                  value: _vm.rightValue,
                                  callback: function ($$v) {
                                    _vm.rightValue = $$v
                                  },
                                  expression: "rightValue",
                                },
                              })
                            : _vm.rightFieldType === "duration_ms"
                            ? _c("v-text-field", {
                                attrs: {
                                  placeholder: "Duration(ms)",
                                  outlined: "",
                                  required: "",
                                  rules: [(v) => !!v || "Required!"],
                                },
                                model: {
                                  value: _vm.rightValue,
                                  callback: function ($$v) {
                                    _vm.rightValue = $$v
                                  },
                                  expression: "rightValue",
                                },
                              })
                            : _vm._e(),
                          _c(
                            "v-radio-group",
                            {
                              staticStyle: {
                                "margin-top": "0",
                                "padding-top": "0",
                              },
                              attrs: {
                                row: "",
                                mandatory: "",
                                py: "0",
                                my: "0",
                              },
                              model: {
                                value: _vm.rightFieldType,
                                callback: function ($$v) {
                                  _vm.rightFieldType = $$v
                                },
                                expression: "rightFieldType",
                              },
                            },
                            [
                              _c(
                                "v-row",
                                { staticClass: "pt-3" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-0",
                                      attrs: { cols: "6" },
                                    },
                                    [
                                      _vm.hasEnums
                                        ? _c("v-radio", {
                                            attrs: {
                                              label: "Enums",
                                              value: "enums",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.resetField("enums")
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                      _c("v-radio", {
                                        attrs: {
                                          label: "Signal",
                                          value: "signal",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.resetField("signal")
                                          },
                                        },
                                      }),
                                      _c("v-radio", {
                                        attrs: {
                                          label: "Value",
                                          value: "value",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.resetField("value")
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-0",
                                      attrs: { cols: "6" },
                                    },
                                    [
                                      _c("v-radio", {
                                        attrs: {
                                          label: "Distance",
                                          value: "distance_m",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.resetField("distance_m")
                                          },
                                        },
                                      }),
                                      _c("v-radio", {
                                        attrs: {
                                          label: "Duration",
                                          value: "duration_ms",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.resetField("duration_ms")
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "right",
                        "margin-top": "20px",
                      },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-2",
                          on: {
                            click: function ($event) {
                              _vm.openDialog = false
                            },
                          },
                        },
                        [_vm._v("Cancel")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", disabled: !_vm.valid },
                          on: {
                            click: function ($event) {
                              return _vm.submitTrigger()
                            },
                          },
                        },
                        [_vm._v("Save")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }