var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      staticClass: "dialog",
      attrs: { width: "400" },
      model: {
        value: _vm.removeDialog,
        callback: function ($$v) {
          _vm.removeDialog = $$v
        },
        expression: "removeDialog",
      },
    },
    [
      _vm.loading ? _c("loader") : _vm._e(),
      _c(
        "v-card",
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                { staticClass: "pl-4", attrs: { align: "center" } },
                [
                  _c("v-col", { attrs: { align: "left", cols: "7" } }, [
                    _c("h3", [_vm._v("Deactivate Customer")]),
                  ]),
                  _c("v-col", [_c("v-spacer")], 1),
                ],
                1
              ),
              _c("v-col", { attrs: { justify: "center", cols: "12" } }, [
                _vm._v(" Are you sure you want to deactivate "),
                _c("br"),
                _vm._v(_vm._s(_vm.customer.name) + " ? "),
              ]),
              _c(
                "v-row",
                { staticClass: "my-1" },
                [
                  _c(
                    "v-col",
                    { staticClass: "pt-0 hidden-xs-and-down" },
                    [_c("v-spacer")],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 mr-1",
                      attrs: { align: "center", cols: "3" },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { plain: "", color: "primary" },
                          on: {
                            click: function ($event) {
                              _vm.removeDialog = false
                            },
                          },
                        },
                        [_vm._v("Close")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 mr-3 mr-1",
                      attrs: { align: "center", cols: "3" },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticStyle: { color: "black" },
                          attrs: { color: "primary" },
                          on: { click: _vm.removeCustomer },
                        },
                        [_vm._v("Agree")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }