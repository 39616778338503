var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              attrs: {
                cols: "3",
                xs: "12",
                sm: "12",
                md: "12",
                lg: "3",
                xl: "3",
              },
            },
            [
              _c("v-text-field", {
                attrs: {
                  placeholder: "Search",
                  solo: "",
                  "prepend-inner-icon": "mdi-magnify",
                  "background-color": "shade",
                },
                model: {
                  value: _vm.searchText,
                  callback: function ($$v) {
                    _vm.searchText = $$v
                  },
                  expression: "searchText",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.loading ? _c("loader") : _vm._e(),
      _c("v-data-table", {
        staticClass: "elevation-1",
        attrs: {
          headers: _vm.dataHeaders,
          items: _vm.dtcRows,
          "items-per-page": 5,
          loading: _vm.loading,
          search: _vm.searchText,
          "item-key": "id",
        },
        scopedSlots: _vm._u([
          {
            key: "item.workbook_link",
            fn: function ({ item }) {
              return [
                item.workbook_link !== "---"
                  ? _c(
                      "a",
                      { attrs: { href: item.workbook_link, target: "_blank" } },
                      [_vm._v(_vm._s(item.link_title))]
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }