var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c("v-card-title", [_vm._v("Add Test")]),
      _c("v-card-text", [_vm._v("Fill in all fields to add a new test")]),
      _c("v-divider"),
      _c(
        "v-form",
        {
          ref: "form",
          attrs: { "lazy-validation": "" },
          model: {
            value: _vm.formValid,
            callback: function ($$v) {
              _vm.formValid = $$v
            },
            expression: "formValid",
          },
        },
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "formLabel", attrs: { sm: "3" } },
                    [_vm._v("Category: ")]
                  ),
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        staticClass: "formInput",
                        attrs: {
                          outlined: "",
                          label:
                            "Will display assigned category from selected step",
                          disabled: "",
                        },
                        model: {
                          value: _vm.assignedCategory,
                          callback: function ($$v) {
                            _vm.assignedCategory = $$v
                          },
                          expression: "assignedCategory",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "formLabel", attrs: { sm: "3" } },
                    [_vm._v("Test Name*:")]
                  ),
                  _c(
                    "v-col",
                    { attrs: { sm: "9" } },
                    [
                      _c("v-text-field", {
                        staticClass: "formInput",
                        attrs: {
                          rules: _vm.nameRules,
                          outlined: "",
                          label: "Add Name",
                          required: "",
                        },
                        model: {
                          value: _vm.name,
                          callback: function ($$v) {
                            _vm.name = $$v
                          },
                          expression: "name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "formLabel", attrs: { sm: "3" } },
                    [_vm._v("Step*: ")]
                  ),
                  _c(
                    "v-col",
                    [
                      _c("v-select", {
                        staticClass: "formInput",
                        attrs: {
                          items: _vm.stepItems,
                          rules: _vm.stepRules,
                          outlined: "",
                          label: "Choose Step",
                          required: "",
                        },
                        model: {
                          value: _vm.selectedStep,
                          callback: function ($$v) {
                            _vm.selectedStep = $$v
                          },
                          expression: "selectedStep",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "formLabel", attrs: { sm: "3" } },
                    [_vm._v("Test Procedure: ")]
                  ),
                  _c(
                    "v-col",
                    [
                      _c("v-textarea", {
                        attrs: { outlined: "", label: "Enter Procedure" },
                        model: {
                          value: _vm.test_procedure,
                          callback: function ($$v) {
                            _vm.test_procedure = $$v
                          },
                          expression: "test_procedure",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "formLabel", attrs: { sm: "3" } },
                    [_vm._v("Expected Results: ")]
                  ),
                  _c(
                    "v-col",
                    [
                      _c("v-textarea", {
                        attrs: {
                          outlined: "",
                          label: "Enter Expected Results",
                        },
                        model: {
                          value: _vm.exp_results,
                          callback: function ($$v) {
                            _vm.exp_results = $$v
                          },
                          expression: "exp_results",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "formLabel", attrs: { sm: "3" } },
                    [_vm._v("Conditions: ")]
                  ),
                  _c(
                    "v-col",
                    [
                      _c("v-textarea", {
                        attrs: { outlined: "", label: "Enter Pre-Conditions" },
                        model: {
                          value: _vm.pre_conditions,
                          callback: function ($$v) {
                            _vm.pre_conditions = $$v
                          },
                          expression: "pre_conditions",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", text: "" },
                  on: { click: _vm.cancel },
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { disabled: !_vm.formValid, color: "primary" },
                  on: { click: _vm.exportTest },
                },
                [_vm._v(" Add Test ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }