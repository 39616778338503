<template>
  <div id="trucks">
    <v-container fluid>
      <v-row>
        <v-col class="py-0">
          <!-- Temp Hide Feature: v-model="selectedTrucks" :show-select="userCanAssignTrucks" -->
          <v-data-table
            :headers="headers"
            :items="exTrucks"
            class="elevation-1 secondary locked-table-style row-height-50"
            :search="searchText"
          >
            <template v-slot:[`item.name`]="props">
              <th class="body-5">{{ props.item.number }}</th>
              <div class="body-6">{{ props.item.company }}</div>
            </template>
            <!-- ----------------- Temp Hide -------------------  -->
            <!--
            <template v-slot:[`item.config_name`]="{ item }">
              <v-chip :color="matchConfigurations(item)" dark>
                {{ item.config_name }}
              </v-chip>
            </template> -->
            <template v-slot:[`item.actions`]="{ item }">
              <v-menu style="top: 312px; left: 300px" offset-y>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-dots-vertical</v-icon>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(route, index) in actionItems"
                    :key="index"
                    :class="route.title"
                    @click="setRoute(item, route.title)"
                    >{{ route.title }}</v-list-item
                  >
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
// import { permissions } from '../../api/permissions';
import _ from 'lodash';
export default {
  name: 'EXTrucks',
  props: {
    exTrucks: {
      type: Array,
      required: true,
    },
    searchText: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      actionItems: [],
      selectedTrucks: [],
      headers: [
        { text: 'Truck # / Customer', value: 'name' },
        { text: 'Truck Type', value: 'truck_type' },
        { text: 'HwType', value: 'HwType' },
        { text: 'ApuPresent', value: 'ApuPresent' },
        { text: 'VIN', value: 'vin' },
        // { text: 'Current Configuration', value: 'config_name' },
        { text: 'mcom algo version', value: 'mcom_algo_version' },
        { text: 'mcom gps version', value: 'mcom_gps_version' },
        { text: 'mcom config version', value: 'mcom_config_version' },
        { text: 'keyscalar version', value: 'keyscalar_version' },
        { text: 'python azure version', value: 'python_azure_version' },
        { text: 'embedded iot version', value: 'embedded_iot_version' },
        { text: 'gmqtt version', value: 'gmqtt_version' },
        { text: 'python version', value: 'python_version' },
        { text: 'dtc iot diag version', value: 'dtc_iot_diag_version' },
        {
          text: '',
          value: 'actions',
          sortable: false,
          align: 'right',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      userRoles: 'getUserRoles',
    }),
    // Temp: Hide
    // userCanAssignTrucks() {
    //   return true; // <- temp this.userRoles && this.userRoles.includes(permissions.write); // permissions.assign
    // },
  },
  watch: {
    // Temp: Hide
    // selectedTrucks(data) {
    //   this.$emit('emitEditTrucks', data);
    // },
  },
  async created() {
    this.userRoles.map((role) => {
      if (role === 'route.health') {
        this.actionItems.push({ title: 'Truck Details' });
      } else if (role === 'route.trip-reports') {
        this.actionItems.push({ title: 'Trip Details' });
      } else if (role === 'route.map') {
        this.actionItems.push({ title: 'Map View' });
      } else if (role === 'admin') {
        this.actionItems.push({ title: 'Uninstall' });
      }
    });
  },
  methods: {
    ...mapActions(['asyncAssignTruck']),
    setRoute: async function (truck, route) {
      if (route === 'Truck Details') {
        await this.asyncAssignTruck({ ...truck });
        this.$router.push(`health/${truck.id}`);
      } else if (route === 'Map View') {
        this.$router.push(`health/${truck.id}/map`);
      } else if (route === 'Uninstall') {
        this.$emit('openUninstallDialog', truck.id);
      } else if (route === 'Trip Details') {
        await this.asyncAssignTruck({ ...truck });
        this.$router.push(`trips/${truck.id}`);
      }
    },
    // matchConfigurations(config) {
    //   // currently using lodash for object comparison. Might be ideal, but will do more testing to ensure this is the right way
    //   if (
    //     _.isEqual(config.assigned_configuration, config.actual_configuration)
    //   ) {
    //     return 'primary';
    //   } else {
    //     return 'red';
    //   }
    // },
  },
};
</script>
<style scoped></style>
