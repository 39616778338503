var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "truck" } },
    [
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-breadcrumbs", {
                    staticStyle: { padding: "5px 0 0 5px" },
                    attrs: { items: _vm.addCrumb, divider: "/" },
                  }),
                ],
                1
              ),
              _c("v-col", [_c("v-spacer")], 1),
            ],
            1
          ),
          _c(
            "v-row",
            { staticStyle: { "margin-top": "-30px" } },
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-tabs",
                    {
                      attrs: { "background-color": "secondary" },
                      model: {
                        value: _vm.tab,
                        callback: function ($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab",
                      },
                    },
                    _vm._l(_vm.tabItems, function (item) {
                      return _c("v-tab", { key: item }, [
                        _vm._v(" " + _vm._s(item) + " "),
                      ])
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  !_vm.truckData
                    ? _c("v-data-table", { attrs: { loading: true } })
                    : _vm.tab === 0 && _vm.truckData.truck_type.includes("EX")
                    ? _c("DTCSummary", {
                        attrs: {
                          truck: _vm.truck,
                          "view-dtc-history": _vm.viewDtcHistory,
                        },
                      })
                    : _vm.tab === 0 && _vm.truckData.truck_type.includes("ERX")
                    ? _c("ErxDtcSummary")
                    : _vm._e(),
                  _vm.tab === 1 && _vm.truckData.truck_type.includes("EX")
                    ? _c("DTCHistory", {
                        attrs: {
                          truck: _vm.truck,
                          "current-dtc": _vm.currentDtc,
                        },
                      })
                    : _vm.tab === 1 && _vm.truckData.truck_type.includes("ERX")
                    ? _c("ErxDtcHistory", {
                        attrs: {
                          truck: _vm.truck,
                          "current-dtc": _vm.currentDtc,
                        },
                      })
                    : _vm.tab === 2 && this.truckData.truck_type.includes("ERX")
                    ? _c("DTCDocumentation")
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }