var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-banner",
    {
      attrs: { id: "lv_banner", rounded: "", "single-line": "" },
      model: {
        value: _vm.active,
        callback: function ($$v) {
          _vm.active = $$v
        },
        expression: "active",
      },
    },
    [
      _c("v-icon", { staticClass: "pa-1 banner_icon" }, [
        _vm._v("mdi-eye-check-outline"),
      ]),
      _c("span", { staticClass: "pr-6 pl-2 py-2 mt-1 text-subtitle-2" }, [
        _vm._v(" You're now viewing the system as "),
        _c("b", [_vm._v(_vm._s(_vm.fleetInfo.name))]),
        _vm._v(" in Live Fleet View."),
      ]),
      _c(
        "v-btn",
        {
          staticStyle: { color: "#1a1a1a" },
          attrs: { color: "primary" },
          on: { click: _vm.closeLiveView },
        },
        [_vm._v(" Close View ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }