var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mx-3", attrs: { id: "service" } },
    [
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { staticClass: "pb-0" },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-breadcrumbs", {
                    staticStyle: { padding: "5px 0 0 5px" },
                    attrs: { items: _vm.addCrumb, divider: "/" },
                  }),
                ],
                1
              ),
              _c("v-col", [_c("v-spacer")], 1),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mt-0 pb-1" },
            [
              _c("v-col", [
                _c("h1", { staticStyle: { "font-weight": "400" } }, [
                  _vm._v(_vm._s(_vm.truckData.number)),
                ]),
              ]),
              _c(
                "v-col",
                { attrs: { align: "right" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticStyle: {
                        "margin-right": "0.7rem",
                        "margin-left": "0.5rem",
                        "background-color": "#1a1a1a",
                        "border-radius": "5px",
                      },
                      attrs: { icon: "", tile: "", large: "" },
                      on: {
                        click: function ($event) {
                          _vm.drawer = true
                        },
                      },
                    },
                    [
                      _c("v-icon", { staticStyle: { opacity: "0.6" } }, [
                        _vm._v("mdi-cog"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mt-0" },
            [
              _c(
                "v-col",
                { attrs: { cols: "8", md: "12", sm: "12" } },
                [
                  _c(
                    "v-tabs",
                    {
                      attrs: { "background-color": "secondary" },
                      model: {
                        value: _vm.tab,
                        callback: function ($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab",
                      },
                    },
                    _vm._l(_vm.tabItems, function (item) {
                      return _c("v-tab", { key: item.id }, [
                        _vm._v(" " + _vm._s(item.name) + " "),
                      ])
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.tabItems[_vm.tab] === "Operations" &&
              _vm.truckData &&
              _vm.truckData.truck_type?.includes("EX")
                ? _c(
                    "v-col",
                    { attrs: { md: "12", lg: "3", align: "right" } },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.viewableOperationGraphs,
                          label: "View Operations Charts",
                          multiple: "",
                          chips: "",
                          height: "68px",
                          outlined: "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "prepend-item",
                              fn: function () {
                                return [
                                  _c(
                                    "v-list-item",
                                    {
                                      attrs: { ripple: "" },
                                      on: {
                                        mousedown: function ($event) {
                                          $event.preventDefault()
                                        },
                                        click: _vm.toggleSelectAll,
                                      },
                                    },
                                    [
                                      _c(
                                        "v-list-item-action",
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                color: _vm.allSelected
                                                  ? "primary"
                                                  : "",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(_vm.icon) + " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c("v-list-item-title", [
                                            _vm._v(" Select All "),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("v-divider", { staticClass: "mt-2" }),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "selection",
                              fn: function ({ item }) {
                                return [
                                  _c(
                                    "v-chip",
                                    {
                                      attrs: { readonly: "" },
                                      on: {
                                        click: [
                                          function ($event) {
                                            return _vm.chipClicked(item)
                                          },
                                          function ($event) {
                                            $event.stopPropagation()
                                          },
                                        ],
                                      },
                                    },
                                    [_vm._v(_vm._s(item))]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2321690101
                        ),
                        model: {
                          value: _vm.operationGraphsSelected,
                          callback: function ($$v) {
                            _vm.operationGraphsSelected = $$v
                          },
                          expression: "operationGraphsSelected",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.tabItems[_vm.tab].id === -1 &&
              _vm.truckData &&
              _vm.truckData.truck_type?.includes("ERX")
                ? _c(
                    "v-col",
                    {
                      staticStyle: { "margin-top": "-10px" },
                      attrs: { md: "12", lg: "3", align: "right" },
                    },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.erxViewableOperationGraphs,
                          label: "View Operations Charts",
                          multiple: "",
                          chips: "",
                          height: "68px",
                          outlined: "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "prepend-item",
                              fn: function () {
                                return [
                                  _c(
                                    "v-list-item",
                                    {
                                      attrs: { ripple: "" },
                                      on: {
                                        mousedown: function ($event) {
                                          $event.preventDefault()
                                        },
                                        click: _vm.erxToggleSelectAll,
                                      },
                                    },
                                    [
                                      _c(
                                        "v-list-item-action",
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                color: _vm.erxAllSelected
                                                  ? "primary"
                                                  : "",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(_vm.erxIcon) + " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item-content",
                                        [
                                          _c("v-list-item-title", [
                                            _vm._v(" Select All "),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("v-divider", { staticClass: "mt-2" }),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "selection",
                              fn: function ({ item }) {
                                return [
                                  _c(
                                    "v-chip",
                                    {
                                      attrs: { readonly: "" },
                                      on: {
                                        click: [
                                          function ($event) {
                                            return _vm.chipClicked(item)
                                          },
                                          function ($event) {
                                            $event.stopPropagation()
                                          },
                                        ],
                                      },
                                    },
                                    [_vm._v(_vm._s(item))]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          920498714
                        ),
                        model: {
                          value: _vm.erxOperationGraphsSelected,
                          callback: function ($$v) {
                            _vm.erxOperationGraphsSelected = $$v
                          },
                          expression: "erxOperationGraphsSelected",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "pr-0" },
                [
                  _c(
                    "v-tabs-items",
                    {
                      model: {
                        value: _vm.tab,
                        callback: function ($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab",
                      },
                    },
                    [
                      _vm.truckData.truck_type?.includes("EX")
                        ? _c(
                            "v-tab-item",
                            [
                              _c("EXOperations", {
                                attrs: {
                                  truck: _vm.truck,
                                  graphs: _vm.operationGraphsSelected,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.truckData.truck_type?.includes("ERX")
                        ? _c(
                            "v-tab-item",
                            [
                              _c("TripGraphERX", {
                                attrs: {
                                  graphs: new Set(
                                    _vm.erxOperationGraphsSelected
                                  ),
                                  loading: _vm.erxGraphLoading,
                                  data: _vm.erxGraphData,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.truckData.vin
                        ? _c(
                            "v-tab-item",
                            [
                              _c("WorkOrders", {
                                attrs: { vin: _vm.truckData.vin },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.truckData.truck_type?.includes("ERX") &&
                      _vm.truckData.vin
                        ? _c(
                            "v-tab-item",
                            [
                              _c("Cases", {
                                attrs: { vin: _vm.truckData.vin },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._l(_vm.templates, function (template) {
                        return _c(
                          "v-tab-item",
                          { key: template.id },
                          [
                            _vm.truckData.truck_type?.includes("ERX")
                              ? _c("Study", {
                                  attrs: {
                                    template: template,
                                    "truck-date": _vm.truckDate,
                                    "in-view":
                                      template === _vm.templates[_vm.tab - 3],
                                    "truck-names": _vm.truckNames,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-navigation-drawer",
        {
          staticClass: "shade",
          attrs: { right: "", temporary: "", fixed: "", width: "360" },
          model: {
            value: _vm.drawer,
            callback: function ($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer",
          },
        },
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                { attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "mb-2", attrs: { align: "right" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function ($event) {
                              _vm.drawer = false
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { light: "" } }, [
                            _vm._v("mdi-close-circle "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-expansion-panels",
                {
                  attrs: { multiple: "" },
                  model: {
                    value: _vm.panel,
                    callback: function ($$v) {
                      _vm.panel = $$v
                    },
                    expression: "panel",
                  },
                },
                [
                  _c(
                    "v-expansion-panel",
                    { staticClass: "shade" },
                    [
                      _c("v-expansion-panel-header", [
                        _vm._v("Date Selection"),
                      ]),
                      _c(
                        "v-expansion-panel-content",
                        [
                          _c("date-input", {
                            attrs: {
                              label: "Start Date",
                              model: _vm.studyStart,
                              "send-date": (d) => (_vm.studyStart = d),
                            },
                          }),
                          _c("date-input", {
                            attrs: {
                              label: "End Date",
                              model: _vm.studyEnd,
                              "send-date": (d) => (_vm.studyEnd = d),
                            },
                          }),
                          _c("br"),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "block",
                                "margin-left": "auto",
                              },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticStyle: { "margin-right": "15px" },
                                  attrs: { disabled: _vm.updateDisabled },
                                  on: { click: _vm.cancelSelection },
                                },
                                [_vm._v("Cancel")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    disabled: _vm.updateDisabled,
                                  },
                                  on: { click: _vm.updateTruckDate },
                                },
                                [_vm._v("Update")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }