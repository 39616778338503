<template>
  <v-card flat>
    <loader v-if="loading" />
    <v-dialog
      v-if="openDialog"
      v-model="openDialog"
      width="550"
      content-class="alertdialog"
    >
      <alert
        v-if="showAlert"
        :alert-type="alertType"
        :alert-message="alertMessage"
      />
      <v-card>
        <v-card-title class=""> Edit DTC </v-card-title>
        <v-card-text> Edit an DTC Event </v-card-text>
        <v-divider></v-divider>
        <v-form ref="form" v-model="valid">
          <v-col class="pl-10"
            >Priority:
            <v-radio-group v-model="dtcData.priority" row>
              <v-radio label="None" value="0"></v-radio>
              <v-radio label="Low" value="1"></v-radio>
              <v-radio label="Med" value="2"></v-radio>
              <v-radio label="High" value="3"></v-radio>
            </v-radio-group>
          </v-col>
          <v-row class="input-row" justify="center">
            <v-text-field
              v-model="dtcData.component"
              placeholder="Component"
              :rules="requiredRule"
              label="Component"
              outlined
              color="primary"
            ></v-text-field>
          </v-row>
          <v-row class="input-row" justify="center">
            <v-text-field
              v-model="dtcData.created_at"
              placeholder="Created At"
              label="Created At"
              outlined
              color="primary"
            ></v-text-field>
          </v-row>
          <v-row class="input-row" justify="center">
            <v-text-field
              v-model="dtcData.diagnostic_type"
              placeholder="Diagnostic Type"
              label="Diagnostic Type"
              outlined
              color="primary"
            ></v-text-field>
          </v-row>
          <v-row class="input-row" justify="center">
            <v-text-field
              v-model="dtcData.diagnostic_unit"
              placeholder="Diagnostic Unit"
              label="Diagnostic Unit"
              outlined
              color="primary"
            ></v-text-field>
          </v-row>
          <v-row class="input-row" justify="center">
            <v-text-field
              v-model="dtcData.dtc_hex"
              disabled
              placeholder="DTC Hex"
              label="DTC Hex"
              outlined
              color="primary"
            ></v-text-field>
          </v-row>
          <v-row class="input-row" justify="center">
            <v-text-field
              v-model="dtcData.ecu"
              placeholder="Name"
              label="Name"
              outlined
              color="primary"
            ></v-text-field>
          </v-row>
          <v-row class="input-row" justify="center">
            <v-text-field
              v-model="dtcData.fail_count_limit"
              placeholder="Fail Count Limit"
              label="Fail Count Limit"
              outlined
              color="primary"
            ></v-text-field>
          </v-row>
          <v-row class="input-row" justify="center">
            <v-text-field
              v-model="dtcData.fail_timers"
              placeholder="Fail Timers"
              label="Fail Timers"
              outlined
              color="primary"
            ></v-text-field>
          </v-row>
          <v-row class="input-row" justify="center">
            <v-text-field
              v-model="dtcData.failure_mode"
              placeholder="Failure Mode"
              label="Failure Mode"
              outlined
              color="primary"
            ></v-text-field>
          </v-row>
          <v-row class="input-row" justify="center">
            <v-text-field
              v-model="dtcData.hmi_string"
              placeholder="Description"
              label="Description"
              outlined
              color="primary"
            ></v-text-field>
          </v-row>
          <v-row class="input-row" justify="center">
            <v-text-field
              v-model="dtcData.id"
              placeholder="ID"
              label="ID"
              outlined
              color="primary"
            ></v-text-field>
          </v-row>
          <v-row class="input-row" justify="center">
            <v-text-field
              v-model="dtcData.priority"
              placeholder="Priority"
              label="Priority"
              outlined
              color="primary"
            ></v-text-field>
          </v-row>
          <v-row class="input-row" justify="center">
            <v-text-field
              v-model="dtcData.proposed_driver_alert_string"
              placeholder="Proposed Driver Alert String"
              label="Proposed Driver Alert String"
              outlined
              color="primary"
            ></v-text-field>
          </v-row>
          <v-row class="input-row" justify="center">
            <v-text-field
              v-model="dtcData.threshold"
              placeholder="Threshold"
              label="Threshold"
              outlined
              color="primary"
            ></v-text-field>
          </v-row>
          <v-row class="input-row" justify="center">
            <v-text-field
              v-model="dtcData.trip_count_limit"
              placeholder="Trip Count Limit"
              label="Trip Count Limit"
              outlined
              color="primary"
            ></v-text-field>
          </v-row>
          <v-row class="input-row" justify="center">
            <v-text-field
              v-model="dtcData.updated_at"
              placeholder="Updated At"
              label="Updated At"
              outlined
              color="primary"
            ></v-text-field>
          </v-row>
          <v-card-actions>
            <v-checkbox v-model="dtcData.active" :label="'Active'"></v-checkbox>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="secondary-button"
              text
              @click="closeModal"
            >
              Cancel
            </v-btn>
            <v-btn
              color="secondary"
              class="primary-button"
              :disabled="!valid"
              text
              @click="editDtc()"
            >
              Edit DTC
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-data-table
      :headers="dataHeaders"
      :items="dtcRows"
      :items-per-page="5"
      item-key="id"
      class="elevation-1"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-menu style="top: 312px; left: 300px" offset-y>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-dots-vertical</v-icon>
          </template>
          <v-list>
            <v-list-item
              v-for="(route, index) in actionItems"
              :key="index"
              :class="route.title"
              @click="editOnClick(item, route.title)"
              >{{ route.title }}</v-list-item
            >
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { getDTCs, editDtc, deleteDtc } from '../../api/dataDictionary';

import loader from '../../utilities/loader';
import alert from '../../utilities/alert';
export default {
  name: 'DtcTable',
  components: { loader, alert },
  props: {},
  data() {
    return {
      requiredRule: [(v) => !!v || 'Required'],
      openDialog: false,
      loading: false,
      valid: false,
      showAlert: false,
      alertType: '',
      alertMessage: '',
      actionItems: [{ title: 'Edit' }, { title: 'Delete' }],
      dtcData: {
        active: true,
        component: 'GFD',
        created_at: '2022-07-15T20:19:57.533Z',
        diagnostic_type: 'CAN MESSAGE',
        diagnostic_unit: 'N/A',
        dtc_hex: '0x01030703',
        ecu: 'KONTRON (MCOM)',
        fail_count_limit: 1,
        fail_timers: '2',
        failure_mode: 'Ground failure',
        hmi_string: 'GFD - Ground failure',
        id: 351,
        priority: '1',
        proposed_driver_alert_string:
          'Hyliion Battery Fault Detected- Turn off Hyliion system and contact Hyliion Field Service Hotline',
        threshold: '',
        trip_count_limit: 1,
        updated_at: '2022-08-19T03:05:38.974Z',
      },
      dataHeaders: [
        {
          text: 'Code',
          value: 'dtc_hex',
          align: 'center',
          sortable: true,
          visible: true,
          hideable: true,
        },
        {
          text: 'Component',
          value: 'component',
          align: 'center',
          sortable: true,
          visible: true,
          hideable: true,
        },
        {
          text: 'Name',
          value: 'ecu',
          align: 'center',
          sortable: true,
          visible: true,
          hideable: true,
        },
        {
          text: 'Description',
          value: 'hmi_string',
          align: 'center',
          sortable: true,
          visible: true,
          hideable: true,
        },
        {
          text: 'Link',
          value: 'workbook_link',
          align: 'center',
          sortable: true,
          visible: true,
          hideable: true,
        },
        {
          text: '',
          value: 'actions',
          align: 'right',
        },
      ],
      dataHeadersDup: [],
      dtcRows: [],
    };
  },
  watch: {
    // Watches for the change in openDialog variable to emit the false variable back to parent to reset
    openDialog: function (newValue) {
      if (!newValue) {
        this.openDialog = false;
        this.$emit('closed', false);
      }
    },
  },
  mounted() {
    this.openDialog = false;
    this.getDtcTable();
  },
  methods: {
    editOnClick: async function (dtc, route) {
      if (route === 'Edit') {
        this.dtcData = { ...dtc };
        this.valid = true;
        this.openDialog = true;
      } else if (route === 'Delete') {
        this.deleteDtc(dtc.id);
      }
    },
    closeModal() {
      this.openDialog = false;
    },
    async getDtcTable() {
      this.loading = true;
      try {
        const { data } = await getDTCs();
        this.dtcRows = data;
        this.loading = false;
      } catch (e) {
        this.loading = false;
        this.alertTimeout(true);
      }
    },
    async deleteDtc(id) {
      try {
        this.loading = true;
        await deleteDtc(id);
        this.loading = false;
        this.openDialog = false;
        this.getDtcTable();
      } catch (e) {
        console.log(e, 'Error deleting DTC');
        this.loading = false;
        this.alertTimeout(true);
      }
    },
    async editDtc() {
      try {
        this.loading = true;
        await editDtc(this.dtcData);
        this.loading = false;
        this.openDialog = false;
        this.getDtcTable();
      } catch (e) {
        console.log(e, 'Error adding DTC');
        this.loading = false;
        this.alertTimeout(true);
      }
    },
    alertTimeout(closeDialog) {
      setTimeout(() => {
        this.showAlert = false;
        if (closeDialog) this.openDialog = false;
      }, 2000);
    },
  },
};
</script>
<style scoped>
.dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  height: auto;
  width: auto;
  z-index: 1;
  transform: translate(-50%, -50%);
}
.primary-button {
  background-color: #48ff48;
}
.primary-button:disabled {
  background-color: #757775;
}
.secondary-button {
  border-color: #48ff48;
}
.input-row {
  margin: 10px;
}
</style>
