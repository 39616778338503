<script>
// route classification pie chart
import { Pie } from 'vue-chartjs';

export default {
  name: 'ClassPie',
  extends: Pie,
  props: ['data', 'labels'],
  data() {
    return {
      options: {
        hoverOffset: 5,
        tooltips: {
          enabled: true,
          callbacks: {
            label: function (tooltipItem, data) {
              return data.labels[tooltipItem.index];
            },
            afterLabel: function (tooltipItem, data) {
              var dataset = data['datasets'][0];
              let total = 0;
              for (const i of data.datasets[0].data) total += i;
              var percent =
                Math.round(
                  (dataset['data'][tooltipItem['index']] / total) * 1000
                ) / 10;
              return (
                data.datasets[0].data[tooltipItem.index] +
                ' mi \n' +
                percent +
                '%'
              );
            },
          },
        },
      },
      fullData: {
        labels: this.labels,
        datasets: [
          {
            data: this.data,
            hoverOffset: 4,
            backgroundColor: [
              '#BB86FC',
              '#FCBB86',
              '#2196F3',
              '#FE83AE',
              '#A5C953',
            ],
            borderColor: '#1A1A1A',
          },
        ],
      },
    };
  },
  mounted() {
    this.renderChart(this.fullData, this.options);
  },
};
</script>
