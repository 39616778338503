var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "mb-1", attrs: { cols: "3" } },
            [
              _c(
                "v-btn",
                {
                  staticStyle: { color: "black", height: "48px" },
                  attrs: { color: "primary" },
                  on: { click: _vm.goBack },
                },
                [
                  _c("v-icon", { staticClass: "pr-1" }, [
                    _vm._v("mdi-arrow-u-left-top"),
                  ]),
                  _vm._v("Back"),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "mb-1", attrs: { align: "left" } },
            [
              _vm.liveView
                ? _c("LiveFleetViewBanner", {
                    attrs: { "fleet-info": _vm.selectedCompany },
                    on: {
                      closed: function ($event) {
                        _vm.liveView = false
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "mb-1", attrs: { cols: "2", align: "right" } },
            [
              _c(
                "v-btn",
                {
                  staticStyle: { color: "black", height: "48px" },
                  attrs: { color: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.journeyEdit = true
                    },
                  },
                },
                [
                  _vm._v("Edit"),
                  _c("v-icon", { staticClass: "pr-1" }, [_vm._v("mdi-pencil")]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "background-color": "#272727" } },
        [
          _c("location-card", {
            staticClass: "lCard",
            attrs: { journey: _vm.journey, "journey-name": _vm.journeyName },
          }),
          _c("HereMap", {
            staticStyle: { "margin-bottom": "50px" },
            attrs: {
              "map-height": 480,
              "zoom-location": "bottom-right",
              "show-traffic": false,
              set: (m) => (_vm.hereMap = m),
            },
          }),
          _c("vehicle-statistics", {
            staticStyle: {
              position: "absolute",
              "z-index": "1",
              top: "70px",
              left: "56px",
            },
            attrs: {
              data: _vm.vehicleStats,
              "truck-name": _vm.truckName,
              "name-edit": _vm.userCanUpdateTruckName,
            },
            on: {
              openNameChange: function ($event) {
                _vm.changeTruckNameDialog = true
              },
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "pieContainer mb-3" },
        [
          _vm.driveEventsPie
            ? _c("pie-card", {
                attrs: {
                  title: "Drive Events",
                  "num-cols": 2,
                  data: _vm.driveEventsPie,
                },
              })
            : _vm._e(),
          _vm.driveModesPie
            ? _c("pie-card", {
                attrs: { title: "Drive Modes", data: _vm.driveModesPie },
              })
            : _vm._e(),
          _vm.driveControlsPie
            ? _c("pie-card", {
                attrs: { title: "eRetarder", data: _vm.driveControlsPie },
              })
            : _vm._e(),
        ],
        1
      ),
      _c("br"),
      _c("br"),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "v-card",
                { attrs: { color: "#272727" } },
                [
                  _c(
                    "v-row",
                    { staticClass: "cust-border mx-0" },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "ml-4",
                          attrs: { cols: "12", align: "left" },
                        },
                        [_vm._v(" Journey Timeline ")]
                      ),
                    ],
                    1
                  ),
                  _c("Gantt", {
                    attrs: {
                      title: "",
                      "journey-start": _vm.journeyData.data.start,
                      "journey-end": _vm.journeyData.data.end,
                      categories: _vm.timelineCategories,
                      data: _vm.timelineData,
                      "tz-offset": _vm.tzOffset,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.changeTruckNameDialog
        ? _c("TruckNameChange", {
            attrs: { "company-id": _vm.companyId, "truck-id": _vm.truckId },
            on: { close: _vm.close },
          })
        : _vm._e(),
      _c(
        "v-dialog",
        {
          staticClass: "dialog",
          attrs: { width: "460" },
          model: {
            value: _vm.journeyEdit,
            callback: function ($$v) {
              _vm.journeyEdit = $$v
            },
            expression: "journeyEdit",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    { attrs: { align: "center" } },
                    [
                      _c("v-col", { attrs: { align: "left", cols: "6" } }, [
                        _c("h3", [_vm._v("Journey Name")]),
                      ]),
                      _c("v-col", [_c("v-spacer")], 1),
                      _c(
                        "v-col",
                        {
                          staticClass: "pr-1",
                          attrs: { align: "right", cols: "3" },
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.closeJourneyEdit()
                                },
                              },
                            },
                            [
                              _c("v-icon", { attrs: { light: "" } }, [
                                _vm._v("mdi-close-circle "),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "py-0",
                          attrs: { align: "center", cols: "12" },
                        },
                        [
                          _c("v-text-field", {
                            attrs: { outlined: "", label: "Name" },
                            model: {
                              value: _vm.journeyName,
                              callback: function ($$v) {
                                _vm.journeyName = $$v
                              },
                              expression: "journeyName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-0",
                          attrs: { align: "center", cols: "12" },
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticStyle: { color: "black", width: "100%" },
                              attrs: {
                                color: "primary",
                                disabled: !_vm.journeyName,
                              },
                              on: { click: _vm.changeJourneyName },
                            },
                            [_vm._v("Save Change")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }