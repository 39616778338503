<!-- Global Icon component -->
<template>
  <v-img
    :src="require(`../../public/images/icons/dtc_indicators/${iconShow}.png`)"
    max-width="50px"
    max-height="42px"
    contain
    v-on="on"
  ></v-img>
</template>

<script>
// These are some of the things needed to be passed in, leaving this here for now
// For future work on the array in the summary
// 'high_voltage_amber',
// 'high_voltage_red',
// 'thermal_systems_amber',
// 'thermal_systems_red',
// 'low_voltage_amber',
// 'low_voltage_red',
// 'genset_amber',
// 'genset_red',
// 'power_steering_amber',
// 'power_steering_red',
// 'charge_amber',
// 'charge_red',
// 'hvac_amber',
// 'hvac_red',
// 'brake_compressor_amber',
// 'brake_compressor_red',
// 'e_axles_amber',
// 'e_axles_red',
// 'hyliion_controller_amber',
// 'stop_vehicle_red',
// 'limited_performance_amber',
// 'rbs_amber',
// 'hyliion_controller_red',
export default {
  name: 'IconLoader',
  props: {
    iconShow: {
      type: String,
      required: true,
    },
    on: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    showIcon: [],
    color: 'grey',
  }),
  created() {
    this.showIcon = [];
  },
};
</script>
<style scoped></style>
