var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "800", persistent: "" },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v(" " + _vm._s(_vm.cardTitle))]),
          _c("v-card-text", [_vm._v("Enter Graph parameters below")]),
          _c("v-divider"),
          _c(
            "v-form",
            {
              ref: "form",
              model: {
                value: _vm.formValid,
                callback: function ($$v) {
                  _vm.formValid = $$v
                },
                expression: "formValid",
              },
            },
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "formLabel", attrs: { sm: "2" } },
                        [_vm._v("Name")]
                      ),
                      _c(
                        "v-col",
                        { attrs: { sm: "10" } },
                        [
                          _c("v-text-field", {
                            staticClass: "formInput",
                            attrs: {
                              outlined: "",
                              label: "Name",
                              rules: _vm.rules.required,
                              required: "",
                            },
                            model: {
                              value: _vm.name,
                              callback: function ($$v) {
                                _vm.name = $$v
                              },
                              expression: "name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "formLabel", attrs: { sm: "2" } },
                        [_vm._v(" Description: ")]
                      ),
                      _c(
                        "v-col",
                        [
                          _c("v-textarea", {
                            staticClass: "formInput",
                            attrs: {
                              outlined: "",
                              label: "Description",
                              required: "",
                            },
                            model: {
                              value: _vm.description,
                              callback: function ($$v) {
                                _vm.description = $$v
                              },
                              expression: "description",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "formLabel", attrs: { sm: "2" } },
                        [_vm._v(" Graph Type: ")]
                      ),
                      _c(
                        "v-col",
                        { attrs: { sm: "10" } },
                        [
                          _c("v-select", {
                            staticClass: "formInput",
                            attrs: {
                              items: _vm.graphTypes,
                              outlined: "",
                              label: "Graph Type",
                              rules: _vm.rules.required,
                              required: "",
                            },
                            model: {
                              value: _vm.selectedGraph,
                              callback: function ($$v) {
                                _vm.selectedGraph = $$v
                              },
                              expression: "selectedGraph",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.selectedGraph === "Candlestick" ||
                  _vm.selectedGraph === "Jitter"
                    ? _c(
                        "div",
                        { staticClass: "candlestickForm" },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "formLabel",
                                  attrs: { sm: "2" },
                                },
                                [_vm._v("Signal:")]
                              ),
                              _c(
                                "v-col",
                                { attrs: { sm: "10" } },
                                [
                                  _c("v-combobox", {
                                    staticClass: "formInput",
                                    attrs: {
                                      outlined: "",
                                      items: _vm.signals,
                                      "return-object": "",
                                      "item-text": "signal",
                                      label: "Signal",
                                      "error-messages": _vm.signalMessage,
                                      rules: _vm.rules.required,
                                    },
                                    model: {
                                      value: _vm.selectedSignal,
                                      callback: function ($$v) {
                                        _vm.selectedSignal = $$v
                                      },
                                      expression: "selectedSignal",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "formLabel",
                                  attrs: { sm: "2" },
                                },
                                [_vm._v(" Start Trigger: ")]
                              ),
                              _c(
                                "v-col",
                                { attrs: { sm: "10" } },
                                [
                                  _c("v-select", {
                                    staticClass: "formInput",
                                    attrs: {
                                      "return-object": "",
                                      items: _vm.triggers,
                                      "item-text": "name",
                                      outlined: "",
                                      label: "Start",
                                      rules: _vm.rules.required,
                                      required: "",
                                    },
                                    model: {
                                      value: _vm.selectedStart,
                                      callback: function ($$v) {
                                        _vm.selectedStart = $$v
                                      },
                                      expression: "selectedStart",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "formLabel",
                                  attrs: { sm: "2" },
                                },
                                [_vm._v(" End Trigger: ")]
                              ),
                              _c(
                                "v-col",
                                { attrs: { sm: "10" } },
                                [
                                  _c("v-select", {
                                    staticClass: "formInput",
                                    attrs: {
                                      "return-object": "",
                                      items: _vm.triggers,
                                      "item-text": "name",
                                      "item-value": "id",
                                      outlined: "",
                                      label: "End",
                                      rules: _vm.rules.required,
                                      required: "",
                                    },
                                    model: {
                                      value: _vm.selectedEnd,
                                      callback: function ($$v) {
                                        _vm.selectedEnd = $$v
                                      },
                                      expression: "selectedEnd",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.selectedEnd === "Duration"
                            ? _c(
                                "v-row",
                                { attrs: { "align-self": "start" } },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "formLabel",
                                      attrs: { sm: "2" },
                                    },
                                    [_vm._v(" Seconds: ")]
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { sm: "3" } },
                                    [
                                      _c("v-text-field", {
                                        staticClass: "formInput",
                                        attrs: {
                                          outlined: "",
                                          type: "number",
                                          label: "Seconds",
                                          rules: _vm.rules.required,
                                          required: "",
                                        },
                                        model: {
                                          value: _vm.seconds,
                                          callback: function ($$v) {
                                            _vm.seconds = $$v
                                          },
                                          expression: "seconds",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.selectedGraph === "Jitter (ERX only)"
                            ? _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "formLabel",
                                      attrs: { sm: "2" },
                                    },
                                    [_vm._v(" Signal Plotted: ")]
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { sm: "10" } },
                                    [
                                      _c(
                                        "v-radio-group",
                                        {
                                          model: {
                                            value: _vm.jitterSignal,
                                            callback: function ($$v) {
                                              _vm.jitterSignal = $$v
                                            },
                                            expression: "jitterSignal",
                                          },
                                        },
                                        [
                                          _c("v-radio", {
                                            attrs: {
                                              label: "Max",
                                              value: "max",
                                            },
                                          }),
                                          _c("v-radio", {
                                            attrs: {
                                              label: "Min",
                                              value: "min",
                                            },
                                          }),
                                          _c("v-radio", {
                                            attrs: {
                                              label: "Max & Min",
                                              value: "maxmin",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.selectedGraph === "Absolute Time Line Graph"
                    ? _c(
                        "div",
                        { staticClass: "candlestickForm" },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "formLabel",
                                  attrs: { sm: "2" },
                                },
                                [_vm._v("Signals:")]
                              ),
                              _c(
                                "v-col",
                                { attrs: { sm: "10" } },
                                [
                                  _c("v-combobox", {
                                    staticClass: "formInput",
                                    attrs: {
                                      outlined: "",
                                      multiple: "",
                                      items: _vm.signals,
                                      "return-object": "",
                                      "item-text": "signal",
                                      label: "Choose one or more signals",
                                      rules: _vm.rules.signalRequired,
                                      required: "",
                                    },
                                    model: {
                                      value: _vm.selectedSignals,
                                      callback: function ($$v) {
                                        _vm.selectedSignals = $$v
                                      },
                                      expression: "selectedSignals",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", text: "" },
                      on: { click: _vm.close },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", disabled: !_vm.formValid },
                      on: { click: _vm.addGraph },
                    },
                    [_vm._v(" " + _vm._s(_vm.submitText) + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }