var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loading
        ? _c("v-progress-circular", {
            staticClass: "center",
            attrs: { indeterminate: "", color: "primary" },
          })
        : _vm._e(),
      _vm.chartsReady
        ? _c(
            "div",
            [
              _vm.graphs.has("Usage")
                ? _c("highcharts", {
                    ref: "chart1",
                    staticClass: "graph",
                    attrs: { id: "chart1", options: _vm.usageTimeOptions },
                  })
                : _vm._e(),
              _vm.graphs.has("Usage")
                ? _c("highcharts", {
                    ref: "chart2",
                    staticClass: "graph",
                    attrs: { options: _vm.distanceOptions },
                  })
                : _vm._e(),
              _vm.graphs.has("Usage")
                ? _c("highcharts", {
                    ref: "chart3",
                    staticClass: "graph",
                    attrs: { options: _vm.fuelTankOptions },
                  })
                : _vm._e(),
              _vm.graphs.has("Usage")
                ? _c("highcharts", {
                    ref: "chart4",
                    staticClass: "graph",
                    attrs: { options: _vm.fuelUsageOptions },
                  })
                : _vm._e(),
              _vm.graphs.has("Usage")
                ? _c("highcharts", {
                    ref: "chart5",
                    staticClass: "graph",
                    attrs: { options: _vm.temperatureOptions },
                  })
                : _vm._e(),
              _vm.graphs.has("Usage")
                ? _c("highcharts", {
                    ref: "chart6",
                    staticClass: "graph",
                    attrs: { options: _vm.weightOptions },
                  })
                : _vm._e(),
              _vm.graphs.has("Usage")
                ? _c("highcharts", {
                    ref: "chart7",
                    staticClass: "graph",
                    attrs: { options: _vm.accelOptions },
                  })
                : _vm._e(),
              _vm.graphs.has("Cruise Control")
                ? _c("highcharts", {
                    ref: "chart8",
                    staticClass: "graph",
                    attrs: { id: "chart8", options: _vm.ccTimeOptions },
                  })
                : _vm._e(),
              _vm.graphs.has("Cruise Control")
                ? _c("highcharts", {
                    ref: "chart9",
                    staticClass: "graph",
                    attrs: { options: _vm.ccSpeedOptions },
                  })
                : _vm._e(),
              _vm.graphs.has("Cruise Control")
                ? _c("highcharts", {
                    ref: "chart10",
                    staticClass: "graph",
                    attrs: { options: _vm.ccUsageOptions },
                  })
                : _vm._e(),
              _vm.graphs.has("BMS")
                ? _c("highcharts", {
                    ref: "chart11",
                    staticClass: "graph",
                    attrs: { id: "chart11", options: _vm.socOptions },
                  })
                : _vm._e(),
              _vm.graphs.has("BMS")
                ? _c("highcharts", {
                    ref: "chart12",
                    staticClass: "graph",
                    attrs: { options: _vm.vcOptions },
                  })
                : _vm._e(),
              _vm.graphs.has("BMS")
                ? _c("highcharts", {
                    ref: "chart13",
                    staticClass: "graph",
                    attrs: { options: _vm.bmsTempOptions },
                  })
                : _vm._e(),
              _vm.graphs.has("Axle")
                ? _c("highcharts", {
                    ref: "chart14",
                    staticClass: "graph",
                    attrs: { id: "chart14", options: _vm.axTempOptions },
                  })
                : _vm._e(),
              _vm.graphs.has("Axle")
                ? _c("highcharts", {
                    ref: "chart15",
                    staticClass: "graph",
                    attrs: { options: _vm.axVoltOptions },
                  })
                : _vm._e(),
              _vm.graphs.has("Axle")
                ? _c("highcharts", {
                    ref: "chart16",
                    staticClass: "graph",
                    attrs: { options: _vm.axCurrentOptions },
                  })
                : _vm._e(),
              _vm.graphs.has("Retarder")
                ? _c("highcharts", {
                    ref: "chart17",
                    staticClass: "graph",
                    attrs: { id: "chart17", options: _vm.rtTimeOptions },
                  })
                : _vm._e(),
              _vm.graphs.has("Retarder")
                ? _c("highcharts", {
                    ref: "chart18",
                    staticClass: "graph",
                    attrs: { options: _vm.rtDistanceOptions },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }