var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "issuesTable" } },
    [
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "pa-0", attrs: { cols: "12" } },
                [
                  _c("v-data-table", {
                    key: _vm.tableKey,
                    staticClass:
                      "elevation-1 secondary locked-table-style row-height-50 row-pointer",
                    attrs: {
                      "single-select": "",
                      headers: _vm.issuesHeaders,
                      items: _vm.issues,
                      "item-key": "id",
                    },
                    on: { "click:row": _vm.loadUpdateIssue },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }