var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { class: !_vm.dashboardActive && "pa-0", attrs: { fluid: "" } },
    [
      _c(
        "v-navigation-drawer",
        {
          staticClass: "shade",
          attrs: { right: "", temporary: "", fixed: "", width: 360 },
          model: {
            value: _vm.drawer,
            callback: function ($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer",
          },
        },
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                { attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "mb-2", attrs: { align: "right" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: { click: _vm.closeSideDrawer },
                        },
                        [
                          _c("v-icon", { attrs: { light: "" } }, [
                            _vm._v("mdi-close-circle "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-expansion-panels",
                {
                  attrs: { multiple: "" },
                  model: {
                    value: _vm.panel,
                    callback: function ($$v) {
                      _vm.panel = $$v
                    },
                    expression: "panel",
                  },
                },
                [
                  _c(
                    "v-expansion-panel",
                    { staticClass: "shade" },
                    [
                      _c("v-expansion-panel-header", [
                        _vm._v("Display Settings"),
                      ]),
                      _c(
                        "v-expansion-panel-content",
                        [
                          _c("p", [_vm._v("Fueling Stations")]),
                          _c("v-checkbox", {
                            attrs: {
                              label: "CNG stations",
                              value: "cng",
                              color: "primary",
                              disabled: _vm.playbackMode,
                            },
                            model: {
                              value: _vm.selectedStations,
                              callback: function ($$v) {
                                _vm.selectedStations = $$v
                              },
                              expression: "selectedStations",
                            },
                          }),
                          _c("p", [_vm._v("Icon Size")]),
                          _c(
                            "v-radio-group",
                            {
                              attrs: { disabled: _vm.playbackMode },
                              model: {
                                value: _vm.iconSize,
                                callback: function ($$v) {
                                  _vm.iconSize = $$v
                                },
                                expression: "iconSize",
                              },
                            },
                            [
                              _c("v-radio", {
                                attrs: { label: "Small", value: "small" },
                              }),
                              _c("v-radio", {
                                attrs: { label: "Large", value: "large" },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c("v-col", [
                                _vm._v(" Marker Clustering"),
                                _c("br"),
                                _c("span", { staticClass: "description" }, [
                                  _vm._v(
                                    " The number on a cluster indicates how many markers it contains. "
                                  ),
                                ]),
                              ]),
                              _c(
                                "v-col",
                                { attrs: { cols: "2" } },
                                [
                                  _c("v-switch", {
                                    attrs: { disabled: _vm.playbackMode },
                                    model: {
                                      value: _vm.clustering,
                                      callback: function ($$v) {
                                        _vm.clustering = $$v
                                      },
                                      expression: "clustering",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c("v-col", [
                                _vm._v(" Truck Timelapse"),
                                _c("br"),
                                _c("span", { staticClass: "description" }, [
                                  _vm._v(
                                    " View routes and movement of trucks on a selected day. "
                                  ),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c("br"),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "v-menu",
                                    {
                                      attrs: {
                                        "close-on-content-click": false,
                                        "nudge-right": 40,
                                        transition: "scale-transition",
                                        "offset-y": "",
                                        "min-width": "auto",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "v-text-field",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        label: "Select Date",
                                                        "append-icon":
                                                          "mdi-calendar",
                                                        outlined: "",
                                                        readonly: "",
                                                        value: _vm.formatDate,
                                                      },
                                                    },
                                                    "v-text-field",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                )
                                              ),
                                            ]
                                          },
                                        },
                                      ]),
                                      model: {
                                        value: _vm.menu2,
                                        callback: function ($$v) {
                                          _vm.menu2 = $$v
                                        },
                                        expression: "menu2",
                                      },
                                    },
                                    [
                                      _c("v-date-picker", {
                                        attrs: { color: "primary" },
                                        on: {
                                          input: function ($event) {
                                            _vm.menu2 = false
                                            _vm.changedManually =
                                              !_vm.changedManually
                                          },
                                        },
                                        model: {
                                          value: _vm.date,
                                          callback: function ($$v) {
                                            _vm.date = $$v
                                          },
                                          expression: "date",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  !_vm.validDate
                                    ? _c(
                                        "div",
                                        { attrs: { id: "dateWarning" } },
                                        [_vm._v(" Please select a valid date ")]
                                      )
                                    : _vm._e(),
                                  _vm.errorMessage.length != 0
                                    ? _c("div", { class: _vm.errorStatus }, [
                                        _vm._v(
                                          " " + _vm._s(_vm.errorMessage) + " "
                                        ),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { staticStyle: { "margin-top": "-30px" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "1" } },
                                [
                                  !_vm.positionsLoaded && _vm.validDate
                                    ? _c("v-progress-circular", {
                                        attrs: {
                                          size: 22,
                                          indeterminate: "",
                                          color: "primary",
                                        },
                                      })
                                    : !_vm.playing
                                    ? _c(
                                        "v-icon",
                                        {
                                          staticClass: "iconButton",
                                          attrs: {
                                            color: "primary",
                                            disabled: _vm.playDisabled,
                                          },
                                          on: { click: _vm.playPressed },
                                        },
                                        [_vm._v("mdi-play")]
                                      )
                                    : _vm.playing
                                    ? _c(
                                        "v-icon",
                                        {
                                          staticClass: "iconButton",
                                          attrs: { color: "primary" },
                                          on: { click: _vm.pausePlayback },
                                        },
                                        [_vm._v("mdi-pause")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                [
                                  _c("v-slider", {
                                    staticStyle: { "margin-left": "5px" },
                                    attrs: {
                                      max: "1440",
                                      ticks: "",
                                      step: "5",
                                    },
                                    model: {
                                      value: _vm.sliderValue,
                                      callback: function ($$v) {
                                        _vm.sliderValue = $$v
                                      },
                                      expression: "sliderValue",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-expansion-panel",
                    { staticClass: "shade" },
                    [
                      _c("v-expansion-panel-header", [
                        _vm._v("Route Planning"),
                      ]),
                      _c(
                        "v-expansion-panel-content",
                        [
                          _vm.routingAlert
                            ? _c("v-alert", { attrs: { type: "error" } }, [
                                _vm._v(" " + _vm._s(_vm.routingAlert) + " "),
                              ])
                            : _vm._e(),
                          _c(
                            "form",
                            {
                              on: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                },
                              },
                            },
                            [
                              _c("v-text-field", {
                                attrs: { label: "Start Address", outlined: "" },
                                model: {
                                  value: _vm.routeStart,
                                  callback: function ($$v) {
                                    _vm.routeStart = $$v
                                  },
                                  expression: "routeStart",
                                },
                              }),
                              _c("v-text-field", {
                                attrs: { label: "End Address", outlined: "" },
                                model: {
                                  value: _vm.routeEnd,
                                  callback: function ($$v) {
                                    _vm.routeEnd = $$v
                                  },
                                  expression: "routeEnd",
                                },
                              }),
                              _vm.routingMessage != ""
                                ? _c("p", { staticClass: "error" }, [
                                    _vm._v(
                                      " " + _vm._s(_vm.routingMessage) + " "
                                    ),
                                  ])
                                : _vm._e(),
                              _c("v-checkbox", {
                                staticStyle: { "margin-top": "-10px" },
                                attrs: { label: "Elevation Profile" },
                                model: {
                                  value: _vm.showElevation,
                                  callback: function ($$v) {
                                    _vm.showElevation = $$v
                                  },
                                  expression: "showElevation",
                                },
                              }),
                              _c(
                                "v-radio-group",
                                {
                                  staticClass: "trailerConfig",
                                  model: {
                                    value: _vm.trailerConfig,
                                    callback: function ($$v) {
                                      _vm.trailerConfig = $$v
                                    },
                                    expression: "trailerConfig",
                                  },
                                },
                                [
                                  _c("v-radio", {
                                    key: "1",
                                    attrs: {
                                      value: "trailer",
                                      label: "With Trailer",
                                    },
                                  }),
                                  _c("v-radio", {
                                    key: "0",
                                    attrs: {
                                      value: "bobtail",
                                      label: "Bobtail",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm.routingLoading
                                ? _c("v-progress-circular", {
                                    staticClass: "center",
                                    attrs: {
                                      indeterminate: "",
                                      color: "primary",
                                    },
                                  })
                                : _c(
                                    "v-btn",
                                    {
                                      staticClass: "center",
                                      attrs: {
                                        color: "primary",
                                        type: "submit",
                                        block: "",
                                        loading: _vm.routeLoading,
                                      },
                                      on: { click: _vm.mapRoute },
                                    },
                                    [_vm._v("Route")]
                                  ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-btn",
        {
          style: _vm.cogStyle,
          attrs: { icon: "", tile: "", large: "" },
          on: {
            click: function ($event) {
              _vm.drawer = true
            },
          },
        },
        [
          _c("v-icon", { staticStyle: { opacity: "0.6" } }, [
            _vm._v("mdi-cog"),
          ]),
        ],
        1
      ),
      !_vm.dashboardActive && !_vm.playbackMode
        ? _c("div", { style: _vm.datetimeStyle }, [
            _c("span", { staticClass: "datetimeLabel" }, [
              _vm._v("Date / Time"),
            ]),
            _c("br"),
            _c("div", { staticClass: "datetime" }, [
              _c("span", { staticClass: "datetimeLine" }, [
                _vm._v(" " + _vm._s(_vm.mapDate) + " "),
              ]),
              _c("span", [_vm._v(" " + _vm._s(_vm.mapTime) + " ")]),
            ]),
          ])
        : _vm.playbackMode
        ? _c(
            "div",
            { style: _vm.playbackStyle },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "space-between",
                  },
                },
                [
                  _c("span", { staticClass: "datetimeLabel" }, [
                    _vm._v("Date / Time"),
                  ]),
                  _c(
                    "span",
                    {
                      staticStyle: { cursor: "pointer" },
                      on: { click: _vm.clear },
                    },
                    [_vm._v("Clear")]
                  ),
                ]
              ),
              _c("div", { staticClass: "playbackDatetime" }, [
                _c("span", { staticClass: "datetimeLine" }, [
                  _vm._v(" " + _vm._s(_vm.mapDate) + " "),
                ]),
                _c("span", [_vm._v(" " + _vm._s(_vm.mapTime) + " ")]),
              ]),
              _c("v-slider", {
                staticStyle: { "margin-left": "5px" },
                attrs: { max: "1440", ticks: "", step: "5" },
                model: {
                  value: _vm.sliderValue,
                  callback: function ($$v) {
                    _vm.sliderValue = $$v
                  },
                  expression: "sliderValue",
                },
              }),
              _c(
                "div",
                { staticClass: "controlsBar" },
                [
                  _vm.noYesterday
                    ? _c("v-progress-circular", {
                        attrs: {
                          size: 22,
                          indeterminate: "",
                          color: "primary",
                        },
                      })
                    : _c(
                        "v-icon",
                        {
                          staticClass: "iconButton",
                          attrs: { color: "primary" },
                          on: { click: _vm.skipPrevious },
                        },
                        [_vm._v("mdi-skip-previous")]
                      ),
                  _vm.speedMultiplier < 0
                    ? _c("div", { staticClass: "rewindSpeed" }, [
                        _vm._v(
                          " " + _vm._s(Math.abs(_vm.speedMultiplier)) + "x "
                        ),
                      ])
                    : _vm._e(),
                  _c(
                    "v-icon",
                    {
                      staticClass: "iconButton",
                      attrs: { color: "primary" },
                      on: { click: _vm.rewind },
                    },
                    [_vm._v("mdi-rewind")]
                  ),
                  !_vm.positionsLoaded && _vm.validDate
                    ? _c("v-progress-circular", {
                        attrs: {
                          size: 22,
                          indeterminate: "",
                          color: "primary",
                        },
                      })
                    : !_vm.playing
                    ? _c(
                        "v-icon",
                        {
                          staticClass: "iconButton",
                          attrs: {
                            color: "primary",
                            disabled: _vm.playDisabled,
                          },
                          on: { click: _vm.playPressed },
                        },
                        [_vm._v("mdi-play")]
                      )
                    : _vm.playing
                    ? _c(
                        "v-icon",
                        {
                          staticClass: "iconButton",
                          attrs: { color: "primary" },
                          on: { click: _vm.pausePlayback },
                        },
                        [_vm._v("mdi-pause")]
                      )
                    : _vm._e(),
                  _vm.speedMultiplier > 1
                    ? _c("div", { staticClass: "forwardSpeed" }, [
                        _vm._v(" " + _vm._s(_vm.speedMultiplier) + "x "),
                      ])
                    : _vm._e(),
                  _c(
                    "v-icon",
                    {
                      staticClass: "iconButton",
                      attrs: { color: "primary" },
                      on: { click: _vm.fastForward },
                    },
                    [_vm._v("mdi-fast-forward")]
                  ),
                  _vm.noTomorrow
                    ? _c("v-progress-circular", {
                        attrs: {
                          size: 22,
                          indeterminate: "",
                          color: "primary",
                        },
                      })
                    : _c(
                        "v-icon",
                        {
                          staticClass: "iconButton",
                          attrs: { color: "primary" },
                          on: { click: _vm.skipNext },
                        },
                        [_vm._v("mdi-skip-next")]
                      ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      false ? _c("loader") : _vm._e(),
      _vm.loadingMarkers
        ? _c("v-progress-circular", {
            staticClass: "loader",
            attrs: { color: "primary", size: 70, width: 7, indeterminate: "" },
          })
        : _vm._e(),
      _c("div", { staticClass: "data", attrs: { id: "id-td-MapView" } }, [
        _c("div", { attrs: { id: "map" } }, [
          _c("div", {
            ref: "hereMap",
            style: `height: ${_vm.mapHeight}px; width: 100%`,
            attrs: { id: "mapContainer" },
          }),
        ]),
      ]),
      _c(
        "div",
        { staticStyle: { "margin-right": "270px" } },
        [
          _vm.elevationProcessed && _vm.showElevation
            ? _c("ElevationGraph", { attrs: { data: _vm.elevationData } })
            : _vm._e(),
        ],
        1
      ),
      _vm.pieLoaded && _vm.showElevation
        ? _c("ClassPie", {
            staticClass: "pieChart",
            attrs: { data: _vm.pieData, labels: _vm.labels },
          })
        : _vm.elevationProcessed && _vm.showElevation
        ? _c(
            "v-sheet",
            { attrs: { color: "darken-2" } },
            [
              _c("v-skeleton-loader", {
                staticClass: "pieChart",
                attrs: { "max-width": "270", type: "list-item-two-line, card" },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }