var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "12", xl: "4", lg: "4" } }, [
            _c("h1", {
              staticClass: "transition-swing",
              staticStyle: { "font-weight": "400" },
              domProps: { textContent: _vm._s("Data  Dictionary") },
            }),
          ]),
          _c("v-col", [_c("v-spacer")], 1),
          _c(
            "v-col",
            { attrs: { align: "right", cols: "12", xl: "4", lg: "4" } },
            [
              _vm.tabItems[_vm.tab] === "Signals"
                ? _c("v-select", {
                    staticClass: "'select'",
                    attrs: {
                      value: _vm.selectedDb,
                      items: _vm.dbTypes,
                      "item-text": "state",
                      "item-value": "abbr",
                      label: "DB Type",
                      placeholder: "DB Type",
                      "persistent-hint": "",
                      "return-object": "",
                      outlined: "",
                    },
                    on: { change: _vm.setDataDictionaryDb },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mt-0 mb-1" },
        [
          _c("v-col", [_c("v-spacer")], 1),
          _c(
            "v-col",
            {
              staticClass: "pt-0",
              attrs: { align: "right", cols: "12", xl: "6", lg: "6" },
            },
            [
              _vm.tabItems[_vm.tab] === "Signals"
                ? _c(
                    "v-btn",
                    {
                      staticStyle: { color: "black" },
                      attrs: { color: "primary" },
                      on: { click: _vm.exportCSV },
                    },
                    [
                      _c(
                        "download-csv",
                        {
                          attrs: {
                            id: "csv",
                            name: "table.csv",
                            data: _vm.sourceDownload,
                          },
                        },
                        [
                          _c(
                            "v-icon",
                            { staticClass: "pr-1", attrs: { id: "csv2" } },
                            [_vm._v("mdi-download")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" Export "),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.tabItems[_vm.tab] === "Cummins Testing"
                ? _c(
                    "v-btn",
                    {
                      staticStyle: { color: "black" },
                      attrs: {
                        disabled: !_vm.cumminsDowload.length,
                        color: "primary",
                      },
                      on: { click: _vm.exportCumminsCSV },
                    },
                    [
                      _c(
                        "download-csv",
                        {
                          attrs: {
                            id: "csv_c",
                            name: "cummins_testing_data.csv",
                            data: _vm.cumminsDowload,
                          },
                        },
                        [
                          _c(
                            "v-icon",
                            { staticClass: "pr-1", attrs: { id: "csv_c2" } },
                            [_vm._v("mdi-download")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" Export "),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.tabItems[_vm.tab] === "Hybrid DTC"
                ? _c(
                    "v-btn",
                    {
                      staticClass: "mr-1",
                      attrs: { color: "secondary" },
                      on: {
                        click: function ($event) {
                          _vm.uploadDTCSOpen = true
                        },
                      },
                    },
                    [
                      _c("v-icon", { staticClass: "pr-1" }, [
                        _vm._v("mdi-upload"),
                      ]),
                      _vm._v(" Upload DTCS "),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.tabItems[_vm.tab] === "ERX DTC"
                ? _c(
                    "v-btn",
                    {
                      staticClass: "mr-1",
                      attrs: { color: "secondary" },
                      on: {
                        click: function ($event) {
                          _vm.findDTCSOpen = true
                        },
                      },
                    },
                    [
                      _c("v-icon", { staticClass: "pr-1" }, [
                        _vm._v("mdi-magnify"),
                      ]),
                      _vm._v(" Find Service Guide "),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.tabItems[_vm.tab] === "Signals"
                ? _c(
                    "v-btn",
                    {
                      staticClass: "mx-1",
                      staticStyle: { color: "black" },
                      attrs: { color: "primary" },
                      on: {
                        click: () => {
                          this.$router.push(`/AddSignal`)
                        },
                      },
                    },
                    [_vm._v(" Add Signal ")]
                  )
                : _vm._e(),
              _vm.tabItems[_vm.tab] === "Signals"
                ? _c(
                    "v-btn",
                    {
                      attrs: { color: "secondary" },
                      on: {
                        click: function ($event) {
                          return _vm.setUploadModalOpen(true)
                        },
                      },
                    },
                    [
                      _c("v-icon", { staticClass: "pr-1" }, [
                        _vm._v("mdi-upload"),
                      ]),
                      _vm._v(" Upload "),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.tabItems[_vm.tab] === "ERX DTC"
                ? _c(
                    "v-btn",
                    {
                      staticClass: "mr-2",
                      attrs: { color: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.$refs.erxDtcTable.addLinksToRows()
                        },
                      },
                    },
                    [_vm._v("Populate Links")]
                  )
                : _vm._e(),
              _vm.tabItems[_vm.tab] === "Signals"
                ? _c(
                    "v-btn",
                    {
                      staticClass: "mx-1",
                      staticStyle: {
                        "margin-right": "0.7rem",
                        "margin-left": "0.5rem",
                        "background-color": "#1a1a1a",
                        "border-radius": "5px",
                      },
                      attrs: { icon: "", tile: "", large: "" },
                      on: {
                        click: function ($event) {
                          _vm.drawer = true
                        },
                      },
                    },
                    [
                      _c("v-icon", { staticStyle: { opacity: "0.6" } }, [
                        _vm._v("mdi-cog"),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-col",
        {
          staticClass: "pa-2",
          attrs: {
            cols: "8",
            xs: "12",
            sm: "12",
            md: "12",
            lg: "10",
            xl: "10",
          },
        },
        [
          _c(
            "v-tabs",
            {
              attrs: { "background-color": "secondary", "fixed-tabs": "" },
              model: {
                value: _vm.tab,
                callback: function ($$v) {
                  _vm.tab = $$v
                },
                expression: "tab",
              },
            },
            _vm._l(_vm.tabItems, function (item) {
              return _c("v-tab", { key: item }, [
                _vm._v(" " + _vm._s(item) + " "),
              ])
            }),
            1
          ),
        ],
        1
      ),
      _vm.tabItems[_vm.tab] === "ADX"
        ? _c("v-card", [_c("adx-data-view")], 1)
        : _vm._e(),
      _vm.tabItems[_vm.tab] === "Cummins Testing"
        ? _c(
            "v-card",
            [
              _c("cummins-testing", {
                on: { updateCumminsData: _vm.updateCumminsData },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.tabItems[_vm.tab] === "Documentation"
        ? _c("v-card", [_c("workbook-table")], 1)
        : _vm._e(),
      _vm.tabItems[_vm.tab] === "ERX DTC"
        ? _c(
            "v-card",
            [
              _c(
                "v-card",
                { attrs: { flat: "" } },
                [_c("erx-dtc-table", { ref: "erxDtcTable" })],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.tabItems[_vm.tab] === "Hybrid DTC"
        ? _c(
            "v-card",
            [_c("v-card", { attrs: { flat: "" } }, [_c("dtc-table")], 1)],
            1
          )
        : _vm._e(),
      _vm.tabItems[_vm.tab] === "Signals"
        ? _c(
            "v-card",
            [
              _c("signals", {
                attrs: {
                  "selected-db": _vm.selectedDb,
                  source: _vm.source,
                  "data-headers": _vm.dataHeaders,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.uploadDTCSOpen
        ? _c("upload-dtcs", {
            on: {
              closed: function ($event) {
                _vm.uploadDTCSOpen = false
              },
            },
          })
        : _vm._e(),
      _vm.findDTCSOpen
        ? _c("find-excel", {
            on: {
              closed: function ($event) {
                _vm.findDTCSOpen = false
              },
            },
          })
        : _vm._e(),
      _c(
        "v-dialog",
        {
          attrs: { width: "500", "content-class": "alertdialog" },
          on: {
            "click:outside": function ($event) {
              return _vm.setUploadModalOpen(false)
            },
          },
          model: {
            value: _vm.uploadModalOpen,
            callback: function ($$v) {
              _vm.uploadModalOpen = $$v
            },
            expression: "uploadModalOpen",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", {}, [
                _vm._v(" " + _vm._s("Upload JSON") + " "),
              ]),
              _c("v-divider"),
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", [
                        _vm._v(_vm._s("Number Added: " + _vm.numberAdded)),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", [
                        _vm._v(_vm._s("Number Updated: " + _vm.numberUpdated)),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", [
                        _vm._v(_vm._s("Number Skipped: " + _vm.numberSkipped)),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", [
                        _vm._v(
                          _vm._s("Number Unchanged: " + _vm.numberUnchanged)
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-file-input", {
                            attrs: {
                              accept: "application/JSON",
                              outlined: "",
                              solo: "",
                              "truncate-length": "30",
                              label: "Upload JSON Configuration",
                            },
                            on: { change: _vm.jsonFileSelected },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { align: "right" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "secondary-button",
                              attrs: { color: "primary", text: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.setUploadModalOpen(false)
                                },
                              },
                            },
                            [_vm._v(" CANCEL ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "primary-button",
                              attrs: {
                                color: "secondary",
                                text: "",
                                align: "right",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.uploadJson(
                                    _vm.sourceTypes,
                                    _vm.signalTypes,
                                    _vm.unitsTypes,
                                    _vm.config
                                  )
                                },
                              },
                            },
                            [_vm._v(" Upload ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-navigation-drawer",
        {
          staticClass: "shade",
          attrs: {
            "disable-click-watcher": "",
            right: "",
            temporary: "",
            fixed: "",
            width: "360",
          },
          model: {
            value: _vm.drawer,
            callback: function ($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer",
          },
        },
        [
          _c(
            "v-row",
            { staticClass: "my-2 pl-5 pr-3", attrs: { align: "center" } },
            [
              _c("v-col", { attrs: { align: "left", cols: "9" } }, [
                _vm._v("Customize Columns:"),
              ]),
              _c(
                "v-col",
                { attrs: { align: "right", cols: "3" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function ($event) {
                          _vm.drawer = false
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { light: "" } }, [
                        _vm._v("mdi-close-circle "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  [
                    _c(
                      "v-list",
                      [
                        _c("v-subheader", [
                          _vm._v("Select to display Columns"),
                        ]),
                        _c(
                          "v-list-item-group",
                          {
                            attrs: {
                              "active-class": "green--text",
                              multiple: "",
                            },
                          },
                          [
                            _vm._l(
                              _vm.dataHeadersDup,
                              function (column, index) {
                                return [
                                  column.text.length
                                    ? _c(
                                        "v-list-item",
                                        {
                                          key: column.value,
                                          attrs: { disabled: !column.hideable },
                                          on: {
                                            click: function ($event) {
                                              return _vm.updateColumn(
                                                column,
                                                index
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-list-item-action",
                                            { staticClass: "mr-5" },
                                            [
                                              !column.visible
                                                ? _c(
                                                    "v-icon",
                                                    {
                                                      attrs: { color: "grey" },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " mdi-square-outline "
                                                      ),
                                                    ]
                                                  )
                                                : _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        color: "primary",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " mdi-checkbox-marked "
                                                      ),
                                                    ]
                                                  ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-list-item-title", [
                                                _vm._v(_vm._s(column.text)),
                                              ]),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-action",
                                            [
                                              !column.hideable
                                                ? _c("v-icon", [
                                                    _vm._v(" mdi-lock "),
                                                  ])
                                                : _c("v-icon", [
                                                    _vm._v(" mdi-menu "),
                                                  ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              }
                            ),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      { staticClass: "pt-6" },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "py-0 pr-0 pl-7",
                            attrs: { cols: "5" },
                          },
                          [
                            _c("v-checkbox", {
                              attrs: { label: "Make Preset" },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          { attrs: { cols: "7" } },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "mr-2",
                                attrs: { color: "primary", outlined: "" },
                                on: {
                                  click: function ($event) {
                                    _vm.drawer = false
                                  },
                                },
                              },
                              [_vm._v("Cancel")]
                            ),
                            _c(
                              "v-btn",
                              {
                                staticStyle: { color: "black" },
                                attrs: { color: "primary" },
                                on: {
                                  click: function ($event) {
                                    _vm.drawer = false
                                  },
                                },
                              },
                              [_vm._v("Save")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }