var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "mt-3 mx-3", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c("v-col", [
            _c("h1", { staticStyle: { "font-weight": "400" } }, [
              _vm._v("DTC Dashboard"),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "mr-4" },
            [
              _vm.initLoading
                ? _c(
                    "v-skeleton-loader",
                    _vm._b(
                      {
                        attrs: {
                          type: "table-heading, image, table-heading, image",
                        },
                      },
                      "v-skeleton-loader",
                      _vm.attrs,
                      false
                    )
                  )
                : _c(
                    "v-card",
                    { attrs: { elevation: "2", color: "#272727" } },
                    [
                      _c(
                        "v-row",
                        { staticClass: "ml-3 mt-3" },
                        [
                          _c(
                            "v-col",
                            { staticClass: "pr-5", attrs: { cols: "3" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.companies,
                                  "item-text": "name",
                                  label: "Select a Fleet",
                                  "single-line": "",
                                  "return-object": "",
                                  solo: "",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.renderCharts("all")
                                  },
                                },
                                model: {
                                  value: _vm.selectedCompany,
                                  callback: function ($$v) {
                                    _vm.selectedCompany = $$v
                                  },
                                  expression: "selectedCompany",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "pl-1", attrs: { cols: "3" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.truckList,
                                  disabled: _vm.firstRowLoading,
                                  "item-text": "number",
                                  multiple: "",
                                  "return-object": "",
                                  solo: "",
                                  chips: "",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "prepend-item",
                                    fn: function () {
                                      return [
                                        _c(
                                          "v-list-item",
                                          {
                                            attrs: { ripple: "" },
                                            on: {
                                              mousedown: function ($event) {
                                                $event.preventDefault()
                                              },
                                              click: _vm.toggle,
                                            },
                                          },
                                          [
                                            _c(
                                              "v-list-item-action",
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      color:
                                                        _vm.selectedTrucks
                                                          .length > 0
                                                          ? "primary"
                                                          : "",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(_vm.icon) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-list-item-title", [
                                                  _vm._v(" Select All "),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("v-divider", {
                                          staticClass: "mt-2",
                                        }),
                                        _c("v-divider", {
                                          staticClass: "mt-2",
                                        }),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                                model: {
                                  value: _vm.selectedTrucks,
                                  callback: function ($$v) {
                                    _vm.selectedTrucks = $$v
                                  },
                                  expression: "selectedTrucks",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "3" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    height: "48",
                                    color: "secondary",
                                    disabled:
                                      _vm.firstRowLoading ||
                                      !_vm.selectedTrucks.length,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.renderCharts("charts")
                                    },
                                  },
                                },
                                [_vm._v("Refresh Data")]
                              ),
                            ],
                            1
                          ),
                          _c("v-col", [_c("v-spacer")], 1),
                        ],
                        1
                      ),
                      _vm.firstRowLoading
                        ? _c(
                            "v-skeleton-loader",
                            _vm._b(
                              { attrs: { type: "table-heading, image" } },
                              "v-skeleton-loader",
                              _vm.attrs,
                              false
                            )
                          )
                        : _c(
                            "v-row",
                            { staticClass: "ml-3 mt-3 mr-6" },
                            [
                              _c("v-col", { attrs: { cols: "3" } }, [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "background-color": "#1a1a1a",
                                    },
                                  },
                                  [
                                    _c("PieChart", {
                                      attrs: {
                                        name: "Fleet Status",
                                        data: _vm.fleetStatusPie,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _c(
                                "v-col",
                                {
                                  staticClass: "mr-0 pr-0",
                                  attrs: { cols: "3" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "background-color": "#1a1a1a",
                                      },
                                    },
                                    [
                                      _c("PieChart", {
                                        attrs: {
                                          name: _vm.dateName("overview"),
                                          data: _vm.overviewPie,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "ml-0 pl-0",
                                  staticStyle: {
                                    "background-color": "#1a1a1a",
                                    height: "fit-content",
                                    "margin-top": "inherit",
                                  },
                                  attrs: { cols: "6" },
                                },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c("v-col", [_c("v-spacer")], 1),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "pb-0 mb-0 d-flex justify-end align-center",
                                          attrs: { cols: "4" },
                                        },
                                        [
                                          _c(
                                            "p",
                                            { staticClass: "pb-3 pr-3" },
                                            [_vm._v("Date Selector:")]
                                          ),
                                          _c("v-divider", {
                                            staticStyle: {
                                              "margin-top": "4px",
                                              height: "48px",
                                            },
                                            attrs: { vertical: "", inset: "" },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pb-0 mb-0 ml-0 pl-0",
                                          attrs: { cols: "4" },
                                        },
                                        [
                                          _c("date-input", {
                                            attrs: {
                                              label: "Date",
                                              model: _vm.singleDate,
                                              "send-date": (d) =>
                                                (_vm.singleDate = d),
                                              "show-guide": false,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            col: "6",
                                            xl: "6",
                                            lg: "6",
                                            md: "12",
                                            sm: "12",
                                          },
                                        },
                                        [
                                          _c("BarChart", {
                                            attrs: {
                                              name: _vm.dateName("topFive"),
                                              data: _vm.topFiveDTCs,
                                              categories: _vm.topFiveCategories,
                                              height: 278,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "6",
                                            xl: "6",
                                            lg: "6",
                                            md: "12",
                                            sm: "12",
                                          },
                                        },
                                        [
                                          _c("BarChart", {
                                            attrs: {
                                              name: _vm.dateName(
                                                "topFiveCritical"
                                              ),
                                              data: _vm.topFiveCritical,
                                              categories:
                                                _vm.topFiveCriticalCategories,
                                              height: 278,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                      _vm.secondRowLoading
                        ? _c(
                            "v-skeleton-loader",
                            _vm._b(
                              { attrs: { type: "table-heading, image" } },
                              "v-skeleton-loader",
                              _vm.attrs,
                              false
                            )
                          )
                        : _c(
                            "v-row",
                            { staticClass: "mx-6" },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "ml-0 pl-0",
                                  staticStyle: {
                                    "background-color": "#1a1a1a",
                                    height: "fit-content",
                                    "margin-top": "inherit",
                                  },
                                  attrs: { cols: "12" },
                                },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "mt-2 ml-3",
                                          attrs: { cols: "2", md: "3" },
                                        },
                                        [
                                          _c("h3", [
                                            _vm._v("Top DTCs and MTBF"),
                                          ]),
                                        ]
                                      ),
                                      _c("v-col", [_c("v-spacer")], 1),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "pb-0 mb-0 d-flex justify-end align-center",
                                          attrs: { cols: "2" },
                                        },
                                        [
                                          _c(
                                            "p",
                                            { staticClass: "pb-3 pr-3" },
                                            [_vm._v("Date Range:")]
                                          ),
                                          _c("v-divider", {
                                            staticStyle: {
                                              "margin-top": "4px",
                                              height: "48px",
                                            },
                                            attrs: { vertical: "", inset: "" },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pb-0 mb-0 ml-0 pl-0",
                                          attrs: { cols: "2" },
                                        },
                                        [
                                          _c("date-input", {
                                            attrs: {
                                              label: "Start Date",
                                              model: _vm.startDate,
                                              "send-date": (d) =>
                                                (_vm.startDate = d),
                                              "show-guide": false,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pb-0 mb-0 ml-0 pl-0",
                                          attrs: { cols: "2" },
                                        },
                                        [
                                          _c("date-input", {
                                            attrs: {
                                              label: "End Date",
                                              model: _vm.endDate,
                                              "send-date": (d) =>
                                                (_vm.endDate = d),
                                              "show-guide": false,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            col: "6",
                                            xl: "6",
                                            lg: "6",
                                            md: "12",
                                            sm: "12",
                                          },
                                        },
                                        [
                                          _c("BarChart", {
                                            attrs: {
                                              name: _vm.dateName("topTen"),
                                              data: _vm.topTenDTCs,
                                              categories: _vm.topTenCategories,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "6",
                                            xl: "6",
                                            lg: "6",
                                            md: "12",
                                            sm: "12",
                                          },
                                        },
                                        [
                                          _c("BarChart", {
                                            attrs: {
                                              name: _vm.dateName(
                                                "topTenCritical"
                                              ),
                                              data: _vm.topTenCritical,
                                              categories:
                                                _vm.topTenCriticalCategories,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            col: "6",
                                            xl: "6",
                                            lg: "6",
                                            md: "12",
                                            sm: "12",
                                          },
                                        },
                                        [
                                          _c("GroupedBarChart", {
                                            attrs: {
                                              name: "Mean Time Between Faults",
                                              data: _vm.meanTime,
                                              categories:
                                                _vm.meanTimeCategories,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "6",
                                            xl: "6",
                                            lg: "6",
                                            md: "12",
                                            sm: "12",
                                          },
                                        },
                                        [
                                          _c("GroupedBarChart", {
                                            attrs: {
                                              name: "Mean Time Between Failure (Critical DTCs)",
                                              data: _vm.meanTimeCritical,
                                              categories:
                                                _vm.meanTimeCriticalCategories,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                    ],
                    1
                  ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }