var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "configuration-view" } },
    [
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { attrs: { align: "center" } },
            [
              _c("v-col", [
                _c("h1", { staticStyle: { "font-weight": "400" } }, [
                  _vm._v("Cloud Management"),
                ]),
              ]),
              _c("v-col", { attrs: { align: "right" } }),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mt-4" },
            [
              _c(
                "v-col",
                {
                  attrs: {
                    cols: "4",
                    xs: "12",
                    sm: "12",
                    md: "12",
                    lg: "4",
                    xl: "4",
                  },
                },
                [
                  _c(
                    "v-tabs",
                    {
                      attrs: {
                        "fixed-tabs": "",
                        "background-color": "secondary",
                      },
                    },
                    [
                      _c(
                        "v-tab",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.changeTab("Summary")
                            },
                          },
                        },
                        [_vm._v("Summary")]
                      ),
                      _c(
                        "v-tab",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.changeTab("Logger")
                            },
                          },
                        },
                        [_vm._v("Logger")]
                      ),
                      _c(
                        "v-tab",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.changeTab("Feedback")
                            },
                          },
                        },
                        [_vm._v("Feedback")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "hidden-sm-and-down" },
                [_c("v-spacer")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.switchTab === "Summary"
        ? _c("summary-view", { staticClass: "pa-1" })
        : _vm.switchTab === "Logger"
        ? _c("logger-view")
        : _vm.switchTab === "Feedback"
        ? _c("feedback-view")
        : _vm._e(),
      _c(
        "v-navigation-drawer",
        {
          staticClass: "shade",
          attrs: { right: "", temporary: "", fixed: "", width: "360" },
          model: {
            value: _vm.drawer,
            callback: function ($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer",
          },
        },
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                { attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "mb-2", attrs: { align: "right" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function ($event) {
                              _vm.drawer = false
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { light: "" } }, [
                            _vm._v("mdi-close-circle "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-expansion-panels",
                {
                  attrs: { multiple: "" },
                  model: {
                    value: _vm.panel,
                    callback: function ($$v) {
                      _vm.panel = $$v
                    },
                    expression: "panel",
                  },
                },
                [
                  _c(
                    "v-expansion-panel",
                    { staticClass: "shade" },
                    [
                      _c("v-expansion-panel-header", [_vm._v("Placeholder")]),
                      _c("v-expansion-panel-content"),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }