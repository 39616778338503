<template>
  <v-container fluid class="body">
    <v-row align="center">
      <v-col>
        <h1 style="font-weight: 400">Logger</h1>
      </v-col>
    </v-row>
    <v-container grid-list-md text-xs-center>
      <v-layout row wrap>
        <!-- DATE PICKER -->
        <v-row>
          <v-col cols="12" lg="3" xl="3">
            <v-menu
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dropDown.dateStart"
                  label="Start Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dropDown.dateStart"
                @change="runCustomQuery"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <!-- DATE PICKER -->
          <v-col cols="12" lg="3" xl="3">
            <v-menu
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dropDown.dateEnd"
                  label="End Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dropDown.dateEnd"
                @change="runCustomQuery"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <!-- DROPDOWN -->
          <v-col cols="12" lg="3" xl="3">
            <v-select
              v-model="dropDown.actorType"
              :items="actorType"
              item-text="actorType"
              item-value="actorType"
              label="actor_type"
              persistent-hint
              return-object
              outlined
              @change="runCustomQuery"
            ></v-select>
          </v-col>

          <!-- DROPDOWN -->
          <v-col cols="12" lg="3" xl="3">
            <v-select
              v-model="dropDown.actorId"
              :items="actorId"
              item-text="actorId"
              item-value="actorId"
              label="actor_id"
              persistent-hint
              return-object
              outlined
              @change="runCustomQuery"
            ></v-select>
          </v-col>

          <!-- DROPDOWN -->
          <v-col cols="12" lg="3" xl="3">
            <v-select
              v-model="dropDown.severity"
              :items="severity"
              item-text="severity"
              item-value="severity"
              label="severity"
              persistent-hint
              return-object
              outlined
              @change="runCustomQuery"
            ></v-select>
          </v-col>

          <!-- DROPDOWN -->
          <v-col cols="12" lg="3" xl="3">
            <v-select
              v-model="dropDown.appId"
              :items="appId"
              item-text="appId"
              item-value="appId"
              label="appId"
              persistent-hint
              return-object
              outlined
              @change="runCustomQuery"
            ></v-select>
          </v-col>

          <!-- DROPDOWN -->
          <v-col cols="12" lg="3" xl="3">
            <v-select
              v-model="dropDown.method"
              :items="method"
              item-text="method"
              item-value="method"
              label="method"
              persistent-hint
              return-object
              outlined
              @change="runCustomQuery"
            ></v-select>
          </v-col>

          <!-- TEXT FIELD -->
          <v-col cols="12" lg="3" xl="3">
            <v-text-field
              v-model="dropDown.limit"
              label="Limit"
              placeholder="100"
              outlined
              color="primary"
              @change="runCustomQuery"
            ></v-text-field
          ></v-col>

          <!-- QUERY BUTTON -->
          <v-col cols="12" lg="3" xl="3">
            <v-btn
              color="primary"
              class="ma-1"
              style="color: black"
              @click="reset()"
            >
              Reset
            </v-btn>
          </v-col>
        </v-row>
      </v-layout>
    </v-container>

    <!-- TABLE -->
    <v-row class="my-0">
      <v-col>
        <v-data-table
          :headers="loggerLabels"
          :items="loggerData"
          :items-per-page="10"
          class="elevation-1"
        >
        </v-data-table>
      </v-col>
    </v-row>

    <!-- NAVIGATION DRAWER -->
    <v-navigation-drawer v-model="drawer" fixed right temporary width="360">
      <v-container>
        <v-row align="center">
          <v-col align="right" class="mb-2">
            <v-btn icon @click="drawer = false">
              <v-icon light>mdi-close-circle </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <p style="text-align: center">No content</p>
      </v-container>
    </v-navigation-drawer>
  </v-container>
</template>
<script>
import dateFormat from 'dateformat';
import { runQuery } from '../../api/logger';
export default {
  data() {
    return {
      dropDown: {
        dateStart: this.getDateStart(),
        dateEnd: this.getDateEnd(),
        menu1: false,
        menu2: false,
        actorType: 'All',
        actorId: 'All',
        severity: 'All',
        appId: 'All',
        method: 'All',
        limit: 100,
      },
      actorType: [],
      actorId: [],
      severity: [],
      appId: [],
      method: [],
      loggerData: [],
      loggerLabels: [
        {
          text: 'timestamp',
          align: 'left',
          value: 'timestamp',
          sortable: true,
        },
        {
          text: 'severity',
          align: 'left',
          value: 'severity',
          sortable: true,
        },
        {
          text: 'trace_id',
          align: 'left',
          value: 'trace_id',
          sortable: true,
        },
        {
          text: 'actor_type',
          align: 'left',
          value: 'actor_type',
          sortable: true,
        },
        {
          text: 'actor_id',
          align: 'left',
          value: 'actor_id',
          sortable: true,
        },
        {
          text: 'method',
          align: 'left',
          value: 'method',
          sortable: true,
        },
        {
          text: 'label',
          align: 'left',
          value: 'label',
          sortable: true,
        },
        {
          text: 'detail',
          align: 'left',
          value: 'detail',
          sortable: false,
        },
        {
          text: 'appId',
          align: 'left',
          value: 'appId',
          sortable: true,
        },
      ],
      drawer: false,
    };
  },
  async mounted() {
    const results = await runQuery(this.dropDown);
    this.populateDropdown(results.data);
    this.populateTable(results.data);
  },
  methods: {
    async runCustomQuery() {
      let results = await runQuery(this.dropDown);
      this.populateDropdown(results.data);
      this.populateTable(results.data);
    },
    populateDropdown(data) {
      for (let key in data) {
        if (key != 'loggerData') {
          data[key].unshift('All');
        }
      }
      this.startDate = data.startDate;
      this.endDate = data.endDate;
      this.actorType = data.actor_type;
      this.actorId = data.actor_id;
      this.severity = data.severity;
      this.appId = data.appId;
      this.method = data.method;
    },
    populateTable(data) {
      if (data.loggerData.length > 0) {
        data.loggerData.forEach(
          (ele) => (ele.detail = JSON.stringify(ele.detail))
        );
      }
      this.loggerData = data.loggerData;
    },
    reset() {
      this.dropDown = {
        dateStart: this.getDateStart(),
        dateEnd: this.getDateEnd(),
        actorType: 'All',
        actorId: 'All',
        severity: 'All',
        appId: 'All',
        method: 'All',
        limit: 100,
      };

      this.runCustomQuery(this.dropDown);
    },
    getDateStart() {
      let days = 7;
      var date = new Date();
      let last = new Date(date.getTime() - days * 24 * 60 * 60 * 1000);
      return dateFormat(last, 'yyyy-mm-dd');
    },
    getDateEnd() {
      let today = new Date();
      return dateFormat(today, 'yyyy-mm-dd');
    },
  },
};
</script>
