import axios from 'axios';

const BASE_URL = process.env.VUE_APP_API_PATH_BASE;

async function getWorkOrders(vin) {
  return await axios.get(BASE_URL + `salesforce/work_orders?vin=${vin}`);
}

async function getCases(vin) {
  return await axios.get(BASE_URL + `salesforce/cases?vin=${vin}`);
}

async function getAssets() {
  return await axios.get(BASE_URL + `salesforce/assets`);
}

async function addCase(newCase) {
  return await axios.post(BASE_URL + 'salesforce/case', newCase);
}

export { getWorkOrders, getCases, addCase, getAssets };
