<template>
  <v-container fluid>
    <v-row> </v-row>
    <v-row>
      <v-col>
        <v-container fluid>
          <v-row style="background-color: #272727; border-radius: 5px">
            <v-col v-for="(item, index) in icons" :key="index">
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <td class="mx-0 px-0">
                    <icon-loader
                      v-if="item.icon"
                      :icon-show="item.icon"
                      class="px-0 mx-0"
                      :on="on"
                    />
                  </td>
                </template>
                <span>{{ item.tooltip }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-row class="padding">
      <v-col cols="6" sm="4" md="4" lg="3" xl="3" align="left">
        <span class="secondary-text">Truck Name</span><br /><b>{{
          erxData.number
        }}</b>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="3" xl="3" align="left">
        <span class="secondary-text">Current Odometer</span>
        <br /><b>{{ erxData.odometer }} {{ displayDistanceUnits() }}</b>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="3" xl="3" align="left">
        <span class="secondary-text">Engine Hours</span>
        <br /><b>{{ erxData.engtotalhoursofoperation }}</b>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="3" xl="3" align="left">
        <span class="secondary-text">Current Speed</span>
        <br /><b>{{ erxData.current_speed }} {{ displaySpeedUnits() }}</b>
      </v-col>
    </v-row>
    <v-row class="padding">
      <v-col cols="6" sm="4" md="4" lg="3" xl="3" align="left">
        <span class="secondary-text">Company</span><br /><b>{{
          erxData.company
        }}</b>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="3" xl="3" align="left">
        <span class="secondary-text">Ignition State</span>
        <br /><b>{{ erxData.ignition_state }}</b>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="3" xl="3" align="left">
        <span class="secondary-text">Fuel Level</span>
        <br /><b>{{ `${erxData.fuel_lvl} %` || '—' }}</b>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="3" xl="3" align="left">
        <span class="secondary-text">Current Weight</span>
        <br /><b>{{ erxData.weight }} {{ displayWeightUnits() }}</b>
      </v-col>
    </v-row>
    <v-row class="padding">
      <v-col cols="6" sm="4" md="4" lg="3" xl="3" align="left">
        <span class="secondary-text">VIN</span> <br /><b>{{ erxData.vin }}</b>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="3" xl="3" align="left">
        <span class="secondary-text">Position</span>
        <br /><b>{{ erxData.position }}</b>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="3" xl="3" align="left">
        <span class="secondary-text">EV Range</span>
        <br /><b>{{ erxData.ev_range }} {{ displayDistanceUnits() }}</b>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="3" xl="3" align="left">
        <span class="secondary-text">Plugged In</span>
        <br /><b>{{ erxData.plugged_in }}</b>
      </v-col>
    </v-row>
    <v-row class="padding">
      <v-col cols="6" sm="4" md="4" lg="3" xl="3" align="left">
        <span class="secondary-text">Fuel Type</span> <br /><b>{{
          erxData.fuel_type
        }}</b>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="3" xl="3" align="left">
        <span class="secondary-text">Current Address</span>
        <br /><b>{{ erxData.address || '—' }}</b>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="3" xl="3" align="left">
        <span class="secondary-text">Fuel Range</span>
        <br /><b>{{ erxData.fuel_range }} {{ displayDistanceUnits() }}</b>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="3" xl="3" align="left">
        <span class="secondary-text">Retarder Level</span>
        <br /><b>{{ erxData.retarder_level }}</b>
      </v-col>
    </v-row>
    <v-row class="padding">
      <v-col cols="6" sm="4" md="4" lg="3" xl="3" align="left">
        <span class="secondary-text">Truck Type</span> <br /><b>{{
          erxData.truck_type
        }}</b>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="3" xl="3" align="left">
        <span class="secondary-text">Hotel Hours</span>
        <br /><b>{{ erxData.hotel_hours }} Hours</b>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="3" xl="3" align="left">
        <span class="secondary-text">Cruise Control Active</span>
        <br /><b>{{ erxData.cruisectrlactive }}</b>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="3" xl="3" align="left">
        <span class="secondary-text">Cruise Control Speed</span>
        <br /><b>{{ erxData.cruisectrlsetspeed }} {{ displaySpeedUnits() }}</b>
      </v-col>
    </v-row>
    <v-row class="padding">
      <v-col cols="6" sm="4" md="4" lg="3" xl="3" align="left">
        <span class="secondary-text">Current SOC</span>
        <br /><b>{{ `${erxData.SOC} %` }}</b>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="3" xl="3" align="left"> </v-col>
      <v-col cols="6" sm="4" md="4" lg="3" xl="3" align="left"> </v-col>
      <v-col cols="6" sm="4" md="4" lg="3" xl="3" align="left"> </v-col>
    </v-row>
  </v-container>
</template>
<script>
import IconLoader from '@/utilities/IconLoader';
import { mapGetters } from 'vuex';
export default {
  name: 'ERXDetails',
  components: {
    'icon-loader': IconLoader,
  },
  props: {
    erxData: {
      type: Object,
      required: true,
    },
    icons: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      userPreferences: 'getUserPreferences',
    }),
  },
  methods: {
    displayDistanceUnits() {
      if (this.userPreferences.regionPref === 'us/imperial') {
        return 'mi';
      } else {
        return 'km';
      }
    },
    displayWeightUnits() {
      if (this.userPreferences.regionPref === 'us/imperial') {
        return 'lbs';
      } else {
        return 'kg';
      }
    },
    displaySpeedUnits() {
      if (this.userPreferences.regionPref === 'us/imperial') {
        return 'mph';
      } else {
        return 'km/h';
      }
    },
  },
};
</script>
<style scoped>
.padding {
  padding: 1rem;
}
.secondary-text {
  font-weight: 100;
  opacity: 0.7;
}
.border {
  border-bottom: 1px solid #e0e0e0;
}
</style>
