<template>
  <v-dialog v-model="dialog" width="550" persistent>
    <v-card class="pa-4 text-center">
      <v-row align="center">
        <v-col align="left" cols="4"><h3>Live Fleet View</h3></v-col>
        <v-col><v-spacer /></v-col>
        <v-col align="right" cols="2">
          <v-btn icon @click="$emit('close')">
            <v-icon color="#979797">mdi-close </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col align="center" class="pb-0">
          <span class="text-h6">Choose a fleet to use live fleet view.</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-select
            v-model="selectedCompany"
            :items="filteredCompanies"
            item-text="name"
            item-value="id"
            return-object
            clearable
            outlined
            hide-selected
            label="Select Fleet"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <!-- <v-col>
          <v-btn color="primary" class="mr-2" outlined @click="$emit('close')"
            >Dismiss</v-btn
          >
        </v-col> -->
        <v-col class="mb-1 pt-0">
          <v-btn
            color="primary"
            width="100%"
            style="color: black"
            @click="changeCompany()"
            >Activate View</v-btn
          >
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'LiveFleetView',
  data() {
    return {
      selectedCompany: null,
      dialog: true,
      filteredCompanies: [],
    };
  },
  computed: {
    ...mapGetters({
      companies: 'getCompanies',
      getSelectedFleet: 'getSelectedLiveFleet',
    }),
  },
  mounted() {
    this.filteredCompanies = this.companies.filter((c) => c.id !== 4); // Remove Hyliion as an option
    if (this.getSelectedFleet) {
      this.selectedCompany = this.getSelectedFleet;
    }
  },
  methods: {
    ...mapMutations(['assignSelectedLiveFleet']),
    changeCompany() {
      this.assignSelectedLiveFleet(this.selectedCompany); // Update vuex global state
      // Refresh Page here to ensure the page the user is on doesnt break if the selected company does not have access to it
      location.reload();
    },
  },
};
</script>

<style scoped></style>
