var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c("v-card-title", [_vm._v(_vm._s(_vm.headerName))]),
      _c("v-card-text"),
      _c("v-divider"),
      _c(
        "v-container",
        [
          _c(
            "v-row",
            { staticClass: "mr-3" },
            [_c("v-col", [_c("h3", [_vm._v("Test Description")])])],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-col", { staticClass: "formLabel", attrs: { sm: "3" } }, [
                _vm._v("Pre Conditions: "),
              ]),
              _c(
                "v-col",
                { attrs: { sm: "9" } },
                [
                  _c("v-textarea", {
                    attrs: {
                      outlined: "",
                      name: "input-7-4",
                      value: "",
                      disabled: "",
                    },
                    model: {
                      value: _vm.preConditions,
                      callback: function ($$v) {
                        _vm.preConditions = $$v
                      },
                      expression: "preConditions",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-col", { staticClass: "formLabel", attrs: { sm: "3" } }, [
                _vm._v("Procedure: "),
              ]),
              _c(
                "v-col",
                { attrs: { sm: "9" } },
                [
                  _c("v-textarea", {
                    attrs: {
                      outlined: "",
                      name: "input-7-4",
                      value: "",
                      disabled: "",
                    },
                    model: {
                      value: _vm.testProcedure,
                      callback: function ($$v) {
                        _vm.testProcedure = $$v
                      },
                      expression: "testProcedure",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-col", { staticClass: "formLabel", attrs: { sm: "3" } }, [
                _vm._v("Expected Results: "),
              ]),
              _c(
                "v-col",
                { attrs: { sm: "9" } },
                [
                  _c("v-textarea", {
                    attrs: {
                      outlined: "",
                      name: "input-7-4",
                      value: "",
                      disabled: "",
                    },
                    model: {
                      value: _vm.expResults,
                      callback: function ($$v) {
                        _vm.expResults = $$v
                      },
                      expression: "expResults",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mr-3" },
            [_c("v-col", [_c("h3", [_vm._v("Previous Results")])])],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mr-3" },
            [
              _c(
                "v-col",
                { attrs: { sm: "12" } },
                [
                  _c("v-data-table", {
                    key: _vm.dataKey,
                    staticClass:
                      "elevation-1 secondary locked-table-style row-height-50",
                    attrs: {
                      headers: _vm.previousHeaders,
                      items: _vm.previousTests,
                      "item-key": "id",
                    },
                    on: { "click:row": _vm.loadResult },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mr-3" },
            [_c("v-col", [_c("h3", [_vm._v("Current Test Results")])])],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-col", { staticClass: "formLabel", attrs: { sm: "3" } }, [
                _vm._v("Start Date: "),
              ]),
              _c(
                "v-col",
                { attrs: { sm: "9" } },
                [
                  _c("v-text-field", {
                    directives: [
                      {
                        name: "mask",
                        rawName: "v-mask",
                        value: "##/##/####",
                        expression: "'##/##/####'",
                      },
                    ],
                    staticClass: "formInput",
                    attrs: {
                      rules: _vm.startDateRules,
                      label: "Start Date (MM/DD/YYYY)",
                      type: "text",
                      outlined: "",
                      "append-icon": "mdi-calendar",
                      required: "",
                    },
                    model: {
                      value: _vm.startDate,
                      callback: function ($$v) {
                        _vm.startDate = $$v
                      },
                      expression: "startDate",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-col", { staticClass: "formLabel", attrs: { sm: "3" } }, [
                _vm._v("Status: "),
              ]),
              _c(
                "v-col",
                { attrs: { sm: "9" } },
                [
                  _c("v-select", {
                    staticClass: "formInput",
                    attrs: {
                      items: _vm.statusItems,
                      outlined: "",
                      label: "Choose Test Status",
                      required: "",
                    },
                    model: {
                      value: _vm.selectedStatus,
                      callback: function ($$v) {
                        _vm.selectedStatus = $$v
                      },
                      expression: "selectedStatus",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-col", { staticClass: "formLabel", attrs: { sm: "3" } }, [
                _vm._v("Additional Info: "),
              ]),
              _c(
                "v-col",
                { attrs: { sm: "9" } },
                [
                  _c("v-textarea", {
                    attrs: { outlined: "", value: "" },
                    model: {
                      value: _vm.additional_info,
                      callback: function ($$v) {
                        _vm.additional_info = $$v
                      },
                      expression: "additional_info",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card-actions",
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            { attrs: { color: "red", text: "" }, on: { click: _vm.cancel } },
            [_vm._v(" Close ")]
          ),
          _c(
            "v-btn",
            {
              attrs: { color: _vm.btnSaveColor },
              on: { click: _vm.saveTestResults },
            },
            [_vm._v(_vm._s(_vm.btnSaveText))]
          ),
          _c(
            "v-btn",
            { attrs: { color: "blue" }, on: { click: _vm.clearForm } },
            [_vm._v("Reset")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }