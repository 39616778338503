<template>
  <div>
    <v-progress-circular
      v-if="loading"
      indeterminate
      color="primary"
      class="center"
    ></v-progress-circular>
    <div v-if="chartsReady">
      <!-- Usage Graphs -->
      <highcharts
        v-if="graphs.has('Usage')"
        id="chart1"
        ref="chart1"
        class="graph"
        :options="usageTimeOptions"
      ></highcharts>
      <highcharts
        v-if="graphs.has('Usage')"
        ref="chart2"
        class="graph"
        :options="distanceOptions"
      ></highcharts>
      <highcharts
        v-if="graphs.has('Usage')"
        ref="chart3"
        class="graph"
        :options="fuelTankOptions"
      ></highcharts>
      <highcharts
        v-if="graphs.has('Usage')"
        ref="chart4"
        class="graph"
        :options="fuelUsageOptions"
      ></highcharts>
      <highcharts
        v-if="graphs.has('Usage')"
        ref="chart5"
        class="graph"
        :options="temperatureOptions"
      ></highcharts>
      <highcharts
        v-if="graphs.has('Usage')"
        ref="chart6"
        class="graph"
        :options="weightOptions"
      ></highcharts>
      <highcharts
        v-if="graphs.has('Usage')"
        ref="chart7"
        class="graph"
        :options="accelOptions"
      ></highcharts>
      <!-- Cruise Control Graphs -->
      <highcharts
        v-if="graphs.has('Cruise Control')"
        id="chart8"
        ref="chart8"
        class="graph"
        :options="ccTimeOptions"
      ></highcharts>
      <highcharts
        v-if="graphs.has('Cruise Control')"
        ref="chart9"
        class="graph"
        :options="ccSpeedOptions"
      ></highcharts>
      <highcharts
        v-if="graphs.has('Cruise Control')"
        ref="chart10"
        class="graph"
        :options="ccUsageOptions"
      ></highcharts>
      <!-- BMS Graphs -->
      <highcharts
        v-if="graphs.has('BMS')"
        id="chart11"
        ref="chart11"
        class="graph"
        :options="socOptions"
      ></highcharts>
      <highcharts
        v-if="graphs.has('BMS')"
        ref="chart12"
        class="graph"
        :options="vcOptions"
      ></highcharts>
      <highcharts
        v-if="graphs.has('BMS')"
        ref="chart13"
        class="graph"
        :options="bmsTempOptions"
      ></highcharts>
      <!-- Axle Graphs -->
      <highcharts
        v-if="graphs.has('Axle')"
        id="chart14"
        ref="chart14"
        class="graph"
        :options="axTempOptions"
      ></highcharts>
      <highcharts
        v-if="graphs.has('Axle')"
        ref="chart15"
        class="graph"
        :options="axVoltOptions"
      ></highcharts>
      <highcharts
        v-if="graphs.has('Axle')"
        ref="chart16"
        class="graph"
        :options="axCurrentOptions"
      ></highcharts>
      <highcharts
        v-if="graphs.has('Retarder')"
        id="chart17"
        ref="chart17"
        class="graph"
        :options="rtTimeOptions"
      ></highcharts>
      <highcharts
        v-if="graphs.has('Retarder')"
        ref="chart18"
        class="graph"
        :options="rtDistanceOptions"
      ></highcharts>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { regionalSetting } from '@/utilities/userSettings';
import _ from 'lodash';
export default {
  props: ['loading', 'data', 'graphs'],
  data() {
    return {
      isMetric: true,
      chartsReady: false,
      zoomedChart: null,
      xMin: null,
      xMax: null,
      charts: [],
      usageTimeOptions: {
        chart: {
          backgroundColor: '#1E1E1E',
          type: 'column',
          zoomType: 'x',
          name: '',
          events: {
            selection: (e) => {
              const chartName = e.target.title.textStr;
              if (e.xAxis) {
                this.zoomedChart = chartName;
                this.xMin = e.xAxis[0].min;
                this.xMax = e.xAxis[0].max;
                this.zoomCharts(true);
              } else {
                this.zoomedChart = '';
                this.xMin = null;
                this.xMax = null;
                this.zoomCharts(false);
              }
            },
          },
        },
        rangeSelector: {
          selected: 1,
        },
        title: {
          text: 'Usage: Time',
          style: {
            color: '#FFFFFF',
          },
        },
        tooltip: {
          shared: true,
          backgroundColor: '#363636',
          borderRadius: 8,
          borderColor: '#363636',
          style: {
            color: '#FFFFFF',
          },
        },
        legend: {
          itemStyle: {
            color: '#FFFFFF',
            fontWeight: 'bold',
          },
        },
        plotOptions: {
          series: {
            stacking: 'normal',
            borderColor: '#1E1E1E',
          },
        },
        xAxis: {
          type: 'datetime',
          title: {
            text: 'Time',
            style: {
              color: '#FFFFFF',
            },
          },
          labels: {
            step: 1,
            style: {
              color: '#FFFFFF',
            },
          },
          lineColor: 'rgba(255, 255, 255, 0.24)',
        },
        yAxis: [
          {
            gridLineColor: ' rgba(255, 255, 255, 0.12)',
            title: {
              text: 'Duration (hrs)',
            },
            labels: {
              format: '{value}',
              style: {
                color: 'white',
              },
            },
          },
        ],
        setOptions: {
          width: '100%',
        },
        series: [],
      },
      distanceOptions: {
        chart: {
          backgroundColor: '#1E1E1E',
          type: 'column',
          zoomType: 'x',
          name: '',
          events: {
            selection: (e) => {
              const chartName = e.target.title.textStr;
              if (e.xAxis) {
                this.zoomedChart = chartName;
                this.xMin = e.xAxis[0].min;
                this.xMax = e.xAxis[0].max;
                this.zoomCharts(true);
              } else {
                this.zoomedChart = '';
                this.xMin = null;
                this.xMax = null;
                this.zoomCharts(false);
              }
            },
          },
        },
        rangeSelector: {
          selected: 1,
        },
        title: {
          text: 'Usage: Distance',
          style: {
            color: '#FFFFFF',
          },
        },
        tooltip: {
          shared: true,
          backgroundColor: '#363636',
          borderRadius: 8,
          borderColor: '#363636',
          style: {
            color: '#FFFFFF',
          },
        },
        legend: {
          itemStyle: {
            color: '#FFFFFF',
            fontWeight: 'bold',
          },
        },
        plotOptions: {
          series: {
            stacking: 'normal',
            borderColor: '#1E1E1E',
          },
        },
        xAxis: {
          type: 'datetime',
          title: {
            text: 'Time',
            style: {
              color: '#FFFFFF',
            },
          },
          labels: {
            step: 1,
            style: {
              color: '#FFFFFF',
            },
          },
          lineColor: 'rgba(255, 255, 255, 0.24)',
        },
        yAxis: [
          {
            gridLineColor: ' rgba(255, 255, 255, 0.12)',
            title: {
              text: 'Distance km',
            },
            labels: {
              format: '{value}',
              style: {
                color: 'white',
              },
            },
          },
        ],
        setOptions: {
          width: '100%',
        },
        series: [],
      },
      fuelTankOptions: {
        chart: {
          backgroundColor: '#1E1E1E',
          type: 'line',
          zoomType: 'x',
          name: '',
          events: {
            selection: (e) => {
              const chartName = e.target.title.textStr;
              if (e.xAxis) {
                this.zoomedChart = chartName;
                this.xMin = e.xAxis[0].min;
                this.xMax = e.xAxis[0].max;
                this.zoomCharts(true);
              } else {
                this.zoomedChart = '';
                this.xMin = null;
                this.xMax = null;
                this.zoomCharts(false);
              }
            },
          },
        },
        rangeSelector: {
          selected: 1,
        },
        title: {
          text: 'Usage: Fuel Tank',
          style: {
            color: '#FFFFFF',
          },
        },
        tooltip: {
          shared: true,
          backgroundColor: '#363636',
          borderRadius: 8,
          borderColor: '#363636',
          style: {
            color: '#FFFFFF',
          },
        },
        legend: {
          itemStyle: {
            color: '#FFFFFF',
            fontWeight: 'bold',
          },
        },
        xAxis: {
          type: 'datetime',
          title: {
            text: 'Time',
            style: {
              color: '#FFFFFF',
            },
          },
          labels: {
            step: 1,
            style: {
              color: '#FFFFFF',
            },
          },
          gridLineColor: 'red',
          lineColor: 'rgba(255, 255, 255, 0.12)',
        },
        yAxis: [
          {
            labels: {
              format: '{value}',
              style: {
                color: 'white',
              },
            },
            title: {
              text: 'Fuel Level (%)',
            },
            gridLineColor: 'rgba(255, 255, 255, 0.12)',
          },
          {
            labels: {
              format: '{value}',
              style: {
                color: 'white',
              },
            },
            title: {
              text: 'Count',
            },
            gridLineColor: 'rgba(255, 255, 255, 0.12)',
            opposite: true,
          },
        ],
        setOptions: {
          width: '100%',
        },
        series: [],
      },
      fuelUsageOptions: {
        chart: {
          backgroundColor: '#1E1E1E',
          type: 'line',
          zoomType: 'x',
          name: '',
          events: {
            selection: (e) => {
              const chartName = e.target.title.textStr;
              if (e.xAxis) {
                this.zoomedChart = chartName;
                this.xMin = e.xAxis[0].min;
                this.xMax = e.xAxis[0].max;
                this.zoomCharts(true);
              } else {
                this.zoomedChart = '';
                this.xMin = null;
                this.xMax = null;
                this.zoomCharts(false);
              }
            },
          },
        },
        rangeSelector: {
          selected: 1,
        },
        title: {
          text: 'Usage: Fuel',
          style: {
            color: '#FFFFFF',
          },
        },
        tooltip: {
          shared: true,
          backgroundColor: '#363636',
          borderRadius: 8,
          borderColor: '#363636',
          style: {
            color: '#FFFFFF',
          },
        },
        legend: {
          itemStyle: {
            color: '#FFFFFF',
            fontWeight: 'bold',
          },
        },
        xAxis: {
          type: 'datetime',
          title: {
            text: 'Time',
            style: {
              color: '#FFFFFF',
            },
          },
          labels: {
            step: 1,
            style: {
              color: '#FFFFFF',
            },
          },
          gridLineColor: 'red',
          lineColor: 'rgba(255, 255, 255, 0.12)',
        },
        yAxis: [
          {
            labels: {
              format: '{value}',
              style: {
                color: 'white',
              },
            },
            title: {
              text: 'Fuel Usage kg',
            },
            gridLineColor: 'rgba(255, 255, 255, 0.12)',
          },
          {
            labels: {
              format: '{value}',
              style: {
                color: 'white',
              },
            },
            title: {
              text: 'km / DLE',
            },
            gridLineColor: 'rgba(255, 255, 255, 0.12)',
            opposite: true,
          },
        ],
        setOptions: {
          width: '100%',
        },
        series: [],
      },
      temperatureOptions: {
        chart: {
          backgroundColor: '#1E1E1E',
          type: 'line',
          zoomType: 'x',
          name: '',
          events: {
            selection: (e) => {
              const chartName = e.target.title.textStr;
              if (e.xAxis) {
                this.zoomedChart = chartName;
                this.xMin = e.xAxis[0].min;
                this.xMax = e.xAxis[0].max;
                this.zoomCharts(true);
              } else {
                this.zoomedChart = '';
                this.xMin = null;
                this.xMax = null;
                this.zoomCharts(false);
              }
            },
          },
        },
        rangeSelector: {
          selected: 1,
        },
        title: {
          text: 'Usage: Temperature',
          style: {
            color: '#FFFFFF',
          },
        },
        tooltip: {
          shared: true,
          backgroundColor: '#363636',
          borderRadius: 8,
          borderColor: '#363636',
          style: {
            color: '#FFFFFF',
          },
        },
        legend: {
          itemStyle: {
            color: '#FFFFFF',
            fontWeight: 'bold',
          },
        },
        xAxis: {
          type: 'datetime',
          title: {
            text: 'Time',
            style: {
              color: '#FFFFFF',
            },
          },
          labels: {
            step: 1,
            style: {
              color: '#FFFFFF',
            },
          },
          gridLineColor: 'red',
          lineColor: 'rgba(255, 255, 255, 0.12)',
        },
        yAxis: [
          {
            labels: {
              format: '{value}',
              style: {
                color: 'white',
              },
            },
            title: {
              text: 'Temperature °C',
            },
            gridLineColor: 'rgba(255, 255, 255, 0.12)',
          },
        ],
        setOptions: {
          width: '100%',
        },
        series: [],
      },
      weightOptions: {
        chart: {
          backgroundColor: '#1E1E1E',
          type: 'line',
          zoomType: 'x',
          name: '',
          events: {
            selection: (e) => {
              const chartName = e.target.title.textStr;
              if (e.xAxis) {
                this.zoomedChart = chartName;
                this.xMin = e.xAxis[0].min;
                this.xMax = e.xAxis[0].max;
                this.zoomCharts(true);
              } else {
                this.zoomedChart = '';
                this.xMin = null;
                this.xMax = null;
                this.zoomCharts(false);
              }
            },
          },
        },
        rangeSelector: {
          selected: 1,
        },
        title: {
          text: 'Usage: Weight',
          style: {
            color: '#FFFFFF',
          },
        },
        tooltip: {
          shared: true,
          backgroundColor: '#363636',
          borderRadius: 8,
          borderColor: '#363636',
          style: {
            color: '#FFFFFF',
          },
        },
        legend: {
          itemStyle: {
            color: '#FFFFFF',
            fontWeight: 'bold',
          },
        },
        xAxis: {
          type: 'datetime',
          title: {
            text: 'Time',
            style: {
              color: '#FFFFFF',
            },
          },
          labels: {
            step: 1,
            style: {
              color: '#FFFFFF',
            },
          },
          gridLineColor: 'red',
          lineColor: 'rgba(255, 255, 255, 0.12)',
        },
        yAxis: [
          {
            labels: {
              format: '{value}',
              style: {
                color: 'white',
              },
            },
            title: {
              text: 'Weight kg',
            },
            gridLineColor: 'rgba(255, 255, 255, 0.12)',
          },
          {
            labels: {
              format: '{value}',
              style: {
                color: 'white',
              },
            },
            title: {
              text: 'Ton Kilometers',
            },
            gridLineColor: 'rgba(255, 255, 255, 0.12)',
            opposite: true,
          },
        ],
        setOptions: {
          width: '100%',
        },
        series: [],
      },
      accelOptions: {
        chart: {
          backgroundColor: '#1E1E1E',
          type: 'line',
          zoomType: 'x',
          name: '',
          events: {
            selection: (e) => {
              const chartName = e.target.title.textStr;
              if (e.xAxis) {
                this.zoomedChart = chartName;
                this.xMin = e.xAxis[0].min;
                this.xMax = e.xAxis[0].max;
                this.zoomCharts(true);
              } else {
                this.zoomedChart = '';
                this.xMin = null;
                this.xMax = null;
                this.zoomCharts(false);
              }
            },
          },
        },
        rangeSelector: {
          selected: 1,
        },
        title: {
          text: 'Usage: Acceleration',
          style: {
            color: '#FFFFFF',
          },
        },
        tooltip: {
          shared: true,
          backgroundColor: '#363636',
          borderRadius: 8,
          borderColor: '#363636',
          style: {
            color: '#FFFFFF',
          },
        },
        legend: {
          itemStyle: {
            color: '#FFFFFF',
            fontWeight: 'bold',
          },
        },
        xAxis: {
          type: 'datetime',
          title: {
            text: 'Time',
            style: {
              color: '#FFFFFF',
            },
          },
          labels: {
            step: 1,
            style: {
              color: '#FFFFFF',
            },
          },
          gridLineColor: 'red',
          lineColor: 'rgba(255, 255, 255, 0.12)',
        },
        yAxis: [
          {
            labels: {
              format: '{value}',
              style: {
                color: 'white',
              },
            },
            title: {
              text: 'Acceleration m/s^2',
            },
            gridLineColor: 'rgba(255, 255, 255, 0.12)',
          },
          {
            labels: {
              format: '{value}',
              style: {
                color: 'white',
              },
            },
            title: {
              text: 'Seconds',
            },
            gridLineColor: 'rgba(255, 255, 255, 0.12)',
            opposite: true,
          },
        ],
        setOptions: {
          width: '100%',
        },
        series: [],
      },
      ccTimeOptions: {
        chart: {
          backgroundColor: '#1E1E1E',
          type: 'column',
          zoomType: 'x',
          name: '',
          events: {
            selection: (e) => {
              const chartName = e.target.title.textStr;
              if (e.xAxis) {
                this.zoomedChart = chartName;
                this.xMin = e.xAxis[0].min;
                this.xMax = e.xAxis[0].max;
                this.zoomCharts(true);
              } else {
                this.zoomedChart = '';
                this.xMin = null;
                this.xMax = null;
                this.zoomCharts(false);
              }
            },
          },
        },
        rangeSelector: {
          selected: 1,
        },
        title: {
          text: 'Cruise Control: Time',
          style: {
            color: '#FFFFFF',
          },
        },
        tooltip: {
          shared: true,
          backgroundColor: '#363636',
          borderRadius: 8,
          borderColor: '#363636',
          style: {
            color: '#FFFFFF',
          },
        },
        legend: {
          itemStyle: {
            color: '#FFFFFF',
            fontWeight: 'bold',
          },
        },
        plotOptions: {
          series: {
            stacking: 'normal',
            borderColor: '#1E1E1E',
          },
        },
        xAxis: {
          type: 'datetime',
          title: {
            text: 'Time',
            style: {
              color: '#FFFFFF',
            },
          },
          labels: {
            step: 1,
            style: {
              color: '#FFFFFF',
            },
          },
          lineColor: 'rgba(255, 255, 255, 0.24)',
        },
        yAxis: [
          {
            gridLineColor: ' rgba(255, 255, 255, 0.12)',
            title: {
              text: 'Duration (hrs)',
            },
            labels: {
              format: '{value}',
              style: {
                color: 'white',
              },
            },
          },
        ],
        setOptions: {
          width: '100%',
        },
        series: [],
      },
      ccSpeedOptions: {
        chart: {
          backgroundColor: '#1E1E1E',
          type: 'line',
          zoomType: 'x',
          name: '',
          events: {
            selection: (e) => {
              const chartName = e.target.title.textStr;
              if (e.xAxis) {
                this.zoomedChart = chartName;
                this.xMin = e.xAxis[0].min;
                this.xMax = e.xAxis[0].max;
                this.zoomCharts(true);
              } else {
                this.zoomedChart = '';
                this.xMin = null;
                this.xMax = null;
                this.zoomCharts(false);
              }
            },
          },
        },
        rangeSelector: {
          selected: 1,
        },
        title: {
          text: 'Cruise Control: Speed',
          style: {
            color: '#FFFFFF',
          },
        },
        tooltip: {
          shared: true,
          backgroundColor: '#363636',
          borderRadius: 8,
          borderColor: '#363636',
          style: {
            color: '#FFFFFF',
          },
        },
        legend: {
          itemStyle: {
            color: '#FFFFFF',
            fontWeight: 'bold',
          },
        },
        xAxis: {
          type: 'datetime',
          title: {
            text: 'Time',
            style: {
              color: '#FFFFFF',
            },
          },
          labels: {
            step: 1,
            style: {
              color: '#FFFFFF',
            },
          },
          gridLineColor: 'red',
          lineColor: 'rgba(255, 255, 255, 0.12)',
        },
        yAxis: [
          {
            labels: {
              format: '{value}',
              style: {
                color: 'white',
              },
            },
            title: {
              text: 'Speed kph',
            },
            gridLineColor: 'rgba(255, 255, 255, 0.12)',
          },
        ],
        setOptions: {
          width: '100%',
        },
        series: [],
      },
      ccUsageOptions: {
        chart: {
          backgroundColor: '#1E1E1E',
          type: 'line',
          zoomType: 'x',
          name: '',
          events: {
            selection: (e) => {
              const chartName = e.target.title.textStr;
              if (e.xAxis) {
                this.zoomedChart = chartName;
                this.xMin = e.xAxis[0].min;
                this.xMax = e.xAxis[0].max;
                this.zoomCharts(true);
              } else {
                this.zoomedChart = '';
                this.xMin = null;
                this.xMax = null;
                this.zoomCharts(false);
              }
            },
          },
        },
        rangeSelector: {
          selected: 1,
        },
        title: {
          text: 'Cruise Control: Usage',
          style: {
            color: '#FFFFFF',
          },
        },
        tooltip: {
          shared: true,
          backgroundColor: '#363636',
          borderRadius: 8,
          borderColor: '#363636',
          style: {
            color: '#FFFFFF',
          },
        },
        legend: {
          itemStyle: {
            color: '#FFFFFF',
            fontWeight: 'bold',
          },
        },
        xAxis: {
          type: 'datetime',
          title: {
            text: 'Time',
            style: {
              color: '#FFFFFF',
            },
          },
          labels: {
            step: 1,
            style: {
              color: '#FFFFFF',
            },
          },
          gridLineColor: 'red',
          lineColor: 'rgba(255, 255, 255, 0.12)',
        },
        yAxis: [
          {
            labels: {
              format: '{value}%',
              style: {
                color: 'white',
              },
            },
            title: {
              text: 'Percent',
            },
            gridLineColor: 'rgba(255, 255, 255, 0.12)',
          },
        ],
        setOptions: {
          width: '100%',
        },
        series: [],
      },
      socOptions: {
        chart: {
          backgroundColor: '#1E1E1E',
          type: 'line',
          zoomType: 'x',
          name: '',
          events: {
            selection: (e) => {
              const chartName = e.target.title.textStr;
              if (e.xAxis) {
                this.zoomedChart = chartName;
                this.xMin = e.xAxis[0].min;
                this.xMax = e.xAxis[0].max;
                this.zoomCharts(true);
              } else {
                this.zoomedChart = '';
                this.xMin = null;
                this.xMax = null;
                this.zoomCharts(false);
              }
            },
          },
        },
        rangeSelector: {
          selected: 1,
        },
        title: {
          text: 'BMS: SOC',
          style: {
            color: '#FFFFFF',
          },
        },
        tooltip: {
          shared: true,
          backgroundColor: '#363636',
          borderRadius: 8,
          borderColor: '#363636',
          style: {
            color: '#FFFFFF',
          },
        },
        legend: {
          itemStyle: {
            color: '#FFFFFF',
            fontWeight: 'bold',
          },
        },
        xAxis: {
          type: 'datetime',
          title: {
            text: 'Time',
            style: {
              color: '#FFFFFF',
            },
          },
          labels: {
            step: 1,
            style: {
              color: '#FFFFFF',
            },
          },
          gridLineColor: 'red',
          lineColor: 'rgba(255, 255, 255, 0.12)',
        },
        yAxis: [
          {
            labels: {
              format: '{value}',
              style: {
                color: 'white',
              },
            },
            title: {
              text: 'SOC (%)',
            },
            gridLineColor: 'rgba(255, 255, 255, 0.12)',
          },
        ],
        setOptions: {
          width: '100%',
        },
        series: [],
      },
      vcOptions: {
        chart: {
          backgroundColor: '#1E1E1E',
          type: 'line',
          zoomType: 'x',
          name: '',
          events: {
            selection: (e) => {
              const chartName = e.target.title.textStr;
              if (e.xAxis) {
                this.zoomedChart = chartName;
                this.xMin = e.xAxis[0].min;
                this.xMax = e.xAxis[0].max;
                this.zoomCharts(true);
              } else {
                this.zoomedChart = '';
                this.xMin = null;
                this.xMax = null;
                this.zoomCharts(false);
              }
            },
          },
        },
        rangeSelector: {
          selected: 1,
        },
        title: {
          text: 'BMS: Voltage & Current',
          style: {
            color: '#FFFFFF',
          },
        },
        tooltip: {
          shared: true,
          backgroundColor: '#363636',
          borderRadius: 8,
          borderColor: '#363636',
          style: {
            color: '#FFFFFF',
          },
        },
        legend: {
          itemStyle: {
            color: '#FFFFFF',
            fontWeight: 'bold',
          },
        },
        xAxis: {
          type: 'datetime',
          title: {
            text: 'Time',
            style: {
              color: '#FFFFFF',
            },
          },
          labels: {
            step: 1,
            style: {
              color: '#FFFFFF',
            },
          },
          gridLineColor: 'red',
          lineColor: 'rgba(255, 255, 255, 0.12)',
        },
        yAxis: [
          {
            labels: {
              format: '{value}',
              style: {
                color: 'white',
              },
            },
            title: {
              text: 'Voltage (V)',
            },
            gridLineColor: 'rgba(255, 255, 255, 0.12)',
          },
          {
            labels: {
              format: '{value}',
              style: {
                color: 'white',
              },
            },
            title: {
              text: 'Current (A)',
            },
            gridLineColor: 'rgba(255, 255, 255, 0.12)',
            opposite: true,
          },
        ],
        setOptions: {
          width: '100%',
        },
        series: [],
      },
      bmsTempOptions: {
        chart: {
          backgroundColor: '#1E1E1E',
          type: 'line',
          zoomType: 'x',
          name: '',
          events: {
            selection: (e) => {
              const chartName = e.target.title.textStr;
              if (e.xAxis) {
                this.zoomedChart = chartName;
                this.xMin = e.xAxis[0].min;
                this.xMax = e.xAxis[0].max;
                this.zoomCharts(true);
              } else {
                this.zoomedChart = '';
                this.xMin = null;
                this.xMax = null;
                this.zoomCharts(false);
              }
            },
          },
        },
        rangeSelector: {
          selected: 1,
        },
        title: {
          text: 'BMS: Temperature',
          style: {
            color: '#FFFFFF',
          },
        },
        tooltip: {
          shared: true,
          backgroundColor: '#363636',
          borderRadius: 8,
          borderColor: '#363636',
          style: {
            color: '#FFFFFF',
          },
        },
        legend: {
          itemStyle: {
            color: '#FFFFFF',
            fontWeight: 'bold',
          },
        },
        xAxis: {
          type: 'datetime',
          title: {
            text: 'Time',
            style: {
              color: '#FFFFFF',
            },
          },
          labels: {
            step: 1,
            style: {
              color: '#FFFFFF',
            },
          },
          gridLineColor: 'red',
          lineColor: 'rgba(255, 255, 255, 0.12)',
        },
        yAxis: [
          {
            labels: {
              format: '{value}',
              style: {
                color: 'white',
              },
            },
            title: {
              text: 'Temperature °C',
            },
            gridLineColor: 'rgba(255, 255, 255, 0.12)',
          },
        ],
        setOptions: {
          width: '100%',
        },
        series: [],
      },
      axTempOptions: {
        chart: {
          backgroundColor: '#1E1E1E',
          type: 'line',
          zoomType: 'x',
          name: '',
          events: {
            selection: (e) => {
              const chartName = e.target.title.textStr;
              if (e.xAxis) {
                this.zoomedChart = chartName;
                this.xMin = e.xAxis[0].min;
                this.xMax = e.xAxis[0].max;
                this.zoomCharts(true);
              } else {
                this.zoomedChart = '';
                this.xMin = null;
                this.xMax = null;
                this.zoomCharts(false);
              }
            },
          },
        },
        rangeSelector: {
          selected: 1,
        },
        title: {
          text: 'Axle: Temperature',
          style: {
            color: '#FFFFFF',
          },
        },
        tooltip: {
          shared: true,
          backgroundColor: '#363636',
          borderRadius: 8,
          borderColor: '#363636',
          style: {
            color: '#FFFFFF',
          },
        },
        legend: {
          itemStyle: {
            color: '#FFFFFF',
            fontWeight: 'bold',
          },
        },
        xAxis: {
          type: 'datetime',
          title: {
            text: 'Time',
            style: {
              color: '#FFFFFF',
            },
          },
          labels: {
            step: 1,
            style: {
              color: '#FFFFFF',
            },
          },
          gridLineColor: 'red',
          lineColor: 'rgba(255, 255, 255, 0.12)',
        },
        yAxis: [
          {
            labels: {
              format: '{value}',
              style: {
                color: 'white',
              },
            },
            title: {
              text: 'Temperature °C',
            },
            gridLineColor: 'rgba(255, 255, 255, 0.12)',
          },
        ],
        setOptions: {
          width: '100%',
        },
        series: [],
      },
      axVoltOptions: {
        chart: {
          backgroundColor: '#1E1E1E',
          type: 'line',
          zoomType: 'x',
          name: '',
          events: {
            selection: (e) => {
              const chartName = e.target.title.textStr;
              if (e.xAxis) {
                this.zoomedChart = chartName;
                this.xMin = e.xAxis[0].min;
                this.xMax = e.xAxis[0].max;
                this.zoomCharts(true);
              } else {
                this.zoomedChart = '';
                this.xMin = null;
                this.xMax = null;
                this.zoomCharts(false);
              }
            },
          },
        },
        rangeSelector: {
          selected: 1,
        },
        title: {
          text: 'Axle: Voltage',
          style: {
            color: '#FFFFFF',
          },
        },
        tooltip: {
          shared: true,
          backgroundColor: '#363636',
          borderRadius: 8,
          borderColor: '#363636',
          style: {
            color: '#FFFFFF',
          },
        },
        legend: {
          itemStyle: {
            color: '#FFFFFF',
            fontWeight: 'bold',
          },
        },
        xAxis: {
          type: 'datetime',
          title: {
            text: 'Time',
            style: {
              color: '#FFFFFF',
            },
          },
          labels: {
            step: 1,
            style: {
              color: '#FFFFFF',
            },
          },
          gridLineColor: 'red',
          lineColor: 'rgba(255, 255, 255, 0.12)',
        },
        yAxis: [
          {
            labels: {
              format: '{value}',
              style: {
                color: 'white',
              },
            },
            title: {
              text: 'Voltage (V)',
            },
            gridLineColor: 'rgba(255, 255, 255, 0.12)',
          },
        ],
        setOptions: {
          width: '100%',
        },
        series: [],
      },
      axCurrentOptions: {
        chart: {
          backgroundColor: '#1E1E1E',
          type: 'line',
          zoomType: 'x',
          name: '',
          events: {
            selection: (e) => {
              const chartName = e.target.title.textStr;
              if (e.xAxis) {
                this.zoomedChart = chartName;
                this.xMin = e.xAxis[0].min;
                this.xMax = e.xAxis[0].max;
                this.zoomCharts(true);
              } else {
                this.zoomedChart = '';
                this.xMin = null;
                this.xMax = null;
                this.zoomCharts(false);
              }
            },
          },
        },
        rangeSelector: {
          selected: 1,
        },
        title: {
          text: 'Axle: Current',
          style: {
            color: '#FFFFFF',
          },
        },
        tooltip: {
          shared: true,
          backgroundColor: '#363636',
          borderRadius: 8,
          borderColor: '#363636',
          style: {
            color: '#FFFFFF',
          },
        },
        legend: {
          itemStyle: {
            color: '#FFFFFF',
            fontWeight: 'bold',
          },
        },
        xAxis: {
          type: 'datetime',
          title: {
            text: 'Time',
            style: {
              color: '#FFFFFF',
            },
          },
          labels: {
            step: 1,
            style: {
              color: '#FFFFFF',
            },
          },
          gridLineColor: 'red',
          lineColor: 'rgba(255, 255, 255, 0.12)',
        },
        yAxis: [
          {
            labels: {
              format: '{value}',
              style: {
                color: 'white',
              },
            },
            title: {
              text: 'Current (A)',
            },
            gridLineColor: 'rgba(255, 255, 255, 0.12)',
          },
        ],
        setOptions: {
          width: '100%',
        },
        series: [],
      },
      rtTimeOptions: {
        chart: {
          backgroundColor: '#1E1E1E',
          zoomType: 'x',
          name: '',
          events: {
            selection: (e) => {
              const chartName = e.target.title.textStr;
              if (e.xAxis) {
                this.zoomedChart = chartName;
                this.xMin = e.xAxis[0].min;
                this.xMax = e.xAxis[0].max;
                this.zoomCharts(true);
              } else {
                this.zoomedChart = '';
                this.xMin = null;
                this.xMax = null;
                this.zoomCharts(false);
              }
            },
          },
        },
        rangeSelector: {
          selected: 1,
        },
        title: {
          text: 'Retarder: Time',
          style: {
            color: '#FFFFFF',
          },
        },
        tooltip: {
          shared: true,
          backgroundColor: '#363636',
          borderRadius: 8,
          borderColor: '#363636',
          style: {
            color: '#FFFFFF',
          },
        },
        legend: {
          itemStyle: {
            color: '#FFFFFF',
            fontWeight: 'bold',
          },
        },
        plotOptions: {
          series: {
            stacking: 'normal',
            borderColor: '#1E1E1E',
          },
        },
        xAxis: {
          type: 'datetime',
          title: {
            text: 'Time',
            style: {
              color: '#FFFFFF',
            },
          },
          labels: {
            step: 1,
            style: {
              color: '#FFFFFF',
            },
          },
          lineColor: 'rgba(255, 255, 255, 0.24)',
        },
        yAxis: [
          {
            gridLineColor: ' rgba(255, 255, 255, 0.12)',
            title: {
              text: 'Duration (hrs)',
            },
            labels: {
              format: '{value}',
              style: {
                color: 'white',
              },
            },
          },
          {
            gridLineColor: ' rgba(255, 255, 255, 0.12)',
            title: {
              text: 'Count',
            },
            labels: {
              format: '{value}',
              style: {
                color: 'white',
              },
            },
            opposite: true,
          },
        ],
        setOptions: {
          width: '100%',
        },
        series: [],
      },
      rtDistanceOptions: {
        chart: {
          backgroundColor: '#1E1E1E',
          type: 'column',
          zoomType: 'x',
          name: '',
          events: {
            selection: (e) => {
              const chartName = e.target.title.textStr;
              if (e.xAxis) {
                this.zoomedChart = chartName;
                this.xMin = e.xAxis[0].min;
                this.xMax = e.xAxis[0].max;
                this.zoomCharts(true);
              } else {
                this.zoomedChart = '';
                this.xMin = null;
                this.xMax = null;
                this.zoomCharts(false);
              }
            },
          },
        },
        rangeSelector: {
          selected: 1,
        },
        title: {
          text: 'Retarder: Distance',
          style: {
            color: '#FFFFFF',
          },
        },
        tooltip: {
          shared: true,
          backgroundColor: '#363636',
          borderRadius: 8,
          borderColor: '#363636',
          style: {
            color: '#FFFFFF',
          },
        },
        legend: {
          itemStyle: {
            color: '#FFFFFF',
            fontWeight: 'bold',
          },
        },
        plotOptions: {
          series: {
            stacking: 'normal',
            borderColor: '#1E1E1E',
          },
        },
        xAxis: {
          type: 'datetime',
          title: {
            text: 'Time',
            style: {
              color: '#FFFFFF',
            },
          },
          labels: {
            step: 1,
            style: {
              color: '#FFFFFF',
            },
          },
          lineColor: 'rgba(255, 255, 255, 0.24)',
        },
        yAxis: [
          {
            gridLineColor: ' rgba(255, 255, 255, 0.12)',
            title: {
              text: 'Distance (km)',
            },
            labels: {
              format: '{value}',
              style: {
                color: 'white',
              },
            },
          },
          {
            gridLineColor: ' rgba(255, 255, 255, 0.12)',
            title: {
              text: 'Count',
            },
            labels: {
              format: '{value}',
              style: {
                color: 'white',
              },
            },
            opposite: true,
          },
        ],
        setOptions: {
          width: '100%',
        },
        series: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      userRoles: 'getUserRoles',
      userPreferences: 'getUserPreferences',
    }),
  },
  watch: {
    loading() {
      this.loading === false
        ? this.populateCharts()
        : (this.chartsReady = false);
    },
    chartsReady() {
      // takes a second after chart construction for refs to be populated
      const vm = this;
      if (this.chartsReady) {
        _.delay(() => vm.updateChartList(), 1000);
      }
    },
    // update chart list when graph selection is changed
    graphs() {
      const vm = this;
      if (this.chartsReady) {
        _.delay(() => vm.updateChartList(), 1000);
      }
    },
  },
  created() {
    this.isMetric = this.userPreferences.regionPref === 'metric' ? true : false;
  },
  mounted() {
    !this.loading ? this.populateCharts() : (this.chartsReady = false);
  },
  methods: {
    populateCharts() {
      // color arrays are based on a 5 color, 5 tone pallet by Johann; corresponding colors are in respective index order
      const colors = ['#DF1278', '#0070F2', '#188918', '#07838F', '#78909C'];
      const colors2 = ['#FA4F96', '#1B90FF', '#36A41D', '#04ACA7', '#90A4AE'];
      const colors3 = ['#FE83AE', '#4DB1FF', '#5DC122', '#00CEAC', '#B0BEC5'];
      const colors5 = ['#FECBDA', '#A6E0FF', '#BDE986', '#64EDD2', '#ECEFF1'];

      // RETARDER
      // Time
      let hrInCruise = [];
      let countRtrdLevelChange = [];
      let totalHrRtrdOff = [];
      let totalHrRtrd1 = [];
      let totalHrRtrd2 = [];
      let totalHrRtrdNA = [];
      // Distance
      let kmInCruise = []; // This will be pre-converted from trips based on User Prefs
      let totalKmRtrdOff = [];
      let totalKmRtrd1 = [];
      let totalKmRtrd2 = [];
      let totalKmRtrdNA = [];
      // Distance
      // AXLE
      // temperature - columns already declared
      // voltage
      let minAxleAVolt = [];
      let maxAxleAVolt = [];
      let minAxleBVolt = [];
      let maxAxleBVolt = [];
      // current
      let minAxleACurrent = [];
      let maxAxleACurrent = [];
      let minAxleBCurrent = [];
      let maxAxleBCurrent = [];
      // BMS
      // SOC
      let minSOC = [];
      let maxSOC = [];
      let avgSOC = [];
      // voltage & current
      let minPackVolt = [];
      let maxPackVolt = [];
      let minPackCurrent = [];
      let maxPackCurrent = [];
      // temperature
      let avgBatteryTemp = [];
      let avgIdleBatteryTemp = [];
      let maxIdleBatteryTemp = [];
      let avgMovingBatteryTemp = [];
      let maxMovingBatteryTemp = [];
      // CRUISE CONTROL
      // time
      let hrAboveSetSpeed = [];
      let hrBelowSetSpeed = [];
      let hrAtSetSpeed = [];
      // speed
      let minSetSpeed = [];
      let maxSetSpeed = [];
      let avgSetSpeed = [];
      // usage
      let pctKmInCruise = [];
      let pctKmAbove90KmpInCruise = [];
      // USAGE
      // Time
      let hrOff = [];
      let hrCrank = [];
      let hrAccessory = [];
      let hrIdle = [];
      let hrMoving = [];
      // Distance
      let kmAbove90Kph = []; // This will be pre-converted from trips based on User Prefs
      let kmBelow90Kph = [];
      // Fuel Tank
      let minFuel = [];
      let maxFuel = [];
      let avgAtRefill = [];
      let refillCount = [];
      // Fuel Usage
      let kgFuelUsed = [];
      let kgPer100km = [];
      let kmPerDLE = [];
      // Temperature
      let maxEngTemp = [];
      let maxBatteryTemp = [];
      let maxAxleAStatorTemp = [];
      let maxAxleARotorTemp = [];
      let maxAxleBStatorTemp = [];
      let maxAxleBRotorTemp = [];
      // Weight
      let minKgWeight = [];
      let maxKgWeight = [];
      let tkm = [];
      // Acceleration
      let maxAccel = []; // This will be pre-converted from trips based on User Prefs
      let maxDecel = [];
      let maxSecSustainedAccel = [];
      let maxSecSustainedDecel = [];

      function round(num) {
        if (num) return Math.round(num * 100) / 100;
        return num;
      }

      for (const row of this.data) {
        const timestamp = Date.parse(row.Day);
        // USAGE
        // time
        hrOff.push([timestamp, round(row.secOff / 3600)]);
        hrCrank.push([timestamp, round(row.secCrank / 3600)]);
        hrAccessory.push([timestamp, round(row.secAccessory / 3600)]);
        hrIdle.push([timestamp, round(row.secIdle / 3600)]);
        hrMoving.push([timestamp, round(row.secMoving / 3600)]);
        // distance
        kmAbove90Kph.push([timestamp, row.kmAbove90Kph]);
        kmBelow90Kph.push([
          timestamp,
          round(row.kmTraveled - row.kmAbove90Kph),
        ]);
        // fuel tank
        minFuel.push([timestamp, round(row.minFuel)]);
        maxFuel.push([timestamp, round(row.maxFuel)]);
        avgAtRefill.push([timestamp, round(row.avgAtRefill)]);
        refillCount.push([timestamp, round(row.refillCount)]);
        // fuel usage
        kgFuelUsed.push([
          timestamp,
          regionalSetting(
            this.userPreferences.regionPref,
            parseInt(row.kgFuelUsed),
            'weight_n',
            2
          ),
        ]);
        const operator = `${this.isMetric ? '*' : '/'}`;
        const operand1 = regionalSetting(
          this.userPreferences.regionPref,
          parseFloat(row.kgFuelUsed),
          'weight_n',
          2
        );
        const operand2 = regionalSetting(
          this.userPreferences.regionPref,
          row.kmTraveled,
          'distance',
          2
        );

        kgPer100km.push([
          timestamp,
          round(`${eval(operand1, operator, operand2)}` / 100),
        ]);
        kmPerDLE.push([
          timestamp,
          round(
            regionalSetting(
              this.userPreferences.regionPref,
              row.kmTraveled,
              'distance',
              2
            ) / row.DLE
          ),
        ]);
        // temperature
        maxEngTemp.push([
          timestamp,
          regionalSetting(
            this.userPreferences.regionPref,
            row.maxEngTemp,
            'temperature',
            0
          ),
        ]);
        maxBatteryTemp.push([
          timestamp,
          regionalSetting(
            this.userPreferences.regionPref,
            row.maxBatteryTemp,
            'temperature',
            0
          ),
        ]);
        // weight
        minKgWeight.push([
          timestamp,
          regionalSetting(
            this.userPreferences.regionPref,
            row.minKgWeight,
            'weight_n',
            2
          ),
        ]);
        maxKgWeight.push([
          timestamp,
          regionalSetting(
            this.userPreferences.regionPref,
            row.maxKgWeight,
            'weight_n',
            2
          ),
        ]);
        tkm.push([
          timestamp,
          regionalSetting(
            this.userPreferences.regionPref,
            row.tkm,
            'distance',
            2
          ),
        ]);
        // acceleration
        maxAccel.push([timestamp, row.maxAccel]);
        maxDecel.push([
          timestamp,
          regionalSetting(
            this.userPreferences.regionPref,
            row.maxDecel,
            'distance_s',
            2
          ),
        ]);
        maxSecSustainedAccel.push([timestamp, round(row.maxSecSustainedAccel)]);
        maxSecSustainedDecel.push([timestamp, round(row.maxSecSustainedDecel)]);
        // CRUISE CONTROL
        // time
        hrAboveSetSpeed.push([timestamp, round(row.secAboveSetSpeed / 3600)]);
        hrBelowSetSpeed.push([timestamp, round(row.secBelowSetSpeed / 3600)]);
        hrAtSetSpeed.push([
          timestamp,
          round(
            (row.secInCruise - row.secAboveSetSpeed - row.secBelowSetSpeed) /
              3600
          ),
        ]);
        // speed
        minSetSpeed.push([
          timestamp,
          regionalSetting(
            this.userPreferences.regionPref,
            row.minSetSpeed,
            'distance',
            0
          ),
        ]);
        maxSetSpeed.push([
          timestamp,
          regionalSetting(
            this.userPreferences.regionPref,
            row.maxSetSpeed,
            'distance',
            0
          ),
        ]);
        avgSetSpeed.push([
          timestamp,
          regionalSetting(
            this.userPreferences.regionPref,
            row.avgSetSpeed,
            'distance',
            0
          ),
        ]);
        // usage
        pctKmInCruise.push([
          timestamp,
          round(
            (row.kmInCruise /
              regionalSetting(
                this.userPreferences.regionPref,
                row.kmTraveled,
                'distance',
                2
              )) *
              100
          ),
        ]);
        pctKmAbove90KmpInCruise.push([
          timestamp,
          round(
            (regionalSetting(
              this.userPreferences.regionPref,
              row.kmInCruiseABove90Kph,
              'distance',
              2
            ) /
              row.kmAbove90Kph) *
              100
          ),
        ]);
        // BMS
        // soc
        minSOC.push([timestamp, round(row.minSOC)]);
        maxSOC.push([timestamp, round(row.maxSOC)]);
        avgSOC.push([timestamp, round(row.avgSOC)]);
        // voltage & current
        minPackVolt.push([timestamp, round(row.minPackVolt)]);
        maxPackVolt.push([timestamp, round(row.maxPackVolt)]);
        minPackCurrent.push([timestamp, round(row.minPackCurrent)]);
        maxPackCurrent.push([timestamp, round(row.maxPackCurrent)]);
        // temp
        avgBatteryTemp.push([
          timestamp,
          regionalSetting(
            this.userPreferences.regionPref,
            row.avgBatteryTemp,
            'temperature',
            0
          ),
        ]);
        avgIdleBatteryTemp.push([
          timestamp,
          regionalSetting(
            this.userPreferences.regionPref,
            row.avgIdleBatteryTemp,
            'temperature',
            0
          ),
        ]);
        maxIdleBatteryTemp.push([
          timestamp,
          regionalSetting(
            this.userPreferences.regionPref,
            row.maxIdleBatteryTemp,
            'temperature',
            0
          ),
        ]);
        avgMovingBatteryTemp.push([
          timestamp,
          regionalSetting(
            this.userPreferences.regionPref,
            row.avgMovingBatteryTemp,
            'temperature',
            0
          ),
        ]);
        maxMovingBatteryTemp.push([
          timestamp,
          regionalSetting(
            this.userPreferences.regionPref,
            row.maxMovingBatteryTemp,
            'temperature',
            0
          ),
        ]);
        // AXLE
        // temp
        maxAxleAStatorTemp.push([
          timestamp,
          regionalSetting(
            this.userPreferences.regionPref,
            row.maxAxleAStatorTemp,
            'temperature',
            0
          ),
        ]);
        maxAxleARotorTemp.push([
          timestamp,
          regionalSetting(
            this.userPreferences.regionPref,
            row.maxAxleARotorTemp,
            'temperature',
            0
          ),
        ]);
        maxAxleBStatorTemp.push([
          timestamp,
          regionalSetting(
            this.userPreferences.regionPref,
            row.maxAxleBStatorTemp,
            'temperature',
            0
          ),
        ]);
        maxAxleBRotorTemp.push([
          timestamp,
          regionalSetting(
            this.userPreferences.regionPref,
            row.maxAxleBRotorTemp,
            'temperature',
            0
          ),
        ]);
        // voltage
        minAxleAVolt.push([timestamp, round(row.minAxleAVolt)]);
        maxAxleAVolt.push([timestamp, round(row.maxAxleAVolt)]);
        minAxleBVolt.push([timestamp, round(row.minAxleBVolt)]);
        maxAxleBVolt.push([timestamp, round(row.maxAxleBVolt)]);
        // current
        minAxleACurrent.push([timestamp, round(row.minAxleACurrent)]);
        maxAxleACurrent.push([timestamp, round(row.maxAxleACurrent)]);
        minAxleBCurrent.push([timestamp, round(row.minAxleBCurrent)]);
        maxAxleBCurrent.push([timestamp, round(row.maxAxleBCurrent)]);
        // RETARDER
        // time
        hrInCruise.push([timestamp, round(row.secInCruise / 3600)]);
        countRtrdLevelChange.push([timestamp, round(row.countRtrdLevelChange)]);
        totalHrRtrdOff.push([timestamp, round(row.secIgnOnRetarderOff / 3600)]);
        totalHrRtrd1.push([timestamp, round(row.secIgnOnRetarder1 / 3600)]);
        totalHrRtrd2.push([timestamp, round(row.secIgnOnRetarder2 / 3600)]);
        totalHrRtrdNA.push([timestamp, round(row.secIgnOnRetarderNA / 3600)]);
        // distance
        kmInCruise.push([timestamp, round(row.kmInCruise)]);
        totalKmRtrdOff.push([
          timestamp,
          regionalSetting(
            this.userPreferences.regionPref,
            row.kmIgnOnRetarderOff,
            'distance',
            2
          ),
        ]);
        totalKmRtrd1.push([
          timestamp,
          regionalSetting(
            this.userPreferences.regionPref,
            row.kmIgnOnRetarder1,
            'distance',
            2
          ),
        ]);
        totalKmRtrd2.push([
          timestamp,
          regionalSetting(
            this.userPreferences.regionPref,
            row.kmIgnOnRetarder2,
            'distance',
            2
          ),
        ]);
        totalKmRtrdNA.push([
          timestamp,
          regionalSetting(
            this.userPreferences.regionPref,
            row.kmIgnOnRetarderNA,
            'distance',
            2
          ),
        ]);
      }
      // feed into highcharts
      // USAGE
      this.usageTimeOptions.series = [
        {
          type: 'column',
          name: 'Off',
          data: hrOff,
          color: colors[0],
          tooltip: {
            valueSuffix: ' hrs',
          },
        },
        {
          type: 'column',
          name: 'Crank',
          data: hrCrank,
          color: colors[1],
          tooltip: {
            valueSuffix: ' hrs',
          },
        },
        {
          type: 'column',
          name: 'Accessory',
          data: hrAccessory,
          color: colors[2],
          tooltip: {
            valueSuffix: ' hrs',
          },
        },
        {
          type: 'column',
          name: 'Idle',
          data: hrIdle,
          color: colors[3],
          tooltip: {
            valueSuffix: ' hrs',
          },
        },
        {
          type: 'column',
          name: 'Moving',
          data: hrMoving,
          color: colors[4],
          tooltip: {
            valueSuffix: ' hrs',
          },
        },
      ];
      this.distanceOptions.series = [
        {
          type: 'column',
          name: `${this.isMetric ? 'km Above 90 kph' : 'mi Above 56 mph'}`,
          data: kmAbove90Kph,
          color: colors[0],
          tooltip: {
            valueSuffix: `${this.isMetric ? ' km' : ' mi'}`,
          },
        },
        {
          type: 'column',
          name: `${this.isMetric ? 'km Below 90 kph' : 'mi Below 56 mph'}`,
          data: kmBelow90Kph,
          color: colors3[0],
          tooltip: {
            valueSuffix: `${this.isMetric ? ' km' : ' mi'}`,
          },
        },
      ];
      this.fuelTankOptions.series = [
        {
          type: 'line',
          name: 'Max Fuel Level',
          yAxis: 0,
          data: maxFuel,
          color: colors[1],
        },
        {
          type: 'line',
          name: 'Min Fuel Level',
          yAxis: 0,
          data: minFuel,
          color: colors3[1],
        },
        {
          type: 'line',
          name: 'Avg Fuel Level at Refuel',
          yAxis: 0,
          data: avgAtRefill,
          color: colors5[1],
        },
        {
          type: 'line',
          name: 'Refuel Events',
          yAxis: 1,
          data: refillCount,
          color: colors[2],
        },
      ];
      this.fuelUsageOptions.series = [
        {
          type: 'line',
          name: 'Fuel Used',
          yAxis: 0,
          data: kgFuelUsed,
          color: colors[2],
          tooltip: {
            valueSuffix: `${this.isMetric ? ' kg' : ' lb'}`,
          },
        },
        {
          type: 'line',
          name: `${
            this.isMetric ? 'Fuel Used kg / 100 km' : 'Fuel Used lb / 100 mi'
          }`,
          yAxis: 0,
          data: kgPer100km,
          color: colors5[2],
        },
        {
          type: 'line',
          name: `${this.isMetric ? 'km per DLE' : 'mi per DGE'}`,
          yAxis: 1,
          data: kmPerDLE,
          color: colors[3],
        },
      ];
      this.temperatureOptions.series = [
        {
          type: 'line',
          name: 'Max Eng Temp',
          data: maxEngTemp,
          color: colors[0],
          tooltip: {
            valueSuffix: `${this.isMetric ? ' °C' : ' °F'}`,
          },
        },
        {
          type: 'line',
          name: 'Max Battery Temp',
          data: maxBatteryTemp,
          color: colors[1],
          tooltip: {
            valueSuffix: `${this.isMetric ? ' °C' : ' °F'}`,
          },
        },
        {
          type: 'line',
          name: 'Max Axle A Stator Temp',
          data: maxAxleAStatorTemp,
          color: colors[2],
          tooltip: {
            valueSuffix: `${this.isMetric ? ' °C' : ' °F'}`,
          },
        },
        {
          type: 'line',
          name: 'Max Axle A Rotor Temp',
          data: maxAxleARotorTemp,
          color: colors3[2],
          tooltip: {
            valueSuffix: `${this.isMetric ? ' °C' : ' °F'}`,
          },
        },
        {
          type: 'line',
          name: 'Max Axle B Stator Temp',
          data: maxAxleBStatorTemp,
          color: colors[3],
          tooltip: {
            valueSuffix: `${this.isMetric ? ' °C' : ' °F'}`,
          },
        },
        {
          type: 'line',
          name: 'Max Axle B Rotor Temp',
          data: maxAxleARotorTemp,
          color: colors3[3],
          tooltip: {
            valueSuffix: `${this.isMetric ? ' °C' : ' °F'}`,
          },
        },
      ];
      this.weightOptions.series = [
        {
          type: 'line',
          name: 'Min Weight',
          yAxis: 0,
          data: minKgWeight,
          color: colors[4],
          tooltip: {
            valueSuffix: `${this.isMetric ? ' kg' : ' lb'}`,
          },
        },
        {
          type: 'line',
          name: 'Max Weight',
          yAxis: 0,
          data: maxKgWeight,
          color: colors3[4],
          tooltip: {
            valueSuffix: `${this.isMetric ? ' kg' : ' lb'}`,
          },
        },
        {
          type: 'line',
          name: `${this.isMetric ? 'Ton Kilometers' : 'Ton Miles'}`,
          yAxis: 0,
          data: tkm,
          color: colors[3],
        },
      ];
      this.accelOptions.series = [
        {
          type: 'line',
          name: 'Max Accel',
          yAxis: 0,
          data: maxAccel,
          color: colors[0],
          tooltip: {
            valueSuffix: `${this.isMetric ? ' m/s^2' : ' f/s^2'}`,
          },
        },

        {
          type: 'line',
          name: 'Max Decel',
          yAxis: 0,
          data: maxDecel,
          color: colors3[0],
          tooltip: {
            valueSuffix: `${this.isMetric ? ' m/s^2' : ' f/s^2'}`,
          },
        },
        {
          type: 'line',
          name: 'Max Sustained Accel',
          yAxis: 1,
          data: maxSecSustainedAccel,
          color: colors[1],
          tooltip: {
            valueSuffix: ' s',
          },
        },

        {
          type: 'line',
          name: 'Max Sustained Decel',
          yAxis: 1,
          data: maxSecSustainedDecel,
          color: colors3[1],
          tooltip: {
            valueSuffix: ' s',
          },
        },
      ];
      // CRUISE CONTROL
      this.ccTimeOptions.series = [
        {
          type: 'column',
          name: 'time above set speed',
          data: hrAboveSetSpeed,
          color: colors[0],
          tooltip: {
            valueSuffix: ' hrs',
          },
        },
        {
          type: 'column',
          name: 'time below set speed',
          data: hrBelowSetSpeed,
          color: colors[1],
          tooltip: {
            valueSuffix: ' hrs',
          },
        },
        {
          type: 'column',
          name: 'time at set speed',
          data: hrAtSetSpeed,
          color: colors[2],
          tooltip: {
            valueSuffix: ' hrs',
          },
        },
      ];
      this.ccSpeedOptions.series = [
        {
          type: 'line',
          name: 'Min Set Speed',
          data: minSetSpeed,
          color: colors[0],
          tooltip: {
            valueSuffix: `${this.isMetric ? ' kph' : ' mph'}`,
          },
        },
        {
          type: 'line',
          name: 'Max Set Speed',
          data: maxSetSpeed,
          color: colors[1],
          tooltip: {
            valueSuffix: `${this.isMetric ? ' kph' : ' mph'}`,
          },
        },
        {
          type: 'line',
          name: 'Avg Set Speed',
          data: avgSetSpeed,
          color: colors[2],
          tooltip: {
            valueSuffix: `${this.isMetric ? ' kph' : ' mph'}`,
          },
        },
      ];
      this.ccUsageOptions.series = [
        {
          type: 'line',
          name: `${this.isMetric ? '% km In Cruise' : '% mi In Cruise'}`,
          data: pctKmInCruise,
          color: colors[0],
          tooltip: {
            valueSuffix: '%',
          },
        },
        {
          type: 'line',
          name: `${
            this.isMetric ? '% km >90kph In Cruise' : '% mi >56mph In Cruise'
          }`,
          data: pctKmAbove90KmpInCruise,
          color: colors[1],
          tooltip: {
            valueSuffix: '%',
          },
        },
      ];
      // BMS
      this.socOptions.series = [
        {
          type: 'line',
          name: 'min SOC',
          yAxis: 0,
          data: minSOC,
          color: colors[2],
          tooltip: {
            valueSuffix: '%',
          },
        },
        {
          type: 'line',
          name: 'max SOC',
          yAxis: 0,
          data: maxSOC,
          color: colors3[2],
          tooltip: {
            valueSuffix: '%',
          },
        },
        {
          type: 'line',
          name: 'avg SOC',
          yAxis: 0,
          data: avgSOC,
          color: colors5[2],
          tooltip: {
            valueSuffix: '%',
          },
        },
      ];
      this.vcOptions.series = [
        {
          type: 'line',
          name: 'min Pack Volt',
          yAxis: 0,
          data: minPackVolt,
          color: colors[3],
        },
        {
          type: 'line',
          name: 'max Pack Volt',
          yAxis: 0,
          data: maxPackVolt,
          color: colors3[3],
        },
        {
          type: 'line',
          name: 'min Pack Current',
          yAxis: 1,
          data: minPackCurrent,
          color: colors[0],
        },
        {
          type: 'line',
          name: 'max Pack Current',
          yAxis: 1,
          data: maxPackCurrent,
          color: colors3[0],
        },
      ];
      this.bmsTempOptions.series = [
        {
          type: 'line',
          name: 'Avg Battery Temperature',
          data: avgBatteryTemp,
          color: colors[0],
        },
        {
          type: 'line',
          name: 'Max Battery Temperature',
          data: maxBatteryTemp,
          color: colors3[0],
        },
        {
          type: 'line',
          name: 'Avg Idle Battery Temperature',
          data: avgIdleBatteryTemp,
          color: colors[1],
        },
        {
          type: 'line',
          name: 'Max Idle Battery Temperature',
          data: maxIdleBatteryTemp,
          color: colors3[1],
        },
        {
          type: 'line',
          name: 'Avg Moving Battery Temperature',
          data: avgMovingBatteryTemp,
          color: colors[2],
        },
        {
          type: 'line',
          name: 'Max Moving Battery Temperature',
          data: maxMovingBatteryTemp,
          color: colors3[2],
        },
      ];
      // AXLE
      this.axTempOptions.series = [
        {
          type: 'line',
          name: 'Max Axle A Stator Temp',
          data: maxAxleAStatorTemp,
          color: colors[0],
        },
        {
          type: 'line',
          name: 'Max Axle A Rotor Temp',
          data: maxAxleARotorTemp,
          color: colors3[0],
        },
        {
          type: 'line',
          name: 'Max Axle B Stator Temp',
          data: maxAxleBStatorTemp,
          color: colors[1],
        },
        {
          type: 'line',
          name: 'Max Axle B Rotor Temp',
          data: maxAxleBRotorTemp,
          color: colors3[1],
        },
      ];
      this.axVoltOptions.series = [
        {
          type: 'line',
          name: 'min Axle A Voltage',
          data: minAxleAVolt,
          color: colors[0],
        },
        {
          type: 'line',
          name: 'max Axle A Voltage',
          data: maxAxleAVolt,
          color: colors3[0],
        },
        {
          type: 'line',
          name: 'min Axle B Voltage',
          data: minAxleBVolt,
          color: colors[1],
        },
        {
          type: 'line',
          name: 'max Axle B Voltage',
          data: maxAxleBVolt,
          color: colors3[1],
        },
      ];
      this.axCurrentOptions.series = [
        {
          type: 'line',
          name: 'min Axle A Current',
          data: minAxleACurrent,
          color: colors[0],
        },
        {
          type: 'line',
          name: 'max Axle A Current',
          data: maxAxleACurrent,
          color: colors3[0],
        },
        {
          type: 'line',
          name: 'min Axle B Current',
          data: minAxleBCurrent,
          color: colors[1],
        },
        {
          type: 'line',
          name: 'max Axle B Current',
          data: maxAxleBCurrent,
          color: colors3[1],
        },
      ];
      // RETARDER
      this.rtTimeOptions.series = [
        {
          type: 'column',
          name: 'Time In Cruise',
          data: hrInCruise,
          color: colors[0],
          yAxis: 0,
          tooltip: {
            valueSuffix: ' hrs',
          },
        },
        {
          type: 'column',
          name: 'Time Retarder Off',
          data: totalHrRtrdOff,
          color: colors[1],
          yAxis: 0,
          tooltip: {
            valueSuffix: ' hrs',
          },
        },
        {
          type: 'column',
          name: 'Time Retarder 1',
          data: totalHrRtrd1,
          color: colors3[1],
          yAxis: 0,
          tooltip: {
            valueSuffix: ' hrs',
          },
        },
        {
          type: 'column',
          name: 'Time Retarder 2',
          data: totalHrRtrd2,
          color: colors5[1],
          yAxis: 0,
          tooltip: {
            valueSuffix: ' hrs',
          },
        },
        {
          type: 'column',
          name: 'Time Retarder NA',
          data: totalHrRtrdNA,
          color: colors2[1],
          yAxis: 0,
          tooltip: {
            valueSuffix: ' hrs',
          },
        },
        {
          type: 'line',
          name: 'Retarder Level Change',
          data: countRtrdLevelChange,
          color: colors[2],
          yAxis: 1,
        },
      ];
      this.rtDistanceOptions.series = [
        {
          type: 'column',
          name: 'Distance In Cruise',
          data: kmInCruise,
          color: colors[0],
          yAxis: 0,
          tooltip: {
            valueSuffix: `${this.isMetric ? ' km' : ' mi'}`,
          },
        },
        {
          type: 'column',
          name: 'Distance Retarder Off',
          data: totalKmRtrdOff,
          color: colors[1],
          yAxis: 0,
          tooltip: {
            valueSuffix: `${this.isMetric ? ' km' : ' mi'}`,
          },
        },
        {
          type: 'column',
          name: 'Distance Retarder 1',
          data: totalKmRtrd1,
          color: colors3[1],
          yAxis: 0,
          tooltip: {
            valueSuffix: `${this.isMetric ? ' km' : ' mi'}`,
          },
        },
        {
          type: 'column',
          name: 'Distance Retarder 2',
          data: totalKmRtrd2,
          color: colors5[1],
          yAxis: 0,
          tooltip: {
            valueSuffix: `${this.isMetric ? ' km' : ' mi'}`,
          },
        },
        {
          type: 'column',
          name: 'Distance Retarder NA',
          data: totalKmRtrdNA,
          color: colors2[1],
          yAxis: 0,
          tooltip: {
            valueSuffix: `${this.isMetric ? ' km' : ' mi'}`,
          },
        },

        {
          type: 'line',
          name: 'Retarder Level Change',
          data: countRtrdLevelChange,
          color: colors[2],
          yAxis: 1,
        },
      ];

      if (!this.isMetric) {
        this.distanceOptions.yAxis[0].title.text = 'Distance mi';
        this.fuelUsageOptions.yAxis[0].title.text = 'Fuel Usage lb';
        this.fuelUsageOptions.yAxis[1].title.text = 'mi / DGE';
        this.temperatureOptions.yAxis[0].title.text = 'Temperature °F';
        this.weightOptions.yAxis[0].title.text = 'Weight lb';
        this.weightOptions.yAxis[1].title.text = 'Ton Miles';
        this.accelOptions.yAxis[0].title.text = 'Acceleration f/s^2';
        this.ccSpeedOptions.yAxis[0].title.text = 'Speed mph';
        this.bmsTempOptions.yAxis[0].title.text = 'Temperature °F';
        this.axTempOptions.yAxis[0].title.text = 'Temperature °F';
      }
      this.chartsReady = true;
    },
    zoomCharts(state) {
      const vm = this;
      vm.charts.forEach((o) => {
        if (state) {
          if (vm.graphs.has('Usage')) {
            for (let i = 1; i < 8; i++) {
              vm.$refs[`chart${i}`].chart.showResetZoom(); // chart1 -> chart7
            }
          }
          if (vm.graphs.has('Cruise Control')) {
            vm.$refs.chart8.chart.showResetZoom();
            vm.$refs.chart9.chart.showResetZoom();
            vm.$refs.chart10.chart.showResetZoom();
          }
          if (vm.graphs.has('BMS')) {
            vm.$refs.chart11.chart.showResetZoom();
            vm.$refs.chart12.chart.showResetZoom();
            vm.$refs.chart13.chart.showResetZoom();
          }
          if (vm.graphs.has('Axle')) {
            vm.$refs.chart14.chart.showResetZoom();
            vm.$refs.chart15.chart.showResetZoom();
            vm.$refs.chart16.chart.showResetZoom();
          }
          if (vm.graphs.has('Retarder')) {
            vm.$refs.chart17.chart.showResetZoom();
            vm.$refs.chart18.chart.showResetZoom();
          }
          o.xAxis[0].setExtremes(vm.xMin, vm.xMax);
        } else {
          o.xAxis[0].setExtremes(null, null);
        }
      });
    },
    updateChartList() {
      this.charts = [];
      if (this.graphs.has('Usage')) {
        this.$refs.chart1 && this.charts.push(this.$refs.chart1.chart);
        this.$refs.chart2 && this.charts.push(this.$refs.chart2.chart);
        this.$refs.chart3 && this.charts.push(this.$refs.chart3.chart);
        this.$refs.chart4 && this.charts.push(this.$refs.chart4.chart);
        this.$refs.chart5 && this.charts.push(this.$refs.chart5.chart);
        this.$refs.chart6 && this.charts.push(this.$refs.chart6.chart);
        this.$refs.chart7 && this.charts.push(this.$refs.chart7.chart);
      }
      if (this.graphs.has('Cruise Control')) {
        this.$refs.chart8 && this.charts.push(this.$refs.chart8.chart);
        this.$refs.chart9 && this.charts.push(this.$refs.chart9.chart);
        this.$refs.chart10 && this.charts.push(this.$refs.chart10.chart);
      }
      if (this.graphs.has('BMS')) {
        this.$refs.chart11 && this.charts.push(this.$refs.chart11.chart);
        this.$refs.chart12 && this.charts.push(this.$refs.chart12.chart);
        this.$refs.chart13 && this.charts.push(this.$refs.chart13.chart);
      }
      if (this.graphs.has('Axle')) {
        this.$refs.chart14 && this.charts.push(this.$refs.chart14.chart);
        this.$refs.chart15 && this.charts.push(this.$refs.chart15.chart);
        this.$refs.chart16 && this.charts.push(this.$refs.chart16.chart);
      }
      if (this.graphs.has('Retarder')) {
        this.$refs.chart17 && this.charts.push(this.$refs.chart17.chart);
        this.$refs.chart18 && this.charts.push(this.$refs.chart18.chart);
        this.$refs.chart19 && this.charts.push(this.$refs.chart19.chart);
      }
    },
  },
};
</script>

<style scoped>
.graph {
  margin-bottom: 30px;
}
.center {
  display: block;
  margin: auto;
}
</style>
