import axios from 'axios';

const BASE_URL = process.env.VUE_APP_API_PATH_BASE;

async function getLoggedErrors(limit, severity) {
  return await axios.get(
    BASE_URL + `logger/limit/${limit}/severity/${severity}`
  );
}

async function getAPICalls(start, end) {
  return await axios.get(BASE_URL + `cloud_health/getAPICalls/${start}/${end}`);
}

async function getUsers(start, end) {
  return await axios.get(BASE_URL + `cloud_health/getUsers/${start}/${end}`);
}

async function getAPIPercentage(start, end) {
  return await axios.get(
    BASE_URL + `cloud_health/callStatuses/${start}/${end}`
  );
}

async function getAPIVersion() {
  return await axios.get(BASE_URL);
}

async function getDataLoad() {
  return await axios.get(BASE_URL + 'cloud_health/dataload');
}

export {
  getLoggedErrors,
  getAPICalls,
  getUsers,
  getAPIPercentage,
  getAPIVersion,
  getDataLoad,
};
