import axios from 'axios';

const BASE_URL = process.env.VUE_APP_API_PATH_BASE + 'feedback/';

async function postFeedback(body) {
  return await axios.post(BASE_URL, body);
}

async function getFeedback() {
  return await axios.get(BASE_URL);
}

async function deleteFeedback(id) {
  return await axios.delete(BASE_URL + `${id}`);
}

export { getFeedback, deleteFeedback, postFeedback };
