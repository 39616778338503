<template>
  <div id="trucks">
    <alert
      v-if="showAlert"
      :alert-type="alertType"
      :alert-message="alertMessage"
    />
    <v-container fluid>
      <v-row>
        <v-col cols="4" class="pb-0" justify="center">
          <v-text-field
            v-model="searchText"
            placeholder="Search"
            solo
            prepend-inner-icon="mdi-magnify"
            color="primary"
          ></v-text-field>
        </v-col>
        <v-col align="right">
          <v-btn
            large
            color="primary"
            style="color: black"
            @click="showNewTrucks = true"
          >
            <v-icon left>mdi-plus-circle-outline</v-icon>Add Truck</v-btn
          >
          <!--  --------- Temp Disable ------------- -->
          <!-- <span>&nbsp;</span>
          <v-btn
            :disabled="!selectedTrucks.length"
            large
            color="primary"
            style="color: black"
            @click="showUpdateTrucks = true"
          >
            <v-icon left>mdi-plus-circle-outline</v-icon>Update
            Configuration</v-btn
          > -->
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-0" cols="4" xs="12" sm="12" md="12" lg="4" xl="4">
          <v-tabs fixed-tabs background-color="secondary">
            <v-tab @click="changeTab('ERX')">ERX Trucks</v-tab>
            <v-tab @click="changeTab('EX')">Hybrid Trucks</v-tab>
          </v-tabs>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-0">
          <v-skeleton-loader
            v-if="loading"
            type="table"
            elevation="2"
          ></v-skeleton-loader>
          <template v-else>
            <extrucks
              v-if="switchTab === 'EX'"
              :ex-trucks="EXTrucks"
              :search-text="searchText"
              @emitEditTrucks="emitEditTrucks"
              @openUninstallDialog="emitOpenDialog"
            />
            <erxtrucks
              v-else-if="switchTab === 'ERX'"
              :erx-trucks="ERXTrucks"
              :search-text="searchText"
              @refresh="refreshData"
            />
          </template>
          <SnackBar
            v-if="snack"
            :snack-type="snackType"
            :snack-message="snackText"
          />
        </v-col>
      </v-row>
      <add-trucks
        v-if="showNewTrucks"
        @closed="closeNewTrucks"
        @refresh="refreshData"
      />
      <update-trucks
        v-if="showUpdateTrucks"
        :selected-trucks="selectedTrucks"
        @closed="closeUpdateTrucks"
        @refresh="refreshData"
      />
    </v-container>
    <v-dialog v-model="openUninstallDialog" class="dialog" width="750">
      <v-card>
        <v-container>
          <v-row>
            <v-col align="center">
              Are you sure you want to change the status of this truck to
              uninstalled?<br />
              This will remove the truck from any active truck list.
            </v-col>
          </v-row>
          <v-row>
            <v-col class="hidden-xs-and-down"><v-spacer /></v-col>
            <v-col align="center" cols="12" sm="4" md="4" lg="4" xl="4"
              ><v-btn
                color="primary"
                class="mr-2"
                outlined
                @click="openUninstallDialog = false"
                >Cancel</v-btn
              ></v-col
            >
            <v-col align="center" cols="12" sm="4" md="4" lg="4" xl="4"
              ><v-btn
                color="primary"
                style="color: black"
                @click="uninstallTruck"
                >Yes</v-btn
              ></v-col
            >
            <v-col class="hidden-xs-and-down"><v-spacer /></v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from 'lodash';
import { getAllTrucks, updateUninstalledStatus } from '@/api/trucks';
import { getAllDevices } from '@/api/iot';
import { mapActions } from 'vuex';
import AddTruckConfig from './AddTrucks';
import UpdateTruckConfig from './UpdateTrucks';
import Alert from '@/utilities/alert';
import SnackBar from '@/utilities/snackbar';
import ERXTrucks from './ERXTrucks';
import EXTrucks from './EXTrucks';
import { iotCleanup } from '@/utilities/health';

export default {
  name: 'TruckView',
  components: {
    'add-trucks': AddTruckConfig,
    'update-trucks': UpdateTruckConfig,
    SnackBar,
    Alert,
    erxtrucks: ERXTrucks,
    extrucks: EXTrucks,
  },
  data() {
    return {
      loading: true,
      showNewTrucks: false,
      snack: false,
      snackType: '',
      snackText: '',
      interval: 3000,
      searchText: '',
      selectedTrucks: [],
      ERXTrucks: [],
      EXTrucks: [],
      truckList: [],
      showAlert: false,
      alertMessage: '',
      alertType: '',
      showUpdateTrucks: false,
      openUninstallDialog: false,
      uninstalledTruck: null,
      switchTab: 'ERX',
    };
  },
  async created() {
    await this.populateTruckList();
  },
  methods: {
    ...mapActions(['asyncAssignTruck']),
    isNullOrUndefined: function (o) {
      return o === null || o === undefined;
    },
    populateTruckList: async function () {
      const vm = this;
      try {
        let res, res2;
        await Promise.allSettled([getAllTrucks(), getAllDevices()]).then(
          (results) => {
            results[0].status === 'fulfilled'
              ? (res = results[0].value.data)
              : console.log(results[0].reason);

            results[1].status === 'fulfilled'
              ? (res2 = results[1].value.data)
              : console.log(results[1].reason);
          }
        );
        const resLength = res.length;

        if (resLength > 0) {
          vm.ERXTrucks = iotCleanup(
            res.filter((t) => t.truck_type.includes('ERX')),
            res2,
            'ERX',
            true
          );
          vm.EXTrucks = iotCleanup(
            res.filter((t) => t.truck_type.includes('EX')),
            res2,
            'EX',
            true
          );
          vm.loading = false;
        } else {
          console.log('Response array for trucks is empty...');
        }
      } catch (error) {
        this.alertResponse('error', 'Could not retrieve truck data...');
      }
    },
    closeNewTrucks() {
      this.showNewTrucks = false;
      this.refreshData();
    },
    closeUpdateTrucks() {
      this.showUpdateTrucks = false;
    },
    async uninstallTruck() {
      try {
        await updateUninstalledStatus(true, this.uninstalledTruck);
        await this.initSnackBar('Truck Succesfully Uninstalled', 'success');
        this.openUninstallDialog = false;
        this.refreshData();
      } catch {
        await this.initSnackBar('Error Uninstalling Truck', 'error');
      }
    },
    async refreshData() {
      this.selectedTrucks = [];
      this.ERXTrucks = [];
      this.EXTrucks = [];
      // This will re-grab the data from the back-end with the updated configuration data
      await this.populateTruckList();
    },
    alertResponse(type, message) {
      this.loading = false;
      this.showAlert = true;
      this.alertType = type;
      this.alertMessage = message;
      setTimeout(() => {
        this.showAlert = false;
      }, this.interval);
    },
    async initSnackBar(text, type) {
      this.snackType = type;
      this.snackText = text;
      this.snack = true;
      setTimeout(() => {
        this.snack = false;
      }, this.interval);
    },
    changeTab(tab) {
      this.switchTab = tab;
    },
    emitOpenDialog(value) {
      this.openUninstallDialog = true;
      this.uninstalledTruck = value;
    },
    emitEditTrucks(value) {
      if (value.length) {
        this.selectedTrucks = value;
      } else {
        this.selectedTrucks = [];
      }
    },
  },
};
</script>

<style>
/* Removes margin on text field component */
#trucks .v-text-field__details {
  margin-bottom: 0;
}
#trucks .v-input__slot {
  margin-bottom: 0;
}
</style>
