import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,
    themes: {
      dark: {
        primary: '#43B02A',
        primaryDark: '#2B8F14',
        accent: '#82B1FF',
        secondary: '#1A1A1A',
        shade: '#272727',
        shadeDark: '#979797',
        info: '#2196F3',
        warning: '#FB8C00',
        error: '#E53935',
        success: '#2B8F14',
      },
    },
  },
});
