var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        { staticClass: "mr-3" },
        [
          _c("v-col", [
            _c("h1", { staticStyle: { "font-weight": "400" } }, [
              _vm._v("DTC History"),
            ]),
          ]),
          _c(
            "v-col",
            { attrs: { align: "right" } },
            [
              _vm.dtcs.length
                ? _c(
                    "v-btn",
                    {
                      staticClass: "mr-1",
                      staticStyle: { color: "black", height: "48px" },
                      attrs: { color: "#D8D8D8" },
                      on: { click: _vm.exportCSV },
                    },
                    [
                      _c(
                        "download-csv",
                        {
                          attrs: {
                            id: "csv",
                            name: _vm.dtcFileName,
                            data: _vm.dtcs,
                          },
                        },
                        [
                          _c("v-icon", { attrs: { id: "csv2" } }, [
                            _vm._v("mdi-download"),
                          ]),
                          _vm._v(" Download CSV "),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              attrs: {
                cols: "3",
                xs: "12",
                sm: "12",
                md: "12",
                lg: "3",
                xl: "3",
              },
            },
            [
              _vm.dtcs.length
                ? _c("v-text-field", {
                    attrs: {
                      placeholder: "Search",
                      solo: "",
                      "prepend-inner-icon": "mdi-magnify",
                      "background-color": "shade",
                    },
                    model: {
                      value: _vm.searchText,
                      callback: function ($$v) {
                        _vm.searchText = $$v
                      },
                      expression: "searchText",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm.loading
        ? _c("v-skeleton-loader", { attrs: { type: "table", elevation: "2" } })
        : _c("v-data-table", {
            staticClass: "elevation-1",
            attrs: {
              headers: _vm.headers,
              items: _vm.dtcs,
              "items-per-page": 15,
              expanded: _vm.expanded,
              "item-key": "id",
              search: _vm.searchText,
              "show-expand": "",
            },
            on: {
              "update:expanded": function ($event) {
                _vm.expanded = $event
              },
            },
            scopedSlots: _vm._u([
              {
                key: "item.name",
                fn: function ({ item }) {
                  return [
                    item.workbook_link !== "---"
                      ? _c(
                          "a",
                          {
                            attrs: {
                              href: item.workbook_link,
                              target: "_blank",
                            },
                          },
                          [_vm._v(_vm._s(item.name))]
                        )
                      : _vm._e(),
                    item.workbook_link === "---"
                      ? _c("div", [_vm._v(_vm._s(item.name))])
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "expanded-item",
                fn: function ({ item }) {
                  return [
                    _c(
                      "td",
                      {
                        staticStyle: {
                          "background-color": "#272727",
                          padding: "0",
                        },
                        attrs: { colspan: _vm.headers.length + 1 },
                      },
                      [
                        _c("v-data-table", {
                          staticClass: "elevation-1 shade",
                          attrs: {
                            headers: _vm.expandedHeaders,
                            items: item.causes,
                            "hide-default-footer": true,
                            "items-per-page": -1,
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }