var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "tr",
    { class: _vm.getClass },
    _vm._l(_vm.headers, function (header, index) {
      return _c(
        "td",
        { key: index },
        [
          _vm._t(
            _vm.columnName(header),
            function () {
              return [
                _c("div", { style: _vm.getAlignment(header) }, [
                  _vm._v(
                    " " + _vm._s(_vm.getNonSlotValue(_vm.item, header)) + " "
                  ),
                ]),
              ]
            },
            { item: _vm.item }
          ),
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }