var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { staticClass: "millionPage" },
    [
      _c(
        "v-col",
        { attrs: { cols: "4" } },
        [
          _c("v-row", { staticClass: "millionTitle" }, [
            _vm._v("Hypertruck ERX Total Miles"),
          ]),
          _vm.loading
            ? _c(
                "v-sheet",
                { staticClass: "dailyInfo dailyInfoLoader" },
                [
                  _c("v-skeleton-loader", {
                    staticClass: "mx-auto",
                    attrs: {
                      type: "list-item-avatar-two-line, list-item-avatar-two-line, table-row",
                    },
                  }),
                ],
                1
              )
            : _c("div", { staticClass: "dailyInfo" }, [
                _c("div", { staticClass: "dailyLineItem" }, [
                  _c(
                    "div",
                    { staticClass: "iconDiv" },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "icon", attrs: { large: "" } },
                        [_vm._v("mdi-bus-multiple")]
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "dailyLabelWrapper" }, [
                    _c("div", { staticClass: "dailyLabel" }, [
                      _vm._v("Total Fleet Count"),
                    ]),
                    _c("div", { staticClass: "dailyValue" }, [
                      _vm._v(_vm._s(_vm.fleetCount)),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "dailyLineItem" }, [
                  _c(
                    "div",
                    { staticClass: "iconDiv" },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "icon", attrs: { large: "" } },
                        [_vm._v("mdi-truck")]
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "dailyLabelWrapper" }, [
                    _c("div", { staticClass: "dailyLabel" }, [
                      _vm._v("Active Now / Today"),
                    ]),
                    _c("div", { staticClass: "dailyValue" }, [
                      _vm._v(_vm._s(_vm.activeNow + " / " + _vm.activeTrucks)),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "dailyLineItem" }, [
                  _c(
                    "div",
                    { staticClass: "iconDiv" },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "icon", attrs: { large: "" } },
                        [_vm._v("mdi-road-variant")]
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "dailyLabelWrapper" }, [
                    _c("div", { staticClass: "dailyLabel" }, [
                      _vm._v("Miles Driven Today"),
                    ]),
                    _c(
                      "div",
                      { staticClass: "dailyValue" },
                      [
                        _vm.odomReady
                          ? _c("Odometer", {
                              staticStyle: { "font-size": "35px" },
                              attrs: {
                                theme: "car3",
                                format: "( ddd).d",
                                value: _vm.dailyMiles,
                                "min-integer-len": 5,
                                duration: _vm.dailyDuration,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "tableLineItem" }, [
                  _c(
                    "div",
                    { staticClass: "iconDiv" },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "icon", attrs: { large: "" } },
                        [_vm._v("mdi-chart-bar")]
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "tableLabel" }, [
                    _vm._v("Top 5 active trucks today"),
                  ]),
                ]),
                _c(
                  "div",
                  { staticStyle: { width: "500px" } },
                  [
                    _c("v-data-table", {
                      staticStyle: { "background-color": "#1a1a1a" },
                      attrs: {
                        loading: _vm.loading,
                        headers: _vm.top5Headers,
                        items: _vm.top5,
                        "sort-desc.sync": "true",
                        "sort-by.sync": "odometer",
                        "hide-default-footer": true,
                      },
                    }),
                  ],
                  1
                ),
              ]),
        ],
        1
      ),
      _c(
        "v-col",
        { staticClass: "rightHalf" },
        [
          _c(
            "v-row",
            [
              _c("img", {
                staticClass: "hexMap",
                attrs: {
                  src: require("../../public/images/assets/hexMap.svg"),
                },
              }),
              _vm.totalLoading
                ? _c(
                    "v-sheet",
                    {
                      staticClass: "pa-3",
                      staticStyle: {
                        "z-index": "1",
                        height: "170px",
                        display: "block",
                        margin: "auto",
                        "margin-top": "30px",
                        "border-radius": "20px",
                      },
                    },
                    [
                      _c("v-skeleton-loader", {
                        staticClass: "mx-auto",
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                          "padding-top": "50px",
                        },
                        attrs: { type: "table-row" },
                      }),
                    ],
                    1
                  )
                : _vm.totalOdomReady
                ? _c("Odometer", {
                    staticStyle: {
                      display: "block",
                      margin: "auto",
                      "margin-top": "30px",
                      "font-size": "100px",
                    },
                    attrs: {
                      theme: "car2",
                      format: "( ddd)",
                      value: _vm.totalMiles,
                      "min-integer-len": 7,
                      duration: _vm.duration,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-row",
            {
              staticStyle: {
                position: "relative",
                display: "flex",
                "justify-content": "space-between",
                "margin-top": "40px",
                "z-index": "100",
              },
            },
            [
              _c(
                "v-col",
                [
                  _vm.loading
                    ? _c(
                        "v-sheet",
                        {
                          staticClass: "pa-3",
                          staticStyle: {
                            "border-radius": "5px",
                            width: "320px",
                          },
                        },
                        [
                          _c("v-skeleton-loader", {
                            staticClass: "mx-auto",
                            attrs: {
                              type: "image,list-item-avatar-three-line",
                            },
                          }),
                        ],
                        1
                      )
                    : _c("AutoPieChart", {
                        staticStyle: { height: "491px" },
                        attrs: {
                          title: "Miles per Drive Mode",
                          data: _vm.driveMode,
                        },
                      }),
                ],
                1
              ),
              _c(
                "v-col",
                [
                  _vm.loading
                    ? _c(
                        "v-sheet",
                        {
                          staticClass: "pa-3",
                          staticStyle: {
                            "border-radius": "5px",
                            width: "320px",
                          },
                        },
                        [
                          _c("v-skeleton-loader", {
                            staticClass: "mx-auto",
                            attrs: {
                              type: "image,list-item-avatar-three-line",
                            },
                          }),
                        ],
                        1
                      )
                    : _c("AutoPieChart", {
                        staticStyle: { height: "491px", "z-index": "5" },
                        attrs: {
                          data: _vm.speedData,
                          title: "Miles at Speed (Mph)",
                        },
                      }),
                ],
                1
              ),
              _c(
                "v-col",
                [
                  _vm.loading
                    ? _c(
                        "v-sheet",
                        {
                          staticClass: "pa-3",
                          staticStyle: {
                            "border-radius": "5px",
                            width: "320px",
                          },
                        },
                        [
                          _c("v-skeleton-loader", {
                            staticClass: "mx-auto",
                            attrs: {
                              type: "image,list-item-avatar-three-line",
                            },
                          }),
                        ],
                        1
                      )
                    : _c("AutoPieChart", {
                        staticStyle: { height: "491px", "z-index": "5" },
                        attrs: {
                          data: _vm.weightData,
                          title: "Miles at Weight (Lb)",
                        },
                      }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }