<template>
  <v-dialog v-model="openDialog" class="dialog" width="750">
    <loader v-if="loading" />
    <v-card>
      <v-container>
        <v-row class="ma-2">
          <v-col align="center" cols="12" sm="12" md="12" lg="12" xl="12">
            <div class="font-weight-bold text-h5">Add Unit</div>
            <v-card align="center" class="padding margin">
              <v-form ref="userForm" v-model="truckValid" lazy-validation>
                <v-toolbar-title>Truck Registration</v-toolbar-title>
                <v-select
                  v-model="customer"
                  :items="companyItems"
                  label="Select Company"
                ></v-select>
                <v-select
                  v-model="truckPlatform"
                  :items="platformList"
                  label="Select Platform"
                ></v-select>
                <v-select
                  v-model="truckType"
                  :items="filteredTypeList"
                  :disabled="truckTypeDisabled"
                  label="Select Truck Type"
                ></v-select>
                <v-text-field
                  v-model="truckName"
                  :rules="truckRules"
                  :disabled="truckDisabled"
                  label="Truck Number"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="vin"
                  :rules="vinRules"
                  :disabled="vinDisabled"
                  label="VIN"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="serialNo"
                  :rules="sernoRules"
                  :disabled="sernoDisabled"
                  label="Unit Serial #"
                  required
                ></v-text-field>
                <v-checkbox
                  v-model="isSimulated"
                  label="Simulated Truck"
                ></v-checkbox>
              </v-form>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="ma-2">
          <v-col class="hidden-xs-and-down"><v-spacer /></v-col>
          <v-col align="center" cols="12" sm="4" md="4" lg="4" xl="4"
            ><v-btn @click="openDialog = false">Dismiss</v-btn></v-col
          >
          <v-col align="center" cols="12" sm="4" md="4" lg="4" xl="4"
            ><v-btn color="primary" :disabled="!truckValid" @click="truckSubmit"
              >Add Truck</v-btn
            ></v-col
          >
          <v-col class="hidden-xs-and-down"><v-spacer /></v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import loader from '@/utilities/loader';
import _ from 'lodash';
import { getTruckTypes, setNewTrucks, checkVin } from '@/api/trucks';
import { getCompanies, getCustomers } from '@/api/company';

export default {
  name: 'AddTrucks',
  components: { loader },
  props: {},
  data() {
    return {
      companyItems: [],
      companyList: [],
      oldCustomerList: [],
      customer: '',
      interval: 3000,
      loading: true,
      openDialog: false,
      platformList: ['EX', 'ERX'],
      serialNo: '',
      sernoDisabled: true,
      sernoRules: [
        (v) => !!v || 'Serial # is required',
        (v) => (v && v.length === 10) || 'Serial must be 10 characters',
      ],
      truckDisabled: true,
      truckName: '',
      truckPlatform: '',
      truckRules: [
        (v) => !!v || 'Number is required',
        (v) =>
          (v && v.length > 0 && v.length < 11) ||
          'Truck No. must bebetween 2 and 10 characters.',
      ],
      truckType: '',
      truckTypeDisabled: true,
      truckTypeList: [],
      filteredTypeList: [],
      truckValid: false,
      vin: '',
      vinDisabled: true,
      vinRules: [
        (v) => !!v || 'VIN# is required',
        (v) => (v && v.length === 17) || 'VIN must be 17 characters',
      ],
      isSimulated: false,
    };
  },
  computed: {},
  watch: {
    // Watches for the change in openDialog variable to emit the false variable back to parent to reset
    openDialog: function (newValue) {
      if (!newValue) {
        this.openDialog = false;
        this.$emit('closed', false);
      }
    },
    truckType: function (val) {
      this.sernoDisabled = val.includes('ERX');
      this.vinDisabled = !val;
      this.truckDisabled = !val;
    },
    truckPlatform: function (val) {
      const vm = this;
      vm.truckTypeDisabled = !val;
      vm.filteredTypeList = _.filter(vm.truckTypeList, function (o) {
        return _.includes(o, val);
      });
    },
    truckValid: function () {
      const vm = this;
      const vinTest = vm.vin.length === 17;
      if (vm.truckType.includes('EX')) {
        vm.truckValid = !(
          !vm.truckName ||
          !vm.serialNo ||
          !vinTest ||
          !vm.customer
        );
      } else {
        vm.truckValid = !(!vm.truckName || !vinTest || !vm.customer);
      }
    },
    vin: async function (numVal) {
      const vm = this;
      if (numVal.length === 17) {
        try {
          const res = await checkVin(numVal);
          if (!vm.isNullOrUndefined(res.data)) {
            const data = res.data;
            vm.truckValid = data.Results[0].ErrorCode === '0';
          } else {
            this.updateSnack({
              type: 'error',
              message: 'Error on NHTSA VIN Lookup...',
            });
            vm.truckValid = false;
          }
        } catch (error) {
          this.updateSnack({
            type: 'error',
            message: 'Could not retrieve NHTSA VIN data...',
          });
          vm.truckValid = false;
        }
      } else {
        vm.truckValid = false;
      }
    },
  },
  async mounted() {
    this.openDialog = true;
    await this.populateTruckTypes();
    await this.getCompanyList();
  },
  methods: {
    ...mapActions({
      updateSnack: 'updateSnack',
    }),
    getCompanyList: async function () {
      //pulling from Truck DB customer accounts
      const vm = this;
      try {
        const [res, resTruckAccts] = await Promise.all([
          getCompanies(),
          getCustomers(),
        ]);
        const resLength = res.data.length;
        const truckLen = resTruckAccts.data.length;
        if (resLength > 0 && truckLen > 0) {
          vm.companyItems = [];
          vm.companyList = res.data.slice();
          vm.oldCustomerList = resTruckAccts.data.slice();
          _.forEach(res.data, function (o) {
            vm.companyItems.push(o.name);
          });
          vm.companyItems = _.sortBy(vm.companyItems, function (o) {
            return o;
          });
        } else {
          this.updateSnack({
            type: 'info',
            message: 'Response array for company names is empty...',
          });
        }
      } catch (error) {
        this.updateSnack({
          type: 'error',
          message: 'Could not retrieve customer (company) data...',
        });
      }
      vm.loading = false;
    },
    getSchema: function () {
      const vm = this;
      const testName = vm.customer.toLowerCase();
      let dataSet = _.find(vm.oldCustomerList, function (o) {
        let match = o.company_name.toLowerCase();
        return match === testName;
      });
      if (!vm.isNullOrUndefined(dataSet)) {
        return dataSet.company_schema_id;
      }
      return '';
    },
    isNullOrUndefined: function (o) {
      return o === null || o === undefined;
    },
    populateTruckTypes: async function () {
      //pulling from Hyliion DB truck_types table
      const vm = this;
      try {
        const res = await getTruckTypes();
        const resLength = res.data.length;
        if (resLength > 0) {
          _.forEach(res.data, function (o) {
            // Only EX truck registration right now - Chris Sealy 11/01/2022
            // if (o.truck_type === 'EX-Diesel' || o.truck_type === 'EX-CNG') {
            //   vm.truckTypeList.push(o.truck_type);
            // }
            vm.truckTypeList.push(o.truck_type);
          });
          vm.filteredTypeList = _.clone(vm.truckTypeList);
        } else {
          this.updateSnack({
            type: 'info',
            message: 'Response array for truck types names is empty...',
          });
        }
      } catch (error) {
        this.updateSnack({
          type: 'error',
          message: 'Could not retrieve truck types.',
        });
      }
    },
    registerTruck: async function (truckConfig) {
      const vm = this;
      let errorText = '';
      try {
        const res = await setNewTrucks(truckConfig);
        const resValid = !vm.isNullOrUndefined(res);

        if (resValid) {
          errorText = res.data;
          if (res.status === 200) {
            _.delay(function () {
              vm.openDialog = false;
            }, 3600);
          }
        } else {
          errorText = `No valid response from registration API. Please try again later.`;
          this.updateSnack({
            type: 'error',
            message: errorText,
          });
        }
      } catch (error) {
        errorText = `Axios error on registering unit/truck ==> ${error}`;
        this.updateSnack({
          type: 'error',
          message: errorText,
        });
      }
    },
    truckFilter: function () {
      const vm = this;
      vm.truckName = vm.truckName.replace(/[^a-zA-Z\d\- ]/g, '');
      vm.vin = vm.vin.replace(/[^a-zA-Z\d]/g, '').toUpperCase();
      if (vm.truckType.includes('EX')) {
        vm.serialNo = vm.serialNo.replace(/[^a-zA-Z\d]/g, '').toUpperCase();
      }
      if (vm.vin.length === 17 && vm.truckValid) {
        return true;
      } else {
        let errorText =
          'Truck form validation failed. Please check values and try again.';
        this.updateSnack({
          type: 'error',
          message: errorText,
        });
      }
      return false;
    },
    truckSubmit: function () {
      const vm = this;
      let truckConfig = {};
      const tester = vm.truckFilter();
      if (!tester) {
        this.updateSnack({
          type: 'error',
          message:
            'Failed on truckFilter() - one or more values entered is invalid...',
        });
        return;
      }
      if (vm.truckType.includes('EX')) {
        truckConfig.schema_name = vm.getSchema();
      } else {
        truckConfig.schema_name = '';
      }
      truckConfig.platform = vm.truckPlatform;
      truckConfig.company = vm.customer;
      truckConfig.unit_name = vm.truckName;
      truckConfig.vin = vm.vin;
      truckConfig.serno = vm.serialNo;
      truckConfig.truck_type = vm.truckType;
      truckConfig.simulated = vm.isSimulated;
      vm.registerTruck(truckConfig);
    },
  },
};
</script>

<style scoped></style>
