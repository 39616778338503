<template>
  <div id="trucks">
    <v-container fluid>
      <v-row>
        <v-col class="py-0">
          <v-data-table
            :headers="headers"
            :items="erxPackages"
            class="elevation-1 secondary row-height-50"
            :search="searchText"
          >
            <template v-slot:[`item.name`]="props">
              <th class="body-5">{{ props.item.number }}</th>
              <div class="body-6">{{ props.item.company }}</div>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-menu style="top: 312px; left: 300px" offset-y>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-dots-vertical</v-icon>
                </template>
                <v-list dense>
                  <v-list-item-group
                    v-if="
                      item.status !== 'deprecated' && item.status !== 'deleted'
                    "
                  >
                    <v-list-item
                      v-for="(action, index) in actionItems"
                      :key="index"
                      :class="action.title"
                      @click="setAction(item, action.title)"
                      ><v-list-item-icon class="mr-2">
                        <v-icon>{{ action.icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title class="pr-2"></v-list-item-title
                        >{{ action.title }}
                      </v-list-item-content></v-list-item
                    >
                  </v-list-item-group>
                  <v-list-item-group v-else>
                    <v-list-item
                      v-for="(action, index) in deletedActionItems"
                      :key="index"
                      :class="action.title"
                      @click="setAction(item, action.title)"
                      ><v-list-item-icon class="mr-2">
                        <v-icon>{{ action.icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title class="pr-2"></v-list-item-title
                        >{{ action.title }}
                      </v-list-item-content></v-list-item
                    >
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <!-- Update Package State Dialog-->
      <v-dialog v-model="updatePackageStateModal" width="500" persistent>
        <v-card class="pa-5 text-center">
          <v-row>
            <v-col>
              <h3>
                Are you sure you want to {{ state }}<br />
                {{ selectedPackage.config_name }} ?
              </h3>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                color="primary"
                class="mr-2"
                outlined
                @click="updatePackageStateModal = false"
                >Dismiss</v-btn
              >
            </v-col>
            <v-col>
              <v-btn
                color="primary"
                style="color: black"
                @click="updatePackageState()"
                >Confirm</v-btn
              >
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { updateConfigurationStatus } from '@/api/configurations';
export default {
  name: 'ERXPackages',
  props: {
    erxPackages: {
      type: Array,
      required: true,
    },
    searchText: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      actionItems: [{ title: 'View Package History', icon: 'mdi-history' }],
      deletedActionItems: [
        { title: 'View Package History', icon: 'mdi-history' },
        { title: 'Restore', icon: 'mdi-restore' },
      ],
      headers: [
        { text: 'File Name', value: 'config_name' },
        { text: 'Version', value: 'config_version' },
        { text: 'State', value: 'status' },
        { text: 'State Last Changed By', value: 'status_updated_by' },
        { text: 'Last State Change', value: 'updated_at' },
        { text: 'Trucks Assigned To', value: 'assigned_trucks' },
        { text: 'Trucks Installed On', value: 'installed_trucks' },
        {
          text: '',
          value: 'actions',
          align: 'right',
        },
      ],
      selectedPackage: {},
      updatePackageStateModal: false,
      state: '',
      states: ['unsupported', 'supported', 'verified', 'available'],
    };
  },
  computed: {
    ...mapGetters({
      userRoles: 'getUserRoles',
    }),
  },
  watch: {},
  async created() {
    // https://hyliion.atlassian.net/wiki/spaces/HLS/pages/2911338565/HCU+HDP+Software+Package+Business+Process+Flow+ERX+Only
    // If user has support, verify, or release role, the have access to promote, demote, and deprecate
    if (
      this.userRoles.includes('config.support') ||
      this.userRoles.includes('config.verify') ||
      this.userRoles.includes('config.release')
    ) {
      this.actionItems.unshift(
        {
          title: 'Promote Package',
          icon: 'mdi-arrow-up-circle-outline',
        },
        {
          title: 'Demote Package',
          icon: 'mdi-arrow-down-circle-outline',
        },
        {
          title: 'Deprecate Package',
          icon: 'mdi-delete-clock',
        }
      );
      // If user has write role, the have access to deprecate
    } else if (this.userRoles.includes('config.write')) {
      this.actionItems.unshift({
        title: 'Deprecate Package',
        icon: 'mdi-delete-clock',
      });
    }
  },
  methods: {
    ...mapActions({
      updateSnack: 'updateSnack',
    }),
    async setAction(packageInfo, action) {
      if (action === 'Promote Package') {
        if (packageInfo.status === 'available') {
          this.triggerError('You cannot promote higher than available!');
          return;
        }
        if (packageInfo.status === 'unsupported') {
          if (!this.userRoles.includes('config.support')) {
            this.triggerError(
              'You do not have permission to promote this package!'
            );
            return;
          }
        }
        if (packageInfo.status === 'supported') {
          if (!this.userRoles.includes('config.verify')) {
            this.triggerError(
              'You do not have permission to promote this package!'
            );
            return;
          }
        }
        if (packageInfo.status === 'verified') {
          if (!this.userRoles.includes('config.release')) {
            this.triggerError(
              'You do not have permission to promote this package!'
            );
            return;
          }
        }
        this.state = 'promote';
        this.selectedPackage = packageInfo;
        this.updatePackageStateModal = true;
      } else if (action === 'Demote Package') {
        if (packageInfo.status === 'unsupported') {
          this.triggerError('You cannot demote lower than unsupported!');
          return;
        }
        if (packageInfo.status === 'supported') {
          if (!this.userRoles.includes('config.support')) {
            this.triggerError(
              'You do not have permission to demote this package!'
            );
            return;
          }
        }
        if (packageInfo.status === 'verified') {
          if (!this.userRoles.includes('config.verify')) {
            this.triggerError(
              'You do not have permission to demote this package!'
            );
            return;
          }
        }
        if (packageInfo.status === 'available') {
          if (!this.userRoles.includes('config.release')) {
            this.triggerError(
              'You do not have permission to demote this package!'
            );
            return;
          }
        }
        this.selectedPackage = packageInfo;
        this.state = 'demote';
        this.updatePackageStateModal = true;
      } else if (action === 'Deprecate Package') {
        if (packageInfo.status === 'unsupported') {
          if (
            !this.userRoles.includes('config.support') ||
            !this.userRoles.includes('config.write')
          ) {
            this.triggerError(
              'You do not have permission to deprecate this package!'
            );
            return;
          }
        }
        if (packageInfo.status === 'supported') {
          if (!this.userRoles.includes('config.verify')) {
            this.triggerError(
              'You do not have permission to deprecate this package!'
            );
            return;
          }
        }
        if (packageInfo.status === 'verified') {
          if (
            !this.userRoles.includes('config.verify') ||
            !this.userRoles.includes('config.release')
          ) {
            this.triggerError(
              'You do not have permission to deprecate this package!'
            );
            return;
          }
        }
        if (packageInfo.status === 'available') {
          if (!this.userRoles.includes('config.release')) {
            this.triggerError(
              'You do not have permission to deprecate this package!'
            );
            return;
          }
        }
        this.selectedPackage = packageInfo;
        this.state = 'deprecate';
        this.updatePackageStateModal = true;
      } else if (action === 'View Update History') {
        // Todo: Add View Update History Modal
      } else if (action === 'Restore') {
        if (
          !this.userRoles.includes('config.verify') ||
          !this.userRoles.includes('config.release') ||
          !this.userRoles.includes('config.support') ||
          !this.userRoles.includes('config.write')
        ) {
          this.triggerError(
            'You do not have permission to deprecate this package!'
          );
          return;
        }
        this.selectedPackage = packageInfo;
        this.state = 'restore';
        this.updatePackageStateModal = true;
      }
    },
    async updatePackageState() {
      if (this.state === 'promote') {
        try {
          await updateConfigurationStatus({
            id: this.selectedPackage.id,
            status:
              this.states[
                this.states.findIndex(
                  (v) => v === this.selectedPackage.status
                ) + 1
              ],
          });
          this.triggerSuccess('Successfully promoted package!');
          this.$emit('refresh');
        } catch (e) {
          this.triggerError('There was an issue promoting the package!');
        }
      } else if (this.state === 'demote') {
        try {
          await updateConfigurationStatus({
            id: this.selectedPackage.id,
            status:
              this.states[
                this.states.findIndex(
                  (v) => v === this.selectedPackage.status
                ) - 1
              ],
          });
          this.triggerSuccess('Successfully demoted package!');
          this.$emit('refresh');
        } catch (e) {
          this.triggerError('There was an issue demoting the package!');
        }
      } else if (this.state === 'deprecate') {
        try {
          await updateConfigurationStatus({
            id: this.selectedPackage.id,
            status: 'deprecated',
          });
          this.triggerSuccess('Successfully deprecated package!');
          this.$emit('refresh');
        } catch (e) {
          this.triggerError('There was an issue deprecating the package!');
        }
      } else if (this.state === 'restore') {
        try {
          await updateConfigurationStatus({
            id: this.selectedPackage.id,
            status: 'unsupported',
          });
          this.triggerSuccess('Successfully restored package!');
          this.$emit('refresh');
        } catch (e) {
          this.triggerError('There was an issue restoring the package!');
        }
      }
      this.updatePackageStateModal = false;
    },
    triggerError(msg) {
      this.updateSnack({
        type: 'error',
        message: msg,
      });
    },
    triggerSuccess(msg) {
      this.updateSnack({
        type: 'success',
        message: msg,
      });
    },
  },
};
</script>
<style scoped></style>
