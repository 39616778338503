var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "manufacturing" } },
    [
      _c(
        "v-container",
        { staticClass: "mt-3 mx-3", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { staticClass: "mr-3" },
            [
              _c("v-col", [
                _c("h1", { staticStyle: { "font-weight": "400" } }, [
                  _vm._v("Manufacturing"),
                ]),
              ]),
              _c(
                "v-col",
                { attrs: { align: "right" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-1",
                      staticStyle: { color: "white", height: "48px" },
                      attrs: { color: "blue" },
                      on: {
                        click: function ($event) {
                          return _vm.toggleFaultsDialog()
                        },
                      },
                    },
                    [_vm._v("Comp. Lvl. Faults ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "4", md: "12" } },
                [
                  _c(
                    "v-tabs",
                    {
                      attrs: {
                        "fixed-tabs": "",
                        "background-color": "secondary",
                      },
                      model: {
                        value: _vm.tab,
                        callback: function ($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab",
                      },
                    },
                    _vm._l(_vm.tabItems, function (item) {
                      return _c("v-tab", { key: item }, [
                        _vm._v(" " + _vm._s(item) + " "),
                      ])
                    }),
                    1
                  ),
                  _c(
                    "v-tabs-items",
                    {
                      model: {
                        value: _vm.tab,
                        callback: function ($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab",
                      },
                    },
                    [
                      _c(
                        "v-tab-item",
                        [
                          _vm.tabItems[_vm.tab] === "Pending"
                            ? _c("test-plan-table", {
                                attrs: {
                                  "plan-data": _vm.pendingUnits,
                                  loading: _vm.loading,
                                  "search-text": _vm.searchText,
                                  "get-elements": _vm.getElements,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "v-tab-item",
                        [
                          _vm.tabItems[_vm.tab] === "In-Flight"
                            ? _c("test-plan-table", {
                                attrs: {
                                  "plan-data": _vm.inFlightUnits,
                                  loading: _vm.loading,
                                  "search-text": _vm.searchText,
                                  "get-elements": _vm.getElements,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "v-tab-item",
                        [
                          _vm.tabItems[_vm.tab] === "Finished"
                            ? _c("test-plan-table", {
                                attrs: {
                                  "plan-data": _vm.finishedUnits,
                                  loading: _vm.loading,
                                  "search-text": _vm.searchText,
                                  "get-elements": _vm.getElements,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c("v-tab-item", [
                        _c("div", {
                          staticStyle: { display: "block", height: "20px" },
                        }),
                        _c(
                          "div",
                          { staticStyle: { margin: "0 auto", width: "60%" } },
                          [
                            _c("v-select", {
                              staticClass: "formInput",
                              attrs: {
                                items: _vm.planMenuItems,
                                outlined: "",
                                label: "Choose Plan Method",
                                required: "",
                              },
                              model: {
                                value: _vm.editPlanChoice,
                                callback: function ($$v) {
                                  _vm.editPlanChoice = $$v
                                },
                                expression: "editPlanChoice",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm.editPlanChoice === "Add Plan"
                          ? _c(
                              "div",
                              [
                                _c("add-plan", {
                                  attrs: {
                                    "add-test-plan": _vm.addTestPlan,
                                    toggle: _vm.togglePlanDialog,
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.editPlanChoice === "Edit Plan"
                          ? _c(
                              "div",
                              [
                                _c("edit-plan", {
                                  attrs: {
                                    "update-test-plan": _vm.updateTestPlan,
                                    "plan-data": _vm.allPlans,
                                    "stage-data": _vm.stageData,
                                    toggle: _vm.togglePlanDialog,
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.showBtmTabs
            ? _c(
                "v-row",
                [
                  _c("v-col", [
                    _c("h2", { staticStyle: { "font-weight": "400" } }, [
                      _vm._v(_vm._s(_vm.activeRowTitle)),
                    ]),
                  ]),
                  _c(
                    "v-col",
                    { attrs: { align: "right" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-1",
                          staticStyle: { color: "black", height: "48px" },
                          attrs: { color: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.toggleStepDialog()
                            },
                          },
                        },
                        [_vm._v("Add Steps ")]
                      ),
                      _c("span", [_vm._v(" ")]),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-1",
                          staticStyle: { color: "black", height: "48px" },
                          attrs: { color: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.toggleTestDialog()
                            },
                          },
                        },
                        [_vm._v("Add Tests ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-1",
                          staticStyle: { color: "black", height: "48px" },
                          attrs: { color: "orange" },
                          on: {
                            click: function ($event) {
                              return _vm.toggleIssuesDialog()
                            },
                          },
                        },
                        [_vm._v("Add Issues ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.showBtmTabs
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "4", md: "12" } },
                    [
                      _c(
                        "v-tabs",
                        {
                          attrs: {
                            "fixed-tabs": "",
                            "background-color": "secondary",
                          },
                          model: {
                            value: _vm.btmTab,
                            callback: function ($$v) {
                              _vm.btmTab = $$v
                            },
                            expression: "btmTab",
                          },
                        },
                        _vm._l(_vm.allCategories, function (cat) {
                          return _c("v-tab", { key: cat }, [
                            _vm._v(" " + _vm._s(cat) + " "),
                          ])
                        }),
                        1
                      ),
                      _c(
                        "v-tabs-items",
                        {
                          model: {
                            value: _vm.btmTab,
                            callback: function ($$v) {
                              _vm.btmTab = $$v
                            },
                            expression: "btmTab",
                          },
                        },
                        [
                          _c("v-tab-item", [
                            _c(
                              "div",
                              { staticClass: "summaryArea" },
                              [
                                _c("v-simple-table", {
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function () {
                                          return [
                                            _c("thead", [
                                              _c("tr", [
                                                _c(
                                                  "th",
                                                  { staticClass: "text-left" },
                                                  [_vm._v("Plan Items")]
                                                ),
                                                _c(
                                                  "th",
                                                  { staticClass: "text-left" },
                                                  [_vm._v("Values")]
                                                ),
                                              ]),
                                            ]),
                                            _c(
                                              "tbody",
                                              _vm._l(
                                                _vm.summaryItems,
                                                function (item) {
                                                  return _c(
                                                    "tr",
                                                    { key: item.name },
                                                    [
                                                      _c(
                                                        "td",
                                                        {
                                                          attrs: {
                                                            width: "20%",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(item.name)
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "td",
                                                        {
                                                          attrs: {
                                                            width: "80%",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(item.props)
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    1518295242
                                  ),
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c(
                            "v-tab-item",
                            [
                              _c("issues-table", {
                                attrs: {
                                  issues: _vm.planIssues,
                                  "load-issue-from-table":
                                    _vm.loadIssueFromTable,
                                  "issue-status": _vm.issueStatus,
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._l(_vm.testCategories, function (category) {
                            return _c(
                              "v-tab-item",
                              { key: category.id },
                              [
                                _c("category-table", {
                                  attrs: {
                                    "category-test-items":
                                      _vm.categoryTestItems,
                                    "load-test-results": _vm.loadTestResults,
                                    "random-key": _vm.catKey,
                                  },
                                }),
                              ],
                              1
                            )
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-dialog",
            {
              attrs: { persistent: "", "max-width": "800" },
              model: {
                value: _vm.stepDialog,
                callback: function ($$v) {
                  _vm.stepDialog = $$v
                },
                expression: "stepDialog",
              },
            },
            [
              _c("add-step", {
                attrs: {
                  "add-plan-step": _vm.addSteps,
                  dialog: _vm.stepDialog,
                  "test-categories": _vm.testCategories,
                  "full-categories": _vm.categories,
                  toggle: _vm.toggleStepDialog,
                },
              }),
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: { persistent: "", "max-width": "800" },
              model: {
                value: _vm.testsDialog,
                callback: function ($$v) {
                  _vm.testsDialog = $$v
                },
                expression: "testsDialog",
              },
            },
            [
              _c("add-test", {
                attrs: {
                  "add-step-test": _vm.addTests,
                  dialog: _vm.testsDialog,
                  "filtered-steps": _vm.stepsData,
                  categories: _vm.categories,
                  toggle: _vm.toggleTestDialog,
                },
              }),
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: { persistent: "", "max-width": "960" },
              model: {
                value: _vm.resultsDialog,
                callback: function ($$v) {
                  _vm.resultsDialog = $$v
                },
                expression: "resultsDialog",
              },
            },
            [
              _c("test-results", {
                attrs: {
                  dialog: _vm.resultsDialog,
                  "plan-id": _vm.selectedPlan,
                  "test-data": _vm.testData,
                  "test-results-data": _vm.testResultsData,
                  "reload-steps": _vm.reloadSteps,
                  toggle: _vm.toggleResultsDialog,
                },
              }),
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: { persistent: "", "max-width": "960" },
              model: {
                value: _vm.issuesDialog,
                callback: function ($$v) {
                  _vm.issuesDialog = $$v
                },
                expression: "issuesDialog",
              },
            },
            [
              _c("add-issues", {
                attrs: {
                  dialog: _vm.issuesDialog,
                  "plan-item": _vm.currentPlan,
                  "issue-status": _vm.issueStatus,
                  "add-issue-item": _vm.addIssueItem,
                  "reload-issues": _vm.reloadIssues,
                  toggle: _vm.toggleIssuesDialog,
                },
              }),
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: { persistent: "", "max-width": "960" },
              model: {
                value: _vm.updateIssuesDialog,
                callback: function ($$v) {
                  _vm.updateIssuesDialog = $$v
                },
                expression: "updateIssuesDialog",
              },
            },
            [
              _c("update-issues", {
                attrs: {
                  dialog: _vm.updateIssuesDialog,
                  "issue-status": _vm.issueStatus,
                  "plan-item": _vm.currentPlan,
                  "current-issue": _vm.currentIssue,
                  "update-issue-item": _vm.updateIssueItem,
                  "reload-issues": _vm.reloadIssues,
                  toggle: _vm.toggleUpdateIssuesDialog,
                },
              }),
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: { "max-width": "1280" },
              model: {
                value: _vm.faultsDialog,
                callback: function ($$v) {
                  _vm.faultsDialog = $$v
                },
                expression: "faultsDialog",
              },
            },
            [
              _c("faults-table", {
                attrs: {
                  dialog: _vm.faultsDialog,
                  "fault-items": _vm.compLvlFaults,
                  toggle: _vm.toggleFaultsDialog,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }