var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-alert",
    {
      staticClass: "alert",
      staticStyle: { color: "black" },
      attrs: {
        dense: "",
        prominent: "",
        type: _vm.alertType,
        icon: _vm.getIcon(),
        color: _vm.getColor(),
      },
    },
    [_vm._v(_vm._s(_vm.alertMessage))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }