<template>
  <v-data-table
    :no-data-text="noDataMsg"
    :headers="labels"
    item-key="truckNumber"
    :items="trucks"
    class="dark-background"
  >
    <!-- Removed expanded options from table ^-->
    <!-- show-expand
         :expanded.sync="expanded" 
         @click:row="(item, slot) => slot.expand(!slot.isExpanded)"-->
    <!-- <template v-slot:expanded-item="{ item }">
      <td :colspan="labels.length + 1" style="padding: 0">
        <v-data-table
          :headers="expandedLabels"
          :items="item.truckDtcData"
          :hide-default-footer="true"
          class="elevation-1 shade"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Details</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
        </v-data-table>
      </td>
    </template> -->
    <template v-slot:[`item.status`]="{ item }">
      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <td :style="severityColor(item.faultSeverity)">
            <v-icon
              large
              :style="severityColor(item.faultSeverity)"
              v-on="on"
              >{{ getIcon(item.status) }}</v-icon
            >
          </td>
        </template>
        <span>{{ item.status }}</span>
      </v-tooltip>
    </template>

    <template v-slot:[`item.closestServiceLocation`]="{ item }">
      <td>
        <p class="mb-0 mt-1">
          {{ item.miles >= 0 ? item.closestServiceLocation : '—' }}
        </p>
        <p style="color: #888888; margin: 0">
          {{ item.miles >= 0 ? item.miles + ' miles' : '' }}
        </p>
      </td>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <td
        style="color: #43b02a; cursor: pointer"
        @click="() => viewTruck(item)"
      >
        VIEW
      </td>
    </template>
  </v-data-table>
</template>

<script>
import { getIcon, severityColor } from '@/utilities/ExternalHealth.js';
export default {
  name: 'NormalTableTemplate',
  components: {},
  props: {
    trucks: {
      type: Array,
      default() {
        return [];
      },
    },
    actions: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      labels: [
        { text: 'Truck Name', value: 'truckNumber' },
        { text: 'Status', value: 'status' },
        { text: 'Current Location', value: 'currentLocation' },
        { text: 'Closest Service Location', value: 'closestServiceLocation' },
      ],
      // expandedLabels: [
      //   {
      //     text: 'Journey Starting Point',
      //     value: 'journeyStartingPoint',
      //     width: '50px',
      //     align: 'left',
      //   },
      //   { text: 'Duration', value: 'duration', width: '75px' },
      // ],
      // expanded: [],
      getIcon,
      severityColor,
      noDataMsg: 'There are no current trucks for this category',
    };
  },
  mounted() {
    if (this.actions) {
      this.labels.push({ text: 'Actions', value: 'actions' });
    }
  },
  methods: {
    viewTruck(truck) {
      this.$emit('viewClicked', truck);
    },
  },
};
</script>
<style scoped></style>
