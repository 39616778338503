<template>
  <div id="manufacturing">
    <v-container fluid class="mt-3 mx-3">
      <v-row class="mr-3">
        <v-col>
          <h1 style="font-weight: 400">Manufacturing</h1>
        </v-col>
        <v-col align="right">
          <v-btn
            color="blue"
            class="mr-1"
            style="color: white; height: 48px"
            @click="toggleFaultsDialog()"
            >Comp. Lvl. Faults
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" md="12">
          <v-tabs v-model="tab" fixed-tabs background-color="secondary">
            <v-tab v-for="item in tabItems" :key="item">
              {{ item }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <test-plan-table
                v-if="tabItems[tab] === 'Pending'"
                :plan-data="pendingUnits"
                :loading="loading"
                :search-text="searchText"
                :get-elements="getElements"
              />
            </v-tab-item>
            <v-tab-item>
              <test-plan-table
                v-if="tabItems[tab] === 'In-Flight'"
                :plan-data="inFlightUnits"
                :loading="loading"
                :search-text="searchText"
                :get-elements="getElements"
              />
            </v-tab-item>
            <v-tab-item>
              <test-plan-table
                v-if="tabItems[tab] === 'Finished'"
                :plan-data="finishedUnits"
                :loading="loading"
                :search-text="searchText"
                :get-elements="getElements"
              />
            </v-tab-item>
            <v-tab-item>
              <div style="display: block; height: 20px"></div>
              <div style="margin: 0 auto; width: 60%">
                <v-select
                  v-model="editPlanChoice"
                  :items="planMenuItems"
                  class="formInput"
                  outlined
                  label="Choose Plan Method"
                  required
                ></v-select>
              </div>
              <div v-if="editPlanChoice === 'Add Plan'">
                <add-plan
                  :add-test-plan="addTestPlan"
                  :toggle="togglePlanDialog"
                ></add-plan>
              </div>
              <div v-if="editPlanChoice === 'Edit Plan'">
                <edit-plan
                  :update-test-plan="updateTestPlan"
                  :plan-data="allPlans"
                  :stage-data="stageData"
                  :toggle="togglePlanDialog"
                ></edit-plan>
              </div>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
      <v-row v-if="showBtmTabs">
        <v-col>
          <h2 style="font-weight: 400">{{ activeRowTitle }}</h2>
        </v-col>
        <v-col align="right">
          <v-btn
            color="primary"
            class="mr-1"
            style="color: black; height: 48px"
            @click="toggleStepDialog()"
            >Add Steps
          </v-btn>
          <span>&nbsp;</span>
          <v-btn
            color="primary"
            class="mr-1"
            style="color: black; height: 48px"
            @click="toggleTestDialog()"
            >Add Tests
          </v-btn>
          <v-btn
            color="orange"
            class="mr-1"
            style="color: black; height: 48px"
            @click="toggleIssuesDialog()"
            >Add Issues
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="showBtmTabs">
        <v-col cols="4" md="12">
          <v-tabs v-model="btmTab" fixed-tabs background-color="secondary">
            <v-tab v-for="cat in allCategories" :key="cat">
              {{ cat }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="btmTab">
            <v-tab-item>
              <div class="summaryArea">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Plan Items</th>
                        <th class="text-left">Values</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in summaryItems" :key="item.name">
                        <td width="20%">{{ item.name }}</td>
                        <td width="80%">{{ item.props }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
            </v-tab-item>
            <v-tab-item>
              <issues-table
                :issues="planIssues"
                :load-issue-from-table="loadIssueFromTable"
                :issue-status="issueStatus"
              >
              </issues-table>
            </v-tab-item>
            <v-tab-item v-for="category in testCategories" :key="category.id">
              <category-table
                :category-test-items="categoryTestItems"
                :load-test-results="loadTestResults"
                :random-key="catKey"
              ></category-table>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
      <v-dialog v-model="stepDialog" persistent max-width="800">
        <add-step
          :add-plan-step="addSteps"
          :dialog="stepDialog"
          :test-categories="testCategories"
          :full-categories="categories"
          :toggle="toggleStepDialog"
        ></add-step>
      </v-dialog>
      <v-dialog v-model="testsDialog" persistent max-width="800">
        <add-test
          :add-step-test="addTests"
          :dialog="testsDialog"
          :filtered-steps="stepsData"
          :categories="categories"
          :toggle="toggleTestDialog"
        ></add-test>
      </v-dialog>
      <v-dialog v-model="resultsDialog" persistent max-width="960">
        <test-results
          :dialog="resultsDialog"
          :plan-id="selectedPlan"
          :test-data="testData"
          :test-results-data="testResultsData"
          :reload-steps="reloadSteps"
          :toggle="toggleResultsDialog"
        ></test-results>
      </v-dialog>
      <v-dialog v-model="issuesDialog" persistent max-width="960">
        <add-issues
          :dialog="issuesDialog"
          :plan-item="currentPlan"
          :issue-status="issueStatus"
          :add-issue-item="addIssueItem"
          :reload-issues="reloadIssues"
          :toggle="toggleIssuesDialog"
        ></add-issues>
      </v-dialog>
      <v-dialog v-model="updateIssuesDialog" persistent max-width="960">
        <update-issues
          :dialog="updateIssuesDialog"
          :issue-status="issueStatus"
          :plan-item="currentPlan"
          :current-issue="currentIssue"
          :update-issue-item="updateIssueItem"
          :reload-issues="reloadIssues"
          :toggle="toggleUpdateIssuesDialog"
        ></update-issues>
      </v-dialog>
      <v-dialog v-model="faultsDialog" max-width="1280">
        <faults-table
          :dialog="faultsDialog"
          :fault-items="compLvlFaults"
          :toggle="toggleFaultsDialog"
        ></faults-table>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import _ from 'lodash';
import CategoryTable from './categoryTable.vue';
import TestPlanTable from './checkListTable.vue';
import TestResults from './TestResults.vue';
import addPlan from './AddPlan.vue';
import editPlan from './EditPlan.vue';
import addStep from './AddStep.vue';
import addTest from './AddTest.vue';
import addIssues from './AddIssue.vue';
import editIssues from './EditIssue.vue';
import IssuesTable from './IssuesTable.vue';
import FaultsTable from './componentFaults.vue';
// Dummy data for testing
import {
  addPlans,
  addSteps,
  addTests,
  addIssue,
  getCategories,
  getPlans,
  getStages,
  getSteps,
  getStatus,
  getIssues,
  getIssueStatus,
  getTests,
  getTestResults,
  updatePlans,
  getFailedTestCount,
  getRecentPlanItems,
  getCompLvlFaults,
  updateIssue,
} from '@/api/manufacturing';
import { getAllTrucks } from '@/api/trucks';

export default {
  components: {
    TestPlanTable,
    'category-table': CategoryTable,
    'test-plan-table': TestPlanTable,
    'test-results': TestResults,
    'add-plan': addPlan,
    'edit-plan': editPlan,
    'add-step': addStep,
    'add-test': addTest,
    'add-issues': addIssues,
    'update-issues': editIssues,
    'issues-table': IssuesTable,
    'faults-table': FaultsTable,
  },
  data() {
    return {
      activeRowTitle: '',
      allCategories: ['Summary', 'Issues'],
      allPlans: [],
      btmTab: 0,
      catKey: 1,
      categories: [],
      categoryTestItems: [],
      checkList: [],
      compLvlFaults: [],
      currentPlan: null,
      currentIssue: null,
      editPlanChoice: '',
      faultsDialog: false,
      fileNameEpoch: Math.round(new Date().getTime() / 1000),
      filteredSteps: [],
      finishedUnits: [],
      inFlightUnits: [],
      issueStatus: [],
      issuesDialog: false,
      loading: true,
      openDialog: false,
      pendingUnits: [],
      planDialog: false,
      planIssues: [],
      planMenuItems: ['Add Plan', 'Edit Plan'],
      resultsDialog: false,
      search: '',
      searchText: '',
      selectedPlan: 0,
      showBtmTabs: false,
      stageData: [],
      statusList: [],
      stepDialog: false,
      stepsData: [],
      summaryItems: [
        {
          name: 'Truck ID',
          props: 0,
        },
        {
          name: 'Truck/Company',
          props: 0,
        },
        {
          name: 'Description',
          props: '',
        },
        {
          name: 'Failed Tests',
          props: 0,
        },
      ],
      tab: 0,
      tabItems: [],
      testCategories: [],
      testData: {},
      testDetailsData: [],
      testItems: [],
      testKey: 1,
      testResultsData: [],
      testsDialog: false,
      updateIssuesDialog: false,
    };
  },
  computed: {},
  watch: {
    btmTab: function (val) {
      if (val > 0) {
        this.loadStepsToCategories();
      }
    },
  },
  async mounted() {
    await this.populateTabs();
    await this.compilePlans();
  },
  methods: {
    ...mapActions({
      updateSnack: 'updateSnack',
    }),
    addIssueItem: async function (issueData) {
      try {
        const res = await addIssue(this.selectedPlan, issueData);
        if (res.status === 201) {
          this.updateSnack({
            type: 'success',
            message: 'New Issue added. ' + res.status,
          });
        } else {
          this.updateSnack({
            type: 'warning',
            message: 'Status code returned - ' + res.status,
          });
        }
        await this.reloadIssues();
      } catch (e) {
        this.updateSnack({
          type: 'error',
          message: 'Error on adding issue to DB ==> ' + e,
        });
      }
    },
    addSteps: async function (stepData) {
      try {
        const res = await addSteps(stepData);
        if (res.status === 201) {
          this.updateSnack({
            type: 'info',
            message: 'New Step added successfully.',
          });
        } else {
          this.updateSnack({
            type: 'warning',
            message: 'New step encountered an issue - ' + res.status,
          });
        }
        await this.compilePlans();
        await this.getElements(this.selectedPlan);
      } catch (e) {
        this.updateSnack({
          type: 'error',
          message: 'Error on adding steps to DB ==> ' + e,
        });
      }
    },
    addTestPlan: async function (planData) {
      try {
        const res = await addPlans(planData);
        if (res.status === 201) {
          this.updateSnack({
            type: 'success',
            message: 'New Test Plan added.',
          });
          await this.compilePlans();
        } else {
          this.updateSnack({
            type: 'warning',
            message: 'Status code returned - ' + res.status,
          });
        }
      } catch (e) {
        this.updateSnack({
          type: 'error',
          message: 'Error on adding steps to DB ==> ' + e,
        });
      }
    },
    addTests: async function (testData) {
      try {
        const res = await addTests(testData);
        if (res.status === 201) {
          this.updateSnack({
            type: 'success',
            message: 'New Test Item added. ' + res.status,
          });
          await this.compilePlans();
          await this.getElements(this.selectedPlan);
        } else {
          this.updateSnack({
            type: 'warning',
            message: 'Status code returned - ' + res.status,
          });
        }
      } catch (e) {
        this.updateSnack({
          type: 'error',
          message: 'Error on adding tests to DB ==> ' + e,
        });
      }
    },
    compilePlans: async function () {
      const vm = this;
      vm.testItems = [];
      vm.pendingUnits = [];
      vm.inFlightUnits = [];
      vm.finishedUnits = [];
      const [res, res_steps, res_tests, res_status, trucks] = await Promise.all(
        [getPlans(), getSteps(), getTests(), getStatus(), getAllTrucks()]
      );
      let rawPlans = res.data;
      vm.stepsData = res_steps.data;
      vm.testItems = res_tests.data;
      vm.statusList = res_status.data;
      // parse all steps and tests
      for (let step of vm.stepsData) {
        let step_id = step.id;
        step.tests = _.filter(vm.testItems, function (o) {
          return o.step_id === step_id;
        });
        if (step.tests === undefined) {
          step.tests = [];
        }
      }
      // process plans to get summaries
      for (const o of rawPlans) {
        let resUnit = _.find(trucks.data, function (p) {
          return p.id === o.truck_id;
        });
        o.vin = resUnit.vin;
        o.activityDate = vm.convertEpochDateTime(o.activity_date);
        o.deliveryDate = vm.convertEpochDateTime(o.delivery_date);
        let tmpObj = await getFailedTestCount(o.id);
        o.failed_tests = tmpObj.data[0].count;
        let recent_items = await getRecentPlanItems(o.id);
        if (recent_items && recent_items?.data[0] !== undefined) {
          o.recent_tester = recent_items.data[0].tester_name;
          for (let i = 0; i < vm.testItems.length; i++) {
            let item = vm.testItems[i];
            if (item.id === recent_items.data[0].test_id) {
              o.recent_test = item.name;
              break;
            }
          }
        }
      }
      vm.allPlans = rawPlans;
      // Organize plans into stages
      _.forEach(rawPlans, function (o) {
        let currStage = _.find(vm.stageData, function (p) {
          return p.id === o.stage_id;
        });
        switch (currStage.name) {
          case 'Pending':
            vm.pendingUnits.push(o);
            break;
          case 'In-Flight':
            vm.inFlightUnits.push(o);
            break;
          case 'Finished':
            vm.finishedUnits.push(o);
            break;
        }
      });
    },
    convertEpochDateTime: function (unix_time) {
      const new_time = unix_time * 1000;
      return (
        new Date(new_time).toLocaleDateString('en-US') +
        ' ' +
        new Date(new_time).toLocaleTimeString('en-US')
      );
    },
    findPlans: function () {
      const vm = this;
      let planObj = _.find(vm.allPlans, function (o) {
        return o.id === vm.selectedPlan;
      });
      return planObj !== undefined ? planObj : false;
    },
    findStatusName: function (status_id) {
      const vm = this;
      let tmpItem = _.find(vm.statusList, function (o) {
        return o.id === status_id;
      });
      return tmpItem ? tmpItem.status_name : '';
    },
    getElements: async function (plan_id) {
      const vm = this;
      vm.testResultsData = [];
      vm.selectedPlan = parseInt(plan_id, 10);
      vm.currentPlan = vm.findPlans();
      vm.activeRowTitle = vm.currentPlan.truck_number;
      vm.showBtmTabs = true;
      await vm.reloadIssues();
      await vm.reloadSteps();
      await vm.loadSummary();
      await vm.loadStepsToCategories();
    },
    loadIssueFromTable: function (row_issue) {
      this.currentIssue = row_issue;
      this.toggleUpdateIssuesDialog();
    },
    loadStepsToCategories: function () {
      const vm = this;
      vm.categoryTestItems = [];
      let tmpItems = [];
      const tabOffset = vm.btmTab - 1;
      _.forEach(vm.stepsData, function (o) {
        if (o.category_id === tabOffset) {
          tmpItems.push(o);
        }
      });
      vm.categoryTestItems = _.sortBy(tmpItems, function (o) {
        return o.id;
      });
      _.delay(function () {
        vm.testKey = vm.randomKey();
      }, 300);
    },
    // here we need to match tests and steps for the presentation layer;
    // so we merge the data from the two JSON returns into one object to parse
    // into lists and data tables
    loadSummary: function () {
      let desc;
      if (this.currentPlan.description) {
        desc = this.currentPlan.description;
      } else {
        desc = 'No Description Available';
      }
      this.summaryItems = [
        {
          name: 'Truck ID',
          props: this.currentPlan.truck_id,
        },
        {
          name: 'Truck/Company',
          props: this.currentPlan.truck_number,
        },
        {
          name: 'Description',
          props: desc,
        },
        {
          name: 'Failed Tests',
          props: this.currentPlan.failed_tests,
        },
      ];
    },
    loadTestResults: async function (rowData) {
      const testID = rowData.id;
      const currTest = _.find(this.testItems, function (o) {
        return o.id === testID;
      });
      try {
        const res = await getTestResults(this.selectedPlan);
        this.testResultsData = res?.data || [];
        this.testData = currTest;
        this.toggleResultsDialog();
      } catch (e) {
        await this.updateSnack({
          type: 'error',
          message: 'Error on getTestDetails => ' + e,
        });
      }
    },
    populateTabs: async function () {
      const vm = this;
      vm.testCategories = [];
      const [stages, categories, issue_status, res_faults] = await Promise.all([
        getStages(),
        getCategories(),
        getIssueStatus(),
        getCompLvlFaults(),
      ]);
      _.forEach(stages.data, function (o) {
        vm.tabItems.push(o.name);
      });
      vm.issueStatus = issue_status.data;
      vm.tabItems.push('Plan Mgmt');
      vm.stageData = stages.data;
      vm.compLvlFaults = res_faults.data;
      let tmpCats = [];
      _.forEach(categories.data, function (o) {
        tmpCats.push(o.name);
      });
      tmpCats = _.sortBy(tmpCats, function (o) {
        return o.id;
      });
      vm.allCategories = vm.allCategories.concat(tmpCats);
      vm.testCategories = vm.testCategories.concat(tmpCats);
      vm.categories = tmpCats;
      vm.loading = false;
    },
    randomKey: function () {
      return Math.round(Math.random() * (9000 - 1) + 1);
    },
    reloadIssues: async function () {
      const vm = this;
      vm.planIssues = [];
      let tmpIssues = [];
      const res = await getIssues(vm.selectedPlan);
      if (res) {
        _.forEach(res.data, function (o) {
          o.resolved_dt =
            o.resolved_date > o.found_date
              ? vm.convertEpochDateTime(o.resolved_date)
              : '';
          o.found_dt = vm.convertEpochDateTime(o.found_date);
          o.plan_name = vm.currentPlan.name;
          let statusObj = _.find(vm.issueStatus, function (t) {
            return t.id === o.issue_status;
          });
          o.status_conv = statusObj !== undefined ? statusObj.status : 'Poo';
          tmpIssues.push(o);
        });
        vm.planIssues = _.sortBy(tmpIssues, function (o) {
          return o.issue_number;
        });
      }
    },
    reloadSteps: async function () {
      const vm = this;
      const [res_steps, res_tests, results] = await Promise.all([
        getSteps(),
        getTests(),
        getTestResults(vm.selectedPlan),
      ]);
      vm.stepsData = _.clone(res_steps.data);
      vm.testItems = _.clone(res_tests.data);
      for (let step of vm.stepsData) {
        let step_id = step.id;
        step.tests = _.filter(vm.testItems, function (o) {
          return o.step_id === step_id;
        });
        if (step.tests === undefined) {
          step.tests = [];
        }
      }
      vm.testResultsData = _.clone(results.data);
      let resultLen = vm.testResultsData.length;
      if (resultLen > 0) {
        for (let i = 0; i < resultLen; i++) {
          let result = vm.testResultsData[i];
          _.forEach(vm.testItems, function (o) {
            if (o.id === result.test_id) {
              o.recent_tester = result.tester_name;
              o.status = vm.findStatusName(result.status_id);
              o.activityDate = vm.convertEpochDateTime(result.activity_date);
            }
          });
        }
      }
      vm.catKey = Math.floor(Math.random() * (90000 - 1) + 1);
    },
    toggleFaultsDialog() {
      this.faultsDialog = !this.faultsDialog;
    },
    toggleIssuesDialog() {
      this.issuesDialog = !this.issuesDialog;
    },
    togglePlanDialog() {
      this.editPlanChoice = '';
    },
    toggleResultsDialog() {
      this.resultsDialog = !this.resultsDialog;
    },
    toggleStepDialog() {
      this.stepDialog = !this.stepDialog;
    },
    toggleTestDialog() {
      this.testsDialog = !this.testsDialog;
    },
    toggleUpdateIssuesDialog() {
      this.updateIssuesDialog = !this.updateIssuesDialog;
    },
    updateIssueItem: async function (issue_id, issueData) {
      try {
        const res = await updateIssue(issue_id, issueData);
        if (res.status === 201) {
          this.updateSnack({
            type: 'success',
            message: 'Issue updated. ' + res.status,
          });
        } else {
          this.updateSnack({
            type: 'warning',
            message: 'Status code returned - ' + res.status,
          });
        }
        await this.reloadIssues();
      } catch (e) {
        this.updateSnack({
          type: 'error',
          message: 'Error on updating issue ==> ' + e,
        });
      }
    },
    updateTestPlan: async function (plan_id, planData) {
      try {
        const res = await updatePlans(plan_id, planData);
        if (res.status === 201) {
          this.updateSnack({
            type: 'success',
            message: 'Test Plan Saved Successfully.',
          });
          await this.compilePlans();
        } else {
          this.updateSnack({
            type: 'warning',
            message: 'Status code returned - ' + res.status,
          });
        }
      } catch (e) {
        this.updateSnack({
          type: 'error',
          message: 'Error on adding test plan to DB ==> ' + e,
        });
      }
    },
  },
};
</script>
<style scoped>
.summaryArea {
  display: block;
  position: relative;
  width: 50%;
}
.v-list-item__content {
  cursor: pointer;
}
/deep/ tr.v-data-table__selected {
  background-color: #42b02a !important;
}
</style>
