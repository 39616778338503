var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            {
              staticStyle: { "margin-top": "-20px" },
              attrs: { justify: "center", align: "center" },
            },
            [
              _c("v-col", [_c("span", [_vm._v("Filter by status:")])]),
              _c(
                "v-col",
                [
                  _c("v-checkbox", {
                    attrs: { label: "Active", value: "Active" },
                    on: { click: _vm.filterDescriptionTruckDtcData },
                    model: {
                      value: _vm.selected,
                      callback: function ($$v) {
                        _vm.selected = $$v
                      },
                      expression: "selected",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                [
                  _c("v-checkbox", {
                    attrs: { label: "Pending", value: "Pending" },
                    on: { click: _vm.filterDescriptionTruckDtcData },
                    model: {
                      value: _vm.selected,
                      callback: function ($$v) {
                        _vm.selected = $$v
                      },
                      expression: "selected",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                [
                  _c("v-checkbox", {
                    attrs: { label: "Cleared", value: "Cleared" },
                    on: { click: _vm.filterDescriptionTruckDtcData },
                    model: {
                      value: _vm.selected,
                      callback: function ($$v) {
                        _vm.selected = $$v
                      },
                      expression: "selected",
                    },
                  }),
                ],
                1
              ),
              _c("v-col", { attrs: { cols: "6" } }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { align: "right", cols: "12", xl: "4", lg: "4" } },
            [
              _c("v-select", {
                staticClass: "'select'",
                attrs: {
                  items: _vm.descriptionTypes,
                  label: "DTC Description Type",
                  placeholder: "Description Type",
                  "persistent-hint": "",
                  "return-object": "",
                  outlined: "",
                },
                on: { change: _vm.filterDescriptionTruckDtcData },
                model: {
                  value: _vm.selectedDescription,
                  callback: function ($$v) {
                    _vm.selectedDescription = $$v
                  },
                  expression: "selectedDescription",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "context-menu",
        { ref: "ctxMenu", attrs: { id: "context-menu" } },
        [
          _c(
            "v-list",
            _vm._l(_vm.actionItems, function (route, index) {
              return _c(
                "v-list-item",
                {
                  key: index,
                  class: route.title,
                  on: {
                    click: function ($event) {
                      return _vm.setRoute(null, route.title)
                    },
                  },
                },
                [_vm._v(_vm._s(route.title))]
              )
            }),
            1
          ),
        ],
        1
      ),
      _c("v-data-table", {
        attrs: {
          "no-data-text": _vm.noDataMsg,
          headers: _vm.labels,
          items: _vm.truckDtcFiltered,
          loading: _vm.loading,
          "loading-text": "Loading... Please wait",
        },
        on: { "click:row": _vm.showMenu },
        scopedSlots: _vm._u(
          [
            {
              key: `item.status`,
              fn: function ({ item }) {
                return [
                  _c("td", { staticClass: "text-xs-center narrow-col" }, [
                    _c(
                      "button",
                      {
                        staticClass: "status text-color-black",
                        class: _vm.statusColor(item.status),
                      },
                      [_vm._v(" " + _vm._s(item.status) + " ")]
                    ),
                  ]),
                ]
              },
            },
            {
              key: `item.actions`,
              fn: function ({ item }) {
                return [
                  _c(
                    "v-menu",
                    {
                      staticStyle: { top: "312px", left: "300px" },
                      attrs: { "offset-y": "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on }) {
                              return [
                                _c("v-icon", _vm._g({}, on), [
                                  _vm._v("mdi-dots-vertical"),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _c(
                        "v-list",
                        _vm._l(_vm.actionItems, function (route, index) {
                          return _c(
                            "v-list-item",
                            {
                              key: index,
                              class: route.title,
                              on: {
                                click: function ($event) {
                                  return _vm.setRoute(item, route.title)
                                },
                              },
                            },
                            [_vm._v(_vm._s(route.title))]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }