var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "ml-3", attrs: { fluid: "" } },
    [
      _vm.scatterView
        ? [
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { staticClass: "ml-3 pb-0", attrs: { cols: "3" } },
                  [
                    _c("h1", { staticStyle: { "font-weight": "400" } }, [
                      _vm._v("Performance"),
                    ]),
                  ]
                ),
                _c(
                  "v-col",
                  { attrs: { align: "left" } },
                  [
                    _vm.liveView
                      ? _c("LiveFleetViewBanner", {
                          attrs: { "fleet-info": _vm.selectedCompany },
                          on: {
                            closed: function ($event) {
                              _vm.liveView = false
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "v-row",
              [
                _c("v-col", { attrs: { cols: "12" } }, [
                  _c(
                    "div",
                    { staticClass: "perfBar" },
                    [
                      _c(
                        "v-row",
                        { staticStyle: { padding: "14px" } },
                        [
                          _c("v-col", { staticClass: "perfCol" }, [
                            _c("div", { staticClass: "label" }, [
                              _vm._v("Total Fuel Economy"),
                            ]),
                            _c(
                              "div",
                              { staticClass: "perfValue" },
                              [
                                _vm._v(" " + _vm._s(_vm.mpge) + " MPGe "),
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: {
                                      bottom: "",
                                      "max-width": "222",
                                      color: "#1a1a1a",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass: "infoBtn",
                                                      staticStyle: {
                                                        "margin-top": "-12px",
                                                      },
                                                      attrs: { small: "" },
                                                    },
                                                    "v-icon",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [_vm._v("mdi-information")]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3178877610
                                    ),
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        " Calculated using fuel burned and miles traveled. Updates if date ranges below are changed. "
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("v-col", { staticClass: "perfCol" }, [
                            _c("div", { staticClass: "label" }, [
                              _vm._v("Fuel Savings"),
                            ]),
                            _c(
                              "div",
                              { staticClass: "perfValue" },
                              [
                                _vm._v(" $ " + _vm._s(_vm.fuelSavings) + " "),
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: {
                                      bottom: "",
                                      "max-width": "222",
                                      color: "#1a1a1a",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass: "infoBtn",
                                                      staticStyle: {
                                                        "margin-top": "-12px",
                                                      },
                                                      attrs: { small: "" },
                                                    },
                                                    "v-icon",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [_vm._v("mdi-information")]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3178877610
                                    ),
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        " Calculated fuel savings for Natural Gas compared to user selected comparison fuel based on fuel costs and diesel mpg defined in the settings. Updates if date ranges below are changed. "
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("v-col", { staticClass: "perfCol" }, [
                            _c("div", { staticClass: "label" }, [
                              _vm._v("CO"),
                              _c("sub", [_vm._v("2")]),
                              _vm._v(" Reduction"),
                            ]),
                            _c(
                              "div",
                              { staticClass: "perfValue" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.carbon !== null ? _vm.carbon : "—"
                                    ) +
                                    " lb "
                                ),
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: {
                                      bottom: "",
                                      "max-width": "222",
                                      color: "#1a1a1a",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass: "infoBtn",
                                                      staticStyle: {
                                                        "margin-top": "-12px",
                                                      },
                                                      attrs: { small: "" },
                                                    },
                                                    "v-icon",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [_vm._v("mdi-information")]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3178877610
                                    ),
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        " Calculated using the GHG Protocol and presenting the carbon reduction compared to user selected comparison fuel. Updates if date ranges below are changed. "
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("v-col", { staticClass: "perfCol" }, [
                            _c("div", { staticClass: "label" }, [
                              _vm._v("N"),
                              _c("sub", [_vm._v("2")]),
                              _vm._v("0 Reduction"),
                            ]),
                            _c(
                              "div",
                              { staticClass: "perfValue" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.nox !== null ? _vm.nox : "—") +
                                    " lb "
                                ),
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: {
                                      bottom: "",
                                      "max-width": "222",
                                      color: "#1a1a1a",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass: "infoBtn",
                                                      staticStyle: {
                                                        "margin-top": "-12px",
                                                      },
                                                      attrs: { small: "" },
                                                    },
                                                    "v-icon",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [_vm._v("mdi-information")]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3178877610
                                    ),
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        " Calculated based on miles traveled and presenting the N"
                                      ),
                                      _c("sub", [_vm._v("2")]),
                                      _vm._v(
                                        "0 reduction compared to user selected comparison fuel. Updates if date ranges below are changed. "
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("v-col", { staticClass: "perfCol" }, [
                            _c("div", { staticClass: "label" }, [
                              _vm._v("Journeys Taken"),
                            ]),
                            _c(
                              "div",
                              { staticClass: "perfValue" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.journeyCount != null
                                        ? _vm.journeyCount
                                        : "—"
                                    ) +
                                    " "
                                ),
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: {
                                      bottom: "",
                                      "max-width": "222",
                                      color: "#1a1a1a",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass: "infoBtn",
                                                      staticStyle: {
                                                        "margin-top": "-12px",
                                                      },
                                                      attrs: { small: "" },
                                                    },
                                                    "v-icon",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [_vm._v("mdi-information")]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3178877610
                                    ),
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        "The total number of completed journeys. Updates if date ranges below are changed."
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("v-col", { staticClass: "perfCol" }, [
                            _c("div", { staticClass: "label" }, [
                              _vm._v("Active Days"),
                            ]),
                            _c(
                              "div",
                              { staticClass: "perfValue" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.activeDays != null
                                        ? _vm.activeDays
                                        : "—"
                                    ) +
                                    " "
                                ),
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: {
                                      bottom: "",
                                      "max-width": "222",
                                      color: "#1a1a1a",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass: "infoBtn",
                                                      staticStyle: {
                                                        "margin-top": "-12px",
                                                      },
                                                      attrs: { small: "" },
                                                    },
                                                    "v-icon",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [_vm._v("mdi-information")]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3178877610
                                    ),
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        "The total number of days any number of trucks are active in your fleet. If 5 are active for 1 day, it is 5. If 5 are active for 5 days, it is 25. Updates if date ranges below are changed."
                                      ),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _vm.userCanUpdateFleetPreferences
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "cog",
                                  staticStyle: {
                                    display: "block",
                                    margin: "auto",
                                    "background-color": "#1a1a1a",
                                  },
                                  attrs: { icon: "", tile: "", large: "" },
                                  on: { click: _vm.openFleetSettings },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { staticStyle: { opacity: "0.6" } },
                                    [_vm._v("mdi-cog")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  [
                    _vm.ready
                      ? _c("scatter", {
                          attrs: {
                            company: _vm.company,
                            "set-journeys": _vm.setJourneys,
                            "send-extremes": _vm.setExtremes,
                            "set-trucks": _vm.setVisibleTrucks,
                          },
                          on: { journeySelected: _vm.showJourney },
                        })
                      : _c("v-skeleton-loader", {
                          attrs: { type: "table-thead", elevation: "2" },
                        }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : [
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { staticClass: "mx-0" },
                  [
                    _c("JourneyDetails", {
                      attrs: { "journey-data": _vm.journeyData },
                      on: { showScatter: _vm.showScatter },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }