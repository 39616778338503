<template>
  <highcharts v-if="dataProcessed" ref="column" :options="options"></highcharts>
</template>

<script>
export default {
  props: ['data'],
  data() {
    return {
      dataProcessed: false,
      options: {
        chart: {
          backgroundColor: '#1E1E1E',
          type: 'column',
          zoomType: 'x',
          name: '',
        },
        rangeSelector: {
          selected: 1,
        },
        title: {
          text: 'Data Load',
          style: {
            color: '#FFFFFF',
          },
        },
        tooltip: {
          shared: true,
          backgroundColor: '#363636',
          borderRadius: 8,
          borderColor: '#363636',
          style: {
            color: '#FFFFFF',
          },
        },
        legend: {
          itemStyle: {
            color: '#FFFFFF',
            fontWeight: 'bold',
          },
        },
        plotOptions: {
          series: {
            borderColor: '#1E1E1E',
          },
        },
        xAxis: {
          type: 'datetime',
          title: {
            text: 'Time',
            style: {
              color: '#FFFFFF',
            },
          },
          labels: {
            step: 1,
            style: {
              color: '#FFFFFF',
            },
          },
          lineColor: 'rgba(255, 255, 255, 0.24)',
        },
        yAxis: [
          {
            gridLineColor: ' rgba(255, 255, 255, 0.12)',
            title: {
              text: 'Size (MB)',
            },
            labels: {
              format: '{value}',
              style: {
                color: 'white',
              },
            },
          },
          {
            gridLineColor: ' rgba(255, 255, 255, 0.12)',
            title: {
              text: 'Count',
            },
            labels: {
              format: '{value}',
              style: {
                color: 'white',
              },
            },
            opposite: true,
          },
        ],
        setOptions: {
          width: '100%',
        },
        series: [],
      },
    };
  },
  mounted() {
    this.processData();
  },
  methods: {
    processData() {
      const colors = ['#DF1278', '#0070F2', '#188918', '#07838F', '#78909C'];
      const colors3 = ['#FE83AE', '#4DB1FF', '#5DC122', '#00CEAC', '#B0BEC5'];

      let erxNumberTrucks = [];
      let erxTotalMBSize = [];
      let exNumberTrucks = [];
      let exTotalMBSize = [];

      for (const row of this.data) {
        erxNumberTrucks.push([Date.parse(row.timestamp), row.erxNumberTrucks]);
        erxTotalMBSize.push([Date.parse(row.timestamp), row.erxTotalMBSize]);
        exNumberTrucks.push([Date.parse(row.timestamp), row.exNumberTrucks]);
        exTotalMBSize.push([Date.parse(row.timestamp), row.exTotalMBSize]);
      }

      this.options.series = [
        {
          type: 'column',
          name: 'ERX Total MB Size',
          data: erxTotalMBSize,
          color: colors[0],
          yAxis: 0,
          stacking: 'normal',
        },

        {
          type: 'column',
          name: 'EX Total MB Size',
          data: exTotalMBSize,
          color: colors[1],
          yAxis: 0,
          stacking: 'normal',
        },
        {
          type: 'line',
          name: 'ERX Number Trucks',
          data: erxNumberTrucks,
          color: colors3[0],
          yAxis: 1,
        },
        {
          type: 'line',
          name: 'EX Number Trucks',
          data: exNumberTrucks,
          color: colors3[1],
          yAxis: 1,
        },
      ];
      this.dataProcessed = true;
    },
  },
};
</script>
