var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { "box-shadow": "0px 1px 8px 0px rgb(212, 14, 14)," } },
    [
      _c(
        "v-expansion-panels",
        {
          staticStyle: {
            width: "367px",
            "box-shadow": "0px 1px 8px 0px rgb(212, 14, 14),",
          },
          model: {
            value: _vm.panels,
            callback: function ($$v) {
              _vm.panels = $$v
            },
            expression: "panels",
          },
        },
        [
          _c(
            "v-expansion-panel",
            {
              staticStyle: {
                "background-color": "#1a1a1a",
                "box-shadow": "0px 1px 8px 0px rgb(212, 14, 14),",
              },
            },
            [
              _c("v-expansion-panel-header", { staticClass: "title" }, [
                _c("span", [
                  _c(
                    "svg",
                    {
                      staticStyle: {
                        "margin-right": "10px",
                        "margin-bottom": "-5px",
                      },
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        width: "24",
                        height: "24",
                        viewBox: "0 0 24 24",
                        fill: "none",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M6.5 8.11C5.61 8.11 4.89 7.39 4.89 6.5C4.89 6.073 5.05962 5.66349 5.36156 5.36156C5.66349 5.05962 6.073 4.89 6.5 4.89C7.39 4.89 8.11 5.61 8.11 6.5C8.11 6.927 7.94038 7.33651 7.63844 7.63844C7.33651 7.94038 6.927 8.11 6.5 8.11ZM6.5 2C4 2 2 4 2 6.5C2 9.87 6.5 14.86 6.5 14.86C6.5 14.86 11 9.87 11 6.5C11 4 9 2 6.5 2ZM17.5 8.11C17.073 8.11 16.6635 7.94038 16.3616 7.63844C16.0596 7.33651 15.89 6.927 15.89 6.5C15.89 5.61 16.61 4.89 17.5 4.89C18.39 4.89 19.11 5.61 19.11 6.5C19.11 6.927 18.9404 7.33651 18.6384 7.63844C18.3365 7.94038 17.927 8.11 17.5 8.11ZM17.5 2C15 2 13 4 13 6.5C13 9.87 17.5 14.86 17.5 14.86C17.5 14.86 22 9.87 22 6.5C22 4 20 2 17.5 2ZM17.5 16C16.23 16 15.1 16.8 14.68 18H9.32C8.77 16.44 7.05 15.62 5.5 16.17C3.93 16.72 3.11 18.44 3.66 20C4.22 21.56 5.93 22.38 7.5 21.83C8.35 21.53 9 20.85 9.32 20H14.69C15.24 21.56 16.96 22.38 18.5 21.83C20.08 21.28 20.9 19.56 20.35 18C19.92 16.8 18.78 16 17.5 16ZM17.5 20.5C17.1022 20.5 16.7206 20.342 16.4393 20.0607C16.158 19.7794 16 19.3978 16 19C16 18.6022 16.158 18.2206 16.4393 17.9393C16.7206 17.658 17.1022 17.5 17.5 17.5C17.8978 17.5 18.2794 17.658 18.5607 17.9393C18.842 18.2206 19 18.6022 19 19C19 19.3978 18.842 19.7794 18.5607 20.0607C18.2794 20.342 17.8978 20.5 17.5 20.5Z",
                          fill: "#888888",
                        },
                      }),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "truncate" }, [
                  _vm._v(" " + _vm._s(_vm.journeyName || "Route") + " "),
                ]),
              ]),
              _c("v-expansion-panel-content", [
                _c("div", { staticClass: "panelContent" }, [
                  _c(
                    "div",
                    { staticClass: "markerIcons" },
                    [
                      _c(
                        "v-icon",
                        {
                          staticStyle: { "margin-top": "10px" },
                          attrs: { color: "#888888" },
                        },
                        [_vm._v("mdi-map-marker")]
                      ),
                      _c(
                        "svg",
                        {
                          staticStyle: { display: "block", margin: "auto" },
                          attrs: {
                            width: "2",
                            height: "34",
                            viewBox: "0 0 2 34",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg",
                          },
                        },
                        [
                          _c("line", {
                            attrs: {
                              x1: "1",
                              y1: "4.37114e-08",
                              x2: "0.999999",
                              y2: "34",
                              stroke: "#888888",
                              "stroke-width": "2",
                              "stroke-dasharray": "4 4",
                            },
                          }),
                        ]
                      ),
                      _c(
                        "v-icon",
                        {
                          staticStyle: { "margin-bottom": "10px" },
                          attrs: { color: "#EF5350" },
                        },
                        [_vm._v("mdi-map-marker")]
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "dateLocationContainer" }, [
                    _c("div", { staticClass: "dateLocation" }, [
                      _c("div", { staticClass: "date" }, [
                        _vm._v(" Start date/Location: "),
                        _c("span", { staticClass: "inlineDate" }, [
                          _vm._v(_vm._s(_vm.startDate)),
                        ]),
                      ]),
                      _c("div", { staticClass: "location" }, [
                        _vm._v(_vm._s(_vm.startLocation)),
                      ]),
                    ]),
                    _c("br"),
                    _c("div", { staticClass: "dateLocation" }, [
                      _c("div", { staticClass: "date" }, [
                        _vm._v(" End date/Location: "),
                        _c("span", { staticClass: "inlineDate" }, [
                          _vm._v(_vm._s(_vm.endDate)),
                        ]),
                      ]),
                      _c("div", { staticClass: "location" }, [
                        _vm._v(" " + _vm._s(_vm.endLocation) + " "),
                      ]),
                    ]),
                  ]),
                ]),
                _c(
                  "div",
                  [
                    _c(
                      "v-icon",
                      { staticClass: "mb-2", attrs: { color: "#888888" } },
                      [_vm._v("mdi-sign-direction")]
                    ),
                    _c("span", { staticClass: "miles" }, [
                      _vm._v(" Journey Miles: "),
                      _c("span", { staticClass: "inlineDate" }, [
                        _vm._v(_vm._s(_vm.miles)),
                      ]),
                    ]),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }