<template>
  <v-container fluid>
    <v-row
      ><v-col class="pl-0 pb-0"
        ><h3>
          {{ notInUseTrucks ? notInUseTrucks.length : 0 }} Results
        </h3></v-col
      ></v-row
    >
    <v-row>
      <v-col class="pa-0" cols="12">
        <not-in-use-table :trucks="notInUseTrucks" @viewClicked="viewTruck" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import NotInUseTableTemplate from './TableUtils/NotInUseTableTemplate';
export default {
  name: 'NotInUseTable',
  components: { 'not-in-use-table': NotInUseTableTemplate },
  props: {
    notInUseTrucks: {
      type: Array,
      required: true,
    },
    toggleDetails: {
      type: Function,
      default: () => {},
    },
    setDetails: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  methods: {
    viewTruck(truck) {
      this.toggleDetails(true);
      this.setDetails(truck);
    },
  },
};
</script>
<style scoped></style>
