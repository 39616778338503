var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.positionData && !_vm.emptyPosition
      ? _c(
          "div",
          [
            _vm.warningMessage
              ? _c(
                  "v-alert",
                  { staticClass: "alertWarning", attrs: { type: "warning" } },
                  [_vm._v(" " + _vm._s(_vm.warningMessage) + " ")]
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "rangeFilter" },
              [
                _c("p", [_vm._v("Map Details")]),
                _vm.noData
                  ? _c("div", { staticClass: "noData" }, [
                      _vm._v("No data for this time period"),
                    ])
                  : _c("div", { staticClass: "noData" }),
                _c(
                  "v-menu",
                  {
                    ref: "menu",
                    attrs: {
                      "close-on-content-click": false,
                      "return-value": _vm.dates,
                    },
                    on: {
                      "update:returnValue": function ($event) {
                        _vm.dates = $event
                      },
                      "update:return-value": function ($event) {
                        _vm.dates = $event
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "v-text-field",
                                _vm._b(
                                  {
                                    directives: [
                                      {
                                        name: "mask",
                                        rawName: "v-mask",
                                        value: "##/##/#### - ##/##/####",
                                        expression: "'##/##/#### - ##/##/####'",
                                      },
                                    ],
                                    attrs: {
                                      type: "text",
                                      outlined: "",
                                      "append-icon": "mdi-calendar",
                                    },
                                    on: { "click:append": on.click },
                                    model: {
                                      value: _vm.dateInput,
                                      callback: function ($$v) {
                                        _vm.dateInput = $$v
                                      },
                                      expression: "dateInput",
                                    },
                                  },
                                  "v-text-field",
                                  attrs,
                                  false
                                )
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1999727006
                    ),
                    model: {
                      value: _vm.menu,
                      callback: function ($$v) {
                        _vm.menu = $$v
                      },
                      expression: "menu",
                    },
                  },
                  [
                    _c(
                      "v-date-picker",
                      {
                        attrs: { range: "" },
                        model: {
                          value: _vm.dates,
                          callback: function ($$v) {
                            _vm.dates = $$v
                          },
                          expression: "dates",
                        },
                      },
                      [
                        _c("v-spacer"),
                        _c(
                          "v-btn",
                          {
                            attrs: { text: "", color: "primary" },
                            on: {
                              click: function ($event) {
                                _vm.menu = false
                              },
                            },
                          },
                          [_vm._v(" Cancel ")]
                        ),
                        _c(
                          "v-btn",
                          {
                            attrs: { text: "", color: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.$refs.menu.save(_vm.dates)
                              },
                            },
                          },
                          [_vm._v(" OK ")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("div", { attrs: { id: "dateGuide" } }, [
                  _vm._v("MM/DD/YYYY - MM/DD/YYYY"),
                ]),
                _c(
                  "div",
                  [
                    _c(
                      "p",
                      {
                        staticClass: "smallText",
                        staticStyle: { "margin-bottom": "-12px" },
                      },
                      [_vm._v("Display Icons")]
                    ),
                    _c("v-checkbox", {
                      attrs: {
                        label: "Show Events",
                        disabled: _vm.historyDisabled,
                      },
                      model: {
                        value: _vm.showEvents,
                        callback: function ($$v) {
                          _vm.showEvents = $$v
                        },
                        expression: "showEvents",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c(
                      "p",
                      {
                        staticClass: "smallText",
                        staticStyle: {
                          "margin-bottom": "-12px",
                          "margin-top": "-7px",
                        },
                      },
                      [_vm._v(" Display Company Trucks ")]
                    ),
                    _vm.positionData.truck_type.toUpperCase().includes("ERX")
                      ? _c(
                          "div",
                          [
                            _c("v-checkbox", {
                              attrs: {
                                label: "Show All Trucks",
                                disabled: _vm.allTrucksDisabled,
                              },
                              model: {
                                value: _vm.showAllTrucks,
                                callback: function ($$v) {
                                  _vm.showAllTrucks = $$v
                                },
                                expression: "showAllTrucks",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm.positionData.truck_type.toUpperCase().includes("EX")
                      ? _c(
                          "div",
                          [
                            _c("v-checkbox", {
                              attrs: {
                                label: `Show All CNG Trucks`,
                                disabled:
                                  _vm.allTrucksDisabled ||
                                  _vm.cngPolylines.length === 0,
                              },
                              model: {
                                value: _vm.showAllCNG,
                                callback: function ($$v) {
                                  _vm.showAllCNG = $$v
                                },
                                expression: "showAllCNG",
                              },
                            }),
                            _c("v-checkbox", {
                              staticStyle: { "margin-top": "-15px" },
                              attrs: {
                                label: `Show All Diesel Trucks`,
                                disabled:
                                  _vm.allTrucksDisabled ||
                                  _vm.dieselPolylines.length === 0,
                              },
                              model: {
                                value: _vm.showAllDiesel,
                                callback: function ($$v) {
                                  _vm.showAllDiesel = $$v
                                },
                                expression: "showAllDiesel",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "p",
                      {
                        staticClass: "smallText",
                        staticStyle: {
                          "margin-bottom": "-12px",
                          "margin-top": "-7px",
                        },
                      },
                      [_vm._v(" Display Elevation Profile ")]
                    ),
                    _c("v-checkbox", {
                      attrs: {
                        label: "Show Elevation",
                        disabled: _vm.historyDisabled,
                      },
                      model: {
                        value: _vm.showElevation,
                        callback: function ($$v) {
                          _vm.showElevation = $$v
                        },
                        expression: "showElevation",
                      },
                    }),
                  ],
                  1
                ),
                _vm.historyDisabled
                  ? _c(
                      "v-row",
                      { staticStyle: { "margin-bottom": "4px" } },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "3" } },
                          [
                            _c("v-progress-circular", {
                              attrs: { indeterminate: "", color: "primary" },
                            }),
                          ],
                          1
                        ),
                        _c("v-col", [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-top": "3px",
                                "margin-left": "-5px",
                              },
                            },
                            [_vm._v(" Loading History... ")]
                          ),
                        ]),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      [
                        _c(
                          "v-btn",
                          {
                            staticStyle: { "margin-bottom": "14px" },
                            attrs: { color: "primary" },
                            on: { click: _vm.submit },
                          },
                          [_vm._v("Submit")]
                        ),
                      ],
                      1
                    ),
              ],
              1
            ),
            _c("div", { attrs: { id: "map" } }, [
              _c("div", {
                ref: "hereMap",
                style: `height: ${_vm.mapHeight}px; width: 100%`,
                attrs: { id: "mapContainer" },
              }),
            ]),
            _c(
              "div",
              [
                _vm.showElevation && !_vm.historyDisabled
                  ? _c("Elevation", { attrs: { data: _vm.elevationData } })
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        )
      : _vm.emptyPosition
      ? _c(
          "div",
          {
            staticStyle: {
              display: "block",
              margin: "auto",
              "text-align": "center",
              "margin-top": "50px",
            },
          },
          [_vm._v(" No position Data ")]
        )
      : _c(
          "div",
          [
            _c("v-progress-circular", {
              staticStyle: {
                display: "block",
                margin: "auto",
                "margin-top": "50px",
              },
              attrs: { indeterminate: "", color: "primary" },
            }),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }