<template>
  <div id="user-details">
    <loader v-if="loading" />
    <v-container fluid class="mt-3 mx-3">
      <v-row class="mr-3">
        <v-col class="pa-0" align="left" cols="12" xl="3" lg="3">
          <v-breadcrumbs
            class="pl-3"
            :items="addCrumb"
            divider="/"
          ></v-breadcrumbs>
        </v-col>
        <v-col><v-spacer /></v-col>
      </v-row>
      <v-row class="mr-3 mt-0">
        <v-col
          ><h1>{{ selectedUser.display_name }}</h1></v-col
        >
        <v-col><v-spacer /></v-col>
      </v-row>
      <v-row class="mr-3 mt-0">
        <v-col cols="12" md="12" lg="12" xl="12">
          <v-expansion-panels v-model="panelIndex">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <v-row>
                  <v-col>
                    <h2>User Details</h2>
                    <br />
                    <span
                      v-if="panelIndex === undefined"
                      class="secondary-text"
                    >
                      Expand to view specific user details</span
                    >
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row class="px-1">
                  <v-col cols="12" md="3" lg="3" xl="3" align="left">
                    <span class="secondary-text">User Name</span><br /><b>{{
                      selectedUser.display_name
                    }}</b>
                  </v-col>
                  <v-col cols="12" md="3" lg="3" xl="3" align="left">
                    <span class="secondary-text">Employer Company</span>
                    <br /><b>{{ selectedUser.employer_company }}</b>
                  </v-col>
                  <v-col cols="12" md="3" lg="3" xl="3" align="left">
                    <span class="secondary-text">Company Access</span>
                    <br /><b>{{ selectedUser.company_access }}</b>
                  </v-col>
                  <v-col cols="12" md="3" lg="3" xl="3" align="left">
                    <span class="secondary-text">Role</span> <br /><b>{{
                      selectedUser.role
                    }}</b>
                  </v-col>
                </v-row>
                <v-row class="px-1">
                  <v-col cols="12" md="3" lg="3" xl="3" align="left">
                    <span class="secondary-text">User Email</span><br /><b>{{
                      selectedUser.user_principal_name
                    }}</b>
                  </v-col>
                  <v-col cols="12" md="3" lg="3" xl="3" align="left">
                    <span class="secondary-text">User ID</span>
                    <br /><b>{{ selectedUser.object_id }}</b>
                  </v-col>
                  <v-col cols="12" md="3" lg="3" xl="3" align="left">
                    <span class="secondary-text">Is Active</span> <br /><b>{{
                      selectedUser.is_active
                    }}</b>
                  </v-col>
                  <v-col cols="12" md="3" lg="3" xl="3" align="left">
                    <span class="secondary-text">Role Description</span>
                    <br /><b>{{ selectedUser.role_description }}</b>
                  </v-col>
                </v-row>
                <v-row class="px-1">
                  <v-col cols="12" md="3" lg="3" xl="3" align="left">
                    <span class="secondary-text">Last Seen</span>
                    <br /><b>{{ selectedUser.last_seen }}</b>
                  </v-col>
                  <v-col cols="12" md="3" lg="3" xl="3" align="left"> </v-col>
                  <v-col cols="12" md="3" lg="3" xl="3" align="left"> </v-col>
                  <v-col cols="12" md="3" lg="3" xl="3" align="left"></v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="3" lg="3" xl="3" align="left">
                    <v-btn
                      v-if="checkUser()"
                      class="pl-1 pt-0"
                      text
                      color="primary"
                      @click="editUser = true"
                      ><span>Edit Profile</span></v-btn
                    >
                  </v-col>
                  <v-col cols="12" md="3" lg="3" xl="3" align="left"> </v-col>
                  <v-col cols="12" md="3" lg="3" xl="3" align="left"> </v-col>
                  <v-col cols="12" md="3" lg="3" xl="3" align="left"></v-col>
                </v-row>
                <v-row class="px-1"> </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
      <v-divider class="mr-6" style="border-width: thin"></v-divider>
    </v-container>
    <edit-user
      v-if="editUser"
      :user="selectedUser"
      @refresh="refreshData"
      @closed="editUser = false"
    />
  </div>
</template>
<script>
import EditUser from './EditUser';
import UserManagement from './UserManagement';
import { mapGetters } from 'vuex';
import { getUsers } from '@/api/users';
import { getCompanies } from '@/api/company';
import loader from '@/utilities/loader';
import dateFormat from 'dateformat';

export default {
  name: 'UserDetails',
  components: { loader, 'edit-user': EditUser },
  data() {
    return {
      editUser: false,
      panelIndex: undefined, // Hack for show/hide panel text
      loading: false,
      showAlert: false,
      alertType: '',
      alertMessage: '',
      oid: '',
      selectedUser: {},
      companies: [],
    };
  },
  computed: {
    ...mapGetters({
      userRoles: 'getUserRoles',
      userOID: 'getUserOID',
    }),
    addCrumb: function () {
      return [
        {
          text: 'Users',
          disabled: false,
          exact: true,
          link: true,
          to: {
            name: 'Users',
            params: {
              paramName: UserManagement,
            },
          },
        },
        {
          text: `${this.selectedUser.display_name || ''}`,
          disabled: true,
          href: `/user-management/${this.selectedUser.display_name || ''}`,
        },
      ];
    },
  },
  watch: {},
  created() {
    this.oid = this.$route.params.id;
  },
  async mounted() {
    if (this.oid) {
      await this.getUserData();
    } else {
      this.$router.push(`/user_management`); //If the route param does not exist, it will redirect user back to user management page
    }
  },
  methods: {
    async getUserData() {
      try {
        const { data } = await getUsers(this.oid);
        this.selectedUser = data[0];

        let company = await getCompanies();
        this.companies = company.data;

        if (this.selectedUser.has_all_company_access) {
          this.selectedUser.company_access = 'All';
        } else if (this.selectedUser.company_ids.length) {
          this.selectedUser.company_access = this.companies
            .filter((c) => this.selectedUser.company_ids.includes(c.id))
            .map((ic) => ic.name)
            .join(', ');
        } else {
          this.selectedUser.company_access = 'None';
        }

        this.selectedUser.last_seen = this.selectedUser.last_seen
          ? dateFormat(
              new Date(this.selectedUser.last_seen),
              'mm/dd/yy h:MM TT'
            )
          : '';

        this.selectedUser.employer_company = this.companies
          .filter((el) => el.id === this.selectedUser.employer_company_id)
          .map((ec) => ec.name)
          .join('');
      } catch (e) {
        console.log('There was an issue retrieving user information', e);
      }
    },
    async refreshData() {
      // This will re-grab the data from the back-end with the updated user data
      await this.getUserData();
    },
    checkUser() {
      return this.userOID === this.oid ? false : true;
    },
  },
};
</script>

<style scoped>
.secondary-text {
  font-weight: 100;
}
.btn-text {
  color: '#43B02A';
}
</style>
