var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      staticClass: "dialog",
      attrs: { width: "750" },
      model: {
        value: _vm.openDialog,
        callback: function ($$v) {
          _vm.openDialog = $$v
        },
        expression: "openDialog",
      },
    },
    [
      _vm.loading ? _c("loader") : _vm._e(),
      _c(
        "v-card",
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                { staticClass: "ma-2" },
                [
                  _c(
                    "v-col",
                    {
                      attrs: {
                        align: "center",
                        cols: "12",
                        sm: "12",
                        md: "12",
                        lg: "12",
                        xl: "12",
                      },
                    },
                    [
                      _c("div", { staticClass: "font-weight-bold text-h5" }, [
                        _vm._v("Add Unit"),
                      ]),
                      _c(
                        "v-card",
                        {
                          staticClass: "padding margin",
                          attrs: { align: "center" },
                        },
                        [
                          _c(
                            "v-form",
                            {
                              ref: "userForm",
                              attrs: { "lazy-validation": "" },
                              model: {
                                value: _vm.truckValid,
                                callback: function ($$v) {
                                  _vm.truckValid = $$v
                                },
                                expression: "truckValid",
                              },
                            },
                            [
                              _c("v-toolbar-title", [
                                _vm._v("Truck Registration"),
                              ]),
                              _c("v-select", {
                                attrs: {
                                  items: _vm.companyItems,
                                  label: "Select Company",
                                },
                                model: {
                                  value: _vm.customer,
                                  callback: function ($$v) {
                                    _vm.customer = $$v
                                  },
                                  expression: "customer",
                                },
                              }),
                              _c("v-select", {
                                attrs: {
                                  items: _vm.platformList,
                                  label: "Select Platform",
                                },
                                model: {
                                  value: _vm.truckPlatform,
                                  callback: function ($$v) {
                                    _vm.truckPlatform = $$v
                                  },
                                  expression: "truckPlatform",
                                },
                              }),
                              _c("v-select", {
                                attrs: {
                                  items: _vm.filteredTypeList,
                                  disabled: _vm.truckTypeDisabled,
                                  label: "Select Truck Type",
                                },
                                model: {
                                  value: _vm.truckType,
                                  callback: function ($$v) {
                                    _vm.truckType = $$v
                                  },
                                  expression: "truckType",
                                },
                              }),
                              _c("v-text-field", {
                                attrs: {
                                  rules: _vm.truckRules,
                                  disabled: _vm.truckDisabled,
                                  label: "Truck Number",
                                  required: "",
                                },
                                model: {
                                  value: _vm.truckName,
                                  callback: function ($$v) {
                                    _vm.truckName = $$v
                                  },
                                  expression: "truckName",
                                },
                              }),
                              _c("v-text-field", {
                                attrs: {
                                  rules: _vm.vinRules,
                                  disabled: _vm.vinDisabled,
                                  label: "VIN",
                                  required: "",
                                },
                                model: {
                                  value: _vm.vin,
                                  callback: function ($$v) {
                                    _vm.vin = $$v
                                  },
                                  expression: "vin",
                                },
                              }),
                              _c("v-text-field", {
                                attrs: {
                                  rules: _vm.sernoRules,
                                  disabled: _vm.sernoDisabled,
                                  label: "Unit Serial #",
                                  required: "",
                                },
                                model: {
                                  value: _vm.serialNo,
                                  callback: function ($$v) {
                                    _vm.serialNo = $$v
                                  },
                                  expression: "serialNo",
                                },
                              }),
                              _c("v-checkbox", {
                                attrs: { label: "Simulated Truck" },
                                model: {
                                  value: _vm.isSimulated,
                                  callback: function ($$v) {
                                    _vm.isSimulated = $$v
                                  },
                                  expression: "isSimulated",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "ma-2" },
                [
                  _c(
                    "v-col",
                    { staticClass: "hidden-xs-and-down" },
                    [_c("v-spacer")],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      attrs: {
                        align: "center",
                        cols: "12",
                        sm: "4",
                        md: "4",
                        lg: "4",
                        xl: "4",
                      },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          on: {
                            click: function ($event) {
                              _vm.openDialog = false
                            },
                          },
                        },
                        [_vm._v("Dismiss")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      attrs: {
                        align: "center",
                        cols: "12",
                        sm: "4",
                        md: "4",
                        lg: "4",
                        xl: "4",
                      },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            disabled: !_vm.truckValid,
                          },
                          on: { click: _vm.truckSubmit },
                        },
                        [_vm._v("Add Truck")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "hidden-xs-and-down" },
                    [_c("v-spacer")],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }