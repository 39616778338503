var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "div",
                { staticClass: "perfBar" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { xl: "8", sm: "12" } },
                        [
                          _c(
                            "v-tabs",
                            {
                              attrs: {
                                id: "external-toolbar-tab",
                                "background-color": "secondary",
                                grow: "",
                                height: "162px",
                              },
                              model: {
                                value: _vm.healthTab,
                                callback: function ($$v) {
                                  _vm.healthTab = $$v
                                },
                                expression: "healthTab",
                              },
                            },
                            _vm._l(_vm.healthTabItems, function (item) {
                              return _c(
                                "v-tab",
                                { key: item.title },
                                [
                                  item.tooltip
                                    ? [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { right: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function ({ on, attrs }) {
                                                    return [
                                                      _c(
                                                        "span",
                                                        {
                                                          key: item.title,
                                                          class: item.class,
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(item.title)
                                                          ),
                                                          _c("br"),
                                                          _c(
                                                            "h2",
                                                            {
                                                              staticClass:
                                                                "pt-1",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.count
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              staticStyle: {
                                                                "padding-left":
                                                                  "4px",
                                                                "margin-bottom":
                                                                  "30px",
                                                                color:
                                                                  "#979797",
                                                              },
                                                              attrs: {
                                                                small: "",
                                                              },
                                                            },
                                                            "v-icon",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [
                                                          _vm._v(
                                                            "mdi-information"
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(_vm._s(item.info)),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    : [
                                        _c("span", { class: item.class }, [
                                          _vm._v(
                                            " " + _vm._s(item.title) + " "
                                          ),
                                          _c("br"),
                                          _c("h2", { staticClass: "pt-1" }, [
                                            _vm._v(_vm._s(item.count)),
                                          ]),
                                        ]),
                                      ],
                                ],
                                2
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.healthTab,
                    callback: function ($$v) {
                      _vm.healthTab = $$v
                    },
                    expression: "healthTab",
                  },
                },
                _vm._l(_vm.healthTabItems, function (item) {
                  return _c(
                    "v-tab-item",
                    { key: item.tab },
                    [
                      _c(
                        "v-card",
                        { staticClass: "dark-background", attrs: { flat: "" } },
                        [
                          item.content === "faulted"
                            ? _c("faulted-table", {
                                attrs: {
                                  "faulted-trucks": _vm.faultedTrucks,
                                  "toggle-details": _vm.toggleDetails,
                                  "set-details": _vm.setDetails,
                                },
                              })
                            : _vm._e(),
                          item.content === "inShop"
                            ? _c("in-shop-table", {
                                attrs: {
                                  "in-shop-trucks": _vm.inShopTrucks,
                                  "toggle-details": _vm.toggleDetails,
                                  "set-details": _vm.setDetails,
                                },
                              })
                            : _vm._e(),
                          item.content === "notInUse"
                            ? _c("not-in-use-table", {
                                attrs: {
                                  "not-in-use-trucks": _vm.notInUseTrucks,
                                  "toggle-details": _vm.toggleDetails,
                                  "set-details": _vm.setDetails,
                                },
                              })
                            : _vm._e(),
                          item.content === "normalOperations"
                            ? _c("normal-table", {
                                attrs: {
                                  "normal-trucks": _vm.normalTrucks,
                                  "toggle-details": _vm.toggleDetails,
                                  "set-details": _vm.setDetails,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }