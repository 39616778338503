<template>
  <v-card>
    <v-card-title>Edit Issue</v-card-title>
    <v-card-text></v-card-text>
    <v-divider></v-divider>
    <v-form ref="form" v-model="formValid" lazy-validation>
      <v-container>
        <v-row>
          <v-col sm="3" class="formLabel">Vehicle:</v-col>
          <v-col sm="9">
            <v-select
              v-model="selectedVehicle"
              :items="vehicleList"
              :rules="reqRules"
              class="formInput"
              outlined
              label="Choose Vehicle"
              required
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="3" class="formLabel">HW/SW:</v-col>
          <v-col sm="9">
            <v-select
              v-model="hw_sw"
              :items="hwswList"
              :rules="reqRules"
              class="formInput"
              outlined
              label="Choose HW or SW"
              required
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="3" class="formLabel">Title:</v-col>
          <v-col sm="9">
            <v-text-field
              v-model="issue_title"
              :rules="reqRules"
              class="formInput"
              outlined
              label="Add Title"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="3" class="formLabel">Details:</v-col>
          <v-col sm="9">
            <v-text-field
              v-model="issue_details"
              :rules="reqRules"
              class="formInput"
              outlined
              label="Add Details"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="3" class="formLabel">Next Steps:</v-col>
          <v-col sm="9">
            <v-text-field
              v-model="next_steps"
              class="formInput"
              outlined
              label="Add Next Steps"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="3" class="formLabel">Status:</v-col>
          <v-col sm="9">
            <v-select
              v-model="chosenStatus"
              :items="statusList"
              :rules="reqRules"
              class="formInput"
              outlined
              label="Choose Status"
              required
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="3" class="formLabel">Comments:</v-col>
          <v-col sm="9">
            <v-text-field
              v-model="issue_comments"
              class="formInput"
              outlined
              label="Add Comments"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="3" class="formLabel">JIRA Link:</v-col>
          <v-col sm="9">
            <v-text-field
              v-model="jira_link"
              class="formInput"
              outlined
              label="Add JIRA Link"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="cancel"> Cancel </v-btn>
        <v-btn :disabled="!formValid" color="primary" @click="submitIssue">
          Update Issue
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import _ from 'lodash';
import { currentDateInput } from '@/utilities/dateFunctions';

export default {
  name: 'AddIssues',
  props: {
    dialog: Boolean,
    updateIssueItem: { type: Function, default: null, required: true },
    currentIssue: { type: Object, default: null, required: true },
    issueStatus: { type: Array, default: null, required: true },
    toggle: { type: Function, default: null, required: true },
    reloadIssues: { type: Function, default: null, required: true },
    planItem: { type: Object, default: null, required: true },
  },
  data() {
    return {
      addDisabled: true,
      chosenStatus: '',
      currentPlan: '',
      dateRules: [
        (v) => !!v || 'Date is required',
        (v) =>
          (v && v.length === 10) || 'Delivery date must be mm/dd/yyyy (10)',
      ],
      formValid: false,
      hw_sw: '',
      hwswList: ['HW', 'SW'],
      issue_comments: '',
      issue_details: '',
      issue_id: null,
      issue_title: '',
      jira_link: '',
      next_steps: '',
      reqRules: [(v) => !!v || 'Required!'],
      resolved_date: null,
      selectedVehicle: '',
      statusList: [],
      truck_number: null,
      vehicleList: ['Pilot'],
    };
  },
  watch: {
    formValid: function () {
      this.formValid = !(
        this.issue_title === '' &&
        this.issue_details === '' &&
        this.found_date === '' &&
        this.resolved_date === ''
      );
    },
    dialog: function (val) {
      if (val) {
        this.rebuildLists();
      }
    },
  },
  mounted() {
    this.rebuildLists();
  },
  methods: {
    rebuildLists: function () {
      this.vehicleList.push(this.planItem.truck_number);
      this.hw_sw = this.currentIssue.hw_sw;
      for (let i = 0; i < this.issueStatus.length; i++) {
        this.statusList.push(this.issueStatus[i].status);
      }
      this.chosenStatus = this.findStatusName(this.currentIssue.issue_status);
      this.issue_title = this.currentIssue.title;
      this.issue_details = this.currentIssue.details;
      this.next_steps = this.currentIssue.next_steps;
      this.issue_comments = this.currentIssue.issue_comments;
      if (this.currentIssue.resolved_date) {
        this.resolved_date = currentDateInput(this.currentIssue.resolved_date);
      }
      this.issue_id = this.currentIssue.id;
      this.selectedVehicle = this.currentIssue.vehicle;
    },
    convertEpochTime: function (setDate) {
      if (setDate) {
        const newTime = new Date(setDate).getTime() / 1000;
        return _.round(newTime, 0);
      } else {
        return null;
      }
    },
    cancel() {
      this.$refs.form.reset();
      this.toggle();
    },
    findStatusID: function () {
      const vm = this;
      let tmpObj = _.find(vm.issueStatus, function (o) {
        return o.status === vm.chosenStatus;
      });
      return tmpObj.id;
    },
    findStatusName: function (status_id) {
      const vm = this;
      let tmpObj = _.find(vm.issueStatus, function (o) {
        return o.id === status_id;
      });
      return tmpObj.status;
    },
    submitIssue: async function () {
      let issueObj = {
        vehicle: this.selectedVehicle,
        hw_sw: this.hw_sw,
        issue_status: this.findStatusID(this.chosenStatus),
        title: this.issue_title,
        details: this.issue_details,
        next_steps: this.next_steps,
        issue_comments: this.issue_comments,
        jira_link: this.jira_link,
      };
      if (issueObj.issue_status === 3 || issueObj.issue_status === 5) {
        issueObj.resolved_date = _.round(new Date().getTime() / 1000, 0);
      } else if (this.resolved_date !== null) {
        issueObj.resolved_date = this.resolved_date;
      }
      await this.updateIssueItem(this.issue_id, issueObj);
      this.cancel();
    },
  },
};
</script>

<style scoped>
.formLabel {
  display: block;
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;
}
.formInput {
  margin-bottom: -20px;
  /* padding: -10px; */
}
</style>
