<template>
  <div id="exTable">
    <v-navigation-drawer
      v-model="drawer"
      right
      temporary
      fixed
      width="360"
      class="shade"
    >
      <v-row align="center" class="my-2 pl-5 pr-3">
        <v-col align="left" cols="9">Customize Columns:</v-col>
        <v-col align="right" cols="3">
          <v-btn icon @click="drawer = false">
            <v-icon light>mdi-close-circle </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-divider />
      <v-row>
        <v-col cols="12">
          <template class="mx-auto column-list">
            <v-list class="drawer-list">
              <v-subheader
                >Select to display Columns and Drag to relocate</v-subheader
              >
              <v-list-item-group v-model="labels" multiple>
                <draggable
                  v-model="healthLabelsDup"
                  v-bind="dragOptions"
                  @change="dragLabels"
                >
                  <template v-for="(column, index) in healthLabelsDup">
                    <v-list-item
                      v-if="column.text.length && column.text !== 'Actions'"
                      :key="column.value"
                      :disabled="!column.hideable"
                      @click="updateColumn(column, index)"
                    >
                      <v-list-item-action class="mr-5">
                        <v-icon v-if="!column.visible" color="grey">
                          mdi-square-outline
                        </v-icon>
                        <v-icon v-else color="primary">
                          mdi-checkbox-marked
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title class="light-text">{{
                          checkUserPrefs(column)
                        }}</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-icon v-if="!column.hideable" class="light-text">
                          mdi-lock
                        </v-icon>
                        <v-icon v-else class="light-text"> mdi-menu </v-icon>
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                </draggable>
              </v-list-item-group>
            </v-list>
            <v-divider />
            <v-row class="pt-6">
              <v-col cols="5" class="py-0 pr-0 pl-7">
                <v-spacer />
              </v-col>
              <v-col>
                <v-btn color="primary" outlined @click="resetColumns()"
                  >Reset Default</v-btn
                >
              </v-col>
            </v-row>
          </template>
        </v-col>
      </v-row>
    </v-navigation-drawer>
    <v-container fluid>
      <v-row class="pa-0"
        ><v-col class="pa-0">{{ truckData.length }} results</v-col
        ><v-col class="pa-0" align="right">
          <v-btn icon @click="drawer = !drawer"
            ><v-icon>mdi mdi-dots-vertical</v-icon></v-btn
          >
        </v-col></v-row
      >
      <v-row>
        <v-col class="pa-0" cols="12">
          <!-- <context-menu id="context-menu" ref="ctxMenu">
            <v-list>
              <v-list-item
                v-for="(route, index) in actionItems"
                :key="index"
                :class="route.title"
                @click="setRoute(truckInfo, route.title)"
                >{{ route.title }}</v-list-item
              >
            </v-list>
          </context-menu> -->
          <v-skeleton-loader
            v-if="loading"
            type="table"
            elevation="2"
          ></v-skeleton-loader>
          <v-data-table
            v-else
            :headers="healthLabels"
            :items="truckData"
            class="elevation-1 secondary locked-table-style row-height-50"
            :search="searchText"
            item-key="id"
            :custom-sort="healthTableCustomSort"
            @click:row="setTruckDetails"
          >
            <template v-slot:[`item.name`]="{ item }">
              <th class="body-5">{{ item.number }}</th>
              <div class="body-6">{{ item.company }}</div>
            </template>
            <template
              v-for="h in healthLabels"
              v-slot:[`header.${h.value}`]="{ header }"
            >
              <v-tooltip v-if="h.value === 'address'" :key="h.text" right>
                <template v-slot:activator="{ on }">
                  <span :key="h.text">{{ header.text }}</span>
                  <v-icon
                    style="padding-left: 4px; color: #979797"
                    small
                    v-on="on"
                    >mdi-information-outline</v-icon
                  >
                </template>
                <b>Color Representations:</b><br />
                <b>GREEN:</b> GPS timestamps from HMI and Kontron Match <br />
                <b>YELLOW:</b> GPS timestamps from HMI and Kontron are
                inconsistent
                <br />
                <b>RED:</b> GPS from either HMI or Kontron are not working
                <br />
              </v-tooltip>
              <v-tooltip
                v-else-if="h.value === 'last_error_shut_down'"
                :key="h.value"
                right
              >
                <template v-slot:activator="{ on }">
                  <span :key="h.text">{{ header.text }}</span>
                  <v-icon
                    style="padding-left: 4px; color: #979797"
                    small
                    v-on="on"
                    >mdi-information-outline</v-icon
                  >
                </template>
                <b
                  >Red text indicates that the truck has most likely had
                  Multiple restarts within a short period of time</b
                >
              </v-tooltip>
              <template v-else-if="header.hasOptions">
                <span :key="h.text">{{ checkUserPrefs(header) }}</span>
              </template>
              <template v-else
                ><span :key="h.text">{{ header.text }}</span></template
              >
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <td class="text-xs-center narrow-col">
                <button :class="statusColor(item.status)">
                  {{ item.status }}
                </button>
              </td>
            </template>
            <template v-slot:[`item.dtc`]="{ item }">
              <td class="text-right" :class="dtcColor(item.dtc, 'dtc')">
                {{ item.dtc }}
              </td>
            </template>
            <template v-slot:[`item.pre_dtc`]="{ item }">
              <td class="text-right" :class="dtcColor(item.pre_dtc, 'pre_dtc')">
                {{ item.pre_dtc }}
              </td>
            </template>
            <template v-slot:[`item.address`]="{ item }">
              <td :class="getTimeDiff(item.position_time, item.timeUpdatedRaw)">
                {{ item.address }}
              </td>
            </template>
            <template v-slot:[`item.last_error_shut_down`]="{ item }">
              <td :class="checkMatchTimes(item)">
                {{ item.last_error_shut_down }}
              </td>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-menu style="top: 312px; left: 300px" offset-y>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-dots-vertical</v-icon>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(route, index) in actionItems"
                    :key="index"
                    :class="route.title"
                    @click="setRoute(item, route.title)"
                    >{{ route.title }}</v-list-item
                  >
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { getPref, putPref } from '@/api/preferences';
import { mapGetters, mapMutations } from 'vuex';
import { compareDates } from '@/utilities/dateFunctions';
// import contextMenu from 'vue-context-menu';
import draggable from 'vuedraggable';

export default {
  name: 'EXTable',
  components: { draggable },
  props: {
    truckData: { type: Array, required: true },
    loading: { type: Boolean, default: true, required: true },
    searchText: { type: String, default: null, required: false },
  },
  data() {
    return {
      actionItems: [],
      drawer: false,
      healthLabels: [
        {
          text: 'Truck # / Customer',
          width: '220px',
          align: 'left',
          sortable: true,
          value: 'name',
          visible: false,
          hideable: false,
        },
        {
          text: 'Status',
          width: '100px',
          align: 'left',
          sortable: true,
          value: 'status',
          visible: false,
          hideable: true,
        },
        {
          text: 'DTCs',
          width: '80px',
          align: 'right',
          sortable: true,
          value: 'dtc',
          visible: false,
          hideable: true,
        },
        {
          text: 'PreDTCs',
          width: '100px',
          align: 'right',
          sortable: true,
          value: 'pre_dtc',
          visible: false,
          hideable: true,
        },
        {
          text: 'Location',
          width: '200px',
          align: 'left',
          value: 'address',
          sortable: false,
          visible: false,
          hideable: true,
        },
        {
          text: 'Time Last File Recieved',
          width: '225px',
          align: 'left',
          value: 'timeUpdated',
          sortable: true,
          visible: false,
          hideable: true,
        },
        {
          text: 'Last IoT Update',
          width: '225px',
          align: 'left',
          value: 'iotUpdate',
          sortable: true,
          visible: false,
          hideable: true,
        },
        {
          text: 'Truck Engine Speed rpm',
          width: '190px',
          align: 'left',
          value: 'engSpeed',
          sortable: true,
          visible: false,
          hideable: true,
        },
        {
          text: 'Speed kph',
          altText: 'Speed mph',
          hasOptions: true,
          type: 'regionPref',
          width: '115px',
          align: 'right',
          value: 'current_speed',
          sortable: true,
          visible: false,
          hideable: true,
        },
        {
          text: 'Engine Load %',
          width: '135px',
          align: 'left',
          value: 'load',
          sortable: true,
          visible: false,
          hideable: true,
        },
        {
          text: 'General Temp °C',
          altText: 'General Temp °F',
          hasOptions: true,
          type: 'regionPref',
          width: '145px',
          align: 'left',
          value: 'generalTemp',
          sortable: true,
          visible: false,
          hideable: true,
        },
        {
          text: 'Motor Temp °C',
          altText: 'Motor Temp °F',
          hasOptions: true,
          type: 'regionPref',
          width: '145px',
          align: 'left',
          value: 'motorTemp',
          sortable: true,
          visible: false,
          hideable: true,
        },
        {
          text: 'Compressor Temp °C',
          altText: 'Compressor Temp °F',
          hasOptions: true,
          type: 'regionPref',
          width: '170px',
          align: 'left',
          value: 'compressorTemp',
          sortable: true,
          visible: false,
          hideable: true,
        },
        {
          text: 'Battery Max Temp °C',
          altText: 'Battery Max Temp °F',
          hasOptions: true,
          type: 'regionPref',
          width: '165px',
          align: 'left',
          value: 'batteryMaxTemp',
          sortable: true,
          visible: false,
          hideable: true,
        },
        {
          text: 'Battery Min Temp °C',
          altText: 'Battery Min Temp °F',
          hasOptions: true,
          type: 'regionPref',
          width: '165px',
          align: 'left',
          value: 'batteryMinTemp',
          sortable: true,
          visible: false,
          hideable: true,
        },
        {
          text: 'Look Ahead',
          width: '225px',
          align: 'left',
          value: 'laTimeUpdated',
          sortable: true,
          visible: false,
          hideable: true,
        },
        {
          text: 'Terrain Ahead',
          width: '225px',
          align: 'left',
          value: 'taTimeUpdated',
          sortable: true,
          visible: false,
          hideable: true,
        },
        {
          text: 'Odometer km',
          altText: 'Odometer mi',
          hasOptions: true,
          type: 'regionPref',
          width: '130px',
          align: 'right',
          value: 'odometer',
          sortable: true,
          visible: false,
          hideable: true,
        },
        {
          text: 'Ignition',
          width: '100px',
          align: 'left',
          value: 'ignition',
          sortable: true,
          visible: false,
          hideable: true,
        },
        {
          text: 'SOC %',
          width: '90px',
          align: 'right',
          value: 'SOC',
          sortable: true,
          visible: false,
          hideable: true,
        },
        {
          text: 'APU',
          width: '75px',
          align: 'left',
          value: 'APU',
          sortable: true,
          visible: false,
          hideable: true,
        },
        {
          text: 'Last Error Shut Down',
          value: 'last_error_shut_down',
          width: '225px',
          sortable: true,
          visible: false,
          hideable: true,
        },
        {
          text: 'Fuel',
          width: '75px',
          align: 'left',
          value: 'fuel_type',
          sortable: true,
          visible: false,
          hideable: true,
        },
        {
          text: 'Model',
          width: '100px',
          align: 'left',
          value: 'truck_type',
          sortable: true,
          visible: false,
          hideable: true,
        },
        {
          text: 'Vin',
          width: '150px',
          align: 'left',
          sortable: true,
          value: 'vin',
          visible: false,
          hideable: true,
        },
        {
          text: 'Serial Number',
          width: '150px',
          align: 'left',
          sortable: true,
          value: 'serial_number',
          visible: false,
          hideable: true,
        },
        {
          text: 'In Service',
          width: '105px',
          align: 'left',
          sortable: true,
          value: 'in_service',
          visible: false,
          hideable: true,
        },
        {
          text: 'Version',
          width: '70px',
          align: 'right',
          value: 'version',
          sortable: false,
          visible: false,
          hideable: true,
        },
        {
          text: 'License Plate',
          width: '125px',
          align: 'left',
          value: 'license_plate',
          sortable: false,
          visible: false,
          hideable: true,
        },
        {
          text: 'Registration Date',
          width: '225px',
          align: 'left',
          value: 'created_at',
          sortable: true,
          visible: false,
          hideable: true,
        },
        {
          text: 'Actions',
          width: '0.1%',
          value: 'actions',
          sortable: false,
          align: 'right',
          visible: false,
          hideable: false,
        },
      ],
      healthLabelsDup: [],
      labels: [],
      prefVisible: {
        name: true,
        status: true,
        dtc: true,
        pre_dtc: true,
        address: true,
        current_speed: true,
        engSpeed: true,
        load: true,
        generalTemp: true,
        motorTemp: true,
        compressorTemp: true,
        batteryMaxTemp: true,
        batteryMinTemp: true,
        timeUpdated: true,
        laTimeUpdated: true,
        taTimeUpdated: true,
        odometer: true,
        ignition: true,
        SOC: true,
        APU: true,
        last_error_shut_down: true,
        vin: true,
        actions: true,
      },
      prefDup: {},
      presetChecked: false,
      truckInfo: null,
    };
  },
  computed: {
    ...mapGetters({
      userRoles: 'getUserRoles',
      userPreferences: 'getUserPreferences',
    }),
    dragOptions() {
      return {
        animation: 100,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      };
    },
  },
  mounted() {
    this.healthLabelsDup = [...this.healthLabels];
    this.matchColumns();
    this.userRoles.map((role) => {
      if (role === 'route.health') {
        this.actionItems.unshift({ title: 'Truck Detail' });
      } else if (role === 'route.trip-reports') {
        this.actionItems.unshift({ title: 'Trip Details' });
      } else if (role === 'route.map') {
        this.actionItems.unshift({ title: 'Map View' });
      } else if (role === 'route.configuration') {
        this.actionItems.unshift({ title: 'Truck Configuration' });
      }
    });
  },
  methods: {
    ...mapMutations(['updateHealthTruckType', 'assignHealthTruck']),
    dragLabels: function () {
      this.healthLabels = this.healthLabelsDup;
      this.matchColumns();
    },
    matchColumns: async function () {
      try {
        let result = await getPref('health_ex');
        this.prefDup = { ...this.prefVisible };
        this.prefVisible = result.data?.preferences || this.prefVisible;
      } catch (error) {
        this.$emit(
          'emitAlert',
          'error',
          'There was an issue getting user preferences!'
        );
        this.$emit(
          'emitAlert',
          'error',
          'There was an issue getting user preferences!'
        );
      }

      this.healthLabels = this.healthLabels.filter(
        (label) => label.value in this.prefVisible
      );

      this.healthLabelsDup.forEach((label) => {
        if (label.value in this.prefVisible) {
          label.visible = true;
        } else {
          label.visible = false;
        }
      });
    },
    async updateColumn(column, index) {
      if (column.value in this.prefVisible) {
        delete this.prefVisible[column.value];
      } else {
        this.prefVisible[column.value] = true;
      }
      this.healthLabelsDup[index].visible = !column.visible;
      try {
        await putPref('health_ex', this.prefVisible);

        this.updateHeaders(this.healthLabelsDup);
      } catch (error) {
        this.updateHeaders(this.healthLabelsDup);
      }
    },
    updateHeaders: function (columns) {
      const tempArr = [];
      columns.forEach((c) => {
        if (c.visible) {
          tempArr.push(c);
        }
      });
      // If the actions menu doesn't exist, pushes action menu at the end of the array
      if (!columns.find((c) => c.value === 'actions')) {
        tempArr.push({
          text: '',
          value: 'actions',
          sortable: false,
          align: 'right',
          visible: false,
          hideable: false,
        });
      }
      this.healthLabels = [...tempArr];
    },
    async resetColumns() {
      this.healthLabels = this.healthLabels.filter(
        (label) => label.value in this.prefDup
      );

      this.healthLabelsDup.forEach((label) => {
        if (label.value in this.prefDup) {
          label.visible = true;
        } else {
          label.visible = false;
        }
      });

      try {
        await putPref('health_ex', this.prefDup);
      } catch (e) {
        console.log('There was an error updating ex health columns');
      }
    },
    healthTableCustomSort: function (items, index, isDesc) {
      // Implementation sample https://codepen.io/mmia/pen/jOPyXad
      items.sort((a, b) => {
        // Sorts out the hyphen(Em dash) for empty row data
        if (b[index] === '—') {
          return -1;
        }
        if (a[index] === '—') {
          return 1;
        }

        if (index[0] === 'created_at') {
          if (!isDesc[0]) {
            return compareDates(a.created_at, b.created_at);
          } else {
            return compareDates(b.created_at, a.created_at);
          }
        } else if (
          index[0] === 'timeUpdated' ||
          index[0] === 'laTimeUpdated' ||
          index[0] === 'last_error_shut_down' ||
          index[0] === 'iotUpdate'
        ) {
          const name = index[0];
          if (!b[name].length) return -1;
          if (!a[name].length) return 1;
          const aTime = a[name].split('  ');
          const bTime = b[name].split('  ');
          const adays = parseInt(aTime[0].match(/\d/g).join(''));
          const bdays = parseInt(bTime[0].match(/\d/g).join(''));

          if (!isDesc[0]) {
            if ((adays !== 0 || bdays !== 0) && adays !== bdays) {
              return adays < bdays ? -1 : 1;
            }
            const ahours = parseInt(aTime[1].match(/\d/g).join(''));
            const bhours = parseInt(bTime[1].match(/\d/g).join(''));
            if (ahours !== 0 || (bhours !== 0 && ahours !== bhours)) {
              return ahours < bhours ? -1 : 1;
            }
            const amins = parseInt(aTime[2].match(/\d/g).join(''));
            const bmins = parseInt(bTime[2].match(/\d/g).join(''));

            return amins < bmins ? -1 : 1;
          } else {
            if ((adays !== 0 || bdays !== 0) && adays !== bdays) {
              return bdays < adays ? -1 : 1;
            }
            const ahours = parseInt(aTime[1].match(/\d/g).join(''));
            const bhours = parseInt(bTime[1].match(/\d/g).join(''));
            if ((ahours !== 0 || bhours !== 0) && ahours !== bhours) {
              return bhours < ahours ? -1 : 1;
            }
            const amins = parseInt(aTime[2].match(/\d/g).join(''));
            const bmins = parseInt(bTime[2].match(/\d/g).join(''));

            return bmins < amins ? -1 : 1;
          }
        } else if (
          index[0] === 'odometer' ||
          index[0] === 'load' ||
          index[0] === 'engSpeed' ||
          index[0] === 'generalTemp' ||
          index[0] === 'motorTemp' ||
          index[0] === 'compressorTemp' ||
          index[0] === 'batteryMaxTemp' ||
          index[0] === 'batteryMinTemp'
        ) {
          if (!isDesc[0]) {
            return parseInt(a[index]) < parseInt(b[index]) ? -1 : 1;
          } else {
            return parseInt(b[index]) < parseInt(a[index]) ? -1 : 1;
          }
        } else if (index[0] === 'current_speed') {
          if (!isDesc[0]) {
            return a[index].toString() < b[index].toString() ? -1 : 1;
          } else {
            return b[index].toString() < a[index].toString() ? -1 : 1;
          }
        } else if (a[index] && b[index]) {
          if (!isDesc[0]) {
            return a[index].toString() < b[index].toString() ? -1 : 1;
          } else {
            return b[index].toString() < a[index].toString() ? -1 : 1;
          }
        }
      });
      return items;
    },
    checkMatchTimes(item) {
      if (item.timeUpdated === item.last_error_shut_down) {
        return 'Critical';
      }
    },
    getTimeDiff(hmi_time, adx_time) {
      //Checks the difference between the 1 minute date via ADX and the timestamp from HMI
      if (!hmi_time || !adx_time) {
        return 'color-red';
      }
      let hmiDate = new Date(hmi_time).getTime(); // In seconds
      let adxDate = new Date(adx_time).getTime(); // In millisecnds

      const minutes = Math.abs((hmiDate - adxDate / 1000) / 60000);
      if (minutes <= 15) {
        return 'color-green';
      }
      const day = minutes / 1440;
      if (day <= 1) {
        return 'color-yellow';
      }

      return 'color-red';
    },
    setTruckDetails: async function (truck) {
      this.updateHealthTruckType('EX');
      this.assignHealthTruck(truck);
      this.$router.push(`health/${truck.id}/details`);
    },
    setRoute: async function (truck, route) {
      if (route === 'Truck Detail') {
        this.updateHealthTruckType('EX');
        this.$router.push(`health/${truck.id}/details`);
      } else if (route === 'Map View') {
        this.$router.push(`health/${truck.id}/map`);
      } else if (route === 'Truck Configuration') {
        this.$router.push('configuration-management');
      } else if (route === 'Trip Details') {
        this.$router.push(`trips/${truck.id}`);
      }
    },
    // showMenu(truck) {
    //   this.truckInfo = truck;
    //   this.$refs.ctxMenu.open();
    // },
    statusColor: function (status) {
      let code = '';
      switch (status) {
        case 'Normal':
          code = 'color-green';
          break;
        case 'Warning':
          code = 'color-yellow';
          break;
        case 'FAULT':
          code = 'color-red';
          break;
        default:
          code = 'color-green';
      }
      return code;
    },
    dtcColor: function (count, type) {
      let code = 'color-green';
      if (count > 0) {
        if (type === 'dtc') {
          code = 'color-red';
        } else {
          code = 'color-yellow';
        }
      }
      return code;
    },
    checkUserPrefs(h) {
      if (h.type === 'regionPref') {
        if (this.userPreferences.regionPref === 'metric') {
          return h.text;
        } else {
          return h.altText;
        }
      } else {
        return h.text;
      }
    },
  },
};
</script>

<style scoped>
.center {
  display: block;
  margin: auto;
}
</style>
