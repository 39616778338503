<template>
  <div class="mainPieContainer" :style="`width: ${svgW + 10}px`">
    <div class="chartTitle">{{ title }}</div>
    <div>
      <svg :id="`pieSVG${id}`" style="margin-left: 5px; margin-right: 5px">
        <!-- procedurally generated chart graphic fills this svg -->
      </svg>
    </div>
    <div v-if="slicesBuilt" class="labels">
      <div
        v-for="datum in unsortedData"
        :key="datum.name + datum.value + datum.color"
        class="labelDiv"
      >
        <span class="label">
          <svg width="20" height="20">
            <circle :style="`fill: ${datum.color}`" r="10" cx="10" cy="10" />
          </svg>
          <div class="labelName">
            {{ datum.name + (datum.moreInfo ? ` ${datum.moreInfo}` : '') }}
          </div>
        </span>
        <div class="labelValue" :style="`color: ${datum.color}`">
          {{ String(Math.round(datum.percent * 100)) + '%' }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, default: () => 'Chart Title' },
    data: {
      type: Array,
      default: () => [
        // sample data by default;
        {
          name: 'Over Speed',
          moreInfo: '(>64)', // moreInfo field displayed on legend but not svg labels
          value: 1122,
          color: 'blue', // optional: data is color configurable
        },
        {
          name: 'High',
          moreInfo: '(50-65 mph)',
          value: 9929.9,
        },
        {
          name: 'Medium',
          moreInfo: '(25-50 mph)',
          value: 6641.8,
        },
        {
          name: 'Slow',
          moreInfo: '(0-25 mph)',
          value: 4929.8,
        },
      ],
    },
    colors: {
      type: Array,
      default: () => ['#E53935', '#FB8C00', '#A3A3A3', '#43b02a', '#5CABFF'],
    },
    svgW: {
      type: Number,
      default: () => 340,
    },
    svgH: {
      type: Number,
      default: () => 280,
    },
    maxRadius: {
      type: Number,
      default: () => 45,
    },
    // percent below which labels aren't shown
    percentThreshold: {
      type: Number,
      default: () => 0.02,
    },
  },
  data() {
    return {
      unsortedData: null,
      slicesBuilt: false,
      id: Math.random().toString(36).slice(2) + Date.now().toString(36),
    };
  },
  mounted() {
    this.unsortedData = { ...this.data };
    this.processData();
    this.buildSlices();
  },
  methods: {
    // sort & add percent & effPercent to data; effPercent is current% + runningAggregate%
    processData() {
      this.data.sort((x, y) => x.value - y.value);
      let sum = 0;
      this.data.forEach((item) => (sum += item.value));
      this.data.forEach((item) => (item.percent = item.value / sum));
      let runningTotal = 0;
      for (let i = this.data.length - 1; i >= 0; i--) {
        runningTotal += this.data[i].percent;
        this.data[i].effPercent = runningTotal;
      }
    },
    buildSlices() {
      const width = this.svgW;
      const height = this.svgH;
      const pi = 3.1415926535;
      const svg = document.getElementById(`pieSVG${this.id}`);
      svg.setAttribute('width', width + 'px');
      svg.setAttribute('height', height + 'px');
      let fos = []; // array to store foreign objects
      for (let i = 0; i < this.data.length; i++) {
        const maxR = this.maxRadius; // radius of largest circle
        const diff = 8; // radius diff per circle
        const r = maxR - (this.data.length - 1) * diff + i * diff;
        const transX = width / 2 - 2 * r;
        const transY = height / 2 - 2 * r;
        const circum = 2 * pi * r;
        const theta =
          (this.data[i].effPercent -
            this.data[i].percent +
            this.data[i].percent / 2.25) *
          2 *
          pi; // radians
        const x = (r + 75) * Math.cos(theta) + width / 2;
        const y = (r + 60) * Math.sin(theta) + height / 2;
        const rightSide = x > width / 2;
        !this.data[i].color && (this.data[i].color = this.colors[i]);
        // don't graph 0 values
        if (this.data[i].percent === 0) {
          continue;
        }
        // draw line segments
        const line = document.createElementNS(
          'http://www.w3.org/2000/svg',
          'line'
        );
        line.setAttribute('x1', width / 2); // start at center
        line.setAttribute('y1', height / 2);
        line.setAttribute('x2', x);
        line.setAttribute('y2', y);
        line.setAttribute(
          'style',
          `stroke:rgba(255, 255, 255, 0.6);stroke-width:1`
        );
        // don't label values below percentThreshold
        if (!(this.data[i].percent < this.percentThreshold)) {
          svg.appendChild(line);
        }
        // draw second line segments
        const line2 = document.createElementNS(
          'http://www.w3.org/2000/svg',
          'line'
        );
        line2.setAttribute('x1', x);
        line2.setAttribute('y1', y);
        line2.setAttribute('y2', y);
        line2.setAttribute(
          'style',
          `stroke:rgba(255, 255, 255, 0.6);stroke-width:1`
        );
        const l2l = 8; // line2 length
        if (rightSide) {
          line2.setAttribute('x2', x + l2l);
        } else {
          line2.setAttribute('x2', x - l2l);
        }
        // don't label values below percentThreshold
        if (!(this.data[i].percent < this.percentThreshold)) {
          svg.appendChild(line2);
        }
        // draw circle
        const cir = document.createElementNS(
          'http://www.w3.org/2000/svg',
          'circle'
        );
        cir.setAttribute('r', r);
        cir.setAttribute('cx', 2 * r);
        cir.setAttribute('cy', 2 * r);
        cir.setAttribute('transform', `translate(${transX} ${transY})`);
        cir.setAttribute('fill', 'none');
        cir.setAttribute('stroke', this.data[i].color);
        cir.setAttribute('stroke-width', String(2 * r));
        cir.setAttribute(
          'stroke-dasharray',
          `${this.data[i].effPercent * circum} ${circum}`
        );
        cir.setAttribute(
          'style',
          'filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.4))'
        );
        svg.appendChild(cir);
        // draw text labels
        const fo = document.createElementNS(
          'http://www.w3.org/2000/svg',
          'foreignObject'
        );
        const text = document.createElement('div');
        text.setAttribute(
          'style',
          "font-family: 'Rawline'; fill: white; font-size: 12px; font-weight: bold; text-align: left; letter-spacing: 2px; line-height: 105%"
        );
        text.setAttribute('xmlns', 'http://www.w3.org/1999/xhtml');
        text.innerHTML = this.data[i].name;
        fo.setAttribute('y', y - 10); // offset to center text on line segment // fixme: potential bad edge case created here
        if (rightSide) {
          fo.setAttribute('x', x + l2l + 3);
          fo.setAttribute('width', this.svgW - x - 11);
          fo.setAttribute('height', this.svgW - y - 11);
        } else {
          const offset = Math.min(
            this.getTextWidth(this.data[i].name, '12px Rawline') + 3,
            x - l2l - 3
          );
          fo.setAttribute('x', x - offset - l2l);
          fo.setAttribute('width', offset);
          fo.setAttribute('height', y);
        }
        // don't label values below percentThreshold
        if (!(this.data[i].percent < this.percentThreshold)) {
          fo.appendChild(text);
        }
        fos.push(fo);
      }
      // add fo's to svg last for max z index priority
      fos.forEach((fo) => svg.appendChild(fo));
      this.slicesBuilt = true;
    },
    getTextWidth(text, font) {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      context.font = font;
      // 1.35 constant hardcoded for 2px letter spacing
      const width = 1.35 * context.measureText(text).width;
      return Math.ceil(width);
    },
  },
};
</script>

<style scoped>
.mainPieContainer {
  display: block;
  margin: auto;
  background-color: #24272c;
  border-radius: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.chartTitle {
  font-family: 'Rawline';
  font-style: normal;
  font-size: 20px;
  font-weight: bold;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.25px;
  color: #babeca;
}
.labelDiv {
  display: flex;
  justify-content: space-between;
}
.labels {
  display: block;
  margin: auto;
  padding-left: 20px;
  padding-right: 20px;
  font-family: 'Rawline';
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.55px;
  color: #babeca;
  text-align: left;
}
.label {
  display: flex;
  margin-bottom: 15px;
  align-items: center;
}
.labelName {
  margin-left: 10px;
  width: 170px;
  font-weight: bold;
}
.labelValue {
  font-weight: bold;
}
</style>
