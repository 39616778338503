const tzjs = require('tzjs');

const getTimeDelta = (lastPing) => {
  const now = Date.now();
  let delta = Math.abs(now - new Date(lastPing).getTime()) / 1000;
  // calculate (and subtract) whole days
  const days = Math.floor(delta / 86400);
  delta -= days * 86400;

  // calculate (and subtract) whole hours
  const hours = Math.floor(delta / 3600) % 24;
  delta -= hours * 3600;

  // calculate (and subtract) whole minutes
  const minutes = Math.floor(delta / 60) % 60;
  delta -= minutes * 60;
  let dayText = days === 1 ? 'Day' : 'Days';
  return `${days} ${dayText}  ${hours} hr.  ${minutes} min.`;
};

const getDaysDelta = (alertTime) => {
  const date = new Date();
  const nowTime = Math.round(date.getTime() / 1000);
  const timeDelta = nowTime - alertTime;
  return Math.round(Math.floor(timeDelta / 86400));
};

const convertEpochTime = (utcSeconds) => {
  if (!utcSeconds) return '';
  const localDate = new Date(0);
  localDate.setUTCMilliseconds(utcSeconds);
  return (
    localDate.getMonth() +
    1 +
    '-' +
    localDate.getDate() +
    '-' +
    localDate.getFullYear() +
    ' ' +
    localDate.getHours() +
    ':' +
    localDate.getMinutes() +
    ':' +
    localDate.getSeconds()
  );
};

const compareDates = (date1, date2) => {
  return new Date(date1) - new Date(date2);
};

const timeConvert = (n) => {
  var num = (Date.now() - new Date(n)) / (1000 * 60 * 60);
  var hours = num / 60;
  var rhours = Math.floor(hours);
  var minutes = (hours - rhours) * 60;
  var rminutes = Math.round(minutes);
  return rhours + ' : ' + rminutes;
};

// returns startofday(start) and endofday(end) in user's tz (epoch format)
function getStartEnd(dates, tz) {
  // start is always earlier date; end is later date
  let start = Math.min(Date.parse(dates[0]), Date.parse(dates[1]));
  let end = Math.max(Date.parse(dates[0]), Date.parse(dates[1]));
  // adjust start, end based on tz
  const offset_ms = 60000 * tzjs.getOffset(tz, start);
  start = start + offset_ms;
  end = end + offset_ms + 3600000 * 24 - 1; // 1 ms before day's end
  return [start, end];
}
function getTimeZoneOffset(
  timeStamp = new Date().toISOString(),
  tz = 'America/Chicago'
) {
  const date = tzjs.toDate(timeStamp);
  // tzjs.getOffset returns the number of minutes to add to the tz to reach UTC.
  const tzOffset = tzjs.getOffset(tz, date);
  return tzOffset;
}

function currentDateInput(inputDate) {
  let cleanDate = inputDate;
  const currYear = new Date().getFullYear();
  // autocomplete logic
  if (parseInt(inputDate[0]) > 1) {
    cleanDate = '0' + inputDate + '/';
  }
  if (parseInt(inputDate[3]) > 3) {
    cleanDate = inputDate.substr(0, 2) + '/0' + inputDate[3] + '/';
  }
  if (
    inputDate.length === 10 &&
    parseInt(inputDate.substr(inputDate.length - 4)) < currYear
  ) {
    cleanDate = inputDate.substr(0, inputDate.length - 4) + currYear;
  }
  return cleanDate;
}

function singleDateInput(inputDate) {
  let cleanDate = inputDate;
  const currYear = new Date().getFullYear();
  // autocomplete logic
  if (parseInt(inputDate[0]) > 1) {
    cleanDate = '0' + inputDate + '/';
  }
  if (parseInt(inputDate[3]) > 3) {
    cleanDate = inputDate.substr(0, 2) + '/0' + inputDate[3] + '/';
  }
  if (
    inputDate.length === 10 &&
    parseInt(inputDate.substr(inputDate.length - 4)) < 2017
  ) {
    cleanDate = inputDate.substr(0, inputDate.length - 4) + '2017';
  }
  if (
    inputDate.length === 10 &&
    parseInt(inputDate.substr(inputDate.length - 4)) > currYear
  ) {
    cleanDate = inputDate.substr(0, inputDate.length - 4) + currYear;
  }
  return cleanDate;
}

// get human readable string in target TZ
function convertTZ(date, tzString) {
  return new Date(
    (typeof date === 'string' ? new Date(date) : date).toLocaleString('en-US', {
      timeZone: tzString,
    })
  );
}

export {
  getTimeDelta,
  getDaysDelta,
  convertEpochTime,
  getStartEnd,
  compareDates,
  timeConvert,
  getTimeZoneOffset,
  currentDateInput,
  singleDateInput,
  convertTZ,
};
