<template>
  <div>
    <v-form ref="form" style="margin: 20px">
      <div style="display: flex">
        <v-select
          v-model="currentTemplate"
          :items="names"
          return-object
          item-text="name"
          item-value="id"
          class="selects"
          label="Name"
          outlined
        ></v-select>
        <v-text-field
          v-if="currentTemplate && currentTemplate.id === -1"
          v-model="name"
          :rules="[rules.required]"
          label="Name"
          outlined
        ></v-text-field>
      </div>
      <div style="display: flex">
        <v-select
          v-model="visibility"
          :items="visibilities"
          :rules="[rules.required]"
          class="selects"
          label="Visibility"
          outlined
        ></v-select>
        <div style="margin-top: 15px">Author: {{ authorName }}</div>
        <div
          v-if="currentTemplate && currentTemplate.id > 0"
          style="margin-top: 15px; margin-left: 60px"
        >
          Last Updated: {{ this.currentTemplate.updated_at }}
        </div>
      </div>
      <v-textarea
        v-model="description"
        label="Description"
        outlined
      ></v-textarea>
      <v-data-table
        ref="table"
        :headers="headers"
        :items="graphs"
        :items-per-page="-1"
        :rules="[rules.graphsRequired]"
        hide-default-footer
      >
        <template #body="props">
          <draggable
            :list="props.items"
            tag="tbody"
            style="cursor: pointer"
            @end="onDrop"
          >
            <data-table-row-handler
              v-for="(item, index) in props.items"
              :key="index"
              :item="item"
              :headers="headers"
            >
              <template v-slot:[`item.signals`]="{ item }">
                {{ getSignalNames(item.signals) }}
              </template>
              <template v-slot:[`item.start_trigger`]="{ item }">
                {{ getTriggerName(item.start_trigger) }}
              </template>
              <template v-slot:[`item.end_trigger`]="{ item }">
                {{ getTriggerName(item.end_trigger) }}
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-menu style="top: 312px; left: 300px" offset-y>
                  <template v-slot:activator="{ on }">
                    <v-icon style="margin-left: 17px" v-on="on"
                      >mdi-dots-vertical</v-icon
                    >
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(action, index) in actionItems"
                      :key="index"
                      @click="graphAction(action, item)"
                      >{{ action }}</v-list-item
                    >
                  </v-list>
                </v-menu>
              </template>
            </data-table-row-handler>
          </draggable>
          <tr>
            <td colspan="6"></td>
            <td>
              <v-btn color="primary" @click="addGraph">Add</v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
      <div class="actionDiv">
        <v-btn
          color="error"
          :disabled="userOID != currentTemplate.author_oid"
          @click="deleteDialog = true"
          >Delete</v-btn
        >
        <v-dialog v-model="deleteDialog" width="500">
          <v-card>
            <v-container>
              Are you sure you want to delete "{{ currentTemplate.name }}"?
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="deleteDialog = false">Cancel</v-btn>
              <v-btn color="error" @click="deleteTemplate">Delete</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <span>
          <v-btn
            v-if="!saveAsDisabled"
            color="#7d7d7d"
            style="margin-right: 20px"
            @click="discard"
            >discard</v-btn
          >
          <v-btn
            color="primary"
            style="margin-right: 20px"
            :disabled="saveDisabled"
            @click="save"
            >Save</v-btn
          >
          <v-btn
            color="primary"
            :disabled="saveAsDisabled"
            @click="showSaveAsDialog"
            >Save As</v-btn
          >
          <v-dialog v-model="saveAsDialog" width="500">
            <v-card>
              <v-card-title>Save As New Template</v-card-title>
              <v-card-text>
                <v-text-field
                  v-model="name"
                  label="Template Name"
                  outlined
                ></v-text-field>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="saveAsDialog = false">Cancel</v-btn>
                <v-btn color="primary" @click="saveAs">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </span>
      </div>
    </v-form>
    <AddGraph
      v-if="showAddGraph"
      :graphs="graphs"
      :triggers="triggers"
      :signals="signals"
      :edit-graph="editGraph"
      @close="() => (showAddGraph = false)"
    />
  </div>
</template>

<script>
import { deleteTemplate, postTemplate, putTemplate } from '@/api/templates';
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import { getSignals } from '@/api/dataDictionary';
import AddGraph from './AddGraph.vue';
import draggable from 'vuedraggable';
import DataTableRowHandler from '../../utilities/DataTableRowHandler.vue';
import dateFormat from 'dateformat';

export default {
  name: 'TemplateEditor',
  components: {
    AddGraph,
    draggable,
    DataTableRowHandler,
  },
  props: ['triggers', 'templates'],
  data() {
    return {
      editGraph: null,
      signals: [],
      showAddGraph: false,
      deleteDialog: false,
      saveAsDialog: false,
      saveAsName: '',
      names: null,
      currentTemplate: {
        name: '',
        visibility: '',
        author: '',
        description: '',
        graphs: [],
      },
      name: '',
      visibility: '',
      author: '',
      description: '',
      graphs: [],
      visibilities: ['Private', 'Shared', 'Health'],
      actionItems: ['Edit', 'Delete'],
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Description', value: 'description' },
        { text: 'Type', value: 'graph_type' },
        { text: 'Signals', value: 'signals' },
        { text: 'Start Trigger', value: 'start_trigger' },
        { text: 'End Trigger', value: 'end_trigger' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      rules: {
        required: (value) => {
          if (value) return true;
          return 'Required';
        },
        graphsRequired: () => {
          if (this.graphs.length > 1) return true;
          return 'At least one graph is required for a template';
        },
      },
    };
  },
  async mounted() {
    this.refreshTemplates();
    this.fetchSignals();
  },
  computed: {
    ...mapGetters({
      userName: 'getUserName',
      userOID: 'getUserOID',
    }),
    authorName() {
      return this.currentTemplate?.id === -1
        ? this.userName
        : this.currentTemplate?.author_name;
    },
    noTemplateChanges() {
      return (
        this.visibility.toLowerCase() === this.currentTemplate.visibility &&
        this.description === this.currentTemplate.description &&
        _.isEqual(this.graphs, this.currentTemplate.graphs)
      );
    },
    // disable save if noTemplateChanges OR user isn't author
    saveDisabled() {
      return (
        this.currentTemplate.author_oid != this.userOID ||
        this.noTemplateChanges ||
        !this.formValid
      );
    },
    saveAsDisabled() {
      return (
        isNaN(this.currentTemplate?.id) ||
        this.noTemplateChanges ||
        !this.formValid
      );
    },
    formValid() {
      return (
        ((this.currentTemplate?.id === -1 && this.name.length > 0) ||
          this.currentTemplate?.id != -1) &&
        this.graphs.length > 0 &&
        this.visibility
      );
    },
  },
  watch: {
    currentTemplate() {
      if (this.currentTemplate?.id === -1) this.clearInputs();
      else this.syncFields();
    },
    // update names once templates have refreshed
    templates() {
      if (this.templates) {
        this.names = [{ id: -1, name: '+ Add New' }];
        for (const template of this.templates) {
          this.names.push(template);
        }
      }
    },
  },
  methods: {
    ...mapActions({
      updateSnack: 'updateSnack',
    }),
    refreshTemplates() {
      this.$emit('refresh');
    },
    clearInputs() {
      this.name = '';
      this.description = '';
      this.graphs = [];
      this.visibility = 'Private';
      this.$refs.form.resetValidation();
    },
    graphAction(action, graph) {
      switch (action.toLowerCase()) {
        case 'edit':
          this.editGraph = graph;
          this.showAddGraph = true;
          break;
        case 'delete':
          this.graphs = this.graphs.filter((g) => g.id != graph.id);
          break;
      }
    },
    // sync templateModel to currentTemplate
    syncFields() {
      if (this.currentTemplate?.id != -1) {
        this.visibility = _.capitalize(this.currentTemplate?.visibility);
        this.author = this.currentTemplate?.author_oid;
        this.description = this.currentTemplate?.description;
        this.graphs = _.cloneDeep(this.currentTemplate?.graphs);
      } else {
        this.clearInputs();
      }
    },
    async deleteTemplate() {
      const { id, name } = this.currentTemplate;
      this.currentTemplate = {
        name: '',
        visibility: 'Private',
        author: '',
        description: '',
        graphs: [],
      };
      this.deleteDialog = false;
      try {
        await deleteTemplate(id);
        this.updateSnack({
          type: 'success',
          message: `Successfully deleted template ${name}`,
        });
      } catch (e) {
        this.updateSnack({
          type: 'error',
          message: `There was an issue deleting template with id ${id}`,
        });
      }
      this.syncFields();
      this.refreshTemplates();
    },
    async save() {
      if (!this.formValid) return;
      // update template
      try {
        // track author and updated_at for front end,
        // (API will ignore them)
        const updatedTemplate = {
          id: this.currentTemplate.id,
          author_name: this.userName,
          author_oid: this.userOID,
          updated_at: dateFormat(Date.now(), 'isoDateTime'),
          name: this.currentTemplate.name,
          description: this.description,
          visibility: this.visibility.toLowerCase(),
          graphs: this.graphs,
        };
        await putTemplate(updatedTemplate);
        this.updateSnack({
          type: 'success',
          message: `Successfully updated template ${this.currentTemplate.name}`,
        });
        this.currentTemplate = updatedTemplate;
        this.refreshTemplates();
        this.syncFields();
      } catch (e) {
        this.updateSnack({
          type: 'error',
          message: `There was an issue updating the template`,
        });
      }
    },
    async saveAs() {
      this.saveAsDialog = false;
      try {
        await postTemplate({
          name: this.name,
          description: this.description,
          visibility: this.visibility.toLowerCase(),
          graphs: this.graphs,
        });
        this.updateSnack({
          type: 'success',
          message: `Successfully created template ${this.name}`,
        });
        this.syncFields();
      } catch (e) {
        this.updateSnack({
          type: 'error',
          message: `There was an issue creating the template`,
        });
      }
      this.refreshTemplates();
    },
    showSaveAsDialog() {
      if (!this.formValid) return;
      if (this.currentTemplate.id != -1) {
        this.name = this.currentTemplate.name + ' Copy';
      }
      this.saveAsDialog = true;
    },
    discard() {
      if (this.currentTemplate?.id === -1) this.clearInputs();
      else this.syncFields();
    },
    async fetchSignals() {
      const vm = this;
      await Promise.allSettled([getSignals()]).then((results) => {
        vm.signals = results[0].value.data.filter(
          (s) => s.truck_type === 'ERX'
        );
      });
    },
    getSignalNames(signals) {
      if (signals && this.signals.length) {
        const signalSet = new Set(signals);
        return this.signals
          .filter((s) => signalSet.has(s.id))
          .map((s) => s.signal)
          .join(', ');
      } else if (signals) {
        return signals;
      } else {
        return '';
      }
    },
    getTriggerName(trigger_id) {
      if (trigger_id && this.triggers.length) {
        return this.triggers.filter((t) => t.id === trigger_id)[0].name;
      } else if (trigger_id) {
        return trigger_id;
      } else {
        return '';
      }
    },
    // update graphs array to new order after drag and drop
    onDrop(event) {
      const [from, to] = [event.oldIndex, event.newIndex];
      this.graphs.splice(to, 0, this.graphs.splice(from, 1)[0]);
    },
    addGraph() {
      this.editGraph = null;
      this.showAddGraph = true;
    },
  },
};
</script>

<style scoped>
.selects {
  max-width: 300px;
  margin-right: 40px;
}
.actionDiv {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}
</style>
