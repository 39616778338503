<template>
  <v-container>
    <v-dialog v-model="openDialog" persistent width="550">
      <loader v-if="loading" />
      <v-row align="center" class="py-2">
        <v-col align="center" cols="4"><h3>Add Customer</h3></v-col>
        <v-col><v-spacer /></v-col>
        <v-col align="right" cols="2">
          <v-btn icon @click="openDialog = false">
            <v-icon light>mdi-close-circle </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-divider />
      <v-stepper v-model="fStepper" alt-labels class="secondary">
        <v-stepper-header>
          <v-stepper-step step="1">DETAILS</v-stepper-step>
          <v-divider></v-divider>
          <v-divider></v-divider>
          <v-stepper-step step="2">PERMISSIONS</v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-form ref="form" v-model="userValid" lazy-validation>
              <v-row>
                <v-col align="center" class="mt-1" cols="12">
                  <v-text-field
                    v-model="customerName"
                    :rules="nameRules"
                    label="Customer Name"
                    required
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col align="center" cols="12">
                  <v-select
                    v-model="customerTruckType"
                    :items="truckTypes"
                    label="Customer Truck Type"
                    required
                    outlined
                  ></v-select>
                </v-col>
              </v-row>
            </v-form>
            <v-row>
              <v-col class="pt-0 hidden-xs-and-down"><v-spacer /></v-col>
              <v-col class="pt-0" align="center" cols="3"
                ><v-btn plain color="primary" @click="openDialog = false"
                  >Cancel</v-btn
                ></v-col
              >
              <v-col class="pt-0" align="center" cols="4"
                ><v-btn
                  color="primary"
                  style="color: black"
                  :disabled="!userValid || submitClicked"
                  @click="checkcustomerName"
                  >Continue</v-btn
                ></v-col
              >
            </v-row>
          </v-stepper-content>
          <v-stepper-content step="2" class="pa-0">
            <CustomerPermissions
              :customer-permissions="customerPermissions"
              @cancel="fStepper = 1"
              @returnPermissions="addCustomer"
            />
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-dialog>
  </v-container>
</template>

<script>
import loader from '@/utilities/loader';
import _ from 'lodash';
import { mapActions } from 'vuex';
import { getCompanies, setNewCustomers, addCompany } from '@/api/company';

import CustomerPermissions from './CustomerPermissions.vue';
export default {
  name: 'AddCustomer',
  components: { loader, CustomerPermissions },
  props: {},
  data() {
    return {
      openDialog: false,
      userValid: true,
      fStepper: 1,
      customers: [],
      companyItems: [],
      customerName: '',
      customerEmail: '',
      customerTruckType: 'ERX',
      truckTypes: ['EX', 'ERX', 'Both'],
      customerPermissions: {},
      nameRules: [
        (v) => !!v || 'Name is required',
        (v) => (v && v.length > 2) || 'Name must be greater than 2 characters',
      ],
      loading: true,
      submitClicked: false,
    };
  },
  watch: {
    // Watches for the change in openDialog variable to emit the false variable back to parent to reset
    openDialog: function (newValue) {
      if (!newValue) {
        this.openDialog = false;
        this.$emit('closed', false);
      }
    },
    customerName: function (val) {
      this.customerName = this.filterString(val);
    },
  },
  async mounted() {
    this.openDialog = true;
    this.getCompanyList();
  },
  methods: {
    ...mapActions({
      updateSnack: 'updateSnack',
    }),
    isNullOrUndefined: function (o) {
      return o === null || o === undefined;
    },
    filterString: function (badStr) {
      return badStr.replace(/[^a-zA-Z\d. ]/g, '');
    },
    getCompanyList: async function () {
      const vm = this;
      vm.masterTrucks = [];
      try {
        const res = await getCompanies();
        const resLength = res.data.length;

        if (resLength > 0) {
          vm.loading = false;
          vm.companyItems = vm.cleanupList(res.data);
        }
      } catch (error) {
        this.alertResponse(
          'error',
          'Could not retrieve customer (company) data...'
        );
      }
    },
    cleanupList: function (data) {
      let cleanArr = _.forEach(data, function (o) {
        return o.name;
      });
      return cleanArr.length > 0 ? cleanArr : [];
    },
    checkcustomerName: function () {
      const vm = this;
      if (!this.$refs.form.validate()) {
        return;
      }
      let testName = vm.customerName.toLowerCase();
      let customer = _.find(vm.companyItems, function (o) {
        return o.name.toLowerCase() === testName;
      });
      if (vm.isNullOrUndefined(customer)) {
        this.fStepper = 2;
      } else {
        vm.alertResponse('warning', 'Company already exists!');
      }
    },
    addCustomer: async function (permissions) {
      const vm = this;
      vm.loading = true;
      vm.submitClicked = true;
      try {
        if (this.customerTruckType !== 'ERX') {
          const email_append = Math.round(Math.random() * (90000 - 1) + 1);
          vm.customerEmail = `customer_${email_append}@hyliion.com`;
          const config = {
            customer_name: vm.customerName,
            customer_email: vm.customerEmail,
            external_permissions: permissions,
          };
          // API call for EX or Both(EX/ERX) customer, adds additional back-end logic
          const res = await setNewCustomers(config); // Sets up customer via ldap
          if (res.status === 200 || res.status === 201) {
            _.delay(vm.addNewCompany, 3600);
          }
        } else {
          //API cal for ERX only customer, avoids unecessary back-end logic
          vm.addNewCompany(permissions);
        }
      } catch (error) {
        this.alertResponse('error', 'Could not add new customer!');
      }
    },
    addNewCompany: async function (permissions) {
      // Adds new company to Azure AD and if the customer does not exist, will add the company to the truck table
      const vm = this;
      const config = {
        name: vm.customerName,
        external_permissions: permissions,
      };
      try {
        await addCompany(config);
        vm.customerName = '';
        vm.alertResponse('success', 'Customer Successfully Added!');
      } catch (error) {
        vm.alertResponse('error', 'Could not set customer authorization...');
      }
    },
    alertResponse(type, message) {
      this.loading = false;
      this.openDialog = false;
      this.submitClicked = false;
      this.updateSnack({
        type,
        message,
      });
    },
  },
};
</script>

<style scoped></style>
