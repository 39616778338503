<template>
  <div id="configuration-view">
    <v-container fluid>
      <v-row align="center">
        <v-col>
          <h1 style="font-weight: 400">Configuration Management</h1>
        </v-col>
        <v-col align="right">
          <!-- hide cog until we have functionality to add to nav drawer -->
          <!-- <v-btn
            icon
            tile
            large
            style="background-color: #1a1a1a; border-radius: 5px"
            @click="drawer = true"
            ><v-icon style="opacity: 0.6">mdi-cog</v-icon></v-btn
          > -->
        </v-col>
      </v-row>
      <v-row class="mt-4">
        <v-col cols="4" xs="12" sm="12" md="12" lg="4" xl="4">
          <v-tabs fixed-tabs background-color="secondary">
            <v-tab v-if="userCanSeeAssets" @click="changeTab('Trucks')"
              >Trucks</v-tab
            >
            <v-tab v-if="userCanSeeAssets" @click="changeTab('Customers')"
              >Customers</v-tab
            >
            <v-tab v-if="userCanSeePackages" @click="changeTab('Packages')"
              >Packages</v-tab
            >
          </v-tabs>
        </v-col>
        <v-col class="hidden-sm-and-down"><v-spacer /></v-col>
      </v-row>
    </v-container>
    <truck-view v-if="switchTab === 'Trucks' && userCanSeeAssets" />
    <customer-view v-else-if="switchTab === 'Customers' && userCanSeeAssets" />
    <package-view v-if="switchTab === 'Packages' && userCanSeePackages">
    </package-view>
    <v-navigation-drawer
      v-model="drawer"
      right
      temporary
      fixed
      width="360"
      class="shade"
    >
      <v-container>
        <v-row align="center">
          <v-col align="right" class="mb-2">
            <v-btn icon @click="drawer = false">
              <v-icon light>mdi-close-circle </v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <v-expansion-panels v-model="panel" multiple>
          <v-expansion-panel class="shade">
            <v-expansion-panel-header>Placeholder</v-expansion-panel-header>
            <v-expansion-panel-content> </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-container>
    </v-navigation-drawer>
  </div>
</template>

<script>
import TruckView from '../Trucks/TruckView';
import CustomerView from '../Customers/CustomerView';
import PackageView from './PackageView';

import { mapGetters } from 'vuex';
import { permissions } from '@/api/permissions';
export default {
  name: 'ConfigurationView',
  components: {
    'truck-view': TruckView,
    'customer-view': CustomerView,
    'package-view': PackageView,
  },
  props: {},
  data() {
    return {
      panel: [], // specify index to set default open panels
      drawer: false,
      switchTab: 'Trucks',
    };
  },
  computed: {
    ...mapGetters({
      userRoles: 'getUserRoles',
    }),
    userCanSeeAssets() {
      return this.userRoles && this.userRoles.includes(permissions.configAsset);
    },
    userCanSeePackages() {
      return (
        this.userRoles && this.userRoles.includes(permissions.configPackage)
      );
    },
  },
  methods: {
    changeTab(tab) {
      this.switchTab = tab;
    },
    closeSideDrawer() {
      this.drawer = false;
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media only screen and (max-width: 600px) {
  .margin {
    margin: 0.2rem;
  }
}
</style>
