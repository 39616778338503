<template>
  <v-container fluid class="body">
    <v-snackbar v-model="snack" :color="snackColor">{{
      snackMessage
    }}</v-snackbar>
    <v-row align="center">
      <v-col>
        <h1 style="font-weight: 400">Cloud Health Summary</h1>
        <div style="color: gray">
          User Portal Version {{ portalVersion }}<br />
          HyliionAPI {{ apiVersion }}
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col><v-spacer class="hidden-sm-and-down"></v-spacer></v-col>
      <v-col>
        <h2 class="centerText stoplightTitle">Data&nbsp;Ingestion</h2>

        <img
          class="centerImg stoplight"
          :src="dataStoplight"
          width="200px"
          height="200px"
          alt="circle"
          @click="scrollTo('offset')"
        />
      </v-col>
      <v-col>
        <h2 class="centerText stoplightTitle">API</h2>
        <img
          class="centerImg stoplight"
          :src="apiStoplight"
          width="200px"
          height="200px"
          alt="circle"
        />
      </v-col>
      <v-col>
        <h2 class="centerText stoplightTitle">User Activity</h2>
        <img
          class="centerImg stoplight"
          :src="userStoplight"
          width="200px"
          height="200px"
          alt="circle"
        />
      </v-col>
      <v-col><v-spacer class="hidden-sm-and-down"></v-spacer></v-col>
    </v-row>
    <br />
    <!-- chart layout breaks when placed inside a v-row -->
    <highcharts
      v-if="apiDataLoaded"
      ref="line"
      :options="apiChartOptions"
    ></highcharts>
    <v-sheet v-else class="pa-3" rounded>
      <v-skeleton-loader class="mx-auto" type="list-item"></v-skeleton-loader>
      <v-skeleton-loader class="mx-auto" type="image"></v-skeleton-loader>
    </v-sheet>

    <data-load
      v-if="dataLoadReady"
      :data="dataLoadData"
      style="margin-top: 30px; margin-bottom: 30px"
    ></data-load>
    <v-sheet v-else class="pa-3" rounded>
      <v-skeleton-loader class="mx-auto" type="list-item"></v-skeleton-loader>
      <v-skeleton-loader class="mx-auto" type="image"></v-skeleton-loader>
    </v-sheet>

    <br />
    <h2 class="centerText slim">Recenty Logged Errors</h2>
    <v-virtual-scroll :items="errors" height="450" :item-height="64">
      <template v-slot="{ item }">
        <v-list-item :key="item.trace_id" @click="showErrorModal(item)">
          <v-list-item-action>
            <v-btn
              fab
              small
              depressed
              :color="item.severity === 'WARNING' ? 'warning' : 'error'"
              @click="showErrorModal(item)"
            >
              {{ item.severity.slice(0, 3) }}
            </v-btn>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title>
              <h4>{{ item.label }}</h4>
              {{ item.detail.detail }}
            </v-list-item-title>
          </v-list-item-content>

          <v-list-item-action>
            {{ item.timestamp }}
          </v-list-item-action>
        </v-list-item>
        <v-divider></v-divider>
      </template>
    </v-virtual-scroll>
    <br /><br />

    <v-dialog v-model="dialog" width="600">
      <v-card>
        <v-card-title>
          Label: {{ selectedError && selectedError.label }}
        </v-card-title>
        <v-container>
          <ul>
            <li>Severity: {{ selectedError && selectedError.severity }}</li>
            <li>Detail: {{ selectedError && selectedError.detail.detail }}</li>
            <li>Timestamp: {{ selectedError && selectedError.timestamp }}</li>
            <li>Severity: {{ selectedError && selectedError.severity }}</li>
            <li>App ID: {{ selectedError && selectedError.appID }}</li>
            <li>Actor Type: {{ selectedError && selectedError.actor_type }}</li>
            <li>Method: {{ selectedError && selectedError.method }}</li>
          </ul>
        </v-container>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> Dismiss </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row>
      <v-col>
        <div class="centerText">
          <h2 class="slim">Trucks sending data</h2>
          <h3 class="slim">Time Frame: {{ timeDescription }}</h3>
          <v-btn class="plusMinus" color="secondary" small @click="addDay"
            >+</v-btn
          >
          <v-btn class="plusMinus" color="secondary" small @click="subtractDay"
            >-</v-btn
          >
        </div>
        <br />
        <v-progress-circular
          v-if="perTruckData != null"
          id="progressCircle"
          :rotate="360"
          :size="290"
          :width="70"
          :value="perTruckData"
          color="#43B02A"
          class="centerText"
          >{{ perTruckData + '%' }}</v-progress-circular
        >
        <v-progress-circular
          v-else
          indeterminate
          :size="290"
          color="#43B02A"
          class="centerImg"
        ></v-progress-circular>
      </v-col>
      <v-col>
        <div id="pieWrapper">
          <h2 class="centerText slim">Truck Configurations</h2>
          <div id="pieContainer">
            <pie-chart
              v-if="pieDataReceived"
              id="pieChart"
              :data="pieData"
              :options="pieOptions"
            ></pie-chart>
            <v-progress-circular
              v-else
              indeterminate
              :size="290"
              color="#43B02A"
              class="centerImg"
            ></v-progress-circular>
          </div>
        </div>
      </v-col>
    </v-row>
    <br />
    <br />
    <div class="centerText">
      <h2 class="slim">Successful API Calls</h2>
      <h3 class="slim">Time Frame: {{ apiTimeDescription }}</h3>
      <v-btn class="plusMinus" color="secondary" small @click="apiAdd">+</v-btn>
      <v-btn class="plusMinus" color="secondary" small @click="apiSubtract"
        >-</v-btn
      >
    </div>
    <br />
    <v-progress-circular
      v-if="apiPercentage"
      id="progressCircle"
      :rotate="360"
      :size="290"
      :width="70"
      :value="apiPercentage"
      color="#43B02A"
      class="centerText"
      >{{ apiPercentage + '%' }}</v-progress-circular
    >
    <v-progress-circular
      v-else
      indeterminate
      :size="290"
      color="#43B02A"
      class="centerImg"
    ></v-progress-circular>
    <br /><br />
    <offset-form :trigger-snack="triggerSnack"></offset-form>
    <v-navigation-drawer v-model="drawer" fixed right temporary width="360">
      <v-container>
        <v-row align="center">
          <v-col align="right" class="mb-2">
            <v-btn icon @click="drawer = false">
              <v-icon light>mdi-close-circle </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <p style="text-align: center">No content</p>
      </v-container>
    </v-navigation-drawer>
  </v-container>
</template>

<script>
import PieChart from '@/utilities/PieChart';
import { getADXLastPing, getCloudTrucks } from '@/api/trucks';
import {
  getLoggedErrors,
  getAPICalls,
  getUsers,
  getAPIPercentage,
  getAPIVersion,
  getDataLoad,
} from '@/api/cloudHealth';
import DataLoad from './DataLoad';
import OffsetForm from './OffsetForm';

export default {
  name: 'Cloud',
  components: {
    PieChart,
    DataLoad,
    OffsetForm,
  },
  data() {
    return {
      snackColor: 'success',
      snackMessage: '',
      snack: false,
      offsetModel: null,
      realOffset: null,
      dataLoadReady: false,
      dataLoadData: null,
      apiVersion: null,
      portalVersion: null,
      drawer: false,
      dataStoplight: '../images/stoplights/Loading.svg',
      apiStoplight: '../images/stoplights/Loading.svg',
      userStoplight: '../images/stoplights/Loading.svg',
      apiDataLoaded: false,
      selectedError: null,
      dialog: false,
      pieDataReceived: false,
      numRed: 0,
      numGreen: 0,
      numGrey: 0,
      dataSent: 0,
      truckConfig: {},
      // percent of trucks that have sent data in the last 24 hours
      perTruckData: null,
      apiPercentage: null,
      hours: 24,
      apiDays: 1,
      pieData: {
        labels: [
          'Sending Data',
          'In Truck Table',
          'Sending Data but not in Truck Table',
        ],
        datasets: [
          {
            label: 'My First Dataset',
            data: [],
            hoverOffset: 4,
            cutout: '50%',
            backgroundColor: ['#43b02a', '#d50000', 'grey'],
            borderColor: '#1A1A1A',
            rotation: 90,
          },
        ],
      },
      pieOptions: {
        cutoutPercentage: 50,
        height: '50px',
        width: '50px',
        hoverOffset: 5,
        rotation: 0,
      },
      headers: [
        { text: 'Date', align: 'center', value: 'date' },
        { text: 'Error', align: 'center', value: 'name' },
      ],
      // placeholder errors
      errors: [
        {
          timestamp: '',
          severity: 'ERROR',
          appID: '',
          trace_id: '',
          actor_type: '',
          actor_id: '',
          method: '',
          label: 'There was an issue fetching recently logged errors',
          detail: {
            detail: '',
          },
        },
      ],
      apiChartOptions: {
        chart: {
          backgroundColor: '#1E1E1E',
          type: 'line',
          zoomType: 'x',
          name: '',
          events: {
            selection: (e) => {
              const chartName = e.target.title.textStr;
              if (e.xAxis) {
                this.zoomedChart = chartName;
                this.xMin = e.xAxis[0].min;
                this.xMax = e.xAxis[0].max;
                this.zoomCharts(true);
              } else {
                this.zoomedChart = '';
                this.xMin = null;
                this.xMax = null;
                this.zoomCharts(false);
              }
            },
          },
        },
        rangeSelector: {
          selected: 1,
        },
        title: {
          text: 'API Status',
          style: {
            color: '#FFFFFF',
          },
        },
        tooltip: {
          shared: true,
          backgroundColor: '#363636',
          borderRadius: 8,
          borderColor: '#363636',
          style: {
            color: '#FFFFFF',
          },
        },
        legend: {
          itemStyle: {
            color: '#FFFFFF',
            fontWeight: 'bold',
          },
        },
        plotOptions: {
          series: {
            stacking: 'normal',
            borderColor: '#1E1E1E',
          },
        },
        xAxis: {
          type: 'datetime',
          title: {
            text: 'Time',
            style: {
              color: '#FFFFFF',
            },
          },
          labels: {
            step: 1,
            style: {
              color: '#FFFFFF',
            },
          },
          lineColor: 'rgba(255, 255, 255, 0.24)',
        },
        yAxis: [
          {
            gridLineColor: ' rgba(255, 255, 255, 0.12)',
            title: {
              text: 'Size (MB)',
            },
            labels: {
              format: '{value}',
              style: {
                color: 'white',
              },
            },
          },
          {
            gridLineColor: ' rgba(255, 255, 255, 0.12)',
            title: {
              text: 'Count',
            },
            labels: {
              format: '{value}',
              style: {
                color: 'white',
              },
            },
            opposite: true,
          },
        ],
        setOptions: {
          width: '100%',
        },
        series: [
          {
            name: 'Calls to Hyliion API',
            yAxis: 0,
            data: [],
            color: '#43B02A',
          },
          {
            name: 'Unique users per day',
            yAxis: 1,
            data: [],
          },
        ],
      },
    };
  },
  computed: {
    timeDescription() {
      if (this.hours === 24) {
        return '24 hours';
      } else {
        return `${this.hours / 24} days`;
      }
    },
    apiTimeDescription() {
      if (this.apiDays == 1) {
        return '24 hours';
      } else {
        return `${this.apiDays} days`;
      }
    },
  },
  watch: {
    // populate data ingestion stoplight
    perTruckData: function () {
      if (this.perTruckData === null) {
        return;
      } else if (this.perTruckData >= 30) {
        this.dataStoplight = '../images/stoplights/Success.svg';
      } else if (this.perTruckData >= 20) {
        this.dataStoplight = '../images/stoplights/Warning.svg';
      } else {
        this.dataStoplight = '../images/stoplights/Error.svg';
      }
    },
    apiPercentage: function () {
      if (this.apiPercentage >= 99) {
        this.apiStoplight = '../images/stoplights/Success.svg';
      } else if (this.apiPercentage >= 95) {
        this.apiStoplight = '../images/stoplights/Warning.svg';
      } else {
        this.apiStoplight = '../images/stoplights/Error.svg';
      }
    },
  },
  async mounted() {
    this.fetchVersions();
    this.fetchLoggedErrors();
    this.findTruckPercentage();
    this.findNumFullyConfig();
    this.fetchDataLoad();
    this.populateAPIChart();
    this.findAPIPercentage();
  },
  methods: {
    addDay() {
      this.hours += 24;
      this.findTruckPercentage();
    },
    subtractDay() {
      if (this.hours - 24 >= 0) {
        this.hours -= 24;
      }
      this.findTruckPercentage();
    },
    apiAdd() {
      this.apiDays += 1;
      this.findAPIPercentage();
    },
    apiSubtract() {
      if (this.apiDays >= 1) {
        this.apiDays -= 1;
      }
      this.findAPIPercentage();
    },
    convertToEpoch(utcTime) {
      return Date.parse(new Date(utcTime));
    },
    async findTruckPercentage() {
      const timeStamps = await getADXLastPing();
      this.dataSent = 0;
      timeStamps.data.map((truck) => {
        const epochTimestamp = this.convertToEpoch(truck.max_timestamp);
        if (epochTimestamp > Date.now() - this.hours * 60 * 60 * 1000) {
          this.dataSent += 1;
        }
      });
      this.perTruckData = Math.round(
        100 * (this.dataSent / timeStamps.data.length)
      );
    },
    async findNumFullyConfig() {
      const truckTable = await getCloudTrucks();

      // immediately every truck we know of is at least red
      truckTable.data.forEach((truck) => {
        this.truckConfig[truck.id] = 'red';
      });

      // eventually: upgrade if truck_id exists in embedded device table; api path to be added for this

      // if truck has been pinged, then upgrade status
      const timeStamps = await getADXLastPing();

      timeStamps.data.forEach((truck) => {
        if (truck.truck_id in this.truckConfig) {
          this.upgradeStatus(truck.truck_id);
        } else {
          this.truckConfig[truck.truck_id] = 'grey';
        }
      });
      this.tallyStatuses();
    },
    upgradeStatus(truck_id) {
      if (this.truckConfig[truck_id] === 'red') {
        this.truckConfig[truck_id] = 'green';
      }
    },
    tallyStatuses() {
      // tally totals
      for (const id in this.truckConfig) {
        if (this.truckConfig[id] === 'red') {
          this.numRed += 1;
        } else if (this.truckConfig[id] === 'green') {
          this.numGreen += 1;
        } else if (this.truckConfig[id] === 'grey') {
          this.numGrey += 1;
        }
      }
      // update pie chart data
      this.pieData.datasets[0].data = [
        this.numGreen,
        this.numRed,
        this.numGrey,
      ];
      this.pieDataReceived = true;
    },
    showErrorModal(error) {
      this.selectedError = error;
      this.dialog = true;
    },
    async fetchLoggedErrors() {
      let errors;
      try {
        errors = await getLoggedErrors(50, 'WARNING');
      } catch (e) {
        console.log('Error on GET logged errors:', e);
      }
      this.errors = errors.data;
    },
    populateAPIChart() {
      // initialize today as midnight tonight UTC time
      let today = new Date();
      today.setUTCHours(24, 0, 0, 0);
      today = Date.parse(today);
      let startDay = today - 86400000 * 10;
      // populate chart
      this.findNumAPICalls(startDay, today).then(() => {
        this.findUniqueUsers(startDay, today).then(() => {
          this.apiDataLoaded = true;
        });
      });
    },
    async findNumAPICalls(startDay, today) {
      let result;
      try {
        result = await getAPICalls(startDay, today);
      } catch (e) {
        console.log('Error on getAPICalls() :', e);
      }

      let chartSeries = [];
      // set all data points as 0
      for (let i = 0; i < 10; i++) {
        chartSeries.push([i * 86400000 + startDay, 0]);
      }
      // fill in known data points with data
      for (const item of result.data) {
        const index = (Date.parse(item.timestamp) - startDay) / 86400000;
        chartSeries[index] = [Date.parse(item.timestamp), item.count];
      }
      this.apiChartOptions.series[0].data = chartSeries;
    },
    async findUniqueUsers(startDay, today) {
      let userResult;
      try {
        userResult = await getUsers(startDay, today);
      } catch (e) {
        console.log('Error on getUsers() :', e);
      }

      let users = {
        0: new Set(),
        1: new Set(),
        2: new Set(),
        3: new Set(),
        4: new Set(),
        5: new Set(),
        6: new Set(),
        7: new Set(),
        8: new Set(),
        9: new Set(),
      };

      let userData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      for (const datum of userResult.data) {
        const timestamp = Math.round(Date.parse(datum.timestamp)) + 40000;
        // tally up unique users for each of the past 10 days
        for (let i = 0; i < 10; i++) {
          if (
            timestamp >= startDay + i * 86400000 &&
            timestamp < startDay + (i + 1) * 86400000 &&
            !users[i].has(datum.actor_id)
          ) {
            users[i].add(datum.actor_id);
            userData[i] += 1;
          }
        }
      }

      // add data to highcharts in time series
      for (let i = 0; i < userData.length; i++) {
        this.apiChartOptions.series[1].data.push([
          startDay + 86400000 * i,
          userData[i],
        ]);
      }
    },
    async findAPIPercentage() {
      // populate API stoplight
      const today = Date.parse(new Date());
      const startDay = today - this.apiDays * 86400000;
      const percentage = await getAPIPercentage(startDay, today);
      this.apiPercentage = Math.round(percentage.data * 1000) / 10;
    },
    async fetchVersions() {
      this.portalVersion = process.env.PACKAGE_VERSION;
      try {
        this.apiVersion = await getAPIVersion();
        this.apiVersion = this.apiVersion.data;
      } catch (e) {
        console.log('Error fetching version', e);
      }
    },
    async fetchDataLoad() {
      const res = await getDataLoad();
      this.dataLoadData = res.data;
      this.dataLoadReady = true;
    },
    scrollTo(id) {
      document.getElementById(id).scrollIntoView();
    },
    triggerSnack(message, type) {
      this.snackMessage = message;
      if (!type) this.snackColor = 'gray';
      else if (type.toLowerCase() === 'success') this.snackColor = 'success';
      else if (type.toLowerCase() === 'error') this.snackColor = 'error';
      this.snack = true;
    },
  },
};
</script>

<style scoped>
.centerText {
  text-align: center;
}
.centerImg {
  display: block;
  margin: auto;
}
#progressCircle {
  display: block;
  margin: auto;
  padding: 135px;
}
#pieContainer {
  display: block;
  margin: auto;
  height: 350px;
  width: 350px;
}
@media only screen and (max-width: 1903px) {
  #pieWrapper {
    padding-top: 38px;
  }
}
.pointer {
  cursor: pointer;
}
.plusMinus {
  margin: 2.5px;
}
.stoplightTitle {
  margin-bottom: -25px;
  font-weight: 400;
}
.stoplight {
  margin-bottom: -40px;
}
.slim {
  font-weight: 400;
}
</style>
