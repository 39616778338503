var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.type === "fault"
        ? _c("faulted-table", { attrs: { trucks: _vm.trucks, actions: false } })
        : _vm.type === "inShop"
        ? _c("in-shop-table", { attrs: { trucks: _vm.trucks, actions: false } })
        : _vm.type === "notInUse"
        ? _c("not-in-use-table", {
            attrs: { trucks: _vm.trucks, actions: false },
          })
        : _vm.type === "normal"
        ? _c("normal-table", { attrs: { trucks: _vm.trucks, actions: false } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }