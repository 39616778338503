<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-spacer class="hidden-sm-and-down"></v-spacer>
      </v-col>
      <v-col>
        <h1 class="centerText">Customer Trucks</h1>
      </v-col>
      <v-col>
        <v-spacer class="hidden-sm-and-down"></v-spacer>
      </v-col>
    </v-row>
    <br />
  </v-container>
</template>

<script>
export default {
  name: 'ExternalTrucks',
  data() {
    return {};
  },
  watch: {},
  mounted() {},
  methods: {},
};
</script>

<style scoped></style>
