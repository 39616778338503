<template>
  <v-container fluid>
    <v-row>
      <h1 style="font-weight: 400">ERX Dashboard</h1>
    </v-row>
    <v-row v-if="showAlert">
      <v-card
        style="display: block; margin: auto; text-align: center; color: black"
        class="pa-2 error"
        elevation="4"
      >
        <span> {{ alertType + ': ' + alertMessage }} </span>
      </v-card>
    </v-row>
    <v-row class="pb-0">
      <v-col>
        <v-spacer />
      </v-col>
      <v-col cols="10">
        <v-card
          v-if="appWarning"
          style="text-align: center; color: black"
          class="pa-2 error"
          :class="hideBlock"
          elevation="4"
        >
          <span
            >WARNING: No functionality, data, or analysis within this
            application should be used for business purposes at this time. We
            are excited to have your input, questions, and suggestions on the
            User Portal, however the application should be considered a
            prototype only and not relied on for business or customer impacting
            use at this time. If you find issues, need help, or have suggestions
            please feel free to reach out to Bill Clements with feedback (which
            will always be welcome).</span
          >
        </v-card>
      </v-col>
      <v-col>
        <v-spacer />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" class="chart-bg">
        <v-card elevation="0" class="block-bg">
          <div class="block-bg" style="display: grid; min-height: 300px">
            <div style="font-size: 20px; padding-left: 10px; z-index: 1">
              All ERX Trucks Overview<br />
            </div>
            <PieCharts />
          </div>
        </v-card>
      </v-col>
      <v-col cols="6" class="chart-bg">
        <v-card elevation="0" class="chart-bg">
          <LineCharts />
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-tabs v-model="tab">
          <v-tab>Active DTCs</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card flat>
              <v-data-table
                :headers="headers"
                :items="dtcTrucks"
                :items-per-page="10"
                :loading="loading"
                loading-text="Loading... Please wait"
                class="elevation-1 secondary locked-table-style row-height-50"
              >
                <template v-slot:[`item.truckName`]="{ item }">
                  <th class="body-5">{{ item.truckName }}</th>
                  <div class="body-6">{{ item.company }}</div>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-menu style="top: 312px; left: 300px" offset-y>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on">mdi-dots-vertical</v-icon>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(route, index) in actionItems"
                        :key="index"
                        :class="route.title"
                        @click="setRoute(item, route.title)"
                        >{{ route.title }}</v-list-item
                      >
                    </v-list>
                  </v-menu>
                </template>
              </v-data-table>
            </v-card>
          </v-tab-item>
          <!-- <v-tab-item>
            <v-card>
              <v-card-text>
                <div id="truck-fpo" :style="{ display: displayType }">
                  <h6 class="emptyStudyHeader">Right On!</h6>
                  <p class="emptyStudyText">
                    There is no recent activity since last logged in.
                  </p>
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item> -->
        </v-tabs-items>
      </v-col>
      <v-col cols="6">
        <div id="id-td-MapView" class="data">
          <div id="map">
            <div
              id="mapContainer"
              ref="hereMap"
              :style="`height: ${mapHeight}px; width: 100%`"
            ></div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import _ from 'lodash';
import { getSpnfmiStates } from '@/api/dtc';
import { getHealthTrucks, getStatusGraph, getADXLocations } from '@/api/trucks';

import LineCharts from '@/components/Dashboard/Charts/LineCharts';
import PieCharts from '@/components/Dashboard/Charts/PieCharts';
import dateFormat from 'dateformat';
import { getDTCs } from '@/api/dataDictionary';

export default {
  name: 'Dashboard',
  components: {
    PieCharts,
    LineCharts,
  },
  props: {},
  data() {
    return {
      tab: 0,
      loading: true,
      appWarning: true,
      showAlert: false,
      alertType: null,
      alertMessage: null,
      alertInterval: 10000,
      displayType: 'block',
      hideBlock: '',
      locationLookup: null,
      center: {
        lat: 39.8283,
        lng: -98.5795,
      },
      headers: [
        { text: 'Truck # / Customer', value: 'truckName' },
        { text: 'Timestamp', value: 'timestamp' },
        { text: 'DTC Name', value: 'dtcName' },
        { text: 'Location', value: 'address' },
        {
          text: '',
          value: 'actions',
          sortable: false,
          align: 'right',
        },
      ],
      actionItems: [{ title: 'Test FPO' }],
      truckList: [],
      truckData: [],
      movingTrucks: [],
      dtcTrucks: [],
      markers: {},
    };
  },
  computed: {
    mapHeight() {
      return window.innerHeight - 400;
    },
    ...mapGetters({
      spnfmiStates: 'getSpnfmiStates',
      exDTCs: 'getEXDTCs',
      HERE_MAP_KEY: 'getMapKey',
    }),
  },
  watch: {
    hideBlock: function (val) {
      // remove the element after fadeout animation class added
      const vm = this;
      if (val) {
        _.delay(function () {
          vm.appWarning = false;
        }, 1000);
      }
    },
  },
  async created() {
    if (!this.spnfmiStates.length) {
      const { data } = await getSpnfmiStates();
      await this.asyncUpdateSpnfmiStates(data);
    }
    if (!this.exDTCs.length) {
      const { data } = await getDTCs();
      await this.asyncUpdateEXDTCs(data);
    }
  },
  async mounted() {
    const vm = this;
    vm.initializeHereMap();
    await vm.populateTruckList();
    _.delay(function () {
      vm.hideBlock = 'fader';
    }, 11000);
  },
  methods: {
    ...mapActions([
      'asyncAssignTruck',
      'asyncUpdateSpnfmiStates',
      'asyncUpdateEXDTCs',
    ]),
    isNullOrUndefined: function (o) {
      return o === null || o === undefined;
    },
    async initializeHereMap() {
      this.apikey = this.HERE_MAP_KEY;
      const platform = new window.H.service.Platform({
        apikey: this.apikey,
      });
      this.platform = platform;
      const mapContainer = this.$refs.hereMap;
      const H = window.H;
      // Obtain the default map types from the platform object
      const maptypes = this.platform.createDefaultLayers();
      // Instantiate (and display) a map object:
      const map = new H.Map(mapContainer, maptypes.vector.normal.map, {
        zoom: 4,
        center: this.center,
      });
      this.hereMap = map;
      addEventListener('resize', () => map.getViewPort().resize());
      // add behavior control
      new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
      // add UI
      this.hereUI = H.ui.UI.createDefault(map, maptypes);
      // get an instance of the routing service version 8
      this.hereRouter = platform.getRoutingService(null, 8);
      // select UI buttons for positioning
      const zoom = this.hereUI.getControl('zoom');
      const mapSettings = this.hereUI.getControl('mapsettings');
      zoom.setAlignment('right-top');
      mapSettings.setAlignment('right-top');
      // style buttons manually to match cog
      const htmlZoom = zoom.getElement();
      htmlZoom.style.background = '#303030';
      htmlZoom.children[0].style.background = '#1a1a1a';
      htmlZoom.children[1].style.background = '#1a1a1a';
      htmlZoom.children[0].firstChild.firstChild.firstChild.style.fill =
        '#9e9e9e';
      htmlZoom.children[1].firstChild.firstChild.style.fill = '#9e9e9e';
      const htmlSetttings = mapSettings.getElement();
      htmlSetttings.firstChild.style.background = '#1a1a1a';
      htmlSetttings.firstChild.firstChild.firstChild.style.fill = '#9e9e9e';
      // remove satellite view option
      htmlSetttings.children[1].firstChild.children[1].remove();
      htmlSetttings.children[1].children[1].remove();
    },
    async populateTruckList() {
      const vm = this;
      vm.truckData = [];
      try {
        let dtc, locations;
        await Promise.allSettled([
          getHealthTrucks(null, 'ERX'),
          getStatusGraph(false),
          getADXLocations(),
        ]).then((results) => {
          results[0].status === 'fulfilled'
            ? (vm.truckData = results[0].value.data)
            : console.log(results[0].reason);

          results[1].status === 'fulfilled'
            ? (dtc = results[1].value.data)
            : console.log(results[1].reason);

          results[2].status === 'fulfilled'
            ? (locations = results[2].value.data)
            : console.log(results[2].reason);
        });
        if (vm.truckData.length > 0) {
          vm.dtcTrucks = vm.cleanupTrucks(dtc, vm.truckData);
          vm.loading = false;

          for (const truck of vm.truckData) {
            vm.$set(truck, 'name', truck.number);
            vm.$set(truck, 'address', truck.address);
            vm.$set(truck, 'status', truck.status);
          }
          // create lookup for locations
          let locationLookup = {};
          locations.forEach(
            (truck) => (locationLookup[truck.truck_id] = truck)
          );
          this.locationLookup = locationLookup;
          vm.buildMapMarkers(null);
        } else {
          console.log('Response array for trucks is empty...');
        }
      } catch (error) {
        this.alertResponse('Error', 'could not retrieve truck data...');
      }
    },
    async buildMapMarkers(item) {
      const H = window.H;
      const vm = this;

      vm.cleanTruckMarkers();
      if (vm.truckUpdateCounter < 2) {
        vm.truckData.sort(vm.sortTruckArray);
      }
      const testLength = vm.truckData.length;
      let testLat = 0;
      let testLng = 0;

      vm.hereGroup = new H.map.Group();

      for (let i = 0; i < testLength; i++) {
        const id = vm.truckData[i].id;
        if (
          !this.locationLookup[id] ||
          (vm.locationLookup[id].latitude === 0 &&
            vm.locationLookup[id].longitude === 0)
        ) {
          continue; // skip if 0,0 coordinates or no adx location
        } else {
          testLat = this.locationLookup[id].latitude;
          testLng = this.locationLookup[id].longitude;
        }
        const tmpPos = { lat: testLat, lng: testLng };

        // set moving & online status
        let movingStatus = 'Idle';
        let iconName = null;
        let healthColor;
        let healthStatus;
        let dtcStatus;

        if (vm.movingTrucks.indexOf(id) >= 0) {
          movingStatus = 'Moving';
        }

        // offline if no position data in past 24 hours
        const now = Date.parse(new Date());
        if (Date.parse(vm.locationLookup[id].timestamp) < now - 86400000) {
          iconName = 'Off-line.svg';
          healthColor = '#9E9E9E';
          healthStatus = 'Offline';
        }

        if (!vm.truckData[i].dtc && !vm.truckData[i].pre_dtc) {
          dtcStatus = 'Normal';
          if (healthStatus !== 'Offline') {
            healthStatus = 'Healthy';
            healthColor = '#4caf50';
          }
        } else if (vm.truckData[i].pre_dtc && !vm.truckData[i].dtc) {
          dtcStatus = 'Warning';
          healthStatus = 'Hazard';
          healthColor = '#F9A825';
          iconName = null;
        } else {
          dtcStatus = 'Error';
          healthStatus = 'Error';
          healthColor = '#D50000';
          iconName = null;
        }

        // use white text on error icon
        // let textColor = 'black';
        if (!iconName) {
          // textColor = healthStatus === 'Error' ? 'white' : 'black';
          iconName = `${healthStatus}_${movingStatus}.svg`;
        }

        let width = 32;
        let markerContent = `
          <div class="hymarker" style="display:block;position:relative;width:30px;height:43px;cursor:pointer;
            background-image:url(../images/icons/small/${iconName});z-index:10;background-size:cover;
            margin-left: -${width / 2}px; margin-top: -36px;">
          </div>`;

        const infoWidth = this.getInfoWidth(
          vm.truckData[i].company,
          vm.truckData[i].name,
          dtcStatus,
          dateFormat(vm.locationLookup[id].timestamp, 'm/d/yy h:MM:ss Z', true)
        );

        // prettier-ignore
        const infoContent = `
        <div style="width: ${infoWidth}px; color: #ffffff; font-weight: 100">
          <span style="color: #757575"> Company: </span> ${vm.truckData[i].company}<br>
          <span style="color: #757575"> Truck Name: </span> ${vm.truckData[i].name}<br>
          <span style="color: #757575"> DTC: </span> ${dtcStatus}<br>
          <span style="color: #757575"> Last Updated: </span> ${dateFormat(vm.locationLookup[id].timestamp, 'm/d/yy h:MM:ss Z', true)}
        </div>`;

        // Add new marker to map
        const domIcon = new H.map.DomIcon(markerContent);
        const newMarker = new H.map.DomMarker(tmpPos, {
          icon: domIcon,
          data: {
            healthStatus: healthStatus,
            healthColor: healthColor,
            company: vm.truckData[i].company,
            truck_id: id,
            number: vm.truckData[i].number,
            name: vm.truckData[i].name,
            movingStatus: movingStatus,
            info: infoContent,
            markerType: 'truck',
          },
        });
        this.hereGroup.addObject(newMarker);
        // set z-index based on health status & truck_type
        if (
          vm.truckData[i].truck_type &&
          vm.truckData[i].truck_type.includes('ERX')
        ) {
          newMarker.setZIndex(99);
        } else if (iconName === 'Off-line.svg') {
          newMarker.setZIndex(97);
        } else if (healthStatus === 'Healthy') {
          newMarker.setZIndex(96);
        } else if (healthStatus === 'Hazard') {
          newMarker.setZIndex(98);
        } else if (healthStatus === 'Error') {
          newMarker.setZIndex(100);
        }

        if (testLat !== 0 && testLng !== 0) {
          if (item && item === vm.truckData[i].company) {
            vm.markers[id] = newMarker;
          }
          if (!item || item === 'All Trucks') {
            vm.markers[id] = newMarker;
          }
          if (!vm.isNullOrUndefined(vm.markers[id])) {
            vm.$set(vm.markers[id], 'status', dtcStatus);
          }
        }
      }

      this.hereMap.addObject(this.hereGroup);

      vm.setupClickableMarkers();
      // vm.updateLocations();
      return false;
    },
    getInfoWidth(company, name, status, timestamp = null) {
      return Math.max(
        // + 26 to accomodate X in top right corner
        this.getBubbleTextWidth(`Company: ${company}`) + 26,
        this.getBubbleTextWidth(`Truck Name: ${name}`),
        this.getBubbleTextWidth(`DTC: ${status}`),
        timestamp ? this.getBubbleTextWidth(`Last Updated: ${timestamp}`) : 0
      );
    },
    getBubbleTextWidth(string) {
      const font = '14px Lucida Grande, Arial, Helvetica';
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      context.font = font;
      const width = context.measureText(string.trim()).width;
      return Math.ceil(width);
    },
    getMarkerContent(healthStatus, movingStatus, width, text, pointer) {
      // set health color & check input
      let healthColor;
      if (healthStatus === 'Healthy') {
        healthColor = '#4caf50';
      } else if (healthStatus === 'Hazard') {
        healthColor = '#F9A825';
      } else if (healthStatus === 'Error') {
        healthColor = '#D50000';
      } else if (healthStatus === 'Offline') {
        healthColor = '#9E9E9E';
      } else {
        throw `Invalid healthStatus given to getMarkerContent();\nhealthStatus: ${healthStatus}`;
      }
      // check movingStatus input
      if (movingStatus != 'Moving' && movingStatus != 'Idle') {
        throw `Invalid movingStatus given to getMarkerContent();\nmovingStatus: ${movingStatus}`;
      }
      // set cursor behavior
      const cursor = pointer ? 'cursor:pointer;' : '';
      // prettier-ignore
      const markerContent = `
        <div style="margin-left: -${(width / 2) + 1}px;margin-top: -36px;${cursor}">
          <div style="position: absolute; left: ${width / 2 - 6.5}px; top: 30px; ">
            <div class="hymarker" style="display:block;position:absolute;width:13px;height:9px;
              background-image:url(../images/icons/ticks/${healthStatus}_tick.svg);z-index:3000;background-size:cover;">
            </div>
          </div>
          <div style="position: absolute;width: ${width + 2}px;height: 30px;background: #f4f4f4;border-radius: 4px;z-index: 1;
            margin-top:1px;">
            <div style="position: absolute;width: ${width}px;height: 28px;margin-top: 1px;margin-left: 1px;
              background: ${healthColor};border-radius: 4px;z-index: 1;">
              <span style="position: absolute;padding-top: 4px;padding-left:5px;"z-index: 1;>
                <img src="../images/icons/${movingStatus}.svg" />
              </span>
              <span style="color: #c02537 ; position: absolute;font-size: 12px;font-weight: bold;color: black;margin-top: 5px;
                margin-left: 30px;z-index: 1;">
                ${text}
              </span>
            </div>
          </div>
        </div>`;
      return markerContent;
    },
    cleanTruckMarkers() {
      if (this.hereGroup && this.hereMap) {
        this.hereMap.removeObject(this.hereGroup);
        this.hereGroup = null;
      }
    },
    setupClickableMarkers() {
      const vm = this;
      this.hereMap.addEventListener('tap', (event) => {
        // remove all open bubbles
        this.hereUI
          .getBubbles()
          .forEach((bub) => this.hereUI.removeBubble(bub));
        // if target has no data (i.e. is the map or a cluster) return
        let bubble;
        if (
          !event.target.getData ||
          (event.target.data.a && !event.target.data.a.data)
        ) {
          return;
        } else if (
          event.target.data.markerType === 'truck' ||
          (event.target.data.a &&
            event.target.data.a.data.markerType === 'truck')
        ) {
          const info =
            event.target.data.markerType === 'truck'
              ? event.target.getData().info
              : event.target.data.a.data.info;

          bubble = new window.H.ui.InfoBubble(event.target.getGeometry(), {
            content: `
                  ${info}
                  <hr style="border: 1px solid #888888; margin-top: 7px; margin-bottom: 7px;">
                  <div style="width: 150px; margin-left: -23px; margin-bottom: -10px; text-align: left;">
                    <ul style="list-style: none;">
                      <li class="health">
                        <a>
                        <i class="mdi mdi-link"
                          style="filter: invert(49%) sepia(72%) saturate(368%) hue-rotate(72deg) brightness(103%) contrast(93%);
                            font-size: 23px; position: relative; top: 4px;">
                        </i>
                        &nbspHealth Detail
                        </a>
                      </li>
                    </ul>
                  </div>`,
          });
        }

        this.hereUI.addBubble(bubble);
        if (event.target.data.markerType === 'truck') {
          // (below bubble method only works after bubble is added to UI)
          const bubbleHTML = bubble.getContentElement();
          // connect links to functions
          bubbleHTML
            .getElementsByClassName('health')[0]
            .addEventListener('click', healthPressed);
        }
        async function healthPressed() {
          vm.$router.push(`health/${event.target.data.truck_id}/details`);
        }
      });
    },
    // updateLocations() {
    //   clearInterval(this.locationInterval);
    //   const frequency = this.clustering ? 60000 : 10000;
    //   this.locationInterval = setInterval(() => {
    //     this.today = new Date();
    //     this.populateTruckList();
    //   }, frequency);
    // },
    cleanupTrucks(dtcTrucks, dataTrucks) {
      dtcTrucks = dtcTrucks.filter((d) => {
        if (d.dtc_active !== false) {
          const matchTrucks = dataTrucks.filter((t) => t.id === d.truck_id);
          d.truckName = matchTrucks[0].number;
          d.company = matchTrucks[0].company;
          d.timestamp = dateFormat(d.timestamp, 'mm/dd/yyyy hh:MM TT');
          d.address = matchTrucks[0].address;
          if (d.truck_type === 'ERX') {
            let data = this.spnfmiStates.filter(
              (s) => s.spnfmi === `${d.spn}${d.fmi}`
            );
            d.dtcName = data[0]?.dtc_name || '';
          } else if (d.truck_type === 'EX') {
            let data = this.exDTCs.filter((dtc) => dtc.dtc_hex === d.code);
            d.dtcName = data[0]?.component || '';
          }
          return d;
        }
      });
      return dtcTrucks;
    },
    alertResponse(type, message) {
      this.loading = false;
      this.showAlert = true;
      this.alertType = type;
      this.alertMessage = message;
      setTimeout(() => {
        this.showAlert = false;
      }, this.alertInterval);
    },
    setRoute: async function (truck, route) {
      if (route === 'Truck Details') {
        await this.asyncAssignTruck({ ...truck });
        this.$router.push(`health/${truck.id}`);
      } else if (route === 'Map View') {
        this.$router.push(`map`);
      } else if (route === 'Configuration Management') {
        this.$router.push('configuration-management');
      } else if (route === 'Uninstall') {
        this.openUninstallDialog = true;
        this.uninstalledTruck = truck.id;
      } else if (route === 'Trip Details') {
        this.$router.push(`trips`);
      }
    },
  },
};
</script>

<style scoped>
.container--fluid {
  max-width: 96%;
}
.fader {
  opacity: 0;
  transition: opacity 1000ms linear;
}
.v-card__title {
  font-size: 1.65rem;
}
/* #truck-fpo {
  position: relative;
  min-height: 370px;
  background-image: url('../../../public/images/assets/Peterbilt_579_BG_Studies.png');
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: 60px;
  margin: 0 auto;
} */
.emptyStudyHeader {
  font-family: 'Rawline', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  /* or 140% */
  display: block;
  position: relative;
  text-align: center;
  letter-spacing: 0.15px;
  /* ◆ Dark / Main Color */
  color: #e0e0e0;
}
.emptyStudyText {
  text-align: center;
  font-family: 'Rawline', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  display: block;
  position: relative;
}
#map {
  width: 100% !important;
  height: 100%;
}
.block-bg {
  background-color: #272727;
}

.chart-bg {
  background-color: #1a1a1a;
}
</style>
