var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "550", persistent: "" },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "pa-4 text-center" },
        [
          _c(
            "v-row",
            { attrs: { align: "center" } },
            [
              _c("v-col", { attrs: { align: "left", cols: "4" } }, [
                _c("h3", [_vm._v("Live Fleet View")]),
              ]),
              _c("v-col", [_c("v-spacer")], 1),
              _c(
                "v-col",
                { attrs: { align: "right", cols: "2" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("close")
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { color: "#979797" } }, [
                        _vm._v("mdi-close "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-col", { staticClass: "pb-0", attrs: { align: "center" } }, [
                _c("span", { staticClass: "text-h6" }, [
                  _vm._v("Choose a fleet to use live fleet view."),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("v-select", {
                    attrs: {
                      items: _vm.filteredCompanies,
                      "item-text": "name",
                      "item-value": "id",
                      "return-object": "",
                      clearable: "",
                      outlined: "",
                      "hide-selected": "",
                      label: "Select Fleet",
                    },
                    model: {
                      value: _vm.selectedCompany,
                      callback: function ($$v) {
                        _vm.selectedCompany = $$v
                      },
                      expression: "selectedCompany",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "mb-1 pt-0" },
                [
                  _c(
                    "v-btn",
                    {
                      staticStyle: { color: "black" },
                      attrs: { color: "primary", width: "100%" },
                      on: {
                        click: function ($event) {
                          return _vm.changeCompany()
                        },
                      },
                    },
                    [_vm._v("Activate View")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }