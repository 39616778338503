var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      staticClass: "dialog",
      attrs: { width: "750" },
      model: {
        value: _vm.openDialog,
        callback: function ($$v) {
          _vm.openDialog = $$v
        },
        expression: "openDialog",
      },
    },
    [
      _vm.showAlert
        ? _c("alert", {
            attrs: {
              "alert-type": _vm.alertType,
              "alert-message": _vm.alertMessage,
            },
          })
        : _vm._e(),
      _vm.loading ? _c("loader") : _vm._e(),
      _c(
        "v-card",
        [
          _c(
            "v-container",
            [
              _c(
                "v-form",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "hidden-xs-and-down" },
                        [_c("v-spacer")],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            align: "center",
                            cols: "12",
                            sm: "8",
                            md: "8",
                            lg: "8",
                            xl: "8",
                          },
                        },
                        [
                          _c("div", { staticClass: "text-h5" }, [
                            _vm._v("Edit User Profile"),
                          ]),
                        ]
                      ),
                      _c(
                        "v-col",
                        { staticClass: "hidden-xs-and-down" },
                        [_c("v-spacer")],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "hidden-xs-and-down" },
                        [_c("v-spacer")],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            cols: "12",
                            sm: "8",
                            md: "8",
                            lg: "8",
                            xl: "8",
                          },
                        },
                        [
                          _c("v-text-field", {
                            attrs: { label: "Display Name", outlined: "" },
                            model: {
                              value: _vm.editedUser.full_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.editedUser, "full_name", $$v)
                              },
                              expression: "editedUser.full_name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "hidden-xs-and-down" },
                        [_c("v-spacer")],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { align: "right" } },
                        [
                          _vm.initLoading
                            ? _c("v-progress-circular", {
                                staticClass: "mt-3",
                                attrs: { indeterminate: "", color: "primary" },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            cols: "12",
                            sm: "8",
                            md: "8",
                            lg: "8",
                            xl: "8",
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.companies,
                              "item-text": "name",
                              label: "Employer Company",
                              outlined: "",
                              "return-object": "",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.selectId($event, "employer")
                              },
                            },
                            model: {
                              value: _vm.editedUser.employer_company,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.editedUser,
                                  "employer_company",
                                  $$v
                                )
                              },
                              expression: "editedUser.employer_company",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "hidden-xs-and-down" },
                        [_c("v-spacer")],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { align: "center", justify: "center" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { align: "right" } },
                        [
                          _vm.initLoading
                            ? _c("v-progress-circular", {
                                attrs: { indeterminate: "", color: "primary" },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            cols: "12",
                            sm: "8",
                            md: "8",
                            lg: "8",
                            xl: "8",
                          },
                        },
                        [
                          _c("v-checkbox", {
                            staticClass: "no-margin",
                            attrs: {
                              label: "Has All Company Access",
                              color: "primary",
                              "hide-details": "",
                            },
                            model: {
                              value: _vm.editedUser.has_all_company_access,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.editedUser,
                                  "has_all_company_access",
                                  $$v
                                )
                              },
                              expression: "editedUser.has_all_company_access",
                            },
                          }),
                          _c("v-select", {
                            attrs: {
                              disabled: _vm.editedUser.has_all_company_access,
                              items: _vm.companies,
                              "item-text": "name",
                              multiple: "",
                              label: "Company Access",
                              outlined: "",
                              "return-object": "",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.selectId($event, "company")
                              },
                            },
                            model: {
                              value: _vm.editedUser.company_id_list,
                              callback: function ($$v) {
                                _vm.$set(_vm.editedUser, "company_id_list", $$v)
                              },
                              expression: "editedUser.company_id_list",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "hidden-xs-and-down" },
                        [_c("v-spacer")],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { align: "right" } },
                        [
                          _vm.initLoading
                            ? _c("v-progress-circular", {
                                staticClass: "mt-3",
                                attrs: { indeterminate: "", color: "primary" },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            cols: "12",
                            sm: "8",
                            md: "8",
                            lg: "8",
                            xl: "8",
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.groups,
                              "item-text": "displayName",
                              label: "Role",
                              outlined: "",
                              "return-object": "",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.selectId($event, "groups")
                              },
                            },
                            model: {
                              value: _vm.editedUser.role_group,
                              callback: function ($$v) {
                                _vm.$set(_vm.editedUser, "role_group", $$v)
                              },
                              expression: "editedUser.role_group",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "hidden-xs-and-down" },
                        [_c("v-spacer")],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "hidden-xs-and-down" },
                        [_c("v-spacer")],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            align: "center",
                            cols: "12",
                            sm: "4",
                            md: "4",
                            lg: "4",
                            xl: "4",
                          },
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              on: {
                                click: function ($event) {
                                  _vm.openDialog = false
                                },
                              },
                            },
                            [_vm._v("Cancel")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            align: "center",
                            cols: "12",
                            sm: "4",
                            md: "4",
                            lg: "4",
                            xl: "4",
                          },
                        },
                        [
                          _c("v-btn", { on: { click: _vm.updateUser } }, [
                            _vm._v("Update"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "hidden-xs-and-down" },
                        [_c("v-spacer")],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }