var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("v-row"),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "v-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "v-row",
                    {
                      staticStyle: {
                        "background-color": "#272727",
                        "border-radius": "5px",
                      },
                    },
                    _vm._l(_vm.icons, function (item, index) {
                      return _c(
                        "v-col",
                        { key: index },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { right: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c(
                                          "td",
                                          { staticClass: "mx-0 px-0" },
                                          [
                                            item.icon
                                              ? _c("icon-loader", {
                                                  staticClass: "px-0 mx-0",
                                                  attrs: {
                                                    "icon-show": item.icon,
                                                    on: on,
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [_c("span", [_vm._v(_vm._s(item.tooltip))])]
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "padding" },
        [
          _c(
            "v-col",
            {
              attrs: {
                cols: "6",
                sm: "4",
                md: "4",
                lg: "3",
                xl: "3",
                align: "left",
              },
            },
            [
              _c("span", { staticClass: "secondary-text" }, [
                _vm._v("Truck Name"),
              ]),
              _c("br"),
              _c("b", [_vm._v(_vm._s(_vm.erxData.number))]),
            ]
          ),
          _c(
            "v-col",
            {
              attrs: {
                cols: "6",
                sm: "4",
                md: "4",
                lg: "3",
                xl: "3",
                align: "left",
              },
            },
            [
              _c("span", { staticClass: "secondary-text" }, [
                _vm._v("Current Odometer"),
              ]),
              _c("br"),
              _c("b", [
                _vm._v(
                  _vm._s(_vm.erxData.odometer) +
                    " " +
                    _vm._s(_vm.displayDistanceUnits())
                ),
              ]),
            ]
          ),
          _c(
            "v-col",
            {
              attrs: {
                cols: "6",
                sm: "4",
                md: "4",
                lg: "3",
                xl: "3",
                align: "left",
              },
            },
            [
              _c("span", { staticClass: "secondary-text" }, [
                _vm._v("Engine Hours"),
              ]),
              _c("br"),
              _c("b", [_vm._v(_vm._s(_vm.erxData.engtotalhoursofoperation))]),
            ]
          ),
          _c(
            "v-col",
            {
              attrs: {
                cols: "6",
                sm: "4",
                md: "4",
                lg: "3",
                xl: "3",
                align: "left",
              },
            },
            [
              _c("span", { staticClass: "secondary-text" }, [
                _vm._v("Current Speed"),
              ]),
              _c("br"),
              _c("b", [
                _vm._v(
                  _vm._s(_vm.erxData.current_speed) +
                    " " +
                    _vm._s(_vm.displaySpeedUnits())
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "padding" },
        [
          _c(
            "v-col",
            {
              attrs: {
                cols: "6",
                sm: "4",
                md: "4",
                lg: "3",
                xl: "3",
                align: "left",
              },
            },
            [
              _c("span", { staticClass: "secondary-text" }, [
                _vm._v("Company"),
              ]),
              _c("br"),
              _c("b", [_vm._v(_vm._s(_vm.erxData.company))]),
            ]
          ),
          _c(
            "v-col",
            {
              attrs: {
                cols: "6",
                sm: "4",
                md: "4",
                lg: "3",
                xl: "3",
                align: "left",
              },
            },
            [
              _c("span", { staticClass: "secondary-text" }, [
                _vm._v("Ignition State"),
              ]),
              _c("br"),
              _c("b", [_vm._v(_vm._s(_vm.erxData.ignition_state))]),
            ]
          ),
          _c(
            "v-col",
            {
              attrs: {
                cols: "6",
                sm: "4",
                md: "4",
                lg: "3",
                xl: "3",
                align: "left",
              },
            },
            [
              _c("span", { staticClass: "secondary-text" }, [
                _vm._v("Fuel Level"),
              ]),
              _c("br"),
              _c("b", [_vm._v(_vm._s(`${_vm.erxData.fuel_lvl} %` || "—"))]),
            ]
          ),
          _c(
            "v-col",
            {
              attrs: {
                cols: "6",
                sm: "4",
                md: "4",
                lg: "3",
                xl: "3",
                align: "left",
              },
            },
            [
              _c("span", { staticClass: "secondary-text" }, [
                _vm._v("Current Weight"),
              ]),
              _c("br"),
              _c("b", [
                _vm._v(
                  _vm._s(_vm.erxData.weight) +
                    " " +
                    _vm._s(_vm.displayWeightUnits())
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "padding" },
        [
          _c(
            "v-col",
            {
              attrs: {
                cols: "6",
                sm: "4",
                md: "4",
                lg: "3",
                xl: "3",
                align: "left",
              },
            },
            [
              _c("span", { staticClass: "secondary-text" }, [_vm._v("VIN")]),
              _vm._v(" "),
              _c("br"),
              _c("b", [_vm._v(_vm._s(_vm.erxData.vin))]),
            ]
          ),
          _c(
            "v-col",
            {
              attrs: {
                cols: "6",
                sm: "4",
                md: "4",
                lg: "3",
                xl: "3",
                align: "left",
              },
            },
            [
              _c("span", { staticClass: "secondary-text" }, [
                _vm._v("Position"),
              ]),
              _c("br"),
              _c("b", [_vm._v(_vm._s(_vm.erxData.position))]),
            ]
          ),
          _c(
            "v-col",
            {
              attrs: {
                cols: "6",
                sm: "4",
                md: "4",
                lg: "3",
                xl: "3",
                align: "left",
              },
            },
            [
              _c("span", { staticClass: "secondary-text" }, [
                _vm._v("EV Range"),
              ]),
              _c("br"),
              _c("b", [
                _vm._v(
                  _vm._s(_vm.erxData.ev_range) +
                    " " +
                    _vm._s(_vm.displayDistanceUnits())
                ),
              ]),
            ]
          ),
          _c(
            "v-col",
            {
              attrs: {
                cols: "6",
                sm: "4",
                md: "4",
                lg: "3",
                xl: "3",
                align: "left",
              },
            },
            [
              _c("span", { staticClass: "secondary-text" }, [
                _vm._v("Plugged In"),
              ]),
              _c("br"),
              _c("b", [_vm._v(_vm._s(_vm.erxData.plugged_in))]),
            ]
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "padding" },
        [
          _c(
            "v-col",
            {
              attrs: {
                cols: "6",
                sm: "4",
                md: "4",
                lg: "3",
                xl: "3",
                align: "left",
              },
            },
            [
              _c("span", { staticClass: "secondary-text" }, [
                _vm._v("Fuel Type"),
              ]),
              _vm._v(" "),
              _c("br"),
              _c("b", [_vm._v(_vm._s(_vm.erxData.fuel_type))]),
            ]
          ),
          _c(
            "v-col",
            {
              attrs: {
                cols: "6",
                sm: "4",
                md: "4",
                lg: "3",
                xl: "3",
                align: "left",
              },
            },
            [
              _c("span", { staticClass: "secondary-text" }, [
                _vm._v("Current Address"),
              ]),
              _c("br"),
              _c("b", [_vm._v(_vm._s(_vm.erxData.address || "—"))]),
            ]
          ),
          _c(
            "v-col",
            {
              attrs: {
                cols: "6",
                sm: "4",
                md: "4",
                lg: "3",
                xl: "3",
                align: "left",
              },
            },
            [
              _c("span", { staticClass: "secondary-text" }, [
                _vm._v("Fuel Range"),
              ]),
              _c("br"),
              _c("b", [
                _vm._v(
                  _vm._s(_vm.erxData.fuel_range) +
                    " " +
                    _vm._s(_vm.displayDistanceUnits())
                ),
              ]),
            ]
          ),
          _c(
            "v-col",
            {
              attrs: {
                cols: "6",
                sm: "4",
                md: "4",
                lg: "3",
                xl: "3",
                align: "left",
              },
            },
            [
              _c("span", { staticClass: "secondary-text" }, [
                _vm._v("Retarder Level"),
              ]),
              _c("br"),
              _c("b", [_vm._v(_vm._s(_vm.erxData.retarder_level))]),
            ]
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "padding" },
        [
          _c(
            "v-col",
            {
              attrs: {
                cols: "6",
                sm: "4",
                md: "4",
                lg: "3",
                xl: "3",
                align: "left",
              },
            },
            [
              _c("span", { staticClass: "secondary-text" }, [
                _vm._v("Truck Type"),
              ]),
              _vm._v(" "),
              _c("br"),
              _c("b", [_vm._v(_vm._s(_vm.erxData.truck_type))]),
            ]
          ),
          _c(
            "v-col",
            {
              attrs: {
                cols: "6",
                sm: "4",
                md: "4",
                lg: "3",
                xl: "3",
                align: "left",
              },
            },
            [
              _c("span", { staticClass: "secondary-text" }, [
                _vm._v("Hotel Hours"),
              ]),
              _c("br"),
              _c("b", [_vm._v(_vm._s(_vm.erxData.hotel_hours) + " Hours")]),
            ]
          ),
          _c(
            "v-col",
            {
              attrs: {
                cols: "6",
                sm: "4",
                md: "4",
                lg: "3",
                xl: "3",
                align: "left",
              },
            },
            [
              _c("span", { staticClass: "secondary-text" }, [
                _vm._v("Cruise Control Active"),
              ]),
              _c("br"),
              _c("b", [_vm._v(_vm._s(_vm.erxData.cruisectrlactive))]),
            ]
          ),
          _c(
            "v-col",
            {
              attrs: {
                cols: "6",
                sm: "4",
                md: "4",
                lg: "3",
                xl: "3",
                align: "left",
              },
            },
            [
              _c("span", { staticClass: "secondary-text" }, [
                _vm._v("Cruise Control Speed"),
              ]),
              _c("br"),
              _c("b", [
                _vm._v(
                  _vm._s(_vm.erxData.cruisectrlsetspeed) +
                    " " +
                    _vm._s(_vm.displaySpeedUnits())
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "padding" },
        [
          _c(
            "v-col",
            {
              attrs: {
                cols: "6",
                sm: "4",
                md: "4",
                lg: "3",
                xl: "3",
                align: "left",
              },
            },
            [
              _c("span", { staticClass: "secondary-text" }, [
                _vm._v("Current SOC"),
              ]),
              _c("br"),
              _c("b", [_vm._v(_vm._s(`${_vm.erxData.SOC} %`))]),
            ]
          ),
          _c("v-col", {
            attrs: {
              cols: "6",
              sm: "4",
              md: "4",
              lg: "3",
              xl: "3",
              align: "left",
            },
          }),
          _c("v-col", {
            attrs: {
              cols: "6",
              sm: "4",
              md: "4",
              lg: "3",
              xl: "3",
              align: "left",
            },
          }),
          _c("v-col", {
            attrs: {
              cols: "6",
              sm: "4",
              md: "4",
              lg: "3",
              xl: "3",
              align: "left",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }