var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "600" },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v("Load Study")]),
          _c("v-card-text", [_vm._v("Choose template for a study")]),
          _c("v-divider"),
          _c(
            "v-form",
            {
              ref: "form",
              model: {
                value: _vm.formValid,
                callback: function ($$v) {
                  _vm.formValid = $$v
                },
                expression: "formValid",
              },
            },
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "formLabel", attrs: { sm: "3" } },
                        [_vm._v(" Templates: ")]
                      ),
                      _c(
                        "v-col",
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.templates,
                              "return-object": "",
                              "item-text": "name",
                              label: "Select Template",
                              rules: [_vm.rules.required],
                              outlined: "",
                            },
                            model: {
                              value: _vm.template,
                              callback: function ($$v) {
                                _vm.template = $$v
                              },
                              expression: "template",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.template
                    ? _c(
                        "v-row",
                        { staticStyle: { "margin-bottom": "10px" } },
                        [
                          _c(
                            "v-col",
                            {
                              staticStyle: { "text-align": "center" },
                              attrs: { sm: "3" },
                            },
                            [_vm._v(" Description: ")]
                          ),
                          _c("v-col", [
                            _c("div", [
                              _vm._v(
                                " " + _vm._s(_vm.template.description) + " "
                              ),
                            ]),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c("v-btn", { on: { click: () => (_vm.show = false) } }, [
                    _vm._v(" Cancel "),
                  ]),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: { click: _vm.loadStudy },
                    },
                    [_vm._v(" Load Study ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }