export default function routeBasePath({ next, store }) {
  if (store.state.user.roles.includes('route.dashboard')) {
    return next({
      name: 'Dashboard',
    });
  } else if (
    store.state.user.roles.includes('external_permissions.performance')
  ) {
    return next({
      name: 'ExternalPerformance',
    });
  }
  // TODO: Create Not Found/404 Page when user does not have any permissions required
}
