var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-data-table", {
    staticClass: "dark-background",
    attrs: {
      "no-data-text": _vm.noDataMsg,
      headers: _vm.labels,
      "item-key": "truckNumber",
      items: _vm.trucks,
      "show-expand": "",
      expanded: _vm.expanded,
    },
    on: {
      "update:expanded": function ($event) {
        _vm.expanded = $event
      },
      "click:row": (item, slot) => slot.expand(!slot.isExpanded),
    },
    scopedSlots: _vm._u(
      [
        {
          key: "expanded-item",
          fn: function ({ item }) {
            return [
              _c(
                "td",
                {
                  staticStyle: { padding: "0" },
                  attrs: { colspan: _vm.labels.length + 1 },
                },
                [
                  _c("v-data-table", {
                    staticClass: "elevation-1 shade",
                    attrs: {
                      headers: _vm.expandedLabels,
                      items: item.truckDtcData,
                      "hide-default-footer": true,
                      "items-per-page": -1,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "top",
                          fn: function () {
                            return [
                              _c(
                                "v-toolbar",
                                { attrs: { flat: "" } },
                                [
                                  _c("v-toolbar-title", [_vm._v("Details")]),
                                  _c("v-spacer"),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: `item.indicator`,
                          fn: function ({ item }) {
                            return [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { right: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on }) {
                                          return [
                                            _c(
                                              "td",
                                              {
                                                staticClass:
                                                  "mx-0 px-0 d-flex justify-center",
                                              },
                                              [
                                                item.indicator
                                                  ? _c("icon-loader", {
                                                      staticClass: "px-0 mx-0",
                                                      attrs: {
                                                        "icon-show":
                                                          item.indicator,
                                                        on: on,
                                                      },
                                                    })
                                                  : _vm._e(),
                                                item.secondaryIndicator
                                                  ? _c("icon-loader", {
                                                      staticClass: "px-0 mx-0",
                                                      attrs: {
                                                        "icon-show":
                                                          item.secondaryIndicator,
                                                        on: on,
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [_c("span", [_vm._v(_vm._s(item.tooltip))])]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ],
                1
              ),
            ]
          },
        },
        {
          key: `item.status`,
          fn: function ({ item }) {
            return [
              _c(
                "v-tooltip",
                {
                  attrs: { left: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on }) {
                          return [
                            _c(
                              "td",
                              {
                                style: _vm.severityColor(
                                  item.faultSeverity,
                                  "#F2B046"
                                ),
                              },
                              [
                                _c(
                                  "v-icon",
                                  _vm._g(
                                    {
                                      style: _vm.severityColor(
                                        item.faultSeverity,
                                        "#F2B046"
                                      ),
                                      attrs: { large: "" },
                                    },
                                    on
                                  ),
                                  [_vm._v(_vm._s(_vm.getIcon(item.status)))]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                },
                [_c("span", [_vm._v(_vm._s(item.status))])]
              ),
            ]
          },
        },
        {
          key: `item.closestServiceLocation`,
          fn: function ({ item }) {
            return [
              _c("td", [
                _c("p", { staticClass: "mb-0 mt-1" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        item.miles >= 0 ? item.closestServiceLocation : "—"
                      ) +
                      " "
                  ),
                ]),
                _c("p", { staticStyle: { color: "#888888", margin: "0" } }, [
                  _vm._v(
                    " " +
                      _vm._s(item.miles >= 0 ? item.miles + " miles" : "") +
                      " "
                  ),
                ]),
              ]),
            ]
          },
        },
        {
          key: `item.faultSeverity`,
          fn: function ({ item }) {
            return [
              _c(
                "td",
                { style: _vm.severityColor(item.faultSeverity, "#FFFFFF") },
                [_vm._v(" " + _vm._s(item.faultSeverity) + " ")]
              ),
            ]
          },
        },
        {
          key: `item.actions`,
          fn: function ({ item }) {
            return [
              _c(
                "td",
                {
                  staticStyle: { color: "#43b02a", cursor: "pointer" },
                  on: { click: () => _vm.viewTruck(item) },
                },
                [_vm._v(" VIEW ")]
              ),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }