import axios from 'axios';

const BASE_URL = process.env.VUE_APP_API_PATH_BASE;

async function getAllConfigurations() {
  return await axios.get(BASE_URL + 'configurations/all_data/');
}

//DEPRECATED
async function getTruckTypes() {
  return await axios.get(BASE_URL + 'registration/truck_types/');
}
//DEPRECATED
async function getEXPackages() {
  return await axios.get(BASE_URL + 'ex_packages/');
}

async function addNewConfiguration(config) {
  return await axios.post(BASE_URL + 'configurations/', config);
}
async function uploadNewPackage(ERXPackageData) {
  return await axios.post(
    BASE_URL + 'configurations/erx-binaries',
    ERXPackageData,
    {
      headers: {
        'Content-Type': 'multipart/form-data; boundary=${form._boundary}',
      },
    }
  );
}

async function updateConfigurationStatus(config) {
  return await axios.patch(BASE_URL + 'configurations/status', config);
}

async function assignTrucksToConfiguration(config) {
  return await axios.patch(BASE_URL + 'configurations/assign', config);
}

export {
  getAllConfigurations,
  // getTruckTypes,
  // getEXPackages,
  addNewConfiguration,
  uploadNewPackage,
  updateConfigurationStatus,
  assignTrucksToConfiguration,
};
