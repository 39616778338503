<template>
  <div id="truck">
    <v-container fluid>
      <v-row>
        <v-col cols="12" xl="3" lg="3">
          <v-breadcrumbs
            style="padding: 5px 0 0 5px"
            :items="addCrumb"
            divider="/"
          ></v-breadcrumbs>
        </v-col>
        <v-col><v-spacer /></v-col>
      </v-row>
      <v-row class="mt-0 mb-6">
        <v-col cols="12" md="12" lg="12" xl="12">
          <v-expansion-panels v-model="panelIndex" class="border">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <v-row>
                  <v-col>
                    <h2>Truck Details</h2>
                    <br />
                    <span
                      v-if="panelIndex === undefined"
                      class="secondary-text"
                    >
                      Expand to view specific truck details</span
                    >
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <erx-details
                  v-if="!isEx && truckData && !loading"
                  :erx-data="truckData"
                  :icons="icons"
                />
                <ex-details v-else-if="isEx && !loading" :ex-data="truckData" />
                <template v-else-if="loading">
                  <v-skeleton-loader
                    type="card"
                    elevation="2"
                  ></v-skeleton-loader>
                </template>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { getTimeDelta } from '@/utilities/dateFunctions';
import {
  getHealthTrucks,
  getADXHealthData,
  getADXHealthERXData,
  getADXLastAhead,
  getADXLocations,
  getERXDTCs,
} from '@/api/trucks';
import { getSpnfmiStates } from '@/api/dtc';
import Health from '../health';
import ERXDetails from './ERXDetails';
import EXDetails from './EXDetails';
import { regionalSetting } from '@/utilities/userSettings';
import { erxDTCLookup } from '@/utilities/health';

import _ from 'lodash';
export default {
  name: 'TruckDetails',
  components: {
    'erx-details': ERXDetails,
    'ex-details': EXDetails,
  },
  data() {
    return {
      panelIndex: 0, // default to open until we have content below (undefined = closed)
      truck: null,
      company: null,
      truckData: {},
      truckName: null,
      isEx: null,
      fromHealth: false,
      dtcs: null,
      loading: true,
      icons: [],
    };
  },
  computed: {
    ...mapGetters({
      userRoles: 'getUserRoles',
      userPreferences: 'getUserPreferences',
      spnfmiStates: 'getSpnfmiStates',
      getSelectedTruck: 'getTruck',
    }),
    addCrumb: function () {
      return [
        {
          text: 'Health',
          disabled: false,
          exact: true,
          link: true,
          to: {
            name: 'Health',
            params: {
              paramName: Health,
            },
          },
        },
        {
          text: `${this.truckName || ''}`,
          disabled: true,
          href: `/health/${this.truckName || ''}`,
        },
      ];
    },
    icon() {
      if (this.allSelected) return 'mdi-close-box';
      else return 'mdi-checkbox-blank-outline';
    },
  },
  async created() {
    this.truck = parseInt(this.$route.params.id);
    if (!this.spnfmiStates.length) {
      const { data } = await getSpnfmiStates();
      await this.asyncUpdateSpnfmiStates(data);
    }
  },
  async mounted() {
    if (isNaN(this.$route.params.id)) {
      this.$router.push(`/health`); //If the route param is not a number, it will redirect user back to health page
    } else {
      await this.getTruckData();
    }
  },
  methods: {
    ...mapMutations(['assignHealthTruck']),
    ...mapActions(['asyncUpdateSpnfmiStates']),
    checkObjectData(info) {
      let formattedInfo = {};
      for (const key in info) {
        if (Object.hasOwnProperty.call(info, key)) {
          formattedInfo[key] = info[key] || info[key] === 0 ? info[key] : '—';
        }
      }
      return formattedInfo;
    },
    async getTruckData() {
      if (this.getSelectedTruck) {
        // From Health Page
        this.truckData = { ...this.getSelectedTruck };
        this.truckName = this.truckData.number;
        this.fromHealth = true;
      } else {
        // Re-Loaded
        let { data } = await getHealthTrucks(this.truck);
        this.truckData = data[0];
        this.truckName = data[0].number;
        this.truckData.number = data[0].number;
        this.company = this.truckData.company_id;
      }
      if (_.isEmpty(this.truckData)) {
        //If the truck has no data, user will be redirected to the health page
        //Add Error?
        this.$router.push(`/health`);
      }
      // For Both EX and ERX Trucks
      this.truckData.fuel_type = this.truckData.fuel_type.toUpperCase();
      this.truckData.address = this.truckData.address
        ? this.truckData.address
        : '—';
      this.truckData.odometer = this.truckData.odometer
        ? this.truckData.odometer
        : '—';

      if (this.truckData.truck_type.includes('ERX')) {
        this.isEx = false;
        if (!this.fromHealth) {
          let res1, res2, res3;
          await Promise.allSettled([
            getADXLocations(this.truck),
            getERXDTCs(),
            getADXHealthERXData(this.truck, this.company),
          ]).then((results) => {
            results[0].status === 'fulfilled'
              ? (res1 = results[0].value?.data[0])
              : console.log(results[0].reason);
            results[1].status === 'fulfilled'
              ? (res2 = results[1].value?.data)
              : console.log(results[1].reason);
            results[2].status === 'fulfilled'
              ? (res3 = results[2].value?.data[0])
              : console.log(results[2].reason);
          });
          this.formatERXData(res1, res2 || [], res3 || {});
        } else {
          this.icons = this.truckData.icons || [];
        }
      } else if (this.truckData.truck_type.includes('EX')) {
        this.isEx = true;
        if (!this.fromHealth) {
          let res1, res2, res3;
          await Promise.allSettled([
            getADXHealthData(this.truck),
            getADXLastAhead(this.truck),
            getADXLocations(this.truck),
          ]).then((results) => {
            results[0].status === 'fulfilled'
              ? (res1 = results[0].value?.data[0])
              : console.log(results[0].reason);
            results[1].status === 'fulfilled'
              ? (res2 = results[1].value?.data)
              : console.log(results[1].reason);
            results[2].status === 'fulfilled'
              ? (res3 = results[2].value?.data[0])
              : console.log(results[2].reason);
          });
          this.formatEXData(res1, res2 || [], res3 || {});
        }
      } else {
        this.$router.push(`/health`);
      }
      this.loading = false;
    },
    mapDtcDetails(dtc) {
      let { dtcs, icons } = erxDTCLookup(
        dtc.length ? dtc : [],
        this.spnfmiStates
      );
      this.icons = icons || [];
      return dtcs.length ? dtcs : [];
    },
    formatERXData(adxLocation, dtc, erxHealth) {
      let truck = { ...this.truckData };
      let dtcs = dtc.filter((t) => t.truck_id == this.$route.params.id);
      truck.active_dtcs = this.mapDtcDetails(dtcs);
      truck.position =
        adxLocation && adxLocation.latitude && adxLocation.longitude
          ? `${adxLocation.latitude}, ${adxLocation.longitude}`
          : '—';
      truck.odometer = regionalSetting(
        this.userPreferences.regionPref,
        erxHealth?.odometer,
        'odometer',
        true
      );
      truck.SOC = erxHealth.SOC || '—';
      truck.current_speed = erxHealth?.current_speed || '—';
      truck.fuel_lvl = erxHealth?.fuel_lvl || '—';
      truck.ev_range = regionalSetting(
        this.userPreferences.regionPref,
        erxHealth?.ev_range,
        'distance',
        true
      );

      truck.fuel_range = regionalSetting(
        this.userPreferences.regionPref,
        erxHealth?.fuel_range,
        'distance',
        true
      );
      truck.hotel_hours = erxHealth?.hotel_hours || '—';
      truck.weight = erxHealth?.weight || '—';
      if (erxHealth?.plugged_in !== null) {
        if (erxHealth?.plugged_in === 1) {
          truck.plugged_in = 'Yes';
        } else {
          truck.plugged_in = 'No';
        }
      } else {
        truck.plugged_in = '—';
      }
      truck.retarder_level =
        typeof erxHealth?.retarder_level === 'number' &&
        erxHealth?.retarder_level !== null
          ? erxHealth.retarder_level
          : '—';

      if (erxHealth?.cruisectrlactive !== null) {
        if (erxHealth?.cruisectrlactive === 1) {
          truck.cruisectrlactive = 'Yes';
          truck.cruisectrlsetspeed = regionalSetting(
            this.userPreferences.regionPref,
            erxHealth?.cruisectrlsetspeed,
            'speed',
            true
          );
        } else {
          truck.cruisectrlactive = 'No';
          truck.cruisectrlsetspeed = '—';
        }
      } else {
        truck.cruisectrlactive = '—';
        truck.cruisectrlsetspeed = '—';
      }

      if (erxHealth?.ignition_state) {
        switch (erxHealth.ignition_state) {
          case 3:
            truck.ignition_state = 'Off';
            break;
          case 7:
            truck.ignition_state = 'Accesory';
            break;
          case 19:
            truck.ignition_state = 'Crank';
            break;
          case 23:
            truck.ignition_state = 'On';
            break;
          default:
            truck.ignition_state = '—';
            break;
        }
      } else {
        truck.ignition_state = '—';
      }

      truck.engtotalhoursofoperation = erxHealth?.engtotalhoursofoperation
        ? erxHealth.engtotalhoursofoperation
        : '—';

      this.truckData = { ...truck };
      this.assignHealthTruck(this.truckData);
    },
    formatEXData(adxHealth, adxTime, adxLocation) {
      let truck = { ...this.truckData };

      truck.position =
        adxLocation && adxLocation.latitude && adxLocation.longitude
          ? `${adxLocation.latitude}, ${adxLocation.longitude}`
          : '—';
      truck.serial_number = truck.serial_number ? truck.serial_number : '—';
      truck.APU = adxHealth?.APU ? 'On' : 'Off';
      truck.SOC =
        adxHealth.SOC || adxHealth.SOC === 0 ? `${adxHealth.SOC}%` : '—';
      truck.ignition =
        adxHealth.ignition || adxHealth.ignition === 0
          ? adxHealth.ignition
            ? 'On'
            : 'Off'
          : '—';
      truck.last_error_shut_down = adxHealth.last_error_shut_down_time
        ? getTimeDelta(adxHealth.last_error_shut_down_time)
        : '—';
      truck.odometer = regionalSetting(
        this.userPreferences.regionPref,
        adxHealth?.odometer,
        'odometer',
        true
      );
      truck.timeUpdated = adxHealth.one_min_time
        ? getTimeDelta(adxHealth.one_min_time)
        : '—';
      truck.ten_hz_time = adxHealth.ten_hz_time
        ? getTimeDelta(adxHealth.ten_hz_time)
        : '—';
      truck.engSpeed = adxHealth.EngSpeed ? adxHealth.EngSpeed : '—';
      truck.laTimeUpdated = adxTime?.la_timestamp
        ? getTimeDelta(adxTime.la_timestamp)
        : '—';
      truck.taTimeUpdated = adxTime?.ta_timestamp
        ? getTimeDelta(adxTime.ta_timestamp)
        : '—';
      truck.iotUpdate = adxHealth?.max_telemetry_time
        ? getTimeDelta(adxHealth.max_telemetry_time)
        : '—';
      truck.ignition = adxHealth ? (adxHealth?.ignition ? 'On' : 'Off') : '—';
      const overLoadedSpeed = (adxHealth) => {
        if (adxHealth?.APU) {
          return 'Hotel';
        } else if (!adxHealth?.ignition) {
          return 'Off';
        } else {
          return regionalSetting(
            this.userPreferences.regionPref,
            adxHealth.speed,
            'speed',
            true
          );
        }
      };
      truck.current_speed = adxHealth ? overLoadedSpeed(adxHealth) : '—';

      truck.load = adxHealth?.Load ? adxHealth.Load : '—';
      truck.engSpeed = adxHealth ? Math.round(adxHealth.EngSpeed) / 10 : '—';

      truck.generalTemp = regionalSetting(
        this.userPreferences.regionPref,
        adxHealth?.generalTemp,
        'temperature',
        true
      );
      truck.motorTemp = regionalSetting(
        this.userPreferences.regionPref,
        adxHealth?.motorTemp,
        'temperature',
        true
      );
      truck.compressorTemp = regionalSetting(
        this.userPreferences.regionPref,
        adxHealth?.compressorTemp,
        'temperature',
        true
      );
      truck.batteryMaxTemp = regionalSetting(
        this.userPreferences.regionPref,
        adxHealth?.batteryMaxTemp,
        'temperature',
        true
      );
      truck.batteryMinTemp = regionalSetting(
        this.userPreferences.regionPref,
        adxHealth?.batteryMinTemp,
        'temperature',
        true
      );
      this.truckData = { ...truck };
      this.assignHealthTruck(this.truckData);
    },
  },
};
</script>

<style scoped>
.padding {
  padding: 1rem;
}
.secondary-text {
  font-weight: 100;
  opacity: 0.7;
}
.border {
  border-bottom: 1px solid #e0e0e0;
}
</style>
