var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      staticClass: "dialog",
      attrs: { width: "460" },
      model: {
        value: _vm.openDialog,
        callback: function ($$v) {
          _vm.openDialog = $$v
        },
        expression: "openDialog",
      },
    },
    [
      _vm.loading ? _c("loader") : _vm._e(),
      _c(
        "v-card",
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                { staticClass: "pl-4", attrs: { align: "center" } },
                [
                  _c("v-col", { attrs: { align: "left", cols: "6" } }, [
                    _c("h3", [_vm._v("Change Truck Name")]),
                  ]),
                  _c("v-col", [_c("v-spacer")], 1),
                  _c(
                    "v-col",
                    { attrs: { align: "right", cols: "2" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function ($event) {
                              _vm.openDialog = false
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { light: "" } }, [
                            _vm._v("mdi-close-circle "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "ma-2" },
                [
                  _c(
                    "v-col",
                    { attrs: { align: "center", cols: "12" } },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "form",
                          attrs: { "lazy-validation": "" },
                          model: {
                            value: _vm.valid,
                            callback: function ($$v) {
                              _vm.valid = $$v
                            },
                            expression: "valid",
                          },
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              rules: [_vm.rules.required],
                              label: "Truck Name",
                              required: "",
                            },
                            model: {
                              value: _vm.truckName,
                              callback: function ($$v) {
                                _vm.truckName = $$v
                              },
                              expression: "truckName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "pt-0 hidden-xs-and-down" },
                    [_c("v-spacer")],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 mr-1",
                      attrs: { align: "center", cols: "3" },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { plain: "", color: "primary" },
                          on: {
                            click: function ($event) {
                              _vm.openDialog = false
                            },
                          },
                        },
                        [_vm._v("Cancel")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pt-0 mr-1",
                      attrs: { align: "center", cols: "3" },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticStyle: { color: "black" },
                          attrs: {
                            color: "primary",
                            disabled: !_vm.valid || _vm.loading,
                          },
                          on: { click: _vm.changeTruckName },
                        },
                        [_vm._v("Submit")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }