// CUSTOMER centric routes
import axios from 'axios';

const BASE_URL = process.env.VUE_APP_API_PATH_BASE;

async function getHealth(company_id) {
  const res = await axios.get(
    BASE_URL + `external/health?company_id=${company_id}`
  );
  return res.data;
}

async function getDTC(company_id) {
  const res = await axios.get(
    BASE_URL + `external/health/dtc?company_id=${company_id}`
  );
  return res.data;
}

async function getnearestSS(company_id) {
  const res = await axios.get(
    BASE_URL + `external/health/location_information?company_id=${company_id}`
  );
  return res.data;
}

async function getPerformanceBar(company_id) {
  const res = await axios.get(
    BASE_URL + `external/health/performanceBar/${company_id}`
  );
  return res.data;
}

async function getSpnfmiStates() {
  return await axios.get(BASE_URL + 'external/health/spnfmi_states');
}

async function getHereMapKey() {
  return await axios.get(BASE_URL + 'external/health/here_key');
}

export {
  getHealth,
  getDTC,
  getnearestSS,
  getPerformanceBar,
  getSpnfmiStates,
  getHereMapKey,
};
