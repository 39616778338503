var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { align: "center" } },
        [
          _c("v-col", { attrs: { cols: "10" } }, [
            _c("h1", { staticStyle: { "font-weight": "400" } }, [
              _vm._v("ERX Study Board"),
            ]),
          ]),
          _c(
            "v-col",
            { attrs: { align: "right" } },
            [
              _c(
                "v-btn",
                {
                  staticStyle: {
                    "background-color": "#1a1a1a",
                    "border-radius": "5px",
                  },
                  attrs: { icon: "", tile: "", large: "" },
                  on: {
                    click: function ($event) {
                      _vm.drawer = true
                    },
                  },
                },
                [
                  _c("v-icon", { staticStyle: { opacity: "0.6" } }, [
                    _vm._v("mdi-cog"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-col",
            { attrs: { align: "right" } },
            [
              _vm.selectedTrucks.length >= 1
                ? _c(
                    "v-btn",
                    {
                      staticClass: "mr-1",
                      staticStyle: { color: "black" },
                      attrs: { color: "#D8D8D8" },
                      on: { click: _vm.showExportAdx },
                    },
                    [
                      _c("v-icon", { attrs: { id: "csv2" } }, [
                        _vm._v("mdi-open-in-new"),
                      ]),
                      _vm._v(" Export ADX Data "),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary" },
                  on: { click: () => (_vm.showLoadStudy = true) },
                },
                [
                  _c("v-icon", { attrs: { id: "csv2" } }, [_vm._v("mdi-plus")]),
                  _vm._v("Load Study"),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card",
            { staticClass: "tabView" },
            [
              _c(
                "v-tabs",
                {
                  attrs: { "background-color": "secondary" },
                  model: {
                    value: _vm.tab,
                    callback: function ($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab",
                  },
                },
                [
                  _c("v-tab", [_vm._v(" Template Editor ")]),
                  _vm._l(_vm.studies, function (study) {
                    return _c(
                      "v-tab",
                      { key: study.id, attrs: { color: "white" } },
                      [
                        _vm._v(" " + _vm._s(study.name) + " "),
                        _c(
                          "v-icon",
                          {
                            staticStyle: {
                              "margin-left": "10px",
                              "margin-bottom": "-4px",
                            },
                            attrs: { medium: "" },
                            on: { click: () => _vm.closeTab(study) },
                          },
                          [_vm._v("mdi-close")]
                        ),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.tab,
                    callback: function ($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab",
                  },
                },
                [
                  _c(
                    "v-tab-item",
                    { key: 0 },
                    [
                      _c("TemplateEditor", {
                        attrs: {
                          triggers: _vm.triggers,
                          templates: _vm.templates,
                        },
                        on: { refresh: _vm.fetchTemplates },
                      }),
                    ],
                    1
                  ),
                  _vm._l(_vm.studies, function (study) {
                    return _c(
                      "v-tab-item",
                      {
                        key: study.id,
                        staticStyle: { "background-color": "#1a1a1a" },
                        attrs: { color: "white" },
                      },
                      [
                        _c("Study", {
                          attrs: {
                            template: study,
                            "truck-date": _vm.truckDate,
                            "in-view": study === _vm.studies[_vm.tab - 1],
                            "update-zoom": _vm.updateZoom,
                            "truck-names": _vm.truckNames,
                            "signal-lookup": _vm.signalLookup,
                          },
                        }),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-navigation-drawer",
        {
          staticClass: "shade",
          attrs: { right: "", temporary: "", fixed: "", width: 360 },
          model: {
            value: _vm.drawer,
            callback: function ($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer",
          },
        },
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                { attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "mb-2", attrs: { align: "right" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function ($event) {
                              _vm.drawer = false
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { light: "" } }, [
                            _vm._v("mdi-close-circle "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-expansion-panels",
                {
                  attrs: { multiple: "" },
                  model: {
                    value: _vm.panel,
                    callback: function ($$v) {
                      _vm.panel = $$v
                    },
                    expression: "panel",
                  },
                },
                [
                  _c(
                    "v-expansion-panel",
                    { staticClass: "shade" },
                    [
                      _c("v-expansion-panel-header", [
                        _vm._v("Date/Truck Selection"),
                      ]),
                      _c(
                        "v-expansion-panel-content",
                        [
                          _c("v-datetime-picker", {
                            attrs: { label: "Start Date" },
                            model: {
                              value: _vm.startDate,
                              callback: function ($$v) {
                                _vm.startDate = $$v
                              },
                              expression: "startDate",
                            },
                          }),
                          _c("div", { staticClass: "zoomDate" }, [
                            _vm._v("Zoomed Start: " + _vm._s(_vm.zoomStart)),
                          ]),
                          _c("v-datetime-picker", {
                            attrs: { label: "End Date" },
                            model: {
                              value: _vm.endDate,
                              callback: function ($$v) {
                                _vm.endDate = $$v
                              },
                              expression: "endDate",
                            },
                          }),
                          _c("div", { staticClass: "zoomDate" }, [
                            _vm._v("Zoomed End: " + _vm._s(_vm.zoomEnd)),
                          ]),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary", block: "" },
                              on: { click: _vm.applyZoom },
                            },
                            [_vm._v("Apply Zoomed Dates")]
                          ),
                          _c("br"),
                          _vm._v(" Select Trucks: "),
                          _c("v-autocomplete", {
                            staticClass: "truckSelect",
                            attrs: {
                              items: _vm.trucks,
                              outlined: "",
                              chips: "",
                              "deletable-chips": "",
                              "item-text": "name",
                              "item-value": "id",
                              multiple: "",
                            },
                            model: {
                              value: _vm.selectedTrucks,
                              callback: function ($$v) {
                                _vm.selectedTrucks = $$v
                              },
                              expression: "selectedTrucks",
                            },
                          }),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "block",
                                "margin-left": "auto",
                              },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticStyle: { "margin-right": "15px" },
                                  attrs: { disabled: _vm.updateDisabled },
                                  on: { click: _vm.cancelSelection },
                                },
                                [_vm._v("Cancel")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary",
                                    disabled: _vm.updateDisabled,
                                  },
                                  on: { click: _vm.updateTruckDate },
                                },
                                [_vm._v("Update")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-expansion-panel",
                    { staticClass: "shade" },
                    [
                      _c("v-expansion-panel-header", [_vm._v("Triggers")]),
                      _c(
                        "v-expansion-panel-content",
                        [
                          _vm.triggers.length
                            ? _c(
                                "div",
                                _vm._l(_vm.triggers, function (trigger) {
                                  return _c(
                                    "p",
                                    { key: trigger.id },
                                    [
                                      _vm._v(" " + _vm._s(trigger.name) + " "),
                                      _c(
                                        "v-btn",
                                        {
                                          staticStyle: {
                                            "background-color": "#070505",
                                            "border-radius": "5px",
                                            float: "right",
                                          },
                                          attrs: {
                                            icon: "",
                                            tile: "",
                                            small: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.callTriggerEdit(
                                                trigger.name
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { staticStyle: { opacity: "0.6" } },
                                            [_vm._v("mdi-pencil")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                }),
                                0
                              )
                            : _c(
                                "div",
                                { staticStyle: { "text-align": "center" } },
                                [_vm._v("No Current Triggers")]
                              ),
                          _c("v-divider", {
                            staticStyle: { margin: "5px 0 5px 0" },
                          }),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { align: "center" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      on: {
                                        click: function ($event) {
                                          _vm.showAddTrigger = true
                                        },
                                      },
                                    },
                                    [_vm._v(" Add New Trigger")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.showAddTrigger
        ? _c("AddTrigger", {
            on: {
              closed: () => (this.showAddTrigger = false),
              refresh: _vm.fetchTriggers,
            },
          })
        : _vm._e(),
      _vm.showEditTrigger
        ? _c("EditTrigger", {
            attrs: { "edit-trigger-select": _vm.editTriggerSelect },
            on: {
              closed: () => (this.showEditTrigger = false),
              refresh: _vm.fetchTriggers,
            },
          })
        : _vm._e(),
      _vm.showLoadStudy
        ? _c("LoadStudy", {
            attrs: { templates: _vm.templates, "add-study": _vm.addTab },
            on: { closed: () => (this.showLoadStudy = false) },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }