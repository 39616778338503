<template>
  <v-container>
    <v-dialog v-model="openDialog" persistent width="520">
      <loader v-if="loading" />
      <v-row align="center" class="py-2">
        <v-col align="center" cols="4">Add Package</v-col>
        <v-col><v-spacer /></v-col>
        <v-col align="right" cols="2">
          <v-btn icon @click="openDialog = false">
            <v-icon light>mdi-close-circle </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-divider />
      <v-stepper v-model="fStepper" alt-labels class="secondary">
        <v-stepper-header>
          <v-stepper-step step="1">DETAILS</v-stepper-step>
          <v-divider></v-divider>
          <v-divider></v-divider>
          <v-stepper-step step="2">REVIEW</v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col align="center" cols="12" class="pb-0">
                  <v-select
                    v-model="packageTruckType"
                    :items="truckTypes"
                    class="pt-1"
                    label="Truck Type"
                    outlined
                    :rules="[(v) => !!v || 'Required!']"
                    required
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="pt-0 pb-0">
                  <v-textarea
                    v-model="packageDescription"
                    label="Package Description"
                    outlined
                    :rules="[(v) => !!v || 'Required!']"
                    required
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-0 pb-0">
                  <v-file-input
                    type="file"
                    truncate-length="30"
                    label="Upload Package"
                    :rules="[(v) => !!v || 'Required!']"
                    required
                    @change="selectPackageFile"
                  />
                </v-col>
              </v-row>
            </v-form>
            <v-row>
              <v-col><v-spacer /></v-col>
              <v-col align="right" cols="3"
                ><v-btn plain color="primary" @click="openDialog = false"
                  >Cancel</v-btn
                ></v-col
              >
              <v-col align="left" cols="4"
                ><v-btn
                  color="primary"
                  style="color: black"
                  :disabled="!valid"
                  @click="checkForm()"
                  >Continue</v-btn
                ></v-col
              >
            </v-row>
          </v-stepper-content>
          <template>
            <v-stepper-content step="2">
              <div
                style="
                  overflow-y: scroll;
                  overflow-x: hidden;
                  height: 450px;
                  width: 100%;
                "
              >
                <v-row>
                  <v-col class="mt-1 text-h5"> Review Package<br /> </v-col>
                </v-row>
                <v-row>
                  <v-col class="text-subtitle-1"> Details </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <span class="opacity-title py-6">Package Name</span><br />
                    {{ fileName }}<br />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <span class="opacity-title py-6">Truck Type</span><br />
                    {{ packageTruckType }}<br />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <span class="opacity-title">Package Description</span><br />
                    {{ packageDescription }}<br />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <span class="opacity-title">Package Architecture</span
                    ><br />
                    {{ packageArchitecture }}<br />
                  </v-col>
                </v-row>
              </div>
              <v-row class="mt-1">
                <v-col align="left" cols="4"
                  ><v-btn plain outlined @click="fStepper = 1"
                    >Back</v-btn
                  ></v-col
                >
                <v-col><v-spacer /></v-col>
                <v-col align="right" cols="7"
                  ><v-btn plain color="primary" @click="openDialog = false"
                    >Cancel</v-btn
                  ><v-btn
                    color="primary"
                    style="color: black"
                    @click="submitPackage()"
                    >Add Package</v-btn
                  ></v-col
                >
              </v-row>
            </v-stepper-content>
          </template>
        </v-stepper-items>
      </v-stepper>
    </v-dialog>
  </v-container>
</template>

<script>
import { uploadNewPackage } from '@/api/configurations';
import loader from '@/utilities/loader';

import { mapActions } from 'vuex';
export default {
  name: 'AddPackage',
  components: { loader },
  data() {
    return {
      openDialog: false,
      fStepper: 1,
      // SAMPLE_B_ARCHITECTURE_NUMBER: '0.00',
      SAMPLE_C_ARCHITECTURE_NUMBER: '1.00',
      truckTypes: ['ERX-C'],
      packageTruckType: 'ERX-C',
      packageDescription: '',
      file: null,
      fileName: '',
      valid: false,
      loading: false,
    };
  },
  computed: {
    packageArchitecture() {
      if (this.packageTruckType === 'ERX-C')
        return this.SAMPLE_C_ARCHITECTURE_NUMBER;
      // if (this.packageTruckType === 'ERX-B')
      //   return this.SAMPLE_B_ARCHITECTURE_NUMBER;
      return this.SAMPLE_C_ARCHITECTURE_NUMBER;
    },
  },
  watch: {
    // Watches for the change in openDialog variable to emit the false variable back to parent to reset
    openDialog: function (newValue) {
      if (!newValue) {
        this.openDialog = false;
        this.$emit('closed', false);
      }
    },
  },
  async mounted() {
    this.openDialog = true;
  },
  methods: {
    ...mapActions({
      updateSnack: 'updateSnack',
    }),
    checkForm() {
      // Checks validation
      if (!this.$refs.form.validate()) {
        return;
      }
      this.fStepper = 2;
    },
    async submitPackage() {
      this.loading = true;
      try {
        let fileData = new FormData();
        fileData.set('file', this.file);
        fileData.set('truck_type', this.packageTruckType.toLowerCase());
        fileData.set('architecture', this.packageArchitecture);
        fileData.set('description', this.packageDescription);
        await uploadNewPackage(fileData);

        this.loading = false;
        this.openDialog = false;
        this.updateSnack({
          type: 'success',
          message: 'ERX Package Added Successfully!',
        });
        this.$emit('refresh');
      } catch (e) {
        this.loading = false;
        this.updateSnack({
          type: 'error',
          message:
            e.response.status === 409
              ? 'This package version already exists for this architecture.'
              : 'There was an issue creating the package, please try again!',
        });
      }
    },
    selectPackageFile(file) {
      this.file = file;
      this.fileName = file.name;
    },
  },
};
</script>
<style>
.opacity-title {
  opacity: 0.5;
}

.v-dialog--active {
  background-color: #1a1a1a;
}
.v-stepper__header {
  box-shadow: none !important;
}
</style>
