var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-list",
    _vm._l(_vm.categoryTestItems, function (item) {
      return _c(
        "v-list-group",
        {
          key: item.name,
          attrs: { "no-action": "" },
          scopedSlots: _vm._u(
            [
              {
                key: "activator",
                fn: function () {
                  return [
                    _c(
                      "v-list-item-content",
                      [
                        _c(
                          "v-list-item-title",
                          {
                            staticStyle: {
                              "font-weight": "bold",
                              "font-size": "1.2em",
                            },
                          },
                          [_vm._v(_vm._s(item.name))]
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            true
          ),
          model: {
            value: item.active,
            callback: function ($$v) {
              _vm.$set(item, "active", $$v)
            },
            expression: "item.active",
          },
        },
        [
          _c(
            "v-list-item",
            [
              _c(
                "v-list-item-content",
                [
                  _c("v-data-table", {
                    key: _vm.randomKey,
                    staticClass:
                      "elevation-1 secondary locked-table-style row-height-50",
                    attrs: {
                      headers: _vm.testHeaders,
                      items: item.tests,
                      search: _vm.searchText,
                      "item-key": "id",
                    },
                    on: { "click:row": _vm.loadTestResults },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }