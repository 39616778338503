import dateFormat from 'dateformat';
import { getTimeDelta } from './dateFunctions';
import { sourceAddresses } from './constants.js';
import _ from 'lodash';
export const getIcon = (status) => {
  if (status === 'Stopped') {
    return 'mdi-truck';
  } else if (status === 'Moving') {
    return 'mdi-truck-fast';
  } else if (status === 'Accessory / Hoteling') {
    return 'mdi-bed-clock';
  }
};

export const severityColor = (status, defaultColor = '#43B02A') => {
  let code = '';
  switch (status) {
    case 'Service':
    case 'Non-Hyliion Faults': // Have non-hyliion alerts show up as Amber by default
      code = 'color: #F2B046'; // Amber
      break;
    case 'Critical':
      code = 'color: #EF5350'; // Red
      break;
    default:
      code = `color: ${defaultColor}`;
  }
  return code;
};

export const filterFaults = (dtcs, spnfmiStates) => {
  return dtcs
    .map((dtc) => {
      let spnfmi = `${dtc.spn}-${dtc.fmi}`;
      // Filter out DTCS that have a specific Non-Hyliion spnfmi fault
      if (spnfmi === '520197-9' || spnfmi === '639-2') {
        return {};
      }
      let data = spnfmiStates.find((s) => s.spnfmi === spnfmi);
      if (data) {
        // Filter out DTCs where show to customer is false
        if (data?.show_to_customer === false) {
          return {};
        }
      } else {
        let source =
          dtc.source !== null
            ? dtc?.source.toString(16).toUpperCase() // Converts to HEX
            : null;
        if (source !== null) {
          let knownSource = sourceAddresses.find((f) => f.source == source);
          // Filters out DTCs where source Address display is false
          if (knownSource.display === false) {
            return {};
          }
        }
      }
      return dtc;
    })
    .filter((d) => !_.isEmpty(d));
};

export const mapTrucks = (trucks, timeZone, spnfmiStates) => {
  return trucks.map((ele) => {
    let truckDtcData = [];
    let faultSeverity = '';
    if (ele.checkDtc) {
      ele.checkDtc.forEach((dtc) => {
        let data = spnfmiStates.filter(
          (s) => s.spnfmi === `${dtc.spn}-${dtc.fmi}`
        );
        const timestamp = new Date(dtc.timestamp);
        timestamp.toLocaleString('en-US', { timeZone });
        dtc.timestamp = dateFormat(timestamp, 'mm/dd/yy HH:MM:ss Z');
        dtc.indicator_data = data[0] || null;
        // no matching spnfmi means non-Hyliion alert
        if (data.length) {
          dtc.name = data[0]?.dtc_name || '—';
          dtc.description = data[0]?.description || '—';
          dtc.source = 'Hyliion Powertrain';
          // any critical fault takes precedent for faultSeverity
          if (faultSeverity != 'Critical')
            faultSeverity = data[0]?.severity || '—';
        } else {
          let source =
            dtc.source !== null
              ? dtc?.source.toString(16).toUpperCase() // Converts to HEX
              : null;
          // Checks if DTC came from a known source
          if (source !== null) {
            let knownSource = sourceAddresses.find((f) => f.source == source);
            let component = knownSource?.component || 'Unknown';
            dtc.description = `Fault Present from ${component}`;
            dtc.source = component;
          } else {
            dtc.description = 'Unknown';
            dtc.source = 'Unknown';
          }
          dtc.name = 'Non-Hyliion Fault';
        }
        // Optional icon indicator
        const tooltip =
          dtc.indicator_data?.primary_indicator?.split('-')[0] || null;
        const dtcRow = {
          tooltip,
          indicator: dtc.indicator_data?.primary_icon_name,
          secondaryIndicator: dtc.indicator_data?.secondary_icon_name,
          spnFmi: `${dtc.spn}-${dtc.fmi}`,
          alertName: dtc.name,
          alertDescription: dtc.description,
          alertSource: dtc.source,
          lastTriggered: dtc.timestamp,
        };
        // add known faults at start of array, unknown faults at the end
        if (data.length) {
          truckDtcData.unshift(dtcRow);
        } else {
          truckDtcData.push(dtcRow);
        }
      });
      faultSeverity = faultSeverity || 'Non-Hyliion Faults';
    }
    let locationInfo = ele?.locationInfo;
    let currentLocation = '—';
    if (ele.location) {
      // If location exists in reverse Geo-Coder
      currentLocation = `${ele.location.city}, ${ele.location.state}`;
    } else if (ele.address) {
      // If location exists in Truck Table
      currentLocation = ele.address;
    }
    let lastCommunication = '—';
    if (ele.type === 'notInUse' && locationInfo?.timestamp) {
      // Retrieves User Preferences TimeZone
      const date = new Date(locationInfo?.timestamp).toLocaleString('en-US', {
        timeZone,
      });
      lastCommunication = dateFormat(date, 'mm/dd/yy HH:MM Z');
    }
    let arrivalTime = '—';
    let timeInShop = '—';
    if (ele.type === 'inShop' && ele.locationInfo) {
      currentLocation = locationInfo.address;
      if (locationInfo?.arrival_time) {
        arrivalTime = dateFormat(
          locationInfo.arrival_time,
          'mm/dd/yy HH:MM:ss Z'
        );
        timeInShop = getTimeDelta(arrivalTime);
      } else {
        // Constraint for Arrival Time(Only goes back 2 months for in Service Station)
        arrivalTime = '> 2 Months';
        timeInShop = '> 2 Months';
      }
    }
    let num = locationInfo?.nearest_ss;
    if (num !== undefined && num !== null) {
      // Converts Meters to Miles for Closest SS if exists
      num = Math.round(num / 1609.34);
    } else {
      num = '—';
    }

    return {
      miles: num,
      truckNumber: ele.number,
      truckID: ele.id,
      status:
        ele?.current_speed && ele.current_speed > 0 ? 'Moving' : 'Stopped',
      currentLocation,
      closestServiceLocation: locationInfo?.address,
      activeFaults: ele.checkDtc ? ele.checkDtc.length : 0,
      faultSeverity,
      truckDtcData,
      lastCommunication,
      arrivalTime,
      timeInShop,
      type: ele.type,
    };
  });
};
