var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { staticClass: "ml-2" },
        [
          _c("v-col", { attrs: { cols: "3" } }, [
            _c("h1", { staticClass: "centerText" }, [_vm._v("Settings")]),
          ]),
          _c(
            "v-col",
            { attrs: { align: "center", cols: "6" } },
            [
              _vm.selectedCompany && _vm.liveView
                ? _c("LiveFleetViewBanner", {
                    attrs: { "fleet-info": _vm.selectedCompany },
                    on: {
                      closed: function ($event) {
                        _vm.liveView = false
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "ml-2" },
        [
          _c(
            "v-col",
            { attrs: { xl: "2", lg: "2", md: "10", sm: "10", xs: "10" } },
            [
              _c(
                "v-tabs",
                {
                  attrs: { vertical: "", "hide-slider": "" },
                  model: {
                    value: _vm.tab,
                    callback: function ($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab",
                  },
                },
                _vm._l(_vm.items, function (item) {
                  return _c(
                    "v-tab",
                    {
                      key: item.tab,
                      staticClass: "settings-tabs",
                      staticStyle: {
                        "justify-content": "flex-start",
                        "max-width": "100%",
                      },
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v(" " + _vm._s(item.icon) + " "),
                      ]),
                      _vm._v(" " + _vm._s(item.tab) + " "),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "pt-0 pl-0",
              attrs: { xl: "10", lg: "10", md: "10", sm: "10", xs: "10" },
            },
            [
              _vm.tab === 0 && _vm.companyId
                ? _c("FleetSettings", {
                    attrs: { "company-id": _vm.companyId },
                  })
                : _vm._e(),
              _vm.tab === 1 && _vm.isHyliion
                ? _c("IndividualSettings")
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("br"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }