var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.exists
    ? _c("div", [
        _vm.showBtn
          ? _c(
              "div",
              {
                staticClass: "closeBtn",
                staticStyle: { cursor: "pointer" },
                on: { click: _vm.toggle },
              },
              [
                _vm._t("icon", function () {
                  return [
                    _c("v-icon", { staticStyle: { "margin-bottom": "6px" } }, [
                      _vm._v("mdi-close-circle"),
                    ]),
                  ]
                }),
                _vm._v(" Close "),
              ],
              2
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "bottomDrawer", style: _vm.drawerStyle },
          [_vm._t("default")],
          2
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }