var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "mt-3 mx-3", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { staticClass: "mr-3" },
        [
          _c("v-col", [
            _c("h1", { staticStyle: { "font-weight": "400" } }, [
              _vm._v("User Management"),
            ]),
          ]),
          _c("v-col", { attrs: { align: "right" } }),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mr-3" },
        [
          _c(
            "v-col",
            { attrs: { cols: "3", justify: "center" } },
            [
              _c("v-combobox", {
                attrs: {
                  items: _vm.companyNames,
                  placeholder: "Company",
                  solo: "",
                  color: "primary",
                },
                model: {
                  value: _vm.searchText,
                  callback: function ($$v) {
                    _vm.searchText = $$v
                  },
                  expression: "searchText",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "3", justify: "center" } },
            [
              _c("v-text-field", {
                attrs: {
                  placeholder: "Enter Name or Email Address",
                  solo: "",
                  "prepend-inner-icon": "mdi-magnify",
                  color: "primary",
                },
                model: {
                  value: _vm.searchText,
                  callback: function ($$v) {
                    _vm.searchText = $$v
                  },
                  expression: "searchText",
                },
              }),
            ],
            1
          ),
          _c("v-col", [_c("v-spacer")], 1),
          _c(
            "v-col",
            { attrs: { align: "right", cols: "2" } },
            [
              _vm.userCanAddNewUser
                ? _c(
                    "v-btn",
                    {
                      staticStyle: { color: "black", height: "48px" },
                      attrs: { color: "primary" },
                      on: { click: _vm.addUser },
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("mdi-plus"),
                      ]),
                      _vm._v("Add User"),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "my-0 mr-3" },
        [
          _c(
            "v-col",
            [
              _c("v-data-table", {
                attrs: {
                  headers: _vm.userLabelsDup,
                  items: _vm.userData,
                  search: _vm.searchText,
                  loading: _vm.loadingTable,
                  "custom-sort": _vm.userTableCustomSort,
                  "loading-text": "Loading... Please wait",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: `item.actions`,
                      fn: function ({ item }) {
                        return [
                          _c(
                            "v-menu",
                            {
                              staticStyle: { top: "312px", left: "300px" },
                              attrs: { "offset-y": "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c("v-icon", _vm._g({}, on), [
                                          _vm._v("mdi-dots-vertical"),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _c(
                                "v-list",
                                { attrs: { dense: "" } },
                                [
                                  _c(
                                    "v-list-item-group",
                                    _vm._l(
                                      _vm.actionItems,
                                      function (route, index) {
                                        return _c(
                                          "v-list-item",
                                          {
                                            key: index,
                                            class: route.title,
                                            on: {
                                              click: function ($event) {
                                                return _vm.setRoute(
                                                  item,
                                                  route.title
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "v-list-item-icon",
                                              { staticClass: "mr-2" },
                                              [
                                                _c("v-icon", [
                                                  _vm._v(_vm._s(route.icon)),
                                                ]),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-list-item-title", {
                                                  staticClass: "pr-2",
                                                }),
                                                _vm._v(
                                                  _vm._s(route.title) + " "
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.showAddUser
        ? _c("add-user", {
            attrs: { companies: _vm.companies },
            on: {
              closed: function ($event) {
                _vm.showAddUser = false
              },
              refresh: _vm.refreshData,
            },
          })
        : _vm._e(),
      _c(
        "v-navigation-drawer",
        {
          staticClass: "shade",
          attrs: { fixed: "", right: "", temporary: "", width: "360" },
          model: {
            value: _vm.drawer,
            callback: function ($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer",
          },
        },
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                { attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "mb-2", attrs: { align: "right" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function ($event) {
                              _vm.drawer = false
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { light: "" } }, [
                            _vm._v("mdi-close-circle "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("p", { staticStyle: { "text-align": "center" } }, [
                _vm._v("No content"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          staticClass: "pa-5",
          attrs: { width: "750" },
          model: {
            value: _vm.openDelete,
            callback: function ($$v) {
              _vm.openDelete = $$v
            },
            expression: "openDelete",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "pa-5" },
            [
              _c("h4", { staticStyle: { "text-align": "center" } }, [
                _vm._v(" Are you sure you want to delete this User?"),
                _c("br"),
                _vm._v(
                  " They will also be removed from any companies and groups they are in as well! "
                ),
              ]),
              _c(
                "v-btn",
                {
                  staticClass: "mr-1 mt-1",
                  staticStyle: { width: "49%", color: "black" },
                  attrs: { large: "", color: "primary" },
                  on: { click: _vm.deleteUsers },
                },
                [_vm._v("Yes")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "mt-1",
                  staticStyle: { width: "49%", color: "black" },
                  attrs: { large: "", color: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.openDelete = false
                    },
                  },
                },
                [_vm._v("No")]
              ),
            ],
            1
          ),
          _vm.loader ? _c("loader") : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }