<template>
  <v-dialog v-model="openDialog" class="dialog" width="750">
    <alert
      v-if="showAlert"
      :alert-type="alertType"
      :alert-message="alertMessage"
    />
    <loader v-if="loading" />
    <v-card>
      <v-container>
        <v-form>
          <v-row>
            <v-col class="hidden-xs-and-down"><v-spacer /></v-col>
            <v-col align="center" cols="12" sm="8" md="8" lg="8" xl="8"
              ><div class="text-h5">Edit User Profile</div></v-col
            >
            <v-col class="hidden-xs-and-down"><v-spacer /></v-col>
          </v-row>
          <v-row>
            <v-col class="hidden-xs-and-down"><v-spacer /></v-col>
            <v-col cols="12" sm="8" md="8" lg="8" xl="8">
              <v-text-field
                v-model="editedUser.full_name"
                label="Display Name"
                outlined
              ></v-text-field>
            </v-col>
            <v-col class="hidden-xs-and-down"><v-spacer /></v-col>
          </v-row>
          <v-row>
            <v-col align="right"
              ><v-progress-circular
                v-if="initLoading"
                indeterminate
                color="primary"
                class="mt-3"
              ></v-progress-circular
            ></v-col>
            <v-col cols="12" sm="8" md="8" lg="8" xl="8">
              <v-select
                v-model="editedUser.employer_company"
                :items="companies"
                item-text="name"
                label="Employer Company"
                outlined
                return-object
                @input="selectId($event, 'employer')"
              ></v-select>
            </v-col>
            <v-col class="hidden-xs-and-down"><v-spacer /></v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-col align="right"
              ><v-progress-circular
                v-if="initLoading"
                indeterminate
                color="primary"
              ></v-progress-circular
            ></v-col>
            <v-col cols="12" sm="8" md="8" lg="8" xl="8">
              <v-checkbox
                v-model="editedUser.has_all_company_access"
                label="Has All Company Access"
                color="primary"
                hide-details
                class="no-margin"
              ></v-checkbox>
              <v-select
                v-model="editedUser.company_id_list"
                :disabled="editedUser.has_all_company_access"
                :items="companies"
                item-text="name"
                multiple
                label="Company Access"
                outlined
                return-object
                @input="selectId($event, 'company')"
              ></v-select>
            </v-col>
            <v-col class="hidden-xs-and-down"><v-spacer /></v-col>
          </v-row>
          <v-row>
            <v-col align="right"
              ><v-progress-circular
                v-if="initLoading"
                indeterminate
                color="primary"
                class="mt-3"
              ></v-progress-circular
            ></v-col>
            <v-col cols="12" sm="8" md="8" lg="8" xl="8">
              <v-select
                v-model="editedUser.role_group"
                :items="groups"
                item-text="displayName"
                label="Role"
                outlined
                return-object
                @input="selectId($event, 'groups')"
              ></v-select>
            </v-col>
            <v-col class="hidden-xs-and-down"><v-spacer /></v-col>
          </v-row>
          <v-row>
            <v-col class="hidden-xs-and-down"><v-spacer /></v-col>
            <v-col align="center" cols="12" sm="4" md="4" lg="4" xl="4"
              ><v-btn @click="openDialog = false">Cancel</v-btn></v-col
            >
            <v-col align="center" cols="12" sm="4" md="4" lg="4" xl="4"
              ><v-btn @click="updateUser">Update</v-btn></v-col
            >
            <v-col class="hidden-xs-and-down"><v-spacer /></v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import alert from '@/utilities/alert';
import loader from '@/utilities/loader';

import { getGroups, updateUser } from '@/api/users';
import { getCompanies } from '@/api/company';

export default {
  name: 'EditUser',
  components: { alert, loader },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      editedUser: {
        full_name: this.user?.display_name,
        employer_company: this.user?.employer_company,
        employer_company_id: this.user?.employer_company_id,
        company_id_list: this.user?.company_ids ? this.user?.company_ids : [],
        has_all_company_access: this.user?.has_all_company_access,
        role_group: this.user?.role,
      },
      openDialog: false,
      loading: false,
      initLoading: true,
      showAlert: false,
      alertType: '',
      alertMessage: '',
      groups: [],
      companies: [],
      roleChanged: false,
      companyListChanged: false,
    };
  },
  watch: {
    // Watches for the change in openDialog variable to emit the false variable back to parent to reset
    openDialog: function (newValue) {
      if (!newValue) {
        this.openDialog = false;
        this.$emit('closed', false);
      }
    },
  },
  async mounted() {
    this.openDialog = true;
    try {
      await Promise.allSettled([getCompanies(), getGroups()]).then(
        (results) => {
          results[0].status === 'fulfilled'
            ? (this.companies = results[0].value.data)
            : console.log(results[0].reason);

          results[1].status === 'fulfilled'
            ? (this.groups = results[1].value.data?.roleGroups)
            : console.log(results[1].reason);

          this.initLoading = false;
        }
      );
      if (this.editedUser.company_id_list.length) {
        // Maps out company ids user had selected to show up correctly in dropdown
        this.editedUser.company_id_list = this.companies.filter((c) =>
          this.editedUser.company_id_list.includes(c.id)
        );
      }
    } catch (e) {
      console.log(e);
    }
  },
  methods: {
    selectId(e, type) {
      if (type === 'groups') {
        this.roleChanged = true; // checks if user changed the role option in the dropdown
        this.editedUser.role_group = e;
      }

      if (type === 'employer') {
        this.editedUser.employer_company_id = e.id;
      }
    },
    async updateUser() {
      this.loading = true;
      try {
        if (!this.roleChanged) {
          // Cleans up role before insertion
          const role = this.groups.filter(
            (g) => g.displayName === this.editedUser.role_group
          );
          this.editedUser.role_group = role[0]?.id;
        } else {
          this.editedUser.role_group = this.editedUser.role_group.id;
        }
        delete this.editedUser.employer_company; // Removes unnecessary field before insertion

        if (this.editedUser.company_id_list.length) {
          // Cleans up the company ids for api
          this.editedUser.company_id_list = this.editedUser.company_id_list.map(
            (c) => c.id
          );
        }
        await updateUser(this.user.object_id, this.editedUser);
        this.alertResponse('success', 'Successfully updated user!');
      } catch (error) {
        this.alertResponse('error', 'error updating user!');
      }
    },
    alertResponse(type, message) {
      this.loading = false;
      this.showAlert = true;
      this.alertType = type;
      this.alertMessage = message;
      setTimeout(() => {
        this.showAlert = false;
        this.openDialog = false;
        this.$emit('refresh');
      }, 2000);
    },
  },
};
</script>
<style scoped>
.dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  height: auto;
  width: auto;
  z-index: 1;
  transform: translate(-50%, -50%);
}
@media only screen and (min-width: 960px) {
  .padding {
    padding: 1rem;
  }
  .margin {
    margin: 0.2rem;
  }
}
</style>
