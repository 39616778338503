var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      staticClass: "dialog",
      attrs: { width: "750", persistent: "" },
      model: {
        value: _vm.openDialog,
        callback: function ($$v) {
          _vm.openDialog = $$v
        },
        expression: "openDialog",
      },
    },
    [
      _vm.showAlert
        ? _c("alert", {
            attrs: {
              "alert-type": _vm.alertType,
              "alert-message": _vm.alertMessage,
            },
          })
        : _vm._e(),
      _vm.loading ? _c("loader") : _vm._e(),
      _c(
        "v-card",
        [
          _c(
            "v-container",
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  model: {
                    value: _vm.valid,
                    callback: function ($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid",
                  },
                },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "hidden-xs-and-down" },
                        [_c("v-spacer")],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            align: "center",
                            cols: "12",
                            sm: "8",
                            md: "8",
                            lg: "8",
                            xl: "8",
                          },
                        },
                        [
                          _c("div", { staticClass: "text-h5" }, [
                            _vm._v("Add New Case"),
                          ]),
                        ]
                      ),
                      _c(
                        "v-col",
                        { staticClass: "hidden-xs-and-down" },
                        [_c("v-spacer")],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "hidden-xs-and-down" },
                        [_c("v-spacer")],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            cols: "12",
                            sm: "8",
                            md: "8",
                            lg: "8",
                            xl: "8",
                          },
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: "Subject",
                              outlined: "",
                              rules: [_vm.rules.required],
                            },
                            model: {
                              value: _vm.newCase.subject,
                              callback: function ($$v) {
                                _vm.$set(_vm.newCase, "subject", $$v)
                              },
                              expression: "newCase.subject",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "hidden-xs-and-down" },
                        [_c("v-spacer")],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "hidden-xs-and-down" },
                        [_c("v-spacer")],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            cols: "12",
                            sm: "8",
                            md: "8",
                            lg: "8",
                            xl: "8",
                          },
                        },
                        [
                          _c("v-textarea", {
                            attrs: {
                              label: "Description",
                              value: "",
                              outlined: "",
                            },
                            model: {
                              value: _vm.newCase.description,
                              callback: function ($$v) {
                                _vm.$set(_vm.newCase, "description", $$v)
                              },
                              expression: "newCase.description",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "hidden-xs-and-down" },
                        [_c("v-spacer")],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "hidden-xs-and-down" },
                        [_c("v-spacer")],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            cols: "12",
                            sm: "8",
                            md: "8",
                            lg: "8",
                            xl: "8",
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              label: "Priority",
                              items: _vm.priorityItems,
                              outlined: "",
                              rules: [_vm.rules.required],
                            },
                            model: {
                              value: _vm.newCase.priority,
                              callback: function ($$v) {
                                _vm.$set(_vm.newCase, "priority", $$v)
                              },
                              expression: "newCase.priority",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "hidden-xs-and-down" },
                        [_c("v-spacer")],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "hidden-xs-and-down" },
                        [_c("v-spacer")],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            cols: "12",
                            sm: "8",
                            md: "8",
                            lg: "8",
                            xl: "8",
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              label: "Type",
                              items: _vm.typeItems,
                              outlined: "",
                              rules: [_vm.rules.required],
                            },
                            model: {
                              value: _vm.newCase.type,
                              callback: function ($$v) {
                                _vm.$set(_vm.newCase, "type", $$v)
                              },
                              expression: "newCase.type",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "hidden-xs-and-down" },
                        [_c("v-spacer")],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "hidden-xs-and-down" },
                        [_c("v-spacer")],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            align: "center",
                            cols: "12",
                            sm: "4",
                            md: "4",
                            lg: "4",
                            xl: "4",
                          },
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { plain: "", color: "primary" },
                              on: {
                                click: function ($event) {
                                  _vm.openDialog = false
                                },
                              },
                            },
                            [_vm._v("Cancel")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            align: "center",
                            cols: "12",
                            sm: "4",
                            md: "4",
                            lg: "4",
                            xl: "4",
                          },
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticStyle: { color: "black" },
                              attrs: { disabled: !_vm.valid, color: "primary" },
                              on: { click: _vm.addNewCase },
                            },
                            [_vm._v("Add")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "hidden-xs-and-down" },
                        [_c("v-spacer")],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }