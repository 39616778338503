var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _vm.loading ? _c("loader") : _vm._e(),
      _c("v-data-table", {
        staticClass: "elevation-1",
        attrs: {
          headers: _vm.fileHeaders,
          items: _vm.fileItems,
          "items-per-page": 5,
          loading: _vm.loading,
          "item-key": "id",
        },
        scopedSlots: _vm._u([
          {
            key: "item.workbook_links",
            fn: function ({ item }) {
              return [
                _c(
                  "v-row",
                  _vm._l(item.workbook_links, function (link) {
                    return _c("v-col", { key: link.label }, [
                      _c(
                        "a",
                        { attrs: { href: link.link, target: "_blank" } },
                        [_vm._v(_vm._s(link.label))]
                      ),
                      _c("br"),
                    ])
                  }),
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }