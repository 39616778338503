<template>
  <div
    :id="`candle${id}`"
    :ref="`csChart${id}`"
    :options="csChartOptions"
  ></div>
</template>

<script>
import _ from 'lodash';
import Highcharts from 'highcharts/highstock';
import { mapGetters } from 'vuex';
import { getTimeZoneOffset } from '@/utilities/dateFunctions.js';

export default {
  name: 'Candlestick',
  props: {
    data: { type: Array, required: false },
    id: { type: String, required: true },
    title: { type: String, required: true },
    truckNames: { type: Object, required: false, default: null },
    addChart: {
      type: Function,
      required: false,
      default: () => {},
    },
    thresholds: { required: false, default: null },
  },
  data() {
    return {
      loading: true,
      fullGraph: false,
      studyHeaders: [
        { text: 'Date/Time', value: 'dateTime', sortable: true },
        { text: 'Company ID', value: 'companyID', sortable: false },
        { text: 'Truck ID', value: 'truckID', sortable: false },
        { text: 'index', value: 'xAxis', sortable: false },
        { text: 'Y-Axis', value: 'yAxis', sortable: false },
      ],
      exportDrawer: false,
      sampleXaxis: ['DataSet #1', 'DataSet #2', 'DataSet #3'],
      mainYaxis: [],
      subYaxis: [],
      studyTab: null,
      studyData: [],
      studyKey: 1,
      alertCopy: '',
      showProgress: false,
      yExtremes: { min: null, max: null },
      csChartOptions: {
        credits: { enabled: false },
        colors: ['#43B02A'],
        chart: {
          backgroundColor: '#1E1E1E',
          type: 'candlestick',
          renderTo: `candle${this.id}`,
          name: 'candle',
        },
        title: {
          text: this.title,
          style: {
            color: '#FFFFFF',
          },
        },
        xAxis: {
          title: {
            text: 'Time',
            style: {
              color: '#FFFFFF',
            },
          },
          labels: {
            step: 1,
            style: {
              color: '#FFFFFF',
            },
          },
          lineColor: '#FFFFFF',
        },
        yAxis: [
          {
            floor: 0,
            title: {
              text: 'Data',
              style: {
                color: '#FFFFFF',
              },
            },
            labels: {
              style: {
                color: '#FFFFFF',
              },
            },
            lineColor: '#FFFFFF',
          },
          {
            floor: 0,
            ceiling: 2,
            gridLineWidth: 0,
            visible: true,
            labels: {
              style: {
                color: '#FF0000',
              },
            },
            gridLineColor: '#000000',
            lineColor: '#000000',
            opposite: true,
          },
        ],
        rangeSelector: {
          selected: 4,
          inputEnabled: false,
          buttonTheme: {
            style: {
              display: 'none',
            },
          },
          labelStyle: {
            visibility: 'hidden',
          },
        },
        series: [
          {
            type: 'candlestick',
            // sample data
            data: [],
            lineColor: '#d9d9d9',
            dataGrouping: {
              units: [
                [
                  'week', // unit name
                  [1], // allowed multiples
                ],
                ['month', [1, 2, 3, 4, 6]],
              ],
            },
            name: 'candle',
          },
        ],
        scrollbar: {
          barBackgroundColor: '#828282',
          barBorderColor: '#000000',
          buttonArrowColor: '#000000',
          buttonBackgroundColor: '#828282',
          buttonBorderColor: '#3d3d3d',
          rifleColor: '#000000',
          trackBackgroundColor: '#3d3d3d',
          trackBorderColor: '#3d3d3d',
        },
        exporting: {
          enabled: true,
        },
      },
      csChart: null,
      csAltChart: null,
      seriesData: [],
      legendNames: null,
      tenColumns: [],
      companyList: [],
      companyItems: [],
      truckList: [],
      queryPath: process.env.VUE_APP_API_PATH_BASE,
      queryString: '',
      colors: [
        '#00B3FF',
        '#24EF10',
        '#972AB0',
        '#FFF000',
        '#FB04D8',
        '#04FB27',
        '#FF9900',
        '#0066FF',
      ],
    };
  },
  computed: {
    ...mapGetters({
      userPreferences: 'getUserPreferences',
    }),
  },
  watch: {
    data() {
      if (this.data) this.processData();
    },
  },
  mounted() {
    if (this.data) this.processData();
  },
  // prevent a memory leak
  beforeDestroy() {
    if (this.csChart) this.csChart.destroy();
  },
  methods: {
    mountGraph() {
      this.csChart = new Highcharts.stockChart(this.csChartOptions);
      // this.addChart(this.csChart); // add back in once exporting cs is sorted
      this.csChart.yAxis[0].setExtremes(
        Math.min(this.csChart.yAxis[0].min, this.yExtremes.min),
        Math.max(this.csChart.yAxis[0].max, this.yExtremes.max)
      );
    },
    processData() {
      const vm = this;
      let tzOffsetMinutes = 0;
      let csData = {};
      for (const [index, datum] of this.data.entries()) {
        if (!(datum.truck_id in csData)) {
          csData[datum.truck_id] = [];
        }
        // Get the offset in seconds to UTC time based on
        // user's preference for time zone and based onf first timestamp
        // in the data.  This is used to set the time offset for Highcharts
        if (index === 0) {
          let tz = this.userPreferences.timeZonePref.canonical;
          this.tzOffsetMinutes = getTimeZoneOffset(datum.end, tz);
        }
        let utcTimeMs = new Date(datum.end).valueOf();
        csData[datum.truck_id].push([
          utcTimeMs,
          (datum.minValue + datum.avgValue) / 2,
          datum.maxValue,
          datum.minValue,
          (datum.maxValue + datum.avgValue) / 2,
        ]);
      }

      let series = [];
      const colors = [
        '#FF0000',
        '#FFFF00',
        '#00EAFF',
        '#AA00FF',
        '#FF7F00',
        '#BFFF00',
        '#0095FF',
        '#FF00AA',
        '#FFD400',
        '#6AFF00',
        '#EDB9B9',
        '#B9D7ED',
        '#E7E9B9',
        '#DCB9ED',
        '#B9EDE0',
        '#8F2323',
        '#23628F',
      ];
      let colorPointer = 0;

      for (const [key, value] of Object.entries(csData)) {
        const truckName = this.truckNames ? this.truckNames[key] : key;
        let ind = {
          type: 'candlestick',
          data: value,
          lineColor: colors[colorPointer],
          name: truckName,
        };
        series.push(ind);
        colorPointer++;
        if (colorPointer > 16) {
          colorPointer = 0;
        }
      }
      this.csChartOptions.series = series;
      this.csChartOptions.time = { timezoneOffset: this.tzOffsetMinutes };
      vm.seriesData = series;
      // fixme on backend: api needs to return signal name
      this.csChartOptions.yAxis[0].title.text = 'Signal';

      // this.buildThresholdSeries();
      _.delay(vm.mountGraph, 200);
    },
    buildThresholdSeries() {
      let plotLines = [];
      let [maxY, minY] = [-Infinity, Infinity];
      this.thresholds[0].thresholds.forEach((t) => {
        maxY = Math.max(t.failed_threshold, t.warning_threshold, maxY);
        minY = Math.min(t.failed_threshold, t.warning_threshold, minY);
        plotLines.push({
          color: 'orange',
          value: t.warning_threshold,
          label: {
            text: t.description,
            style: { color: 'white' },
            align: 'right',
          },
        });

        plotLines.push({
          color: 'red',
          value: t.failed_threshold,
          label: {
            text: t.description,
            style: { color: 'white' },
            align: 'left',
          },
        });
      });
      this.yExtremes = {
        min: minY,
        max: maxY,
      };
      this.csChartOptions.yAxis[0].plotLines = plotLines;
    },
  },
};
</script>

<style scoped></style>
