<template>
  <div id="health">
    <alert
      v-if="showAlert"
      :alert-type="alertType"
      :alert-message="alertMessage"
    />
    <v-container fluid class="mt-3 mx-3">
      <v-row class="mr-3">
        <v-col>
          <h1 style="font-weight: 400">Health</h1>
        </v-col>
        <v-col align="right">
          <dtc-lookup />
          <v-btn
            color="#D8D8D8"
            class="mr-1"
            style="color: black; height: 48px"
            @click="exportCSV"
          >
            <download-csv
              id="csv"
              :name="tab === 'EX' ? 'ex_truck_data.csv' : 'erx_truck_data.csv'"
              :data="tab === 'EX' ? exTrucks : erxTrucks"
              :fields="truckDataCSV(tab === 'EX' ? exTrucks : erxTrucks)"
            >
              <v-icon id="csv2">mdi-download</v-icon>
              Download CSV
            </download-csv>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3" xs="12" sm="12" md="6" lg="4" xl="3">
          <v-tabs v-model="tab" fixed-tabs background-color="secondary">
            <v-tab v-for="item in tabItems" :key="item">
              {{ item }} Trucks
            </v-tab>
          </v-tabs>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3" xs="12" sm="12" md="6" lg="4" xl="3" class="pb-0">
          <v-text-field
            v-model="searchText"
            placeholder="Search By Customer or Truck"
            solo
            prepend-inner-icon="mdi-magnify"
            background-color="shade"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="my-0 mr-3">
        <v-col class="py-0">
          <ex-table
            v-if="tabItems[tab] === 'EX'"
            :truck-data="exTrucks"
            :loading="exLoading"
            :search-text="searchText"
            @emitAlert="alertResponse"
          />
          <erx-table
            v-else-if="tabItems[tab] === 'ERX'"
            :truck-data="erxTrucks"
            :loading="erxLoading"
            :search-text="searchText"
            @emitAlert="alertResponse"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import dateFormat from 'dateformat';
import {
  getLatestDTCs,
  getADXHealthData,
  getADXHealthERXData,
  getADXLastAhead,
  getERXDTCs,
} from '@/api/trucks';
import { getSpnfmiStates } from '@/api/dtc';
import { getAllDevices } from '@/api/iot';

import EXTable from './EXTable';
import ERXTable from './ERXTable';
import DTCLookup from './DTC/DTCLookup';

import { mapGetters, mapActions } from 'vuex';
import alert from '@/utilities/alert';
import { getTimeDelta } from '@/utilities/dateFunctions';
import { regionalSetting } from '@/utilities/userSettings';
import { erxDTCLookup, iotCleanup } from '@/utilities/health';
import _ from 'lodash';

export default {
  name: 'Health',
  components: {
    alert,
    'ex-table': EXTable,
    'erx-table': ERXTable,
    'dtc-lookup': DTCLookup,
  },
  data() {
    return {
      healthTrucks: null,
      exLoading: true,
      erxLoading: true,
      showAlert: false,
      alertType: '',
      alertMessage: '',
      type: [],
      tab: 0,
      tabItems: ['ERX', 'EX'],
      truckData: [],
      exTrucks: [],
      erxTrucks: [],
      truckList: [],
      adxTimeStamps: [],
      adxHealthData: [],
      adxerxHealthData: [],
      dtcData: [],
      erxDtcData: [],
      searchText: '',
    };
  },
  computed: {
    ...mapGetters({
      userPreferences: 'getUserPreferences',
      truckType: 'getHealthTruckType',
      truckDetailsRoute: 'getFromTruckDetails',
      storeHealthTrucks: 'getHealthTrucks',
      spnfmiStates: 'getSpnfmiStates',
    }),
  },
  async created() {
    // Checks if previous route was truck details, and then checks if the truck was an EX or ERX truck to properly display tab
    if (this.truckDetailsRoute) {
      if (this.truckType === 'EX') {
        this.tab = 1;
      }
    }
    if (!this.spnfmiStates.length) {
      const { data } = await getSpnfmiStates();
      await this.asyncUpdateSpnfmiStates(data);
    }
    // populate ERX completely first since it is displayed initially
    await this.populateERXTrucks();
    this.populateEXTrucks();
  },
  methods: {
    ...mapActions(['fetchHealthTrucks', 'asyncUpdateSpnfmiStates']),
    async populateERXTrucks() {
      const vm = this;
      await this.getERXData();
      let iotDeviceData = await getAllDevices();
      this.healthTrucks = iotCleanup(
        this.healthTrucks,
        iotDeviceData.data,
        'ERX',
        false
      );
      try {
        if (this.healthTrucks.length > 0) {
          for (const truck of this.healthTrucks) {
            // skip all non ERX trucks
            if (!truck.truck_type.includes('ERX')) continue;
            vm.$set(truck, 'name', `${truck.company},${truck.number}`); // Specifically for Company Search Filtering
            vm.$set(truck, 'number', truck.number);
            vm.$set(truck, 'alias', truck.alias ? truck.alias : '—');
            vm.$set(truck, 'company', truck.company);
            vm.$set(
              truck,
              'hcu_version',
              truck.hcu_version ? truck.hcu_version : '—'
            );
            vm.$set(
              truck,
              'hdp_version',
              truck.hdp_version ? truck.hdp_version : '—'
            );
            vm.$set(
              truck,
              'sgw_version',
              truck.sgw_version ? truck.sgw_version : '—'
            );
            vm.$set(
              truck,
              'address',
              truck.address ? truck.address : 'No Location Data'
            );
            vm.$set(
              truck,
              'license_plate',
              truck.license_plate ? truck.license_plate.plate : '—'
            );
            vm.$set(
              truck,
              'created_at',
              dateFormat(truck.created_at, 'mm/dd/yyyy hh:MM TT')
            );
            vm.$set(
              truck,
              'version',
              truck.config_version ? truck.config_version : '—'
            );
            vm.$set(
              truck,
              'truck_type',
              truck.truck_type ? truck.truck_type : 'EX'
            );
            vm.$set(truck, 'fuel_type', truck.fuel_type);
            vm.$set(truck, 'in_service', truck.in_service ? 'Yes' : 'No');
            this.formatERXTrucks(truck);
          }
          // this.matchColumns(); // Grabs visible columns, temp static data(Will grab from API in future)
        } else {
          console.log('Response array for trucks is empty...');
        }
      } catch (error) {
        console.error('Axios error on getting trucks ==> ' + error);
        this.alertResponse('error', 'Error retrieving ERX trucks');
      }
      vm.erxLoading = false;
    },
    async getERXData() {
      const vm = this;
      // get health trucks from store if they exist
      if (this.storeHealthTrucks && !this.healthTrucks) {
        this.healthTrucks = _.cloneDeep(this.storeHealthTrucks);
      }
      let promises = [getADXHealthERXData(), getERXDTCs()];
      // else fetch health trucks if non-existent in store
      if (!this.healthTrucks) promises.push(this.fetchHealthTrucks());
      await Promise.allSettled(promises).then((results) => {
        results[0].status === 'fulfilled'
          ? (vm.adxerxHealthData = results[0].value.data)
          : console.log(results[0].reason);
        results[1].status === 'fulfilled'
          ? (vm.erxDtcData = results[1].value.data)
          : console.log(results[1].reason);
        // set health trucks to deep clone of store result if fetched
        if (!vm.healthTrucks) {
          results[2].status === 'fulfilled'
            ? (vm.healthTrucks = _.cloneDeep(vm.storeHealthTrucks))
            : console.log(results[2].reason);
        }
      });
    },
    async populateEXTrucks() {
      const vm = this;
      await this.getEXData();
      try {
        if (this.healthTrucks.length > 0) {
          for (const truck of this.healthTrucks) {
            // skip all non EX trucks
            if (!truck.truck_type.includes('EX')) continue;
            vm.$set(truck, 'name', `${truck.company},${truck.number}`); // Specifically for Company Search Filtering
            vm.$set(truck, 'number', truck.number);
            vm.$set(truck, 'company', truck.company);
            vm.$set(
              truck,
              'address',
              truck.address ? truck.address : 'No Location Data'
            );
            vm.$set(
              truck,
              'license_plate',
              truck.license_plate ? truck.license_plate.plate : '—'
            );
            vm.$set(
              truck,
              'created_at',
              dateFormat(truck.created_at, 'mm/dd/yyyy hh:MM TT')
            );
            vm.$set(
              truck,
              'version',
              truck.config_version ? truck.config_version : '—'
            );
            vm.$set(
              truck,
              'truck_type',
              truck.truck_type ? truck.truck_type : 'EX'
            );
            vm.$set(truck, 'fuel_type', truck.fuel_type);
            vm.$set(truck, 'in_service', truck.in_service ? 'Yes' : 'No');
            this.formatEXTrucks(truck);
          }
          // this.matchColumns(); // Grabs visible columns, temp static data(Will grab from API in future)
        } else {
          console.log('Response array for trucks is empty...');
        }
      } catch (error) {
        console.error('Axios error on getting trucks ==> ' + error);
        this.alertResponse('error', 'Error retrieving EX trucks');
      }
      vm.exLoading = false;
    },
    async getEXData() {
      const vm = this;
      // get health trucks from store if they exist
      if (this.storeHealthTrucks && !this.healthTrucks) {
        this.healthTrucks = _.cloneDeep(this.storeHealthTrucks);
      }
      let promises = [getADXHealthData(), getADXLastAhead(), getLatestDTCs()];
      if (!this.healthTrucks) promises.push(this.fetchHealthTrucks());
      await Promise.allSettled(promises).then((results) => {
        results[0].status === 'fulfilled'
          ? (vm.adxHealthData = results[0].value.data)
          : console.log(results[0].reason);
        results[1].status === 'fulfilled'
          ? (vm.adxTimeStamps = results[1].value.data)
          : console.log(results[1].reason);
        results[2].status === 'fulfilled'
          ? (vm.dtcData = results[2].value.data)
          : console.log(results[2].reason);
        if (!vm.healthTrucks) {
          results[3].status === 'fulfilled'
            ? (vm.healthTrucks = _.cloneDeep(vm.storeHealthTrucks))
            : console.log(results[3].reason);
        }
      });
    },
    formatERXTrucks(truck) {
      const adxerxData = this.adxerxHealthData.find(
        (el) => el.truck_id === truck.id && el.company_id === truck.company_id
      );

      truck.odometer = regionalSetting(
        this.userPreferences.regionPref,
        adxerxData?.odometer,
        'odometer',
        true,
        0
      );

      truck.fuel_lvl = adxerxData?.fuel_lvl ? adxerxData.fuel_lvl : '—';
      if (adxerxData?.ignition_state) {
        switch (adxerxData.ignition_state) {
          case 3:
            truck.ignition_state = 'Off';
            break;
          case 7:
            truck.ignition_state = 'Accesory';
            break;
          case 19:
            truck.ignition_state = 'Crank';
            break;
          case 23:
            truck.ignition_state = 'On';
            break;
          default:
            truck.ignition_state = '—';
            break;
        }
      } else {
        truck.ignition_state = '—';
      }

      truck.current_speed = regionalSetting(
        this.userPreferences.regionPref,
        adxerxData?.current_speed,
        'speed',
        true,
        2
      );
      truck.ev_range = regionalSetting(
        this.userPreferences.regionPref,
        adxerxData?.ev_range,
        'distance',
        true
      );

      truck.fuel_range = regionalSetting(
        this.userPreferences.regionPref,
        adxerxData?.fuel_range,
        'distance',
        true
      );
      truck.hotel_hours = adxerxData?.hotel_hours || '—';
      truck.mostRecentBatteryRecal = adxerxData?.mostRecentBatteryRecal
        ? dateFormat(adxerxData.mostRecentBatteryRecal, 'mm/dd/yyyy hh:MM TT')
        : '—';
      truck.SOC = adxerxData?.SOC ? adxerxData.SOC : '—';
      truck.weight = regionalSetting(
        this.userPreferences.regionPref,
        adxerxData?.weight,
        'weight',
        true,
        0
      );
      if (adxerxData?.cruisectrlactive !== null) {
        if (adxerxData?.cruisectrlactive === 1) {
          truck.cruisectrlactive = 'Yes';
        } else {
          truck.cruisectrlactive = 'No';
        }
      } else {
        truck.cruisectrlactive = '—';
      }
      truck.cruisectrlsetspeed = regionalSetting(
        this.userPreferences.regionPref,
        adxerxData?.cruisectrlsetspeed,
        'speed',
        true,
        2
      );
      if (adxerxData?.plugged_in !== null) {
        if (adxerxData?.plugged_in === 1) {
          truck.plugged_in = 'Yes';
        } else {
          truck.plugged_in = 'No';
        }
      } else {
        truck.plugged_in = '—';
      }
      truck.retarder_level =
        typeof adxerxData?.retarder_level === 'number' &&
        adxerxData?.retarder_level !== null
          ? adxerxData.retarder_level
          : '—';
      truck.timeUpdated = adxerxData?.timestamp
        ? getTimeDelta(adxerxData.timestamp)
        : '—';

      // For Truck Details Icon Loader Mapping
      let dtc = this.erxDtcData.filter((t) => t.truck_id == truck.id);
      let { dtcs, icons } = erxDTCLookup(dtc, this.spnfmiStates);
      let status = 'Normal';
      if (dtcs.length) {
        status = 'TRUCK';
        let hyliionDTC = dtcs.find((d) => d.source === '0x03');
        if (hyliionDTC) {
          status = 'FAULT';
        }
      }

      truck.active_dtcs = dtcs;
      truck.icons = icons;

      truck.status = status;
      truck.primary_email = truck.primary_email ? truck.primary_email : '—';
      truck.secondary_email = truck.secondary_email
        ? truck.secondary_email
        : '—';
      truck.intended_usage = truck.intended_usage ? truck.intended_usage : '—';
      truck.note = truck.note ? truck.note : '—';
      truck.last_usage_update = truck.last_usage_update
        ? getTimeDelta(truck.last_usage_update)
        : '—';
      truck.engtotalhoursofoperation = adxerxData?.engtotalhoursofoperation
        ? adxerxData.engtotalhoursofoperation
        : '—';
      this.erxTrucks.push(truck);
    },
    formatEXTrucks(truck) {
      const adxData = this.adxHealthData.find((el) => el.truck_id === truck.id);
      truck.timeUpdatedRaw = adxData ? adxData.one_min_time : '—';
      truck.timeUpdated = adxData ? getTimeDelta(adxData.one_min_time) : '—';
      truck.iotUpdate =
        adxData && adxData.max_telemetry_time
          ? getTimeDelta(adxData.max_telemetry_time)
          : '—';
      truck.odometer = regionalSetting(
        this.userPreferences.regionPref,
        adxData?.odometer,
        'odometer',
        true,
        0
      );
      truck.ignition = adxData ? (adxData?.ignition ? 'On' : 'Off') : '—';
      const overLoadedSpeed = (adxData) => {
        if (adxData?.APU) {
          return 'Hotel';
        } else if (!adxData?.ignition) {
          return 'Off';
        } else {
          return regionalSetting(
            this.userPreferences.regionPref,
            adxData.speed,
            'speed',
            true,
            2
          );
        }
      };
      truck.current_speed = adxData ? overLoadedSpeed(adxData) : '—';
      truck.SOC = adxData?.SOC ? adxData.SOC : '—';
      truck.APU = adxData ? (adxData?.APU ? 'On' : 'Off') : '—';
      truck.last_error_shut_down = adxData?.last_error_shut_down_time
        ? getTimeDelta(adxData.last_error_shut_down_time)
        : '—';

      const laLastPing = this.adxTimeStamps.find(
        (el) => parseInt(el.la_truck_id) === truck.id
      );
      truck.laTimeUpdated = laLastPing
        ? getTimeDelta(laLastPing.la_timestamp)
        : '—';

      const taLastPing = this.adxTimeStamps.find(
        (el) => parseInt(el.ta_truck_id) === truck.id
      );
      truck.taTimeUpdated = taLastPing
        ? getTimeDelta(taLastPing.ta_timestamp)
        : '—';

      const dtcs = this.dtcData.filter((d) => d.truck_id === truck.id);
      let dtc = 0;
      let pre_dtc = 0;
      let status = 'Normal';
      if (dtcs.length) {
        dtcs.forEach((d) => {
          if (d.dtc_active && !d.cleared) {
            status = 'FAULT';
            dtc++;
          } else if ((d.fail_count > 0 || d.trip_count > 0) && !d.cleared) {
            if (status !== 'FAULT') status = 'Warning';
            pre_dtc++;
          }
        });
      }
      truck.dtc = dtc;
      truck.pre_dtc = pre_dtc;
      truck.status = status;

      truck.load = adxData?.Load ? adxData.Load : '—';
      truck.engSpeed = adxData ? Math.round(adxData.EngSpeed) / 10 : '—';

      truck.generalTemp = regionalSetting(
        this.userPreferences.regionPref,
        adxData?.generalTemp,
        'temperature',
        true,
        0
      );
      truck.motorTemp = regionalSetting(
        this.userPreferences.regionPref,
        adxData?.motorTemp,
        'temperature',
        true,
        0
      );
      truck.compressorTemp = regionalSetting(
        this.userPreferences.regionPref,
        adxData?.compressorTemp,
        'temperature',
        true,
        0
      );
      truck.batteryMaxTemp = regionalSetting(
        this.userPreferences.regionPref,
        adxData?.batteryMaxTemp,
        'temperature',
        true,
        0
      );
      truck.batteryMinTemp = regionalSetting(
        this.userPreferences.regionPref,
        adxData?.batteryMinTemp,
        'temperature',
        true,
        0
      );

      truck.serial_number = truck.serial_number ? truck.serial_number : '—';
      this.exTrucks.push(truck);
    },
    alertResponse(type, message) {
      this.showAlert = true;
      this.alertType = type;
      this.alertMessage = message;
      setTimeout(() => {
        this.showAlert = false;
      }, 2000);
    },
    truckDataCSV(truckData) {
      truckData.map((el) => {
        if (el.position !== null && typeof el.position === 'object') {
          el.position = `${el.position.lat},${el.position.lng}`;
        }
        return el;
      });
    },
    exportCSV(event) {
      if (event.target.id != 'csv' && event.target.id != 'csv2') {
        document.getElementById('csv').click();
      }
    },
  },
};
</script>
