<template>
  <v-card flat>
    <loader v-if="loading" />
    <v-data-table
      :headers="fileHeaders"
      :items="fileItems"
      :items-per-page="5"
      :loading="loading"
      item-key="id"
      class="elevation-1"
    >
      <template v-slot:item.workbook_links="{ item }">
        <v-row>
          <v-col v-for="link in item.workbook_links" :key="link.label">
            <a :href="link.link" target="_blank">{{ link.label }}</a>
            <br />
          </v-col>
        </v-row>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';
import { getServiceGuidesTable } from '@/api/dtc';
import loader from '@/utilities/loader';
export default {
  name: 'WorkbookTable',
  components: { loader },
  data() {
    return {
      fileHeaders: [
        {
          text: 'Workbook',
          value: 'file_name',
          align: 'center',
          sortable: true,
          visible: true,
          hideable: true,
        },
        {
          text: 'Component',
          value: 'component',
          align: 'center',
          sortable: true,
          visible: true,
          hideable: true,
        },
        {
          text: 'Link',
          value: 'workbook_links',
          align: 'center',
          sortable: true,
          visible: true,
          hideable: true,
        },
        {
          text: '',
          value: 'actions',
          align: 'right',
        },
      ],
      fileItems: [],
      loading: false,
    };
  },
  mounted() {
    this.loadTable();
  },
  methods: {
    ...mapActions({
      updateSnack: 'updateSnack',
    }),
    loadTable: async function () {
      this.loading = true;
      try {
        const res1 = await getServiceGuidesTable();
        this.fileItems = res1.data;
      } catch (error) {
        this.updateSnack({
          type: 'error',
          message: 'Unable to load System Serviec Guides table.',
        });
      }
      this.loading = false;
    },
  },
};
</script>
<style scoped></style>
