<!-- DEPRECATED: invoke GlobalSnack instead; documentation and example in GlobalSnack.vue file -->
<template>
  <v-snackbar v-model="snackbar" :color="getColor()">
    {{ snackMessage }}
    <template v-slot:action="{ attrs }">
      <v-btn v-bind="attrs" text @click="snackbar = false"> Close </v-btn>
    </template>
  </v-snackbar>
</template>

<!--
    Possible statuses can be ('success','info', 'warning', and 'error')
-->
<script>
export default {
  name: 'SnackBar',
  props: {
    snackType: {
      type: String,
      required: true,
    },
    snackMessage: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    snackbar: false,
  }),
  created() {
    this.snackbar = true;
  },
  methods: {
    getColor() {
      let color = '';
      switch (this.snackType) {
        case 'success':
          color = 'primaryDark';
          break;
        case 'error':
          color = 'red';
          break;
        default:
          color = 'primary';
          break;
      }
      return color;
    },
  },
};
</script>
<style scoped></style>
