var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { lg: "9" } }, [
            _c("h1", { staticStyle: { "font-weight": "400" } }, [
              _vm._v("Alerts"),
            ]),
          ]),
          _c(
            "v-col",
            { attrs: { align: "right", lg: "3" } },
            [
              _c("v-row", {
                staticStyle: {
                  display: "block",
                  "margin-top": "7px",
                  "margin-bottom": "7px",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card",
        [
          _c(
            "v-tabs",
            {
              attrs: { "background-color": "Secondary", dark: "" },
              model: {
                value: _vm.tab,
                callback: function ($$v) {
                  _vm.tab = $$v
                },
                expression: "tab",
              },
            },
            [
              _vm._l(_vm.tabItems, function (item) {
                return _c("v-tab", { key: item.tab }, [
                  _vm._v(" " + _vm._s(item.tab) + " "),
                ])
              }),
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.tab,
                    callback: function ($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab",
                  },
                },
                _vm._l(_vm.tabItems, function (item) {
                  return _c(
                    "v-tab-item",
                    { key: item.tab },
                    [
                      _c(
                        "v-card",
                        { attrs: { flat: "" } },
                        [
                          item.content === "history" ? _c("history") : _vm._e(),
                          item.content === "subscribe"
                            ? _c("subscribe")
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "v-navigation-drawer",
        {
          staticClass: "shade",
          attrs: { right: "", temporary: "", fixed: "", width: "360" },
          model: {
            value: _vm.drawer,
            callback: function ($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer",
          },
        },
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                { attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "mb-2", attrs: { align: "right" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function ($event) {
                              _vm.drawer = false
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { light: "" } }, [
                            _vm._v("mdi-close-circle "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-expansion-panels",
                [
                  _c(
                    "v-expansion-panel",
                    { staticClass: "shade" },
                    [
                      _c("v-expansion-panel-header", [_vm._v(" Preferences ")]),
                      _c("v-expansion-panel-content", [_vm._v(" TBD ")]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }