var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c("v-col", { staticClass: "pl-0 pb-0" }, [
            _c("h3", [
              _vm._v(
                " " +
                  _vm._s(_vm.notInUseTrucks ? _vm.notInUseTrucks.length : 0) +
                  " Results "
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pa-0", attrs: { cols: "12" } },
            [
              _c("not-in-use-table", {
                attrs: { trucks: _vm.notInUseTrucks },
                on: { viewClicked: _vm.viewTruck },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }