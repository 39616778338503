<!-- Global DTC Lookup component -->
<template>
  <v-btn
    color="primary"
    class="mr-1"
    style="color: black; height: 48px"
    @click="openDialog = true"
  >
    <v-icon id="csv2">mdi-magnify</v-icon>
    DTC LOOKUP
    <v-dialog v-model="openDialog" class="dialog" width="625" persistent>
      <loader v-if="dtcLoading" />
      <v-card>
        <v-container fluid>
          <v-row style="padding-bottom: 10px">
            <v-col align="left" cols="6">
              <h3>DTC Lookup</h3>
            </v-col>
            <v-col align="right" cols="6">
              <v-btn icon @click="closeDialog"
                ><v-icon> mdi-close-circle </v-icon></v-btn
              >
            </v-col>
          </v-row>
          <v-spacer />
          <v-row>
            <v-divider></v-divider>
          </v-row>

          <v-row>
            <v-col align="right" cols="4">
              <v-text-field
                v-model="search"
                outlined
                label="Enter SPN number"
                hide-details
                @keypress="isNumber($event)"
              ></v-text-field>
            </v-col>
            <v-col align="right" cols="4">
              <v-text-field
                v-model="searchFmi"
                outlined
                label="Enter FMI number"
                hide-details
                @keypress="isNumber($event)"
              ></v-text-field>
            </v-col>
            <v-col align="left" cols="3">
              <v-btn
                color="primary"
                :outlined="!search.length"
                style="color: black; height: 56px; width: 165px"
                :disabled="!search.length"
                @click="searchDtc"
                >Search</v-btn
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col class="body-6" align="left">
              <p>Enter the SPN and FMI</p>
              <v-spacer />
            </v-col>
          </v-row>
          <v-row>
            <v-divider></v-divider>
          </v-row>
          <v-row>
            <v-col v-if="dtcLookupError" align="left" cols="12">
              <v-alert
                dense
                prominent
                style="color: black"
                height="46px"
                color="red"
                ><v-row
                  ><v-col cols="1"
                    ><v-icon
                      color="#E0E0E0"
                      class="pa-1"
                      style="background-color: #272727"
                      @click="dtcLookupError = false"
                    >
                      mdi-close-octagon-outline
                    </v-icon></v-col
                  >
                  <v-col cols="11"
                    ><p style="font-size: 16px">
                      Sorry, no match found! Please try again
                    </p></v-col
                  ></v-row
                ></v-alert
              >
            </v-col>
          </v-row>
          <template v-if="dtcResults.spn.length">
            <v-row
              ><v-col><h3>Result</h3></v-col>
            </v-row>
            <v-row>
              <v-col cols="2" class="pt-5">
                <span class="secondary-text">Indicator:</span></v-col
              >
              <v-col cols="4" align="left" :class="!hasIcon ? 'mt-2' : ''">
                <icon-loader
                  v-if="hasIcon"
                  :icon-show="dtcResults.primary_icon_name"
                />
                <span v-else>N/A</span>
              </v-col>
              <v-col>
                <p>
                  <span class="secondary-text">SPN:</span><br />
                  {{ dtcResults.spn }}
                </p></v-col
              ><v-col
                ><p>
                  <span class="secondary-text">FMI:</span><br />
                  {{ dtcResults.fmi }}
                </p></v-col
              >
            </v-row>
            <p class="left" style="margin-top: 1em">
              <span class="secondary-text">Title:</span><br />
              {{ dtcResults.name }}
            </p>
            <p class="left" style="margin-top: 1em">
              <span class="secondary-text">Description:</span><br />
              {{ dtcResults.description }}
            </p>
            <p class="left" style="margin-top: 1em">
              <span class="secondary-text">Component:</span><br />
              <a :href="dtcResults.workbook_link">{{ dtcResults.component }}</a>
            </p>
          </template>
        </v-container>
      </v-card>
    </v-dialog>
  </v-btn>
</template>

<script>
import IconLoader from '@/utilities/IconLoader.vue';
import { dtcLookup } from '@/api/trucks';
import loader from '@/utilities/loader.vue';

export default {
  name: 'DTCLookup',
  components: {
    'icon-loader': IconLoader,
    loader,
  },
  data() {
    return {
      dtcResults: {
        description: '',
        name: '',
        fmi: '',
        spn: '',
        primary_icon_name: [],
        component: '---',
        workbook_link: '---',
      },
      dtcLoading: false,
      dtcLookupError: false,
      search: '',
      searchFmi: '',
      hasIcon: false,
      openDialog: false,
    };
  },
  methods: {
    closeDialog: function () {
      // TODO: Emit close var to parent to allow parent to close modal, which will reset all vars
      this.dtcResults = {
        description: '',
        name: '',
        fmi: '',
        spn: '',
        spnfmi: '',
        primary_icon_name: '',
        component: '---',
        workbook_link: '---',
      };
      this.openDialog = false;
      this.search = '';
      this.searchFmi = '';
      this.dtcLookupError = false;
      this.hasIcon = false;
    },
    searchDtc: async function () {
      this.dtcLoading = true;
      this.hasIcon = false;
      try {
        const res = await dtcLookup(this.search + '-' + this.searchFmi);
        this.dtcLookupError = false;
        this.dtcResults = res.data.spnfmi.length
          ? res.data
          : {
              dtc_description: '',
              dtc_name: '',
              fmi: '',
              spn: '',
              spnfmi: '',
              component: '---',
              workbook_link: '---',
            };
        if (this.dtcResults.primary_icon_name) this.hasIcon = true;
        this.dtcLoading = false;
      } catch (error) {
        this.dtcLookupError = true;
        this.dtcLoading = false;
      }
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>
<style scoped></style>
