<template>
  <v-dialog v-model="removeDialog" class="dialog" width="400">
    <loader v-if="loading" />
    <v-card>
      <v-container>
        <v-row align="center" class="pl-4">
          <v-col align="left" cols="7"><h3>Deactivate Customer</h3></v-col>
          <v-col><v-spacer /></v-col>
        </v-row>
        <v-col justify="center" cols="12">
          Are you sure you want to deactivate <br />{{ customer.name }} ?
        </v-col>
        <v-row class="my-1">
          <v-col class="pt-0 hidden-xs-and-down"><v-spacer /></v-col>
          <v-col class="pt-0 mr-1" align="center" cols="3"
            ><v-btn plain color="primary" @click="removeDialog = false"
              >Close</v-btn
            ></v-col
          >
          <v-col class="pt-0 mr-3 mr-1" align="center" cols="3"
            ><v-btn color="primary" style="color: black" @click="removeCustomer"
              >Agree</v-btn
            ></v-col
          >
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import loader from '@/utilities/loader';
import _ from 'lodash';
import { removeCompany } from '@/api/company';

import { mapActions } from 'vuex';

export default {
  name: 'RemoveCustomer',
  components: { loader },
  props: ['customer'],
  data() {
    return {
      removeDialog: false,
      companySelect: 'Choose a customer to remove.',
      loading: false,
      interval: 3000,
    };
  },
  computed: {},
  watch: {
    // Watches for the change in removeDialog variable to emit the false variable back to parent to reset
    removeDialog: function (newValue) {
      if (!newValue) {
        this.removeDialog = false;
        this.$emit('closed');
      }
    },
  },
  async mounted() {
    this.removeDialog = true;
  },
  methods: {
    ...mapActions({
      updateSnack: 'updateSnack',
    }),
    async removeCustomer() {
      const vm = this;
      vm.loading = true;
      try {
        const res = await removeCompany(vm.customer.id);
        if (res.status === 200 || res.status === 204) {
          vm.loading = false;
          this.updateSnack({
            type: 'success',
            message: 'Customer Removed Successfully!',
          });
          vm.removeDialog = false;
        }
      } catch (error) {
        this.updateSnack({
          type: 'error',
          message: 'Error attempting to remove customer ...',
        });
      }
    },
  },
};
</script>

<style scoped></style>
