import axios from 'axios';

const BASE_URL = process.env.VUE_APP_API_PATH_BASE;

const runQuery = async (dropDown) => {
  for (let key in dropDown) {
    if (dropDown[key] == '') {
      dropDown[key] = 'blank';
    }
  }

  let {
    dateStart,
    dateEnd,
    actorType,
    actorId,
    severity,
    appId,
    method,
    limit,
  } = dropDown;

  return await axios.get(
    BASE_URL +
      `logger?startdate=${dateStart}&enddate=${dateEnd}&actortype=${actorType}&actorid=${actorId}&severity=${severity}&appid=${appId}&method=${encodeURIComponent(
        method
      )}&limit=${limit}`
  );
};

async function getUnknownVIN() {
  return await axios.get(BASE_URL + 'logger/unknownVIN');
}

export { runQuery, getUnknownVIN };
