<template>
  <v-data-table
    :no-data-text="noDataMsg"
    :headers="labels"
    item-key="truckNumber"
    :items="trucks"
    class="dark-background"
    show-expand
    :expanded.sync="expanded"
    @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
  >
    <template v-slot:expanded-item="{ item }">
      <td :colspan="labels.length + 1" style="padding: 0">
        <v-data-table
          :headers="expandedLabels"
          :items="item.truckDtcData"
          :hide-default-footer="true"
          :items-per-page="-1"
          class="elevation-1 shade"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Details</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:[`item.indicator`]="{ item }">
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <td class="mx-0 px-0 d-flex justify-center">
                  <icon-loader
                    v-if="item.indicator"
                    :icon-show="item.indicator"
                    class="px-0 mx-0"
                    :on="on"
                  />
                  <icon-loader
                    v-if="item.secondaryIndicator"
                    :icon-show="item.secondaryIndicator"
                    class="px-0 mx-0"
                    :on="on"
                  />
                </td>
              </template>
              <span>{{ item.tooltip }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </td>
    </template>
    <template v-slot:[`item.status`]="{ item }">
      <v-tooltip left>
        <template v-slot:activator="{ on }">
          <td :style="severityColor(item.faultSeverity, '#F2B046')">
            <v-icon
              large
              :style="severityColor(item.faultSeverity, '#F2B046')"
              v-on="on"
              >{{ getIcon(item.status) }}</v-icon
            >
          </td>
        </template>
        <span>{{ item.status }}</span>
      </v-tooltip>
    </template>
    <template v-slot:[`item.closestServiceLocation`]="{ item }">
      <td>
        <p class="mb-0 mt-1">
          {{ item.miles >= 0 ? item.closestServiceLocation : '—' }}
        </p>
        <p style="color: #888888; margin: 0">
          {{ item.miles >= 0 ? item.miles + ' miles' : '' }}
        </p>
      </td>
    </template>
    <template v-slot:[`item.faultSeverity`]="{ item }">
      <td :style="severityColor(item.faultSeverity, '#FFFFFF')">
        {{ item.faultSeverity }}
      </td>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <td
        style="color: #43b02a; cursor: pointer"
        @click="() => viewTruck(item)"
      >
        VIEW
      </td>
    </template>
  </v-data-table>
</template>

<script>
import { getIcon, severityColor } from '@/utilities/ExternalHealth.js';
import IconLoader from '@/utilities/IconLoader';
export default {
  name: 'FaultedTableTemplate',
  components: {
    'icon-loader': IconLoader,
  },
  props: {
    trucks: {
      type: Array,
      default() {
        return [];
      },
    },
    actions: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      labels: [
        { text: 'Truck Name', value: 'truckNumber' },
        { text: 'Status', value: 'status' },
        { text: 'Current Location', value: 'currentLocation' },
        { text: 'Closest Service Location', value: 'closestServiceLocation' },
        { text: 'Active Faults', value: 'activeFaults' },
        { text: 'Fault Severity', value: 'faultSeverity' },
      ],
      expandedLabels: [
        {
          text: 'Fault Present From',
          value: 'alertSource',
          width: '100px',
        },
        { text: 'Alert Name', value: 'alertName', width: '75px' },
        {
          text: 'Alert Description',
          value: 'alertDescription',
          width: '210px',
        },
        { text: 'DTC', value: 'spnFmi', width: '75px' },
        { text: 'Last Triggered', value: 'lastTriggered', width: '150px' },
        {
          text: 'Hyliion Indicator',
          value: 'indicator',
          width: '50px',
          align: 'center',
        },
      ],
      expanded: [],
      getIcon,
      severityColor,
      noDataMsg: 'There are no current trucks for this category',
    };
  },
  mounted() {
    if (this.actions) {
      this.labels.push({ text: 'Actions', value: 'actions' });
    }
  },
  methods: {
    viewTruck(truck) {
      this.$emit('viewClicked', truck);
    },
  },
};
</script>
<style scoped></style>
