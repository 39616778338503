<template>
  <v-banner id="lv_banner" v-model="active" rounded single-line>
    <v-icon class="pa-1 banner_icon">mdi-eye-check-outline</v-icon
    ><span class="pr-6 pl-2 py-2 mt-1 text-subtitle-2">
      You're now viewing the system as <b>{{ fleetInfo.name }}</b> in Live Fleet
      View.</span
    >
    <v-btn color="primary" style="color: #1a1a1a" @click="closeLiveView">
      Close View
    </v-btn>
  </v-banner>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
// TODO: Place LiveFleetBanner in an absolute area on the application to avoid multiple instantiations
export default {
  name: 'LiveFleetViewBanner',
  props: {
    fleetInfo: {
      required: true,
      default: () => {},
      type: Object,
    },
  },
  data() {
    return {
      active: true,
    };
  },
  computed: {
    ...mapGetters({
      companies: 'getCompanies',
    }),
  },
  methods: {
    ...mapMutations(['assignSelectedLiveFleet']),
    closeLiveView() {
      // Refresh the page
      location.reload();
      this.assignSelectedLiveFleet(this.companies.find((c) => c.id === 4));
    },
  },
};
</script>

<style scoped>
.banner_icon {
  background-color: #1a1a1a;
  border-radius: 3px;
}
#lv_banner {
  color: #1a1a1a;
  background-color: #e0e0e0;
  width: fit-content;
}
::v-deep .v-banner__wrapper {
  padding-left: 8px;
}
</style>
