var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mainPieContainer", style: `width: ${_vm.svgW + 10}px` },
    [
      _c("div", { staticClass: "chartTitle" }, [_vm._v(_vm._s(_vm.title))]),
      _c("div", [
        _c("svg", {
          staticStyle: { "margin-left": "5px", "margin-right": "5px" },
          attrs: { id: `pieSVG${_vm.id}` },
        }),
      ]),
      _vm.slicesBuilt
        ? _c(
            "div",
            { staticClass: "labels" },
            _vm._l(_vm.unsortedData, function (datum) {
              return _c(
                "div",
                {
                  key: datum.name + datum.value + datum.color,
                  staticClass: "labelDiv",
                },
                [
                  _c("span", { staticClass: "label" }, [
                    _c("svg", { attrs: { width: "20", height: "20" } }, [
                      _c("circle", {
                        style: `fill: ${datum.color}`,
                        attrs: { r: "10", cx: "10", cy: "10" },
                      }),
                    ]),
                    _c("div", { staticClass: "labelName" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            datum.name +
                              (datum.moreInfo ? ` ${datum.moreInfo}` : "")
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "labelValue",
                      style: `color: ${datum.color}`,
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            String(Math.round(datum.percent * 100)) + "%"
                          ) +
                          " "
                      ),
                    ]
                  ),
                ]
              )
            }),
            0
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }