<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <h3>{{ dtcsLength || 0 }} Results</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-container fluid>
          <v-row style="background-color: #272727; border-radius: 5px">
            <v-col v-for="(item, index) in icons" :key="index">
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <td class="mx-0 px-0">
                    <icon-loader
                      v-if="item.icon"
                      :icon-show="item.icon"
                      class="px-0 mx-0"
                      :on="on"
                    />
                  </td>
                </template>
                <span>{{ item.tooltip }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-skeleton-loader
          v-if="loading"
          type="table"
          elevation="2"
        ></v-skeleton-loader>
        <v-data-table
          v-else
          :headers="headers"
          :items="dtcs"
          :items-per-page="15"
          :expanded.sync="expanded"
          item-key="id"
          class="elevation-1"
          show-expand
        >
          <template v-slot:item.name="{ item }">
            <a
              v-if="item.workbook_link !== '---'"
              :href="item.workbook_link"
              target="_blank"
              >{{ item.name }}</a
            >
            <div v-if="item.workbook_link === '---'">{{ item.name }}</div>
          </template>
          <template v-slot:expanded-item="{ item }">
            <td
              :colspan="headers.length + 1"
              style="background-color: #272727; padding: 0"
            >
              <v-data-table
                :headers="expandedHeaders"
                :items="item.causes"
                :hide-default-footer="true"
                :items-per-page="-1"
                class="elevation-1 shade"
              />
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getERXDTCs } from '@/api/trucks';
import { getSpnfmiStates } from '@/api/dtc';

import { mapGetters, mapActions } from 'vuex';
import IconLoader from '@/utilities/IconLoader';
import { erxDTCLookup } from '@/utilities/health';

export default {
  name: 'ErxDtcSummary',
  components: {
    'icon-loader': IconLoader,
  },
  data() {
    return {
      expanded: [],
      headers: [
        { text: '', value: 'data-table-expand' },
        {
          text: 'Timestamp',
          value: 'timestamp',
        },
        { text: 'Name', value: 'name' },
        { text: 'Shown on CoPilot?', value: 'show_to_customer' },
        { text: 'Description', value: 'description' },
        { text: 'Source Address', value: 'source' },
        { text: 'Source Address Component', value: 'sa_component' },
        { text: 'SPN', value: 'spn' },
        { text: 'FMI', value: 'fmi' },
        { text: 'Occurence Count', value: 'occurrence_count' },
        {
          text: 'Component',
          value: 'component',
        },
      ],
      expandedHeaders: [
        { text: 'Possible Causes', value: 'name' },
        { text: 'Description', value: 'description' },
      ],
      dtcs: null,
      dtcsLength: 0,
      icons: [],
      loading: true,
    };
  },
  computed: {
    ...mapGetters({
      spnfmiStates: 'getSpnfmiStates',
    }),
  },
  async created() {
    if (!this.spnfmiStates.length) {
      const { data } = await getSpnfmiStates();
      await this.asyncUpdateSpnfmiStates(data);
    }
  },
  async mounted() {
    const res = await getERXDTCs();
    this.loading = false;
    this.dtcs = res.data.filter((t) => t.truck_id == this.$route.params.id);
    this.dtcs = this.mapDtcDetails(this.dtcs);
    this.dtcsLength = this.dtcs ? this.dtcs.length : 0;
  },
  methods: {
    ...mapActions(['asyncUpdateSpnfmiStates']),
    mapDtcDetails(dtc) {
      let { dtcs, icons } = erxDTCLookup(
        dtc.length ? dtc : [],
        this.spnfmiStates
      );
      this.icons = icons;
      return dtcs;
    },
  },
};
</script>
