import axios from 'axios';

const BASE_URL = process.env.VUE_APP_API_PATH_BASE;
const FUNCTION_BASE_URL = process.env.VUE_APP_API_ADX_DATA_BASE;

const getDropDown = async () => {
  return await axios.get(BASE_URL + `adx_data`);
};

const getADXRowCount = async function getADXRowCount(
  truck_id,
  start,
  end,
  granularity
) {
  let queryString = `?truckSelectionId= ${truck_id}&startDate=${start}&endDate=${end}&pickedGranularity=${granularity}`;
  const res = await axios.get(
    FUNCTION_BASE_URL + `adxDataRowCount${queryString}`
  );
  return res.data.rowCount;
};

//creating a POST to avoid 431 status code: Request Header Too Large
const postADXData = async (dropDown) => {
  return await axios.post(FUNCTION_BASE_URL + `exportAdxData`, dropDown, {
    responseType: 'blob',
  });
};

export { getDropDown, getADXRowCount, postADXData };
