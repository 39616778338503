var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "outerDiv" }, [
    _c("div", { staticClass: "mainDiv" }, [
      _c("div", { staticClass: "topRow" }, [
        _c("div", { staticClass: "title" }, [
          _c("div", { staticClass: "truck" }, [
            _vm._v(" Truck "),
            _vm.nameEdit
              ? _c(
                  "div",
                  { staticClass: "editButton" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "pb-1",
                        attrs: { icon: "", plain: "", color: "#43b02a" },
                        on: {
                          click: function ($event) {
                            return _vm.$emit("openNameChange")
                          },
                        },
                      },
                      [
                        _c("v-icon", [_vm._v("mdi-pencil")]),
                        _c("b", [_vm._v("Edit")]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _c("div", { staticClass: "truckNumber" }, [
            _vm._v(" " + _vm._s(_vm.truckName) + " "),
          ]),
        ]),
        _c("img", {
          attrs: {
            src: require("../../../../../public/images/assets/Truck.png"),
          },
        }),
      ]),
      _c("div", { staticClass: "flexCol" }, [
        _c("div", { staticClass: "flexRow" }, [
          _c("div", { staticClass: "statContainer" }, [
            _c(
              "div",
              { staticClass: "statIconContainer" },
              [
                _c(
                  "v-icon",
                  {
                    staticClass: "statIcon",
                    attrs: { color: "primary", large: "" },
                  },
                  [_vm._v("mdi-gas-station")]
                ),
              ],
              1
            ),
            _c("div", { staticClass: "statLabel" }, [
              _c("div", { staticClass: "statText" }, [_vm._v("Fuel Economy")]),
              _c("div", { staticClass: "statValue" }, [
                _vm._v(_vm._s(_vm.data?.fuelEconomy || "—") + " mpge"),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "statContainer" }, [
            _c(
              "div",
              { staticClass: "statIconContainer" },
              [
                _c(
                  "v-icon",
                  {
                    staticClass: "statIcon",
                    attrs: { color: "#F2B046", large: "" },
                  },
                  [_vm._v("mdi-alert")]
                ),
              ],
              1
            ),
            _c("div", { staticClass: "statLabel" }, [
              _c("div", { staticClass: "statText" }, [
                _vm._v("Journey Faults"),
              ]),
              _c("div", { staticClass: "statValue" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.data?.journeyFaults !== null
                        ? _vm.data?.journeyFaults
                        : "—"
                    ) +
                    " "
                ),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "flexRow" }, [
          _c("div", { staticClass: "statContainer" }, [
            _c("div", { staticClass: "statIconContainer" }, [
              _c(
                "svg",
                {
                  staticStyle: {
                    display: "block",
                    margin: "auto",
                    "margin-top": "20%",
                  },
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    width: "33",
                    height: "33",
                    viewBox: "0 0 33 33",
                    fill: "none",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M9.5006 20.5005L12 12.5",
                      stroke: "#2196F3",
                      "stroke-width": "1.5",
                    },
                  }),
                  _c("path", {
                    attrs: {
                      d: "M17.5 9.5L23.5 15",
                      stroke: "#2196F3",
                      "stroke-width": "1.5",
                    },
                  }),
                  _c("path", {
                    attrs: {
                      d: "M15.5625 12L21.0664 17",
                      stroke: "#2196F3",
                      "stroke-width": "1.5",
                    },
                  }),
                  _c("circle", {
                    attrs: { cx: "23", cy: "4", r: "2", fill: "#2196F3" },
                  }),
                  _c("circle", {
                    attrs: { cx: "8", cy: "25", r: "6", fill: "#2196F3" },
                  }),
                  _c("circle", {
                    attrs: { cx: "13", cy: "8", r: "6", fill: "#2196F3" },
                  }),
                  _c("circle", {
                    attrs: { cx: "25", cy: "20", r: "6", fill: "#2196F3" },
                  }),
                ]
              ),
            ]),
            _c("div", { staticClass: "statLabel" }, [
              _vm._m(0),
              _c("div", { staticClass: "statValue" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.data?.noxReduction !== null
                        ? _vm.data?.noxReduction
                        : "—"
                    ) +
                    " lbs "
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "statContainer" }, [
            _c(
              "div",
              { staticClass: "statIconContainer" },
              [
                _c(
                  "v-icon",
                  {
                    staticClass: "statIcon",
                    attrs: { color: "primary", large: "" },
                  },
                  [_vm._v("mdi-leaf")]
                ),
              ],
              1
            ),
            _c("div", { staticClass: "statLabel" }, [
              _vm._m(1),
              _c("div", { staticClass: "statValue" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.data?.co2Reduction !== null
                        ? _vm.data?.co2Reduction
                        : "—"
                    ) +
                    " lbs "
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "statText" }, [
      _vm._v("N"),
      _c("sub", [_vm._v("2")]),
      _vm._v("0 Reduction"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "statText" }, [
      _vm._v("CO"),
      _c("sub", [_vm._v("2")]),
      _vm._v(" Reduction"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }