var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _vm.loading ? _c("loader") : _vm._e(),
      _vm.openDialog
        ? _c(
            "v-dialog",
            {
              attrs: { width: "550", "content-class": "alertdialog" },
              model: {
                value: _vm.openDialog,
                callback: function ($$v) {
                  _vm.openDialog = $$v
                },
                expression: "openDialog",
              },
            },
            [
              _vm.showAlert
                ? _c("alert", {
                    attrs: {
                      "alert-type": _vm.alertType,
                      "alert-message": _vm.alertMessage,
                    },
                  })
                : _vm._e(),
              _c(
                "v-card",
                [
                  _c("v-card-title", {}, [_vm._v(" Edit DTC ")]),
                  _c("v-card-text", [_vm._v(" Edit an DTC Event ")]),
                  _c("v-divider"),
                  _c(
                    "v-form",
                    {
                      ref: "form",
                      model: {
                        value: _vm.valid,
                        callback: function ($$v) {
                          _vm.valid = $$v
                        },
                        expression: "valid",
                      },
                    },
                    [
                      _c(
                        "v-col",
                        { staticClass: "pl-10" },
                        [
                          _vm._v("Priority: "),
                          _c(
                            "v-radio-group",
                            {
                              attrs: { row: "" },
                              model: {
                                value: _vm.dtcData.priority,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dtcData, "priority", $$v)
                                },
                                expression: "dtcData.priority",
                              },
                            },
                            [
                              _c("v-radio", {
                                attrs: { label: "None", value: "0" },
                              }),
                              _c("v-radio", {
                                attrs: { label: "Low", value: "1" },
                              }),
                              _c("v-radio", {
                                attrs: { label: "Med", value: "2" },
                              }),
                              _c("v-radio", {
                                attrs: { label: "High", value: "3" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "input-row",
                          attrs: { justify: "center" },
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              placeholder: "Component",
                              rules: _vm.requiredRule,
                              label: "Component",
                              outlined: "",
                              color: "primary",
                            },
                            model: {
                              value: _vm.dtcData.component,
                              callback: function ($$v) {
                                _vm.$set(_vm.dtcData, "component", $$v)
                              },
                              expression: "dtcData.component",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "input-row",
                          attrs: { justify: "center" },
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              placeholder: "Created At",
                              label: "Created At",
                              outlined: "",
                              color: "primary",
                            },
                            model: {
                              value: _vm.dtcData.created_at,
                              callback: function ($$v) {
                                _vm.$set(_vm.dtcData, "created_at", $$v)
                              },
                              expression: "dtcData.created_at",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "input-row",
                          attrs: { justify: "center" },
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              placeholder: "Diagnostic Type",
                              label: "Diagnostic Type",
                              outlined: "",
                              color: "primary",
                            },
                            model: {
                              value: _vm.dtcData.diagnostic_type,
                              callback: function ($$v) {
                                _vm.$set(_vm.dtcData, "diagnostic_type", $$v)
                              },
                              expression: "dtcData.diagnostic_type",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "input-row",
                          attrs: { justify: "center" },
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              placeholder: "Diagnostic Unit",
                              label: "Diagnostic Unit",
                              outlined: "",
                              color: "primary",
                            },
                            model: {
                              value: _vm.dtcData.diagnostic_unit,
                              callback: function ($$v) {
                                _vm.$set(_vm.dtcData, "diagnostic_unit", $$v)
                              },
                              expression: "dtcData.diagnostic_unit",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "input-row",
                          attrs: { justify: "center" },
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              disabled: "",
                              placeholder: "DTC Hex",
                              label: "DTC Hex",
                              outlined: "",
                              color: "primary",
                            },
                            model: {
                              value: _vm.dtcData.dtc_hex,
                              callback: function ($$v) {
                                _vm.$set(_vm.dtcData, "dtc_hex", $$v)
                              },
                              expression: "dtcData.dtc_hex",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "input-row",
                          attrs: { justify: "center" },
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              placeholder: "Name",
                              label: "Name",
                              outlined: "",
                              color: "primary",
                            },
                            model: {
                              value: _vm.dtcData.ecu,
                              callback: function ($$v) {
                                _vm.$set(_vm.dtcData, "ecu", $$v)
                              },
                              expression: "dtcData.ecu",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "input-row",
                          attrs: { justify: "center" },
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              placeholder: "Fail Count Limit",
                              label: "Fail Count Limit",
                              outlined: "",
                              color: "primary",
                            },
                            model: {
                              value: _vm.dtcData.fail_count_limit,
                              callback: function ($$v) {
                                _vm.$set(_vm.dtcData, "fail_count_limit", $$v)
                              },
                              expression: "dtcData.fail_count_limit",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "input-row",
                          attrs: { justify: "center" },
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              placeholder: "Fail Timers",
                              label: "Fail Timers",
                              outlined: "",
                              color: "primary",
                            },
                            model: {
                              value: _vm.dtcData.fail_timers,
                              callback: function ($$v) {
                                _vm.$set(_vm.dtcData, "fail_timers", $$v)
                              },
                              expression: "dtcData.fail_timers",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "input-row",
                          attrs: { justify: "center" },
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              placeholder: "Failure Mode",
                              label: "Failure Mode",
                              outlined: "",
                              color: "primary",
                            },
                            model: {
                              value: _vm.dtcData.failure_mode,
                              callback: function ($$v) {
                                _vm.$set(_vm.dtcData, "failure_mode", $$v)
                              },
                              expression: "dtcData.failure_mode",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "input-row",
                          attrs: { justify: "center" },
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              placeholder: "Description",
                              label: "Description",
                              outlined: "",
                              color: "primary",
                            },
                            model: {
                              value: _vm.dtcData.hmi_string,
                              callback: function ($$v) {
                                _vm.$set(_vm.dtcData, "hmi_string", $$v)
                              },
                              expression: "dtcData.hmi_string",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "input-row",
                          attrs: { justify: "center" },
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              placeholder: "ID",
                              label: "ID",
                              outlined: "",
                              color: "primary",
                            },
                            model: {
                              value: _vm.dtcData.id,
                              callback: function ($$v) {
                                _vm.$set(_vm.dtcData, "id", $$v)
                              },
                              expression: "dtcData.id",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "input-row",
                          attrs: { justify: "center" },
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              placeholder: "Priority",
                              label: "Priority",
                              outlined: "",
                              color: "primary",
                            },
                            model: {
                              value: _vm.dtcData.priority,
                              callback: function ($$v) {
                                _vm.$set(_vm.dtcData, "priority", $$v)
                              },
                              expression: "dtcData.priority",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "input-row",
                          attrs: { justify: "center" },
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              placeholder: "Proposed Driver Alert String",
                              label: "Proposed Driver Alert String",
                              outlined: "",
                              color: "primary",
                            },
                            model: {
                              value: _vm.dtcData.proposed_driver_alert_string,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.dtcData,
                                  "proposed_driver_alert_string",
                                  $$v
                                )
                              },
                              expression:
                                "dtcData.proposed_driver_alert_string",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "input-row",
                          attrs: { justify: "center" },
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              placeholder: "Threshold",
                              label: "Threshold",
                              outlined: "",
                              color: "primary",
                            },
                            model: {
                              value: _vm.dtcData.threshold,
                              callback: function ($$v) {
                                _vm.$set(_vm.dtcData, "threshold", $$v)
                              },
                              expression: "dtcData.threshold",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "input-row",
                          attrs: { justify: "center" },
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              placeholder: "Trip Count Limit",
                              label: "Trip Count Limit",
                              outlined: "",
                              color: "primary",
                            },
                            model: {
                              value: _vm.dtcData.trip_count_limit,
                              callback: function ($$v) {
                                _vm.$set(_vm.dtcData, "trip_count_limit", $$v)
                              },
                              expression: "dtcData.trip_count_limit",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "input-row",
                          attrs: { justify: "center" },
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              placeholder: "Updated At",
                              label: "Updated At",
                              outlined: "",
                              color: "primary",
                            },
                            model: {
                              value: _vm.dtcData.updated_at,
                              callback: function ($$v) {
                                _vm.$set(_vm.dtcData, "updated_at", $$v)
                              },
                              expression: "dtcData.updated_at",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-checkbox", {
                            attrs: { label: "Active" },
                            model: {
                              value: _vm.dtcData.active,
                              callback: function ($$v) {
                                _vm.$set(_vm.dtcData, "active", $$v)
                              },
                              expression: "dtcData.active",
                            },
                          }),
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              staticClass: "secondary-button",
                              attrs: { color: "primary", text: "" },
                              on: { click: _vm.closeModal },
                            },
                            [_vm._v(" Cancel ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "primary-button",
                              attrs: {
                                color: "secondary",
                                disabled: !_vm.valid,
                                text: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.editDtc()
                                },
                              },
                            },
                            [_vm._v(" Edit DTC ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("v-data-table", {
        staticClass: "elevation-1",
        attrs: {
          headers: _vm.dataHeaders,
          items: _vm.dtcRows,
          "items-per-page": 5,
          "item-key": "id",
        },
        scopedSlots: _vm._u(
          [
            {
              key: `item.actions`,
              fn: function ({ item }) {
                return [
                  _c(
                    "v-menu",
                    {
                      staticStyle: { top: "312px", left: "300px" },
                      attrs: { "offset-y": "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on }) {
                              return [
                                _c("v-icon", _vm._g({}, on), [
                                  _vm._v("mdi-dots-vertical"),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _c(
                        "v-list",
                        _vm._l(_vm.actionItems, function (route, index) {
                          return _c(
                            "v-list-item",
                            {
                              key: index,
                              class: route.title,
                              on: {
                                click: function ($event) {
                                  return _vm.editOnClick(item, route.title)
                                },
                              },
                            },
                            [_vm._v(_vm._s(route.title))]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }