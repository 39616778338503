var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "px-0 my-1", attrs: { cols: "12" } },
            [
              [
                _c(
                  "section",
                  [
                    _c("highcharts", {
                      attrs: {
                        "constructor-type": "ganttChart",
                        options: _vm.chartOptions,
                      },
                    }),
                  ],
                  1
                ),
              ],
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }