<template>
  <div
    style="
      box-shadow: 0px 1px 8px 0px rgb(212, 14, 14),
        0px 2px 4px 0px rgb(210, 18, 18), 0px 1px 2px 0px rgb(228, 8, 8);
    "
  >
    <v-expansion-panels
      v-model="panels"
      style="
        width: 367px;
        box-shadow: 0px 1px 8px 0px rgb(212, 14, 14),
          0px 2px 4px 0px rgb(210, 18, 18), 0px 1px 2px 0px rgb(228, 8, 8);
      "
    >
      <v-expansion-panel
        style="
          background-color: #1a1a1a;
          box-shadow: 0px 1px 8px 0px rgb(212, 14, 14),
            0px 2px 4px 0px rgb(210, 18, 18), 0px 1px 2px 0px rgb(228, 8, 8);
        "
      >
        <v-expansion-panel-header class="title">
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              style="margin-right: 10px; margin-bottom: -5px"
            >
              <path
                d="M6.5 8.11C5.61 8.11 4.89 7.39 4.89 6.5C4.89 6.073 5.05962 5.66349 5.36156 5.36156C5.66349 5.05962 6.073 4.89 6.5 4.89C7.39 4.89 8.11 5.61 8.11 6.5C8.11 6.927 7.94038 7.33651 7.63844 7.63844C7.33651 7.94038 6.927 8.11 6.5 8.11ZM6.5 2C4 2 2 4 2 6.5C2 9.87 6.5 14.86 6.5 14.86C6.5 14.86 11 9.87 11 6.5C11 4 9 2 6.5 2ZM17.5 8.11C17.073 8.11 16.6635 7.94038 16.3616 7.63844C16.0596 7.33651 15.89 6.927 15.89 6.5C15.89 5.61 16.61 4.89 17.5 4.89C18.39 4.89 19.11 5.61 19.11 6.5C19.11 6.927 18.9404 7.33651 18.6384 7.63844C18.3365 7.94038 17.927 8.11 17.5 8.11ZM17.5 2C15 2 13 4 13 6.5C13 9.87 17.5 14.86 17.5 14.86C17.5 14.86 22 9.87 22 6.5C22 4 20 2 17.5 2ZM17.5 16C16.23 16 15.1 16.8 14.68 18H9.32C8.77 16.44 7.05 15.62 5.5 16.17C3.93 16.72 3.11 18.44 3.66 20C4.22 21.56 5.93 22.38 7.5 21.83C8.35 21.53 9 20.85 9.32 20H14.69C15.24 21.56 16.96 22.38 18.5 21.83C20.08 21.28 20.9 19.56 20.35 18C19.92 16.8 18.78 16 17.5 16ZM17.5 20.5C17.1022 20.5 16.7206 20.342 16.4393 20.0607C16.158 19.7794 16 19.3978 16 19C16 18.6022 16.158 18.2206 16.4393 17.9393C16.7206 17.658 17.1022 17.5 17.5 17.5C17.8978 17.5 18.2794 17.658 18.5607 17.9393C18.842 18.2206 19 18.6022 19 19C19 19.3978 18.842 19.7794 18.5607 20.0607C18.2794 20.342 17.8978 20.5 17.5 20.5Z"
                fill="#888888"
              />
            </svg>
          </span>
          <div class="truncate">
            {{ journeyName || 'Route' }}
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div class="panelContent">
            <div class="markerIcons">
              <v-icon color="#888888" style="margin-top: 10px"
                >mdi-map-marker</v-icon
              >
              <svg
                width="2"
                height="34"
                viewBox="0 0 2 34"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style="display: block; margin: auto"
              >
                <line
                  x1="1"
                  y1="4.37114e-08"
                  x2="0.999999"
                  y2="34"
                  stroke="#888888"
                  stroke-width="2"
                  stroke-dasharray="4 4"
                />
              </svg>
              <v-icon color="#EF5350" style="margin-bottom: 10px"
                >mdi-map-marker</v-icon
              >
            </div>
            <div class="dateLocationContainer">
              <div class="dateLocation">
                <div class="date">
                  Start date/Location:
                  <span class="inlineDate">{{ startDate }}</span>
                </div>
                <div class="location">{{ startLocation }}</div>
              </div>
              <br />
              <div class="dateLocation">
                <div class="date">
                  End date/Location:
                  <span class="inlineDate">{{ endDate }}</span>
                </div>
                <div class="location">
                  {{ endLocation }}
                </div>
              </div>
            </div>
          </div>
          <div>
            <v-icon color="#888888" class="mb-2">mdi-sign-direction</v-icon>
            <span class="miles">
              Journey Miles:
              <span class="inlineDate">{{ miles }}</span>
            </span>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import dateFormat from 'dateformat';

export default {
  props: {
    journey: {
      required: true,
      type: Object,
    },
    journeyName: {
      required: false,
      type: String,
      default: null,
    },
  },
  data() {
    return {
      panels: 0,
    };
  },
  computed: {
    startDate() {
      return dateFormat(this.journey?.start, 'mmm d, yyyy');
    },
    endDate() {
      return dateFormat(this.journey?.end, 'mmm d, yyyy');
    },
    startLocation() {
      return this.journey?.segments
        ? `${this.journey?.segments[0]?.startLocation?.city?.city}, ${this.journey?.segments[0]?.startLocation?.city?.state}`
        : '';
    },
    endLocation() {
      return this.journey?.segments
        ? `${
            this.journey?.segments[this.journey?.segments?.length - 1]
              ?.endLocation?.city?.city
          }, ${
            this.journey?.segments[this.journey?.segments?.length - 1]
              ?.endLocation?.city?.state
          }`
        : '';
    },
    miles() {
      return (
        Math.round(this.journey?.statistics?.totalKilometers / 1.60934) || ''
      );
    },
  },
};
</script>

<style scoped>
.mainDiv {
  width: 367px;
  height: 164px;
  border-radius: 4px;
  background-color: #1a1a1a;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.1),
    0px 2px 4px 0px rgba(0, 0, 0, 0.12), 0px 1px 2px 0px rgba(0, 0, 0, 0.16);
}
.title {
  color: #e0e0e0;
  font-family: Rawline;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
}
.panelContent {
  display: flex;
  flex-direction: row;
}
.markerIcons {
  display: flex;
  flex-direction: column;
}
.dateLocationContainer {
  margin-left: 10px;
}
.date {
  color: #888;
  font-family: Rawline;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.44px;
}
.miles {
  color: #888;
  font-family: Rawline;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0.44px;
  padding-left: 5px;
  padding-top: 5px;
}
.inlineDate {
  color: white;
}
.location {
  color: #e0e0e0;
  font-family: Rawline;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
}
.truncate {
  max-width: 186px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: clip;
}

::v-deep .v-expansion-panel-header > *:not(.v-expansion-panel-header__icon) {
  flex: 0 2 auto; /* Overwrite Vuetify default flex */
}
</style>
