var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", width: "550" },
      model: {
        value: _vm.removeDialog,
        callback: function ($$v) {
          _vm.removeDialog = $$v
        },
        expression: "removeDialog",
      },
    },
    [
      _vm.loading ? _c("loader") : _vm._e(),
      _c(
        "v-container",
        [
          _c(
            "v-row",
            { staticClass: "py-2", attrs: { align: "center" } },
            [
              _c("v-col", { attrs: { align: "center", cols: "6" } }, [
                _c("h3", [_vm._v("Edit Customer Permissions")]),
              ]),
              _c("v-col", [_c("v-spacer")], 1),
              _c(
                "v-col",
                { attrs: { align: "right", cols: "2" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("closed", false)
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { light: "" } }, [
                        _vm._v("mdi-close-circle "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c("CustomerPermissions", {
            attrs: {
              "customer-permissions": _vm.customer.external_permissions,
              "form-type": "Edit",
            },
            on: {
              cancel: function ($event) {
                return _vm.$emit("closed", false)
              },
              returnPermissions: _vm.updateCustomer,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }