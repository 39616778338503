<template>
  <div>
    <highcharts ref="elevation" :options="options"></highcharts>
  </div>
</template>

<script>
export default {
  name: 'ElevationGraph',
  props: ['data'],
  data() {
    return {
      options: {
        chart: {
          backgroundColor: '#000000',
          type: 'line',
          zoomType: 'x',
          name: '',
          height: '270px',
        },
        rangeSelector: {
          selected: 1,
        },
        title: {
          text: 'Elevation Profile',
          style: {
            color: '#FFFFFF',
          },
        },
        tooltip: {
          formatter: function () {
            return `${Math.round(this.x)} mi, ${Math.round(this.y)} ft`;
          },
        },
        legend: {
          itemStyle: {
            color: '#FFFFFF',
            fontWeight: 'bold',
          },
        },
        plotOptions: {
          series: {
            stacking: 'normal',
          },
        },
        xAxis: {
          title: {
            text: 'Distance (mi)',
            style: {
              color: '#FFFFFF',
            },
          },
          labels: {
            step: 1,
            style: {
              color: '#FFFFFF',
            },
          },
          lineColor: '#000000',
        },
        yAxis: [
          {
            labels: {
              format: '{value}',
            },
            title: {
              text: 'Elevation (ft)',
            },
          },
        ],
        setOptions: {
          width: '100%',
        },
        series: [
          {
            data: this.data,
            showInLegend: false,
          },
        ],
      },
    };
  },
};
</script>
