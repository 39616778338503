var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "body", attrs: { fluid: "" } },
    [
      _c(
        "v-snackbar",
        {
          attrs: { color: _vm.snackColor },
          model: {
            value: _vm.snack,
            callback: function ($$v) {
              _vm.snack = $$v
            },
            expression: "snack",
          },
        },
        [_vm._v(_vm._s(_vm.snackMessage))]
      ),
      _c(
        "v-row",
        { attrs: { align: "center" } },
        [
          _c("v-col", [
            _c("h1", { staticStyle: { "font-weight": "400" } }, [
              _vm._v("Cloud Health Summary"),
            ]),
            _c("div", { staticStyle: { color: "gray" } }, [
              _vm._v(" User Portal Version " + _vm._s(_vm.portalVersion)),
              _c("br"),
              _vm._v(" HyliionAPI " + _vm._s(_vm.apiVersion) + " "),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [_c("v-spacer", { staticClass: "hidden-sm-and-down" })],
            1
          ),
          _c("v-col", [
            _c("h2", { staticClass: "centerText stoplightTitle" }, [
              _vm._v("Data Ingestion"),
            ]),
            _c("img", {
              staticClass: "centerImg stoplight",
              attrs: {
                src: _vm.dataStoplight,
                width: "200px",
                height: "200px",
                alt: "circle",
              },
              on: {
                click: function ($event) {
                  return _vm.scrollTo("offset")
                },
              },
            }),
          ]),
          _c("v-col", [
            _c("h2", { staticClass: "centerText stoplightTitle" }, [
              _vm._v("API"),
            ]),
            _c("img", {
              staticClass: "centerImg stoplight",
              attrs: {
                src: _vm.apiStoplight,
                width: "200px",
                height: "200px",
                alt: "circle",
              },
            }),
          ]),
          _c("v-col", [
            _c("h2", { staticClass: "centerText stoplightTitle" }, [
              _vm._v("User Activity"),
            ]),
            _c("img", {
              staticClass: "centerImg stoplight",
              attrs: {
                src: _vm.userStoplight,
                width: "200px",
                height: "200px",
                alt: "circle",
              },
            }),
          ]),
          _c(
            "v-col",
            [_c("v-spacer", { staticClass: "hidden-sm-and-down" })],
            1
          ),
        ],
        1
      ),
      _c("br"),
      _vm.apiDataLoaded
        ? _c("highcharts", {
            ref: "line",
            attrs: { options: _vm.apiChartOptions },
          })
        : _c(
            "v-sheet",
            { staticClass: "pa-3", attrs: { rounded: "" } },
            [
              _c("v-skeleton-loader", {
                staticClass: "mx-auto",
                attrs: { type: "list-item" },
              }),
              _c("v-skeleton-loader", {
                staticClass: "mx-auto",
                attrs: { type: "image" },
              }),
            ],
            1
          ),
      _vm.dataLoadReady
        ? _c("data-load", {
            staticStyle: { "margin-top": "30px", "margin-bottom": "30px" },
            attrs: { data: _vm.dataLoadData },
          })
        : _c(
            "v-sheet",
            { staticClass: "pa-3", attrs: { rounded: "" } },
            [
              _c("v-skeleton-loader", {
                staticClass: "mx-auto",
                attrs: { type: "list-item" },
              }),
              _c("v-skeleton-loader", {
                staticClass: "mx-auto",
                attrs: { type: "image" },
              }),
            ],
            1
          ),
      _c("br"),
      _c("h2", { staticClass: "centerText slim" }, [
        _vm._v("Recenty Logged Errors"),
      ]),
      _c("v-virtual-scroll", {
        attrs: { items: _vm.errors, height: "450", "item-height": 64 },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ item }) {
              return [
                _c(
                  "v-list-item",
                  {
                    key: item.trace_id,
                    on: {
                      click: function ($event) {
                        return _vm.showErrorModal(item)
                      },
                    },
                  },
                  [
                    _c(
                      "v-list-item-action",
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              fab: "",
                              small: "",
                              depressed: "",
                              color:
                                item.severity === "WARNING"
                                  ? "warning"
                                  : "error",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.showErrorModal(item)
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(item.severity.slice(0, 3)) + " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-list-item-content",
                      [
                        _c("v-list-item-title", [
                          _c("h4", [_vm._v(_vm._s(item.label))]),
                          _vm._v(" " + _vm._s(item.detail.detail) + " "),
                        ]),
                      ],
                      1
                    ),
                    _c("v-list-item-action", [
                      _vm._v(" " + _vm._s(item.timestamp) + " "),
                    ]),
                  ],
                  1
                ),
                _c("v-divider"),
              ]
            },
          },
        ]),
      }),
      _c("br"),
      _c("br"),
      _c(
        "v-dialog",
        {
          attrs: { width: "600" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _vm._v(
                  " Label: " +
                    _vm._s(_vm.selectedError && _vm.selectedError.label) +
                    " "
                ),
              ]),
              _c("v-container", [
                _c("ul", [
                  _c("li", [
                    _vm._v(
                      "Severity: " +
                        _vm._s(_vm.selectedError && _vm.selectedError.severity)
                    ),
                  ]),
                  _c("li", [
                    _vm._v(
                      "Detail: " +
                        _vm._s(
                          _vm.selectedError && _vm.selectedError.detail.detail
                        )
                    ),
                  ]),
                  _c("li", [
                    _vm._v(
                      "Timestamp: " +
                        _vm._s(_vm.selectedError && _vm.selectedError.timestamp)
                    ),
                  ]),
                  _c("li", [
                    _vm._v(
                      "Severity: " +
                        _vm._s(_vm.selectedError && _vm.selectedError.severity)
                    ),
                  ]),
                  _c("li", [
                    _vm._v(
                      "App ID: " +
                        _vm._s(_vm.selectedError && _vm.selectedError.appID)
                    ),
                  ]),
                  _c("li", [
                    _vm._v(
                      "Actor Type: " +
                        _vm._s(
                          _vm.selectedError && _vm.selectedError.actor_type
                        )
                    ),
                  ]),
                  _c("li", [
                    _vm._v(
                      "Method: " +
                        _vm._s(_vm.selectedError && _vm.selectedError.method)
                    ),
                  ]),
                ]),
              ]),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", text: "" },
                      on: {
                        click: function ($event) {
                          _vm.dialog = false
                        },
                      },
                    },
                    [_vm._v(" Dismiss ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "div",
                { staticClass: "centerText" },
                [
                  _c("h2", { staticClass: "slim" }, [
                    _vm._v("Trucks sending data"),
                  ]),
                  _c("h3", { staticClass: "slim" }, [
                    _vm._v("Time Frame: " + _vm._s(_vm.timeDescription)),
                  ]),
                  _c(
                    "v-btn",
                    {
                      staticClass: "plusMinus",
                      attrs: { color: "secondary", small: "" },
                      on: { click: _vm.addDay },
                    },
                    [_vm._v("+")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "plusMinus",
                      attrs: { color: "secondary", small: "" },
                      on: { click: _vm.subtractDay },
                    },
                    [_vm._v("-")]
                  ),
                ],
                1
              ),
              _c("br"),
              _vm.perTruckData != null
                ? _c(
                    "v-progress-circular",
                    {
                      staticClass: "centerText",
                      attrs: {
                        id: "progressCircle",
                        rotate: 360,
                        size: 290,
                        width: 70,
                        value: _vm.perTruckData,
                        color: "#43B02A",
                      },
                    },
                    [_vm._v(_vm._s(_vm.perTruckData + "%"))]
                  )
                : _c("v-progress-circular", {
                    staticClass: "centerImg",
                    attrs: { indeterminate: "", size: 290, color: "#43B02A" },
                  }),
            ],
            1
          ),
          _c("v-col", [
            _c("div", { attrs: { id: "pieWrapper" } }, [
              _c("h2", { staticClass: "centerText slim" }, [
                _vm._v("Truck Configurations"),
              ]),
              _c(
                "div",
                { attrs: { id: "pieContainer" } },
                [
                  _vm.pieDataReceived
                    ? _c("pie-chart", {
                        attrs: {
                          id: "pieChart",
                          data: _vm.pieData,
                          options: _vm.pieOptions,
                        },
                      })
                    : _c("v-progress-circular", {
                        staticClass: "centerImg",
                        attrs: {
                          indeterminate: "",
                          size: 290,
                          color: "#43B02A",
                        },
                      }),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
      _c("br"),
      _c("br"),
      _c(
        "div",
        { staticClass: "centerText" },
        [
          _c("h2", { staticClass: "slim" }, [_vm._v("Successful API Calls")]),
          _c("h3", { staticClass: "slim" }, [
            _vm._v("Time Frame: " + _vm._s(_vm.apiTimeDescription)),
          ]),
          _c(
            "v-btn",
            {
              staticClass: "plusMinus",
              attrs: { color: "secondary", small: "" },
              on: { click: _vm.apiAdd },
            },
            [_vm._v("+")]
          ),
          _c(
            "v-btn",
            {
              staticClass: "plusMinus",
              attrs: { color: "secondary", small: "" },
              on: { click: _vm.apiSubtract },
            },
            [_vm._v("-")]
          ),
        ],
        1
      ),
      _c("br"),
      _vm.apiPercentage
        ? _c(
            "v-progress-circular",
            {
              staticClass: "centerText",
              attrs: {
                id: "progressCircle",
                rotate: 360,
                size: 290,
                width: 70,
                value: _vm.apiPercentage,
                color: "#43B02A",
              },
            },
            [_vm._v(_vm._s(_vm.apiPercentage + "%"))]
          )
        : _c("v-progress-circular", {
            staticClass: "centerImg",
            attrs: { indeterminate: "", size: 290, color: "#43B02A" },
          }),
      _c("br"),
      _c("br"),
      _c("offset-form", { attrs: { "trigger-snack": _vm.triggerSnack } }),
      _c(
        "v-navigation-drawer",
        {
          attrs: { fixed: "", right: "", temporary: "", width: "360" },
          model: {
            value: _vm.drawer,
            callback: function ($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer",
          },
        },
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                { attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "mb-2", attrs: { align: "right" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function ($event) {
                              _vm.drawer = false
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { light: "" } }, [
                            _vm._v("mdi-close-circle "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("p", { staticStyle: { "text-align": "center" } }, [
                _vm._v("No content"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }