var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", width: "750" },
      model: {
        value: _vm.editTriggerDialog,
        callback: function ($$v) {
          _vm.editTriggerDialog = $$v
        },
        expression: "editTriggerDialog",
      },
    },
    [
      _vm.loading ? _c("loader") : _vm._e(),
      _c(
        "v-card",
        [
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c("h3", [_vm._v("Edit Trigger")]),
              _c(
                "v-form",
                {
                  ref: "form",
                  attrs: { "lazy-validation": "" },
                  model: {
                    value: _vm.valid,
                    callback: function ($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid",
                  },
                },
                [
                  _c(
                    "v-text-field",
                    {
                      staticStyle: {
                        "margin-top": "15px",
                        "margin-bottom": "-25px",
                      },
                      attrs: {
                        placeholder: "Name",
                        rules: _vm.nameRules,
                        required: "",
                        outlined: "",
                      },
                      model: {
                        value: _vm.name,
                        callback: function ($$v) {
                          _vm.name = $$v
                        },
                        expression: "name",
                      },
                    },
                    [_vm._v(" Name")]
                  ),
                  _c(
                    "v-radio-group",
                    {
                      attrs: { row: "", mandatory: "", required: "" },
                      model: {
                        value: _vm.publicOrPrivate,
                        callback: function ($$v) {
                          _vm.publicOrPrivate = $$v
                        },
                        expression: "publicOrPrivate",
                      },
                    },
                    [
                      _c("v-radio", {
                        attrs: { label: "Public", value: "public" },
                      }),
                      _c("v-radio", {
                        attrs: { label: "Private", value: "private" },
                      }),
                    ],
                    1
                  ),
                  _c("v-textarea", {
                    attrs: { label: "Description", outlined: "" },
                    model: {
                      value: _vm.description,
                      callback: function ($$v) {
                        _vm.description = $$v
                      },
                      expression: "description",
                    },
                  }),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "right",
                      },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-2",
                          on: {
                            click: function ($event) {
                              _vm.editTriggerDialog = false
                            },
                          },
                        },
                        [_vm._v("Cancel")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", disabled: !_vm.valid },
                          on: {
                            click: function ($event) {
                              return _vm.submitTrigger()
                            },
                          },
                        },
                        [_vm._v("Save")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }