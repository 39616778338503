<template>
  <v-container class="pa-0">
    <v-card class="secondary">
      <v-card-title>Restrict Access to Features</v-card-title>
      <v-card-subtitle
        >Control the availability of specifric features, allowing them to either
        be enabled or disabled for different user roles or
        fleets</v-card-subtitle
      >
      <v-row class="pa-1">
        <v-col cols="2" class="d-flex align-center justify-center">
          <v-checkbox v-model="performance" class=""></v-checkbox>
        </v-col>
        <v-col cols="10">
          <h4>Performance</h4>
          <span class="secondary-text"
            >Configure and optimize the system's efficiency, responsiveness, and
            resource utilization to enhance the overall experience within the
            Hub.</span
          >
        </v-col>
      </v-row>
      <v-row class="pa-1">
        <v-col cols="2" class="d-flex align-center justify-center">
          <v-checkbox v-model="health"></v-checkbox>
        </v-col>
        <v-col cols="10">
          <h4>Health</h4>
          <span class="secondary-text"
            >Monitor system status, uptime, and overall performance to ensure
            optimal operation within the Hub.</span
          >
        </v-col>
      </v-row>
      <v-row class="pa-2">
        <v-col class="mt-2 hidden-xs-and-down"><v-spacer /></v-col>
        <v-col class="mt-2" align="center" cols="3"
          ><v-btn plain color="primary" @click="emitCancel">{{
            backButton
          }}</v-btn></v-col
        >
        <v-col class="mt-2" align="center" cols="4">
          <!-- TODO: Add emitter to disable button on click to prevent multiple submissions-->
          <v-btn
            color="primary"
            style="color: black"
            @click="emitCompanyPermissions"
            >{{ submitButton }}</v-btn
          ></v-col
        >
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import _ from 'lodash';
export default {
  name: 'CustomerPermissions',
  components: {},
  props: {
    customerPermissions: {
      type: Object,
      required: true,
      default: () => {},
    },
    formType: {
      type: String,
      required: false,
      default: 'Add',
    },
  },
  data() {
    return {
      performance: false,
      health: false,
      backButton: 'Back',
      submitButton: 'Submit',
    };
  },
  watch: {},
  async mounted() {
    if (this.formType === 'Edit') {
      this.backButton = 'Cancel';
      this.submitButton = 'Update';
    }
    if (!_.isEmpty(this.customerPermissions)) {
      if (this.customerPermissions.health) {
        this.health = true;
      }
      if (this.customerPermissions.performance) {
        this.performance = true;
      }
    }
  },
  methods: {
    ...mapActions({
      updateSnack: 'updateSnack',
    }),
    emitCancel() {
      this.$emit('cancel');
    },
    emitCompanyPermissions() {
      // Initial Permissions Object that will change as we add more permissions
      const permissionsObject = {
        health: this.health,
        performance: this.performance,
      };
      this.$emit('returnPermissions', permissionsObject);
    },
  },
};
</script>

<style scoped></style>
