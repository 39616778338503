var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _vm.loading ? _c("loader") : _vm._e(),
      _c(
        "v-card",
        {
          staticStyle: { "background-color": "#272727" },
          attrs: { elevation: "0" },
        },
        [
          _c("v-card-title", { staticClass: "text-h5" }, [
            _vm._v("Individual"),
          ]),
          _c("div", { staticClass: "setting" }, [
            _c("div", { staticClass: "settingText" }, [
              _c("div", { staticClass: "settingTitle" }, [
                _vm._v("System Units"),
              ]),
              _c("div", { staticClass: "settingDescription" }, [
                _vm._v(" Select preferred measurement units. "),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "fieldContainer" },
              [
                _c(
                  "v-btn-toggle",
                  {
                    staticClass: "tfield",
                    model: {
                      value: _vm.systemUnits,
                      callback: function ($$v) {
                        _vm.systemUnits = $$v
                      },
                      expression: "systemUnits",
                    },
                  },
                  [
                    _c(
                      "v-btn",
                      {
                        style:
                          _vm.systemUnits === "metric"
                            ? "background-color: #2B8F14; color: black"
                            : "",
                        attrs: { value: "metric" },
                      },
                      [_vm._v("metric")]
                    ),
                    _c(
                      "v-btn",
                      {
                        style:
                          _vm.systemUnits === "us/imperial"
                            ? "background-color: #2B8F14; color: black"
                            : "",
                        attrs: { value: "us/imperial" },
                      },
                      [_vm._v("us/imperial")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "setting" }, [
            _c("div", { staticClass: "settingText" }, [
              _c("div", { staticClass: "settingTitle" }, [
                _vm._v("Fuel economy units"),
              ]),
              _c("div", { staticClass: "settingDescription" }, [
                _vm._v(" Select preferred units for fuel economy. "),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "fieldContainer" },
              [
                _c(
                  "v-btn-toggle",
                  {
                    staticClass: "tfield",
                    model: {
                      value: _vm.fuelEconomyPref,
                      callback: function ($$v) {
                        _vm.fuelEconomyPref = $$v
                      },
                      expression: "fuelEconomyPref",
                    },
                  },
                  [
                    _c(
                      "v-btn",
                      {
                        style:
                          _vm.fuelEconomyPref === "mpge"
                            ? "background-color: #2B8F14; color: black"
                            : "",
                        attrs: { value: "mpge" },
                      },
                      [_vm._v("mpge")]
                    ),
                    _c(
                      "v-btn",
                      {
                        style:
                          _vm.fuelEconomyPref === "km/l-e"
                            ? "background-color: #2B8F14; color: black"
                            : "",
                        attrs: { value: "km/l-e" },
                      },
                      [_vm._v("km/l-e")]
                    ),
                    _c(
                      "v-btn",
                      {
                        style:
                          _vm.fuelEconomyPref === "l-e/100"
                            ? "background-color: #2B8F14; color: black"
                            : "",
                        attrs: { value: "l-e/100" },
                      },
                      [_vm._v("l-e/100")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "setting" }, [
            _c("div", { staticClass: "settingText" }, [
              _c("div", { staticClass: "settingTitle" }, [
                _vm._v("Date Format"),
              ]),
              _c("div", { staticClass: "settingDescription" }, [
                _vm._v("Select your desired date format."),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "fieldContainer" },
              [
                _c("v-select", {
                  staticClass: "tfield",
                  attrs: { items: _vm.dateItems, outlined: "" },
                  model: {
                    value: _vm.datePref,
                    callback: function ($$v) {
                      _vm.datePref = $$v
                    },
                    expression: "datePref",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "setting" }, [
            _c("div", { staticClass: "settingText" }, [
              _c("div", { staticClass: "settingTitle" }, [
                _vm._v("Time Format"),
              ]),
              _c("div", { staticClass: "settingDescription" }, [
                _vm._v("Select your desired time format."),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "fieldContainer" },
              [
                _c("v-select", {
                  staticClass: "tfield",
                  attrs: { items: _vm.timeItems, outlined: "" },
                  model: {
                    value: _vm.timePref,
                    callback: function ($$v) {
                      _vm.timePref = $$v
                    },
                    expression: "timePref",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "setting" }, [
            _c("div", { staticClass: "settingText" }, [
              _c("div", { staticClass: "settingTitle" }, [_vm._v("Time Zone")]),
              _c("div", { staticClass: "settingDescription" }, [
                _vm._v("Select your desired time zone."),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "fieldContainer" },
              [
                _c("v-select", {
                  staticClass: "tfield",
                  attrs: {
                    items: _vm.timeZoneItems,
                    "item-text": "utc",
                    "return-object": "",
                    outlined: "",
                  },
                  model: {
                    value: _vm.timeZonePref,
                    callback: function ($$v) {
                      _vm.timeZonePref = $$v
                    },
                    expression: "timeZonePref",
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "actionBtns" },
            [
              _c(
                "v-btn",
                {
                  staticStyle: { "margin-right": "20px", color: "#2b8f14" },
                  on: { click: _vm.syncUserPreferences },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "v-btn",
                {
                  staticStyle: { color: "black" },
                  attrs: { color: "primary" },
                  on: { click: _vm.updateUserPrefs },
                },
                [_vm._v("Save changes")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }