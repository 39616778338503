<template>
  <div>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      :return-value.sync="calDate"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="textDate"
          v-mask="'##/##/####'"
          :label="label"
          type="text"
          outlined
          append-icon="mdi-calendar"
          v-bind="attrs"
          @click:append="on.click"
        >
        </v-text-field>
      </template>
      <v-date-picker v-model="calDate">
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
        <v-btn text color="primary" @click="saveCalDate"> OK </v-btn>
      </v-date-picker>
    </v-menu>
    <div v-if="showGuide" class="dateGuide">MM/DD/YYYY</div>
  </div>
</template>

<script>
import dateFormat from 'dateformat';

export default {
  name: 'DateInput',
  props: {
    label: {
      type: String,
      required: false,
      default: '',
    },
    /* function defined in outer scope to get model variable
      in outer scope, might look like the following:  
          <date-input
            label="Start Date"
            :model="startDate"
            :sendDate="(d) => (startDate = d)"
          ></date-input>
    */
    sendDate: {
      type: Function,
      required: true,
    },
    model: {
      type: undefined,
      required: true,
    },
    showGuide: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      menu: false,
      textDate: null,
      calDate: null,
    };
  },
  watch: {
    model() {
      this.setModel();
    },
    textDate() {
      // autocomplete logic
      if (parseInt(this.textDate[0]) > 1) {
        this.textDate = '0' + this.textDate + '/';
      }
      if (parseInt(this.textDate[3]) > 3) {
        this.textDate =
          this.textDate.substr(0, 2) + '/0' + this.textDate[3] + '/';
      }
      // sync with this.calDate
      if (this.textDate.length === 10) {
        this.calDate = dateFormat(this.textDate, 'yyyy-mm-dd', true); // Sends in UTC By default
        this.sendDate(this.textDate);
      }
    },
  },
  mounted() {
    this.setModel();
  },
  methods: {
    saveCalDate() {
      this.$refs.menu.save(this.calDate);
      this.textDate = dateFormat(this.calDate, 'mm/dd/yyyy', true); // Sends in UTC By default
      this.sendDate(this.textDate);
    },
    setModel() {
      this.textDate = dateFormat(this.model, 'mm/dd/yyyy', true); // Sends in UTC By default
      this.sendDate(this.textDate);
    },
  },
};
</script>

<style scoped>
.dateGuide {
  margin-top: -27px;
  margin-bottom: 20px;
  margin-left: 10px;
  font-size: 13px;
  color: rgb(171, 171, 171);
}
</style>
