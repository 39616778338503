<template>
  <v-container fluid>
    <v-row
      ><v-col class="pl-0 pb-0"
        ><h3>{{ inShopTrucks ? inShopTrucks.length : 0 }} Results</h3></v-col
      ></v-row
    >
    <v-row>
      <v-col class="pa-0" cols="12">
        <in-shop-table :trucks="inShopTrucks" @viewClicked="viewTruck" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import InShopTableTemplate from './TableUtils/InShopTableTemplate';
export default {
  name: 'InShopTable',
  components: {
    'in-shop-table': InShopTableTemplate,
  },
  props: {
    inShopTrucks: {
      type: Array,
      required: true,
    },
    toggleDetails: {
      type: Function,
      default: () => {},
    },
    setDetails: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  methods: {
    viewTruck(truck) {
      this.toggleDetails(true);
      this.setDetails(truck);
    },
  },
};
</script>
<style scoped></style>
