var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    {
      staticClass: "body",
      attrs: { "grid-list-md": "", "text-xs-center": "" },
    },
    [
      _c(
        "v-row",
        { attrs: { align: "center" } },
        [
          _c("v-col", { attrs: { cols: "2" } }, [_c("v-spacer")], 1),
          _c(
            "v-col",
            { staticClass: "mb-1", attrs: { cols: "12", lg: "4", xl: "4" } },
            [
              _c("h1", { staticStyle: { "font-weight": "400" } }, [
                _vm._v("ADX Data"),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm.truckSelection.length == 0 ? _c("loader") : _vm._e(),
      _c(
        "v-row",
        { staticClass: "mb-2" },
        [
          _c("v-col", { attrs: { cols: "2" } }, [_c("v-spacer")], 1),
          _c(
            "v-col",
            { attrs: { cols: "12", lg: "4", xl: "4" } },
            [
              _c("v-select", {
                attrs: {
                  items: _vm.truckSelection,
                  loading: _vm.isSelectLoading,
                  "item-text": _vm.getFieldText,
                  "item-value": _vm.getFieldText,
                  label: "Truck Selection",
                  "persistent-hint": "",
                  "return-object": "",
                  outlined: "",
                  rules: [(v) => !!v || "Required!"],
                  required: "",
                },
                on: {
                  click: function ($event) {
                    _vm.exportClicked = false
                  },
                },
                model: {
                  value: _vm.dropDown.truckSelection,
                  callback: function ($$v) {
                    _vm.$set(_vm.dropDown, "truckSelection", $$v)
                  },
                  expression: "dropDown.truckSelection",
                },
              }),
            ],
            1
          ),
          _c("v-col", { attrs: { cols: "1" } }, [_c("v-spacer")], 1),
          _c(
            "v-col",
            { attrs: { cols: "2" } },
            [
              _vm._v(" Signal Name Source "),
              _c(
                "v-radio-group",
                {
                  attrs: { inline: "" },
                  model: {
                    value: _vm.dropDown.signalNameSource,
                    callback: function ($$v) {
                      _vm.$set(_vm.dropDown, "signalNameSource", $$v)
                    },
                    expression: "dropDown.signalNameSource",
                  },
                },
                [
                  _c("v-radio", {
                    attrs: { label: "ADX Names", value: "ADX" },
                  }),
                  _c("v-radio", {
                    attrs: { label: "DBC Names", value: "DBC" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "2" } },
            [
              _vm._v(" File Export Type "),
              _c(
                "v-radio-group",
                {
                  attrs: { inline: "" },
                  model: {
                    value: _vm.dropDown.fileExportType,
                    callback: function ($$v) {
                      _vm.$set(_vm.dropDown, "fileExportType", $$v)
                    },
                    expression: "dropDown.fileExportType",
                  },
                },
                [
                  _c("v-radio", { attrs: { label: "CSV", value: "CSV" } }),
                  _c("v-radio", {
                    attrs: { label: "CSV-MF4", value: "CSV-MF4" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "2" } }, [_c("v-spacer")], 1),
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "12", lg: "4", xl: "4" } },
            [
              _c("v-datetime-picker", {
                attrs: { label: _vm.startDateLabel },
                on: { input: _vm.saveDates },
                model: {
                  value: _vm.dropDown.startDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.dropDown, "startDate", $$v)
                  },
                  expression: "dropDown.startDate",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "12", lg: "4", xl: "4" } },
            [
              _c("v-datetime-picker", {
                attrs: { label: _vm.endDateLabel },
                on: { input: _vm.saveDates },
                model: {
                  value: _vm.dropDown.endDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.dropDown, "endDate", $$v)
                  },
                  expression: "dropDown.endDate",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "2" } }, [_c("v-spacer")], 1),
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "12", lg: "8", xl: "8" } },
            [
              _c("v-select", {
                attrs: {
                  items: _vm.getGranularity,
                  "item-text": "granularity",
                  "item-value": "granularity",
                  label: "Granularity",
                  "persistent-hint": "",
                  "return-object": "",
                  outlined: "",
                  rules: [(v) => !!v || "Required!"],
                  required: "",
                },
                on: {
                  click: function ($event) {
                    _vm.exportClicked = false
                  },
                },
                model: {
                  value: _vm.dropDown.pickedGranularity,
                  callback: function ($$v) {
                    _vm.$set(_vm.dropDown, "pickedGranularity", $$v)
                  },
                  expression: "dropDown.pickedGranularity",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "2" } }, [_c("v-spacer")], 1),
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "12", lg: "8", xl: "8" } },
            [
              _vm.dropDown.truckSelection == undefined
                ? _c("p", { staticStyle: { color: "#43b02a" } }, [
                    _vm._v(" Please select a truck. "),
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticStyle: { display: "flex", "align-items": "baseline" } },
                [
                  _c("p", { style: _vm.displayRowCountColor }, [
                    _vm._v(" " + _vm._s(_vm.displayRowCountText) + " "),
                  ]),
                  _vm.isRowCountLoading == true
                    ? _c(
                        "div",
                        { staticStyle: { "margin-left": "20px" } },
                        [
                          _c("v-progress-circular", {
                            attrs: {
                              "model-value": "100",
                              color: "primary",
                              indeterminate: "",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", [_c("v-spacer")], 1),
          _c(
            "v-col",
            { staticClass: "py-0", attrs: { cols: "12", lg: "3", xl: "3" } },
            [
              _vm.isDataLoading == true
                ? _c(
                    "div",
                    { attrs: { justify: "center", align: "center" } },
                    [
                      _c("v-progress-circular", {
                        attrs: {
                          "model-value": "100",
                          color: "primary",
                          indeterminate: "",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-btn",
                {
                  attrs: {
                    disabled:
                      _vm.dropDown.truckSelection === undefined ||
                      // dropDown.selectedSignals.length === 0 ||
                      _vm.count > _vm.maxRowsThreshold ||
                      _vm.count == 0 ||
                      _vm.exportClicked === true,
                    color: "primary",
                    block: "",
                    plain: "",
                  },
                  on: { click: _vm.exportADXCSV },
                },
                [
                  _c("v-icon", { staticClass: "pr-1" }, [
                    _vm._v("mdi-download"),
                  ]),
                  _vm._v(" Export "),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-col", [_c("v-spacer")], 1),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }