var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "truck" } },
    [
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", xl: "3", lg: "3" } },
                [
                  _c("v-breadcrumbs", {
                    staticStyle: { padding: "5px 0 0 5px" },
                    attrs: { items: _vm.addCrumb, divider: "/" },
                  }),
                ],
                1
              ),
              _c("v-col", [_c("v-spacer")], 1),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mt-0 mb-6" },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "12", lg: "12", xl: "12" } },
                [
                  _c(
                    "v-expansion-panels",
                    {
                      staticClass: "border",
                      model: {
                        value: _vm.panelIndex,
                        callback: function ($$v) {
                          _vm.panelIndex = $$v
                        },
                        expression: "panelIndex",
                      },
                    },
                    [
                      _c(
                        "v-expansion-panel",
                        [
                          _c(
                            "v-expansion-panel-header",
                            [
                              _c(
                                "v-row",
                                [
                                  _c("v-col", [
                                    _c("h2", [_vm._v("Truck Details")]),
                                    _c("br"),
                                    _vm.panelIndex === undefined
                                      ? _c(
                                          "span",
                                          { staticClass: "secondary-text" },
                                          [
                                            _vm._v(
                                              " Expand to view specific truck details"
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-expansion-panel-content",
                            [
                              !_vm.isEx && _vm.truckData && !_vm.loading
                                ? _c("erx-details", {
                                    attrs: {
                                      "erx-data": _vm.truckData,
                                      icons: _vm.icons,
                                    },
                                  })
                                : _vm.isEx && !_vm.loading
                                ? _c("ex-details", {
                                    attrs: { "ex-data": _vm.truckData },
                                  })
                                : _vm.loading
                                ? [
                                    _c("v-skeleton-loader", {
                                      attrs: { type: "card", elevation: "2" },
                                    }),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }