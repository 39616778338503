var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-form",
        { ref: "form", staticStyle: { margin: "20px" } },
        [
          _c(
            "div",
            { staticStyle: { display: "flex" } },
            [
              _c("v-select", {
                staticClass: "selects",
                attrs: {
                  items: _vm.names,
                  "return-object": "",
                  "item-text": "name",
                  "item-value": "id",
                  label: "Name",
                  outlined: "",
                },
                model: {
                  value: _vm.currentTemplate,
                  callback: function ($$v) {
                    _vm.currentTemplate = $$v
                  },
                  expression: "currentTemplate",
                },
              }),
              _vm.currentTemplate && _vm.currentTemplate.id === -1
                ? _c("v-text-field", {
                    attrs: {
                      rules: [_vm.rules.required],
                      label: "Name",
                      outlined: "",
                    },
                    model: {
                      value: _vm.name,
                      callback: function ($$v) {
                        _vm.name = $$v
                      },
                      expression: "name",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { display: "flex" } },
            [
              _c("v-select", {
                staticClass: "selects",
                attrs: {
                  items: _vm.visibilities,
                  rules: [_vm.rules.required],
                  label: "Visibility",
                  outlined: "",
                },
                model: {
                  value: _vm.visibility,
                  callback: function ($$v) {
                    _vm.visibility = $$v
                  },
                  expression: "visibility",
                },
              }),
              _c("div", { staticStyle: { "margin-top": "15px" } }, [
                _vm._v("Author: " + _vm._s(_vm.authorName)),
              ]),
              _vm.currentTemplate && _vm.currentTemplate.id > 0
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        "margin-top": "15px",
                        "margin-left": "60px",
                      },
                    },
                    [
                      _vm._v(
                        " Last Updated: " +
                          _vm._s(this.currentTemplate.updated_at) +
                          " "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("v-textarea", {
            attrs: { label: "Description", outlined: "" },
            model: {
              value: _vm.description,
              callback: function ($$v) {
                _vm.description = $$v
              },
              expression: "description",
            },
          }),
          _c("v-data-table", {
            ref: "table",
            attrs: {
              headers: _vm.headers,
              items: _vm.graphs,
              "items-per-page": -1,
              rules: [_vm.rules.graphsRequired],
              "hide-default-footer": "",
            },
            scopedSlots: _vm._u([
              {
                key: "body",
                fn: function (props) {
                  return [
                    _c(
                      "draggable",
                      {
                        staticStyle: { cursor: "pointer" },
                        attrs: { list: props.items, tag: "tbody" },
                        on: { end: _vm.onDrop },
                      },
                      _vm._l(props.items, function (item, index) {
                        return _c("data-table-row-handler", {
                          key: index,
                          attrs: { item: item, headers: _vm.headers },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: `item.signals`,
                                fn: function ({ item }) {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.getSignalNames(item.signals)
                                        ) +
                                        " "
                                    ),
                                  ]
                                },
                              },
                              {
                                key: `item.start_trigger`,
                                fn: function ({ item }) {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.getTriggerName(item.start_trigger)
                                        ) +
                                        " "
                                    ),
                                  ]
                                },
                              },
                              {
                                key: `item.end_trigger`,
                                fn: function ({ item }) {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.getTriggerName(item.end_trigger)
                                        ) +
                                        " "
                                    ),
                                  ]
                                },
                              },
                              {
                                key: `item.actions`,
                                fn: function ({ item }) {
                                  return [
                                    _c(
                                      "v-menu",
                                      {
                                        staticStyle: {
                                          top: "312px",
                                          left: "300px",
                                        },
                                        attrs: { "offset-y": "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on }) {
                                                return [
                                                  _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      {
                                                        staticStyle: {
                                                          "margin-left": "17px",
                                                        },
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _vm._v(
                                                        "mdi-dots-vertical"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _c(
                                          "v-list",
                                          _vm._l(
                                            _vm.actionItems,
                                            function (action, index) {
                                              return _c(
                                                "v-list-item",
                                                {
                                                  key: index,
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.graphAction(
                                                        action,
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v(_vm._s(action))]
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        })
                      }),
                      1
                    ),
                    _c("tr", [
                      _c("td", { attrs: { colspan: "6" } }),
                      _c(
                        "td",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: { click: _vm.addGraph },
                            },
                            [_vm._v("Add")]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c(
            "div",
            { staticClass: "actionDiv" },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "error",
                    disabled: _vm.userOID != _vm.currentTemplate.author_oid,
                  },
                  on: {
                    click: function ($event) {
                      _vm.deleteDialog = true
                    },
                  },
                },
                [_vm._v("Delete")]
              ),
              _c(
                "v-dialog",
                {
                  attrs: { width: "500" },
                  model: {
                    value: _vm.deleteDialog,
                    callback: function ($$v) {
                      _vm.deleteDialog = $$v
                    },
                    expression: "deleteDialog",
                  },
                },
                [
                  _c(
                    "v-card",
                    [
                      _c("v-container", [
                        _vm._v(
                          ' Are you sure you want to delete "' +
                            _vm._s(_vm.currentTemplate.name) +
                            '"? '
                        ),
                      ]),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              on: {
                                click: function ($event) {
                                  _vm.deleteDialog = false
                                },
                              },
                            },
                            [_vm._v("Cancel")]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "error" },
                              on: { click: _vm.deleteTemplate },
                            },
                            [_vm._v("Delete")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "span",
                [
                  !_vm.saveAsDisabled
                    ? _c(
                        "v-btn",
                        {
                          staticStyle: { "margin-right": "20px" },
                          attrs: { color: "#7d7d7d" },
                          on: { click: _vm.discard },
                        },
                        [_vm._v("discard")]
                      )
                    : _vm._e(),
                  _c(
                    "v-btn",
                    {
                      staticStyle: { "margin-right": "20px" },
                      attrs: { color: "primary", disabled: _vm.saveDisabled },
                      on: { click: _vm.save },
                    },
                    [_vm._v("Save")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", disabled: _vm.saveAsDisabled },
                      on: { click: _vm.showSaveAsDialog },
                    },
                    [_vm._v("Save As")]
                  ),
                  _c(
                    "v-dialog",
                    {
                      attrs: { width: "500" },
                      model: {
                        value: _vm.saveAsDialog,
                        callback: function ($$v) {
                          _vm.saveAsDialog = $$v
                        },
                        expression: "saveAsDialog",
                      },
                    },
                    [
                      _c(
                        "v-card",
                        [
                          _c("v-card-title", [_vm._v("Save As New Template")]),
                          _c(
                            "v-card-text",
                            [
                              _c("v-text-field", {
                                attrs: { label: "Template Name", outlined: "" },
                                model: {
                                  value: _vm.name,
                                  callback: function ($$v) {
                                    _vm.name = $$v
                                  },
                                  expression: "name",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-card-actions",
                            [
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  on: {
                                    click: function ($event) {
                                      _vm.saveAsDialog = false
                                    },
                                  },
                                },
                                [_vm._v("Cancel")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary" },
                                  on: { click: _vm.saveAs },
                                },
                                [_vm._v("Save")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.showAddGraph
        ? _c("AddGraph", {
            attrs: {
              graphs: _vm.graphs,
              triggers: _vm.triggers,
              signals: _vm.signals,
              "edit-graph": _vm.editGraph,
            },
            on: { close: () => (_vm.showAddGraph = false) },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }