<template>
  <div class="mainContainer">
    <div class="leftCol">
      <div class="title">{{ title }}</div>
      <highcharts
        v-if="dataMounted"
        id="donut1"
        ref="donutChartA"
        :options="options"
      ></highcharts>
    </div>
    <div class="legend-area" :style="{ columnCount: numCols }">
      <div v-for="(item, index) in data" :key="index" class="legend-item">
        <div class="legend-text">
          <span
            class="dot"
            :style="{ backgroundColor: `${item.color}` }"
          ></span>
          {{ item.name }}
        </div>
        <div class="subtext">{{ `${Math.round(item.y)} min` }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PieCard',
  props: {
    title: {
      type: String,
      required: true,
    },
    // should match highcharts format
    // property 'color' required for legend
    data: {
      type: Array,
      required: true,
    },
    numCols: {
      type: Number,
      required: false,
      default: 1,
    },
  },
  data() {
    return {
      dataMounted: false,
      options: null,
    };
  },
  mounted() {
    this.setOptions();
  },
  methods: {
    setOptions() {
      this.options = {
        chart: {
          type: 'pie',
          backgroundColor: '#1a1a1a',
          width: 200,
          height: 200,
          margin: 0,
          padding: 0,
          spacingTop: 0,
          spacingBottom: 0,
          spacingLeft: 0,
          spacingRight: 0,
        },
        title: null,
        yAxis: {
          title: null,
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          pie: {
            slicedOffset: 0,
            size: '100%',
            dataLabels: {
              enabled: false,
            },
            colors: this.colors,
            borderColor: null,
            borderRadius: 0,
          },
        },
        tooltip: {
          formatter: function () {
            return '<b>' + this.point.name + '</b>: ' + Math.round(this.y);
          },
        },
        series: [
          {
            align: 'left',
            data: this.data,
            size: '60%',
            innerSize: '80%',
            showInLegend: true,
            dataLabels: {
              enabled: false,
            },
          },
        ],
        credits: {
          enabled: false,
        },
        exporting: {
          enabled: false,
        },
      };
      this.dataMounted = true;
    },
  },
};
</script>

<style scoped>
.mainContainer {
  display: flex;
  flex-direction: row;
  width: 493px;
  height: 229px;
  border-radius: 12px;
  background: #1a1a1a;
  margin: 3px;
}
.title {
  position: relative;
  top: 20px;
  text-align: center;
  z-index: 1;
  color: var(--dark-base-chart-title, rgba(255, 255, 255, 0.87));
  /* 24 sp • H5 Headline */
  font-family: Rawline !important;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.dot {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  opacity: 1;
  display: block;
  position: relative;
  margin-right: 10px;
  padding: 0;
  float: left;
  top: 7px;
}
.legend-area {
  /* background-color: rgba(0, 255, 255, 0.281); */
  display: block;
  margin-top: auto;
  margin-bottom: auto;
}
.legend-item {
  -webkit-column-break-inside: avoid;
}
.legend-text {
  color: rgba(224, 224, 224, 0.6);

  /* v-text/body-1 */
  font-family: Rawline;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  letter-spacing: 0.5px;
}
.subtext {
  color: #fff;
  margin-left: 25px;
  margin-bottom: 10px;
  text-align: left;
  /* 14 sp • Body 2 */
  font-family: Rawline;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.25px;
}
</style>
