<template>
  <div>
    <div v-if="positionData && !emptyPosition">
      <v-alert v-if="warningMessage" type="warning" class="alertWarning">
        {{ warningMessage }}
      </v-alert>
      <div class="rangeFilter">
        <p>Map Details</p>
        <div v-if="noData" class="noData">No data for this time period</div>
        <!-- empty placeholder below prevents formatting issues -->
        <div v-else class="noData"></div>
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="dates"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateInput"
              v-mask="'##/##/#### - ##/##/####'"
              type="text"
              outlined
              append-icon="mdi-calendar"
              v-bind="attrs"
              @click:append="on.click"
            >
            </v-text-field>
          </template>
          <v-date-picker v-model="dates" range>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(dates)">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
        <div id="dateGuide">MM/DD/YYYY - MM/DD/YYYY</div>
        <div>
          <p style="margin-bottom: -12px" class="smallText">Display Icons</p>
          <v-checkbox
            v-model="showEvents"
            label="Show Events"
            :disabled="historyDisabled"
          ></v-checkbox>
        </div>
        <div>
          <p style="margin-bottom: -12px; margin-top: -7px" class="smallText">
            Display Company Trucks
          </p>
          <div v-if="positionData.truck_type.toUpperCase().includes('ERX')">
            <v-checkbox
              v-model="showAllTrucks"
              label="Show All Trucks"
              :disabled="allTrucksDisabled"
            ></v-checkbox>
          </div>
          <div v-else-if="positionData.truck_type.toUpperCase().includes('EX')">
            <v-checkbox
              v-model="showAllCNG"
              :label="`Show All CNG Trucks`"
              :disabled="allTrucksDisabled || cngPolylines.length === 0"
            ></v-checkbox>
            <v-checkbox
              v-model="showAllDiesel"
              style="margin-top: -15px"
              :label="`Show All Diesel Trucks`"
              :disabled="allTrucksDisabled || dieselPolylines.length === 0"
            ></v-checkbox>
          </div>

          <p style="margin-bottom: -12px; margin-top: -7px" class="smallText">
            Display Elevation Profile
          </p>
          <v-checkbox
            v-model="showElevation"
            label="Show Elevation"
            :disabled="historyDisabled"
          ></v-checkbox>
        </div>
        <v-row v-if="historyDisabled" style="margin-bottom: 4px">
          <v-col cols="3">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-col>
          <v-col>
            <div style="margin-top: 3px; margin-left: -5px">
              Loading History...
            </div>
          </v-col>
        </v-row>
        <div v-else>
          <v-btn style="margin-bottom: 14px" color="primary" @click="submit"
            >Submit</v-btn
          >
        </div>
      </div>
      <div id="map">
        <div
          id="mapContainer"
          ref="hereMap"
          :style="`height: ${mapHeight}px; width: 100%`"
        ></div>
      </div>
      <div>
        <Elevation
          v-if="showElevation && !historyDisabled"
          :data="elevationData"
        ></Elevation>
      </div>
    </div>
    <div
      v-else-if="emptyPosition"
      style="display: block; margin: auto; text-align: center; margin-top: 50px"
    >
      No position Data
    </div>
    <div v-else>
      <v-progress-circular
        indeterminate
        color="primary"
        style="display: block; margin: auto; margin-top: 50px"
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import { getTravelHistory, getEventHistory } from '@/api/maps';
import { getStartEnd } from '@/utilities/dateFunctions';
import { getADXLocations, getERXDTCs } from '@/api/trucks';
import { mapGetters } from 'vuex';
import Elevation from './Elevation';
import dateFormat from 'dateformat';
import _ from 'lodash';

export default {
  name: 'TruckMap',
  components: {
    Elevation,
  },
  data() {
    return {
      fault: false,
      warningMessage: null,
      menu: false,
      dateInput: null,
      dates: [],
      historyDisabled: true,
      allTrucksDisabled: true,
      showEvents: false,
      showAllTrucks: false,
      showAllCNG: false,
      showAllDiesel: false,
      showElevation: false,
      apikey: null,
      platform: null,
      hereMap: null,
      hereUI: null,
      hereRouter: null,
      formattedPos: null,
      range: '1',
      onMap: new Set(),
      noData: false,
      eventGroup1: null,
      positionData: null,
      emptyPosition: false,
      polylines: {
        polyline1: [],
      },
      allPolylines: [],
      cngPolylines: [],
      dieselPolylines: [],
      elevationData: [],
    };
  },
  computed: {
    ...mapGetters({
      userPreferences: 'getUserPreferences',
      HERE_MAP_KEY: 'getMapKey',
    }),
    mapHeight() {
      if (this.showElevation && !this.historyDisabled) {
        return Math.max(window.innerHeight - 70 - 270, 270);
      } else {
        return window.innerHeight - 70;
      }
    },
    truckType() {
      return this.positionData.truck_type.toUpperCase().includes('ERX')
        ? 'ERX'
        : 'EX';
    },
  },
  watch: {
    positionData() {
      const vm = this;
      if (this.truckData && this.truckData.position) {
        _.delay(function () {
          vm.initializeHereMap().then(() => {
            vm.plotTruck();
          });
        }, 500);
      }
    },
    showEvents() {
      // turn on event markers
      if (this.showEvents) {
        this.onMap.add('eventGroup1');
        this.hereMap.addObject(this.eventGroup1);
        // turn off event markers
      } else {
        if (this.onMap.has('eventGroup1')) {
          this.hereMap.removeObject(this.eventGroup1);
          this.onMap.delete('eventGroup1');
        }
      }
    },
    showAllTrucks() {
      if (this.showAllTrucks) {
        this.onMap.add('allTrucks');
        this.allPolylines.forEach((p) => {
          this.hereMap.addObject(p);
        });
      } else {
        if (this.onMap.has('allTrucks')) {
          this.allPolylines.forEach((p) => {
            this.hereMap.removeObject(p);
          });
          this.onMap.delete('allTrucks');
        }
      }
    },
    showAllCNG() {
      if (this.showAllCNG) {
        this.onMap.add('all');
        this.cngPolylines.forEach((p) => {
          this.hereMap.addObject(p);
        });
      } else {
        if (this.onMap.has('all')) {
          this.cngPolylines.forEach((p) => {
            this.hereMap.removeObject(p);
          });
          this.onMap.delete('all');
        }
      }
    },
    showAllDiesel() {
      if (this.showAllDiesel) {
        this.onMap.add('allDiesel');
        this.dieselPolylines.forEach((p) => {
          this.hereMap.addObject(p);
        });
      } else {
        if (this.onMap.has('allDiesel')) {
          this.dieselPolylines.forEach((p) => {
            this.hereMap.removeObject(p);
          });
          this.onMap.delete('allDiesel');
        }
      }
    },
    dates() {
      // sync with this.dateInput
      this.dateInput = '';
      for (let i = 0; i < this.dates.length; i++) {
        this.dateInput += dateFormat(this.dates[i], 'mm/dd/yyyy', true);
        if (i === 0) this.dateInput += ' - ';
      }
    },
    dateInput() {
      // autocomplete logic
      if (parseInt(this.dateInput[0]) > 1) {
        this.dateInput = '0' + this.dateInput + '/';
      }
      if (parseInt(this.dateInput[3]) > 3) {
        this.dateInput =
          this.dateInput.substr(0, 2) + '/0' + this.dateInput[3] + '/';
      }
      if (parseInt(this.dateInput[13]) > 1) {
        this.dateInput =
          this.dateInput.substr(0, 12) + ' 0' + this.dateInput[13] + '/';
      }
      if (parseInt(this.dateInput[16]) > 3) {
        this.dateInput =
          this.dateInput.substr(0, 15) + '/0' + this.dateInput[16] + '/';
      }
      // sync with this.dates
      if (this.dateInput.length === 23) {
        let [start, end] = this.dateInput.split('-');
        this.dates = [
          dateFormat(start, 'yyyy-mm-dd', true),
          dateFormat(end, 'yyyy-mm-dd', true),
        ];
      }
    },
  },
  async mounted() {
    await Promise.allSettled([this.getDTCStatus(), this.getPosition()]);
    const ms_28_days = 86400000 * 28;
    this.dates = [Date.parse(new Date()) - ms_28_days, Date.parse(new Date())];
    if (this.positionData && !this.emptyPosition) {
      await this.initializeHereMap();
      await this.plotTruck();
      this.loadTravelHistory();
      this.loadAllTruckHistory();
    }
  },
  methods: {
    async initializeHereMap() {
      this.apikey = this.HERE_MAP_KEY;

      const platform = new window.H.service.Platform({
        apikey: this.apikey,
      });
      this.platform = platform;
      const mapContainer = this.$refs.hereMap;
      const H = window.H;
      // Obtain the default map types from the platform object
      const maptypes = this.platform.createDefaultLayers();
      // Instantiate (and display) a map object:
      const map = new H.Map(mapContainer, maptypes.vector.normal.map, {
        zoom: 6,
        center: {
          lat: this.positionData.latitude,
          lng: this.positionData.longitude,
        },
      });
      this.hereMap = map;
      addEventListener('resize', () => map.getViewPort().resize());
      // add behavior control
      new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
      // add UI
      this.hereUI = H.ui.UI.createDefault(map, maptypes);
      // get an instance of the routing service version 8
      this.hereRouter = platform.getRoutingService(null, 8);
      // select UI buttons for positioning
      const zoom = this.hereUI.getControl('zoom');
      const mapSettings = this.hereUI.getControl('mapsettings');
      zoom.setAlignment('right-top');
      mapSettings.setAlignment('right-top');
      // style buttons manually to match cog
      const htmlZoom = zoom.getElement();
      htmlZoom.style.background = '#303030';
      htmlZoom.children[0].style.background = '#1a1a1a';
      htmlZoom.children[1].style.background = '#1a1a1a';
      htmlZoom.children[0].firstChild.firstChild.firstChild.style.fill =
        '#9e9e9e';
      htmlZoom.children[1].firstChild.firstChild.style.fill = '#9e9e9e';
      const htmlSetttings = mapSettings.getElement();
      htmlSetttings.firstChild.style.background = '#1a1a1a';
      htmlSetttings.firstChild.firstChild.firstChild.style.fill = '#9e9e9e';
      // remove satellite view option
      htmlSetttings.children[1].firstChild.children[1].remove();
      htmlSetttings.children[1].children[1].remove();
    },
    async plotTruck() {
      const H = window.H;
      let iconName;
      // configure online/offline status
      if (this.fault) {
        iconName = 'Error_Idle.svg';
      } else if (
        Date.parse(this.positionData.timestamp) <
        Date.parse(new Date()) - 86400000
      ) {
        iconName = 'Off-line.svg';
      } else {
        iconName = `Healthy_Idle.svg`;
      }

      const markerContent = `
        <div class="hymarker" style="display:block;position:relative;width:30px;height:43px;
          background-image:url(../../images/icons/small/${iconName});z-index:10;background-size:cover;
          margin-left: -16px; margin-top: -36px;">
        </div>`;

      // Add truck to map
      const domIcon = new H.map.DomIcon(markerContent);
      const truckMarker = new H.map.DomMarker(
        { lat: this.positionData.latitude, lng: this.positionData.longitude },
        {
          icon: domIcon,
        }
      );
      truckMarker.setZIndex(14);
      await this.hereMap.addObject(truckMarker);
    },
    submit() {
      this.clearMap();
      // quick input validation
      const [start, end] = getStartEnd(
        this.dates,
        this.userPreferences.timeZonePref.canonical
      );

      if (start <= 1514764800000 || end <= 1514764800000) {
        const vm = this;
        // send warning
        this.warningMessage =
          'Dates must be after Jan 01 2018 00:00:00 GMT+0000';
        setTimeout(() => (vm.warningMessage = null), 5000);
        return;
      }
      // reset map
      this.eventGroup1 = null;
      this.polylines = {
        polyline1: [],
      };
      this.elevationData = null;
      this.plotTruck();
      this.loadTravelHistory();
      this.loadAllTruckHistory();
    },
    clearMap() {
      if (this.hereMap) {
        this.hereMap.removeObjects(this.hereMap.getObjects());
        this.markers = {};
      }
    },
    async loadTravelHistory() {
      this.historyDisabled = true;
      this.noData = false;
      const vm = this;
      const H = window.H;
      const [start, end] = getStartEnd(
        this.dates,
        this.userPreferences.timeZonePref.canonical
      );

      getEventHistory(
        this.positionData.truck_id,
        this.truckType,
        start,
        end
      ).then((res) => {
        this.buildEventHistory(res);
      });
      const res = await getTravelHistory(
        this.positionData.truck_id,
        this.truckType,
        start,
        end
      );

      let linestring1 = new H.geo.LineString();
      let data1 = 0;
      // return if no data
      if (res.data.length === 0) {
        this.noData = true;
        this.historyDisabled = false;
        return;
      }

      // create lineStrings from raw data & build elevation series
      let elevationData = [];
      for (let i = 0; i < res.data.length; i++) {
        linestring1.pushPoint({
          lat: res.data[i].latitude,
          lng: res.data[i].longitude,
        });
        // track whether linestring has >= 2 points
        if (data1 < 2) data1 += 1;
        elevationData.push([
          Date.parse(res.data[i].timestamp),
          res.data[i].altitude,
        ]);
      }
      // highcharts requires timestamp asc order
      this.elevationData = elevationData.reverse();
      // add tail end polylines to arrays
      vm.polylines.polyline1.push(
        data1 > 1
          ? new H.map.Polyline(linestring1, {
              style: { lineWidth: 4, strokeColor: '#2196F3' },
              zIndex: 201,
            })
          : null
      );

      // add polyline1 if data exists (assumes range is 1 by default)
      if (vm.polylines.polyline1.length > 0) {
        vm.polylines.polyline1.forEach((p) => {
          if (p) {
            vm.hereMap.addObject(p);
            this.onMap.add('polyline1');
          }
        });
      }
      this.historyDisabled = false;
    },
    buildEventHistory(res) {
      // build event objects on map
      const vm = this;
      const H = window.H;
      vm.eventGroup1 = new H.map.Group();

      for (const event of res.data) {
        let iconName, zIndex;
        if (event.eventType.toUpperCase() === 'OFFLINE') {
          iconName = 'Off-line.svg';
          zIndex = 11;
        } else if (event.eventType.toUpperCase().includes('DTC')) {
          iconName = 'Error_Idle.svg';
          zIndex = 12;
        } else {
          // APU, LOAD, UNLOAD, REFUEL
          iconName = 'APU.svg'; // this will likely be redefined in the future
          zIndex = 10;
        }
        const markerContent = `
          <div class="hymarker" style="display:block;position:relative;width:30px;height:43px; cursor: pointer;
            background-image:url(../../images/icons/small/${iconName});z-index:10;background-size:cover;
            margin-left: -16px; margin-top: -36px;">
          </div>`;
        const domIcon = new H.map.DomIcon(markerContent);
        const eventMarker = new H.map.DomMarker(
          { lat: event.latitude, lng: event.longitude },
          {
            icon: domIcon,
            data: {
              eventType: event.eventType,
              eventDuration: event.eventDuration,
              timestamp: event.timestamp,
            },
          }
        );
        eventMarker.setZIndex(zIndex);
        vm.eventGroup1.addObject(eventMarker);
      }
      // add eventGroup1; assumes range is 1 day by default
      if (this.showEvents) {
        vm.hereMap.addObject(vm.eventGroup1);
        vm.onMap.add('eventGroup1');
      }
      vm.setupInfoBubbles();
    },
    setupInfoBubbles() {
      this.hereMap.addEventListener('tap', (event) => {
        // remove all open bubbles
        this.hereUI
          .getBubbles()
          .forEach((bub) => this.hereUI.removeBubble(bub));
        // if target has no data (i.e. is the map) return
        let bubble;
        if (!event.target.getData || !event.target.getData()) {
          return;
        } else if (event.target.data && event.target.data.eventType) {
          const eventName =
            event.target.data.eventType === 'APU'
              ? 'Hotelling Active'
              : event.target.data.eventType;

          let durationString = '';
          if (event.target.data.eventDuration) {
            const hour = Math.floor(event.target.data.eventDuration / 60);
            let minute = event.target.data.eventDuration - hour * 60;
            if (minute < 10) minute = '0' + minute;
            durationString = `${hour}:${minute} min <br>`;
          }

          bubble = new window.H.ui.InfoBubble(event.target.getGeometry(), {
            content: `
              <div style="color: white; width: 200px;">
                <span style="font-weight: bold">${eventName}</span><br>
                ${durationString}
                ${dateFormat(
                  event.target.data.timestamp,
                  'ddd mmm dd yyyy HH:MM:ss Z',
                  true
                )}<br>
              </div>
                  `,
          });
        }

        this.hereUI.addBubble(bubble);
      });
    },
    async getPosition() {
      const res = await getADXLocations(this.$route.params.id);
      this.positionData = res.data[0];
      if (
        !this.positionData ||
        !this.positionData.latitude ||
        !this.positionData.longitude
      ) {
        this.emptyPosition = true;
      }
    },
    async loadAllTruckHistory() {
      this.allTrucksDisabled = true;
      this.noData = false;
      const vm = this;
      const H = window.H;
      const [start, end] = getStartEnd(
        this.dates,
        this.userPreferences.timeZonePref.canonical
      );
      const res = await getTravelHistory(
        this.positionData.truck_id,
        this.truckType,
        start,
        end,
        true
      );
      let fuelLookup = {};
      if (res.data[0]) {
        for (const truck of res.data[0]) {
          fuelLookup[truck.id] = truck.fuel_type.toLowerCase();
        }
      }

      let current_truck = res.data[1][0] ? res.data[1][0].truck_id : null;
      let current_linestring = new H.geo.LineString();
      for (const datum of res.data[1]) {
        // create new polyline everytime it's a new truck
        if (datum.truck_id === current_truck) {
          // add gps to linestring
          current_linestring.pushPoint({
            lat: datum.latitude,
            lng: datum.longitude,
          });
        } else {
          // create new polyline from linestring; save to array
          // (length 6 => 2 points)
          if (current_linestring.X.length >= 6) {
            const polyline = new H.map.Polyline(current_linestring, {
              style: { lineWidth: 4, strokeColor: '#8a8a8a' },
              zIndex: 1,
            });

            if (res.data[0] && fuelLookup[datum.truck_id] === 'cng') {
              this.cngPolylines.push(polyline);
            } else if (res.data[0]) {
              this.dieselPolylines.push(polyline);
            } else {
              this.allPolylines.push(polyline);
            }
          }
          // reset current values
          current_truck = datum.truck_id;
          current_linestring = new H.geo.LineString();
        }
      }

      this.allTrucksDisabled = false;
    },
    async getDTCStatus() {
      const res = await getERXDTCs();
      let fault = false;
      for (const dtc of res.data) {
        if (dtc.truck_id === parseInt(this.$route.params.id)) {
          fault = true;
          break;
        }
      }
      this.fault = fault;
    },
  },
};
</script>

<style scoped>
.rangeFilter {
  position: absolute;
  width: 315px;
  left: 23px;
  top: 25px;
  border-radius: 5px;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-content: center;
  padding-right: 20px;
  padding-top: 15px;
  padding-left: 20px;
  background: #272727;
}
.smallText {
  font-family: 'Rawline';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: flex-end;
  letter-spacing: 0.2px;
  color: #e0e0e0;
  opacity: 0.54;

  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}
.noData {
  font-family: 'Rawline';
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  color: #ffba30;
  margin: 3px;
}
#dateGuide {
  margin-top: -27px;
  margin-bottom: 20px;
  margin-left: 10px;
  font-size: 13px;
  color: rgb(171, 171, 171);
}
.alertWarning {
  z-index: 1000;
  position: absolute;
  top: 30px;
  left: 60px;
  right: 60px;
}
</style>
