<template>
  <highcharts :options="getChartData()"></highcharts>
</template>

<script>
export default {
  name: 'BarChart',
  props: {
    name: {
      required: true,
      type: String,
      defualt: '',
    },
    data: {
      required: true,
      type: Array,
      default: () => [],
    },
    categories: {
      required: true,
      type: Array,
      default: () => [],
    },
    height: {
      required: false,
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      total: 0,
      dataMerch: [],
      colors: ['#ff0000', '#fb8c00', '#43b02a', '#888888'],
    };
  },
  methods: {
    getChartData() {
      return {
        chart: {
          type: 'column',
          backgroundColor: '#1a1a1a',
          height: this.height ? this.height : null,
        },
        title: {
          text: this.name,
          style: {
            color: '#FFFFFF',
            fontFamily: 'Rawline',
            fontWeight: 'bold',
          },
        },
        tooltip: {
          formatter: function () {
            return `
               Name: <b>${this.x ? this.x : 'N/A'}</b> <br/>DTC Count: <b>${
              this.y
            }</b> <br/>SPN-FMI: <b>${this.point.spn}-${this.point.fmi}</b>`;
          },
        },
        series: [
          {
            name: 'DTCs',
            data: this.data,
            borderRadius: 2,
            borderWidth: 0,
          },
        ],
        xAxis: {
          categories: this.categories,
          labels: {
            style: {
              color: '#FFFFFF',
            },
          },
        },
        yAxis: {
          gridLineColor: '#696969',
          labels: {
            style: {
              color: '#FFFFFF',
            },
          },
        },
        legend: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },
      };
    },
  },
};
</script>
