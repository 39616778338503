import axios from 'axios';

const BASE_URL = process.env.VUE_APP_API_PATH_BASE;

async function getCoordinates(title) {
  const HERE_KEY = process.env.VUE_APP_HERE_KEY;
  return await axios.get(
    `https://geocode.search.hereapi.com/v1/geocode?q=${title}&apiKey=${HERE_KEY}`
  );
}

async function getCNGStations() {
  const api_key = 'whJIbCgslYBUnbQ1a3aqsqTcd3XUYUGzs0V9M6gT';
  const fuel_type = 'CNG';
  return await axios.get(
    `https://developer.nrel.gov/api/alt-fuel-stations/v1.json?fuel_type=${fuel_type}&api_key=${api_key}&status=E&access=public&cng_vehicle_class=HD&country=all`
  );
}

async function getTravelHistory(
  truck_id,
  truck_type,
  start = null,
  end = null,
  all_company = null
) {
  let queryStr = start ? `&start=${start}` : '';
  queryStr += end ? `&end=${end}` : '';
  queryStr += all_company ? `&all_company=${all_company}` : '';
  return await axios.get(
    BASE_URL +
      `maps/travelHistory?truck_id=${truck_id}&truck_type=${truck_type}${queryStr}`
  );
}

async function getElevation(origin, destination) {
  const HERE_KEY = process.env.VUE_APP_HERE_KEY;
  return await axios.get(
    `https://router.hereapi.com/v8/routes?origin=${origin.lat},${origin.lng}&transportMode=truck&destination=${destination.lat},${destination.lng}&return=polyline,elevation&apikey=${HERE_KEY}`
  );
}

async function getEventHistory(truck_id, truck_type, start, end, tz) {
  let queryStr = start ? `&start=${start}` : '';
  queryStr += end ? `&end=${end}` : '';
  queryStr += tz ? `&tz=${tz}` : '';
  return await axios.get(
    BASE_URL +
      `maps/eventHistory?truck_id=${truck_id}&truck_type=${truck_type}${queryStr}`
  );
}

async function getRouteClassification(route) {
  return await axios.post(BASE_URL + 'maps/analyze_route', route);
}

export {
  getCoordinates,
  getCNGStations,
  getElevation,
  getTravelHistory,
  getEventHistory,
  getRouteClassification,
};
