<template>
  <v-dialog v-model="openDialog" class="dialog" width="750" persistent>
    <alert
      v-if="showAlert"
      :alert-type="alertType"
      :alert-message="alertMessage"
    />
    <loader v-if="loading" />
    <v-card>
      <v-container>
        <v-form ref="form" v-model="valid">
          <v-row>
            <v-col class="hidden-xs-and-down"><v-spacer /></v-col>
            <v-col align="center" cols="12" sm="8" md="8" lg="8" xl="8"
              ><div class="text-h5">Add New Case</div></v-col
            >
            <v-col class="hidden-xs-and-down"><v-spacer /></v-col>
          </v-row>
          <v-row>
            <v-col class="hidden-xs-and-down"><v-spacer /></v-col>
            <v-col cols="12" sm="8" md="8" lg="8" xl="8">
              <v-text-field
                v-model="newCase.subject"
                label="Subject"
                outlined
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col class="hidden-xs-and-down"><v-spacer /></v-col>
          </v-row>
          <v-row>
            <v-col class="hidden-xs-and-down"><v-spacer /></v-col>
            <v-col cols="12" sm="8" md="8" lg="8" xl="8">
              <v-textarea
                v-model="newCase.description"
                label="Description"
                value=""
                outlined
              ></v-textarea>
            </v-col>
            <v-col class="hidden-xs-and-down"><v-spacer /></v-col>
          </v-row>
          <v-row>
            <v-col class="hidden-xs-and-down"><v-spacer /></v-col>
            <v-col cols="12" sm="8" md="8" lg="8" xl="8">
              <v-select
                v-model="newCase.priority"
                label="Priority"
                :items="priorityItems"
                outlined
                :rules="[rules.required]"
              ></v-select>
            </v-col>
            <v-col class="hidden-xs-and-down"><v-spacer /></v-col>
          </v-row>
          <v-row>
            <v-col class="hidden-xs-and-down"><v-spacer /></v-col>
            <v-col cols="12" sm="8" md="8" lg="8" xl="8">
              <v-select
                v-model="newCase.type"
                label="Type"
                :items="typeItems"
                outlined
                :rules="[rules.required]"
              ></v-select>
            </v-col>
            <v-col class="hidden-xs-and-down"><v-spacer /></v-col>
          </v-row>
          <v-row>
            <v-col class="hidden-xs-and-down"><v-spacer /></v-col>
            <v-col align="center" cols="12" sm="4" md="4" lg="4" xl="4"
              ><v-btn plain color="primary" @click="openDialog = false"
                >Cancel</v-btn
              ></v-col
            >
            <v-col align="center" cols="12" sm="4" md="4" lg="4" xl="4"
              ><v-btn
                :disabled="!valid"
                color="primary"
                style="color: black"
                @click="addNewCase"
                >Add</v-btn
              ></v-col
            >
            <v-col class="hidden-xs-and-down"><v-spacer /></v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
import alert from '@/utilities/alert';
import loader from '@/utilities/loader';

import { addCase } from '@/api/salesforce';
export default {
  name: 'AddCase',
  components: { alert, loader },
  props: {
    // eslint-disable-next-line
    assetId: String,
    // eslint-disable-next-line
    vin: String,
  },
  data() {
    return {
      loading: false,
      showAlert: false,
      alertType: '',
      alertMessage: '',
      newCase: {
        subject: '',
        description: '',
        priority: '',
        type: '',
        email: 'hls@hlyiion.com',
        company: 'Hyliion',
        assetId: '',
        vin: '',
      },
      rules: {
        required: (value) => !!value || 'Required.',
      },
      priorityItems: ['Low', 'Medium', 'High'],
      typeItems: ['Problem', 'Feature Request', 'Question'],
      openDialog: false,
      valid: false,
    };
  },
  watch: {
    // Watches for the change in openDialog variable to emit the false variable back to parent to reset
    openDialog: function (newValue) {
      if (!newValue) {
        this.openDialog = false;
        this.$emit('closed', false);
      }
    },
  },
  async mounted() {
    this.openDialog = true;
  },
  methods: {
    async addNewCase() {
      try {
        if (this.assetId) {
          this.newCase.assetId = this.assetId;
        } else {
          this.newCase.vin = this.vin;
        }
        await addCase(this.newCase);
        this.$emit('refresh');
        this.alertResponse('success', 'Successfully added new case!');
      } catch (e) {
        this.alertResponse(
          'error',
          'Something went wrong, please try again later!'
        );
      }
    },
    alertResponse(type, message) {
      this.loading = false;
      this.showAlert = true;
      this.alertType = type;
      this.alertMessage = message;
      setTimeout(() => {
        this.showAlert = false;
        if (type !== 'error') this.openDialog = false;
      }, 2000);
    },
  },
};
</script>
