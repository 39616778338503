<template>
  <div id="packages">
    <v-container fluid>
      <v-row>
        <v-col cols="4" class="pb-0" justify="center">
          <v-text-field
            v-model="searchText"
            placeholder="Search"
            solo
            prepend-inner-icon="mdi-magnify"
            color="primary"
          ></v-text-field>
        </v-col>
        <v-col align="right">
          <v-btn
            v-if="userCanUploadPackage"
            large
            color="primary"
            style="color: black"
            @click="showNewPackage = true"
          >
            <v-icon left>mdi-plus-circle-outline</v-icon>Upload Package</v-btn
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-0" cols="4" xs="12" sm="12" md="12" lg="4" xl="4">
          <v-tabs fixed-tabs background-color="secondary">
            <v-tab>ERX Packages</v-tab>
          </v-tabs>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-0">
          <v-skeleton-loader
            v-if="loading"
            type="table"
            elevation="2"
          ></v-skeleton-loader>
          <template v-else>
            <erxpackages
              v-if="switchTab === 'ERX'"
              :erx-packages="ERXPackages"
              :search-text="searchText"
              @refresh="refreshData"
            />
          </template>
        </v-col>
      </v-row>
    </v-container>
    <add-package
      v-if="showNewPackage"
      @closed="closeNewPackage"
      @refresh="refreshData"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { getAllConfigurations } from '@/api/configurations';
import { getAllDevices } from '@/api/iot';
import AddPackage from './AddPackage';

import ERXPackages from './ERXPackages';
import { permissions } from '@/api/permissions';
import dateFormat from 'dateformat';
export default {
  name: 'TruckView',
  components: {
    'add-package': AddPackage,
    erxpackages: ERXPackages,
  },
  data() {
    return {
      loading: true,
      searchText: '',
      headers: [
        { text: 'Name', value: 'config_name' },
        { text: 'Trucks Assigned', value: 'trucks_assigned_count' },
        { text: 'Status', value: 'status' },
        { text: 'Truck Type', value: 'truck_type' },
        { text: 'Uploaded By', value: 'uploaded_by' },
        {
          text: '',
          value: 'actions',
          align: 'right',
        },
      ],
      showNewPackage: false,
      ERXPackages: [],
      switchTab: 'ERX',
    };
  },
  computed: {
    ...mapGetters({
      userRoles: 'getUserRoles',
      userPreferences: 'getUserPreferences',
    }),
    userCanUploadPackage() {
      return this.userRoles && this.userRoles.includes(permissions.configWrite);
    },
  },
  watch: {},
  async created() {
    await this.populatePackageList();
  },
  methods: {
    ...mapActions({
      updateSnack: 'updateSnack',
    }),
    async populatePackageList() {
      let res, res2;
      try {
        await Promise.allSettled([
          getAllConfigurations(),
          getAllDevices(),
        ]).then((results) => {
          results[0].status === 'fulfilled'
            ? (res = results[0].value.data)
            : console.log(results[0].reason);

          results[1].status === 'fulfilled'
            ? (res2 = results[1].value.data)
            : console.log(results[1].reason);
        });

        if (res) {
          res.forEach((c) => {
            if (c.truck_type && c.truck_type.includes('erx')) {
              c.updated_at = dateFormat(c.updated_at, 'mm/dd/yyyy hh:MM TT Z');
              c.assigned_trucks = c.trucks?.length || 0;
              c.installed_trucks = res2.filter(
                (d) =>
                  d.properties.reported.erx_binary_version === c.config_version
              ).length;
              this.ERXPackages.push(c);
            }
          });
          this.loading = false;
        }
      } catch (error) {
        this.loading = false;
        this.updateSnack({
          type: 'error',
          message: 'There was an issue retrieving packages',
        });
      }
    },
    closeNewPackage() {
      this.showNewPackage = false;
    },
    async refreshData() {
      this.ERXPackages = [];
      // This will re-grab the data from the back-end with the updated package data
      await this.populatePackageList();
    },
  },
};
</script>
<style>
/* Removes margin on text field component */
#packages .v-text-field__details {
  margin-bottom: 0;
}
#packages .v-input__slot {
  margin-bottom: 0;
}
</style>
