<template>
  <v-dialog v-model="openDialog" class="dialog" width="750">
    <loader v-if="loading" />
    <v-card class="pa-4">
      <v-card-title>Find Service Guide by DTC(SPNFMI)</v-card-title>
      <v-card-text>
        <div>
          <v-text-field
            v-model="spn_input"
            label="Enter SPN"
            outlined
          ></v-text-field>
          <v-text-field
            v-model="fmi_input"
            label="Enter FMI"
            outlined
          ></v-text-field>
        </div>
        <v-row class="ma-2">
          <v-col class="hidden-xs-and-down"><v-spacer /></v-col>
          <v-col align="center" cols="12" sm="4" md="4" lg="4" xl="4"
            ><v-btn
              color="primary"
              class="mr-2"
              style="width: 100%"
              outlined
              @click="findInsideBooks"
              >Locate Workbook</v-btn
            ></v-col
          >
          <v-col align="center" cols="12" sm="4" md="4" lg="4" xl="4">
            <v-btn
              color="primary"
              style="color: black; width: 100%"
              @click="cancel"
              >Close</v-btn
            >
          </v-col>
          <v-col class="hidden-xs-and-down"><v-spacer /></v-col>
        </v-row>
        <v-row class="ma-2">
          <v-col align="center" cols="8">
            <v-text-field
              v-model="found_component"
              label="Component"
              hint="Component name found in workbook"
              outlined
              readonly
            ></v-text-field>
          </v-col>
          <v-col align="center" cols="4">
            <v-btn
              class="mr-2"
              :disabled="linkActive"
              color="primary"
              style="color: black; height: 56px; width: 100%"
              @click="openSheet"
              >Open Link</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import loader from '@/utilities/loader';
import { findDtcInSheet } from '@/api/dtc';
export default {
  name: 'FindDTCSheet',
  components: { loader },
  props: {},
  data() {
    return {
      found_component: '',
      extSheetPathBase: process.env.VUE_APP_SP_SYS_ENG_CONV_URL,
      fileList: [],
      fmi_input: null,
      linkActive: true,
      linkSheet: '#',
      linkText: '',
      loading: false,
      locatedFile: null,
      openDialog: false,
      sheetName: '',
      spn_input: null,
    };
  },
  watch: {
    openDialog: function (newValue) {
      if (!newValue) {
        this.openDialog = false;
        this.$emit('closed', false);
      }
    },
  },
  mounted() {
    this.openDialog = true;
  },
  methods: {
    ...mapActions({
      updateSnack: 'updateSnack',
    }),
    cancel: function () {
      this.openDialog = false;
    },
    findInsideBooks: async function () {
      this.loading = true;
      if (this.spn_input && this.fmi_input) {
        const dtcObj = {
          spn: this.spn_input,
          fmi: this.fmi_input,
        };
        const fileInfo = await findDtcInSheet(dtcObj);
        if (fileInfo) {
          this.found_component = fileInfo.data.component;
          this.linkSheet = fileInfo.data.link;
          this.linkActive = false;
          this.updateSnack({
            type: 'success',
            message: 'Workbook found. Click "Open Link" to view.',
          });
          this.loading = false;
        } else {
          this.updateSnack({
            type: 'error',
            message: 'Workbook not found. Please try again.',
          });
          this.loading = false;
        }
      } else {
        this.updateSnack({
          type: 'error',
          message: 'Please enter SPN and FMI values to search for.',
        });
        this.loading = false;
      }
    },
    openSheet: function () {
      if (this.linkSheet) window.open(this.linkSheet, '_blank');
    },
  },
};
</script>
<style scoped>
.dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  height: auto;
  width: auto;
  z-index: 1;
  transform: translate(-50%, -50%);
}
</style>
