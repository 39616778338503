<template>
  <v-container fluid>
    <!-- header -->
    <v-row align="center">
      <v-col cols="10">
        <h1 style="font-weight: 400">ERX Study Board</h1>
      </v-col>
      <v-col align="right">
        <v-btn
          icon
          tile
          large
          style="background-color: #1a1a1a; border-radius: 5px"
          @click="drawer = true"
          ><v-icon style="opacity: 0.6">mdi-cog</v-icon></v-btn
        ></v-col
      >
    </v-row>
    <!-- page content -->
    <v-container fluid>
      <v-col align="right">
        <v-btn
          v-if="selectedTrucks.length >= 1"
          color="#D8D8D8"
          class="mr-1"
          style="color: black"
          @click="showExportAdx"
        >
          <v-icon id="csv2">mdi-open-in-new</v-icon>
          Export ADX Data
        </v-btn>
        <v-btn color="primary" @click="() => (showLoadStudy = true)"
          ><v-icon id="csv2">mdi-plus</v-icon>Load Study</v-btn
        >
      </v-col>
      <v-card class="tabView">
        <v-tabs v-model="tab" background-color="secondary">
          <v-tab> Template Editor </v-tab>
          <v-tab v-for="study in studies" :key="study.id" color="white">
            {{ study.name }}
            <v-icon
              medium
              style="margin-left: 10px; margin-bottom: -4px"
              @click="() => closeTab(study)"
              >mdi-close</v-icon
            >
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item :key="0">
            <TemplateEditor
              :triggers="triggers"
              :templates="templates"
              @refresh="fetchTemplates"
            />
          </v-tab-item>
          <v-tab-item
            v-for="study in studies"
            :key="study.id"
            color="white"
            style="background-color: #1a1a1a"
          >
            <Study
              :template="study"
              :truck-date="truckDate"
              :in-view="study === studies[tab - 1]"
              :update-zoom="updateZoom"
              :truck-names="truckNames"
              :signal-lookup="signalLookup"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-container>
    <!-- nav drawer -->
    <v-navigation-drawer
      v-model="drawer"
      right
      temporary
      fixed
      :width="360"
      class="shade"
    >
      <v-container>
        <v-row align="center">
          <v-col align="right" class="mb-2">
            <v-btn icon @click="drawer = false">
              <v-icon light>mdi-close-circle </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-expansion-panels v-model="panel" multiple>
          <v-expansion-panel class="shade">
            <v-expansion-panel-header
              >Date/Truck Selection</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <v-datetime-picker v-model="startDate" label="Start Date" />
              <div class="zoomDate">Zoomed Start: {{ zoomStart }}</div>
              <v-datetime-picker v-model="endDate" label="End Date" />
              <div class="zoomDate">Zoomed End: {{ zoomEnd }}</div>
              <v-btn color="primary" block @click="applyZoom"
                >Apply Zoomed Dates</v-btn
              >
              <br />
              Select Trucks:
              <v-autocomplete
                v-model="selectedTrucks"
                class="truckSelect"
                :items="trucks"
                outlined
                chips
                deletable-chips
                item-text="name"
                item-value="id"
                multiple
              >
              </v-autocomplete>
              <div style="display: block; margin-left: auto">
                <v-btn
                  style="margin-right: 15px"
                  :disabled="updateDisabled"
                  @click="cancelSelection"
                  >Cancel</v-btn
                >
                <v-btn
                  color="primary"
                  :disabled="updateDisabled"
                  @click="updateTruckDate"
                  >Update</v-btn
                >
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel class="shade">
            <v-expansion-panel-header>Triggers</v-expansion-panel-header>
            <v-expansion-panel-content>
              <div v-if="triggers.length">
                <p v-for="trigger in triggers" :key="trigger.id">
                  {{ trigger.name }}
                  <v-btn
                    icon
                    tile
                    small
                    style="
                      background-color: #070505;
                      border-radius: 5px;
                      float: right;
                    "
                    @click="callTriggerEdit(trigger.name)"
                    ><v-icon style="opacity: 0.6">mdi-pencil</v-icon></v-btn
                  >
                </p>
              </div>
              <div v-else style="text-align: center">No Current Triggers</div>
              <v-divider style="margin: 5px 0 5px 0" />
              <v-row
                ><v-col align="center"
                  ><v-btn @click="showAddTrigger = true">
                    Add New Trigger</v-btn
                  ></v-col
                ></v-row
              >
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-container>
    </v-navigation-drawer>
    <!-- modals -->
    <AddTrigger
      v-if="showAddTrigger"
      @closed="() => (this.showAddTrigger = false)"
      @refresh="fetchTriggers"
    />
    <EditTrigger
      v-if="showEditTrigger"
      :edit-trigger-select="editTriggerSelect"
      @closed="() => (this.showEditTrigger = false)"
      @refresh="fetchTriggers"
    />
    <LoadStudy
      v-if="showLoadStudy"
      :templates="templates"
      :add-study="addTab"
      @closed="() => (this.showLoadStudy = false)"
    />
  </v-container>
</template>

<script>
import TemplateEditor from './TemplateEditor.vue';
import AddTrigger from './AddTrigger.vue';
import EditTrigger from './EditTrigger.vue';
import LoadStudy from './LoadStudy.vue';
import Study from './Study.vue';
import { getAllTrucks } from '@/api/trucks.js';
import { getTriggers } from '@/api/study';
import { getTemplates } from '@/api/templates';
import { getSignals } from '@/api/dataDictionary';
import _ from 'lodash';
import dateFormat from 'dateformat';

export default {
  name: 'StudyBoard',
  components: {
    TemplateEditor,
    AddTrigger,
    EditTrigger,
    LoadStudy,
    Study,
  },
  data() {
    return {
      editTriggerSelect: null,
      showAddTrigger: false,
      showEditTrigger: false,
      showLoadStudy: false,
      selectedTrucks: [],
      panel: [0],
      startDate: new Date(Date.now() - 24 * 60 * 60000),
      endDate: new Date(Date.now()),
      zoomStart: null,
      zoomEnd: null,
      drawer: false,
      length: 5,
      tab: 0,
      truckDate: {}, // object detailing current truck/date selection
      studies: [], // array of template object studies currently visible
      templates: [], // array of all template objects
      trucks: [],
      triggers: [],
      signalLookup: {}, // key=signal_name, value=signal_id
      truckNames: {}, // key=id, value=name
    };
  },
  computed: {
    updateDisabled() {
      return (
        this.truckDate.start === this.startDate &&
        this.truckDate.end === this.endDate &&
        _.isEmpty(_.xor(this.selectedTrucks, this.truckDate.trucks))
      );
    },
  },
  mounted() {
    this.updateTruckDate();
    this.fetchTemplates();
    this.fetchTruckNames();
    this.fetchTriggers();
    this.fetchSignals();
  },
  methods: {
    remove(item) {
      const index = this.trucks.indexOf(item);
      if (index >= 0) this.trucks.splice(index, 1);
    },
    showExportAdx() {
      if (this.selectedTrucks.length === 1) {
        this.$router.push(
          `DataDictionary?truck=${
            this.selectedTrucks[0]
          }&startDate=${dateFormat(
            this.startDate,
            'yyyy-mm-dd HH:MM'
          )}&endDate=${dateFormat(this.endDate, 'yyyy-mm-dd HH:MM')}`
        );
      }
    },
    async fetchTruckNames() {
      let res = await getAllTrucks();
      this.trucks = res.data.filter((t) => t.truck_type.includes('ERX'));
      const truckNames = {};
      this.trucks.forEach((t) => (truckNames[t.id] = t.name));
      this.truckNames = truckNames;
    },
    async fetchTriggers() {
      const res = await getTriggers();
      this.triggers = res.data;
    },
    async fetchTemplates() {
      const res = await getTemplates();
      this.templates = res.data;
    },
    async fetchSignals() {
      const res = await getSignals();
      this.signals = res.data;
      let signalLookup = {};
      res.data.forEach((s) => (signalLookup[s.signal] = s.id));
      this.signalLookup = signalLookup;
    },
    callTriggerEdit(name) {
      let selected = this.triggers.filter((t) => t.name === name)[0];
      if (selected) {
        this.editTriggerSelect = selected;
        this.showEditTrigger = true;
      }
    },
    closeTab(study) {
      this.studies = this.studies.filter((s) => s.id != study.id);
    },
    addTab(study) {
      if (!this.studies.includes(study)) this.studies.push(study);
      this.tab = this.studies.indexOf(study) + 1; // navigate to study
    },
    updateTruckDate() {
      this.truckDate = {
        start: this.startDate,
        end: this.endDate,
        trucks: this.selectedTrucks,
      };
    },
    cancelSelection() {
      this.startDate = this.truckDate.start;
      this.endDate = this.truckDate.end;
      this.selectedTrucks = this.truckDate.trucks;
    },
    updateZoom(min, max) {
      this.zoomStart = dateFormat(min);
      this.zoomEnd = dateFormat(max);
    },
    // put time on the second line
    applyZoom(firstCall = true) {
      if (this.zoomStart && this.zoomEnd) {
        this.startDate = new Date(this.zoomStart);
        this.endDate = new Date(this.zoomEnd);
        this.updateTruckDate();
      }
    },
  },
};
</script>

<style scoped>
.zoomDate {
  margin-bottom: 33px;
  margin-top: -10px;
  font-size: 13px;
  margin-left: 7px;
}
</style>

<style>
.truckSelect .v-chip {
  overflow: initial;
}
.truckSelect .v-select__selections {
  overflow: visible;
  flex-wrap: wrap !important;
}
</style>
