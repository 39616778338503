<template>
  <div class="plan-block">
    <v-card>
      <v-card-title class="justify-center">Edit Test Plan</v-card-title>
      <v-divider></v-divider>
      <v-form ref="form" v-model="formValid" lazy-validation>
        <v-container>
          <v-row>
            <v-col sm="3" class="formLabel">Select Plan</v-col>
            <v-col sm="9">
              <v-select
                v-model="selectedPlan"
                :items="planItems"
                :rules="selectedPlanRules"
                class="formInput"
                outlined
                label="Choose Plan"
                required
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="3" class="formLabel">Plan Name</v-col>
            <v-col sm="9">
              <v-text-field
                v-model="name"
                class="formInput"
                :rules="nameRules"
                outlined
                label="Add Name"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="3" class="formLabel">Description</v-col>
            <v-col sm="9">
              <v-text-field
                v-model="description"
                class="formInput"
                outlined
                label="Add Description (not required)"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="3" class="formLabel">Truck: </v-col>
            <v-col>
              <v-select
                v-model="selectedTruck"
                :items="truckItems"
                :rules="truckRules"
                class="formInput"
                outlined
                label="Choose Truck"
                required
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="3" class="formLabel">Delivery Date: </v-col>
            <v-col sm="9">
              <v-text-field
                v-model="deliveryDate"
                v-mask="'##/##/####'"
                :rules="devDateRules"
                label="Delivery Date (MM/DD/YYYY)"
                class="formInput"
                type="text"
                outlined
                append-icon="mdi-calendar"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="3" class="formLabel">Select Stage</v-col>
            <v-col sm="9">
              <v-select
                v-model="selectedStage"
                :items="stageItems"
                :rules="stageRules"
                class="formInput"
                outlined
                label="Choose Stage"
                required
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="3" class="formLabel">Completed:</v-col>
            <v-col sm="9">
              <v-switch v-model="completed"></v-switch>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="cancel"> Cancel </v-btn>
          <v-btn :disabled="!formValid" color="primary" @click="exportPlan">
            Save Changes
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import _ from 'lodash';
import { currentDateInput } from '@/utilities/dateFunctions';
import { getAllTrucks } from '@/api/trucks';
import { getSteps, getTests } from '@/api/manufacturing';
import dateFormat from 'dateformat';

export default {
  name: 'EditPlan',
  props: {
    planData: { type: Array, default: null, required: true },
    stageData: { type: Array, default: null, required: true },
    toggle: { type: Function, default: null, required: true },
    updateTestPlan: { type: Function, default: null, required: true },
  },
  data() {
    return {
      addDisabled: true,
      completed: false,
      deliveryDate: '',
      description: '',
      devDateRules: [
        (v) => !!v || 'Date is required',
        (v) =>
          (v && v.length === 10) || 'Delivery date must be mm/dd/yyyy (10)',
      ],
      failed_tests: 0,
      formValid: false,
      lastTester: 'N/A',
      name: '',
      nameRules: [
        (v) => !!v || 'Name is required',
        (v) =>
          (v && v.length >= 2) || 'Name must be at least 2 or more characters',
      ],
      origDeliveryDate: '',
      planItems: [],
      selectedPlan: '',
      selectedPlanID: 0,
      selectedPlanRules: [(v) => !!v || 'Required!'],
      selectedStage: '',
      selectedTruck: '',
      stageItems: [],
      stageRules: [(v) => !!v || 'Required!'],
      stage_id: 1,
      status: '',
      statusItems: ['Passed', 'Failed', 'Untested'],
      step_count: 0,
      test_count: 0,
      truckItems: [],
      truckRules: [(v) => !!v || 'Required!'],
      trucks: [],
    };
  },
  watch: {
    selectedPlan: function (val) {
      if (val) {
        const thisPlan = this.matchPlanInfo(val);
        this.selectedPlanID = thisPlan.id;
        this.name = thisPlan.name;
        this.selectedTruck = thisPlan.truck_number;
        this.stage_id = thisPlan.stage_id;
        if (thisPlan.description) {
          this.description = thisPlan.description;
        }
        this.deliveryDate = this.convertDateTime(thisPlan.delivery_date);
        this.origDeliveryDate = this.convertDateTime(thisPlan.delivery_date);
        this.selectedStage = this.getStageName(thisPlan.stage_id);
        this.completed = thisPlan.completed;
        this.getCounts();
      }
    },
    selectedStage: function (val) {
      if (val) {
        this.stage_id = this.getStageId(val);
      }
    },
    formValid() {
      this.formValid =
        this.name &&
        this.deliveryDate &&
        this.selectedTruck !== '' &&
        this.selectedStage !== '' &&
        this.selectedPlan !== '';
    },
    deliveryDate(val) {
      const vm = this;
      if (val && val.length === 10) {
        const newDate = vm.convertEpochTime(vm.deliveryDate);
        const nowDate = vm.convertEpochTime(
          dateFormat(new Date(), 'mm/dd/yyyy')
        );
        if (newDate < nowDate) {
          vm.deliveryDate = vm.origDeliveryDate;
          vm.updateSnack({
            type: 'warning',
            message: 'Delivery Date cannot be set before today.',
          });
        }
      }
      if (val && val.length < 6) {
        this.deliveryDate = currentDateInput(val);
      }
    },
  },
  async mounted() {
    this.$forceUpdate();
    await this.initialize();
    await this.getTrucks();
  },
  methods: {
    ...mapActions({
      updateSnack: 'updateSnack',
    }),
    cancel() {
      this.$refs.form.reset();
      this.deliveryDate = '';
      this.toggle();
    },
    convertDateTime: function (unix_time) {
      const new_time = unix_time * 1000;
      return dateFormat(new_time, 'mm/dd/yyyy', true);
    },
    convertEpochTime: function (setDate) {
      const newTime = new Date(setDate).getTime() / 1000;
      return _.round(newTime, 0);
    },
    countTests: async function (steps) {
      let testsArray = [];
      let failed = 0;
      let testCount = 0;
      let testObj = {};
      // Now we get the test series for each step and append to our step data array
      for (const step of steps) {
        let step_id = step.id;
        let testItem = await getTests(step_id);
        testsArray = _.concat(testsArray, testItem.data);
        testCount += testItem.data.length;
      }
      _.forEach(testsArray, function (test) {
        if (test.test_status === false) {
          failed++;
        }
      });
      testObj.test_count = testCount;
      testObj.failed_tests = failed;
      return testObj;
    },
    exportPlan: async function () {
      if (!this.$refs.form.validate()) {
        return;
      }
      let dataObj = this.matchPlanInfo(this.selectedPlan);
      const aDate = new Date();
      const truckInfo = await this.matchTruckInfo();
      dataObj.stage_id = this.stage_id;
      dataObj.activity_date = this.convertEpochTime(aDate);
      dataObj.delivery_date = this.convertEpochTime(this.deliveryDate);
      dataObj.truck_id = truckInfo.id;
      dataObj.truck_number = this.selectedTruck;
      dataObj.step_count = this.step_count;
      dataObj.test_count = this.test_count;
      dataObj.failed_tests = this.failed_tests;
      dataObj.name = this.name;
      dataObj.description = this.description;
      dataObj.completed = this.completed;
      this.updateTestPlan(this.selectedPlanID, dataObj);
      this.$refs.form.reset();
      this.deliveryDate = null;
    },
    getCounts: async function () {
      const vm = this;
      const res1 = await getSteps(vm.selectedPlanID);
      vm.step_count = res1.data.length;
      let [testInfo] = await Promise.all([this.countTests(res1.data)]);
      vm.test_count = testInfo.test_count;
      vm.failed_tests = testInfo.failed_tests;
    },
    getStageId: function (stage_name) {
      const vm = this;
      let stageObj = _.find(vm.stageData, function (o) {
        return o.name === stage_name;
      });
      return stageObj.id;
    },
    getStageName: function (stage_id) {
      const vm = this;
      let stageObj = _.find(vm.stageData, function (o) {
        return o.id === stage_id;
      });
      return stageObj.name;
    },
    getTrucks: async function () {
      const vm = this;
      try {
        const res = await getAllTrucks();
        vm.trucks = res.data;
        _.forEach(res.data, function (o) {
          vm.truckItems.push(o.name);
        });
      } catch (e) {
        this.updateSnack({
          type: 'error',
          message: 'Axios error on getting trucks ==> ' + e,
        });
      }
    },
    initialize: function () {
      const vm = this;
      vm.planItems = [];
      vm.stageItems = [];
      _.forEach(vm.planData, function (plan) {
        vm.planItems.push(plan.name);
      });
      _.forEach(vm.stageData, function (stage) {
        vm.stageItems.push(stage.name);
      });
    },
    matchPlanInfo: function (currentPlan) {
      const vm = this;
      // use truck_name/number since that is the plan name
      return _.find(vm.planData, function (o) {
        return o.name === currentPlan;
      });
    },
    matchTruckInfo: function () {
      const vm = this;
      return _.find(vm.trucks, function (o) {
        return o.name === vm.selectedTruck;
      });
    },
  },
};
</script>

<style scoped>
.plan-block {
  display: block;
  position: relative;
  width: 60%;
  margin: 0 auto;
}
.formLabel {
  display: block;
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;
}
.formInput {
  margin-bottom: -20px;
  /* padding: -10px; */
}
</style>
