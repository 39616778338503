var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-img",
    _vm._g(
      {
        attrs: {
          src: require(`../../public/images/icons/dtc_indicators/${_vm.iconShow}.png`),
          "max-width": "50px",
          "max-height": "42px",
          contain: "",
        },
      },
      _vm.on
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }