<template>
  <v-data-table
    :headers="alertHistoryHeaders"
    :items="alerts"
    :options.sync="alertsOptions"
    :server-items-length="alertsCount"
    :loading="alertsLoading"
    class="elevation-1"
  >
  </v-data-table>
</template>
<script>
import { getAlerts, getAlertsCount } from '@/api/alerts';
import dateFormat from 'dateformat';
export default {
  name: 'Alerts',
  data() {
    return {
      pageLoadTime: Date.now(),
      alertsLoading: true,
      alertsCount: 0,
      alertsOptions: {},
      alerts: [],
      alertHistoryHeaders: [
        { text: 'Condition', value: 'condition_name' },
        { text: 'Trigger Value', value: 'trigger_value' },
        { text: 'Description', value: 'description', sortable: false },
        { text: 'Truck ID', value: 'detail.truck_id' },
        { text: 'Company ID', value: 'detail.company_id' },
        { text: 'Created At', value: 'created_at' },
        { text: 'Sent Time', value: 'sent_time' },
      ],
    };
  },
  watch: {
    alertsOptions: {
      handler() {
        this.getAlertsApi();
      },
      deep: true,
    },
  },
  async mounted() {
    try {
      this.alertsCount = await getAlertsCount(this.pageLoadTime);
    } catch (e) {
      console.log(e, 'error in retrieving the alerts subscriptions');
    }
  },
  methods: {
    async getAlertsApi() {
      // TODO: implement caching to avoid making the same calls multiple times
      this.alertsLoading = true;
      let { sortBy, sortDesc, itemsPerPage, page } = this.alertsOptions;
      let orderBy = '';
      let orderDir = '';
      if (sortBy.length === 1 && sortDesc.length === 1) {
        orderBy = sortBy[0];
        orderDir = sortDesc[0] === false ? 'ASC' : 'DESC';
      }
      if (itemsPerPage === -1) {
        itemsPerPage = this.alertsCount;
      }
      this.alerts = await getAlerts(
        this.pageLoadTime,
        page,
        itemsPerPage,
        orderBy,
        orderDir
      );

      this.alerts.forEach((alert) => {
        (alert.created_at = dateFormat(
          alert.created_at,
          'mm/dd/yyyy hh:MM TT'
        )),
          (alert.sent_time = dateFormat(
            alert.sent_time,
            'mm/dd/yyyy hh:MM TT'
          ));
      });
      this.alertsLoading = false;
    },
  },
};
</script>
