var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mainContainer" }, [
    _c(
      "div",
      { staticClass: "leftCol" },
      [
        _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
        _vm.dataMounted
          ? _c("highcharts", {
              ref: "donutChartA",
              attrs: { id: "donut1", options: _vm.options },
            })
          : _vm._e(),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "legend-area", style: { columnCount: _vm.numCols } },
      _vm._l(_vm.data, function (item, index) {
        return _c("div", { key: index, staticClass: "legend-item" }, [
          _c("div", { staticClass: "legend-text" }, [
            _c("span", {
              staticClass: "dot",
              style: { backgroundColor: `${item.color}` },
            }),
            _vm._v(" " + _vm._s(item.name) + " "),
          ]),
          _c("div", { staticClass: "subtext" }, [
            _vm._v(_vm._s(`${Math.round(item.y)} min`)),
          ]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }