var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-data-table", {
    staticClass: "elevation-1",
    attrs: {
      headers: _vm.alertHistoryHeaders,
      items: _vm.alerts,
      options: _vm.alertsOptions,
      "server-items-length": _vm.alertsCount,
      loading: _vm.alertsLoading,
    },
    on: {
      "update:options": function ($event) {
        _vm.alertsOptions = $event
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }