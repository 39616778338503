var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { staticClass: "pa-0" },
        [
          _c("v-col", { staticClass: "pa-0" }, [
            _vm._v(_vm._s(_vm.caseData.length) + " results"),
          ]),
          _c(
            "v-col",
            { staticClass: "pa-0", attrs: { align: "right" } },
            [
              _vm.userCanDownloadVinComparisons
                ? _c(
                    "v-btn",
                    {
                      staticClass: "mx-1",
                      staticStyle: { color: "black" },
                      attrs: { color: "primary" },
                      on: { click: _vm.exportCSV },
                    },
                    [
                      _c(
                        "download-csv",
                        {
                          attrs: {
                            id: "csv",
                            name: "truck_comparisons.csv",
                            data: _vm.assets,
                          },
                        },
                        [
                          _c("v-icon", { attrs: { id: "csv2" } }, [
                            _vm._v("mdi-download"),
                          ]),
                          _vm._v(" Download Vin Comparisons "),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-tooltip",
                {
                  attrs: { left: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on, attrs }) {
                        return [
                          _vm.userCanAddCase
                            ? _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticStyle: { color: "black" },
                                      attrs: {
                                        color: "primary",
                                        rounded: "",
                                        fab: "",
                                        small: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.addCase = true
                                        },
                                      },
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [_c("v-icon", [_vm._v("mdi-plus")])],
                                1
                              )
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                },
                [_c("span", [_vm._v("Add case")])]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pa-0", attrs: { cols: "12" } },
            [
              _c("v-data-table", {
                attrs: {
                  loading: _vm.loading,
                  "no-data-text": _vm.noDataMsg,
                  headers: _vm.labels,
                  items: _vm.caseData,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: `item.actions`,
                      fn: function ({ item }) {
                        return [
                          _c(
                            "v-menu",
                            {
                              staticStyle: { top: "312px", left: "300px" },
                              attrs: { "offset-y": "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c("v-icon", _vm._g({}, on), [
                                          _vm._v("mdi-dots-vertical"),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _c(
                                "v-list",
                                { attrs: { dense: "" } },
                                [
                                  _c(
                                    "v-list-item-group",
                                    _vm._l(
                                      _vm.actionItems,
                                      function (action, index) {
                                        return _c(
                                          "v-list-item",
                                          {
                                            key: index,
                                            class: action.title,
                                            on: {
                                              click: function ($event) {
                                                return _vm.setAction(
                                                  item,
                                                  action.title
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "v-list-item-icon",
                                              { staticClass: "mr-2" },
                                              [
                                                _c("v-icon", {
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      action.icon
                                                    ),
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-list-item-title", {
                                                  staticClass: "pr-2",
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      action.title
                                                    ),
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.addCase
        ? _c("AddCase", {
            attrs: { "asset-id": _vm.assetId, vin: _vm.vin },
            on: { closed: _vm.closeCase, refresh: _vm.refreshData },
          })
        : _vm._e(),
      _c(
        "v-dialog",
        {
          staticClass: "dialog",
          attrs: { width: "750" },
          model: {
            value: _vm.openDetails,
            callback: function ($$v) {
              _vm.openDetails = $$v
            },
            expression: "openDetails",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-text",
                _vm._l(_vm.caseDetail, function (value, propertyName, index) {
                  return _c(
                    "v-row",
                    { key: index },
                    [
                      _c("v-col", [
                        _c("strong", [_vm._v(_vm._s(propertyName))]),
                        _vm._v(": " + _vm._s(value)),
                      ]),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }