<template>
  <v-dialog v-model="show" max-width="600">
    <v-card>
      <v-card-title>Load Study</v-card-title>
      <v-card-text>Choose template for a study</v-card-text>
      <v-divider></v-divider>
      <v-form ref="form" v-model="formValid">
        <v-container>
          <v-row>
            <v-col sm="3" class="formLabel"> Templates: </v-col>
            <v-col>
              <v-select
                v-model="template"
                :items="templates"
                return-object
                item-text="name"
                label="Select Template"
                :rules="[rules.required]"
                outlined
              ></v-select>
            </v-col>
          </v-row>
          <v-row v-if="template" style="margin-bottom: 10px">
            <v-col sm="3" style="text-align: center"> Description: </v-col>
            <v-col>
              <div>
                {{ template.description }}
              </div>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="() => (show = false)"> Cancel </v-btn>
          <v-btn color="primary" @click="loadStudy"> Load Study </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'LoadStudy',
  props: ['templates', 'addStudy'],
  data() {
    return {
      formValid: null,
      show: true,
      template: null,
      rules: {
        required: (value) => {
          if (value) return true;
          return 'Required';
        },
      },
    };
  },
  watch: {
    // emit when closed to reset outer scope variable
    show() {
      if (!this.show) {
        this.$emit('closed');
      }
    },
  },
  methods: {
    loadStudy() {
      if (!this.$refs.form.validate()) return;
      this.show = false;
      this.addStudy(this.template);
    },
  },
};
</script>

<style scoped>
.formLabel {
  display: block;
  margin-top: 14px;
  margin-bottom: auto;
  text-align: center;
}
</style>
