var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {
    ref: `csChart${_vm.id}`,
    attrs: { id: `candle${_vm.id}`, options: _vm.csChartOptions },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }