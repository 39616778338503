<template>
  <v-card>
    <v-card-title>Add Test</v-card-title>
    <v-card-text>Fill in all fields to add a new test</v-card-text>
    <v-divider></v-divider>
    <v-form ref="form" v-model="formValid" lazy-validation>
      <v-container>
        <v-row>
          <v-col sm="3" class="formLabel">Category: </v-col>
          <v-col>
            <v-text-field
              v-model="assignedCategory"
              class="formInput"
              outlined
              label="Will display assigned category from selected step"
              disabled
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="3" class="formLabel">Test Name*:</v-col>
          <v-col sm="9">
            <v-text-field
              v-model="name"
              :rules="nameRules"
              class="formInput"
              outlined
              label="Add Name"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="3" class="formLabel">Step*: </v-col>
          <v-col>
            <v-select
              v-model="selectedStep"
              :items="stepItems"
              :rules="stepRules"
              class="formInput"
              outlined
              label="Choose Step"
              required
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="3" class="formLabel">Test Procedure: </v-col>
          <v-col>
            <v-textarea
              v-model="test_procedure"
              outlined
              label="Enter Procedure"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="3" class="formLabel">Expected Results: </v-col>
          <v-col>
            <v-textarea
              v-model="exp_results"
              outlined
              label="Enter Expected Results"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="3" class="formLabel">Conditions: </v-col>
          <v-col>
            <v-textarea
              v-model="pre_conditions"
              outlined
              label="Enter Pre-Conditions"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="cancel"> Cancel </v-btn>
        <v-btn :disabled="!formValid" color="primary" @click="exportTest">
          Add Test
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import _ from 'lodash';
export default {
  name: 'AddTest',
  props: {
    testsDialog: Boolean,
    toggle: { type: Function, default: null, required: true },
    addStepTest: { type: Function, default: null, required: true },
    filteredSteps: { type: Array, default: null, required: true },
    categories: { type: Array, default: null, required: true },
  },
  data() {
    return {
      addDisabled: true,
      assignedCategory: '',
      exp_results: '',
      formValid: false,
      name: '',
      nameRules: [
        (v) => !!v || 'Name is required',
        (v) =>
          (v && v.length >= 2) || 'Name must be at least 2 or more characters',
      ],
      pre_conditions: '',
      selectedStep: '',
      stepItems: [],
      stepRules: [(v) => !!v || 'Required!'],
      test_procedure: '',
    };
  },
  watch: {
    formValid: function () {
      this.formValid = !(
        this.name === '' &&
        this.status === '' &&
        this.selectedStep === '' &&
        this.lastTester === ''
      );
    },
    selectedStep(val) {
      if (val) {
        const vm = this;
        const step = _.find(vm.filteredSteps, function (o) {
          return o.name === vm.selectedStep;
        });
        const cat_id = step.category_id;
        const cat = _.find(vm.categories, function (o) {
          return o.id === cat_id;
        });
        vm.assignedCategory = cat.name;
      }
    },
  },
  mounted() {
    this.$forceUpdate(); // component not refreshing even with a random key
    this.buildStepList();
  },
  methods: {
    buildStepList: function () {
      const vm = this;
      _.forEach(vm.filteredSteps, function (o) {
        vm.stepItems.push(o.name);
      });
    },
    convertEpochTime: function (setDate) {
      const newTime = new Date(setDate).getTime() / 1000;
      return _.round(newTime, 0);
    },
    async exportTest() {
      if (!this.$refs.form.validate()) {
        return;
      }
      let dataObj = {};
      dataObj.step_id = this.getStepID();
      dataObj.name = this.name;
      if (this.test_procedure) {
        dataObj.test_procedure = this.test_procedure;
      }
      if (this.exp_results) {
        dataObj.exp_results = this.exp_results;
      }
      if (this.pre_conditions) {
        dataObj.pre_conditions = this.pre_conditions;
      }
      this.addStepTest(dataObj);
      this.cancel();
    },
    getStepID: function () {
      const vm = this;
      let step = _.find(vm.filteredSteps, function (o) {
        return o.name === vm.selectedStep;
      });
      return parseInt(step.id, 10);
    },
    cancel() {
      this.$refs.form.reset();
      this.toggle();
    },
  },
};
</script>

<style scoped>
.formLabel {
  display: block;
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;
}
.formInput {
  margin-bottom: -20px;
  /* padding: -10px; */
}
</style>
