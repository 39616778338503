import axios from 'axios';
const BASE_URL = process.env.VUE_APP_API_PATH_BASE;

async function getJourney(
  company_id,
  start,
  end,
  journey_only = true,
  truck_id = null,
  geozones = null
) {
  let queryString = `?start=${start}&end=${end}&isJourneysOnly=${journey_only}`;
  if (truck_id) {
    queryString += `&truckIds=${truck_id}`;
  }
  if (geozones) {
    queryString += `&geozoneIds=${geozones}`;
  }
  const res = await axios.get(
    BASE_URL + `external/journey/${company_id}${queryString}`
  );
  return res.data;
}

async function updateJourneyName(segment_id, data) {
  return await axios.patch(
    BASE_URL + `external/journey/meta_data/${segment_id}`,
    data
  );
}
export { getJourney, updateJourneyName };
