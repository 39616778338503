var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("v-row", [
        _c("h1", { staticStyle: { "font-weight": "400" } }, [
          _vm._v("ERX Dashboard"),
        ]),
      ]),
      _vm.showAlert
        ? _c(
            "v-row",
            [
              _c(
                "v-card",
                {
                  staticClass: "pa-2 error",
                  staticStyle: {
                    display: "block",
                    margin: "auto",
                    "text-align": "center",
                    color: "black",
                  },
                  attrs: { elevation: "4" },
                },
                [
                  _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.alertType + ": " + _vm.alertMessage) +
                        " "
                    ),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        { staticClass: "pb-0" },
        [
          _c("v-col", [_c("v-spacer")], 1),
          _c(
            "v-col",
            { attrs: { cols: "10" } },
            [
              _vm.appWarning
                ? _c(
                    "v-card",
                    {
                      staticClass: "pa-2 error",
                      class: _vm.hideBlock,
                      staticStyle: { "text-align": "center", color: "black" },
                      attrs: { elevation: "4" },
                    },
                    [
                      _c("span", [
                        _vm._v(
                          "WARNING: No functionality, data, or analysis within this application should be used for business purposes at this time. We are excited to have your input, questions, and suggestions on the User Portal, however the application should be considered a prototype only and not relied on for business or customer impacting use at this time. If you find issues, need help, or have suggestions please feel free to reach out to Bill Clements with feedback (which will always be welcome)."
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("v-col", [_c("v-spacer")], 1),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "chart-bg", attrs: { cols: "6" } },
            [
              _c(
                "v-card",
                { staticClass: "block-bg", attrs: { elevation: "0" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "block-bg",
                      staticStyle: { display: "grid", "min-height": "300px" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "20px",
                            "padding-left": "10px",
                            "z-index": "1",
                          },
                        },
                        [_vm._v(" All ERX Trucks Overview"), _c("br")]
                      ),
                      _c("PieCharts"),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "chart-bg", attrs: { cols: "6" } },
            [
              _c(
                "v-card",
                { staticClass: "chart-bg", attrs: { elevation: "0" } },
                [_c("LineCharts")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c(
                "v-tabs",
                {
                  model: {
                    value: _vm.tab,
                    callback: function ($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab",
                  },
                },
                [_c("v-tab", [_vm._v("Active DTCs")])],
                1
              ),
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.tab,
                    callback: function ($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab",
                  },
                },
                [
                  _c(
                    "v-tab-item",
                    [
                      _c(
                        "v-card",
                        { attrs: { flat: "" } },
                        [
                          _c("v-data-table", {
                            staticClass:
                              "elevation-1 secondary locked-table-style row-height-50",
                            attrs: {
                              headers: _vm.headers,
                              items: _vm.dtcTrucks,
                              "items-per-page": 10,
                              loading: _vm.loading,
                              "loading-text": "Loading... Please wait",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: `item.truckName`,
                                  fn: function ({ item }) {
                                    return [
                                      _c("th", { staticClass: "body-5" }, [
                                        _vm._v(_vm._s(item.truckName)),
                                      ]),
                                      _c("div", { staticClass: "body-6" }, [
                                        _vm._v(_vm._s(item.company)),
                                      ]),
                                    ]
                                  },
                                },
                                {
                                  key: `item.actions`,
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "v-menu",
                                        {
                                          staticStyle: {
                                            top: "312px",
                                            left: "300px",
                                          },
                                          attrs: { "offset-y": "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on }) {
                                                  return [
                                                    _c(
                                                      "v-icon",
                                                      _vm._g({}, on),
                                                      [
                                                        _vm._v(
                                                          "mdi-dots-vertical"
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _c(
                                            "v-list",
                                            _vm._l(
                                              _vm.actionItems,
                                              function (route, index) {
                                                return _c(
                                                  "v-list-item",
                                                  {
                                                    key: index,
                                                    class: route.title,
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.setRoute(
                                                          item,
                                                          route.title
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(_vm._s(route.title))]
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-col", { attrs: { cols: "6" } }, [
            _c("div", { staticClass: "data", attrs: { id: "id-td-MapView" } }, [
              _c("div", { attrs: { id: "map" } }, [
                _c("div", {
                  ref: "hereMap",
                  style: `height: ${_vm.mapHeight}px; width: 100%`,
                  attrs: { id: "mapContainer" },
                }),
              ]),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }