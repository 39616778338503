import '@babel/polyfill';
import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import 'vuetify/dist/vuetify.css';
import { default as msalPlugin } from 'vue-msal-browser';
import { router } from './router/routes';
import store from './store/store';
import JsonCSV from 'vue-json-csv';
import '../assets/scss/main.scss';
import VueMask from 'v-mask';
import HighchartsVue from 'highcharts-vue';
import VueGtag from 'vue-gtag';
import DatetimePicker from 'vuetify-datetime-picker';

Vue.use(
  VueGtag,
  {
    config: { id: 'G-V2ZKJ4MS9B' },
  },
  router
);

Vue.use(VueMask);
Vue.use(DatetimePicker);
Vue.component('downloadCsv', JsonCSV);

Vue.use(HighchartsVue);

const msalConfig = {
  auth: {
    tenant: process.env.VUE_APP_TENANT,
    clientId: process.env.VUE_APP_CLIENT_ID,
    authority: process.env.VUE_APP_AUTHORITY,
    redirectUri: window.location.origin, // It has to be configured on your Azure tenant
    scopes: [process.env.VUE_APP_SCOPES],
  },
  cache: {
    cacheLocation: 'localStorage',
  },
  graph: {
    url: 'https://graph.microsoft.com',
    scopes: 'User.Read',
  },
  mode: 'redirect',
};

Vue.use(msalPlugin, msalConfig);

Vue.config.productionTip = false;

Vue.use(vuetify, {
  iconfont: 'mdi',
});

new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
}).$mount('#app');
