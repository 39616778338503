// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../public/images/assets/TruckGraphic.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n#TruckGraphic {\n  width: 1869px;\n  height: 800px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;\n}\n.left {\n  text-align: left;\n}\n.center {\n  text-align: center;\n}\n/* Custom toolbar config*/\n#external-toolbar-tab .v-tab--active {\n  background-color: #272727;\n}\n#external-toolbar-tab .v-tabs-slider-wrapper {\n  top: 0 !important;\n}\n/* removes unwanted prefix gap in v-tabs  */\n.v-slide-group__prev {\n  display: none !important;\n}\n", ""]);
// Exports
module.exports = exports;
