var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "trucks" } },
    [
      _vm.loading ? _c("loader") : _vm._e(),
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "py-0" },
                [
                  _c("v-data-table", {
                    staticClass:
                      "elevation-1 secondary locked-table-style row-height-50",
                    attrs: {
                      headers: _vm.headers,
                      items: _vm.erxTrucks,
                      search: _vm.searchText,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: `item.name`,
                          fn: function (props) {
                            return [
                              _c("th", { staticClass: "body-5" }, [
                                _vm._v(_vm._s(props.item.number)),
                              ]),
                              _c("div", { staticClass: "body-6" }, [
                                _vm._v(_vm._s(props.item.company)),
                              ]),
                            ]
                          },
                        },
                        {
                          key: `item.actions`,
                          fn: function ({ item }) {
                            return [
                              _c(
                                "v-menu",
                                {
                                  staticStyle: { top: "312px", left: "300px" },
                                  attrs: { "offset-y": "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on }) {
                                          return [
                                            _c("v-icon", _vm._g({}, on), [
                                              _vm._v("mdi-dots-vertical"),
                                            ]),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _c(
                                    "v-list",
                                    { attrs: { dense: "" } },
                                    [
                                      _c(
                                        "v-list-item-group",
                                        _vm._l(
                                          _vm.actionItems,
                                          function (action, index) {
                                            return _c(
                                              "v-list-item",
                                              {
                                                key: index,
                                                class: action.title,
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.setAction(
                                                      item,
                                                      action.title
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-list-item-icon",
                                                  { staticClass: "mr-2" },
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v(
                                                        _vm._s(action.icon)
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-list-item-content",
                                                  [
                                                    _c("v-list-item-title", {
                                                      staticClass: "pr-2",
                                                    }),
                                                    _vm._v(
                                                      _vm._s(action.title) + " "
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: { width: "500", persistent: "" },
              model: {
                value: _vm.assignPackageModal,
                callback: function ($$v) {
                  _vm.assignPackageModal = $$v
                },
                expression: "assignPackageModal",
              },
            },
            [
              _c(
                "v-card",
                { staticClass: "pa-5 text-center" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-select", {
                            staticClass: "ma-1",
                            attrs: {
                              items: _vm.availableStates,
                              "return-object": "",
                              clearable: "",
                              "hide-selected": "",
                              outlined: "",
                              label: "Select State",
                            },
                            on: { change: _vm.filterPackages },
                            model: {
                              value: _vm.stateSelected,
                              callback: function ($$v) {
                                _vm.stateSelected = $$v
                              },
                              expression: "stateSelected",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-select", {
                            staticClass: "ma-1",
                            attrs: {
                              items: _vm.packagesDup,
                              "item-text": "config_name",
                              "item-value": "id",
                              "return-object": "",
                              clearable: "",
                              "hide-selected": "",
                              outlined: "",
                              "no-data-text": "No Packages Available",
                              label: "Select Package",
                            },
                            model: {
                              value: _vm.selectedPackage,
                              callback: function ($$v) {
                                _vm.selectedPackage = $$v
                              },
                              expression: "selectedPackage",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-2",
                              attrs: { color: "primary", outlined: "" },
                              on: {
                                click: function ($event) {
                                  _vm.assignPackageModal = false
                                },
                              },
                            },
                            [_vm._v("Dismiss")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-btn",
                            {
                              staticStyle: { color: "black" },
                              attrs: {
                                color: "primary",
                                disabled:
                                  !_vm.selectedPackage || _vm.submitClicked,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.assignPackage()
                                },
                              },
                            },
                            [_vm._v("Confirm")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: { width: "530", persistent: "" },
              model: {
                value: _vm.reassignCompanyDialog,
                callback: function ($$v) {
                  _vm.reassignCompanyDialog = $$v
                },
                expression: "reassignCompanyDialog",
              },
            },
            [
              _c(
                "v-card",
                { staticClass: "pa-5 text-center" },
                [
                  _c(
                    "v-row",
                    [_c("v-col", [_c("h3", [_vm._v("Re-Assign Company")])])],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "pb-0" },
                        [
                          _c("v-select", {
                            staticClass: "ma-1",
                            attrs: {
                              items: _vm.companyList,
                              "item-text": "name",
                              "item-value": "id",
                              clearable: "",
                              "hide-selected": "",
                              outlined: "",
                              label: "Select New Company",
                            },
                            model: {
                              value: _vm.selectedCompany,
                              callback: function ($$v) {
                                _vm.selectedCompany = $$v
                              },
                              expression: "selectedCompany",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c("v-col", [
                        _c("span", { staticClass: "bold Warning" }, [
                          _vm._v(
                            "WARNING: This change will affect the data flow for this truck and will no longer report data for the current company it is assigned to!"
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-2",
                              attrs: { color: "primary", outlined: "" },
                              on: {
                                click: function ($event) {
                                  _vm.reassignCompanyDialog = false
                                },
                              },
                            },
                            [_vm._v("Dismiss")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-btn",
                            {
                              staticStyle: { color: "black" },
                              attrs: {
                                color: "primary",
                                disabled:
                                  !_vm.selectedCompany || _vm.submitClicked,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.reassignCompany()
                                },
                              },
                            },
                            [_vm._v("Confirm")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }