<template>
  <v-container fluid>
    <v-row>
      <v-card flat>
        <v-data-table
          :headers="dataHeaders"
          :items="source"
          :items-per-page="5"
          item-key="id"
          class="elevation-1"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-menu style="top: 312px; left: 300px" offset-y>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">mdi-dots-vertical</v-icon>
              </template>
              <v-list>
                <v-list-item
                  v-for="(route, index) in actionItems"
                  :key="index"
                  :class="route.title"
                  @click="deleteEditOnClick(item, route.title)"
                  >{{ route.title }}</v-list-item
                >
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

import { deleteSignal } from '@/api/dataDictionary';

export default {
  name: 'Signals',
  props: ['selectedDb', 'source', 'dataHeaders'],
  data() {
    return {
      actionItems: [{ title: 'Delete' }, { title: 'Edit' }],
      dbTypes: [
        { state: 'Sample B Data', abbr: 'sampleBData' },
        { state: 'EX One Minute', abbr: 'exOneMinute' },
        { state: 'Ex Telemetry', abbr: 'ExTelemetry' },
      ],
      signal: [],
    };
  },
  computed: {
    ...mapGetters({
      storedSignal: 'getSignal',
      isEditSignal: 'getIsEditSignal',
    }),
  },
  methods: {
    ...mapMutations([
      'updateDataDictionaryDb',
      'updateIsEditSignal',
      'updateSignal',
    ]),
    deleteEditOnClick: async function (signal, route) {
      if (route === 'Edit') {
        // Make edit form
        const dbOption = this.dbTypes.find(
          (ele) => ele.abbr === signal.adx_db_table
        );
        this.updateSignal(signal);
        this.updateDataDictionaryDb(dbOption);
        this.updateIsEditSignal(signal.id);
        this.$router.push(`/AddSignal`);
      } else if (route === 'Delete') {
        this.deleteSignalAction(signal);
      }
    },
    deleteSignalAction: async function (signal) {
      try {
        const id = signal.id ? signal.id : '';
        const response = await deleteSignal(id);
        if (response) {
          this.getData();
        }
      } catch (e) {
        console.log(
          e,
          'Error: something went wrong while trying to delete the Signal'
        );
      }
    },
    editSignalAction: async function () {},
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media only screen and (min-width: 960px) {
  .select {
    max-width: 60px;
    max-height: 60px;
    font-size: 11px;
  }
  .primary-button {
    background-color: #48ff48;
  }
  .secondary-button {
    border-color: #48ff48;
  }
}
</style>
