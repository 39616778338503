var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "map" } }, [
    _c("div", {
      ref: "hereMap",
      staticClass: "px-0",
      style: `height: ${_vm.mapHeight}px; width: 100%`,
      attrs: { id: "mapContainer" },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }