var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "pa-0" },
    [
      _c(
        "v-card",
        { staticClass: "secondary" },
        [
          _c("v-card-title", [_vm._v("Restrict Access to Features")]),
          _c("v-card-subtitle", [
            _vm._v(
              "Control the availability of specifric features, allowing them to either be enabled or disabled for different user roles or fleets"
            ),
          ]),
          _c(
            "v-row",
            { staticClass: "pa-1" },
            [
              _c(
                "v-col",
                {
                  staticClass: "d-flex align-center justify-center",
                  attrs: { cols: "2" },
                },
                [
                  _c("v-checkbox", {
                    model: {
                      value: _vm.performance,
                      callback: function ($$v) {
                        _vm.performance = $$v
                      },
                      expression: "performance",
                    },
                  }),
                ],
                1
              ),
              _c("v-col", { attrs: { cols: "10" } }, [
                _c("h4", [_vm._v("Performance")]),
                _c("span", { staticClass: "secondary-text" }, [
                  _vm._v(
                    "Configure and optimize the system's efficiency, responsiveness, and resource utilization to enhance the overall experience within the Hub."
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "pa-1" },
            [
              _c(
                "v-col",
                {
                  staticClass: "d-flex align-center justify-center",
                  attrs: { cols: "2" },
                },
                [
                  _c("v-checkbox", {
                    model: {
                      value: _vm.health,
                      callback: function ($$v) {
                        _vm.health = $$v
                      },
                      expression: "health",
                    },
                  }),
                ],
                1
              ),
              _c("v-col", { attrs: { cols: "10" } }, [
                _c("h4", [_vm._v("Health")]),
                _c("span", { staticClass: "secondary-text" }, [
                  _vm._v(
                    "Monitor system status, uptime, and overall performance to ensure optimal operation within the Hub."
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "pa-2" },
            [
              _c(
                "v-col",
                { staticClass: "mt-2 hidden-xs-and-down" },
                [_c("v-spacer")],
                1
              ),
              _c(
                "v-col",
                { staticClass: "mt-2", attrs: { align: "center", cols: "3" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { plain: "", color: "primary" },
                      on: { click: _vm.emitCancel },
                    },
                    [_vm._v(_vm._s(_vm.backButton))]
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "mt-2", attrs: { align: "center", cols: "4" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticStyle: { color: "black" },
                      attrs: { color: "primary" },
                      on: { click: _vm.emitCompanyPermissions },
                    },
                    [_vm._v(_vm._s(_vm.submitButton))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }