var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-container", { attrs: { align: "center" } }, [
    _c("div", { staticStyle: { "margin-top": "-20px" } }, [
      _c("div", { staticClass: "test-style" }, [
        _c(
          "div",
          { staticClass: "chart-area" },
          [
            _vm.statusLoaded
              ? _c("highcharts", {
                  ref: "donutChartA",
                  attrs: { id: "donut1", options: _vm.statusOptions },
                })
              : _c("v-progress-circular", {
                  staticStyle: {
                    position: "relative",
                    top: "38px",
                    left: "38px",
                  },
                  attrs: { indeterminate: "", size: 120, width: 10 },
                }),
          ],
          1
        ),
        _c("div", { staticClass: "legend-area" }, [
          _c("div", { staticClass: "graph-title" }, [_vm._v("Status")]),
          _c("ul", { staticClass: "graph-legend" }, [
            _c("li", [
              _c("span", { staticClass: "dot bg-red" }),
              _vm._v(" " + _vm._s(_vm.active) + " Active DTCs"),
            ]),
            _c("li", [
              _c("span", { staticClass: "dot bg-orange" }),
              _vm._v(" " + _vm._s(_vm.pending) + " Pending DTCs "),
            ]),
            _c("li", [
              _c("span", { staticClass: "dot bg-green" }),
              _vm._v(" " + _vm._s(_vm.normal) + " Normal"),
            ]),
            _c("li", [
              _c("span", { staticClass: "dot bg-gray" }),
              _vm._v(" " + _vm._s(_vm.noData) + " No Data"),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "test-style" }, [
        _c(
          "div",
          { staticClass: "chart-area" },
          [
            _vm.stateLoaded
              ? _c("highcharts", {
                  ref: "donutChartA",
                  attrs: { id: "donut1", options: _vm.stateOptions },
                })
              : _c("v-progress-circular", {
                  staticStyle: {
                    position: "relative",
                    top: "38px",
                    left: "38px",
                  },
                  attrs: { indeterminate: "", size: 120, width: 10 },
                }),
          ],
          1
        ),
        _c("div", { staticClass: "legend-area" }, [
          _c("div", { staticClass: "graph-title" }, [_vm._v("State")]),
          _c("ul", { staticClass: "graph-legend" }, [
            _c("li", [
              _c("span", { staticClass: "dot bg-green" }),
              _vm._v(" " + _vm._s(_vm.moving) + " Moving"),
            ]),
            _c("li", [
              _c("span", { staticClass: "dot bg-orange" }),
              _vm._v(" " + _vm._s(_vm.idle) + " Idle"),
            ]),
            _c("li", [
              _c("span", { staticClass: "dot bg-gray" }),
              _vm._v(" " + _vm._s(_vm.offline) + " Offline"),
            ]),
            _c("li", [
              _c("span", { staticClass: "dot bg-darkGray" }),
              _vm._v(" " + _vm._s(_vm.noData) + " No Data"),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }