import axios from 'axios';

const BASE_URL = process.env.VUE_APP_API_PATH_BASE;
// USING EXTERNAL/CUSTOMER API ROUTE
async function getSpnfmiStates() {
  return await axios.get(BASE_URL + 'dtc/spnfmi_states');
}

async function getdtcErxTable() {
  return await axios.get(BASE_URL + 'dtc/dtc_erx_table');
}

async function getDtcErxHistoryTable(truck_id, start, end) {
  let queryString = `?truck_id=${truck_id}`;
  start && (queryString += `&start=${start}`);
  end && (queryString += `&end=${end}`);
  return await axios.get(BASE_URL + `dtc/history/erx${queryString}`);
}

async function getServiceGuidesTable() {
  return await axios.get(BASE_URL + 'dtc/service_guides_table');
}

async function updateDtcTableLinks() {
  return await axios.patch(BASE_URL + 'dtc/update_dtc_table_links');
}

async function findDtcInSheet(dtcObj) {
  const config = {
    params: {
      dtcObj: dtcObj,
    },
  };
  return await axios.get(BASE_URL + 'dtc/find_dtc_in_files', config);
}

export {
  getSpnfmiStates,
  getdtcErxTable,
  getDtcErxHistoryTable,
  getServiceGuidesTable,
  updateDtcTableLinks,
  findDtcInSheet,
};
