var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      staticClass: "dialog",
      attrs: { width: "750" },
      model: {
        value: _vm.openDialog,
        callback: function ($$v) {
          _vm.openDialog = $$v
        },
        expression: "openDialog",
      },
    },
    [
      _vm.loading ? _c("loader") : _vm._e(),
      _vm.showAlert
        ? _c("alert", {
            attrs: {
              "alert-type": _vm.alertType,
              "alert-message": _vm.alertMessage,
            },
          })
        : _vm._e(),
      _c(
        "v-card",
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                { staticClass: "ma-2" },
                [
                  _c(
                    "v-col",
                    {
                      attrs: {
                        align: "center",
                        cols: "12",
                        sm: "6",
                        md: "6",
                        lg: "6",
                        xl: "6",
                      },
                    },
                    [
                      _c("div", { staticClass: "font-weight-bold text-h5" }, [
                        _vm._v("Selected Trucks"),
                      ]),
                      _c(
                        "v-card",
                        {
                          staticClass: "padding margin",
                          attrs: { align: "center" },
                        },
                        _vm._l(_vm.trucks, function (truck, index) {
                          return _c(
                            "v-chip",
                            {
                              key: index,
                              staticClass: "margin",
                              attrs: { color: "primary" },
                            },
                            [_vm._v(_vm._s(truck.name))]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      attrs: {
                        align: "center",
                        cols: "12",
                        sm: "6",
                        md: "6",
                        lg: "6",
                        xl: "6",
                      },
                    },
                    [
                      _c("div", { staticClass: "font-weight-bold text-h5" }, [
                        _vm._v(" Select Configuration to assign trucks "),
                      ]),
                      _c("div", { staticClass: "font-weight-bold" }, [
                        _vm._v(
                          " Note: The configuration will be assigned to all trucks selected. "
                        ),
                      ]),
                      _c("v-combobox", {
                        staticClass: "ma-1",
                        attrs: {
                          items: _vm.configurations,
                          "item-text": "config_name",
                          "item-value": "id",
                          "return-object": "",
                          clearable: "",
                          "hide-selected": "",
                          outlined: "",
                          label: "Select Configuration",
                        },
                        model: {
                          value: _vm.selectedConfig,
                          callback: function ($$v) {
                            _vm.selectedConfig = $$v
                          },
                          expression: "selectedConfig",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "ma-2" },
                [
                  _c(
                    "v-col",
                    { staticClass: "hidden-xs-and-down" },
                    [_c("v-spacer")],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      attrs: {
                        align: "center",
                        cols: "12",
                        sm: "4",
                        md: "4",
                        lg: "4",
                        xl: "4",
                      },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          on: {
                            click: function ($event) {
                              _vm.openDialog = false
                            },
                          },
                        },
                        [_vm._v("Dismiss")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      attrs: {
                        align: "center",
                        cols: "12",
                        sm: "4",
                        md: "4",
                        lg: "4",
                        xl: "4",
                      },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { disabled: !_vm.selectedConfig },
                          on: { click: _vm.updateTrucks },
                        },
                        [_vm._v("Update Trucks Configuration")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "hidden-xs-and-down" },
                    [_c("v-spacer")],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }