var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "plan-block" },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "justify-center" }, [
            _vm._v("Add Test Plan"),
          ]),
          _c("v-card-text", { staticClass: "text-center" }, [
            _vm._v("Fill in all fields to add a new plan"),
          ]),
          _c("v-divider"),
          _c(
            "v-form",
            {
              ref: "form",
              attrs: { "lazy-validation": "" },
              model: {
                value: _vm.formValid,
                callback: function ($$v) {
                  _vm.formValid = $$v
                },
                expression: "formValid",
              },
            },
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "formLabel", attrs: { sm: "3" } },
                        [_vm._v("Plan Name")]
                      ),
                      _c(
                        "v-col",
                        { attrs: { sm: "9" } },
                        [
                          _c("v-text-field", {
                            staticClass: "formInput",
                            attrs: {
                              rules: _vm.nameRules,
                              outlined: "",
                              label: "Add Name",
                              required: "",
                            },
                            model: {
                              value: _vm.name,
                              callback: function ($$v) {
                                _vm.name = $$v
                              },
                              expression: "name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "formLabel", attrs: { sm: "3" } },
                        [_vm._v("Description")]
                      ),
                      _c(
                        "v-col",
                        { attrs: { sm: "9" } },
                        [
                          _c("v-text-field", {
                            staticClass: "formInput",
                            attrs: {
                              outlined: "",
                              label: "Add Description (not required)",
                            },
                            model: {
                              value: _vm.description,
                              callback: function ($$v) {
                                _vm.description = $$v
                              },
                              expression: "description",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "formLabel", attrs: { sm: "3" } },
                        [_vm._v("Truck: ")]
                      ),
                      _c(
                        "v-col",
                        [
                          _c("v-select", {
                            staticClass: "formInput",
                            attrs: {
                              items: _vm.truckItems,
                              outlined: "",
                              label: "Choose Truck",
                              required: "",
                            },
                            model: {
                              value: _vm.selectedTruck,
                              callback: function ($$v) {
                                _vm.selectedTruck = $$v
                              },
                              expression: "selectedTruck",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "formLabel", attrs: { sm: "3" } },
                        [_vm._v("Delivery Date: ")]
                      ),
                      _c(
                        "v-col",
                        { attrs: { sm: "9" } },
                        [
                          _c("v-text-field", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: "##/##/####",
                                expression: "'##/##/####'",
                              },
                            ],
                            staticClass: "formInput",
                            attrs: {
                              rules: _vm.devDateRules,
                              label: "Delivery Date (MM/DD/YYYY)",
                              type: "text",
                              outlined: "",
                              "append-icon": "mdi-calendar",
                              required: "",
                            },
                            model: {
                              value: _vm.deliveryDate,
                              callback: function ($$v) {
                                _vm.deliveryDate = $$v
                              },
                              expression: "deliveryDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", text: "" },
                      on: { click: _vm.cancel },
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { disabled: !_vm.formValid, color: "primary" },
                      on: { click: _vm.exportPlan },
                    },
                    [_vm._v(" Add Test Plan ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }