import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store/store';

import hasPermission from './middleware/hasPermission';
import setDetailsID from './middleware/setDetailsID';
import initRoles from './middleware/initRoles';
import checkHealthTruck from './middleware/checkHealthTruck';
import routeBasePath from './middleware/routeBasePath';
import checkCompanyAccess from './middleware/checkCompanyAccess';
import checkPrevRoute from './middleware/checkPrevRoute';
import checkMapKey from './middleware/checkMapKey';
import middlewarePipeline from './middlewarePipeline';

import Health from '../components/Health/health';
import Details from '../components/Health/Details/Details';
import DTC from '../components/Health/DTC/DTC';
import Service from '../components/Health/Service/Service';
import TruckMap from '../components/Health/Map/TruckMap';
import Map from '../components/Map/map';
import DTCDashboard from '../components/DTCDashboard/DTCDashboard.vue';
import CloudManagement from '../components/Cloud/CloudManagement';
import Geozone from '../components/Geozone/geozone';
import Trips from '../components/Trips/trips';
import Users from '../components/Users/UserManagement';
import UserDetails from '../components/Users/UserDetails';
import Dashboard from '../components/Dashboard/dashboard';
import UserSettings from '../components/Users/UserSettings';
import Alerts from '../components/Alerts/alerts';
import DataDictionary from '../components/DataDictionary/DataDictionary';
import AddSignal from '../components/DataDictionary/AddSignal';
import ConfigurationManagement from '../components/Configurations/Packages/ConfigurationManagement';
// import studyBoard from '../components/Study/studyBoard';
import StudyBoardNew from '../components/Study2/StudyBoardNew';
import ERXMiles from '../components/ERXMiles';
import Manufacturing from '../components/Manufacturing/manufacturing';
// import ExternalDashboard from '../components/External/Dashboard/ExternalDashboard';
import ExternalTrucks from '../components/External/Trucks/ExternalTrucks';
import ExternalPerformance from '../components/External/Performance/ExternalPerformance.vue';
import ExternalHealth from '../components/External/Health/ExternalHealth.vue';
import ExternalGroups from '../components/External/Groups/ExternalGroups.vue';
import ExternalSettings from '../components/External/Settings/ExternalSettings.vue';
Vue.use(VueRouter);

const routes = [
  {
    path: '/dashboard',
    component: Dashboard,
    name: 'Dashboard',
    meta: {
      role: 'route.dashboard',
      middleware: [initRoles, hasPermission, checkMapKey],
    },
  },
  {
    path: '/settings',
    component: UserSettings,
    name: 'Settings',
    meta: {
      middleware: [initRoles, checkPrevRoute],
    },
  },
  {
    path: '/health',
    component: Health,
    name: 'Health',
    meta: {
      role: 'route.health',
      middleware: [hasPermission, checkHealthTruck],
    },
  },
  {
    path: '/health/:id/details',
    alias: '/health/:id',
    component: Details,
    name: 'TruckDetails',
    meta: {
      role: 'route.health',
      middleware: [hasPermission, setDetailsID],
    },
  },
  {
    path: '/health/:id/dtc',
    component: DTC,
    name: 'TruckDTC',
    meta: {
      role: 'route.health',
      middleware: [hasPermission, setDetailsID],
    },
  },
  {
    path: '/health/:id/service',
    component: Service,
    name: 'TruckService',
    meta: {
      role: 'route.health',
      middleware: [hasPermission, setDetailsID],
    },
  },
  {
    path: '/health/:id/map',
    component: TruckMap,
    name: 'TruckMap',
    meta: {
      role: 'route.health',
      middleware: [hasPermission, setDetailsID, checkMapKey],
    },
  },
  {
    path: '/dtc-dashboard',
    component: DTCDashboard,
    name: 'DTCDashboard',
    meta: {
      role: 'route.dtc_dashboard',
      middleware: [hasPermission],
    },
  },
  {
    path: '/map',
    component: Map,
    name: 'Map',
    meta: {
      role: 'route.map',
      middleware: [hasPermission, checkMapKey],
    },
  },
  {
    path: '/cloud',
    component: CloudManagement,
    name: 'Cloud',
    meta: {
      role: 'route.cloud-health',
      middleware: [hasPermission],
    },
  },
  {
    path: '/geozone',
    component: Geozone,
    name: 'Geozone',
    meta: {
      role: 'route.geozones',
      middleware: [hasPermission, checkMapKey],
    },
  },
  {
    path: '/trips/:id?',
    component: Trips,
    name: 'Trips',
    meta: {
      role: 'route.trip-reports',
      middleware: [hasPermission],
    },
  },
  {
    path: '/user-management',
    component: Users,
    name: 'Users',
    meta: {
      role: 'route.user-management',
      middleware: [hasPermission],
    },
  },
  {
    path: '/user-management/:id',
    component: UserDetails,
    name: 'UserDetails',
    meta: {
      role: 'route.user-management',
      middleware: [hasPermission],
    },
  },
  {
    path: '/alerts',
    component: Alerts,
    name: 'Alerts',
    meta: {
      role: 'route.alerts',
      middleware: [hasPermission],
    },
  },
  {
    path: '/DataDictionary',
    component: DataDictionary,
    name: 'DataDictionary',
    meta: {
      role: 'route.data-dictionary',
      middleware: [hasPermission],
    },
  },
  {
    path: '/AddSignal',
    component: AddSignal,
    name: 'AddSignal',
    meta: {
      role: 'route.data-dictionary',
      middleware: [hasPermission],
    },
  },
  {
    path: '/EditSignal',
    component: AddSignal,
    name: 'EditSignal',
    meta: {
      role: 'route.data-dictionary',
      middleware: [hasPermission],
    },
  },
  {
    path: '/configuration-management',
    component: ConfigurationManagement,
    name: 'ConfigurationManagement',
    meta: {
      role: 'route.configuration',
      middleware: [hasPermission],
    },
  },
  {
    path: '/studyboard',
    component: StudyBoardNew,
    name: 'StudyBoard',
    meta: {
      role: 'route.study',
      middleware: [hasPermission],
    },
  },
  {
    path: '/erxmiles',
    component: ERXMiles,
    name: 'ERXMiles',
    meta: {
      role: 'route.erxmiles',
      middleware: [hasPermission],
    },
  },
  {
    path: '/manufacturing',
    component: Manufacturing,
    name: 'Manufacturing',
    meta: {
      role: 'route.manufacturing',
      middleware: [hasPermission],
    },
  },
  // {
  //   path: '/customer/dashboard',
  //   component: ExternalDashboard,
  //   name: 'ExternalDashboard',
  //   meta: {
  //     role: 'route.customer_dashboard',
  //     middleware: [initRoles, checkCompanyAccess, checkMapKey, hasPermission],
  //   },
  // },
  {
    path: '/customer/performance',
    component: ExternalPerformance,
    name: 'ExternalPerformance',
    meta: {
      role: 'external_permissions.performance',
      middleware: [initRoles, checkCompanyAccess, checkMapKey, hasPermission],
    },
  },
  {
    path: '/customer/trucks',
    component: ExternalTrucks,
    name: 'ExternalTrucks',
    meta: {
      role: 'route.customer_dashboard',
      middleware: [hasPermission],
    },
  },
  {
    path: '/customer/health',
    component: ExternalHealth,
    name: 'ExternalHealth',
    meta: {
      role: 'external_permissions.health',
      middleware: [initRoles, checkCompanyAccess, checkMapKey, hasPermission],
      // Permission middleware moved to the end to confirm if the user has access based on company permission role
      // This is contingent on speed based on the App.vue check for the roles to include them or not
    },
  },
  {
    path: '/customer/groups',
    component: ExternalGroups,
    name: 'ExternalGroups',
    meta: {
      role: 'route.customer_dashboard',
      middleware: [hasPermission],
    },
  },
  {
    path: '/customer/settings',
    component: ExternalSettings,
    name: 'ExternalSettings',
    meta: {
      role: '*',
      middleware: [checkCompanyAccess, hasPermission],
    },
  },
  {
    path: '*',
    meta: {
      middleware: [initRoles, checkCompanyAccess, routeBasePath],
    },
  },
];

export const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (!to.meta.middleware) {
    return next();
  }
  const middleware = to.meta.middleware;
  const role = to.meta.role;
  const context = {
    to,
    role,
    from,
    store,
    next,
  };
  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1), // This will be required if we want to add another middleware for the routes
  });
});
