var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              attrs: {
                cols: "6",
                sm: "4",
                md: "4",
                lg: "3",
                xl: "3",
                align: "left",
              },
            },
            [
              _c("span", { staticClass: "secondary-text" }, [
                _vm._v("Truck Name"),
              ]),
              _c("br"),
              _c("b", [_vm._v(_vm._s(_vm.exData.number || ""))]),
            ]
          ),
          _c(
            "v-col",
            {
              attrs: {
                cols: "6",
                sm: "4",
                md: "4",
                lg: "3",
                xl: "3",
                align: "left",
              },
            },
            [
              _c("span", { staticClass: "secondary-text" }, [
                _vm._v("Current Odometer"),
              ]),
              _c("br"),
              _c("b", [
                _vm._v(
                  _vm._s(_vm.exData.odometer) +
                    " " +
                    _vm._s(_vm.displayDistanceUnits())
                ),
              ]),
            ]
          ),
          _c(
            "v-col",
            {
              attrs: {
                cols: "6",
                sm: "4",
                md: "4",
                lg: "3",
                xl: "3",
                align: "left",
              },
            },
            [
              _c("span", { staticClass: "secondary-text" }, [
                _vm._v("Active DTC(s)"),
              ]),
              _c("br"),
              _c("b", [_vm._v(_vm._s(_vm.exData.dtc || 0))]),
            ]
          ),
          _c(
            "v-col",
            {
              attrs: {
                cols: "6",
                sm: "4",
                md: "4",
                lg: "3",
                xl: "3",
                align: "left",
              },
            },
            [
              _c("span", { staticClass: "secondary-text" }, [_vm._v("APU")]),
              _c("br"),
              _c("b", [_vm._v(_vm._s(_vm.exData.APU))]),
            ]
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              attrs: {
                cols: "6",
                sm: "4",
                md: "4",
                lg: "3",
                xl: "3",
                align: "left",
              },
            },
            [
              _c("span", { staticClass: "secondary-text" }, [
                _vm._v("Customer"),
              ]),
              _c("br"),
              _c("b", [_vm._v(_vm._s(_vm.exData.company))]),
            ]
          ),
          _c(
            "v-col",
            {
              attrs: {
                cols: "6",
                sm: "4",
                md: "4",
                lg: "3",
                xl: "3",
                align: "left",
              },
            },
            [
              _c("span", { staticClass: "secondary-text" }, [
                _vm._v("Ignition"),
              ]),
              _c("br"),
              _c("b", [_vm._v(_vm._s(_vm.exData.ignition))]),
            ]
          ),
          _c(
            "v-col",
            {
              attrs: {
                cols: "6",
                sm: "4",
                md: "4",
                lg: "3",
                xl: "3",
                align: "left",
              },
            },
            [
              _c("span", { staticClass: "secondary-text" }, [
                _vm._v("Current Speed"),
              ]),
              _c("br"),
              _c("b", [
                _vm._v(
                  _vm._s(_vm.exData.current_speed) +
                    " " +
                    _vm._s(_vm.displaySpeedUnits())
                ),
              ]),
            ]
          ),
          _c(
            "v-col",
            {
              attrs: {
                cols: "6",
                sm: "4",
                md: "4",
                lg: "3",
                xl: "3",
                align: "left",
              },
            },
            [
              _c("span", { staticClass: "secondary-text" }, [
                _vm._v("General Temp."),
              ]),
              _c("br"),
              _c("b", [
                _vm._v(
                  _vm._s(_vm.exData.generalTemp) +
                    " " +
                    _vm._s(_vm.displayTempUnits())
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              attrs: {
                cols: "6",
                sm: "4",
                md: "4",
                lg: "3",
                xl: "3",
                align: "left",
              },
            },
            [
              _c("span", { staticClass: "secondary-text" }, [_vm._v("VIN")]),
              _vm._v(" "),
              _c("br"),
              _c("b", [_vm._v(_vm._s(_vm.exData.vin))]),
            ]
          ),
          _c(
            "v-col",
            {
              attrs: {
                cols: "6",
                sm: "4",
                md: "4",
                lg: "3",
                xl: "3",
                align: "left",
              },
            },
            [
              _c("span", { staticClass: "secondary-text" }, [
                _vm._v("Position"),
              ]),
              _c("br"),
              _c("b", [_vm._v(_vm._s(_vm.exData.position))]),
            ]
          ),
          _c(
            "v-col",
            {
              attrs: {
                cols: "6",
                sm: "4",
                md: "4",
                lg: "3",
                xl: "3",
                align: "left",
              },
            },
            [
              _c("span", { staticClass: "secondary-text" }, [_vm._v("APU")]),
              _vm._v(" "),
              _c("br"),
              _c("b", [_vm._v(_vm._s(_vm.exData.APU))]),
            ]
          ),
          _c(
            "v-col",
            {
              attrs: {
                cols: "6",
                sm: "4",
                md: "4",
                lg: "3",
                xl: "3",
                align: "left",
              },
            },
            [
              _c("span", { staticClass: "secondary-text" }, [
                _vm._v("Motor Temp."),
              ]),
              _c("br"),
              _c("b", [
                _vm._v(
                  _vm._s(_vm.exData.motorTemp) +
                    " " +
                    _vm._s(_vm.displayTempUnits())
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              attrs: {
                cols: "6",
                sm: "4",
                md: "4",
                lg: "3",
                xl: "3",
                align: "left",
              },
            },
            [
              _c("span", { staticClass: "secondary-text" }, [
                _vm._v("Fuel Type"),
              ]),
              _c("br"),
              _c("b", [_vm._v(_vm._s(_vm.exData.fuel_type))]),
            ]
          ),
          _c(
            "v-col",
            {
              attrs: {
                cols: "6",
                sm: "4",
                md: "4",
                lg: "3",
                xl: "3",
                align: "left",
              },
            },
            [
              _c("span", { staticClass: "secondary-text" }, [
                _vm._v("Current Address"),
              ]),
              _c("br"),
              _c("b", [_vm._v(_vm._s(_vm.exData.address))]),
            ]
          ),
          _c(
            "v-col",
            {
              attrs: {
                cols: "6",
                sm: "4",
                md: "4",
                lg: "3",
                xl: "3",
                align: "left",
              },
            },
            [
              _c("span", { staticClass: "secondary-text" }, [
                _vm._v("Ignition"),
              ]),
              _vm._v(" "),
              _c("br"),
              _c("b", [_vm._v(_vm._s(_vm.exData.ignition))]),
            ]
          ),
          _c(
            "v-col",
            {
              attrs: {
                cols: "6",
                sm: "4",
                md: "4",
                lg: "3",
                xl: "3",
                align: "left",
              },
            },
            [
              _c("span", { staticClass: "secondary-text" }, [
                _vm._v("Compressor Temp."),
              ]),
              _c("br"),
              _c("b", [
                _vm._v(
                  _vm._s(_vm.exData.compressorTemp) +
                    " " +
                    _vm._s(_vm.displayTempUnits())
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              attrs: {
                cols: "6",
                sm: "4",
                md: "4",
                lg: "3",
                xl: "3",
                align: "left",
              },
            },
            [
              _c("span", { staticClass: "secondary-text" }, [
                _vm._v("Truck Type"),
              ]),
              _c("br"),
              _c("b", [_vm._v(_vm._s(_vm.exData.truck_type))]),
            ]
          ),
          _c(
            "v-col",
            {
              attrs: {
                cols: "6",
                sm: "4",
                md: "4",
                lg: "3",
                xl: "3",
                align: "left",
              },
            },
            [
              _c("span", { staticClass: "secondary-text" }, [
                _vm._v("Last Look Ahead Call"),
              ]),
              _c("br"),
              _c("b", [_vm._v(_vm._s(_vm.exData.laTimeUpdated))]),
            ]
          ),
          _c(
            "v-col",
            {
              attrs: {
                cols: "6",
                sm: "4",
                md: "4",
                lg: "3",
                xl: "3",
                align: "left",
              },
            },
            [
              _c("span", { staticClass: "secondary-text" }, [
                _vm._v("Last Terrain Ahead Call"),
              ]),
              _c("br"),
              _c("b", [_vm._v(_vm._s(_vm.exData.taTimeUpdated))]),
            ]
          ),
          _c(
            "v-col",
            {
              attrs: {
                cols: "6",
                sm: "4",
                md: "4",
                lg: "3",
                xl: "3",
                align: "left",
              },
            },
            [
              _c("span", { staticClass: "secondary-text" }, [
                _vm._v("Last 10 Hz Ping"),
              ]),
              _c("br"),
              _c("b", [_vm._v(_vm._s(_vm.exData.ten_hz_time || "—"))]),
            ]
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              attrs: {
                cols: "6",
                sm: "4",
                md: "4",
                lg: "3",
                xl: "3",
                align: "left",
              },
            },
            [
              _c("span", { staticClass: "secondary-text" }, [
                _vm._v("Current SOC"),
              ]),
              _c("br"),
              _c("b", [_vm._v(_vm._s(_vm.exData.SOC))]),
            ]
          ),
          _c(
            "v-col",
            {
              attrs: {
                cols: "6",
                sm: "4",
                md: "4",
                lg: "3",
                xl: "3",
                align: "left",
              },
            },
            [
              _c("span", { staticClass: "secondary-text" }, [
                _vm._v("Serial Number"),
              ]),
              _c("br"),
              _c("b", [_vm._v(_vm._s(_vm.exData.serial_number))]),
            ]
          ),
          _c(
            "v-col",
            {
              attrs: {
                cols: "6",
                sm: "4",
                md: "4",
                lg: "3",
                xl: "3",
                align: "left",
              },
            },
            [
              _c("span", { staticClass: "secondary-text" }, [
                _vm._v("Current Engine Speed"),
              ]),
              _c("br"),
              _c("b", [_vm._v(_vm._s(_vm.exData.engSpeed) + " rpm")]),
            ]
          ),
          _c(
            "v-col",
            {
              attrs: {
                cols: "6",
                sm: "4",
                md: "4",
                lg: "3",
                xl: "3",
                align: "left",
              },
            },
            [
              _c("span", { staticClass: "secondary-text" }, [
                _vm._v("Battery Min Temp."),
              ]),
              _c("br"),
              _c("b", [
                _vm._v(
                  _vm._s(_vm.exData.batteryMinTemp) +
                    " " +
                    _vm._s(_vm.displayTempUnits())
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              attrs: {
                cols: "6",
                sm: "4",
                md: "4",
                lg: "3",
                xl: "3",
                align: "left",
              },
            },
            [
              _c("span", { staticClass: "secondary-text" }, [
                _vm._v("Last Error Shut Down"),
              ]),
              _c("br"),
              _c("b", [_vm._v(_vm._s(_vm.exData.last_error_shut_down))]),
            ]
          ),
          _c(
            "v-col",
            {
              attrs: {
                cols: "6",
                sm: "4",
                md: "4",
                lg: "3",
                xl: "3",
                align: "left",
              },
            },
            [
              _c("span", { staticClass: "secondary-text" }, [
                _vm._v("Last One Minute Ping"),
              ]),
              _c("br"),
              _c("b", [_vm._v(_vm._s(_vm.exData.timeUpdated))]),
            ]
          ),
          _c(
            "v-col",
            {
              attrs: {
                cols: "6",
                sm: "4",
                md: "4",
                lg: "3",
                xl: "3",
                align: "left",
              },
            },
            [
              _c("span", { staticClass: "secondary-text" }, [
                _vm._v("Last IoT Update"),
              ]),
              _c("br"),
              _c("b", [_vm._v(_vm._s(_vm.exData.iotUpdate))]),
            ]
          ),
          _c(
            "v-col",
            {
              attrs: {
                cols: "6",
                sm: "4",
                md: "4",
                lg: "3",
                xl: "3",
                align: "left",
              },
            },
            [
              _c("span", { staticClass: "secondary-text" }, [
                _vm._v("Battery Max Temp."),
              ]),
              _c("br"),
              _c("b", [
                _vm._v(
                  _vm._s(_vm.exData.batteryMaxTemp) +
                    " " +
                    _vm._s(_vm.displayTempUnits())
                ),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }