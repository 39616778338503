<template>
  <v-row justify="center">
    <v-dialog v-model="show" persistent max-width="450">
      <v-card>
        <v-form ref="form">
          <v-card-title>
            <span class="text-h5">Give Us Feedback</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="close"><v-icon>mdi-close-circle</v-icon></v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <div style="margin: 10px"></div>
          <v-card-text>
            <v-container>
              <v-row>
                <span style="font-size: 16px; color: white">
                  How would you rate your experience?
                </span>
              </v-row>
              <v-row
                style="margin-top: 30px; margin-bottom: 20px"
                justify="space-between"
              >
                <div class="emoticon" @click="setExperience('love')">
                  <v-icon x-large :color="loveColor">
                    mdi-emoticon-excited-outline
                  </v-icon>
                  <div :style="{ color: loveColor }">Love</div>
                </div>
                <div class="emoticon" @click="setExperience('like')">
                  <v-icon x-large :color="likeColor">
                    mdi-emoticon-happy-outline
                  </v-icon>
                  <div :style="{ color: likeColor }">Like</div>
                </div>
                <div class="emoticon" @click="setExperience('neutral')">
                  <v-icon x-large :color="neutralColor">
                    mdi-emoticon-neutral-outline
                  </v-icon>
                  <div :style="{ color: neutralColor }">Neutral</div>
                </div>
                <div class="emoticon" @click="setExperience('dislike')">
                  <v-icon x-large :color="dislikeColor">
                    mdi-emoticon-frown-outline
                  </v-icon>
                  <div :style="{ color: dislikeColor }">Dislike</div>
                </div>
                <div class="emoticon" @click="setExperience('hate')">
                  <v-icon x-large :color="hateColor">
                    mdi-emoticon-angry-outline
                  </v-icon>
                  <div :style="{ color: hateColor }">Hate</div>
                </div>
              </v-row>
              <v-row v-if="experience"
                ><v-select
                  v-model="category"
                  label="Select Category"
                  :items="categories"
                  :rules="[rules.required]"
                  outlined
                  required
                ></v-select
              ></v-row>
              <v-row v-if="experience">
                <v-textarea
                  v-model="description"
                  label="Description"
                  :rules="[rules.maxLen, rules.required]"
                  outlined
                  required
                  style="margin-bottom: 13px"
                ></v-textarea>
              </v-row>
              <div v-if="experience" class="charCount" style="">
                {{ charCount }}/{{ maxChar }}
              </div>
            </v-container>
            <v-btn block color="primary" :disabled="disabled" @click="submit">
              Submit
            </v-btn>
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { postFeedback } from '@/api/feedback.js';

export default {
  name: 'FeedbackModal',
  props: ['show', 'toggle'],
  data() {
    return {
      experience: null,
      maxChar: 2047,
      description: '',
      category: null,
      categories: ['Feature', 'Bug', 'Other'],
      tickLabels: ['Very Low', 'Low', 'Medium', 'High', 'Critical'],
      rules: {
        required: (value) => !!value || 'Required.',
        maxLen: (value) =>
          (value || '').length <= this.maxChar ||
          `Description must be ${this.maxChar} characters or less`,
      },
    };
  },
  computed: {
    charCount() {
      return this.description ? this.description.length : 0;
    },
    ...mapGetters({
      userName: 'getUserName',
      userEmail: 'getUserEmail',
      userOID: 'getUserOID',
      currentScreen: 'getCurrentScreen',
    }),
    loveColor() {
      return this.experience === 'love' ? '#43B02A' : 'white';
    },
    likeColor() {
      return this.experience === 'like' ? '#43B02A' : 'white';
    },
    neutralColor() {
      return this.experience === 'neutral' ? '#43B02A' : 'white';
    },
    dislikeColor() {
      return this.experience === 'dislike' ? '#43B02A' : 'white';
    },
    hateColor() {
      return this.experience === 'hate' ? '#43B02A' : 'white';
    },
    disabled() {
      return this.experience ? false : true;
    },
  },
  watch: {},
  mounted() {
    this.dialog = this.show;
  },
  methods: {
    ...mapActions({
      updateSnack: 'updateSnack',
    }),
    async submit() {
      // validate
      const valid = this.$refs.form.validate();
      if (!valid) return;
      let importance = null;
      // this is a temporary fix; we'll want to rework the table
      // and API to take a string rather than int in the future
      if (this.experience === 'love') importance = 5;
      if (this.experience === 'like') importance = 4;
      if (this.experience === 'neutral') importance = 3;
      if (this.experience === 'dislike') importance = 2;
      if (this.experience === 'hate') importance = 1;
      // post data in postgres
      const feedback = {
        userName: this.userName,
        email: this.userEmail,
        userID: this.userOID,
        importance: importance,
        category: this.category,
        description: this.description,
        screen: this.getScreenString(),
      };
      this.close();
      postFeedback(feedback)
        .then(() => {
          this.updateSnack({
            type: 'success',
            message: 'Feedback successfully submitted!',
          });
        })
        .catch((e) => {
          console.error(e);
          this.updateSnack({
            type: 'error',
            message: 'There was an issue submitting feedback',
          });
        });
    },
    async close() {
      await this.$refs.form.reset();
      this.experience = null;
      this.toggle();
    },
    setExperience(exp) {
      this.experience = exp;
    },
    // decision tree to get string representative of current screen
    getScreenString() {
      if (this.$route.name != 'CustomerDashboard') return this.$route.name;
      let s = this.currentScreen;
      let currentScreen = 'CustomerDashboard';
      if (s.tab.toLowerCase() === 'health') {
        currentScreen += ' - Health';
        if (s.mapShowing) currentScreen += ' - Map Showing';
        else currentScreen += ' - Map not Showing';
        if (s.truckDetails) currentScreen += ' - Truck Details';
        else currentScreen += ` - ${s.healthTab}`;
      } else if (s.tab.toLowerCase() === 'performance') {
        currentScreen += ' - Performance';
        if (s.journeyDetails) currentScreen += ' - Journey Details';
        else {
          currentScreen += ` - ${s.scatterMetric}`;
          currentScreen += ` - ${s.scatterRange}`;
        }
      }
      return currentScreen;
    },
  },
};
</script>

<style scoped>
.charCount {
  font-size: 10px;
  position: relative;
  top: -15px;
  right: 0px;
  text-align: right;
}
.emoticon {
  text-align: center;
  cursor: pointer;
}
</style>
