var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "trucks" } },
    [
      _vm.showAlert
        ? _c("alert", {
            attrs: {
              "alert-type": _vm.alertType,
              "alert-message": _vm.alertMessage,
            },
          })
        : _vm._e(),
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "pb-0",
                  attrs: { cols: "4", justify: "center" },
                },
                [
                  _c("v-text-field", {
                    attrs: {
                      placeholder: "Search",
                      solo: "",
                      "prepend-inner-icon": "mdi-magnify",
                      color: "primary",
                    },
                    model: {
                      value: _vm.searchText,
                      callback: function ($$v) {
                        _vm.searchText = $$v
                      },
                      expression: "searchText",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { align: "right" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticStyle: { color: "black" },
                      attrs: { large: "", color: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.showNewTrucks = true
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("mdi-plus-circle-outline"),
                      ]),
                      _vm._v("Add Truck"),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "py-0",
                  attrs: {
                    cols: "4",
                    xs: "12",
                    sm: "12",
                    md: "12",
                    lg: "4",
                    xl: "4",
                  },
                },
                [
                  _c(
                    "v-tabs",
                    {
                      attrs: {
                        "fixed-tabs": "",
                        "background-color": "secondary",
                      },
                    },
                    [
                      _c(
                        "v-tab",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.changeTab("ERX")
                            },
                          },
                        },
                        [_vm._v("ERX Trucks")]
                      ),
                      _c(
                        "v-tab",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.changeTab("EX")
                            },
                          },
                        },
                        [_vm._v("Hybrid Trucks")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "py-0" },
                [
                  _vm.loading
                    ? _c("v-skeleton-loader", {
                        attrs: { type: "table", elevation: "2" },
                      })
                    : [
                        _vm.switchTab === "EX"
                          ? _c("extrucks", {
                              attrs: {
                                "ex-trucks": _vm.EXTrucks,
                                "search-text": _vm.searchText,
                              },
                              on: {
                                emitEditTrucks: _vm.emitEditTrucks,
                                openUninstallDialog: _vm.emitOpenDialog,
                              },
                            })
                          : _vm.switchTab === "ERX"
                          ? _c("erxtrucks", {
                              attrs: {
                                "erx-trucks": _vm.ERXTrucks,
                                "search-text": _vm.searchText,
                              },
                              on: { refresh: _vm.refreshData },
                            })
                          : _vm._e(),
                      ],
                  _vm.snack
                    ? _c("SnackBar", {
                        attrs: {
                          "snack-type": _vm.snackType,
                          "snack-message": _vm.snackText,
                        },
                      })
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
          _vm.showNewTrucks
            ? _c("add-trucks", {
                on: { closed: _vm.closeNewTrucks, refresh: _vm.refreshData },
              })
            : _vm._e(),
          _vm.showUpdateTrucks
            ? _c("update-trucks", {
                attrs: { "selected-trucks": _vm.selectedTrucks },
                on: { closed: _vm.closeUpdateTrucks, refresh: _vm.refreshData },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          staticClass: "dialog",
          attrs: { width: "750" },
          model: {
            value: _vm.openUninstallDialog,
            callback: function ($$v) {
              _vm.openUninstallDialog = $$v
            },
            expression: "openUninstallDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { align: "center" } }, [
                        _vm._v(
                          " Are you sure you want to change the status of this truck to uninstalled?"
                        ),
                        _c("br"),
                        _vm._v(
                          " This will remove the truck from any active truck list. "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "hidden-xs-and-down" },
                        [_c("v-spacer")],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            align: "center",
                            cols: "12",
                            sm: "4",
                            md: "4",
                            lg: "4",
                            xl: "4",
                          },
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-2",
                              attrs: { color: "primary", outlined: "" },
                              on: {
                                click: function ($event) {
                                  _vm.openUninstallDialog = false
                                },
                              },
                            },
                            [_vm._v("Cancel")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            align: "center",
                            cols: "12",
                            sm: "4",
                            md: "4",
                            lg: "4",
                            xl: "4",
                          },
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticStyle: { color: "black" },
                              attrs: { color: "primary" },
                              on: { click: _vm.uninstallTruck },
                            },
                            [_vm._v("Yes")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "hidden-xs-and-down" },
                        [_c("v-spacer")],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }