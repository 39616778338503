<template>
  <div>
    <div style="display: flex; justify-content: space-between">
      <div style="margin-bottom: 5px">
        <h1>{{ template.name }}</h1>
        <h4>{{ template.description }}</h4>
      </div>
      <div>
        <v-btn
          v-if="trucksSelected"
          color="info"
          style="margin-top: auto"
          @click="exportPDF"
          >Export PDF</v-btn
        >
        <div v-if="exportingStr != null">{{ exportingStr }}</div>
      </div>
    </div>
    <div v-if="!trucksSelected">
      <v-alert color="warning" outlined style="text-align: center"
        >No Trucks Selected. Edit Selection in
        <v-icon style="margin-top: -4px; opacity: 0.6">mdi-cog</v-icon>
        Study Board Settings
      </v-alert>
    </div>
    <div
      v-for="graph in template.graphs"
      v-else
      :key="graph.id"
      style="margin-top: 20px; box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px"
    >
      <v-progress-circular
        v-if="graphsLoading[graph.id]"
        class="loader"
        color="primary"
        :size="70"
        :width="7"
        style="display: block; margin: auto"
        indeterminate
      ></v-progress-circular>
      <AbsoluteTime
        v-else-if="
          graph.graph_type === 'Absolute Time Line Graph' && graphData[graph.id]
        "
        :id="graph.id.toString()"
        :ref="`abstime${graph.id}`"
        :title="graph.name"
        :data="graphData[graph.id]"
        :add-chart="addChart"
        :zoom="zoom"
        :truck-names="truckNames"
      />
      <Candlestick
        v-else-if="graph.graph_type === 'Candlestick' && graphData[graph.id]"
        :id="graph.id.toString()"
        :ref="`candle${graph.id}`"
        :title="graph.name"
        :data="graphData[graph.id]"
        :add-chart="addChart"
        :thresholds="thresholds[graph.id]"
        :truck-names="truckNames"
      />
      <Jitter
        v-else-if="graph.graph_type === 'Jitter' && graphData[graph.id]"
        :id="graph.id.toString()"
        :ref="`jitter${graph.id}`"
        :title="graph.name"
        :data="graphData[graph.id]"
        :add-chart="addChart"
        :thresholds="thresholds[graph.id]"
        signal="maxmin"
        :truck-names="truckNames"
      />
    </div>
  </div>
</template>

<script>
import AbsoluteTime from './Graphs/AbsoluteTime.vue';
import Candlestick from './Graphs/Candlestick.vue';
import Jitter from './Graphs/Jitter.vue';
import { getCandlestick, getAbsoluteTimeGraph } from '@/api/study';
import { getSourceDictionary } from '@/api/dataDictionary';
import { getTimeZoneOffset } from '@/utilities/dateFunctions';
import { mapActions, mapGetters } from 'vuex';
import Highcharts from 'highcharts';
import exporting from 'highcharts/modules/exporting';
import offlineExporting from 'highcharts/modules/offline-exporting';
exporting(Highcharts);
offlineExporting(Highcharts);
import _ from 'lodash';

export default {
  name: 'Study',
  components: {
    AbsoluteTime,
    Candlestick,
    Jitter,
  },
  props: {
    template: {
      type: Object,
      required: true,
    },
    truckDate: {
      type: Object,
      required: true,
    },
    inView: {
      type: Boolean,
      required: false,
      default: true,
    },
    updateZoom: {
      type: Function,
      required: false,
      default: () => {},
    },
    truckNames: {
      type: Object,
      required: false,
      default: () => {},
    },
    signalLookup: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      charts: [], // array of highchart objects
      chartArray: [],
      graphData: {}, // graph id is key, data is value
      thresholds: {}, // graph id is key, threshhold data is value
      graphsLoading: {}, // key: graph_id, value: boolean describing graph loading status
      staleData: false,
      exportingStr: null,
      maxExtremes: [null, null],
    };
  },
  computed: {
    ...mapGetters({
      userPreferences: 'getUserPreferences',
    }),
    trucksSelected() {
      return this.truckDate.trucks?.length > 0;
    },
  },
  watch: {
    truckDate() {
      if (this.trucksSelected && this.inView) this.fetchGraphData();
      else if (this.trucksSelected) this.staleData = true;
    },
    inView() {
      if (this.inView && this.staleData) this.fetchGraphData();
    },
  },
  mounted() {
    if (this.trucksSelected) this.fetchGraphData();
  },
  methods: {
    ...mapActions({
      updateSnack: 'updateSnack',
    }),
    startLoaders() {
      const vm = this;
      for (const graph of this.template.graphs)
        this.$set(vm.graphsLoading, graph.id, true);
    },
    fetchGraphData() {
      this.startLoaders();
      this.staleData = false;
      this.charts = []; // reset charts array
      // set global queryString values (truck/date selection)
      let queryString = '?';
      for (const truck_id of this.truckDate.trucks)
        queryString += `&truckList=${truck_id}`;

      // getTimeZoneOffset function returns the number of minutes to add to the time zone time
      // to get the equivalent UTC time. We are using timestamps from controls
      // based on the browser's timezone.  So we need to get the difference between
      // the user preference tz offset and the browser's tz offset and add that
      // to the start and end times that are based on the browser start and end times
      // converted to milliseconds since Unix Epoch

      // Get the user preference timezone offset in minutes
      const tz = this.userPreferences.timeZonePref.canonical;
      const tzOffsetUP = getTimeZoneOffset(this.truckDate.start, tz);
      // Get the browser timezone offset in minutes
      const browserTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const tzOffsetBrwsr = getTimeZoneOffset(this.truckDate.start, browserTz);
      // Get the offset difference between the User Preference and Browser offsets and convert to milliseconds
      const tzOffsetDiffMs = (tzOffsetUP - tzOffsetBrwsr) * 60 * 1000;
      // Add that difference in milliseconds to the unix epoch time to convert to the user preference time
      let startMs = new Date(this.truckDate.start).valueOf() + tzOffsetDiffMs;
      let endMs = new Date(this.truckDate.end).valueOf() + tzOffsetDiffMs;
      this.maxExtremes = [startMs, endMs];
      queryString += `&startDate=${startMs}`;
      queryString += `&endDate=${endMs}`;

      const vm = this;
      let absTimeSignals = new Set();
      for (const graph of this.template.graphs) {
        switch (graph.graph_type) {
          case 'Absolute Time Line Graph':
            graph.signals.forEach((s) => absTimeSignals.add(s));
            break;
          case 'Jitter':
          case 'Candlestick':
            this.fetchCandlestick(graph, queryString).then(() => {
              vm.$set(vm.graphsLoading, graph.id, false);
            });
            break;
        }
      }

      this.fetchAllAbsTimeGraphs(absTimeSignals, queryString);
    },
    async fetchAllAbsTimeGraphs(signalSet, queryString) {
      queryString += `&signal_id=${Array.from(signalSet).join(',')}`;
      const res = await getAbsoluteTimeGraph(queryString, 'ERX');
      this.splitAbsData(res.data);
    },
    splitAbsData(data) {
      data = this.stripPretext(data);
      for (const graph of this.template.graphs) {
        let graphDataArray = [];
        for (const truck of data) {
          graphDataArray.push({
            truck_id: truck.truck_id,
            data: this.filterParams(truck.data, graph),
          });
        }
        this.$set(this.graphData, graph.id, graphDataArray);
        this.$set(this.graphsLoading, graph.id, false);
      }
    },
    filterParams(data, graph) {
      const signalSet = new Set(graph.signals);
      let res = data.map((obj) => {
        const filteredObject = {};
        const allowedKeys = new Set(['truck_id', 'timestamp']); // company_id?
        for (const key in obj) {
          if (Object.prototype.hasOwnProperty.call(obj, key)) {
            // include property if it is allowed or a signal in the graph
            if (allowedKeys.has(key) || signalSet.has(this.signalLookup[key])) {
              filteredObject[key] = obj[key];
            }
          }
        }
        return filteredObject;
      });
      return res; // array of objects with only relevant signals, truck_id, timestamp
    },
    // fixme: ask backend to remove the 'ff' pretext; assume it is removed here and just ignore this
    // computational expense
    stripPretext(data) {
      const pretext = 'ff';
      for (const obj of data) {
        obj.data = obj.data.map((obj) => {
          const strippedObject = {};
          for (const key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
              // Check if the property name starts with the specified pretext
              if (key.startsWith(pretext)) {
                // Remove the pretext and assign to the new object
                const newKey = key.substring(pretext.length);
                strippedObject[newKey] = obj[key];
              } else {
                // Keep the original property name and value
                strippedObject[key] = obj[key];
              }
            }
          }
          return strippedObject;
        });
      }
      return data;
    },
    async fetchAbsoluteTimeGraph(graph, queryString) {
      queryString += `&signal_id=${_.toString(graph.signals)}`;
      let res;
      try {
        res = await getAbsoluteTimeGraph(queryString, 'ERX');
      } catch (e) {
        this.updateSnack({
          type: 'error',
          message: `Error fetching graph ${graph?.name}`,
        });
        return;
      }
      this.$set(this.graphData, graph.id, res.data);
    },
    async fetchCandlestick(graph, queryString) {
      // parallel call for warning thresholds
      let thresholds;
      getSourceDictionary('sampleBData', graph.signals)
        .then((res) => (thresholds = res))
        .catch(() => {
          this.updateSnack({
            type: 'error',
            message: `Error fetching warning thresholds for graph ${graph?.name}`,
          });
        });

      // fetch candlestick data
      queryString += `&startTrigger=${graph.start_trigger}`;
      queryString += `&endTrigger=${graph.end_trigger}`;
      queryString += `&signal_id=${_.toString(graph.signals)}`;
      let res;
      try {
        res = await getCandlestick(queryString, 'ERX');
      } catch (e) {
        this.updateSnack({
          type: 'error',
          message: `Error fetching graph ${graph?.name}`,
        });
        return;
      }

      this.$set(this.graphData, graph.id, res.data);
      this.$set(this.thresholds, graph.id, thresholds?.data);
    },
    addChart(chart) {
      this.charts.push(chart);
      if (this.charts.length === this.template.graphs.length) this.sortCharts();
    },
    // charts get added to this.charts as they load in; sort so they match template order
    sortCharts() {
      const graphOrder = this.template.graphs.map((graph) => graph.id);
      this.charts.sort((a, b) => {
        return graphOrder.indexOf(a.id) - graphOrder.indexOf(b.id);
      });
    },
    zoom(min, max, event) {
      let i = this.charts.indexOf(event.target);

      const vm = this;
      setTimeout(() => {
        if (vm.charts.length > 1) {
          // zoom charts above and below if they exist
          if (i + 1 < vm.charts.length) zoomChart(vm.charts[i + 1]);
          if (i - 1 >= 0) zoomChart(vm.charts[i - 1]);

          // middle-out propagated zoom for all other charts on 100ms offset
          setTimeout(() => {
            let [j, delay] = [i + 1, 100];
            while (i >= 0 || j < vm.charts.length) {
              if (i >= 0) {
                delayZoom(i, delay);
                i--;
              }
              if (j < vm.charts.length) {
                delayZoom(j, delay);
                j++;
              }
              delay += 70; // stagger each subsequent zoom by x ms
            }
          }, 100);

          // zoom all other charts in an additional 100ms offset
          // setTimeout(() => {
          //   for (let j = 0; j < vm.charts.length; j++) {
          //     if (j > i + 1 || j < i - 1) {
          //       zoomChart(vm.charts[j]);
          //     }
          //   }
          // }, 100);
        }
      }, 100);
      this.updateZoom(min, max);

      // fn declaration is necessary to freeze the state of input var i
      function delayZoom(i, delay) {
        setTimeout(() => {
          zoomChart(vm.charts[i]);
        }, delay);
      }

      // logic to zoom on a given chart
      function zoomChart(chart) {
        // reset xAxis and yAxis on resetZoom
        if (chart.userOptions.chart.type === 'line' && event.resetSelection) {
          chart.xAxis[0].setExtremes(vm.maxExtremes[0], vm.maxExtremes[1]);
          chart.yAxis[0].setExtremes(min, max);
        } else if (
          chart.userOptions.chart.type === 'line' &&
          event.target != chart
        ) {
          // sync only xAxis on zoom;
          chart.xAxis[0].setExtremes(min, max);
          chart.showResetZoom();
        }
      }
    },
    exportPDF() {
      this.exportingStr = 'Preparing export...';
      // DOM freezes without this timeout
      const vm = this;
      setTimeout(() => {
        vm.export();
      }, 100);
    },
    export() {
      const vm = this;
      /**
       * Create a global getSVG method that takes an array of charts as an
       * argument
       */
      Highcharts.getSVG = function (charts) {
        var svgArr = [],
          top = 0,
          width = 0;

        Highcharts.each(charts, function (chart) {
          var svg = chart.getSVG(),
            // Get width/height of SVG for export
            svgWidth = +svg.match(/^<svg[^>]*width\s*=\s*"(\d+)"[^>]*>/)[1],
            svgHeight = +svg.match(/^<svg[^>]*height\s*=\s*"(\d+)"[^>]*>/)[1];

          // give each chart its own line
          svg = svg.replace('<svg', '<g transform="translate(0,' + top + ')"');
          svg = svg.replace('</svg>', '</g>');

          width = Math.max(width, svgWidth);
          top += svgHeight + 20;

          svgArr.push(svg);
        });

        return (
          '<svg height="' +
          top +
          '" width="' +
          width +
          '" version="1.1" xmlns="http://www.w3.org/2000/svg">' +
          svgArr.join('') +
          '</svg>'
        );
      };

      /**
       * Create a global exportCharts method that takes an array of charts as an
       * argument, and exporting options as the second argument
       */
      Highcharts.exportCharts = function (charts, options) {
        // Merge the options
        options = Highcharts.merge(Highcharts.getOptions().exporting, options);

        // Post to export server
        Highcharts.post(options.url, {
          filename: vm.template.name || 'chart',
          type: options.type,
          svg: Highcharts.getSVG(charts),
        });
      };

      // Set global default options for all charts
      Highcharts.setOptions({
        exporting: {
          width: 1000,
          sourceWidth: 1000,
          fallbackToExportServer: false, // Ensure the export happens on the client side or not at all
        },
      });

      let chartArray = [];
      this.charts.forEach((c) => chartArray.push(c));
      Highcharts.exportCharts(chartArray, {
        type: 'application/pdf',
      });
      this.exportingStr = null;
    },
  },
};
</script>

<style>
.highcharts-contextbutton > .highcharts-button-box {
  fill: #1a1a1a;
}
</style>
