<template>
  <div id="map">
    <div
      id="mapContainer"
      ref="hereMap"
      :style="`height: ${mapHeight}px; width: 100%`"
      class="px-0"
    ></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'HereMap',
  props: {
    mapHeight: {
      type: Number,
      default: window.innerHeight - 90,
    },
    center: {
      type: Object,
      default() {
        return {
          lat: 39.8283,
          lng: -98.5795,
        };
      },
    },
    zoom: {
      type: Number,
      default: 4.5,
    },
    // theme prop defines color scheme of the map
    theme: {
      type: String,
      default: 'normal',
    },
    // cogPresent determines top-right + - btn locations
    cogPresent: {
      type: Boolean,
      default: false,
    },
    // available options for now: 'top-right' and 'bottom-right'
    zoomLocation: {
      type: String,
      default: 'top-right',
    },
    // outer scope level function that will retrieve the hereMap object for manipulation
    set: {
      type: Function,
      default: () => {
        return;
      },
    },
    // outer scope level function that will retrieve the hereUI object
    setUI: {
      type: Function,
      default: () => {
        return;
      },
    },
    // defines visibility of navigation controls (+ - zoom and traffic btn); reactive
    showNav: {
      type: Boolean,
      default: true,
    },
    // defines visibility of traffic btn; not reactive like showNav; overriden by false showNav
    showTraffic: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      platform: null,
      hereMap: null,
      hereUI: null,
      navControls: null,
    };
  },
  computed: {
    ...mapGetters({
      HERE_MAP_KEY: 'getMapKey',
    }),
  },
  watch: {
    // toggle visibility of map nav controls
    showNav() {
      this.setNavVisibility();
    },
  },
  async mounted() {
    await this.initializeHereMap();
    this.set(this.hereMap);
    this.setUI(this.hereUI);
  },
  methods: {
    async initializeHereMap() {
      const H = window.H;
      const mapContainer = this.$refs.hereMap;
      const apikey = this.HERE_MAP_KEY;
      const platform = new H.service.Platform({
        apikey: apikey,
      });
      this.platform = platform;
      // Obtain the default map types from the platform object
      const defaultLayers = this.platform.createDefaultLayers();
      // Fixed Map issue with customization, defaulted to night map
      // https://developer.here.com/documentation/maps/3.1.42.2/dev_guide/topics/raster.html
      // TODO: Find permanent solution for custom map implementation
      // const engineType = H.Map.EngineType['HARP'];
      // set style based on theme prop
      let style;
      if (this.theme.toLowerCase() === 'dark') {
        style = defaultLayers.raster.normal.mapnight;
      } else {
        style = defaultLayers.vector.normal.map;
      }
      // var vectorLayer = platform
      //   .getOMVService()
      //   .createLayer(style, { engineType });
      // Instantiate (and display) a map object:
      const map = new H.Map(mapContainer, style, {
        // engineType,
        zoom: this.zoom,
        center: this.center,
        pixelRatio: window.devicePixelRatio || 1,
      });
      this.hereMap = map;
      addEventListener('resize', () => map.getViewPort().resize());
      // add behavior control
      new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
      // add UI
      this.hereUI = H.ui.UI.createDefault(map, defaultLayers);
      // get an instance of the routing service version 8
      this.hereRouter = platform.getRoutingService(null, 8);

      // select UI buttons for positioning
      const zoom = this.hereUI.getControl('zoom');
      const mapSettings = this.hereUI.getControl('mapsettings');
      if (this.zoomLocation === 'bottom-right') {
        zoom.setAlignment('right-bottom');
        mapSettings.setAlignment('right-bottom');
      } else {
        zoom.setAlignment('right-top');
        mapSettings.setAlignment('right-top');
      }
      // style buttons manually to match cog
      const htmlZoom = zoom.getElement();
      htmlZoom.style.background = '#303030';
      if (this.cogPresent) htmlZoom.style.marginTop = '65px';
      htmlZoom.children[0].style.background = '#1a1a1a';
      htmlZoom.children[1].style.background = '#1a1a1a';
      htmlZoom.children[0].firstChild.firstChild.firstChild.style.fill =
        '#9e9e9e';
      htmlZoom.children[1].firstChild.firstChild.style.fill = '#9e9e9e';
      const htmlSetttings = mapSettings.getElement();
      htmlSetttings.firstChild.style.background = '#1a1a1a';
      htmlSetttings.firstChild.firstChild.firstChild.style.fill = '#9e9e9e';
      // remove satellite view option
      htmlSetttings.children[1].firstChild.children[1].remove();
      htmlSetttings.children[1].children[1].remove();

      // add nav controls to reference var
      this.navControls = [htmlZoom, htmlSetttings];
      this.setNavVisibility();
    },
    setNavVisibility() {
      // false showNav overrides traffic btn visibility
      this.navControls.forEach((element) => {
        if (this.showNav) element.style.display = 'block';
        else element.style.display = 'none';
      });
      // set traffic btn visibility
      if (!this.showTraffic) this.navControls[1].style.display = 'none';
    },
  },
};
</script>
