<template>
  <div>
    <context-menu id="context-menu" ref="ctxMenu">
      <v-list>
        <v-list-item
          v-for="(route, index) in actionItems"
          :key="index"
          :class="route.title"
          @click="setRoute(null, route.title)"
          >{{ route.title }}</v-list-item
        >
      </v-list>
    </context-menu>
    <v-data-table
      :no-data-text="noDataMsg"
      :headers="labels"
      :items="truckDtcData"
      :loading="loading"
      loading-text="Loading... Please wait"
      @click:row="showMenu"
    >
      <template v-slot:[`item.status`]="{ item }">
        <td class="text-xs-center narrow-col">
          <button
            class="status text-color-black"
            :class="statusColor(item.status)"
          >
            {{ item.status }}
          </button>
        </td>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-menu style="top: 312px; left: 300px" offset-y>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-dots-vertical</v-icon>
          </template>
          <v-list>
            <v-list-item
              v-for="(route, index) in actionItems"
              :key="index"
              :class="route.title"
              @click="setRoute(item, route.title)"
              >{{ route.title }}</v-list-item
            >
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { getLatestDTCs } from '@/api/trucks';
import contextMenu from 'vue-context-menu';

import dateFormat from 'dateformat';
export default {
  name: 'TruckDtcs',
  components: { contextMenu },
  props: {
    truck: {
      type: Number,
      default: null,
    },
    viewDtcHistory: {
      type: Function,
      default: (err) => {
        console.log(err, 'error');
      },
    },
    history: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      labels: [
        { text: 'Component', value: 'component' },
        { text: 'DTC Hex', value: 'dtc_hex' },
        { text: 'Status', value: 'status' },
        { text: 'Severity', value: 'severity' },
        { text: 'Description', value: 'description' },
        { text: 'DTC Date', value: 'dtc_date' },
        { text: 'Actions', value: 'actions' },
      ],
      noDataMsg: 'There are no recent truck DTCs',
      truckDtcData: [],
      selected: ['Active', 'Pending', 'Cleared'],
      actionItems: [
        // { title: 'Freeze Frame' },
        { title: 'Knowledge Base' },
        { title: 'History' },
        { title: 'Clear' },
      ],
      loading: true,
    };
  },
  watch: {},
  async mounted() {
    try {
      const { data } = await getLatestDTCs(this.truck);
      const dtc = data;

      if (dtc.length) {
        dtc.forEach((d) => {
          if (d.cleared) {
            d.status = 'Cleared';
          } else if (d.active) {
            d.status = 'Active';
          } else if (d.fail_count > 0 || d.trip_count > 0) {
            d.status = 'Warning';
          }
          this.truckDtcData.push({
            component: d.component ? d.component : '—',
            dtc_hex: d.code ? d.code : '—',
            status: d.status,
            severity: d.severity ? d.severity : '—',
            description: d.failure_mode ? d.failure_mode : '—',
            dtc_date: d.timestamp
              ? dateFormat(d.timestamp, 'm/d/yyyy hh:MM:ss TT')
              : '—',
            actions: d.actions ? d.actions : '—',
          });
        });
      }
      this.loading = false;
    } catch (e) {
      console.log('There was an error grabbing truck logs');
      this.loading = false;
    }
  },
  methods: {
    statusColor: function (status) {
      let code = '';
      switch (status) {
        case 'Cleared':
          code = 'border-green bg-green';
          break;
        case 'Pending':
          code = 'border-yellow bg-yellow';
          break;
        case 'Active':
          code = 'border-red bg-red';
          break;
        default:
          code = 'border-green bg-green';
      }
      return code;
    },
    setRoute: function (truck, route) {
      //This needs to be hooked up once the Freeze Frame is done
      if (route === 'Knowledge Base') {
        this.$router.push('/configuration-management');
      } else if (route === 'Freeze Frame') {
        this.$router.push('/configuration-management');
      } else if (route === 'Clear') {
        this.$router.push('/configuration-management');
      }
    },
    showMenu(truck) {
      this.$refs.ctxMenu.open();
    },
  },
};
</script>
<style scoped>
.dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  height: auto;
  width: auto;
  z-index: 1;
  transform: translate(-50%, -50%);
}
@media only screen and (min-width: 960px) {
  .padding {
    padding: 1rem;
  }
  .margin {
    margin: 0.2rem;
  }
}
</style>
