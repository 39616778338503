var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "trucks" } },
    [
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "py-0" },
                [
                  _c("v-data-table", {
                    staticClass: "elevation-1 secondary row-height-50",
                    attrs: {
                      headers: _vm.headers,
                      items: _vm.erxPackages,
                      search: _vm.searchText,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: `item.name`,
                          fn: function (props) {
                            return [
                              _c("th", { staticClass: "body-5" }, [
                                _vm._v(_vm._s(props.item.number)),
                              ]),
                              _c("div", { staticClass: "body-6" }, [
                                _vm._v(_vm._s(props.item.company)),
                              ]),
                            ]
                          },
                        },
                        {
                          key: `item.actions`,
                          fn: function ({ item }) {
                            return [
                              _c(
                                "v-menu",
                                {
                                  staticStyle: { top: "312px", left: "300px" },
                                  attrs: { "offset-y": "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on }) {
                                          return [
                                            _c("v-icon", _vm._g({}, on), [
                                              _vm._v("mdi-dots-vertical"),
                                            ]),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _c(
                                    "v-list",
                                    { attrs: { dense: "" } },
                                    [
                                      item.status !== "deprecated" &&
                                      item.status !== "deleted"
                                        ? _c(
                                            "v-list-item-group",
                                            _vm._l(
                                              _vm.actionItems,
                                              function (action, index) {
                                                return _c(
                                                  "v-list-item",
                                                  {
                                                    key: index,
                                                    class: action.title,
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.setAction(
                                                          item,
                                                          action.title
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-list-item-icon",
                                                      { staticClass: "mr-2" },
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v(
                                                            _vm._s(action.icon)
                                                          ),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-list-item-content",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          {
                                                            staticClass: "pr-2",
                                                          }
                                                        ),
                                                        _vm._v(
                                                          _vm._s(action.title) +
                                                            " "
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            1
                                          )
                                        : _c(
                                            "v-list-item-group",
                                            _vm._l(
                                              _vm.deletedActionItems,
                                              function (action, index) {
                                                return _c(
                                                  "v-list-item",
                                                  {
                                                    key: index,
                                                    class: action.title,
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.setAction(
                                                          item,
                                                          action.title
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-list-item-icon",
                                                      { staticClass: "mr-2" },
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v(
                                                            _vm._s(action.icon)
                                                          ),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-list-item-content",
                                                      [
                                                        _c(
                                                          "v-list-item-title",
                                                          {
                                                            staticClass: "pr-2",
                                                          }
                                                        ),
                                                        _vm._v(
                                                          _vm._s(action.title) +
                                                            " "
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: { width: "500", persistent: "" },
              model: {
                value: _vm.updatePackageStateModal,
                callback: function ($$v) {
                  _vm.updatePackageStateModal = $$v
                },
                expression: "updatePackageStateModal",
              },
            },
            [
              _c(
                "v-card",
                { staticClass: "pa-5 text-center" },
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", [
                        _c("h3", [
                          _vm._v(
                            " Are you sure you want to " + _vm._s(_vm.state)
                          ),
                          _c("br"),
                          _vm._v(
                            " " +
                              _vm._s(_vm.selectedPackage.config_name) +
                              " ? "
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-2",
                              attrs: { color: "primary", outlined: "" },
                              on: {
                                click: function ($event) {
                                  _vm.updatePackageStateModal = false
                                },
                              },
                            },
                            [_vm._v("Dismiss")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-btn",
                            {
                              staticStyle: { color: "black" },
                              attrs: { color: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.updatePackageState()
                                },
                              },
                            },
                            [_vm._v("Confirm")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }