var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", width: "520" },
          model: {
            value: _vm.openDialog,
            callback: function ($$v) {
              _vm.openDialog = $$v
            },
            expression: "openDialog",
          },
        },
        [
          _vm.loading ? _c("loader") : _vm._e(),
          _c(
            "v-row",
            { staticClass: "py-2", attrs: { align: "center" } },
            [
              _c("v-col", { attrs: { align: "center", cols: "4" } }, [
                _vm._v("Add Package"),
              ]),
              _c("v-col", [_c("v-spacer")], 1),
              _c(
                "v-col",
                { attrs: { align: "right", cols: "2" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function ($event) {
                          _vm.openDialog = false
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { light: "" } }, [
                        _vm._v("mdi-close-circle "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-stepper",
            {
              staticClass: "secondary",
              attrs: { "alt-labels": "" },
              model: {
                value: _vm.fStepper,
                callback: function ($$v) {
                  _vm.fStepper = $$v
                },
                expression: "fStepper",
              },
            },
            [
              _c(
                "v-stepper-header",
                [
                  _c("v-stepper-step", { attrs: { step: "1" } }, [
                    _vm._v("DETAILS"),
                  ]),
                  _c("v-divider"),
                  _c("v-divider"),
                  _c("v-stepper-step", { attrs: { step: "2" } }, [
                    _vm._v("REVIEW"),
                  ]),
                ],
                1
              ),
              _c(
                "v-stepper-items",
                [
                  _c(
                    "v-stepper-content",
                    { attrs: { step: "1" } },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "form",
                          attrs: { "lazy-validation": "" },
                          model: {
                            value: _vm.valid,
                            callback: function ($$v) {
                              _vm.valid = $$v
                            },
                            expression: "valid",
                          },
                        },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pb-0",
                                  attrs: { align: "center", cols: "12" },
                                },
                                [
                                  _c("v-select", {
                                    staticClass: "pt-1",
                                    attrs: {
                                      items: _vm.truckTypes,
                                      label: "Truck Type",
                                      outlined: "",
                                      rules: [(v) => !!v || "Required!"],
                                      required: "",
                                    },
                                    model: {
                                      value: _vm.packageTruckType,
                                      callback: function ($$v) {
                                        _vm.packageTruckType = $$v
                                      },
                                      expression: "packageTruckType",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "pt-0 pb-0",
                                  attrs: { cols: "12" },
                                },
                                [
                                  _c("v-textarea", {
                                    attrs: {
                                      label: "Package Description",
                                      outlined: "",
                                      rules: [(v) => !!v || "Required!"],
                                      required: "",
                                    },
                                    model: {
                                      value: _vm.packageDescription,
                                      callback: function ($$v) {
                                        _vm.packageDescription = $$v
                                      },
                                      expression: "packageDescription",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { staticClass: "pt-0 pb-0" },
                                [
                                  _c("v-file-input", {
                                    attrs: {
                                      type: "file",
                                      "truncate-length": "30",
                                      label: "Upload Package",
                                      rules: [(v) => !!v || "Required!"],
                                      required: "",
                                    },
                                    on: { change: _vm.selectPackageFile },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c("v-col", [_c("v-spacer")], 1),
                          _c(
                            "v-col",
                            { attrs: { align: "right", cols: "3" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { plain: "", color: "primary" },
                                  on: {
                                    click: function ($event) {
                                      _vm.openDialog = false
                                    },
                                  },
                                },
                                [_vm._v("Cancel")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { align: "left", cols: "4" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticStyle: { color: "black" },
                                  attrs: {
                                    color: "primary",
                                    disabled: !_vm.valid,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.checkForm()
                                    },
                                  },
                                },
                                [_vm._v("Continue")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  [
                    _c(
                      "v-stepper-content",
                      { attrs: { step: "2" } },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "overflow-y": "scroll",
                              "overflow-x": "hidden",
                              height: "450px",
                              width: "100%",
                            },
                          },
                          [
                            _c(
                              "v-row",
                              [
                                _c("v-col", { staticClass: "mt-1 text-h5" }, [
                                  _vm._v(" Review Package"),
                                  _c("br"),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { staticClass: "text-subtitle-1" },
                                  [_vm._v(" Details ")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              [
                                _c("v-col", [
                                  _c(
                                    "span",
                                    { staticClass: "opacity-title py-6" },
                                    [_vm._v("Package Name")]
                                  ),
                                  _c("br"),
                                  _vm._v(" " + _vm._s(_vm.fileName)),
                                  _c("br"),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              [
                                _c("v-col", [
                                  _c(
                                    "span",
                                    { staticClass: "opacity-title py-6" },
                                    [_vm._v("Truck Type")]
                                  ),
                                  _c("br"),
                                  _vm._v(" " + _vm._s(_vm.packageTruckType)),
                                  _c("br"),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              [
                                _c("v-col", [
                                  _c("span", { staticClass: "opacity-title" }, [
                                    _vm._v("Package Description"),
                                  ]),
                                  _c("br"),
                                  _vm._v(" " + _vm._s(_vm.packageDescription)),
                                  _c("br"),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              [
                                _c("v-col", [
                                  _c("span", { staticClass: "opacity-title" }, [
                                    _vm._v("Package Architecture"),
                                  ]),
                                  _c("br"),
                                  _vm._v(" " + _vm._s(_vm.packageArchitecture)),
                                  _c("br"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-row",
                          { staticClass: "mt-1" },
                          [
                            _c(
                              "v-col",
                              { attrs: { align: "left", cols: "4" } },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { plain: "", outlined: "" },
                                    on: {
                                      click: function ($event) {
                                        _vm.fStepper = 1
                                      },
                                    },
                                  },
                                  [_vm._v("Back")]
                                ),
                              ],
                              1
                            ),
                            _c("v-col", [_c("v-spacer")], 1),
                            _c(
                              "v-col",
                              { attrs: { align: "right", cols: "7" } },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: { plain: "", color: "primary" },
                                    on: {
                                      click: function ($event) {
                                        _vm.openDialog = false
                                      },
                                    },
                                  },
                                  [_vm._v("Cancel")]
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticStyle: { color: "black" },
                                    attrs: { color: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.submitPackage()
                                      },
                                    },
                                  },
                                  [_vm._v("Add Package")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }