<template>
  <div>
    <div v-if="data.length === 0" style="text-align: center">
      No Data for {{ title }}
    </div>
    <div v-else :id="`absoluteTime${id}`" class="absTime"></div>
  </div>
</template>

<script>
import Highcharts from 'highcharts';
import HighchartsBoost from 'highcharts/modules/boost';
HighchartsBoost(Highcharts);
import _ from 'lodash';
import { getTimeZoneOffset } from '@/utilities/dateFunctions.js';
import { mapGetters } from 'vuex';

export default {
  name: 'AbsoluteTime',
  props: {
    id: {
      type: String,
      required: false,
      default: '',
    },
    data: {
      type: Array,
      required: false,
      default: () => [],
    },
    title: {
      type: String,
      required: false,
      default: 'Absolute Time',
    },
    addChart: {
      type: Function,
      required: false,
      default: () => {},
    },
    zoom: {
      type: Function,
      required: false,
      default: () => {},
    },
    truckNames: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      options: null,
      seriesArray: [],
      colors: null,
      chart: null,
      boostThreshold: 1,
      tzOffsetMinutes: 0,
    };
  },
  computed: {
    ...mapGetters({
      userPreferences: 'getUserPreferences',
    }),
  },
  watch: {
    data() {
      if (this.data && this.data.length > 0) {
        this.processData();
      }
    },
  },
  async mounted() {
    if (this.data && this.data.length > 0) {
      this.processData();
    }
  },
  // prevent a memory leak
  beforeDestroy() {
    if (this.chart) this.chart.destroy();
  },
  methods: {
    // build seriesArray object for highcharts ingestion
    processData() {
      this.seriesArray = [];
      const excludeSignals = [
        'company_id',
        'reference_row',
        'timestamp',
        'truck_id',
      ];
      for (const truck of this.data) {
        for (const signal of Object.keys(truck.data[0])) {
          if (!excludeSignals.includes(signal)) {
            const signalData = [];
            for (const [index, row] of truck.data.entries()) {
              // Get the offset in seconds to UTC time based on
              // user's preference for time zone and based onf first timestamp
              // in the data.  This is used to set the time offset for Highcharts
              if (index === 0) {
                let tz = this.userPreferences.timeZonePref.canonical;
                this.tzOffsetMinutes = getTimeZoneOffset(row.timestamp, tz);
              }
              let utcTimeMs = new Date(row.timestamp).valueOf();
              signalData.push([utcTimeMs, row[signal]]);
            }
            signalData.sort((a, b) => a[0] - b[0]);
            const truckName = this.truckNames
              ? this.truckNames[truck.truck_id]
              : truck.truck_id;
            this.seriesArray.push({
              name: truckName + ' - ' + _.trimStart(signal, 'ff'), // remove ff prefix if it exists
              data: signalData,
              truck: truckName,
              boostThreshold: this.boostThreshold,
            });
          }
        }
      }
      this.buildGraph();
    },
    buildGraph() {
      // replace highcharts gray color that blends in too much
      this.colors = Highcharts.getOptions().colors.map((color) => {
        if (color === '#434348') return '#f45b5b';
        return color;
      });
      this.setOptions();
      this.chart = Highcharts.chart(`absoluteTime${this.id}`, this.options);
      this.chart.id = parseInt(this.id);
      this.addChart(this.chart);
    },
    // depends on this.colors, this.title, this.seriesArray, this.tzOffsetMinutes
    setOptions() {
      const vm = this;
      this.options = {
        credits: { enabled: false },
        chart: {
          backgroundColor: '#1E1E1E',
          type: 'line',
          zoomType: 'xy',
          events: {
            selection: (e) => {
              if (e.xAxis) {
                vm.zoom(e.xAxis[0].min, e.xAxis[0].max, e);
              } else {
                vm.zoom(null, null, e);
              }
            },
          },
        },
        colors: this.colors,
        time: {
          timezoneOffset: vm.tzOffsetMinutes,
        },
        title: {
          text: this.title,
          style: {
            color: '#FFFFFF',
          },
        },
        tooltip: {
          shared: true,
          backgroundColor: '#363636',
          borderRadius: 8,
          borderColor: '#363636',
          style: {
            color: '#FFFFFF',
          },
        },
        legend: {
          itemStyle: {
            color: '#FFFFFF',
            fontWeight: 'bold',
          },
        },
        xAxis: {
          type: 'datetime',
          title: {
            text: 'Time',
            style: {
              color: '#FFFFFF',
            },
          },
          labels: {
            step: 1,
            style: {
              color: '#FFFFFF',
            },
            formatter: function () {
              let adjustedTime = this.value - vm.tzOffsetMinutes * 60 * 1000;
              return Highcharts.dateFormat('%b %d %Y \n%H:%M:%S', adjustedTime);
            },
          },
          lineColor: 'rgba(255, 255, 255, 0.12)',
          gridLineWidth: 1,
          gridLineColor: 'rgba(255, 255, 255, 0.12)',
        },
        yAxis: [
          {
            labels: {
              format: '{value}',
              style: {
                color: 'white',
              },
            },
            title: {
              text: 'Signal Value',
            },
            gridLineWidth: 1,
            gridLineColor: 'rgba(255, 255, 255, 0.12)',
          },
        ],
        setOptions: {
          width: '100%',
        },
        series: this.seriesArray,
        exporting: {
          enabled: true,
          sourceWidth: 1000,
          sourceHeight: 400,
          scale: 1,
        },
        plotOptions: {
          series: {
            shadow: false,
            marker: { enabled: false },
            connectNulls: true,
          },
        },
      };
    },
  },
};
</script>

<style scoped>
.absTime {
  margin-top: 5px;
  margin-bottom: 5px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
</style>
