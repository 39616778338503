import axios from 'axios';

const BASE_URL = process.env.VUE_APP_API_PATH_BASE;

async function getStages() {
  return await axios.get(BASE_URL + `manufacturing/stages`);
}
async function getPlans() {
  return await axios.get(BASE_URL + `manufacturing/plans`);
}
async function getSteps() {
  return await axios.get(BASE_URL + `manufacturing/steps`);
}
async function getCategories() {
  return await axios.get(BASE_URL + `manufacturing/categories`);
}
async function getStatus() {
  return await axios.get(BASE_URL + `manufacturing/test_status`);
}
async function getIssues(plan_id) {
  return await axios.get(BASE_URL + `manufacturing/issues/${plan_id}`);
}
async function getIssueStatus() {
  return await axios.get(BASE_URL + `manufacturing/issues_status`);
}
async function getTests() {
  return await axios.get(BASE_URL + `manufacturing/tests`);
}
async function getTestResults(plan_id) {
  return await axios.get(BASE_URL + `manufacturing/test_results/${plan_id}`);
}
async function getFailedTestCount(plan_id) {
  return await axios.get(BASE_URL + `manufacturing/failed_tests/${plan_id}`);
}
async function getRecentPlanItems(plan_id) {
  return await axios.get(BASE_URL + `manufacturing/plans/recent/${plan_id}`);
}
async function getCompLvlFaults() {
  return await axios.get(BASE_URL + `manufacturing/component_level_faults`);
}
async function addPlans(newPlan) {
  return await axios.post(BASE_URL + `manufacturing/plans`, newPlan);
}
async function addSteps(newStep) {
  return await axios.post(BASE_URL + `manufacturing/steps`, newStep);
}
async function addTests(newTest) {
  return await axios.post(BASE_URL + `manufacturing/test`, newTest);
}
async function addTestResults(results) {
  return await axios.post(BASE_URL + `manufacturing/test_results`, results);
}
async function addIssue(plan_id, issue) {
  return await axios.post(BASE_URL + `manufacturing/issues/${plan_id}`, issue);
}
async function updateIssue(issue_id, issue) {
  return await axios.put(BASE_URL + `manufacturing/issues/${issue_id}`, issue);
}
async function updateTestResults(results, id) {
  return await axios.put(
    BASE_URL + `manufacturing/test_results/${id}`,
    results
  );
}
async function updatePlans(id, plan) {
  return await axios.put(BASE_URL + `manufacturing/plans/${id}`, plan);
}

export {
  getPlans,
  getSteps,
  getStages,
  getCategories,
  getStatus,
  getTests,
  getTestResults,
  getFailedTestCount,
  getRecentPlanItems,
  getIssues,
  getIssueStatus,
  getCompLvlFaults,
  addPlans,
  addSteps,
  addTests,
  addTestResults,
  addIssue,
  updateIssue,
  updateTestResults,
  updatePlans,
};
