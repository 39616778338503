<template>
  <v-form @submit.prevent="updateOffset">
    <v-card id="offset" max-width="500" class="offsetCard">
      <v-card-title>Million Miles Offset</v-card-title>
      <v-card-subtitle>Value that gets added to odomoterTotal</v-card-subtitle>
      <v-card-text
        >Current Offset:
        <span class="offset">{{ realOffset }}</span>
        <v-spacer></v-spacer>
        <a
          href="https://hyliion.atlassian.net/wiki/spaces/HLS/pages/3372089364/ERX+Miles+Offset"
          target="_blank"
          >ERX Mile Offset Confluence Log Link</a
        >
        <br /><br />
        Set new offset:
        <v-text-field
          v-model="offsetModel"
          outlined
          style="margin-bottom: -10px"
          @keydown.enter="updateOffset()"
        ></v-text-field>
      </v-card-text>
      <v-card-actions style="display: block; margin-left: auto">
        <v-btn dark color="primary" type="submit" @click="updateOffset"
          >Update</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<script>
import { getOffset, updateOffset } from '@/api/million';
export default {
  name: 'OffsetForm',
  props: ['triggerSnack'],
  data() {
    return {
      realOffset: null,
      offsetModel: null,
    };
  },
  mounted() {
    this.fetchOffset();
  },
  methods: {
    fetchOffset() {
      const vm = this;
      this.realOffset = null;
      getOffset()
        .then((r) => (vm.realOffset = r.toLocaleString()))
        .catch((e) => {
          vm.triggerSnack('There was an error fetching offset value.', 'error');
        });
    },
    updateOffset() {
      const vm = this;
      updateOffset(this.offsetModel)
        .then(() => {
          getOffset().then((o) => (vm.realOffset = o.toLocaleString()));
          vm.triggerSnack('Offset successfully updated!', 'success');
        })
        .catch((e) => {
          vm.triggerSnack(
            'There was an error updating offset. Ensure value is an integer',
            'error'
          );
        });
    },
  },
};
</script>
<style scoped>
.offset {
  font-weight: bolder;
  color: white;
  margin-left: 5px;
}
.offsetCard {
  display: block;
  margin: auto;
  margin-bottom: 50px;
  margin-top: 30px;
}
</style>
