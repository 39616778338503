var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", width: "550" },
          model: {
            value: _vm.openDialog,
            callback: function ($$v) {
              _vm.openDialog = $$v
            },
            expression: "openDialog",
          },
        },
        [
          _vm.loading ? _c("loader") : _vm._e(),
          _c(
            "v-row",
            { staticClass: "py-2", attrs: { align: "center" } },
            [
              _c("v-col", { attrs: { align: "center", cols: "4" } }, [
                _c("h3", [_vm._v("Add Customer")]),
              ]),
              _c("v-col", [_c("v-spacer")], 1),
              _c(
                "v-col",
                { attrs: { align: "right", cols: "2" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function ($event) {
                          _vm.openDialog = false
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { light: "" } }, [
                        _vm._v("mdi-close-circle "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-stepper",
            {
              staticClass: "secondary",
              attrs: { "alt-labels": "" },
              model: {
                value: _vm.fStepper,
                callback: function ($$v) {
                  _vm.fStepper = $$v
                },
                expression: "fStepper",
              },
            },
            [
              _c(
                "v-stepper-header",
                [
                  _c("v-stepper-step", { attrs: { step: "1" } }, [
                    _vm._v("DETAILS"),
                  ]),
                  _c("v-divider"),
                  _c("v-divider"),
                  _c("v-stepper-step", { attrs: { step: "2" } }, [
                    _vm._v("PERMISSIONS"),
                  ]),
                ],
                1
              ),
              _c(
                "v-stepper-items",
                [
                  _c(
                    "v-stepper-content",
                    { attrs: { step: "1" } },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "form",
                          attrs: { "lazy-validation": "" },
                          model: {
                            value: _vm.userValid,
                            callback: function ($$v) {
                              _vm.userValid = $$v
                            },
                            expression: "userValid",
                          },
                        },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "mt-1",
                                  attrs: { align: "center", cols: "12" },
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      rules: _vm.nameRules,
                                      label: "Customer Name",
                                      required: "",
                                      outlined: "",
                                    },
                                    model: {
                                      value: _vm.customerName,
                                      callback: function ($$v) {
                                        _vm.customerName = $$v
                                      },
                                      expression: "customerName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { align: "center", cols: "12" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.truckTypes,
                                      label: "Customer Truck Type",
                                      required: "",
                                      outlined: "",
                                    },
                                    model: {
                                      value: _vm.customerTruckType,
                                      callback: function ($$v) {
                                        _vm.customerTruckType = $$v
                                      },
                                      expression: "customerTruckType",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "pt-0 hidden-xs-and-down" },
                            [_c("v-spacer")],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-0",
                              attrs: { align: "center", cols: "3" },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { plain: "", color: "primary" },
                                  on: {
                                    click: function ($event) {
                                      _vm.openDialog = false
                                    },
                                  },
                                },
                                [_vm._v("Cancel")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pt-0",
                              attrs: { align: "center", cols: "4" },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticStyle: { color: "black" },
                                  attrs: {
                                    color: "primary",
                                    disabled:
                                      !_vm.userValid || _vm.submitClicked,
                                  },
                                  on: { click: _vm.checkcustomerName },
                                },
                                [_vm._v("Continue")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-stepper-content",
                    { staticClass: "pa-0", attrs: { step: "2" } },
                    [
                      _c("CustomerPermissions", {
                        attrs: {
                          "customer-permissions": _vm.customerPermissions,
                        },
                        on: {
                          cancel: function ($event) {
                            _vm.fStepper = 1
                          },
                          returnPermissions: _vm.addCustomer,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }