<template>
  <v-container fluid>
    <v-row
      ><v-col class="pl-0 pb-0"
        ><h3>{{ faultedTrucks ? faultedTrucks.length : 0 }} Results</h3></v-col
      ></v-row
    >
    <v-row>
      <v-col class="pa-0" cols="12">
        <faulted-table :trucks="faultedTrucks" @viewClicked="viewTruck" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getIcon, severityColor } from '@/utilities/ExternalHealth.js';
import FaultedTableTemplate from './TableUtils/FaultedTableTemplate';
export default {
  name: 'FaultedTable',
  components: {
    'faulted-table': FaultedTableTemplate,
  },
  props: {
    faultedTrucks: {
      type: Array,
      required: true,
    },
    toggleDetails: {
      type: Function,
      default: () => {},
    },
    setDetails: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      getIcon,
      severityColor,
    };
  },
  methods: {
    viewTruck(truck) {
      this.toggleDetails(true);
      this.setDetails(truck);
    },
  },
};
</script>
<style scoped></style>
