var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticStyle: { display: "flex", "justify-content": "space-between" },
        },
        [
          _c("div", { staticStyle: { "margin-bottom": "5px" } }, [
            _c("h1", [_vm._v(_vm._s(_vm.template.name))]),
            _c("h4", [_vm._v(_vm._s(_vm.template.description))]),
          ]),
          _c(
            "div",
            [
              _vm.trucksSelected
                ? _c(
                    "v-btn",
                    {
                      staticStyle: { "margin-top": "auto" },
                      attrs: { color: "info" },
                      on: { click: _vm.exportPDF },
                    },
                    [_vm._v("Export PDF")]
                  )
                : _vm._e(),
              _vm.exportingStr != null
                ? _c("div", [_vm._v(_vm._s(_vm.exportingStr))])
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      !_vm.trucksSelected
        ? _c(
            "div",
            [
              _c(
                "v-alert",
                {
                  staticStyle: { "text-align": "center" },
                  attrs: { color: "warning", outlined: "" },
                },
                [
                  _vm._v("No Trucks Selected. Edit Selection in "),
                  _c(
                    "v-icon",
                    { staticStyle: { "margin-top": "-4px", opacity: "0.6" } },
                    [_vm._v("mdi-cog")]
                  ),
                  _vm._v(" Study Board Settings "),
                ],
                1
              ),
            ],
            1
          )
        : _vm._l(_vm.template.graphs, function (graph) {
            return _c(
              "div",
              {
                key: graph.id,
                staticStyle: {
                  "margin-top": "20px",
                  "box-shadow": "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                },
              },
              [
                _vm.graphsLoading[graph.id]
                  ? _c("v-progress-circular", {
                      staticClass: "loader",
                      staticStyle: { display: "block", margin: "auto" },
                      attrs: {
                        color: "primary",
                        size: 70,
                        width: 7,
                        indeterminate: "",
                      },
                    })
                  : graph.graph_type === "Absolute Time Line Graph" &&
                    _vm.graphData[graph.id]
                  ? _c("AbsoluteTime", {
                      ref: `abstime${graph.id}`,
                      refInFor: true,
                      attrs: {
                        id: graph.id.toString(),
                        title: graph.name,
                        data: _vm.graphData[graph.id],
                        "add-chart": _vm.addChart,
                        zoom: _vm.zoom,
                        "truck-names": _vm.truckNames,
                      },
                    })
                  : graph.graph_type === "Candlestick" &&
                    _vm.graphData[graph.id]
                  ? _c("Candlestick", {
                      ref: `candle${graph.id}`,
                      refInFor: true,
                      attrs: {
                        id: graph.id.toString(),
                        title: graph.name,
                        data: _vm.graphData[graph.id],
                        "add-chart": _vm.addChart,
                        thresholds: _vm.thresholds[graph.id],
                        "truck-names": _vm.truckNames,
                      },
                    })
                  : graph.graph_type === "Jitter" && _vm.graphData[graph.id]
                  ? _c("Jitter", {
                      ref: `jitter${graph.id}`,
                      refInFor: true,
                      attrs: {
                        id: graph.id.toString(),
                        title: graph.name,
                        data: _vm.graphData[graph.id],
                        "add-chart": _vm.addChart,
                        thresholds: _vm.thresholds[graph.id],
                        signal: "maxmin",
                        "truck-names": _vm.truckNames,
                      },
                    })
                  : _vm._e(),
              ],
              1
            )
          }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }