<template>
  <div id="truck">
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-breadcrumbs
            style="padding: 5px 0 0 5px"
            :items="addCrumb"
            divider="/"
          ></v-breadcrumbs>
        </v-col>
        <v-col><v-spacer /></v-col>
      </v-row>
      <v-row style="margin-top: -30px">
        <v-col>
          <v-tabs v-model="tab" background-color="secondary">
            <v-tab v-for="item in tabItems" :key="item">
              {{ item }}
            </v-tab>
          </v-tabs>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table v-if="!truckData" :loading="true"></v-data-table>
          <DTCSummary
            v-else-if="tab === 0 && truckData.truck_type.includes('EX')"
            :truck="truck"
            :view-dtc-history="viewDtcHistory"
          />
          <ErxDtcSummary
            v-else-if="tab === 0 && truckData.truck_type.includes('ERX')"
          />
          <DTCHistory
            v-if="tab === 1 && truckData.truck_type.includes('EX')"
            :truck="truck"
            :current-dtc="currentDtc"
          />
          <ErxDtcHistory
            v-else-if="tab === 1 && truckData.truck_type.includes('ERX')"
            :truck="truck"
            :current-dtc="currentDtc"
          />
          <!-- <DTCFreezeFrame
            v-if="tab === 2"
            :truck="truck"
            :current-dtc="currentDtc"
          /> -->
          <DTCDocumentation
            v-else-if="tab === 2 && this.truckData.truck_type.includes('ERX')"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';
import DTCSummary from './DTCSummary';
import ErxDtcSummary from './ErxDtcSummary';
import ErxDtcHistory from './ErxDtcHistory';
import DTCHistory from './DTCHistory';
// import DTCFreezeFrame from './DTCFreezeFrame';
import DTCDocumentation from '@/components/DataDictionary/WorkbookTable';
import { getHealthTrucks } from '@/api/trucks';
import Health from '../health';
import _ from 'lodash';

export default {
  name: 'TruckDetails',
  components: {
    DTCSummary,
    DTCHistory,
    // DTCFreezeFrame,
    DTCDocumentation,
    ErxDtcSummary,
    ErxDtcHistory,
  },
  data() {
    return {
      panelIndex: undefined, // Hack for show/hide panel text
      tab: 0,
      truck: null,
      truckData: null,
      currentDtc: '',
      tabItems: ['Active', 'History', 'Documentation'],
    };
  },
  computed: {
    ...mapGetters({
      userRoles: 'getUserRoles',
      storeHealthTrucks: 'getHealthTrucks',
    }),
    addCrumb: function () {
      return [
        {
          text: 'Health',
          disabled: false,
          exact: true,
          link: true,
          to: {
            name: 'Health',
            params: {
              paramName: Health,
            },
          },
        },
        {
          text: `${this.truckData?.number || ''}`,
          disabled: true,
          href: `/health/${this.truckData?.number || ''}`,
        },
      ];
    },
    icon() {
      if (this.allSelected) return 'mdi-close-box';
      else return 'mdi-checkbox-blank-outline';
    },
  },
  created() {
    this.truck = parseInt(this.$route.params.id);
  },
  async mounted() {
    if (isNaN(this.$route.params.id)) {
      this.$router.push(`/health`); //If the route param is not a number, it will redirect user back to health page
    } else {
      await this.getTruckData();
    }
  },
  methods: {
    ...mapMutations(['assignHealthTruck']),
    async getTruckData() {
      const vm = this;
      let res;
      // filter health trucks if already defined in store, otherwise just call for current truck
      if (this.storeHealthTrucks) {
        res = _.cloneDeep(
          this.storeHealthTrucks.filter((t) => t.id === vm.truck)[0]
        );
      } else {
        res = await getHealthTrucks(this.truck);
        res = res.data[0];
      }
      this.truckData = this.formatData(res);
      this.assignHealthTruck(this.truckData);
    },
    formatData(truck) {
      if (!truck) {
        //If the truck has no data, user will be redirected to the health page
        this.$router.push(`/health`);
      }
      truck.fuel_type = truck.fuel_type.toUpperCase();
      truck.address = truck.address ? truck.address : '—';
      truck.position = truck.position
        ? `${truck.position.lat},${truck.position.lng}`
        : '—';
      return truck;
    },
    setCurrentDtc(currentDtc) {
      this.currentDtc = currentDtc;
    },
    viewDtcHistory(currentDtc) {
      this.tab = 1;
      this.setCurrentDtc(currentDtc);
    },
  },
};
</script>
