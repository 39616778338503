import axios from 'axios';

const BASE_URL = process.env.VUE_APP_API_PATH_BASE;

async function getCompanies() {
  return await axios.get(BASE_URL + `company/`);
}

async function setNewCustomers(config) {
  return await axios.post(BASE_URL + 'registration/create_customer', config);
}

async function getCustomers() {
  return await axios.get(BASE_URL + 'registration/get_customers');
}

async function addCompany(config) {
  return await axios.post(BASE_URL + 'company/', config);
}

// External Permissions for Company/Customer
async function updateCompanyPermissions(company, permissions) {
  return await axios.put(BASE_URL + `company/external_permissions/${company}`, {
    external_permissions: permissions,
  });
}

async function removeCompany(id) {
  return await axios.patch(BASE_URL + `company/remove/${id}`);
}

async function getFleets() {
  return await axios.get(BASE_URL + 'company/fleet');
}

export {
  getCompanies,
  getCustomers,
  setNewCustomers,
  addCompany,
  updateCompanyPermissions,
  removeCompany,
  getFleets,
};
