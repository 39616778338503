<template>
  <!-- <v-container fluid :class="!dashboardActive && 'pa-0'"> -->
  <v-container fluid class="pa-0">
    <v-row align="center">
      <v-btn icon tile large :style="cogStyle" @click="openDrawerCogButton()"
        ><v-icon style="opacity: 0.6">mdi-cog</v-icon></v-btn
      >
    </v-row>
    <div id="id-td-MapView" class="data">
      <div id="map">
        <div
          id="mapContainer"
          ref="hereMap"
          :style="`height: ${mapHeight}px; width: 100%`"
        ></div>
      </div>
    </div>
    <!-- MAIN DRAWER -->
    <v-navigation-drawer
      v-model="listDrawer"
      fixed
      persistent
      right
      width="360"
      class="shade"
    >
      <v-container>
        <v-row align="center">
          <v-col align="right" class="mb-2">
            <v-btn icon @click="listDrawer = false">
              <v-icon light>mdi-close-circle </v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <div v-if="showList == true">
          <h1>Geo-Zones</h1>
          <div class="my-2"></div>

          <!-- MAIN DRAWER - TABS -->
          <v-tabs v-model="tab" fixed-tabs background-color="#272727" dark>
            <v-tab v-for="item in tabItems" :key="item.tab">
              {{ item.tab }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item v-for="item in tabItems" :key="item.tab">
              <v-card flat>
                <!-- MAIN DRAWER - LIST TAB -->
                <div v-if="item.content === 'list'">
                  <v-container class="shade">
                    <v-select
                      v-model="companySelected"
                      :items="companies"
                      name="company"
                      item-text="company_name"
                      label="Select Company"
                      return-object
                      outlined
                      @change="
                        populateTable();
                        getList();
                      "
                    ></v-select>
                    <p>{{ geoZonesSelectedList.length }} Results</p>
                    <div
                      v-if="
                        geoZonesSelectedList.length == 0 &&
                        companySelected != null
                      "
                    >
                      <v-container>
                        <v-icon
                          class="d-flex justify-center py-10"
                          style="font-size: 50px; color: #2196f3"
                          >mdi-alert-circle
                        </v-icon>
                        <p text-align: center>No Active Geo-Zones!</p>
                        <p>
                          They company you selected has no active Geo-Zones to
                          view in the list.
                        </p>
                      </v-container>
                    </div>
                    <div v-else>
                      <!-- MAIN DRAWER - LIST TABLE -->
                      <v-row class="my-0">
                        <v-col>
                          <v-data-table
                            :headers="geoZonesLabels"
                            :items="geoZonesSelectedList"
                            :items-per-page="10"
                            class="elevation-0 shade"
                            @click:row="handleClickRow"
                          >
                            <template v-slot:[`item.edit`]="{ item }">
                              <v-btn
                                v-if="item.is_creator == true"
                                icon
                                tile
                                large
                                style="
                                  margin-right: 0.5rem;
                                  background-color: #272727;
                                  border-radius: 5px;
                                "
                                @click="listTabPencilButton(item)"
                              >
                                <v-icon>mdi-pencil</v-icon>
                              </v-btn>
                              <v-btn
                                v-else
                                icon
                                tile
                                large
                                style="
                                  margin-right: 0.5rem;
                                  background-color: #272727;
                                  border-radius: 5px;
                                "
                                @click="listTabPencilButton(item)"
                              >
                                <v-icon>mdi-lock</v-icon>
                              </v-btn>
                            </template>
                          </v-data-table>
                        </v-col>
                      </v-row>
                    </div>
                  </v-container>
                </div>

                <!-- MAIN DRAWER - CREATE TAB -->
                <div v-if="item.content === 'create'">
                  <v-container class="shade">
                    <v-form ref="form" v-model="valid" lazy-validation>
                      <v-select
                        v-model="companySelected"
                        :items="companies"
                        name="company"
                        item-text="company_name"
                        label="Select Company"
                        return-object
                        outlined
                        @change="
                          populateTable();
                          getList();
                        "
                      ></v-select>

                      <!-- If Company is NOT selected -->
                      <div
                        v-if="
                          companySelected == 'All' || companySelected == null
                        "
                      >
                        <v-container>
                          <v-icon
                            class="d-flex justify-center py-10"
                            style="font-size: 50px; color: red"
                            >mdi-alert-octagon
                          </v-icon>
                          <p text-align: center>No Company selected!</p>
                          <p>
                            Select a company from the company dropdown above to
                            create a Geo-zone.
                          </p>
                        </v-container>
                      </div>
                      <!-- If Company is selected -->
                      <div
                        v-if="
                          companySelected != 'All' && companySelected != null
                        "
                      >
                        <v-radio-group v-model="shape">
                          <!-- <v-radio
                            label="Start and End Route"
                            value="start-end"
                          ></v-radio> -->
                          <v-radio
                            label="Circle Geo-Zone"
                            value="circle"
                          ></v-radio>
                          <!-- <v-radio
                            label="Polygon Geo-Zone"
                            value="polygon"
                          ></v-radio> -->
                        </v-radio-group>
                        <v-sheet
                          v-if="
                            shape == null &&
                            companySelected != 'All' &&
                            companySelected != null
                          "
                          class="my-10"
                          color="grey darken-3"
                          elevation="1"
                          height="140"
                        >
                          <v-container class="black">
                            <p>How to Create a Zone</p>
                            <p>
                              Click and drag on the map to create a circle area
                              then increase or decrease the size of the Geo-Zone
                              area you want.
                            </p>
                          </v-container>
                        </v-sheet>
                      </div>
                      <div
                        v-if="
                          shape == 'circle' &&
                          companySelected != 'All' &&
                          companySelected != null
                        "
                      >
                        <p>Circle Zone</p>
                        <v-text-field
                          v-model="name"
                          :rules="[(v) => !!v || 'Required!']"
                          label="Name"
                          outlined
                          required
                        ></v-text-field>
                        <v-text-field
                          v-model="description"
                          label="Description"
                          outlined
                          required
                        ></v-text-field>
                        <v-text-field
                          v-model.number="latitude"
                          :rules="[(v) => !!v || 'Required!']"
                          :disabled="is_service_station"
                          label="Latitude"
                          outlined
                          required
                        ></v-text-field>
                        <v-text-field
                          v-model.number="longitude"
                          :rules="[(v) => !!v || 'Required!']"
                          :disabled="is_service_station"
                          label="Longitude"
                          outlined
                          required
                        ></v-text-field>
                        <v-text-field
                          v-model.number="radius_meters"
                          :rules="[(v) => !!v || 'Required!']"
                          label="Radius in Meters"
                          outlined
                          required
                        ></v-text-field>
                        <v-checkbox
                          v-model="is_landmark"
                          label="Set as Landmark"
                        ></v-checkbox>
                        <!-- <v-checkbox
                          v-model="ev_zone"
                          label="*EV Zones"
                        ></v-checkbox> -->
                        <!-- <v-checkbox
                          v-model="quiet_zone"
                          label="Quiet Zones"
                        ></v-checkbox> -->
                        <!-- <v-checkbox
                          v-model="soc_zone"
                          label="Soc Zone"
                        ></v-checkbox> -->
                        <!-- <p>
                          *If there is insufficient charge available, then the
                          generator will run even while in an EV Zone. This is
                          usually the result of running in EV Mode for too long
                          and/or insufficient time between EV Zones to recharge.
                        </p> -->
                        <v-checkbox
                          v-model="is_service_station"
                          label="Service Station"
                        ></v-checkbox>
                        <template v-if="is_service_station">
                          <v-text-field
                            v-model="service_station.name"
                            label="Service Station Name"
                            :rules="[(v) => !!v || 'Required!']"
                            outlined
                            required
                          ></v-text-field>
                          <v-text-field
                            v-model="service_station.address"
                            label="Service Station Address"
                            :rules="[(v) => !!v || 'Required!']"
                            outlined
                            required
                            @change="searchCoords"
                          ></v-text-field>
                          <v-textarea
                            v-model="service_station.additional_info"
                            label="Additional Info"
                            outlined
                          ></v-textarea>
                          <v-text-field
                            v-model="service_station.network.name"
                            label="Network Name"
                            :rules="[(v) => !!v || 'Required!']"
                            outlined
                            required
                          ></v-text-field>
                          <v-textarea
                            v-model="service_station.network.description"
                            label="Network Description"
                            outlined
                          ></v-textarea>
                        </template>
                        <v-btn
                          color="primary"
                          class="center"
                          :disabled="!valid"
                          block
                          @click="createTabAddButton()"
                          >Add</v-btn
                        >
                      </div>
                    </v-form>
                  </v-container>
                </div>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </div>

        <!-- MAIN DRAWER - EDIT FORM -->
        <div v-else-if="showList == false">
          <v-container>
            <h1>Edit Circle Zone</h1>
            <div class="my-5"></div>
            <div class="text-right">
              <v-dialog v-model="mainDrawerDeleteDialog" width="500">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="is_creator == true"
                    class="primary--text"
                    color="#272727"
                    dark
                    v-bind="attrs"
                    elevation="0"
                    v-on="on"
                  >
                    DELETE
                  </v-btn>
                  <v-btn
                    v-else
                    class="white--text"
                    color="#757575"
                    dark
                    elevation="0"
                  >
                    DELETE
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title class="text-h5">
                    Delete Circle Zone
                  </v-card-title>
                  <div v-if="delete_allowed == true">
                    <v-card-text>
                      Are you sure you want to delete the selected zone?
                    </v-card-text>
                  </div>
                  <div v-else>
                    <v-card-text> Delete is not allowed. </v-card-text>
                    <v-card-text>
                      {{ message }}
                    </v-card-text>
                  </div>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      text
                      @click="mainDrawerDeleteDialog = false"
                    >
                      CLOSE
                    </v-btn>
                    <div v-if="delete_allowed == true">
                      <v-btn
                        color="primary"
                        text
                        @click="editFormDeleteButton()"
                      >
                        AGREE
                      </v-btn>
                    </div>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
            <div class="my-5"></div>
            <v-form ref="editForm" v-model="valid" lazy-validation>
              <v-container v-if="is_creator == false" class="black my-6">
                Editing this Geozone is only allowed for the creator.
              </v-container>
              <div>
                <v-text-field
                  v-model="name"
                  label="Name"
                  outlined
                  required
                ></v-text-field>
                <v-text-field
                  v-model="description"
                  label="Description"
                  outlined
                  required
                ></v-text-field>
                <v-text-field
                  v-model="latitude"
                  label="Latitude"
                  :disabled="is_service_station"
                  outlined
                  required
                ></v-text-field>
                <v-text-field
                  v-model="longitude"
                  label="Longitude"
                  :disabled="is_service_station"
                  outlined
                  required
                ></v-text-field>
                <v-text-field
                  v-model="radius_meters"
                  label="Radius in Meters"
                  outlined
                  required
                ></v-text-field>
                <v-checkbox
                  v-model="is_landmark"
                  label="Set as Landmark"
                ></v-checkbox>
                <!-- <v-checkbox v-model="ev_zone" label="*EV Zones"></v-checkbox> -->
                <!-- <v-checkbox
                  v-model="quiet_zone"
                  label="Quiet Zones"
                ></v-checkbox> -->
                <!-- <v-checkbox v-model="soc_zone" label="SOC Zone"></v-checkbox> -->
                <!-- <p>
                  *If there is insufficient charge available, then the generator
                  will run even while in an EV Zone. This is usually the result
                  of running in EV Mode for too long and/or insufficient time
                  between EV Zones to recharge.
                </p> -->
                <v-checkbox
                  v-model="is_service_station"
                  label="Service Station"
                ></v-checkbox>
                <template v-if="is_service_station">
                  <v-text-field
                    v-model="service_station.name"
                    label="Service Station Name"
                    :rules="[(v) => !!v || 'Required!']"
                    outlined
                    required
                  ></v-text-field>
                  <v-text-field
                    v-model="service_station.address"
                    label="Service Station Address"
                    :rules="[(v) => !!v || 'Required!']"
                    outlined
                    required
                    @change="searchCoords"
                  ></v-text-field>
                  <v-textarea
                    v-model="service_station.additional_info"
                    label="Additional Info"
                    outlined
                  ></v-textarea>
                  <v-text-field
                    v-model="service_station.network.name"
                    label="Network Name"
                    :rules="[(v) => !!v || 'Required!']"
                    outlined
                    required
                  ></v-text-field>
                  <v-textarea
                    v-model="service_station.network.description"
                    label="Network Description"
                    outlined
                  ></v-textarea>
                </template>
                <!-- CREATOR = TRUE, UPDATE_WARNING = TRUE -->
                <div v-if="is_creator == true && update_warning == true">
                  <v-dialog v-model="mainDrawerUpdateDialog" width="500">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        type="submit"
                        class="center"
                        block
                        v-bind="attrs"
                        v-on="on"
                      >
                        SAVE
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title class="text-h5">
                        Save Circle Zone
                      </v-card-title>
                      <v-card-text>
                        Are you sure you want to update the selected zone?
                      </v-card-text>
                      <v-card-text>
                        {{ message }}
                      </v-card-text>
                      <v-divider></v-divider>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          text
                          @click="mainDrawerUpdateDialog = false"
                        >
                          CLOSE
                        </v-btn>
                        <v-btn
                          color="primary"
                          text
                          @click="editFormUpdateAgreeButton()"
                        >
                          AGREE
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>
                <!-- CREATOR = TRUE, UPDATE_WARNING = FALSE -->
                <div v-else-if="is_creator == true && update_warning == false">
                  <v-btn
                    color="primary"
                    class="center"
                    block
                    @click="editFormSaveButton()"
                  >
                    SAVE
                  </v-btn>
                </div>
                <!-- CREATOR = FALSE, UPDATE_WARNING = ANY -->
                <div v-else>
                  <v-btn color="#757575" type="submit" class="center" block>
                    SAVE
                  </v-btn>
                </div>
                <v-btn
                  class="center primary--text"
                  color="#272727"
                  type="submit"
                  block
                  elevation="0"
                  @click="editFormCancelButton()"
                  >CANCEL</v-btn
                >
              </div>
            </v-form>
          </v-container>
        </div>
      </v-container>
    </v-navigation-drawer>

    <!-- EDIT DRAWER -->
    <v-navigation-drawer
      v-model="editDrawer"
      fixed
      persistent
      right
      width="360"
      class="shade"
    >
      <v-container>
        <v-row align="center">
          <v-col align="right" class="mb-2">
            <v-btn icon @click="editDrawer = false">
              <v-icon light>mdi-close-circle </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <h1>Geo-Zones</h1>
        <v-container>
          <div class="text-right">
            <v-dialog v-model="editDrawerDeleteDialog" width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="is_creator == true"
                  class="primary--text"
                  color="#272727"
                  dark
                  v-bind="attrs"
                  elevation="0"
                  v-on="on"
                >
                  DELETE
                </v-btn>
                <v-btn
                  v-else
                  class="white--text"
                  color="#757575"
                  dark
                  elevation="0"
                >
                  DELETE
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="text-h5">
                  Delete Circle Zone
                </v-card-title>
                <div v-if="delete_allowed == true">
                  <v-card-text>
                    Are you sure you want to delete the selected zone?
                  </v-card-text>
                </div>
                <div v-else>
                  <v-card-text> Delete is not allowed. </v-card-text>
                  <v-card-text>
                    {{ message }}
                  </v-card-text>
                </div>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    text
                    @click="editDrawerDeleteDialog = false"
                  >
                    CLOSE
                  </v-btn>
                  <div v-if="delete_allowed == true">
                    <v-btn
                      color="primary"
                      text
                      @click="editDrawerDeleteButton()"
                    >
                      AGREE
                    </v-btn>
                  </div>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>

          <!-- EDIT DRAWER - FORM -->
          <div class="my-5"></div>
          <form @submit.prevent="">
            <v-container v-if="is_creator == false" class="black my-6">
              Editing this Geozone is only allowed for the creator.
            </v-container>
            <div>
              <v-text-field
                v-model="name"
                label="Name"
                outlined
                required
              ></v-text-field>
              <v-text-field
                v-model="description"
                label="Description"
                outlined
                required
              ></v-text-field>
              <v-text-field
                v-model="latitude"
                label="Latitude"
                outlined
                required
              ></v-text-field>
              <v-text-field
                v-model="longitude"
                label="Longitude"
                outlined
                required
              ></v-text-field>
              <v-text-field
                v-model="radius_meters"
                label="Radius in Meters"
                outlined
                required
              ></v-text-field>
              <v-checkbox
                v-model="is_landmark"
                label="Set as Landmark"
              ></v-checkbox>
              <!-- <v-checkbox v-model="ev_zone" label="*EV Zones"></v-checkbox> -->
              <!-- <v-checkbox v-model="quiet_zone" label="Quiet Zones"></v-checkbox> -->
              <!-- <v-checkbox v-model="soc_zone" label="SOC Zone"></v-checkbox> -->
              <!-- <p>
                *If there is insufficient charge available, then the generator
                will run even while in an EV Zone. This is usually the result of
                running in EV Mode for too long and/or insufficient time between
                EV Zones to recharge.
              </p> -->

              <!-- CREATOR = TRUE, UPDATE_WARNING = TRUE -->
              <div v-if="is_creator == true && update_warning == true">
                <v-dialog v-model="editDrawerUpdateDialog" width="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      type="submit"
                      class="center"
                      block
                      v-bind="attrs"
                      v-on="on"
                    >
                      SAVE
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="text-h5">
                      Save Circle Zone
                    </v-card-title>
                    <v-card-text>
                      Are you sure you want to update the selected zone?
                    </v-card-text>
                    <v-card-text>
                      {{ message }}
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="primary"
                        text
                        @click="editDrawerUpdateDialog = false"
                      >
                        CLOSE
                      </v-btn>
                      <v-btn
                        color="primary"
                        text
                        @click="editDrawerUpdateAgreeButton()"
                      >
                        AGREE
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </div>
              <!-- CREATOR = TRUE, UPDATE_WARNING = FALSE -->
              <div v-else-if="is_creator == true && update_warning == false">
                <v-btn
                  color="primary"
                  type="submit"
                  class="center"
                  block
                  @click="editDrawerSaveButton()"
                  >SAVE
                </v-btn>
              </div>
              <!-- CREATOR = FALSE, UPDATE_WARNING = ANY -->
              <div v-else>
                <v-btn color="#757575" type="submit" class="center" block>
                  SAVE
                </v-btn>
              </div>
              <v-btn
                class="center primary--text"
                color="#272727"
                type="submit"
                block
                elevation="0"
                @click="editDrawerCancelButton()"
                >CANCEL</v-btn
              >
            </div>
          </form>
        </v-container>
      </v-container>
    </v-navigation-drawer>
  </v-container>
</template>

<script>
import {
  postGeozone,
  getGeozones,
  updateGeozone,
  deleteGeozone,
} from '@/api/geozones';
import { getAllTrucks } from '@/api/trucks';
import { mapGetters } from 'vuex';
export default {
  name: 'Geozones',
  data() {
    return {
      oid: '',
      showList: true, //true for List, false for edit
      tab: null,
      tabItems: [
        { tab: 'List', content: 'list' },
        { tab: 'Create', content: 'create' },
      ],
      dialog: false,
      mainDrawerDeleteDialog: false,
      mainDrawerUpdateDialog: false,
      editDrawerDeleteDialog: false,
      editDrawerUpdateDialog: false,
      geoZonesList: [],
      geoZonesSelectedList: [],
      geoZonesLabels: [
        {
          text: 'Name',
          align: 'left',
          value: 'name',
          sortable: true,
        },
        {
          text: 'Edit',
          align: 'left',
          value: 'edit',
          sortable: false,
        },
      ],
      id: '',
      companies: [],
      companySelected: null,
      name: '',
      description: null,
      is_private: true,
      is_landmark: false,
      is_service_station: false,
      service_station: {
        name: '',
        address: '',
        services_offered: null,
        additional_info: null,
        network: {
          name: '',
          description: null,
          company_ids: [],
        },
      },
      shape: null,
      radius_meters: '',
      address: null,
      latitude: '',
      longitude: '',
      coordinates_array: null,
      offset_meters: 0,
      ev_zone: false,
      quiet_zone: false,
      soc_zone: false,
      is_creator: false,
      delete_allowed: false,
      update_warning: false,
      message: '',
      hereGroup: null,
      listDrawer: false,
      editDrawer: false,
      hereMap: null,
      hereUI: null,
      platform: null,
      apikey: null,
      valid: false,
      circleToFlagHash: {},
    };
  },
  computed: {
    ...mapGetters({
      HERE_MAP_KEY: 'getMapKey',
    }),
    mapStyle() {
      return `height: ${window.innerHeight - 140}px; width: 100%`;
    },
    mapHeight() {
      if (this.elevationProcessed && this.showElevation) {
        return Math.max(window.innerHeight - 66 - 270, 270);
      } else {
        return window.innerHeight - 66;
      }
    },
    cogStyle() {
      if (this.dashboardActive) {
        return `
          position: relative;
          float: right;
          margin-top: 23px;
          margin-right: 23px;
          margin-bottom: -80px;
          width: 41px;
          height: 41px;
          background-color: #1a1a1a;
          border-radius: 5px;
          z-index: 1;`;
      } else {
        return `
          position: absolute;
          right: 24px;
          top: 24px;
          width: 41px;
          height: 41px;
          background-color: #1a1a1a;
          border-radius: 5px;
          z-index: 1;
        `;
      }
    },
  },
  mounted() {
    this.initializeHereMap();
    this.getList();
    this.getCompaniesList();
  },
  methods: {
    //COG
    async openDrawerCogButton() {
      this.clearPayload();
      this.companySelected = null;
      this.populateTable();
      this.tab = 'list';
      this.showList = true;
      this.listDrawer = true;
    },

    //LIST TAB
    listTabPencilButton(item) {
      this.populatePayload(item);
      this.showList = false;
    },

    //CREATE TAB
    async createTabAddButton() {
      // Checks validation
      if (!this.$refs['form'][0].validate()) {
        return;
      }
      await this.addGeozone();
      await this.getList();
      this.clearPayload();
      this.populateTable();
      this.tab = 'list';
      this.showList = true;
      this.$refs['form'][0].reset(); // Resets form fields after submission
    },

    // EDIT FORM
    //TESTED
    async editFormDeleteButton() {
      await this.removeGeozone();
      await this.getList();
      this.clearPayload();
      this.populateTable();
      this.mainDrawerDeleteDialog = false;
      this.tab = 'list';
      this.showList = true;
    },
    async editFormUpdateAgreeButton() {
      await this.editGeozone();
      await this.getList();
      this.clearPayload();
      this.populateTable();
      this.mainDrawerUpdateDialog = false;
      this.showList = true;
    },
    async editFormSaveButton() {
      await this.editGeozone();
      await this.getList();
      this.clearPayload();
      this.populateTable();
      this.showList = true;
      this.$refs['editForm'].reset(); // Resets form fields after submission
    },
    editFormCancelButton() {
      this.clearPayload();
      this.tab = 'list';
      this.showList = true;
      this.$refs['editForm'].reset(); // Resets form fields after submission
    },

    //EDIT DRAWER
    async editDrawerDeleteButton() {
      await this.removeGeozone();
      await this.getList();
      this.clearPayload();
      this.companySelected = null;
      this.editDrawerDeleteDialog = false;
      this.editDrawer = false;
    },
    async editDrawerUpdateAgreeButton() {
      await this.editGeozone();
      await this.getList();
      this.clearPayload();
      this.companySelected = null;
      this.editDrawerUpdateDialog = false;
      this.editDrawer = false;
    },
    async editDrawerSaveButton() {
      await this.editGeozone();
      await this.getList();
      this.clearPayload();
      this.companySelected = null;
      this.editDrawer = false;
    },
    editDrawerCancelButton() {
      this.clearPayload();
      this.editDrawer = false;
    },
    populateTable() {
      if (this.companySelected == null) {
        this.geoZonesSelectedList = [];
      } else if (this.companySelected == 'All') {
        this.geoZonesSelectedList = this.geoZonesList;
      } else {
        this.geoZonesSelectedList = this.geoZonesList.filter((geozone) => {
          return geozone.company_id == this.companySelected.company_id;
        });
      }
    },
    populatePayload(geozone) {
      this.id = geozone.id;
      this.companySelected = {
        company_id: geozone.company_id,
        company_name: geozone.company_name,
      };
      this.name = geozone.name;
      this.description = geozone.description;
      this.is_private = geozone.is_private;
      this.is_landmark = geozone.is_landmark;
      this.shape = geozone.shape;
      this.radius_meters = geozone.radius_meters;
      this.address = geozone.address;
      this.latitude = geozone.latitude;
      this.longitude = geozone.longitude;
      this.coordinates_array = geozone.coordinates_array;
      this.offset_meters = geozone.offset_meters;
      this.ev_zone = geozone.ev_zone;
      this.quiet_zone = geozone.quiet_zone;
      this.soc_zone = geozone.soc_zone;
      this.is_creator = geozone.is_creator;
      this.delete_allowed = geozone.delete_allowed;
      this.update_warning = geozone.update_warning;
      this.message = geozone.message;
      if (geozone.service_station_id) {
        this.is_service_station = true;
        this.service_station = {
          id: geozone.service_station_id,
          name: geozone.ss_name,
          address: geozone.address,
          services_offered: geozone.services_offered,
          additional_info: geozone.additional_info,
          network: {
            id: geozone.network_id,
            name: geozone.network_name,
            description: geozone.network_description,
            company_ids: [geozone.company_id], // Temp, will place in from Geozone Company ID
          },
        };
      }
    },
    clearPayload() {
      this.id = '';
      // this.companySelected = null;
      this.name = '';
      this.description = null;
      this.is_private = true;
      this.is_landmark = false;
      this.shape = null;
      this.radius_meters = '';
      this.address = null;
      this.latitude = null;
      this.longitude = null;
      this.coordinates_array = null;
      this.offset_meters = 0;
      this.ev_zone = false;
      this.quiet_zone = false;
      this.soc_zone = false;
      this.is_creator = false;
      this.is_service_station = false;
      this.delete_allowed = false;
      this.update_warning = false;
      this.message = '';
      this.service_station = {
        name: '',
        address: '',
        services_offered: null,
        additional_info: null,
        network: {
          name: '',
          description: null,
          company_ids: [],
        },
      };
    },
    async initializeHereMap() {
      this.apikey = this.HERE_MAP_KEY;
      const platform = new window.H.service.Platform({
        apikey: this.apikey,
      });
      this.platform = platform;
      const mapContainer = this.$refs.hereMap;
      const H = window.H;
      // Obtain the default map types from the platform object
      const maptypes = this.platform.createDefaultLayers();
      // Instantiate a map object with zoom level of continental USA
      const map = new H.Map(mapContainer, maptypes.vector.normal.map, {
        zoom: 4.5,
        center: {
          lat: 39.8283,
          lng: -98.5795,
        },
      });
      this.hereMap = map;

      const flagVsCircle = () => {
        // Key Concept: Longitude = vertical, latitudes = horizontal

        // 1) Detect zoom change (bounds)
        let { top, left, right, bottom } = this.getBounds(map);
        let coordOfBounds = this.getCoordOfBounds(top, left, right, bottom);

        // 2) calcuate area of polygon
        let areaPolygon = this.calculatePolygonArea(coordOfBounds);

        this.hereMap.getObjects(true).forEach((circle) => {
          // 3) Filter for circles
          if (circle instanceof H.map.Circle) {
            let centerOfCircle = circle.getCenter();

            let areaCircle = circle.getData().areaCircle;

            // 4) filter geozones within view
            if (
              centerOfCircle.lat < top &&
              centerOfCircle.lat > bottom &&
              centerOfCircle.lng > left &&
              centerOfCircle.lng < right
            ) {
              let circleId = circle.getId();
              let marker = this.circleToFlagHash[circleId];

              //5) compare circle area vs. bound area
              let bool = areaCircle <= (1 / 4000) * areaPolygon;

              //6) show flag or hide flag
              marker.setVisibility(bool);
            }
          }
        });
      };

      map.addEventListener('mapviewchangeend', flagVsCircle);

      addEventListener('resize', () => map.getViewPort().resize());
      new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
      this.hereUI = H.ui.UI.createDefault(map, maptypes);

      map.addEventListener('tap', (evt) => {
        var coord = map.screenToGeo(
          evt.currentPointer.viewportX,
          evt.currentPointer.viewportY
        );

        this.longitude = coord.lng.toFixed(6);
        this.latitude = coord.lat.toFixed(6);
      });
    },
    calculatePolygonArea(coordinates) {
      let area = 0;
      if (coordinates.length > 2) {
        for (let i = 0; i < coordinates.length - 1; i++) {
          let p1 = coordinates[i];
          let p2 = coordinates[i + 1];
          area +=
            this.convertToRadian(p2.longitude - p1.longitude) *
            (2 +
              Math.sin(this.convertToRadian(p1.latitude)) +
              Math.sin(this.convertToRadian(p2.latitude)));
        }
        area = (area * 6378137 * 6378137) / 2;
      }
      return Math.abs(area); //square meters
    },
    convertToRadian(degrees) {
      var pi = Math.PI;
      return degrees * (pi / 180);
    },
    calculateCircleArea(radiusMeters) {
      return Math.PI * radiusMeters ** 2;
    },
    getBounds(map) {
      var bounds = map.getViewModel().getLookAtData().bounds;
      var points = bounds.getBoundingBox();
      var top = points.getTop();
      var left = points.getLeft();
      var right = points.getRight();
      var bottom = points.getBottom();
      return { top, left, right, bottom };
    },
    getCoordOfBounds(top, left, right, bottom) {
      let topLeft = { latitude: top, longitude: left };
      let topRight = { latitude: top, longitude: right };
      let bottomRight = { latitude: bottom, longitude: right };
      let bottomLeft = { latitude: bottom, longitude: left };
      return [topLeft, topRight, bottomRight, bottomLeft, topLeft];
    },
    async getList() {
      //remove all objects
      if (this.hereMap) {
        this.hereUI
          .getBubbles()
          .forEach((bub) => this.hereUI.removeBubble(bub));
        this.hereMap.removeObjects(this.hereMap.getObjects());
      }

      try {
        this.geoZonesList = await getGeozones();
        this.geoZonesList = this.geoZonesList.data;
        //set company dropdown
        //iterate through geozones list
        //run addObjectToMap on each
        for (let geozone of this.geoZonesList) {
          if (
            (this.companySelected &&
              this.companySelected?.company_id == geozone.company_id) ||
            this.companySelected == 'All' ||
            this.companySelected == null
          ) {
            this.addObjectToMap(
              { lat: geozone.latitude, lng: geozone.longitude },
              geozone.radius_meters,
              geozone
            );
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getCompaniesList() {
      let companyNumSet = new Set();
      let companiesAccess = [];
      const { data } = await getAllTrucks();

      data.forEach((truck) => {
        if (!companyNumSet.has(truck.company_id)) {
          companiesAccess.push({
            company_id: truck.company_id,
            company_name: truck.company,
          });
          companyNumSet.add(truck.company_id);
        }
      });

      this.companies = ['All', ...companiesAccess];
    },
    extractPayload(action = 'add') {
      /* Temp ---- All service stations will be Hyliion or based on what Company the user selects
       * In the future the user will be able to select multiple or all companies based on permissions
       */
      if (action === 'add') {
        this.service_station.network.company_ids = [
          this.companySelected.company_id,
        ];
      } else {
        if (this.is_service_station) {
          this.service_station.network.company_ids = [
            this.companySelected.company_id,
          ];
        }
      }
      return {
        company_id: this.companySelected.company_id,
        name: this.name,
        description: this.description,
        is_private: this.is_private,
        is_landmark: this.is_landmark,
        is_service_station: this.is_service_station,
        shape: this.shape,
        radius_meters: this.radius_meters,
        address: this.address,
        latitude: this.latitude,
        longitude: this.longitude,
        coordinates_array: this.coordinates_array,
        offset_meters: this.offset_meters,
        ev_zone: this.ev_zone,
        quiet_zone: this.quiet_zone,
        soc_zone: this.soc_zone,
        service_station: this.is_service_station ? this.service_station : null,
      };
    },
    handleClickRow(value) {
      let coordinates = { lat: value.latitude, lng: value.longitude };
      this.goToCoordinates(this.hereMap, coordinates, value.radius_meters);
    },
    goToCoordinates(map, coordinates, radius) {
      map.setCenter(coordinates);
      if (radius >= 10000) map.setZoom(10);
      if (radius >= 100000) map.setZoom(8);
      if (radius >= 1000000) map.setZoom(5);
    },
    async addGeozone() {
      let payload = this.extractPayload();
      await postGeozone(payload);
    },
    async editGeozone() {
      if (!this.$refs['editForm'].validate()) {
        return;
      }
      let payload = this.extractPayload('edit');
      payload.id = this.id;
      await updateGeozone(this.id, payload);
    },
    async removeGeozone() {
      await deleteGeozone(
        this.id,
        this.service_station['id'] ? this.service_station['id'] : ''
      );
    },
    addObjectToMap(coordinates, radius_meters, geozone) {
      const vm = this;
      const H = window.H;
      vm.hereGroup = new H.map.Group();

      let circle = new H.map.Circle(
        // The central point of the circle
        coordinates,
        // The radius of the circle in meters
        radius_meters,
        {
          style: {
            background: '#82B1FF',
            opacity: 0.3,
            border: '2px solid #2196F3',
          },
        }
      );

      let marker = new H.map.Marker(coordinates);

      const infoWidth = 200;

      const infoContent = `
        <div style="width: ${infoWidth}px; color: #ffffff; font-weight: 100">
          <span style="color: #757575"> Name: </span> ${geozone.name}<br>
          <span style="color: #757575"> Description: </span> ${geozone.description}<br>
          <span style="color: #757575"> Company Name: </span> ${geozone.company_name}<br>
          <span style="color: #757575"> Radius in Meters: </span> ${geozone.radius_meters}<br>
        </div>`;

      const editLinkContent =
        geozone.is_creator == true
          ? `
            <i class="mdi mdi-link"
              style="filter: invert(49%) sepia(72%) saturate(368%) hue-rotate(72deg) brightness(103%) contrast(93%);
              font-size: 23px; position: relative; top: 4px;">
            </i>
              &nbspEdit
            `
          : `
            <i class="mdi mdi-link"
              style="color: grey; filter: invert(49%) sepia(72%) saturate(368%) hue-rotate(72deg) brightness(103%) contrast(93%);
              font-size: 23px; position: relative; top: 4px;">
            </i>
              &nbspEdit Not Allowed
            `;

      const data = {};
      data.infoContent = infoContent;
      data.editLinkContent = editLinkContent;
      data.geozone = geozone;

      let areaCircle = this.calculateCircleArea(radius_meters);
      data.areaCircle = areaCircle;

      let { top, left, right, bottom } = this.getBounds(this.hereMap);
      let coordOfBounds = this.getCoordOfBounds(top, left, right, bottom);
      let areaPolygon = this.calculatePolygonArea(coordOfBounds);

      //adding meta data to the circle
      circle.setData(data);
      marker.setData(data);

      let bool = areaCircle <= (1 / 4000) * areaPolygon;
      marker.setVisibility(bool);

      let circleId = circle.getId();
      this.circleToFlagHash[circleId] = marker;
      this.hereGroup.addObject(circle);
      this.hereGroup.addObject(marker);
      this.hereMap.addObject(this.hereGroup);
      vm.setupClickableObjects();
    },
    setupClickableObjects() {
      const vm = this;
      const H = window.H;
      this.hereGroup.addEventListener(
        'tap',
        (event) => {
          //remove all open bubbles
          this.hereUI
            .getBubbles()
            .forEach((bub) => this.hereUI.removeBubble(bub));

          const info = event.target.getData().infoContent;
          const editLink = event.target.getData().editLinkContent;
          const geozone = event.target.getData().geozone;

          let center =
            event.target instanceof H.map.Circle
              ? event.target.getCenter()
              : event.target.getGeometry();

          let bubble = new window.H.ui.InfoBubble(center, {
            content: `
            ${info}
            <hr style="border: 1px solid #888888; margin-top: 7px; margin-bottom: 7px;">
              <div style="width: 150px; margin-left: -23px; margin-bottom: -10px; text-align: left;">
                <ul style="list-style: none;">
                  <li class="health" style="width: 200px">
                    <a>
                      ${editLink}
                    </a>
                  </li>
                </ul>
              </div>`,
          });

          // show info bubble
          this.hereUI.addBubble(bubble);
          //below bubble method only works after bubble is added to UI
          const bubbleHTML = bubble.getContentElement();

          bubbleHTML
            .getElementsByClassName('health')[0]
            .addEventListener('click', async () => {
              this.populatePayload(geozone);
              vm.editDrawer = true;
              vm.shape = 'circle';
            });
        },
        false
      );
    },
    searchCoords(input) {
      let geocoder = this.platform.getSearchService(),
        geocodingParameters = {
          q: input,
        };
      geocoder.geocode(geocodingParameters, this.onSuccess, this.onError);
    },
    onSuccess(result) {
      var locations = result.items;
      if (locations[0]) {
        this.latitude = locations[0].position.lat;
        this.longitude = locations[0].position.lng;
      }
    },
    onError(error) {
      console.log(error);
    },
  },
};
</script>
<style scoped>
#map {
  width: 100% !important;
  height: 100%;
}
</style>
