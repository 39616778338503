var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.updateOffset.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "v-card",
        {
          staticClass: "offsetCard",
          attrs: { id: "offset", "max-width": "500" },
        },
        [
          _c("v-card-title", [_vm._v("Million Miles Offset")]),
          _c("v-card-subtitle", [
            _vm._v("Value that gets added to odomoterTotal"),
          ]),
          _c(
            "v-card-text",
            [
              _vm._v("Current Offset: "),
              _c("span", { staticClass: "offset" }, [
                _vm._v(_vm._s(_vm.realOffset)),
              ]),
              _c("v-spacer"),
              _c(
                "a",
                {
                  attrs: {
                    href: "https://hyliion.atlassian.net/wiki/spaces/HLS/pages/3372089364/ERX+Miles+Offset",
                    target: "_blank",
                  },
                },
                [_vm._v("ERX Mile Offset Confluence Log Link")]
              ),
              _c("br"),
              _c("br"),
              _vm._v(" Set new offset: "),
              _c("v-text-field", {
                staticStyle: { "margin-bottom": "-10px" },
                attrs: { outlined: "" },
                on: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.updateOffset()
                  },
                },
                model: {
                  value: _vm.offsetModel,
                  callback: function ($$v) {
                    _vm.offsetModel = $$v
                  },
                  expression: "offsetModel",
                },
              }),
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticStyle: { display: "block", "margin-left": "auto" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { dark: "", color: "primary", type: "submit" },
                  on: { click: _vm.updateOffset },
                },
                [_vm._v("Update")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }