import axios from 'axios';

const BASE_URL = process.env.VUE_APP_API_PATH_BASE;

async function getAlertSubscriptions() {
  return await axios.get(BASE_URL + 'alerts/conditions_subs');
}

async function subscribeToAlert(id) {
  return await axios.post(BASE_URL + 'alerts/subscribe', { condition_id: id });
}

async function unsubscribeToAlert(id) {
  return await axios.patch(BASE_URL + 'alerts/unsubscribe', {
    condition_id: id,
  });
}

async function addAlert(condition) {
  condition.trigger_source = condition.trigger_source.abbr;
  return await axios.post(BASE_URL + 'alerts/condition', condition);
}

async function getAlerts(endTimeMS, page, limit, orderBy, orderDir) {
  let opts = { page, limit, orderBy, orderDir };
  let queryString = Object.keys(opts)
    .map((key) => (opts[key] ? key + '=' + opts[key] : undefined))
    .filter((e) => (e ? true : false))
    .join('&');
  let res = await axios.get(BASE_URL + `alerts/history?${queryString}`);
  return res.data;
}

async function getAlertsCount(endTimeMS) {
  let res = await axios.get(BASE_URL + `alerts/history_count/${endTimeMS}`);
  return res.data.count;
}

async function getAlertsOperations() {
  let res = await axios.get(BASE_URL + `alerts/operations`);
  return res.data;
}

async function getGeozoneSubs() {
  let res = await axios.get(BASE_URL + `alerts/geozone_subs`);
  return res.data;
}

async function addGeozoneAlert(id, action) {
  let res = await axios.post(
    BASE_URL + `alerts/sub_geozone/?geozoneId=${id}&action=${action}`
  );
  return res.data;
}

export {
  getAlertSubscriptions,
  subscribeToAlert,
  unsubscribeToAlert,
  addAlert,
  getAlerts,
  getAlertsCount,
  getAlertsOperations,
  getGeozoneSubs,
  addGeozoneAlert,
};
