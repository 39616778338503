<template>
  <v-container fluid>
    <v-snackbar v-model="snack" :color="snackColor">{{
      snackMessage
    }}</v-snackbar>
    <v-row justify="space-between" style="margin: 10px">
      <h1 style="font-weight: 400">Feedback</h1>
      <v-btn
        color="primary"
        class="mr-1"
        style="color: black; height: 48px"
        @click="exportCSV"
      >
        <download-csv id="csv" name="hub_feedback.csv" :data="feedback">
          <v-icon id="csv2">mdi-download</v-icon>
          Download CSV
        </download-csv>
      </v-btn>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="feedback"
      :sort-by="['created_at']"
      :custom-sort="customSort"
      style="cursor: pointer"
      @click:row="rowClicked"
    ></v-data-table>
    <v-dialog v-model="dialog" :max-width="1000">
      <v-card>
        <v-card-title>
          {{ cardTitle }}
        </v-card-title>
        <v-card-text class="feedbackText">
          <v-icon class="iconOffset">mdi-format-quote-open</v-icon>
          {{ cardFeedback }}
          <v-icon class="iconOffset">mdi-format-quote-close</v-icon>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" dark @click="deleteRow">
            <v-icon style="margin-right: 5px">mdi-trash-can</v-icon> Delete
          </v-btn>
          <v-btn dark @click="dialog = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { getFeedback, deleteFeedback } from '@/api/feedback';
import dateFormat from 'dateformat';

export default {
  name: 'FeedbackView',
  data() {
    return {
      snack: false,
      snackMessage: null,
      snackColor: 'success',
      dialog: false,
      clickedRow: null,
      feedback: [],
      headers: [
        { text: 'Timestamp', value: 'created_at' },
        { text: 'User Name', value: 'username' },
        { text: 'User ID', value: 'userid' },
        { text: 'Email', value: 'email' },
        { text: 'Screen', value: 'screen' },
        { text: 'Category', value: 'category' },
        { text: 'Experience', value: 'experience' },
        { text: 'Description', value: 'description' },
      ],
      experienceLookup: {
        1: 'Hate',
        2: 'Dislike',
        3: 'Neutral',
        4: 'Like',
        5: 'Love',
      },
    };
  },
  computed: {
    cardTitle() {
      return this.clickedRow && this.clickedRow.username + "'s Feedback";
    },
    cardFeedback() {
      return this.clickedRow && this.clickedRow.description;
    },
  },
  mounted() {
    this.populateTable();
  },
  methods: {
    async populateTable() {
      const vm = this;
      const res = await getFeedback();
      res.data.forEach((row) => {
        row.created_at = dateFormat(row.created_at, 'm/d/yy h:MM TT Z');
        // below is a temporary fix; we'll want to rework the table
        // and API to take an 'experience' col of type string in the future
        row.experience = vm.experienceLookup[row.importance];
        delete row.importance;
      });
      this.feedback = res.data;
    },
    rowClicked(row) {
      this.clickedRow = row;
      this.dialog = true;
    },
    deleteRow() {
      this.dialog = false;
      const vm = this;
      deleteFeedback(this.clickedRow.id)
        .then(() => {
          vm.feedback = vm.feedback.filter((row) => row.id != vm.clickedRow.id);
          vm.snack = true;
          vm.snackMessage = 'Feedback successfully deleted!';
          vm.snackColor = 'success';
        })
        .catch((e) => {
          console.error(e);
          vm.snack = true;
          vm.snackMessage = 'There was a problem deleting this feedback.';
          vm.snackColor = 'error';
        });
    },
    customSort: function (items, index, isDesc) {
      items.sort((a, b) => {
        if (index[0] == 'created_at') {
          if (!isDesc[0]) {
            return new Date(b[index]) - new Date(a[index]);
          } else {
            return new Date(a[index]) - new Date(b[index]);
          }
        } else {
          if (typeof a[index] !== 'undefined') {
            if (!isDesc[0]) {
              return a[index]
                .toLowerCase()
                .localeCompare(b[index].toLowerCase());
            } else {
              return b[index]
                .toLowerCase()
                .localeCompare(a[index].toLowerCase());
            }
          }
        }
      });
      return items;
    },
    exportCSV(event) {
      if (event.target.id != 'csv' && event.target.id != 'csv2') {
        document.getElementById('csv').click();
      }
    },
  },
};
</script>

<style scoped>
.iconOffset {
  margin-top: -20px;
}
.feedbackText {
  margin-top: 30px;
}
</style>
