import Vue from 'vue';
import Vuex from 'vuex';
import { getCompanies } from '@/api/external/company';
import { getHereMapKey } from '@/api/external/health';
import { getHealthTrucks } from '@/api/trucks';

import _ from 'lodash';
Vue.use(Vuex);

const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  state: {
    snack: {
      model: false,
      type: null,
      message: null,
    },
    selectedConfiguration: {
      trucks: [],
      status: '',
      trucks_assigned_count: 0,
    },
    selectedTruck: null,
    user: {
      user_name: '',
      user_email: '',
      user_oid: '',
      authenticated: false,
      roles: [],
      preferences: {
        regionPref: 'us/imperial',
        // fuelEconomyPref: 'mpge',
        datePref: 'mm/dd/yyyy',
        timePref: '12 hour (e.g. 1:00p.m.)',
        timeZonePref: {
          utc: '(UTC-06:00) Central Time(US & Canada)',
          canonical: 'America/Chicago',
        },
      },
    },
    // data_timer: '', // TODO: add store timer to reset after a certain time
    spnfmi_states: [],
    ex_dtcs: [],
    selectedUser: {},
    isEditSignal: null,
    signal: {
      src_id: null,
      column_name: '',
      signal: '',
      comment: '',
      adx_db_table: { state: 'Sample B Data', abbr: 'sampleBData' },
      signal_type_id: { state: 'state', abbr: 9 },
      units_id: null,
      restricted: true,
      max: null,
      min: null,
      publish: false,
      store: false,
      truck_type: 'ERX',
      choices: [],
    },
    truckType: { state: 'ERX', abbr: 'ERX' },
    detailsID: null,
    healthTruckType: null,
    fromTruckDetails: false,
    journeyData: [],
    prevRoute: null,
    healthTrucks: null,
    HERE_MAP_KEY: window.localStorage.getItem('hereMapKey') || null,
    /********** External ***********/
    truckAccess: [],
    // Retrieves company data from local storage
    companyAccess: JSON.parse(
      window.localStorage.getItem('companyAccess') || '[]'
    ),
    selectedLiveFleet: window.localStorage.getItem('selectedFleet') || null,
    companyPreferences: null,
    currentScreen: {
      tab: null, // health vs performance
      mapShowing: null, // boolean
      healthTab: null, // faulted, inshop
      scatterMetric: null, // mpge, idle, cruise
      scatterRange: null, // month, week
      journeyDetails: null, // boolean
      truckDetails: null,
    },
    metric: 'mpge',
    refreshDashboard: 0,
    geozones: [],
  },
  getters: {
    getConfiguration: (state) => state.selectedConfiguration,
    getTruck: (state) => state.selectedTruck,
    getUserName: (state) => state.user.user_name,
    getUserEmail: (state) => state.user.user_email,
    getUserOID: (state) => state.user.user_oid,
    getUserRoles: (state) => state.user.roles,
    getUserPreferences: (state) => state.user.preferences,
    getSelectedUser: (state) => state.selectedUser,
    getSignal: (state) => state.signal,
    getIsEditSignal: (state) => state.isEditSignal,
    getDataDictionaryDb: (state) => state.signal.adx_db_table,
    getDetailsID: (state) => state.detailsID,
    getTruckType: (state) => state.truckType,
    getHealthTruckType: (state) => state.healthTruckType,
    getFromTruckDetails: (state) => state.fromTruckDetails,
    getSpnfmiStates: (state) => state.spnfmi_states,
    getEXDTCs: (state) => state.ex_dtcs,
    getSnack: (state) => state.snack,
    getPrevRoute: (state) => state.prevRoute,
    getHealthTrucks: (state) => state.healthTrucks,
    /********** External ***********/
    getCompanies: (state) => state.companyAccess,
    getAllTrucks: (state) => state.truckAccess,
    getSelectedLiveFleet: (state) =>
      state.selectedLiveFleet
        ? JSON.parse(window.localStorage.getItem('selectedFleet'))
        : null,
    getCompanyPreferences: (state) => state.companyPreferences,
    getMapKey: (state) => state.HERE_MAP_KEY,
    getMetric: (state) => state.metric,
    getCurrentScreen: (state) => state.currentScreen,
    getJourneyData: (state) => state.journeyData,
    getGeozones: (state) => state.geozones,
  },
  mutations: {
    selectConfiguration: (state, payload) => {
      state.selectedConfiguration = payload;
    },
    updateDataDictionaryDb: (state, payload) => {
      state.signal.adx_db_table = payload;
    },
    updateTruckType: (state, payload) => {
      state.truckType = payload;
    },
    updateSignal: (state, payload) => {
      state.signal = payload;
    },
    updateIsEditSignal: (state, payload) => {
      state.isEditSignal = payload;
    },
    selectUser: (state, payload) => {
      state.selectedUser = payload;
    },
    assignHealthTruck: (state, payload) => {
      // Stores selected truck from Internal Health Page to use in Health Child Components
      state.selectedTruck = payload;
    },
    updateConfigurationStatus: (state, payload) => {
      state.selectedConfiguration.status = payload;
    },
    updateConfigurationsTrucks: (state, payload) => {
      state.selectedConfiguration.trucks = [
        ...(state.selectedConfiguration.trucks || []),
        ...payload,
      ];
      state.selectedConfiguration.trucks_assigned_count =
        state.selectedConfiguration.trucks_assigned_count || 0 + payload.length;
    },
    updateUserRoles: (state, role) => {
      state.user.roles.push(role);
    },
    updateUserRolesFromMSAL: (state, roles) => {
      state.user.roles = roles;
    },
    updateUserPreferences: (state, preferences) => {
      state.user.preferences = preferences;
    },
    authenticateUser: (state, userInfo) => {
      state.user.authenticated = true;
      state.user.user_name = userInfo.user;
      state.user.user_email = userInfo.email;
      state.user.user_oid = userInfo.oid;
    },
    updateDetailsID: (state, id) => {
      state.detailsID = id;
    },
    updateHealthTruckType: (state, payload) => {
      state.healthTruckType = payload;
    },
    updateFromTruckDetails: (state, payload) => {
      state.fromTruckDetails = payload;
    },
    updateSpnfmiStates: (state, payload) => {
      state.spnfmi_states = payload;
    },
    updateEXDTCs: (state, payload) => {
      state.ex_dtcs = payload;
    },
    updateSnack: (state, snack) => {
      state.snack.model = snack.model;
      // only update defined values; this causes smoother transitions on close
      if (snack.type != undefined) state.snack.type = snack.type;
      if (snack.message != undefined) state.snack.message = snack.message;
    },
    updateHealthTrucks: (state, payload) => {
      state.healthTrucks = payload;
    },
    /********** External ***********/
    assignCompanies: (state, payload) => {
      // If selected live fleet, Hyliion users only
      if (state.selectedLiveFleet) {
        let parsed = JSON.parse(state.selectedLiveFleet);
        //Find live fleet in payload
        let fleet = payload.find((c) => c.id === parsed.id);
        // Check if payload is different than the current live fleet view
        if (
          !_.isEqual(parsed.external_permissions, fleet.external_permissions)
        ) {
          // TODO: Clean up duplicate logic on checks
          // Lets user know about page refresh from permission change
          store.dispatch('updateSnack', {
            type: 'warning',
            message: 'Update permissions detected, will reload page shortly...',
          });
          // Stores updated live fleet in local storage
          window.localStorage.setItem('selectedFleet', JSON.stringify(fleet));
          setTimeout(() => {
            location.reload();
          }, 10000);
        }
      }
      // If External Customer User
      if (state.companyAccess.length === 1) {
        // Check if payload is different than the current user company settings
        if (!_.isEqual(state.companyAccess[0], payload[0])) {
          store.dispatch('updateSnack', {
            type: 'warning',
            message: 'Update permissions detected, will reload page shortly...',
          });
          // Refreshes when there is a state change
          setTimeout(() => {
            location.reload();
          }, 10000);
        }
      }
      // Stores company data in local storage
      window.localStorage.setItem('companyAccess', JSON.stringify(payload));
      state.companyAccess = payload;
    },
    assignTrucks: (state, payload) => {
      state.truckAccess = payload;
    },
    setMapKey: (state, payload) => {
      // Stores map key data in local storage
      window.localStorage.setItem('hereMapKey', payload);
      state.HERE_MAP_KEY = payload;
    },
    assignSelectedLiveFleet: (state, payload) => {
      // Stores selected fleet in local storage
      window.localStorage.setItem('selectedFleet', JSON.stringify(payload));
      state.selectedLiveFleet = payload;
    },
    assignCompanyPreferences: (state, payload) => {
      state.companyPreferences = payload;
    },
    updateTruckName: (state, payload) => {
      let index = state.truckAccess.findIndex(
        (ta) =>
          ta.id === payload.truck_id && ta.company_id === payload.company_id
      );
      if (index !== -1) {
        state.truckAccess[index].number = payload.truck_name;
      }
      state.refreshDashboard += 1; // Refreshes the dashboard to allow the health table to reset and update the name change
    },
    // only update defined values
    updateCurrentScreen: (state, payload) => {
      if (payload.tab != undefined) state.currentScreen.tab = payload.tab;
      if (payload.mapShowing != undefined)
        state.currentScreen.mapShowing = payload.mapShowing;
      if (payload.healthTab != undefined)
        state.currentScreen.healthTab = payload.healthTab;
      if (payload.scatterMetric != undefined)
        state.currentScreen.scatterMetric = payload.scatterMetric;
      if (payload.scatterRange != undefined)
        state.currentScreen.scatterRange = payload.scatterRange;
      if (payload.journeyDetails != undefined)
        state.currentScreen.journeyDetails = payload.journeyDetails;
      if (payload.truckDetails != undefined)
        state.currentScreen.truckDetails = payload.truckDetails;
    },
    updateJourneyData: (state, payload) => {
      state.journeyData = payload;
    },
    updatePrevRoute: (state, payload) => {
      state.prevRoute = payload;
    },
    updateMetric: (state, payload) => {
      state.metric = payload;
    },
    assignGeozones: (state, payload) => {
      state.geozones = payload;
    },
  },
  actions: {
    asyncAssignTruck: ({ commit }, payload) => {
      commit('assignTruck', payload);
    },
    asyncUpdateConfigurationStatus: ({ commit }, payload) => {
      commit('updateConfigurationStatus', payload);
    },
    asyncUpdateConfigurationsTrucks: ({ commit }, payload) => {
      commit('updateConfigurationsTrucks', payload);
    },
    asyncUpdateUserPreferences: ({ commit }, payload) => {
      commit('updateUserPreferences', payload);
    },
    asyncUpdateUserRolesFromMSAL: async ({ commit }) => {
      let roles;
      try {
        let auth = await Vue.prototype.$msal.authenticate();
        let accounts = auth.accounts;
        roles = accounts[0] ? accounts[0]?.idTokenClaims?.roles : [];
      } catch (e) {
        return e;
      }
      commit('updateUserRolesFromMSAL', await roles);
    },
    asyncUpdateSpnfmiStates: ({ commit }, payload) => {
      commit('updateSpnfmiStates', payload);
    },
    asyncUpdateEXDTCs: ({ commit }, payload) => {
      commit('updateEXDTCs', payload);
    },
    updateSnack({ commit }, snack) {
      // default model to true
      if (snack.model === undefined) snack.model = true;
      commit('updateSnack', snack);
      // auto close snack after 5 seconds when invoked
      if (snack.model === true) {
        setTimeout(() => {
          commit('updateSnack', {
            model: false,
          });
        }, 5000);
      }
    },
    async fetchHealthTrucks({ commit }) {
      let res;
      try {
        const { data } = await getHealthTrucks();
        res = data;
      } catch (e) {
        console.error('Store error, could not retrieve health trucks', e);
      }
      commit('updateHealthTrucks', await res);
    },
    /********** External ***********/
    asyncRetrieveMapKey: async ({ commit }) => {
      let mapKey;
      try {
        const { data } = await getHereMapKey();
        mapKey = data;
      } catch (e) {
        console.error('Store error, could not retrieve map key');
      }
      commit('setMapKey', await mapKey);
    },
    asyncRetrieveCompanies: async ({ commit, state }, payload) => {
      // Optional payload sent for calls that require a company api check
      if (state.companyAccess.length && !payload) {
        let hyliion = state.companyAccess.find((c) => c.id === 4);
        if (state.companyAccess.length > 1 && hyliion) {
          if (state.selectedLiveFleet) {
            checkExternalPermissions(
              commit,
              JSON.parse(state.selectedLiveFleet)
            );
          } else {
            // Hyliion User
            checkExternalPermissions(commit, hyliion);
          }
        } else {
          // External User
          checkExternalPermissions(commit, state.companyAccess[0]);
        }
      } else {
        let companyData = [];
        try {
          const { data } = await getCompanies(true);
          companyData = data;
        } catch (e) {
          console.error('Store error, could not retrieve company data');
        }
        commit('assignCompanies', await companyData);
      }
    },
  },
});

const checkExternalPermissions = (commit, company) => {
  if (!_.isEmpty(company.external_permissions)) {
    const permissions = company.external_permissions;
    if (permissions.health === true) {
      commit('updateUserRoles', 'external_permissions.health');
    }
    if (permissions.performance === true) {
      commit('updateUserRoles', 'external_permissions.performance');
    }
  }
};

export default store;
