import axios from 'axios';

const BASE_URL = process.env.VUE_APP_API_PATH_BASE;

async function getSourceDictionary(table, signals = null) {
  let signalStr = '';
  if (signals) {
    signalStr = '&signals=';
    signals.forEach((s) => (signalStr += `${s},`));
  }

  return await axios.get(
    BASE_URL + `component_list/?page=1&size=500&table=${table}${signalStr}`
  );
}

async function getSignals() {
  return await axios.get(BASE_URL + 'component_list/id_name/');
}

async function getSignalEnums(id) {
  const signal_id = id;
  return await axios.get(
    BASE_URL + `component_list/signal_enums/${signal_id ? signal_id : ''}`
  );
}

async function getSignalDictionary() {
  return await axios.get(BASE_URL + 'data_fields/');
}

async function getDropdown() {
  return await axios.get(BASE_URL + 'data_fields/drop_down');
}

async function addSource(source) {
  return await axios.post(BASE_URL + 'data_fields/', source);
}

async function addSignal(signal) {
  return await axios.post(BASE_URL + 'component_list/', signal);
}
async function putSignal(signal, id) {
  return await axios.put(BASE_URL + `component_list/` + id, signal);
}

async function deleteSignal(id) {
  return await axios.delete(BASE_URL + `component_list/` + id);
}

async function upsertDTCs(dtc_codes) {
  return await axios.put(BASE_URL + `component_list/dtc_codes`, dtc_codes);
}

async function getDTCs() {
  return await axios.get(BASE_URL + `component_list/dtc_codes`);
}

async function editDtc(dtc) {
  return await axios.put(BASE_URL + `component_list/dtc_code`, dtc);
}

async function deleteDtc(id) {
  return await axios.delete(BASE_URL + `component_list/dtc_code/` + id);
}

async function getCumminsTestingData() {
  return await axios.get(BASE_URL + `component_list/cummins_testing`);
}

export {
  getSourceDictionary,
  getSignalDictionary,
  addSignal,
  putSignal,
  deleteSignal,
  addSource,
  getDropdown,
  getSignals,
  getSignalEnums,
  upsertDTCs,
  getDTCs,
  editDtc,
  deleteDtc,
  getCumminsTestingData,
};
