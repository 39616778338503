<template>
  <div id="user-settings" class="mt-8 ml-8 mr-8">
    <loader v-if="loading" />
    <v-container fluid class="pr-0">
      <v-row class="mt-0">
        <v-col class="pl-0"><h1>Settings</h1></v-col>
        <v-col><v-spacer /></v-col>
      </v-row>
      <v-row class="mt-3 mb-5 secondary">
        <v-col
          xs="12"
          sm="6"
          md="6"
          lg="9"
          xl="9"
          style="background-color: #272727"
        >
          Unit Format<br />
          <span class="secondary-text">Select regional preference</span>
        </v-col>
        <v-col
          xs="12"
          sm="6"
          md="6"
          lg="3"
          xl="3"
          align="right"
          style="background-color: #272727"
        >
          <v-tabs
            v-model="regionTab"
            fixed-tabs
            right
            background-color="secondary"
            style="border-radius: 5px"
          >
            <v-tabs-slider color="black"></v-tabs-slider>
            <v-tab v-for="tab of regionTabs" :key="tab.id">{{
              tab.name
            }}</v-tab>
          </v-tabs>
        </v-col>
      </v-row>
      <!-- <v-row class="mt-0 mb-5 secondary">
        <v-col
          xs="12"
          sm="6"
          md="6"
          lg="8"
          xl="8"
          style="background-color: #272727"
        >
          Fuel Economy<br />
          <span class="secondary-text"
            >Select global fuel economy preference</span
          >
        </v-col>
        <v-col
          xs="12"
          sm="6"
          md="6"
          lg="4"
          xl="4"
          align="right"
          style="background-color: #272727"
        >
          <v-tabs
            v-model="fuelEconomyTab"
            fixed-tabs
            right
            background-color="secondary"
            style="border-radius: 5px"
          >
            <v-tabs-slider color="black"></v-tabs-slider>
            <v-tab v-for="tab of fuelEconomyTabs" :key="tab.id">{{
              tab.name
            }}</v-tab>
          </v-tabs>
        </v-col>
      </v-row> -->
      <v-row class="mt-0 mb-5 secondary">
        <v-col
          xs="12"
          sm="6"
          md="6"
          lg="10"
          xl="10"
          style="background-color: #272727"
        >
          Date Format<br />
          <span class="secondary-text"
            >Select global date format preference</span
          >
        </v-col>
        <v-col
          xs="12"
          sm="6"
          md="6"
          lg="2"
          xl="2"
          align="right"
          style="background-color: #272727"
        >
          <v-select
            v-model="datePref"
            style="height: 55px"
            :items="dateItems"
            outlined
          ></v-select>
        </v-col>
      </v-row>
      <v-row class="mt-0 mb-5 secondary">
        <v-col
          xs="12"
          sm="6"
          md="6"
          lg="9"
          xl="9"
          style="background-color: #272727"
        >
          Time Format<br />
          <span class="secondary-text"
            >Select global time format preference</span
          >
        </v-col>
        <v-col
          xs="12"
          sm="6"
          md="6"
          lg="3"
          xl="3"
          align="right"
          style="background-color: #272727"
        >
          <v-select
            v-model="timePref"
            style="height: 55px"
            :items="timeItems"
            outlined
          ></v-select>
        </v-col>
      </v-row>
      <v-row class="mt-0 mb-5 secondary">
        <v-col
          xs="12"
          sm="6"
          md="6"
          lg="8"
          xl="8"
          style="background-color: #272727"
        >
          Time Zone<br />
          <span class="secondary-text">Select global time zone preference</span>
        </v-col>
        <v-col
          xs="12"
          sm="6"
          md="6"
          lg="4"
          xl="4"
          align="right"
          style="background-color: #272727"
        >
          <v-select
            v-model="timeZonePref"
            style="height: 55px"
            :items="timeZoneItems"
            item-text="utc"
            return-object
            outlined
          ></v-select>
        </v-col>
      </v-row>
      <v-row class="mt-0 secondary">
        <v-col class="pl-0 pt-0" cols="12">
          <v-btn class="pl-2" plain color="primary" @click="routeBack()"
            >Go Back</v-btn
          >
          <v-btn
            color="primary"
            style="color: black"
            :disabled="loading"
            @click="updateUserPrefs"
            >Update</v-btn
          >
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';

import loader from '@/utilities/loader';

import { putPref } from '@/api/preferences';
export default {
  name: 'UserSettings',
  components: { loader },
  data() {
    return {
      loading: false,
      showAlert: false,
      alertType: '',
      alertMessage: '',
      oid: '',
      regionTab: 0,
      regionTabs: [
        { id: 1, name: 'metric' },
        { id: 2, name: 'us/imperial' },
      ],
      // fuelEconomyTab: 0,
      // fuelEconomyTabs: [
      //   { id: 1, name: 'mpge' },
      //   { id: 2, name: 'km/l-e' },
      //   { id: 3, name: 'l-e/100' },
      // ],
      dateItems: ['mm/dd/yyyy', 'dd/mm/yy', 'yy/mm/dd', 'mmmm d, yy'],
      datePref: 'mm/dd/yyyy',
      timeItems: ['12 hour (e.g. 1:00p.m.)', '24 hour (e.g. 13:00)'],
      timePref: '12 hour (e.g. 1:00p.m.)',
      timeZoneItems: [
        {
          utc: '(UTC) Dublin, Edinburgh, Lisbon, London',
          canonical: 'Europe/London',
        },
        {
          utc: '(UTC-04:00) Atlantic Time (Canada)',
          canonical: 'America/Moncton',
        },
        {
          utc: '(UTC-05:00) Eastern Time(US & Canada)',
          canonical: 'America/New_York',
        },
        {
          utc: '(UTC-06:00) Central Time(US & Canada)',
          canonical: 'America/Chicago',
        },
        {
          utc: '(UTC-07:00) Mountain Time(US & Canada)',
          canonical: 'America/Denver',
        },
        {
          utc: '(UTC-08:00) Pacific Time(US & Canada)',
          canonical: 'America/Los_Angeles',
        },
        {
          utc: '(UTC-09:00) Alaska',
          canonical: 'America/Anchorage',
        },
      ],
      timeZonePref: {
        utc: '(UTC-06:00) Central Time(US & Canada)',
        canonical: 'America/Chicago',
      },
    };
  },
  computed: {
    ...mapGetters({
      userPreferences: 'getUserPreferences',
      prevRoute: 'getPrevRoute',
    }),
  },
  watch: {
    '$store.state.user.preferences': function () {
      this.populateUserPreferences();
    },
  },
  created() {
    this.oid = this.$route.params.id;
  },
  async mounted() {
    this.populateUserPreferences();
  },
  methods: {
    ...mapMutations(['updateUserPreferences']),
    ...mapActions({
      updateSnack: 'updateSnack',
    }),
    populateUserPreferences() {
      this.regionTab = this.regionTabs.findIndex(
        (d) => d.name === this.userPreferences.regionPref
      );
      // this.fuelEconomyTab = this.fuelEconomyTabs.findIndex(
      //   (f) => f.name === this.userPreferences.fuelEconomyPref
      // );
      this.datePref = this.userPreferences.datePref;
      this.timePref = this.userPreferences.timePref;
      this.timeZonePref = this.userPreferences.timeZonePref;
    },
    async updateUserPrefs() {
      try {
        this.loading = true;
        const preferences = {
          regionPref: this.regionTabs[this.regionTab].name,
          // fuelEconomyPref: this.fuelEconomyTabs[this.fuelEconomyTab].name,
          datePref: this.datePref,
          timePref: this.timePref,
          timeZonePref: this.timeZonePref,
        };
        await putPref('user_preferences', preferences);
        this.updateUserPreferences({ ...preferences });
        this.loading = false;
        this.updateSnack({
          type: 'success',
          message: 'Successfully updated user settings!',
        });
      } catch (e) {
        this.updateSnack({
          type: 'error',
          message: 'Something went wrong, please try again later!',
        });
      }
    },
    routeBack() {
      if (this.prevRoute !== '/') {
        this.$router.push(this.prevRoute);
      } else {
        this.updateSnack({
          type: 'warning',
          message: 'Could not navigate to previous route!',
        });
      }
    },
  },
};
</script>

<style scoped>
.v-tab--active {
  background-color: #43b02a !important;
  color: black !important;
  border-radius: 5px;
}
</style>
