var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "health" } },
    [
      _vm.showAlert
        ? _c("alert", {
            attrs: {
              "alert-type": _vm.alertType,
              "alert-message": _vm.alertMessage,
            },
          })
        : _vm._e(),
      _c(
        "v-container",
        { staticClass: "mt-3 mx-3", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { staticClass: "mr-3" },
            [
              _c("v-col", [
                _c("h1", { staticStyle: { "font-weight": "400" } }, [
                  _vm._v("Health"),
                ]),
              ]),
              _c(
                "v-col",
                { attrs: { align: "right" } },
                [
                  _c("dtc-lookup"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-1",
                      staticStyle: { color: "black", height: "48px" },
                      attrs: { color: "#D8D8D8" },
                      on: { click: _vm.exportCSV },
                    },
                    [
                      _c(
                        "download-csv",
                        {
                          attrs: {
                            id: "csv",
                            name:
                              _vm.tab === "EX"
                                ? "ex_truck_data.csv"
                                : "erx_truck_data.csv",
                            data:
                              _vm.tab === "EX" ? _vm.exTrucks : _vm.erxTrucks,
                            fields: _vm.truckDataCSV(
                              _vm.tab === "EX" ? _vm.exTrucks : _vm.erxTrucks
                            ),
                          },
                        },
                        [
                          _c("v-icon", { attrs: { id: "csv2" } }, [
                            _vm._v("mdi-download"),
                          ]),
                          _vm._v(" Download CSV "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  attrs: {
                    cols: "3",
                    xs: "12",
                    sm: "12",
                    md: "6",
                    lg: "4",
                    xl: "3",
                  },
                },
                [
                  _c(
                    "v-tabs",
                    {
                      attrs: {
                        "fixed-tabs": "",
                        "background-color": "secondary",
                      },
                      model: {
                        value: _vm.tab,
                        callback: function ($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab",
                      },
                    },
                    _vm._l(_vm.tabItems, function (item) {
                      return _c("v-tab", { key: item }, [
                        _vm._v(" " + _vm._s(item) + " Trucks "),
                      ])
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "pb-0",
                  attrs: {
                    cols: "3",
                    xs: "12",
                    sm: "12",
                    md: "6",
                    lg: "4",
                    xl: "3",
                  },
                },
                [
                  _c("v-text-field", {
                    attrs: {
                      placeholder: "Search By Customer or Truck",
                      solo: "",
                      "prepend-inner-icon": "mdi-magnify",
                      "background-color": "shade",
                    },
                    model: {
                      value: _vm.searchText,
                      callback: function ($$v) {
                        _vm.searchText = $$v
                      },
                      expression: "searchText",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "my-0 mr-3" },
            [
              _c(
                "v-col",
                { staticClass: "py-0" },
                [
                  _vm.tabItems[_vm.tab] === "EX"
                    ? _c("ex-table", {
                        attrs: {
                          "truck-data": _vm.exTrucks,
                          loading: _vm.exLoading,
                          "search-text": _vm.searchText,
                        },
                        on: { emitAlert: _vm.alertResponse },
                      })
                    : _vm.tabItems[_vm.tab] === "ERX"
                    ? _c("erx-table", {
                        attrs: {
                          "truck-data": _vm.erxTrucks,
                          loading: _vm.erxLoading,
                          "search-text": _vm.searchText,
                        },
                        on: { emitAlert: _vm.alertResponse },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }