var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { "margin-top": "-20px" } },
    [
      _vm.errorMessage
        ? _c("v-alert", { attrs: { type: "error" } }, [
            _vm._v(" " + _vm._s(_vm.errorMessage) + " "),
          ])
        : _vm._e(),
      _vm.loading ? _c("loader") : _vm._e(),
      _c(
        "div",
        {
          ref: "milesTraveled",
          staticClass: "graph",
          attrs: { id: "milesTraveled" },
        },
        [
          !_vm.loading && _vm.graphs.includes("Truck Usage")
            ? _c("highcharts", {
                ref: "chart1",
                attrs: { options: _vm.distanceTraveledOptions },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { ref: "usageTime", staticClass: "graph", attrs: { id: "usageTime" } },
        [
          !_vm.loading && _vm.graphs.includes("Truck Usage")
            ? _c("highcharts", {
                ref: "chart2",
                attrs: { options: _vm.usageTimeOptions },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { ref: "fuelUsage", staticClass: "graph", attrs: { id: "fuelUsage" } },
        [
          _vm.fuelLoaded && _vm.graphs.includes("Truck Usage")
            ? _c("highcharts", {
                ref: "chart3",
                attrs: { options: _vm.fuelUsageOptions },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { ref: "soc", staticClass: "graph", attrs: { id: "soc" } },
        [
          _vm.socLoaded && _vm.graphs.includes("Truck Usage")
            ? _c("highcharts", {
                ref: "chart4",
                attrs: { options: _vm.socOptions },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { ref: "power", staticClass: "graph", attrs: { id: "power" } },
        [
          _vm.powerLoaded && _vm.graphs.includes("Truck Usage")
            ? _c("highcharts", {
                ref: "chart5",
                attrs: { options: _vm.powerOptions },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }