var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "user-details" } },
    [
      _vm.loading ? _c("loader") : _vm._e(),
      _c(
        "v-container",
        { staticClass: "mt-3 mx-3", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { staticClass: "mr-3" },
            [
              _c(
                "v-col",
                {
                  staticClass: "pa-0",
                  attrs: { align: "left", cols: "12", xl: "3", lg: "3" },
                },
                [
                  _c("v-breadcrumbs", {
                    staticClass: "pl-3",
                    attrs: { items: _vm.addCrumb, divider: "/" },
                  }),
                ],
                1
              ),
              _c("v-col", [_c("v-spacer")], 1),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mr-3 mt-0" },
            [
              _c("v-col", [
                _c("h1", [_vm._v(_vm._s(_vm.selectedUser.display_name))]),
              ]),
              _c("v-col", [_c("v-spacer")], 1),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mr-3 mt-0" },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "12", lg: "12", xl: "12" } },
                [
                  _c(
                    "v-expansion-panels",
                    {
                      model: {
                        value: _vm.panelIndex,
                        callback: function ($$v) {
                          _vm.panelIndex = $$v
                        },
                        expression: "panelIndex",
                      },
                    },
                    [
                      _c(
                        "v-expansion-panel",
                        [
                          _c(
                            "v-expansion-panel-header",
                            [
                              _c(
                                "v-row",
                                [
                                  _c("v-col", [
                                    _c("h2", [_vm._v("User Details")]),
                                    _c("br"),
                                    _vm.panelIndex === undefined
                                      ? _c(
                                          "span",
                                          { staticClass: "secondary-text" },
                                          [
                                            _vm._v(
                                              " Expand to view specific user details"
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-expansion-panel-content",
                            [
                              _c(
                                "v-row",
                                { staticClass: "px-1" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      attrs: {
                                        cols: "12",
                                        md: "3",
                                        lg: "3",
                                        xl: "3",
                                        align: "left",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "secondary-text" },
                                        [_vm._v("User Name")]
                                      ),
                                      _c("br"),
                                      _c("b", [
                                        _vm._v(
                                          _vm._s(_vm.selectedUser.display_name)
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      attrs: {
                                        cols: "12",
                                        md: "3",
                                        lg: "3",
                                        xl: "3",
                                        align: "left",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "secondary-text" },
                                        [_vm._v("Employer Company")]
                                      ),
                                      _c("br"),
                                      _c("b", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.selectedUser.employer_company
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      attrs: {
                                        cols: "12",
                                        md: "3",
                                        lg: "3",
                                        xl: "3",
                                        align: "left",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "secondary-text" },
                                        [_vm._v("Company Access")]
                                      ),
                                      _c("br"),
                                      _c("b", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.selectedUser.company_access
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      attrs: {
                                        cols: "12",
                                        md: "3",
                                        lg: "3",
                                        xl: "3",
                                        align: "left",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "secondary-text" },
                                        [_vm._v("Role")]
                                      ),
                                      _vm._v(" "),
                                      _c("br"),
                                      _c("b", [
                                        _vm._v(_vm._s(_vm.selectedUser.role)),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                { staticClass: "px-1" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      attrs: {
                                        cols: "12",
                                        md: "3",
                                        lg: "3",
                                        xl: "3",
                                        align: "left",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "secondary-text" },
                                        [_vm._v("User Email")]
                                      ),
                                      _c("br"),
                                      _c("b", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.selectedUser.user_principal_name
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      attrs: {
                                        cols: "12",
                                        md: "3",
                                        lg: "3",
                                        xl: "3",
                                        align: "left",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "secondary-text" },
                                        [_vm._v("User ID")]
                                      ),
                                      _c("br"),
                                      _c("b", [
                                        _vm._v(
                                          _vm._s(_vm.selectedUser.object_id)
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      attrs: {
                                        cols: "12",
                                        md: "3",
                                        lg: "3",
                                        xl: "3",
                                        align: "left",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "secondary-text" },
                                        [_vm._v("Is Active")]
                                      ),
                                      _vm._v(" "),
                                      _c("br"),
                                      _c("b", [
                                        _vm._v(
                                          _vm._s(_vm.selectedUser.is_active)
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      attrs: {
                                        cols: "12",
                                        md: "3",
                                        lg: "3",
                                        xl: "3",
                                        align: "left",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "secondary-text" },
                                        [_vm._v("Role Description")]
                                      ),
                                      _c("br"),
                                      _c("b", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.selectedUser.role_description
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                { staticClass: "px-1" },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      attrs: {
                                        cols: "12",
                                        md: "3",
                                        lg: "3",
                                        xl: "3",
                                        align: "left",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "secondary-text" },
                                        [_vm._v("Last Seen")]
                                      ),
                                      _c("br"),
                                      _c("b", [
                                        _vm._v(
                                          _vm._s(_vm.selectedUser.last_seen)
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c("v-col", {
                                    attrs: {
                                      cols: "12",
                                      md: "3",
                                      lg: "3",
                                      xl: "3",
                                      align: "left",
                                    },
                                  }),
                                  _c("v-col", {
                                    attrs: {
                                      cols: "12",
                                      md: "3",
                                      lg: "3",
                                      xl: "3",
                                      align: "left",
                                    },
                                  }),
                                  _c("v-col", {
                                    attrs: {
                                      cols: "12",
                                      md: "3",
                                      lg: "3",
                                      xl: "3",
                                      align: "left",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      attrs: {
                                        cols: "12",
                                        md: "3",
                                        lg: "3",
                                        xl: "3",
                                        align: "left",
                                      },
                                    },
                                    [
                                      _vm.checkUser()
                                        ? _c(
                                            "v-btn",
                                            {
                                              staticClass: "pl-1 pt-0",
                                              attrs: {
                                                text: "",
                                                color: "primary",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.editUser = true
                                                },
                                              },
                                            },
                                            [
                                              _c("span", [
                                                _vm._v("Edit Profile"),
                                              ]),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _c("v-col", {
                                    attrs: {
                                      cols: "12",
                                      md: "3",
                                      lg: "3",
                                      xl: "3",
                                      align: "left",
                                    },
                                  }),
                                  _c("v-col", {
                                    attrs: {
                                      cols: "12",
                                      md: "3",
                                      lg: "3",
                                      xl: "3",
                                      align: "left",
                                    },
                                  }),
                                  _c("v-col", {
                                    attrs: {
                                      cols: "12",
                                      md: "3",
                                      lg: "3",
                                      xl: "3",
                                      align: "left",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("v-row", { staticClass: "px-1" }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider", {
            staticClass: "mr-6",
            staticStyle: { "border-width": "thin" },
          }),
        ],
        1
      ),
      _vm.editUser
        ? _c("edit-user", {
            attrs: { user: _vm.selectedUser },
            on: {
              refresh: _vm.refreshData,
              closed: function ($event) {
                _vm.editUser = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }