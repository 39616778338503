<template>
  <v-card>
    <v-card-title>Add Step</v-card-title>
    <v-card-text>Fill in Step name and choose a plan category</v-card-text>
    <v-divider></v-divider>
    <v-form ref="form" v-model="formValid" lazy-validation>
      <v-container>
        <v-row>
          <v-col sm="3" class="formLabel">Name</v-col>
          <v-col sm="9">
            <v-text-field
              v-model="name"
              :rules="nameRules"
              class="formInput"
              outlined
              label="Name"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="3" class="formLabel">Category: </v-col>
          <v-col>
            <v-select
              v-model="stepCategory"
              :items="testCategories"
              :rules="catRules"
              class="formInput"
              outlined
              label="Categories"
              required
            ></v-select>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="cancel"> Cancel </v-btn>
        <v-btn :disabled="!formValid" color="primary" @click="exportStep">
          Add Step
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import _ from 'lodash';
export default {
  name: 'AddStep',
  props: {
    // eslint-disable-next-line
    stepDialog: Boolean,
    // eslint-disable-next-line
    toggle: Function,
    // eslint-disable-next-line
    addPlanStep: Function,
    // eslint-disable-next-line
    testCategories: Array,
    // eslint-disable-next-line
    fullCategories: Array,
  },
  data() {
    return {
      addDisabled: true,
      catRules: [(v) => !!v || 'Required!'],
      formValid: false,
      name: '',
      nameRules: [
        (v) => !!v || 'Name is required',
        (v) =>
          (v && v.length >= 2) || 'Name must be at least 2 or more characters',
      ],
      stepCategory: '',
    };
  },
  watch: {
    formValid: function () {
      this.formValid = !(this.name === '' && this.stepCategory === '');
    },
  },
  mounted() {},
  methods: {
    cancel() {
      this.$refs.form.reset();
      this.toggle();
    },
    async exportStep() {
      if (!this.$refs.form.validate()) {
        return;
      }
      const vm = this;
      let dataObj = {};
      dataObj.name = this.name;
      let catObj = _.find(vm.fullCategories, function (o) {
        return o.name === vm.stepCategory;
      });
      dataObj.category_id = catObj.id;
      // this.$emit('passInfo', dataObj);
      this.addPlanStep(dataObj);
      this.cancel();
    },
  },
};
</script>

<style scoped>
.formLabel {
  display: block;
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;
}
.formInput {
  margin-bottom: -20px;
  /* padding: -10px; */
}
</style>
