<template>
  <div style="margin-top: -20px">
    <v-alert v-if="errorMessage" type="error">
      {{ errorMessage }}
    </v-alert>
    <loader v-if="loading"></loader>
    <div id="milesTraveled" ref="milesTraveled" class="graph">
      <highcharts
        v-if="!loading && graphs.includes('Truck Usage')"
        ref="chart1"
        :options="distanceTraveledOptions"
      ></highcharts>
    </div>
    <div id="usageTime" ref="usageTime" class="graph">
      <highcharts
        v-if="!loading && graphs.includes('Truck Usage')"
        ref="chart2"
        :options="usageTimeOptions"
      ></highcharts>
    </div>
    <div id="fuelUsage" ref="fuelUsage" class="graph">
      <highcharts
        v-if="fuelLoaded && graphs.includes('Truck Usage')"
        ref="chart3"
        :options="fuelUsageOptions"
      ></highcharts>
    </div>
    <div id="soc" ref="soc" class="graph">
      <highcharts
        v-if="socLoaded && graphs.includes('Truck Usage')"
        ref="chart4"
        :options="socOptions"
      ></highcharts>
    </div>
    <div id="power" ref="power" class="graph">
      <highcharts
        v-if="powerLoaded && graphs.includes('Truck Usage')"
        ref="chart5"
        :options="powerOptions"
      ></highcharts>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getPastYear } from '@/api/trucks';
import { getTripReport } from '@/api/trips';
import loader from '@/utilities/loader';
import _ from 'lodash';
import { regionalSetting } from '@/utilities/userSettings';
export default {
  name: 'EXOperations',
  components: {
    loader,
  },
  props: {
    truck: {
      type: Number,
      default: null,
    },
    graphs: {
      type: Array,
      default() {
        return ['milesTraveled', 'usageTime'];
      },
    },
  },
  data() {
    return {
      loading: true,
      errorMessage: null,
      xMin: null,
      xMax: null,
      zoomedChart: null,
      charts: [],
      socLoaded: false,
      powerLoaded: false,
      fuelLoaded: false,
      initialLoadComplete: false,
      usageTimeOptions: {
        chart: {
          backgroundColor: '#1E1E1E',
          type: 'column',
          zoomType: 'x',
          name: '',
          events: {
            selection: (e) => {
              const chartName = e.target.title.textStr;
              if (e.xAxis) {
                this.zoomedChart = chartName;
                this.xMin = e.xAxis[0].min;
                this.xMax = e.xAxis[0].max;
                this.zoomCharts(true);
              } else {
                this.zoomedChart = '';
                this.xMin = null;
                this.xMax = null;
                this.zoomCharts(false);
              }
            },
          },
        },
        rangeSelector: {
          selected: 1,
        },
        title: {
          text: 'Usage Time',
          style: {
            color: '#FFFFFF',
          },
        },
        tooltip: {
          shared: true,
        },
        legend: {
          itemStyle: {
            color: '#FFFFFF',
            fontWeight: 'bold',
          },
        },
        plotOptions: {
          series: {
            stacking: 'normal',
            borderColor: '#1E1E1E',
          },
        },
        xAxis: {
          type: 'datetime',
          title: {
            text: 'Time',
            style: {
              color: '#FFFFFF',
            },
          },
          labels: {
            step: 1,
            style: {
              color: '#FFFFFF',
            },
          },
          lineColor: 'rgba(255, 255, 255, 0.24)',
        },
        yAxis: [
          {
            gridLineColor: ' rgba(255, 255, 255, 0.12)',
            title: {
              text: 'Duration hrs',
            },
            labels: {
              format: '{value}',
              style: {
                color: 'white',
              },
            },
          },
        ],
        setOptions: {
          width: '100%',
        },
        series: [],
      },
      distanceTraveledOptions: {
        chart: {
          backgroundColor: '#1E1E1E',
          type: 'line',
          zoomType: 'x',
          name: '',
          events: {
            selection: (e) => {
              const chartName = e.target.title.textStr;
              if (e.xAxis) {
                this.zoomedChart = chartName;
                this.xMin = e.xAxis[0].min;
                this.xMax = e.xAxis[0].max;
                this.zoomCharts(true);
              } else {
                this.zoomedChart = '';
                this.xMin = null;
                this.xMax = null;
                this.zoomCharts(false);
              }
            },
          },
        },
        rangeSelector: {
          selected: 1,
        },
        title: {
          text: 'Distance Traveled',
          style: {
            color: '#FFFFFF',
          },
        },
        tooltip: {
          shared: true,
        },
        legend: {
          itemStyle: {
            color: '#FFFFFF',
            fontWeight: 'bold',
          },
        },
        plotOptions: {
          series: {
            stacking: 'normal',
          },
        },
        xAxis: {
          type: 'datetime',
          title: {
            text: 'Time',
            style: {
              color: '#FFFFFF',
            },
          },
          labels: {
            step: 1,
            style: {
              color: '#FFFFFF',
            },
          },
          lineColor: 'rgba(255, 255, 255, 0.24)',
        },
        yAxis: [
          {
            gridLineColor: ' rgba(255, 255, 255, 0.12)',
            labels: {
              format: '{value}',
              style: {
                color: 'white',
              },
            },
            title: {
              text: 'Miles Traveled mi',
            },
          },
        ],
        setOptions: {
          width: '100%',
        },
        series: [],
      },
      fuelUsageOptions: {
        chart: {
          backgroundColor: '#1E1E1E',
          type: 'line',
          zoomType: 'x',
          name: '',
          events: {
            selection: (e) => {
              const chartName = e.target.title.textStr;
              if (e.xAxis) {
                this.zoomedChart = chartName;
                this.xMin = e.xAxis[0].min;
                this.xMax = e.xAxis[0].max;
                this.zoomCharts(true);
              } else {
                this.zoomedChart = '';
                this.xMin = null;
                this.xMax = null;
                this.zoomCharts(false);
              }
            },
          },
        },
        rangeSelector: {
          selected: 1,
        },
        title: {
          text: 'Fuel Usage',
          style: {
            color: '#FFFFFF',
          },
        },
        tooltip: {
          shared: true,
        },
        legend: {
          itemStyle: {
            color: '#FFFFFF',
            fontWeight: 'bold',
          },
        },
        xAxis: {
          type: 'datetime',
          title: {
            text: 'Time',
            style: {
              color: '#FFFFFF',
            },
          },
          labels: {
            step: 1,
            style: {
              color: '#FFFFFF',
            },
          },
          lineColor: 'rgba(255, 255, 255, 0.24)',
        },
        yAxis: [
          {
            labels: {
              style: {
                color: 'white',
              },
            },
            gridLineColor: 'rgba(255, 255, 255, 0.12)',
            title: {
              text: 'Fuel Usage (gal)',
            },
            opposite: true,
          },
          {
            labels: {
              style: {
                color: 'white',
              },
            },
            title: {
              text: 'MPG',
            },
            gridLineColor: 'rgba(255, 255, 255, 0.12)',
          },
        ],
        setOptions: {
          width: '100%',
        },
        series: [],
      },
      socOptions: {
        chart: {
          backgroundColor: '#1E1E1E',
          type: 'line',
          zoomType: 'x',
          name: '',
          events: {
            selection: (e) => {
              const chartName = e.target.title.textStr;
              if (e.xAxis) {
                this.zoomedChart = chartName;
                this.xMin = e.xAxis[0].min;
                this.xMax = e.xAxis[0].max;
                this.zoomCharts(true);
              } else {
                this.zoomedChart = '';
                this.xMin = null;
                this.xMax = null;
                this.zoomCharts(false);
              }
            },
          },
        },
        rangeSelector: {
          selected: 1,
        },
        title: {
          text: 'State of Charge',
          style: {
            color: '#FFFFFF',
          },
        },
        tooltip: {
          shared: true,
        },
        legend: {
          itemStyle: {
            color: '#FFFFFF',
            fontWeight: 'bold',
          },
        },
        xAxis: {
          type: 'datetime',
          title: {
            text: 'Time',
            style: {
              color: '#FFFFFF',
            },
          },
          labels: {
            step: 1,
            style: {
              color: '#FFFFFF',
            },
          },
          lineColor: 'rgba(255, 255, 255, 0.24)',
        },
        yAxis: [
          {
            gridLineColor: ' rgba(255, 255, 255, 0.12)',
            title: {
              text: 'SOC %',
            },
            labels: {
              format: '{value}',
              style: {
                color: 'white',
              },
            },
          },
        ],
        setOptions: {
          width: '100%',
        },
        series: [],
      },
      powerOptions: {
        chart: {
          backgroundColor: '#1E1E1E',
          type: 'line',
          zoomType: 'x',
          name: '',
          events: {
            selection: (e) => {
              const chartName = e.target.title.textStr;
              if (e.xAxis) {
                this.zoomedChart = chartName;
                this.xMin = e.xAxis[0].min;
                this.xMax = e.xAxis[0].max;
                this.zoomCharts(true);
              } else {
                this.zoomedChart = '';
                this.xMin = null;
                this.xMax = null;
                this.zoomCharts(false);
              }
            },
          },
        },
        rangeSelector: {
          selected: 1,
        },
        title: {
          text: 'Power',
          style: {
            color: '#FFFFFF',
          },
        },
        tooltip: {
          shared: true,
        },
        legend: {
          itemStyle: {
            color: '#FFFFFF',
            fontWeight: 'bold',
          },
        },
        xAxis: {
          type: 'datetime',
          title: {
            text: 'Time',
            style: {
              color: '#FFFFFF',
            },
          },
          labels: {
            step: 1,
            style: {
              color: '#FFFFFF',
            },
          },
          lineColor: 'rgba(255, 255, 255, 0.24)',
        },
        yAxis: [
          {
            gridLineColor: ' rgba(255, 255, 255, 0.12)',
            title: {
              text: 'Power kW',
            },
            labels: {
              format: '{value}',
              style: {
                color: 'white',
              },
            },
          },
        ],
        setOptions: {
          width: '100%',
        },
        series: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      userRoles: 'getUserRoles',
      userPreferences: 'getUserPreferences',
    }),
  },
  watch: {
    loading: function () {
      const vm = this;
      if (!vm.loading) {
        _.delay(() => {
          vm.updateChartList();
        }, 1000);
      }
    },
    graphs: function () {
      // if user changes graph selection (after initial load), update this.charts list
      if (this.initialLoadComplete) {
        _.delay(() => {
          this.updateChartList();
        }, 500);
      }
    },
  },
  mounted() {
    this.populateDefaultCharts();
  },
  methods: {
    async populateDefaultCharts() {
      let res;
      this.isMetric =
        this.userPreferences.regionPref === 'metric' ? true : false;
      try {
        res = await getPastYear(this.truck);
      } catch (e) {
        this.loading = false;
        this.errorMessage = 'trucks/pastyear/:id => ' + String(e);
        return;
      }

      let apuSeries = [];
      let idleSeries = [];
      let movingSeries = [];
      let prepSeries = [];
      let milesSeries = [];

      // process the data to feed into highcharts
      for (let i = 0; i < res.data.length; i++) {
        apuSeries.push([
          Date.parse(res.data[i].Day),
          Math.round((res.data[i].delta_APU / 60) * 100) / 100,
        ]);
        idleSeries.push([
          Date.parse(res.data[i].Day),
          Math.round((res.data[i].delta_Idle / 60) * 100) / 100,
        ]);
        movingSeries.push([
          Date.parse(res.data[i].Day),
          Math.round((res.data[i].delta_Moving / 60) * 100) / 100,
        ]);
        prepSeries.push([
          Date.parse(res.data[i].Day),
          Math.round((res.data[i].delta_Prep / 60) * 100) / 100,
        ]);
        milesSeries.push([
          Date.parse(res.data[i].Day),
          Math.round(res.data[i].delta_odometer * 100) / 100,
        ]);

        // while no data for dayPointer day, add data point of 0
        let dayPointer = Date.parse(res.data[i].Day) + 86400000;
        while (
          i < res.data.length - 1 &&
          Date.parse(res.data[i + 1].Day) > dayPointer
        ) {
          milesSeries.push([dayPointer, 0]);
          dayPointer += 86400000;
        }
      }

      // feed into highcharts
      this.usageTimeOptions.series = [
        {
          type: 'column',
          name: 'APU Time',
          data: apuSeries,
          color: '#BB86FC',
        },
        {
          type: 'column',
          name: 'Idle Time',
          data: idleSeries,
          color: '#FCBB86',
        },
        {
          type: 'column',
          name: 'Moving Time',
          data: movingSeries,
          color: '#2196F3',
        },
        {
          type: 'column',
          name: 'Prep Time',
          data: prepSeries,
          color: '#FE83AE',
        },
      ];

      this.distanceTraveledOptions.series = [
        {
          type: 'line',
          name: 'Distance Traveled',
          yAxis: 0,
          data: milesSeries,
          zIndex: 100,
          color: '#2196F3',
        },
      ];

      if (this.isMetric) {
        this.distanceTraveledOptions.yAxis[0].title.text =
          'Kilometers Traveled km';
      }

      this.loading = false;
      this.loadAdditionalCharts();
    },
    zoomCharts: function (state) {
      const vm = this;
      _.forEach(vm.charts, function (o) {
        if (state) {
          if (vm.graphs.includes('Truck Usage')) {
            for (let i = 1; i < 6; i++) {
              vm.$refs[`chart${i}`].chart.showResetZoom();
            }
          }
          o.xAxis[0].setExtremes(vm.xMin, vm.xMax);
        } else {
          o.xAxis[0].setExtremes(null, null);
        }
      });
    },
    async loadAdditionalCharts() {
      // load additional charts after default charts have loaded
      let res;
      try {
        res = await getTripReport(
          0,
          null,
          null,
          null,
          [this.truck],
          'EX',
          false,
          this.userPreferences.timeZonePref.canonical
        );
      } catch (e) {
        this.loading = false;
        this.errorMessage = 'trips/getTripReport => ' + String(e);
        return;
      }

      let fuelUsage = [];
      let mmpg = [];
      let tmpg = [];

      let powerIn = [];
      let powerOut = [];

      let minSOC = [];
      let maxSOC = [];
      // process the data to feed into highcharts
      for (let i = 0; i < res.data.length; i++) {
        fuelUsage.push([
          Date.parse(res.data[i].Day),
          regionalSetting(
            this.userPreferences.regionPref,
            res.data[i].totalFuelUsageLiters,
            'volume',
            1
          ),
        ]);
        mmpg.push([
          Date.parse(res.data[i].Day),
          regionalSetting(
            this.userPreferences.regionPref,
            res.data[i].estMovingKPL,
            'fuel_economy',
            2
          ),
        ]);
        tmpg.push([
          Date.parse(res.data[i].Day),
          regionalSetting(
            this.userPreferences.regionPref,
            res.data[i].estTotalKPL,
            'fuel_economy',
            2
          ),
        ]);

        powerIn.push([
          Date.parse(res.data[i].Day),
          Math.round(res.data[i].powerInKwh * 100) / 100,
        ]);
        powerOut.push([
          Date.parse(res.data[i].Day),
          Math.round(res.data[i].powerOutKwh * 100) / 100,
        ]);

        minSOC.push([
          Date.parse(res.data[i].Day),
          Math.round(res.data[i].socMin * 100) / 100,
        ]);
        maxSOC.push([
          Date.parse(res.data[i].Day),
          Math.round(res.data[i].socMax * 100) / 100,
        ]);

        // fill in missing data with 0's for power & fuelUsage series'
        let dayPointer = Date.parse(res.data[i].Day) + 86400000;
        while (
          i < res.data.length - 1 &&
          Date.parse(res.data[i + 1].Day) > dayPointer
        ) {
          mmpg.push([dayPointer, 0]);
          tmpg.push([dayPointer, 0]);
          fuelUsage.push([dayPointer, 0]);
          powerIn.push([dayPointer, 0]);
          powerOut.push([dayPointer, 0]);
          dayPointer += 86400000;
        }
      }

      // feed into highcharts
      this.socOptions.series = [
        {
          type: 'line',
          name: 'min SOC',
          data: minSOC,
          color: '#BB86FC',
        },
        {
          type: 'line',
          name: 'max SOC',
          data: maxSOC,
          color: '#FCBB86',
        },
      ];
      this.socLoaded = true;

      this.powerOptions.series = [
        {
          type: 'line',
          name: 'Power In',
          data: powerIn,
          color: '#2196F3',
        },
        {
          type: 'line',
          name: 'Power Out',
          data: powerOut,
          color: '#FCBB86',
        },
      ];
      this.powerLoaded = true;

      this.fuelUsageOptions.series = [
        {
          type: 'line',
          name: 'Fuel Used',
          data: fuelUsage,
          color: '#BB86FC',
          yAxis: 0,
        },
        {
          type: 'line',
          name: `${this.isMetric ? 'MKPL' : 'MMPG'}`,
          data: mmpg,
          color: '#FCBB86',
          yAxis: 1,
        },
        {
          type: 'line',
          name: `${this.isMetric ? 'TKPL' : 'TMPG'}`,
          data: tmpg,
          color: '#2196F3',
          yAxis: 1,
        },
      ];

      if (this.isMetric) {
        this.fuelUsageOptions.yAxis[1].title.text = 'KPL';
        this.fuelUsageOptions.yAxis[0].title.text = 'Fuel Usage L';
      }

      this.fuelLoaded = true;
      this.initialLoadComplete = true;
    },
    updateChartList() {
      this.charts = [];
      if (this.graphs.includes('Truck Usage')) {
        for (let i = 1; i < 6; i++) {
          this.$refs[`chart${i}`] &&
            this.charts.push(this.$refs[`chart${i}`].chart);
        }
      }
    },
  },
};
</script>

<style scoped>
.graph {
  margin-bottom: 30px;
}
</style>
