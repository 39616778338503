<!-- Global loading component -->
<template>
  <v-alert
    class="alert"
    dense
    prominent
    style="color: black"
    :type="alertType"
    :icon="getIcon()"
    :color="getColor()"
    >{{ alertMessage }}</v-alert
  >
</template>

<!--
    Possible statuses can be ('success','info', 'warning', and 'error')
-->
<script>
export default {
  name: 'Alert',
  props: {
    alertType: {
      type: String,
      required: true,
    },
    alertMessage: {
      type: String,
      required: true,
    },
  },
  data: () => ({}),
  methods: {
    getIcon() {
      let icon = '';
      switch (this.alertType) {
        case 'success':
          icon = 'mdi-checkbox-marked';
          break;
        case 'error':
          icon = 'mdi-alert-circle';
          break;
        default:
          icon = 'mdi-checkbox-marked';
          break;
      }
      return icon;
    },
    getColor() {
      let color = '';
      switch (this.alertType) {
        case 'success':
          color = 'primaryDark';
          break;
        case 'error':
          color = 'red';
          break;
        default:
          color = 'primary';
          break;
      }
      return color;
    },
  },
};
</script>
<style scoped>
/* centered global loader */
.alert {
  position: fixed;
  top: 50%;
  left: 50%;
  height: auto;
  width: auto;
  z-index: 1;
  transform: translate(-50%, -50%);
}
</style>
