<template>
  <highcharts :options="getChartData()"></highcharts>
</template>

<script>
export default {
  name: 'GroupedBarChart',
  props: {
    name: {
      required: true,
      type: String,
      defualt: '',
    },
    data: {
      required: true,
      type: Array,
      default: () => [],
    },
    categories: {
      required: true,
      type: Array,
      default: () => [],
    },
    height: {
      required: false,
      type: Number,
      default: null,
    },
  },
  data() {
    return {};
  },
  methods: {
    getChartData() {
      return {
        chart: {
          type: 'column',
          backgroundColor: '#1a1a1a',
          height: this.height ? this.height : null,
          borderColor: '#1a1a1a',
        },
        title: {
          text: this.name,
          style: {
            color: '#FFFFFF',
            fontFamily: 'Rawline',
            fontWeight: 'bold',
          },
        },
        yAxis: {
          gridLineColor: '#696969',
          labels: {
            style: {
              color: '#FFFFFF',
            },
          },
        },
        xAxis: {
          categories: this.categories,
          labels: {
            style: {
              color: '#FFFFFF',
            },
          },
        },
        legend: {
          enabled: true,
          itemStyle: {
            color: '#FFFFFF',
            fontWeight: 'bold',
          },
        },
        tooltip: {
          formatter: function () {
            return `
               Hours: <b>${this.y}</b><br/> `;
          },
        },
        series: this.data,
        credits: {
          enabled: false,
        },
      };
    },
  },
};
</script>
