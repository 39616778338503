<template>
  <div id="checkListTable">
    <v-container fluid>
      <v-row>
        <v-col class="pa-0" cols="12">
          <v-skeleton-loader
            v-if="loading"
            type="table"
            elevation="2"
          ></v-skeleton-loader>
          <v-data-table
            v-else
            :key="tableKey"
            single-select
            :headers="planHeaders"
            :items="planData"
            class="elevation-1 secondary locked-table-style row-height-50 row-pointer"
            :search="searchText"
            item-key="id"
            @click:row="getRowData"
          ></v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import _ from 'lodash';
export default {
  name: 'TestPlanTable',
  components: {},
  props: {
    planData: { type: Array, required: true },
    loading: { type: Boolean, default: true, required: true },
    searchText: { type: String, default: null, required: false },
    getElements: { type: Function, default: null, required: false },
  },
  data() {
    return {
      actionItems: [],
      drawer: false,
      planHeaders: [
        {
          text: 'Truck/Company',
          align: 'center',
          sortable: true,
          value: 'truck_number',
        },
        {
          text: 'Plan Name',
          align: 'center',
          sortable: true,
          value: 'name',
        },
        {
          text: 'VIN',
          align: 'center',
          sortable: true,
          value: 'vin',
        },
        {
          text: 'Delivery Date',
          align: 'center',
          sortable: true,
          value: 'deliveryDate',
        },
        {
          text: 'Failed Tests',
          align: 'center',
          sortable: true,
          value: 'failed_tests',
        },
        {
          text: 'Activity Date',
          align: 'center',
          sortable: true,
          value: 'activityDate',
        },
        {
          text: 'Recent Test',
          align: 'center',
          sortable: true,
          value: 'recent_test',
        },
        {
          text: 'Recent Tester',
          align: 'center',
          sortable: true,
          value: 'recent_tester',
        },
      ],
      selectedID: 0,
      tableKey: 1,
    };
  },
  mounted() {
    this.tableKey = Math.round(Math.random() * (9000 - 1) + 1);
  },
  methods: {
    getRowData: function (item, row) {
      this.getElements(item.id);
      row.select(true);
    },
  },
};
</script>

<style scoped>
.center {
  display: block;
  margin: auto;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
