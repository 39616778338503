<template>
  <v-container fluid class="mt-3 mx-3">
    <v-row class="mr-3">
      <v-col>
        <h1 style="font-weight: 400">User Management</h1>
      </v-col>
      <v-col align="right">
        <!-- hide cog until we have functionality to add to nav drawer -->
        <!-- <v-btn
          icon
          tile
          large
          style="background-color: #1a1a1a; border-radius: 5px"
          @click="drawer = true"
          ><v-icon style="opacity: 0.6">mdi-cog</v-icon>
        </v-btn> -->
      </v-col>
    </v-row>
    <v-row class="mr-3">
      <v-col cols="3" justify="center">
        <v-combobox
          v-model="searchText"
          :items="companyNames"
          placeholder="Company"
          solo
          color="primary"
        ></v-combobox>
      </v-col>
      <v-col cols="3" justify="center">
        <v-text-field
          v-model="searchText"
          placeholder="Enter Name or Email Address"
          solo
          prepend-inner-icon="mdi-magnify"
          color="primary"
        ></v-text-field>
      </v-col>
      <v-col><v-spacer /></v-col>
      <v-col align="right" cols="2">
        <v-btn
          v-if="userCanAddNewUser"
          color="primary"
          style="color: black; height: 48px"
          @click="addUser"
          ><v-icon left>mdi-plus</v-icon>Add User</v-btn
        >
      </v-col>
    </v-row>
    <v-row class="my-0 mr-3">
      <v-col>
        <v-data-table
          :headers="userLabelsDup"
          :items="userData"
          :search="searchText"
          :loading="loadingTable"
          :custom-sort="userTableCustomSort"
          loading-text="Loading... Please wait"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-menu style="top: 312px; left: 300px" offset-y>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">mdi-dots-vertical</v-icon>
              </template>
              <v-list dense>
                <v-list-item-group>
                  <v-list-item
                    v-for="(route, index) in actionItems"
                    :key="index"
                    :class="route.title"
                    @click="setRoute(item, route.title)"
                    ><v-list-item-icon class="mr-2">
                      <v-icon>{{ route.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="pr-2"></v-list-item-title
                      >{{ route.title }}
                    </v-list-item-content></v-list-item
                  >
                </v-list-item-group>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <add-user
      v-if="showAddUser"
      :companies="companies"
      @closed="showAddUser = false"
      @refresh="refreshData"
    />
    <v-navigation-drawer
      v-model="drawer"
      fixed
      right
      temporary
      width="360"
      class="shade"
    >
      <v-container>
        <v-row align="center">
          <v-col align="right" class="mb-2">
            <v-btn icon @click="drawer = false">
              <v-icon light>mdi-close-circle </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <p style="text-align: center">No content</p>
      </v-container>
    </v-navigation-drawer>
    <v-dialog v-model="openDelete" class="pa-5" width="750">
      <v-card class="pa-5">
        <h4 style="text-align: center">
          Are you sure you want to delete this User?<br />
          They will also be removed from any companies and groups they are in as
          well!
        </h4>
        <v-btn
          style="width: 49%; color: black"
          class="mr-1 mt-1"
          large
          color="primary"
          @click="deleteUsers"
          >Yes</v-btn
        >
        <v-btn
          style="width: 49%; color: black"
          class="mt-1"
          large
          color="primary"
          @click="openDelete = false"
          >No</v-btn
        >
      </v-card>
      <loader v-if="loader" />
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { permissions } from '@/api/permissions';
import { getUsers, deleteUser } from '@/api/users';
import { getCompanies } from '@/api/company';
import loader from '@/utilities/loader';
import AddUser from './AddUser';
import dateFormat from 'dateformat';

export default {
  name: 'Users',
  components: { 'add-user': AddUser, loader },
  data() {
    return {
      drawer: false,
      actionItems: [
        { title: 'Edit', icon: 'mdi-pencil' },
        { title: 'Delete', icon: 'mdi-delete' },
      ],
      userLabels: [
        {
          text: 'Full Name',
          align: 'left',
          value: 'display_name',
          sortable: true,
        },
        {
          text: 'Role',
          align: 'left',
          value: 'role',
          sortable: true,
        },
        {
          text: 'Email Address',
          align: 'left',
          value: 'user_principal_name',
          sortable: true,
        },
        {
          text: 'Last Seen',
          align: 'left',
          value: 'last_seen',
          sortable: true,
        },
        {
          text: '',
          value: 'actions',
          align: 'right',
          sortable: false,
        },
      ],
      userLabelsDup: [],
      searchText: '',
      loadingTable: true,
      loader: false,
      showAddUser: false,
      companies: [],
      companyNames: [],
      userData: [],
      openDelete: false,
      selectedUser: '',
    };
  },
  computed: {
    ...mapGetters({
      userRoles: 'getUserRoles',
    }),
    userCanAddNewUser() {
      return this.userRoles && this.userRoles.includes(permissions.addUser);
    },
  },
  async created() {
    await this.getUserData();
  },
  methods: {
    ...mapActions({
      updateSnack: 'updateSnack',
    }),
    setRoute(user, route) {
      if (route === 'Edit') {
        this.$router.push(`/user-management/${user.object_id}`);
      } else if (route === 'Delete') {
        this.selectedUser = user.object_id;
        this.openDelete = true;
      }
    },
    async getUserData() {
      this.userLabelsDup = [...this.userLabels];
      try {
        await Promise.allSettled([getUsers(), getCompanies()]).then(
          (results) => {
            results[0].status === 'fulfilled'
              ? (this.userData = results[0].value.data)
              : console.log(results[0].reason);

            results[1].status === 'fulfilled'
              ? (this.companies = results[1].value.data)
              : console.log(results[1].reason);
          }
        );
        // Add Columns if Hyliion Employee
        if (this.companies.length > 1) {
          this.userLabelsDup.splice(3, 0, {
            text: 'Employer Company',
            align: 'left',
            value: 'employer_company',
            sortable: true,
          });
          this.userLabelsDup.splice(4, 0, {
            text: 'Company Access',
            align: 'left',
            value: 'company_access',
            sortable: true,
          });
        }

        this.userData.forEach((u) => {
          if (u.has_all_company_access) {
            u.company_access = 'All';
          } else if (u.company_ids.length) {
            u.company_access = this.companies
              .filter((c) => u.company_ids.includes(c.id))
              .map((ic) => ic.name)
              .join(', ');
          } else {
            u.company_access = '';
          }

          u.last_seen = u.last_seen
            ? dateFormat(new Date(u.last_seen), 'mm/dd/yy h:MM TT')
            : '';

          u.employer_company = this.companies
            .filter((el) => el.id === u.employer_company_id)
            .map((ec) => ec.name);
        });
        this.companyNames = this.companies.map((c) => c.name);
      } catch (e) {
        this.updateSnack({
          type: 'error',
          message: 'There was an issue retrieving users!',
        });
      }
      this.loadingTable = false;
    },
    addUser() {
      this.showAddUser = true;
    },
    async deleteUsers() {
      try {
        this.loader = true;
        await deleteUser(this.selectedUser);
        this.updateComponents('success', 'User Successfully Deleted!');
      } catch (e) {
        this.updateComponents('error', 'There was an issue deleting user!');
        console.log('Error deleting user.');
      }
    },
    updateComponents(type, message) {
      this.loader = false;
      this.updateSnack({
        type,
        message,
      });
      setTimeout(() => {
        if (type !== 'error') this.openDelete = false;
        this.refreshData();
      }, 2000);
    },
    async refreshData() {
      // This will re-grab the data from the back-end with the updated user data
      this.loadingTable = true;
      await this.getUserData();
    },
    userTableCustomSort: function (items, index, isDesc) {
      items.sort((a, b) => {
        // Sorts out the empty row data
        if (!b[index]) {
          return -1;
        }
        if (!a[index]) {
          return 1;
        }
        if (index[0] === 'last_seen') {
          if (!isDesc[0]) {
            return new Date(a.last_seen) < new Date(b.last_seen) ? -1 : 1;
          } else {
            return new Date(b.last_seen) < new Date(a.last_seen) ? -1 : 1;
          }
        } else if (a[index] && b[index]) {
          if (!isDesc[0]) {
            return a[index].toString().toLowerCase() <
              b[index].toString().toLowerCase()
              ? -1
              : 1;
          } else {
            return b[index].toString().toLowerCase() <
              a[index].toString().toLowerCase()
              ? -1
              : 1;
          }
        }
      });
      return items;
    },
  },
};
</script>

<style scoped>
.placeholder {
  text-align: center;
}
</style>
