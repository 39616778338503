<template>
  <div>
    <div :id="`jitter${id}`" style="margin-top: 10px"></div>
  </div>
</template>

<script>
import Highcharts from 'highcharts';
import { mapGetters } from 'vuex';
import { convertTZ } from '@/utilities/dateFunctions.js';
import dateFormat from 'dateformat';

export default {
  name: 'Jitter',
  props: [
    'id',
    'data',
    'trucks',
    'title',
    'signal',
    'truckNames',
    'addChart',
    'thresholds',
  ],
  data() {
    return {
      options: null,
      colors: null,
      seriesData: null,
      categories: null,
      chart: null,
      plotLines: [],
      yExtremes: { min: null, max: null },
    };
  },
  async mounted() {
    if (this.data) {
      this.buildSeriesData(this.data);
      this.buildJitter();
    }
  },
  computed: {
    ...mapGetters({
      userPreferences: 'getUserPreferences',
    }),
  },
  // prevent a memory leak
  beforeDestroy() {
    if (this.chart) this.chart.destroy();
  },
  methods: {
    buildSeriesData(data) {
      let preSeriesData = {};
      // use this both to track trucks we've seen as well as index them for a proper x value
      let trucksSeen = [];
      for (const d of data) {
        if (!Object.keys(preSeriesData).includes(String(d.truck_id))) {
          trucksSeen.push(d.truck_id);
          preSeriesData[d.truck_id] = [];
        }
        const x = trucksSeen.indexOf(d.truck_id);
        // push signals based on jitterSignal type allowed
        const maxDatum = {
          x: x,
          y: d.maxValue,
          type: 'max',
          start: this.formatTS(d.start),
          end: this.formatTS(d.end),
        };
        const minDatum = {
          x: x,
          y: d.minValue,
          type: 'min',
          start: this.formatTS(d.start),
          end: this.formatTS(d.end),
        };
        switch (this.signal) {
          case 'min':
            preSeriesData[d.truck_id].push(minDatum);
            break;
          case 'max':
            preSeriesData[d.truck_id].push(maxDatum);
            break;
          case 'maxmin':
            preSeriesData[d.truck_id].push(maxDatum);
            preSeriesData[d.truck_id].push(minDatum);
            break;
        }
      }

      let seriesData = [];
      for (const truck_id of Object.keys(preSeriesData)) {
        const truckName = this.truckNames
          ? this.truckNames[truck_id]
          : truck_id;
        seriesData.push({
          name: truckName,
          data: preSeriesData[truck_id],
        });
      }
      const vm = this;
      this.categories = trucksSeen.map((t) =>
        vm.truckNames ? vm.truckNames[t] : 'Truck ' + t
      );
      this.seriesData = seriesData;
      this.buildThresholdSeries();
    },
    buildThresholdSeries() {
      let plotLines = [];
      let [maxY, minY] = [-Infinity, Infinity];
      this.thresholds[0].thresholds.forEach((t) => {
        maxY = Math.max(t.failed_threshold, t.warning_threshold, maxY);
        minY = Math.min(t.failed_threshold, t.warning_threshold, minY);
        plotLines.push({
          color: 'orange',
          value: t.warning_threshold,
          label: {
            text: t.description,
            style: { color: 'white' },
            align: 'right',
          },
        });

        plotLines.push({
          color: 'red',
          value: t.failed_threshold,
          label: {
            text: t.description,
            style: { color: 'white' },
            align: 'left',
          },
        });
      });
      this.plotLines = plotLines;
      this.yExtremes = {
        min: minY,
        max: maxY,
      };
    },
    buildJitter() {
      // Make all the colors semi-transparent so we can see overlapping dots
      this.colors = Highcharts.getOptions().colors.map((color) =>
        Highcharts.color(color).setOpacity(0.5).get()
      );
      this.colors[1] = this.colors[8]; // replace gray with red
      this.setOptions();
      this.chart = Highcharts.chart(`jitter${this.id}`, this.options);
      this.addChart(this.chart);
      // set zoom to include threshold lines
      this.chart.yAxis[0].setExtremes(
        Math.min(this.chart.yAxis[0].min, this.yExtremes.min),
        Math.max(this.chart.yAxis[0].max, this.yExtremes.max)
      );
    },
    // depends on this.colors and this.seriesData
    setOptions() {
      const vm = this;
      if (!this.colors || !this.seriesData)
        console.warn(
          'WARNING: configure colors and seriesData in Jitter options'
        );
      this.options = {
        credits: { enabled: false },
        chart: {
          backgroundColor: '#1E1E1E',

          type: 'scatter',
        },

        colors: vm.colors,

        title: {
          text: vm.title || 'Jitter',
          style: {
            color: '#FFFFFF',
          },
        },
        tooltip: {
          backgroundColor: '#363636',
          borderRadius: 8,
          borderColor: '#363636',
          style: {
            color: '#FFFFFF',
          },
          formatter: function () {
            return `
              <strong>${this.x}</strong><br>
              ${this.point.type}: <strong>${this.y}</strong><br>
              start: ${this.point.start}<br>
              end: ${this.point.end}
              `;
          },
        },

        xAxis: {
          categories: vm.categories,
          labels: {
            style: {
              color: '#FFFFFF',
            },
          },
        },

        yAxis: {
          title: {
            text: `Measurements (${vm.signal})`, // fixme: send signal name
            style: {
              color: '#FFFFFF',
            },
          },
          labels: {
            style: {
              color: '#FFFFFF',
            },
          },
          gridLineColor: 'rgba(255, 255, 255, 0.12)',
          plotLines: vm.plotLines,
        },

        plotOptions: {
          scatter: {
            showInLegend: false,
            jitter: {
              x: 0.24,
              y: 0,
            },
            marker: {
              radius: 3,
              symbol: 'circle',
            },
            tooltip: {
              pointFormat: 'Measurement: {point.y:.3f}',
            },
          },
        },
        series: this.seriesData,
        exporting: {
          enabled: true,
          width: 1000,
        },
      };
    },
    // convert to userPref timezone and format timestamp
    formatTS(timestamp) {
      let convertedDate = convertTZ(
        timestamp,
        this.userPreferences.timeZonePref.canonical
      );
      return dateFormat(convertedDate, 'm/d/yy h:MM:ss Z');
    },
  },
};
</script>
