var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "configuration-view" } },
    [
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { attrs: { align: "center" } },
            [
              _c("v-col", [
                _c("h1", { staticStyle: { "font-weight": "400" } }, [
                  _vm._v("Configuration Management"),
                ]),
              ]),
              _c("v-col", { attrs: { align: "right" } }),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mt-4" },
            [
              _c(
                "v-col",
                {
                  attrs: {
                    cols: "4",
                    xs: "12",
                    sm: "12",
                    md: "12",
                    lg: "4",
                    xl: "4",
                  },
                },
                [
                  _c(
                    "v-tabs",
                    {
                      attrs: {
                        "fixed-tabs": "",
                        "background-color": "secondary",
                      },
                    },
                    [
                      _vm.userCanSeeAssets
                        ? _c(
                            "v-tab",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeTab("Trucks")
                                },
                              },
                            },
                            [_vm._v("Trucks")]
                          )
                        : _vm._e(),
                      _vm.userCanSeeAssets
                        ? _c(
                            "v-tab",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeTab("Customers")
                                },
                              },
                            },
                            [_vm._v("Customers")]
                          )
                        : _vm._e(),
                      _vm.userCanSeePackages
                        ? _c(
                            "v-tab",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.changeTab("Packages")
                                },
                              },
                            },
                            [_vm._v("Packages")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "hidden-sm-and-down" },
                [_c("v-spacer")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.switchTab === "Trucks" && _vm.userCanSeeAssets
        ? _c("truck-view")
        : _vm.switchTab === "Customers" && _vm.userCanSeeAssets
        ? _c("customer-view")
        : _vm._e(),
      _vm.switchTab === "Packages" && _vm.userCanSeePackages
        ? _c("package-view")
        : _vm._e(),
      _c(
        "v-navigation-drawer",
        {
          staticClass: "shade",
          attrs: { right: "", temporary: "", fixed: "", width: "360" },
          model: {
            value: _vm.drawer,
            callback: function ($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer",
          },
        },
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                { attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "mb-2", attrs: { align: "right" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function ($event) {
                              _vm.drawer = false
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { light: "" } }, [
                            _vm._v("mdi-close-circle "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-expansion-panels",
                {
                  attrs: { multiple: "" },
                  model: {
                    value: _vm.panel,
                    callback: function ($$v) {
                      _vm.panel = $$v
                    },
                    expression: "panel",
                  },
                },
                [
                  _c(
                    "v-expansion-panel",
                    { staticClass: "shade" },
                    [
                      _c("v-expansion-panel-header", [_vm._v("Placeholder")]),
                      _c("v-expansion-panel-content"),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }