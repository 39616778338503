var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "trucks" } },
    [
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "4", justify: "center" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      placeholder: "Search",
                      solo: "",
                      "prepend-inner-icon": "mdi-magnify",
                      color: "primary",
                    },
                    model: {
                      value: _vm.searchTextCompany,
                      callback: function ($$v) {
                        _vm.searchTextCompany = $$v
                      },
                      expression: "searchTextCompany",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "8", align: "right" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticStyle: { color: "black" },
                      attrs: { large: "", color: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.showNewConfig = true
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v("mdi-plus-circle-outline"),
                      ]),
                      _vm._v("Add Customer"),
                    ],
                    1
                  ),
                  _c("span", [_vm._v(" ")]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "py-0" },
                [
                  _vm.loading
                    ? _c("v-skeleton-loader", {
                        attrs: { type: "table", elevation: "2" },
                      })
                    : _c("v-data-table", {
                        staticClass: "elevation-1",
                        attrs: {
                          headers: _vm.headers,
                          items: _vm.masterCustomers,
                          search: _vm.searchTextCompany,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: `item.actions`,
                              fn: function ({ item }) {
                                return [
                                  _c(
                                    "v-menu",
                                    {
                                      staticStyle: {
                                        top: "312px",
                                        left: "300px",
                                      },
                                      attrs: { "offset-y": "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on }) {
                                              return [
                                                _c("v-icon", _vm._g({}, on), [
                                                  _vm._v("mdi-dots-vertical"),
                                                ]),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _c(
                                        "v-list",
                                        _vm._l(
                                          _vm.actionItems,
                                          function (route, index) {
                                            return _c(
                                              "v-list-item",
                                              {
                                                key: index,
                                                class: route.title,
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.clickAction(
                                                      item,
                                                      route.title
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v(_vm._s(route.title))]
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.showNewConfig
            ? _c("add-customer", {
                on: { closed: _vm.closeNewConfig, refresh: _vm.refreshData },
              })
            : _vm._e(),
          _vm.showEditConfig
            ? _c("edit-customer", {
                attrs: { customer: _vm.customerSelected },
                on: { closed: _vm.closeEditConfig, refresh: _vm.refreshData },
              })
            : _vm._e(),
          _vm.showRemoveConfig
            ? _c("remove-customer", {
                attrs: { customer: _vm.customerSelected },
                on: { closed: _vm.closeRemoveConfig, refresh: _vm.refreshData },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }