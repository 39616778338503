var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c("v-card-title", [_vm._v("Add Issues")]),
      _c("v-card-text", [_vm._v("Please Add Issue Details")]),
      _c("v-divider"),
      _c(
        "v-form",
        {
          ref: "form",
          attrs: { "lazy-validation": "" },
          model: {
            value: _vm.formValid,
            callback: function ($$v) {
              _vm.formValid = $$v
            },
            expression: "formValid",
          },
        },
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "formLabel", attrs: { sm: "3" } },
                    [_vm._v("Plan:")]
                  ),
                  _c(
                    "v-col",
                    [
                      _c("v-text-field", {
                        staticClass: "formInput",
                        attrs: {
                          outlined: "",
                          label: "Currently selected plan",
                          disabled: "",
                        },
                        model: {
                          value: _vm.currentPlan,
                          callback: function ($$v) {
                            _vm.currentPlan = $$v
                          },
                          expression: "currentPlan",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "formLabel", attrs: { sm: "3" } },
                    [_vm._v("Vehicle:")]
                  ),
                  _c(
                    "v-col",
                    { attrs: { sm: "9" } },
                    [
                      _c("v-select", {
                        staticClass: "formInput",
                        attrs: {
                          items: _vm.vehicleList,
                          rules: _vm.reqRules,
                          outlined: "",
                          label: "Choose Vehicle",
                          required: "",
                        },
                        model: {
                          value: _vm.selectedVehicle,
                          callback: function ($$v) {
                            _vm.selectedVehicle = $$v
                          },
                          expression: "selectedVehicle",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "formLabel", attrs: { sm: "3" } },
                    [_vm._v("HW/SW:")]
                  ),
                  _c(
                    "v-col",
                    { attrs: { sm: "9" } },
                    [
                      _c("v-select", {
                        staticClass: "formInput",
                        attrs: {
                          items: _vm.hwswList,
                          rules: _vm.reqRules,
                          outlined: "",
                          label: "Choose HW or SW",
                          required: "",
                        },
                        model: {
                          value: _vm.hw_sw,
                          callback: function ($$v) {
                            _vm.hw_sw = $$v
                          },
                          expression: "hw_sw",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "formLabel", attrs: { sm: "3" } },
                    [_vm._v("Title:")]
                  ),
                  _c(
                    "v-col",
                    { attrs: { sm: "9" } },
                    [
                      _c("v-text-field", {
                        staticClass: "formInput",
                        attrs: {
                          rules: _vm.reqRules,
                          outlined: "",
                          label: "Add Title",
                          required: "",
                        },
                        model: {
                          value: _vm.issue_title,
                          callback: function ($$v) {
                            _vm.issue_title = $$v
                          },
                          expression: "issue_title",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "formLabel", attrs: { sm: "3" } },
                    [_vm._v("Details:")]
                  ),
                  _c(
                    "v-col",
                    { attrs: { sm: "9" } },
                    [
                      _c("v-text-field", {
                        staticClass: "formInput",
                        attrs: {
                          rules: _vm.reqRules,
                          outlined: "",
                          label: "Add Details",
                          required: "",
                        },
                        model: {
                          value: _vm.issue_details,
                          callback: function ($$v) {
                            _vm.issue_details = $$v
                          },
                          expression: "issue_details",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "formLabel", attrs: { sm: "3" } },
                    [_vm._v("Next Steps:")]
                  ),
                  _c(
                    "v-col",
                    { attrs: { sm: "9" } },
                    [
                      _c("v-text-field", {
                        staticClass: "formInput",
                        attrs: { outlined: "", label: "Add Next Steps" },
                        model: {
                          value: _vm.next_steps,
                          callback: function ($$v) {
                            _vm.next_steps = $$v
                          },
                          expression: "next_steps",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "formLabel", attrs: { sm: "3" } },
                    [_vm._v("Status:")]
                  ),
                  _c(
                    "v-col",
                    { attrs: { sm: "9" } },
                    [
                      _c("v-select", {
                        staticClass: "formInput",
                        attrs: {
                          items: _vm.status,
                          rules: _vm.reqRules,
                          outlined: "",
                          label: "Choose Status",
                          required: "",
                        },
                        model: {
                          value: _vm.chosenStatus,
                          callback: function ($$v) {
                            _vm.chosenStatus = $$v
                          },
                          expression: "chosenStatus",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "formLabel", attrs: { sm: "3" } },
                    [_vm._v("Comments:")]
                  ),
                  _c(
                    "v-col",
                    { attrs: { sm: "9" } },
                    [
                      _c("v-text-field", {
                        staticClass: "formInput",
                        attrs: { outlined: "", label: "Add Comments" },
                        model: {
                          value: _vm.issue_comments,
                          callback: function ($$v) {
                            _vm.issue_comments = $$v
                          },
                          expression: "issue_comments",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "formLabel", attrs: { sm: "3" } },
                    [_vm._v("Found Date: ")]
                  ),
                  _c(
                    "v-col",
                    { attrs: { sm: "9" } },
                    [
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "##/##/####",
                            expression: "'##/##/####'",
                          },
                        ],
                        staticClass: "formInput",
                        attrs: {
                          rules: _vm.dateRules,
                          label: "Start Date (MM/DD/YYYY)",
                          type: "text",
                          outlined: "",
                          "append-icon": "mdi-calendar",
                          required: "",
                        },
                        model: {
                          value: _vm.found_date,
                          callback: function ($$v) {
                            _vm.found_date = $$v
                          },
                          expression: "found_date",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "formLabel", attrs: { sm: "3" } },
                    [_vm._v("Resolved Date: ")]
                  ),
                  _c(
                    "v-col",
                    { attrs: { sm: "9" } },
                    [
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "##/##/####",
                            expression: "'##/##/####'",
                          },
                        ],
                        staticClass: "formInput",
                        attrs: {
                          rules: _vm.dateRules,
                          label: "Start Date (MM/DD/YYYY)",
                          type: "text",
                          outlined: "",
                          "append-icon": "mdi-calendar",
                          required: "",
                        },
                        model: {
                          value: _vm.resolved_date,
                          callback: function ($$v) {
                            _vm.resolved_date = $$v
                          },
                          expression: "resolved_date",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "formLabel", attrs: { sm: "3" } },
                    [_vm._v("JIRA Link:")]
                  ),
                  _c(
                    "v-col",
                    { attrs: { sm: "9" } },
                    [
                      _c("v-text-field", {
                        staticClass: "formInput",
                        attrs: { outlined: "", label: "Add JIRA Link" },
                        model: {
                          value: _vm.jira_link,
                          callback: function ($$v) {
                            _vm.jira_link = $$v
                          },
                          expression: "jira_link",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", text: "" },
                  on: { click: _vm.cancel },
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { disabled: !_vm.formValid, color: "primary" },
                  on: { click: _vm.submitIssue },
                },
                [_vm._v(" Add Issues ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }