<template>
  <v-dialog v-model="openDialog" width="460" persistent>
    <loader v-if="loading" />
    <v-card class="modal-bg-color">
      <v-container fluid>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row align="center" class="pl-4">
            <v-col align="left" cols="4"><h3>Add User</h3></v-col>
            <v-col><v-spacer /></v-col>
            <v-col align="right" cols="2">
              <v-btn icon @click="openDialog = false">
                <v-icon light>mdi-close-circle </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0">
              <v-text-field
                v-model="user.full_name"
                :rules="[(v) => !!v || 'Required!']"
                label="Full Name"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0">
              <v-text-field
                v-model.trim="user.email"
                label="Email"
                outlined
                :rules="[emailRules, (v) => !!v || 'Required!'].flat()"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <template v-if="isHyliion">
            <v-row>
              <v-col class="py-0">
                <v-select
                  :items="companies"
                  item-text="name"
                  label="Employer Company"
                  :rules="[(v) => !!v || 'Required!']"
                  outlined
                  return-object
                  @input="selectId($event, 'employer')"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0">
                <v-checkbox
                  v-model="user.has_all_company_access"
                  label="Has All Company Access"
                  color="primary"
                  hide-details
                  class="ma-0"
                ></v-checkbox>
                <v-select
                  :disabled="user.has_all_company_access"
                  :items="companies"
                  item-text="name"
                  multiple
                  label="Company Access"
                  outlined
                  return-object
                  @input="selectId($event, 'company')"
                ></v-select>
              </v-col>
            </v-row>
          </template>
          <v-row>
            <v-col class="py-0">
              <v-select
                :items="groups"
                :rules="[(v) => !!v || 'Required!']"
                item-text="displayName"
                label="Role"
                outlined
                return-object
                @input="selectId($event, 'groups')"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0 hidden-xs-and-down"><v-spacer /></v-col>
            <v-col class="pt-0 mr-1" align="center" cols="3"
              ><v-btn plain color="primary" @click="openDialog = false"
                >Cancel</v-btn
              ></v-col
            >
            <v-col class="pt-0 mr-1" align="center" cols="3"
              ><v-btn
                color="primary"
                style="color: black"
                :disabled="!valid"
                @click="addNewUser"
                >Submit</v-btn
              ></v-col
            >
          </v-row>
        </v-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import loader from '@/utilities/loader';

import { getGroups, addUser } from '@/api/users';
export default {
  name: 'AddUser',
  components: { loader },
  props: {
    companies: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      openDialog: false,
      loading: false,
      valid: false,
      emailRules: [
        (v) =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          'E-mail must be valid',
      ],
      user: {
        full_name: '',
        email: '',
        employer_company_id: '',
        company_id_list: [],
        has_all_company_access: false,
        role_group: '',
      },
      items: [
        { text: 'Yes', value: true },
        { text: 'No', value: false },
      ],
      groups: [],
      isHyliion: false,
    };
  },
  watch: {
    // Watches for the change in openDialog variable to emit the false variable back to parent to reset
    openDialog: function (newValue) {
      if (!newValue) {
        this.openDialog = false;
        this.$emit('closed', false);
      }
    },
  },
  async mounted() {
    this.openDialog = true;
    if (this.companies.length > 1) {
      this.isHyliion = true;
    } else {
      this.user.employer_company_id = this.companies[0].id;
      this.user.company_id_list = [this.companies[0].id];
    }
    try {
      let { data } = await getGroups();
      this.groups = data.roleGroups;
    } catch (e) {
      this.updateSnack({
        type: 'error',
        message: 'There was an issue retrieving data!',
      });
    }
  },
  methods: {
    ...mapActions({
      updateSnack: 'updateSnack',
    }),
    selectId(e, type) {
      if (type === 'groups') {
        this.user.role_group = e.id;
      }

      if (type === 'employer') {
        this.user.employer_company_id = e.id;
      }

      if (type === 'company') {
        this.user.company_id_list = e.map((el) => el.id);
      }
    },
    async addNewUser() {
      // Checks validation
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loading = true;
      try {
        await addUser(this.user);

        this.$emit('refresh');
        this.alertResponse('success', 'Successfully added user!');
      } catch (error) {
        this.alertResponse(
          'error',
          'Something went wrong, please try again later!'
        );
      }
    },
    alertResponse(type, message) {
      this.loading = false;
      this.updateSnack({
        type,
        message,
      });
      setTimeout(() => {
        if (type !== 'error') this.openDialog = false;
      }, 2000);
    },
  },
};
</script>
<style scoped></style>
