var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        { staticClass: "mr-3" },
        [_c("v-col", [_c("h3", [_vm._v("Component Level Faults")])])],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { sm: "12" } }, [
            _c(
              "div",
              [
                _c("v-data-table", {
                  staticClass:
                    "elevation-1 secondary locked-table-style row-height-50",
                  attrs: {
                    headers: _vm.faultHeaders,
                    items: _vm.faultItems,
                    "items-per-page": _vm.rowCount,
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }