<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" xl="3" lg="3">
        <v-breadcrumbs
          style="padding: 5px 0 0 5px"
          :items="breadcrumbs"
          divider="/"
        ></v-breadcrumbs>
      </v-col>
      <v-col><v-spacer /></v-col>
    </v-row>
    <v-row>
      <v-col lg="7">
        <h1
          class="transition-swing"
          style="font-weight: 400"
          v-text="isEditSignal ? 'Edit Signal' : 'Add Signal'"
        ></h1>
      </v-col>
      <v-col align="right" lg="5">
        <v-row style="display: block; margin-top: 7px; margin-bottom: 7px">
          <v-btn
            icon
            tile
            large
            style="
              margin-right: 0.7rem;
              margin-left: 0.5rem;
              background-color: #1a1a1a;
              border-radius: 5px;
            "
            @click="drawer = true"
            ><v-icon style="opacity: 0.6">mdi-cog</v-icon></v-btn
          >
        </v-row>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dictionaryModalOpen"
      width="500"
      content-class="alertdialog"
      @click:outside="closeDictionaryModal"
    >
      <v-card>
        <v-card-title class="">
          {{
            newSignal.signal_type_id && newSignal.signal_type_id.abbr == 9
              ? 'Add State'
              : 'Add Mask'
          }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-actions>
          <v-col>
            <v-text-field
              v-if="
                newSignal.signal_type_id && newSignal.signal_type_id.abbr == 8
              "
              v-model="dictionaryModal.mask"
              label="Mask"
              placeholder="Mask"
              outlined
              color="primary"
            ></v-text-field>
            <v-text-field
              v-if="
                newSignal.signal_type_id && newSignal.signal_type_id.abbr == 9
              "
              v-model="dictionaryModal.state"
              label="State"
              placeholder="State"
              outlined
              color="primary"
            ></v-text-field>
            <v-text-field
              v-model="dictionaryModal.value"
              label="Value"
              placeholder="Value"
              outlined
              color="primary"
            ></v-text-field>
            <v-col align="right">
              <v-btn
                color="primary"
                class="secondary-button"
                text
                @click="closeDictionaryModal(false)"
              >
                CANCEL
              </v-btn>
              <v-btn
                color="secondary"
                class="primary-button"
                text
                @click="sendDictionaryModal()"
              >
                ADD
              </v-btn>
            </v-col>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <loader v-if="sourceTypes.length == 0 && signalTypes.length == 0" />
    <v-card
      v-if="sourceTypes.length > 0 && signalTypes.length > 0"
      max-width="848"
      class="pa-2"
    >
      <v-row>
        <v-col>
          <v-text-field
            v-model="newSignal.signal"
            label="Signal Name"
            placeholder="Signal Name"
            outlined
            color="primary"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-select
            v-model="newSignal.signal_type_id"
            :items="signalTypes"
            item-text="state"
            item-value="abbr"
            label="Signal Type"
            placeholder="Signal Type"
            persistent-hint
            :disabled="isEditSignal !== null"
            return-object
            outlined
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="newSignal.column_name"
            label="ADX Column Name"
            placeholder="ADX Column Name"
            outlined
            color="primary"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-select
            v-model="newSignal.units_id"
            :items="unitsTypes"
            item-text="state"
            item-value="abbr"
            label="Units Type"
            persistent-hint
            return-object
            outlined
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="newSignal.min"
            label="Min"
            placeholder="Min"
            outlined
            color="primary"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="newSignal.max"
            label="Max"
            placeholder="Max"
            outlined
            color="primary"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" justify="center">
          <v-text-field
            v-model="newSignal.comment"
            label="Comment"
            placeholder="Comment"
            outlined
            color="primary"
          ></v-text-field>
        </v-col>
        <!-- <v-col>
          <v-select
            v-model="newSignal.units_id"
            :items="unitsTypes"
            item-text="state"
            item-value="abbr"
            label="Units Type"
            persistent-hint
            return-object
            outlined
          ></v-select>
        </v-col> -->

        <!-- <v-col>
            <v-select
              v-model="newSignal.src_id"
              :items="sourceTypes"
              item-text="state"
              item-value="abbr"
              label="Source Type"
              persistent-hint
              return-object
              outlined
            ></v-select>
          </v-col> -->
      </v-row>
      <v-row>
        <v-col
          v-if="newSignal.signal_type_id && newSignal.signal_type_id.abbr == 9"
          cols="12"
          align="right"
          class="pb-0"
        >
          <v-btn
            color="primary"
            class="secondary-button mr-3"
            text
            outlined
            align="right"
            @click="openDictionaryModal()"
          >
            <v-icon dark> mdi-plus </v-icon>
            Add State
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col>
          <v-data-table
            :headers="[
              { text: 'State', value: 'state' },
              { text: 'Value', value: 'value' },
              { text: '', value: 'action' },
            ]"
            :items="controllerState"
            :items-per-page="500"
            class="elevation-1"
          >
            <template v-slot:[`item.action`]="{ item }">
              <v-btn @click="deleteMaskState(false, item)">DELETE</v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-if="newSignal.signal_type_id && newSignal.signal_type_id.abbr == 8"
          class="toolbar"
          cols="12"
        >
          <v-btn
            color="primary"
            text
            outlined
            align="right"
            @click="openDictionaryModal()"
          >
            <v-icon dark> mdi-plus </v-icon>
            Add Mask
          </v-btn>
          <v-data-table
            :headers="[
              { text: 'Mask', value: 'mask' },
              { text: 'Value', value: 'value' },
              { text: '', value: 'action' },
            ]"
            :items="bitField"
            :items-per-page="500"
            class="elevation-1"
          >
            <template v-slot:[`item.action`]="{ item }">
              <v-btn @click="deleteMaskState(true, item)">DELETE</v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="secondary-button"
          text
          @click="closeSignalDictionaryForm(false)"
        >
          Cancel
        </v-btn>

        <v-btn
          color="secondary"
          class="primary-button"
          text
          @click="sendSignalDictionaryForm()"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-navigation-drawer
      v-model="drawer"
      disable-click-watcher
      right
      temporary
      fixed
      width="360"
    >
      <v-row align="center" class="my-2 pl-5 pr-3">
        <v-col align="left" cols="9">Customize Columns:</v-col>
        <v-col align="right" cols="3">
          <v-btn icon @click="drawer = false">
            <v-icon light>mdi-close-circle </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-divider />
      <v-row>
        <v-col cols="12"> </v-col>
      </v-row>
    </v-navigation-drawer>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import loader from '../../utilities/loader';
import { getDropdown, addSignal, putSignal } from '../../api/dataDictionary';
export default {
  name: 'AddSignal',
  components: { loader },
  props: [],
  data() {
    return {
      drawer: false,
      tab: null,
      dictionaryModalOpen: false,
      dictionaryModal: {
        open: false,
        state: '',
        mask: '',
        value: '',
      },
      dropdown: null,
      breadcrumbs: [
        {
          text: 'Data Dictionary',
          disabled: false,
          href: '/DataDictionary',
        },
        {
          text: 'Add Signal',
          disabled: true,
          href: '',
        },
      ],
      dbTypes: [
        { state: 'Sample B Data', abbr: 'sampleBData' },
        { state: 'EX One Minute', abbr: 'exOneMinute' },
        { state: 'Ex Telemetry', abbr: 'ExTelemetry' },
      ],
      signal: [],
      controllerState: [],
      bitField: [],
      sourceTypes: [],
      unitsTypes: [],
      signalTypes: [],
      newSignal: {
        src_id: null,
        column_name: '',
        signal: '',
        comment: '',
        adx_db_table: { state: 'Sample B Data', abbr: 'sampleBData' },
        signal_type_id: { state: 'state', abbr: 9 },
        units_id: null,
        restricted: true,
        max: null,
        min: null,
        publish: false,
        store: false,
        truck_type: 'ERX',
        choices: [],
      },
    };
  },

  computed: {
    ...mapGetters({
      userRoles: 'getUserRoles',
      storedSignal: 'getSignal',
      isEditSignal: 'getIsEditSignal',
    }),
  },
  async mounted() {
    try {
      this.newSignal = { ...this.storedSignal };
      const dropdown = await getDropdown();
      this.setDropdown(dropdown.data);
      if (this.isEditSignal) {
        if (this.newSignal.choices.length) {
          this.newSignal['choices'] = this.newSignal.choices.map((ele) => {
            if (this.newSignal.signal_type_id === 'State') {
              ele.state = ele.key;
            } else {
              ele.mask = ele.key;
            }
            return ele;
          });
          this.bitField = this.newSignal.choices;
          this.controllerState = this.newSignal.choices;
        }
        this.newSignal['units_id'] = this.unitsTypes.find(
          (ele) => this.storedSignal.units_id == ele.state
        );
        this.newSignal['signal_type_id'] = this.signalTypes.find(
          (ele) => this.storedSignal.signal_type_id == ele.state
        );
      }
    } catch (e) {
      console.log(e, 'error in retrieving the source or signal dictionary');
    }
  },
  async created() {},
  methods: {
    ...mapMutations(['updateSignal', 'updateIsEditSignal']),
    mapDropdowns(dropdown) {
      return dropdown.map((ele) => {
        return { state: ele.name, abbr: ele.id };
      });
    },
    setDropdown(dropdown) {
      this.dropdown = dropdown;
      this.signalTypes = this.mapDropdowns(dropdown.signalType);
      this.sourceTypes = this.mapDropdowns(dropdown.source);
      this.unitsTypes = this.mapDropdowns(dropdown.units);
    },
    getData: async function () {
      try {
        const dropdown = await getDropdown();
        this.setDropdown(dropdown.data);
      } catch (e) {
        console.log(e, 'error in retrieving the source or signal dictionary');
      }
    },
    deleteMaskState(mask, item) {
      if (mask) {
        this.bitField.splice(
          this.bitField.findIndex((e) => e.text == item.text),
          1
        );
      } else {
        this.controllerState.splice(
          this.controllerState.findIndex((e) => e.value == item.value),
          1
        );
      }
    },
    sendSignalDictionaryForm: async function () {
      try {
        const newSignalDataMapped = this.mapAddSignal(
          this.newSignal,
          this.bitField,
          this.controllerState
        );
        let response;
        if (this.isEditSignal) {
          response = await putSignal(newSignalDataMapped, this.isEditSignal);
        } else {
          response = await addSignal(newSignalDataMapped);
        }
        if (response) {
          this.getData();
          this.closeSignalDictionaryForm();
        }
      } catch (e) {
        console.log(
          e,
          'Error: something went wrong while trying to save the Signal'
        );
      }
    },
    mapAddSignal(signalData, bitField, controllerState) {
      const src_id_abbr = signalData.src_id?.abbr;
      const signal_type_idAbbr = signalData.signal_type_id?.abbr;
      const adx_db_tableAbbr = signalData.adx_db_table?.abbr;
      const units_id_abbr = signalData.units_id?.abbr;
      if (
        signalData.signal_type_id &&
        signalData.signal_type_id.abbr == 8 &&
        bitField.length > 0
      ) {
        signalData.choices = bitField.map((ele) => {
          ele.key = ele.mask;
          return ele;
        });
      } else if (
        signalData.signal_type_id &&
        signalData.signal_type_id.abbr == 9 &&
        controllerState.length > 0
      ) {
        signalData.choices = controllerState.map((ele) => {
          ele.key = ele.state;
          return ele;
        });
      }
      signalData.src_id = src_id_abbr;
      signalData.signal_type_id = signal_type_idAbbr;
      signalData.adx_db_table = adx_db_tableAbbr;
      signalData.units_id = units_id_abbr;
      return signalData;
    },
    closeSignalDictionaryForm() {
      this.clearNewSignal();
      this.$router.push(`/DataDictionary`);
    },
    sendDictionaryModal: function () {
      if (
        this.newSignal.signal_type_id &&
        this.newSignal.signal_type_id.abbr == 8
      ) {
        this.bitField.push({
          mask: this.dictionaryModal.mask,
          value: this.dictionaryModal.value,
        });
      } else if (
        this.newSignal.signal_type_id &&
        this.newSignal.signal_type_id.abbr == 9
      ) {
        this.controllerState.push({
          state: this.dictionaryModal.state,
          value: this.dictionaryModal.value,
        });
      }
      this.closeDictionaryModal();
    },
    setDictionaryModal(dictionaryModal) {
      this.dictionaryModalOpen = dictionaryModal;
    },
    openDictionaryModal() {
      this.setDictionaryModal(true);
      this.clearDictionaryModal();
    },
    closeDictionaryModal() {
      this.setDictionaryModal(false);
      this.clearDictionaryModal();
    },
    clearDictionaryModal() {
      let dictionaryModal = {
        open: false,
        state: '',
        mask: '',
        value: '',
      };
      this.dictionaryModal = Object.assign(
        {},
        this.dictionaryModal,
        dictionaryModal
      );
    },
    clearNewSignal() {
      const dbOption = this.dbTypes.find(
        (ele) => ele.abbr === this.newSignal.adx_db_table
      );
      let newSignal = {
        src_id: null,
        column_name: '',
        signal: '',
        comment: '',
        adx_db_table: dbOption,
        signal_type_id: { state: 'state', abbr: 9 },
        units_id: null,
        restricted: true,
        max: null,
        min: null,
        publish: false,
        store: false,
        truck_type: 'ERX',
        choices: [],
      };
      this.clearTables();
      this.updateIsEditSignal(null);
      this.updateSignal(newSignal);
    },
    clearTables() {
      this.bitfield = [];
      this.controllerState = [];
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media only screen and (min-width: 960px) {
  .toolbar {
    margin-top: 10px;
  }
  .primary-button {
    background-color: #48ff48;
  }
  .secondary-button {
    border-color: #48ff48;
  }
}
</style>
