<template>
  <div :id="`odom${id}`"></div>
</template>

<script>
import Odometer from 'odometer/odometer.min.js';
import 'odometer/themes/odometer-theme-car.css';
import 'odometer/themes/odometer-theme-car2.css';
import 'odometer/themes/odometer-theme-car3.css';

export default {
  name: 'Odometer',
  props: {
    value: { type: Number, default: () => 0 },
    theme: { type: String, default: () => 'car2' },
    format: { type: String, default: () => 'd' },
    duration: { type: Number, default: () => 3000 },
    className: { type: String, default: () => 'odometer' },
    animation: { type: String, default: () => 'slide' },
    minIntegerLen: { type: Number, default: () => 0 },
    formatFunction: { type: Function },
  },
  data: () => ({
    id: Math.random().toString(36).slice(2) + Date.now().toString(36),
    instance: null,
  }),
  computed: {
    animationTime() {
      return String(this.duration / 1000 + 's');
    },
  },
  watch: {
    value: {
      handler(value) {
        if (this.instance && this.instance.update) {
          this.instance.update(value);
        }
      },
      deep: false,
    },
  },
  mounted() {
    this.setupAnimations();
    this.createOdometer();
  },
  methods: {
    setupAnimations() {
      const sheet = document.createElement('style');
      sheet.innerHTML = `
      .odometer.odometer-auto-theme.odometer-animating-down.odometer-animating
      .odometer-ribbon-inner,
      .odometer.odometer-theme-${this.theme}.odometer-animating-down.odometer-animating
        .odometer-ribbon-inner {
        -webkit-transition: -webkit-transform ${this.animationTime};
        -moz-transition: -moz-transform ${this.animationTime};
        -ms-transition: -ms-transform ${this.animationTime};
        -o-transition: -o-transform ${this.animationTime};
        transition: transform ${this.animationTime};
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
      }
      .odometer.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner,
      .odometer.odometer-theme-${this.theme}.odometer-animating-up .odometer-ribbon-inner {
        -webkit-transition: -webkit-transform ${this.animationTime};
        -moz-transition: -moz-transform ${this.animationTime};
        -ms-transition: -ms-transform ${this.animationTime};
        -o-transition: -o-transform ${this.animationTime};
        transition: transform ${this.animationTime};
      }`;
      document.body.appendChild(sheet);
    },
    createOdometer() {
      const el = document.getElementById(`odom${this.id}`);
      this.instance = new Odometer({
        el: el,
        value: this.value,
        theme: this.theme,
        format: this.format,
        duration: this.duration,
        animation: this.animation,
        minIntegerLen: this.minIntegerLen,
      });
      this.instance.render();
    },
  },
};
</script>
