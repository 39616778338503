var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-8 ml-8 mr-8", attrs: { id: "user-settings" } },
    [
      _vm.loading ? _c("loader") : _vm._e(),
      _c(
        "v-container",
        { staticClass: "pr-0", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { staticClass: "mt-0" },
            [
              _c("v-col", { staticClass: "pl-0" }, [
                _c("h1", [_vm._v("Settings")]),
              ]),
              _c("v-col", [_c("v-spacer")], 1),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mt-3 mb-5 secondary" },
            [
              _c(
                "v-col",
                {
                  staticStyle: { "background-color": "#272727" },
                  attrs: { xs: "12", sm: "6", md: "6", lg: "9", xl: "9" },
                },
                [
                  _vm._v(" Unit Format"),
                  _c("br"),
                  _c("span", { staticClass: "secondary-text" }, [
                    _vm._v("Select regional preference"),
                  ]),
                ]
              ),
              _c(
                "v-col",
                {
                  staticStyle: { "background-color": "#272727" },
                  attrs: {
                    xs: "12",
                    sm: "6",
                    md: "6",
                    lg: "3",
                    xl: "3",
                    align: "right",
                  },
                },
                [
                  _c(
                    "v-tabs",
                    {
                      staticStyle: { "border-radius": "5px" },
                      attrs: {
                        "fixed-tabs": "",
                        right: "",
                        "background-color": "secondary",
                      },
                      model: {
                        value: _vm.regionTab,
                        callback: function ($$v) {
                          _vm.regionTab = $$v
                        },
                        expression: "regionTab",
                      },
                    },
                    [
                      _c("v-tabs-slider", { attrs: { color: "black" } }),
                      _vm._l(_vm.regionTabs, function (tab) {
                        return _c("v-tab", { key: tab.id }, [
                          _vm._v(_vm._s(tab.name)),
                        ])
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mt-0 mb-5 secondary" },
            [
              _c(
                "v-col",
                {
                  staticStyle: { "background-color": "#272727" },
                  attrs: { xs: "12", sm: "6", md: "6", lg: "10", xl: "10" },
                },
                [
                  _vm._v(" Date Format"),
                  _c("br"),
                  _c("span", { staticClass: "secondary-text" }, [
                    _vm._v("Select global date format preference"),
                  ]),
                ]
              ),
              _c(
                "v-col",
                {
                  staticStyle: { "background-color": "#272727" },
                  attrs: {
                    xs: "12",
                    sm: "6",
                    md: "6",
                    lg: "2",
                    xl: "2",
                    align: "right",
                  },
                },
                [
                  _c("v-select", {
                    staticStyle: { height: "55px" },
                    attrs: { items: _vm.dateItems, outlined: "" },
                    model: {
                      value: _vm.datePref,
                      callback: function ($$v) {
                        _vm.datePref = $$v
                      },
                      expression: "datePref",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mt-0 mb-5 secondary" },
            [
              _c(
                "v-col",
                {
                  staticStyle: { "background-color": "#272727" },
                  attrs: { xs: "12", sm: "6", md: "6", lg: "9", xl: "9" },
                },
                [
                  _vm._v(" Time Format"),
                  _c("br"),
                  _c("span", { staticClass: "secondary-text" }, [
                    _vm._v("Select global time format preference"),
                  ]),
                ]
              ),
              _c(
                "v-col",
                {
                  staticStyle: { "background-color": "#272727" },
                  attrs: {
                    xs: "12",
                    sm: "6",
                    md: "6",
                    lg: "3",
                    xl: "3",
                    align: "right",
                  },
                },
                [
                  _c("v-select", {
                    staticStyle: { height: "55px" },
                    attrs: { items: _vm.timeItems, outlined: "" },
                    model: {
                      value: _vm.timePref,
                      callback: function ($$v) {
                        _vm.timePref = $$v
                      },
                      expression: "timePref",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mt-0 mb-5 secondary" },
            [
              _c(
                "v-col",
                {
                  staticStyle: { "background-color": "#272727" },
                  attrs: { xs: "12", sm: "6", md: "6", lg: "8", xl: "8" },
                },
                [
                  _vm._v(" Time Zone"),
                  _c("br"),
                  _c("span", { staticClass: "secondary-text" }, [
                    _vm._v("Select global time zone preference"),
                  ]),
                ]
              ),
              _c(
                "v-col",
                {
                  staticStyle: { "background-color": "#272727" },
                  attrs: {
                    xs: "12",
                    sm: "6",
                    md: "6",
                    lg: "4",
                    xl: "4",
                    align: "right",
                  },
                },
                [
                  _c("v-select", {
                    staticStyle: { height: "55px" },
                    attrs: {
                      items: _vm.timeZoneItems,
                      "item-text": "utc",
                      "return-object": "",
                      outlined: "",
                    },
                    model: {
                      value: _vm.timeZonePref,
                      callback: function ($$v) {
                        _vm.timeZonePref = $$v
                      },
                      expression: "timeZonePref",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mt-0 secondary" },
            [
              _c(
                "v-col",
                { staticClass: "pl-0 pt-0", attrs: { cols: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "pl-2",
                      attrs: { plain: "", color: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.routeBack()
                        },
                      },
                    },
                    [_vm._v("Go Back")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticStyle: { color: "black" },
                      attrs: { color: "primary", disabled: _vm.loading },
                      on: { click: _vm.updateUserPrefs },
                    },
                    [_vm._v("Update")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }