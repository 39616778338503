var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-snackbar",
        {
          attrs: { color: _vm.snackColor },
          model: {
            value: _vm.snack,
            callback: function ($$v) {
              _vm.snack = $$v
            },
            expression: "snack",
          },
        },
        [_vm._v(_vm._s(_vm.snackMessage))]
      ),
      _c(
        "v-row",
        {
          staticStyle: { margin: "10px" },
          attrs: { justify: "space-between" },
        },
        [
          _c("h1", { staticStyle: { "font-weight": "400" } }, [
            _vm._v("Feedback"),
          ]),
          _c(
            "v-btn",
            {
              staticClass: "mr-1",
              staticStyle: { color: "black", height: "48px" },
              attrs: { color: "primary" },
              on: { click: _vm.exportCSV },
            },
            [
              _c(
                "download-csv",
                {
                  attrs: {
                    id: "csv",
                    name: "hub_feedback.csv",
                    data: _vm.feedback,
                  },
                },
                [
                  _c("v-icon", { attrs: { id: "csv2" } }, [
                    _vm._v("mdi-download"),
                  ]),
                  _vm._v(" Download CSV "),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-data-table", {
        staticStyle: { cursor: "pointer" },
        attrs: {
          headers: _vm.headers,
          items: _vm.feedback,
          "sort-by": ["created_at"],
          "custom-sort": _vm.customSort,
        },
        on: { "click:row": _vm.rowClicked },
      }),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": 1000 },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v(" " + _vm._s(_vm.cardTitle) + " ")]),
              _c(
                "v-card-text",
                { staticClass: "feedbackText" },
                [
                  _c("v-icon", { staticClass: "iconOffset" }, [
                    _vm._v("mdi-format-quote-open"),
                  ]),
                  _vm._v(" " + _vm._s(_vm.cardFeedback) + " "),
                  _c("v-icon", { staticClass: "iconOffset" }, [
                    _vm._v("mdi-format-quote-close"),
                  ]),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error", dark: "" },
                      on: { click: _vm.deleteRow },
                    },
                    [
                      _c("v-icon", { staticStyle: { "margin-right": "5px" } }, [
                        _vm._v("mdi-trash-can"),
                      ]),
                      _vm._v(" Delete "),
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { dark: "" },
                      on: {
                        click: function ($event) {
                          _vm.dialog = false
                        },
                      },
                    },
                    [_vm._v(" Close ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }