var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.authenticated
    ? _c(
        "div",
        { attrs: { id: "app" } },
        [
          _c(
            "v-app",
            { attrs: { id: "inspire", dark: "" } },
            [
              _vm.tacDialog
                ? [
                    _c(
                      "div",
                      { staticClass: "text-center" },
                      [
                        _c(
                          "v-dialog",
                          {
                            directives: [
                              {
                                name: "click-outside",
                                rawName: "v-click-outside",
                                value: () => (_vm.clickedOutside = true),
                                expression: "() => (clickedOutside = true)",
                              },
                            ],
                            attrs: { width: "500", persistent: "" },
                            model: {
                              value: _vm.tacDialog,
                              callback: function ($$v) {
                                _vm.tacDialog = $$v
                              },
                              expression: "tacDialog",
                            },
                          },
                          [
                            _c(
                              "v-card",
                              [
                                _c(
                                  "v-card-title",
                                  {
                                    staticClass: "text-h5 primary pa-3",
                                    staticStyle: { color: "black" },
                                  },
                                  [_vm._v(" Hyliion Hub Beta ")]
                                ),
                                _c("v-card-text", { staticClass: "pa-3" }, [
                                  _vm._v(
                                    " You are about to access a Beta version of the Hyliion Hub. Your company has signed a Hub Beta Agreement with Hyliion for access to this application during your Extended Fleet Trial. All the terms laid out in the Hub Beta Agreement apply while using this product. "
                                  ),
                                  _vm.clickedOutside
                                    ? _c(
                                        "span",
                                        { staticClass: "color-red pt-1" },
                                        [
                                          _vm._v(
                                            "You must accept the Terms and Conditions in order to continue"
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                                _c("v-divider"),
                                _c(
                                  "v-card-actions",
                                  [
                                    _c("v-spacer"),
                                    _c(
                                      "v-btn",
                                      {
                                        staticStyle: { color: "black" },
                                        attrs: { color: "primary" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.clickAcceptTAC()
                                          },
                                        },
                                      },
                                      [_vm._v(" I accept ")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                : [
                    _c(
                      "v-navigation-drawer",
                      {
                        staticClass: "shade",
                        attrs: {
                          "mini-variant": _vm.mini,
                          clipped: "",
                          permanent: "",
                          app: "",
                        },
                        on: {
                          "update:miniVariant": function ($event) {
                            _vm.mini = $event
                          },
                          "update:mini-variant": function ($event) {
                            _vm.mini = $event
                          },
                        },
                      },
                      [
                        _c(
                          "v-list-item-group",
                          { attrs: { "active-class": "bg-active" } },
                          _vm._l(_vm.generateRoutes, function (item, i) {
                            return _c(
                              "div",
                              { key: i },
                              [
                                _c(
                                  "v-list-item",
                                  {
                                    attrs: {
                                      to: item.to,
                                      router: "",
                                      exact: "",
                                      dark: "",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-list-item-action",
                                      [
                                        _c(
                                          "v-icon",
                                          { staticClass: "active" },
                                          [_vm._v(_vm._s(item.icon))]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c("v-list-item-title", [
                                          _vm._v(_vm._s(item.title)),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm.checkDivisibleByFour(i)
                                  ? _c("v-divider", { key: `divider-${i}` })
                                  : _vm._e(),
                              ],
                              1
                            )
                          }),
                          0
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-app-bar",
                      { attrs: { "clipped-left": "", fixed: "", app: "" } },
                      [
                        _c("v-app-bar-nav-icon", {
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.closeOpenDrawer()
                            },
                          },
                        }),
                        _c("v-img", {
                          staticClass: "mx-2",
                          attrs: {
                            src: require("../public/images/logos/hub.svg"),
                            "max-width": "130px",
                            "max-height": "32px",
                            contain: "",
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.closeOpenDrawer()
                            },
                          },
                        }),
                        _c(
                          "v-app-bar-nav-icon",
                          {
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.closeOpenDrawer()
                              },
                            },
                          },
                          [
                            _c("v-icon", [
                              _vm._v(
                                _vm._s(
                                  _vm.mini
                                    ? "mdi-chevron-right"
                                    : "mdi-chevron-left"
                                )
                              ),
                            ]),
                          ],
                          1
                        ),
                        _c("v-spacer"),
                        _vm.isTruckDetails
                          ? _c(
                              "v-tabs",
                              {
                                model: {
                                  value: _vm.tab,
                                  callback: function ($$v) {
                                    _vm.tab = $$v
                                  },
                                  expression: "tab",
                                },
                              },
                              [
                                _c(
                                  "v-tab",
                                  {
                                    attrs: {
                                      to: `/health/${_vm.detailsID}/details`,
                                    },
                                  },
                                  [_vm._v("Details")]
                                ),
                                _c(
                                  "v-tab",
                                  {
                                    attrs: {
                                      to: `/health/${_vm.detailsID}/dtc`,
                                    },
                                  },
                                  [_vm._v("DTC")]
                                ),
                                _c(
                                  "v-tab",
                                  {
                                    attrs: {
                                      to: `/health/${_vm.detailsID}/service`,
                                    },
                                  },
                                  [_vm._v("Service")]
                                ),
                                _c(
                                  "v-tab",
                                  {
                                    attrs: {
                                      to: `/health/${_vm.detailsID}/map`,
                                    },
                                  },
                                  [_vm._v("Map")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "v-menu",
                          {
                            staticStyle: { "z-index": "13" },
                            attrs: {
                              "offset-y": "",
                              transition: "slide-x-transition",
                              bottom: "",
                              right: "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              class: !_vm.isHyliionUser
                                                ? "non-hyliion"
                                                : "",
                                              attrs: { plain: "" },
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-avatar",
                                            {
                                              attrs: {
                                                size: "36px",
                                                color: "primary",
                                              },
                                            },
                                            [
                                              _c("span", {
                                                staticClass: "pb-1",
                                                staticStyle: { color: "black" },
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.getUserInitials()
                                                  ),
                                                },
                                              }),
                                            ]
                                          ),
                                          _c("span", { staticClass: "pl-1" }, [
                                            _vm._v(_vm._s(_vm.userName)),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2421319529
                            ),
                          },
                          [
                            _vm.isHyliionUser
                              ? _c(
                                  "v-list",
                                  { attrs: { dense: "" } },
                                  [
                                    _c(
                                      "v-list-item-group",
                                      _vm._l(
                                        _vm.settingsItems,
                                        function (option, index) {
                                          return _c(
                                            "v-list-item",
                                            {
                                              key: index,
                                              on: {
                                                click: function ($event) {
                                                  return _vm.selectSettingOption(
                                                    option.title
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-list-item-icon",
                                                { staticClass: "mr-2" },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticStyle: {
                                                        opacity: "0.6",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(option.icon)
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  _c("v-list-item-title", {
                                                    staticClass:
                                                      "text-body-1 opacity: 0.6",
                                                    staticStyle: {
                                                      "line-height": "1.5rem",
                                                    },
                                                  }),
                                                  _vm._v(
                                                    _vm._s(option.title) + " "
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-main",
                      { staticClass: "secondary" },
                      [
                        _c("global-snack"),
                        _vm.userRoles ? _c("router-view") : _vm._e(),
                        _c(
                          "v-btn",
                          {
                            staticClass: "fbtn",
                            class: _vm.mini ? "mini-fbtn" : "expanded-fbtn",
                            staticStyle: { background: "#2b8f14" },
                            attrs: { fab: "", dark: "" },
                            on: {
                              click: function ($event) {
                                _vm.showFeedback = true
                              },
                            },
                          },
                          [
                            _c("v-icon", { staticStyle: { color: "black" } }, [
                              _vm._v("mdi-forum-outline"),
                            ]),
                          ],
                          1
                        ),
                        _c("FeedbackModal", {
                          attrs: {
                            show: _vm.showFeedback,
                            toggle: _vm.toggleFeedback,
                          },
                        }),
                        _vm.showLiveFleetView
                          ? _c("LiveFleetView", {
                              on: {
                                close: function ($event) {
                                  _vm.showLiveFleetView = false
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
            ],
            2
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }