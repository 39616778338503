var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { "grid-list-md": "", fluid: "" } },
    [
      _c(
        "v-navigation-drawer",
        {
          staticClass: "shade",
          attrs: { right: "", temporary: "", fixed: "", width: 360 },
          model: {
            value: _vm.drawer,
            callback: function ($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer",
          },
        },
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                { attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "mb-2", attrs: { align: "right" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function ($event) {
                              _vm.drawer = false
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { light: "" } }, [
                            _vm._v("mdi-close-circle "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-expansion-panels",
                {
                  attrs: { multiple: "" },
                  model: {
                    value: _vm.panel,
                    callback: function ($$v) {
                      _vm.panel = $$v
                    },
                    expression: "panel",
                  },
                },
                [
                  _c(
                    "v-expansion-panel",
                    { staticClass: "shade" },
                    [
                      _c("v-expansion-panel-header", [
                        _vm._v("Generate Report"),
                      ]),
                      _c(
                        "v-expansion-panel-content",
                        [
                          _c("p", [_vm._v("Truck Type:")]),
                          _c(
                            "v-radio-group",
                            {
                              attrs: { disabled: _vm.truckTypeDisabled },
                              model: {
                                value: _vm.truckType,
                                callback: function ($$v) {
                                  _vm.truckType = $$v
                                },
                                expression: "truckType",
                              },
                            },
                            [
                              _c("v-radio", {
                                key: "0",
                                attrs: { label: "EX", value: "EX" },
                              }),
                              _c("v-radio", {
                                key: "1",
                                attrs: { label: "ERX", value: "ERX" },
                              }),
                            ],
                            1
                          ),
                          _c("p", [_vm._v("One row per:")]),
                          _c(
                            "v-radio-group",
                            {
                              model: {
                                value: _vm.rowDef,
                                callback: function ($$v) {
                                  _vm.rowDef = $$v
                                },
                                expression: "rowDef",
                              },
                            },
                            [
                              _c("v-radio", {
                                key: 0,
                                attrs: { label: "Day", value: 0 },
                              }),
                              _c("v-radio", {
                                key: 1,
                                attrs: { label: "Week", value: 1 },
                              }),
                              _c("v-radio", {
                                key: 2,
                                attrs: { label: "Month", value: 2 },
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "center" }, [
                            _c("p", [_vm._v("Date Range:")]),
                          ]),
                          _c(
                            "v-menu",
                            {
                              ref: "menu",
                              attrs: {
                                "close-on-content-click": false,
                                "return-value": _vm.dates,
                              },
                              on: {
                                "update:returnValue": function ($event) {
                                  _vm.dates = $event
                                },
                                "update:return-value": function ($event) {
                                  _vm.dates = $event
                                },
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "v-text-field",
                                        _vm._b(
                                          {
                                            directives: [
                                              {
                                                name: "mask",
                                                rawName: "v-mask",
                                                value:
                                                  "##/##/#### - ##/##/####",
                                                expression:
                                                  "'##/##/#### - ##/##/####'",
                                              },
                                            ],
                                            attrs: {
                                              type: "text",
                                              outlined: "",
                                              "append-icon": "mdi-calendar",
                                            },
                                            on: { "click:append": on.click },
                                            model: {
                                              value: _vm.dateInput,
                                              callback: function ($$v) {
                                                _vm.dateInput = $$v
                                              },
                                              expression: "dateInput",
                                            },
                                          },
                                          "v-text-field",
                                          attrs,
                                          false
                                        )
                                      ),
                                    ]
                                  },
                                },
                              ]),
                              model: {
                                value: _vm.menu,
                                callback: function ($$v) {
                                  _vm.menu = $$v
                                },
                                expression: "menu",
                              },
                            },
                            [
                              _c(
                                "v-date-picker",
                                {
                                  attrs: { range: "" },
                                  model: {
                                    value: _vm.dates,
                                    callback: function ($$v) {
                                      _vm.dates = $$v
                                    },
                                    expression: "dates",
                                  },
                                },
                                [
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "", color: "primary" },
                                      on: {
                                        click: function ($event) {
                                          _vm.menu = false
                                        },
                                      },
                                    },
                                    [_vm._v(" Cancel ")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "", color: "primary" },
                                      on: { click: _vm.saveDates },
                                    },
                                    [_vm._v(" OK ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("div", { attrs: { id: "dateGuide" } }, [
                            _vm._v("MM/DD/YYYY - MM/DD/YYYY"),
                          ]),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-select", {
                                    attrs: {
                                      items: _vm.companies,
                                      "item-text": "name",
                                      label: "Company",
                                      outlined: "",
                                      "return-object": "",
                                    },
                                    model: {
                                      value: _vm.company,
                                      callback: function ($$v) {
                                        _vm.company = $$v
                                      },
                                      expression: "company",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("v-select", {
                            attrs: {
                              items: _vm.viewableTrucks,
                              label: "Trucks",
                              multiple: "",
                              chips: "",
                              height: "56px",
                              outlined: "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "prepend-item",
                                fn: function () {
                                  return [
                                    _c(
                                      "v-list-item",
                                      {
                                        on: {
                                          click: _vm.toggleSelectAllTrucks,
                                        },
                                      },
                                      [
                                        _c(
                                          "v-list-item-action",
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  color: _vm.allTrucksSelected
                                                    ? "primary"
                                                    : "",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.truckIcon) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-list-item-content",
                                          [
                                            _c("v-list-item-title", [
                                              _vm._v(" Select All "),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c("v-divider", { staticClass: "mt-2" }),
                                  ]
                                },
                                proxy: true,
                              },
                              {
                                key: "selection",
                                fn: function ({ item }) {
                                  return [
                                    _c("v-chip", { attrs: { readonly: "" } }, [
                                      _vm._v(_vm._s(item)),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.selectedTrucks,
                              callback: function ($$v) {
                                _vm.selectedTrucks = $$v
                              },
                              expression: "selectedTrucks",
                            },
                          }),
                          _vm.reportLoading
                            ? _c("v-progress-circular", {
                                staticClass: "center",
                                attrs: { indeterminate: "", color: "primary" },
                              })
                            : _c(
                                "v-btn",
                                {
                                  staticClass: "center",
                                  attrs: {
                                    color: "primary",
                                    disabled: _vm.disabledStatus,
                                  },
                                  on: { click: _vm.generateReport },
                                },
                                [_vm._v("Generate Report")]
                              ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.errorMessage
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("v-alert", { attrs: { type: "error" } }, [
                    _vm._v(" " + _vm._s(_vm.errorMessage) + " "),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        { attrs: { align: "center" } },
        [
          _c("v-col", { attrs: { cols: "10" } }, [
            _c("h1", { staticStyle: { "font-weight": "400" } }, [
              _vm._v(
                " " +
                  _vm._s(_vm.truckTypeFinal) +
                  " Trip Reports " +
                  _vm._s(_vm.readableRange) +
                  " "
              ),
            ]),
          ]),
          _c(
            "v-col",
            { attrs: { align: "right" } },
            [
              _c(
                "v-btn",
                {
                  staticStyle: {
                    "background-color": "#1a1a1a",
                    "border-radius": "5px",
                  },
                  attrs: { icon: "", tile: "", large: "" },
                  on: {
                    click: function ($event) {
                      _vm.drawer = true
                    },
                  },
                },
                [
                  _c("v-icon", { staticStyle: { opacity: "0.6" } }, [
                    _vm._v("mdi-cog"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.truckTypeFinal === "ERX"
        ? _c(
            "v-col",
            { staticStyle: { "margin-bottom": "-20px" } },
            [
              _c(
                "v-card",
                {
                  staticClass: "pa-2 error",
                  staticStyle: { "text-align": "center", color: "white" },
                  attrs: { elevation: "4" },
                },
                [
                  _c("span", [
                    _vm._v(
                      "WARNING: There are known issues with ERX cloud data at this time, and as a result the aggregations are unreliable"
                    ),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-col",
        [
          _c(
            "v-row",
            { staticClass: "mt-4" },
            [
              _c(
                "v-col",
                {
                  attrs: {
                    cols: "4",
                    xs: "12",
                    sm: "12",
                    md: "12",
                    lg: "4",
                    xl: "4",
                  },
                },
                [
                  _c(
                    "v-tabs",
                    {
                      attrs: {
                        "fixed-tabs": "",
                        "background-color": "secondary",
                      },
                    },
                    [
                      _c(
                        "v-tab",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.changeTab("Usage")
                            },
                          },
                        },
                        [_vm._v("Usage")]
                      ),
                      _c(
                        "v-tab",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.changeTab("Graphs")
                            },
                          },
                        },
                        [_vm._v("Graphs")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "hidden-sm-and-down" },
                [_c("v-spacer")],
                1
              ),
              _vm.exportData && _vm.switchTab != "Graphs"
                ? _c(
                    "v-btn",
                    {
                      staticStyle: { color: "black", "margin-top": "18px" },
                      attrs: { color: "primary" },
                      on: { click: _vm.exportCSV },
                    },
                    [
                      _vm.exportData
                        ? _c(
                            "download-csv",
                            {
                              attrs: {
                                id: "csv",
                                name:
                                  _vm.truckTypeFinal === "EX"
                                    ? "ex_trips.csv"
                                    : "erx_trips.csv",
                                data: _vm.exportData,
                              },
                            },
                            [
                              _c(
                                "v-icon",
                                { staticClass: "pr-1", attrs: { id: "csv2" } },
                                [_vm._v("mdi-download")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" Export "),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.switchTab === "Graphs"
                ? _c(
                    "v-col",
                    {
                      staticStyle: { "margin-top": "-10px" },
                      attrs: { md: "12", lg: "3", align: "right" },
                    },
                    [
                      _vm.truckTypeFinal === "EX"
                        ? _c("v-select", {
                            attrs: {
                              items: _vm.viewableGraphs,
                              label: "View Chart",
                              multiple: "",
                              chips: "",
                              height: "68px",
                              outlined: "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "prepend-item",
                                  fn: function () {
                                    return [
                                      _c(
                                        "v-list-item",
                                        {
                                          attrs: { ripple: "" },
                                          on: {
                                            mousedown: function ($event) {
                                              $event.preventDefault()
                                            },
                                            click: _vm.toggleSelectAll,
                                          },
                                        },
                                        [
                                          _c(
                                            "v-list-item-action",
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    color: _vm.allSelected
                                                      ? "primary"
                                                      : "",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " + _vm._s(_vm.icon) + " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-list-item-title", [
                                                _vm._v(" Select All "),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("v-divider", { staticClass: "mt-2" }),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "selection",
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "v-chip",
                                        {
                                          attrs: { readonly: "" },
                                          on: {
                                            click: [
                                              function ($event) {
                                                return _vm.chipClicked(item)
                                              },
                                              function ($event) {
                                                $event.stopPropagation()
                                              },
                                            ],
                                          },
                                        },
                                        [_vm._v(_vm._s(item))]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2321690101
                            ),
                            model: {
                              value: _vm.selectedGraphs,
                              callback: function ($$v) {
                                _vm.selectedGraphs = $$v
                              },
                              expression: "selectedGraphs",
                            },
                          })
                        : _vm._e(),
                      _vm.truckTypeFinal === "ERX"
                        ? _c("v-select", {
                            attrs: {
                              items: _vm.erxViewableGraphs,
                              label: "View Chart",
                              multiple: "",
                              chips: "",
                              height: "68px",
                              outlined: "",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "prepend-item",
                                  fn: function () {
                                    return [
                                      _c(
                                        "v-list-item",
                                        {
                                          attrs: { ripple: "" },
                                          on: {
                                            mousedown: function ($event) {
                                              $event.preventDefault()
                                            },
                                            click: _vm.erxToggleSelectAll,
                                          },
                                        },
                                        [
                                          _c(
                                            "v-list-item-action",
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    color: _vm.erxAllSelected
                                                      ? "primary"
                                                      : "",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.erxIcon) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-content",
                                            [
                                              _c("v-list-item-title", [
                                                _vm._v(" Select All "),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("v-divider", { staticClass: "mt-2" }),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "selection",
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "v-chip",
                                        {
                                          attrs: { readonly: "" },
                                          on: {
                                            click: [
                                              function ($event) {
                                                return _vm.chipClicked(item)
                                              },
                                              function ($event) {
                                                $event.stopPropagation()
                                              },
                                            ],
                                          },
                                        },
                                        [_vm._v(_vm._s(item))]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              920498714
                            ),
                            model: {
                              value: _vm.erxSelectedGraphs,
                              callback: function ($$v) {
                                _vm.erxSelectedGraphs = $$v
                              },
                              expression: "erxSelectedGraphs",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-col",
            [
              _vm.truckTypeFinal === "EX"
                ? _c(
                    "KeepAlive",
                    [
                      _c(_vm.switchTab === "Usage" && "TripUsage", {
                        tag: "component",
                        attrs: { items: _vm.tData, loading: _vm.reportLoading },
                        on: { headers: _vm.getHeaders },
                      }),
                    ],
                    1
                  )
                : _c(
                    "KeepAlive",
                    [
                      _c(_vm.switchTab === "Usage" && "TripUsageERX", {
                        tag: "component",
                        attrs: { items: _vm.tData, loading: _vm.reportLoading },
                        on: { headers: _vm.getHeaders },
                      }),
                    ],
                    1
                  ),
            ],
            1
          ),
          _c(
            "v-col",
            [
              _c(
                "KeepAlive",
                [
                  _c(_vm.switchTab === "ESG" && "TripESG", {
                    tag: "component",
                    attrs: { items: _vm.tData, loading: _vm.reportLoading },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.switchTab === "Graphs"
            ? _c(
                "v-col",
                [
                  _vm.truckTypeFinal === "EX"
                    ? _c("TripGraphEX", {
                        attrs: {
                          data: _vm.graphData,
                          "selected-graphs": _vm.selectedGraphs,
                          loading: _vm.graphLoading,
                          "toggle-loading": _vm.toggleGraphLoading,
                        },
                      })
                    : _c("TripGraphERX", {
                        attrs: {
                          data: _vm.graphData,
                          graphs: new Set(_vm.erxSelectedGraphs),
                          loading: _vm.reportLoading,
                        },
                      }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }