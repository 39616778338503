<template>
  <v-container fluid>
    <v-row>
      <v-col cols="3" class="mb-1">
        <v-btn
          color="primary"
          style="color: black; height: 48px"
          @click="goBack"
          ><v-icon class="pr-1">mdi-arrow-u-left-top</v-icon>Back</v-btn
        >
      </v-col>
      <v-col align="left" class="mb-1"
        ><LiveFleetViewBanner
          v-if="liveView"
          :fleet-info="selectedCompany"
          @closed="liveView = false"
      /></v-col>
      <v-col cols="2" align="right" class="mb-1">
        <v-btn
          color="primary"
          style="color: black; height: 48px"
          @click="journeyEdit = true"
          >Edit<v-icon class="pr-1">mdi-pencil</v-icon></v-btn
        >
      </v-col>
    </v-row>
    <div style="background-color: #272727">
      <location-card
        class="lCard"
        :journey="journey"
        :journey-name="journeyName"
      ></location-card>
      <HereMap
        :map-height="480"
        zoom-location="bottom-right"
        :show-traffic="false"
        :set="(m) => (hereMap = m)"
        style="margin-bottom: 50px"
      />
      <vehicle-statistics
        :data="vehicleStats"
        :truck-name="truckName"
        :name-edit="userCanUpdateTruckName"
        style="position: absolute; z-index: 1; top: 70px; left: 56px"
        @openNameChange="changeTruckNameDialog = true"
      ></vehicle-statistics>
      <!-- placing here until we have a more defined layout matching the design -->
    </div>
    <div class="pieContainer mb-3">
      <pie-card
        v-if="driveEventsPie"
        title="Drive Events"
        :num-cols="2"
        :data="driveEventsPie"
      />
      <pie-card
        v-if="driveModesPie"
        title="Drive Modes"
        :data="driveModesPie"
      />
      <pie-card
        v-if="driveControlsPie"
        title="eRetarder"
        :data="driveControlsPie"
      />
    </div>
    <br />
    <br />
    <v-row>
      <v-col>
        <v-card color="#272727">
          <v-row class="cust-border mx-0">
            <v-col cols="12" align="left" class="ml-4">
              Journey Timeline
            </v-col>
          </v-row>
          <Gantt
            title=""
            :journey-start="journeyData.data.start"
            :journey-end="journeyData.data.end"
            :categories="timelineCategories"
            :data="timelineData"
            :tz-offset="tzOffset"
          />
          <!-- <Gantt
            v-if="faultData.length"
            title="Faults"
            :journey-start="journeyData.data.start"
            :journey-end="journeyData.data.end"
            :categories="faultCategoryData"
            :data="faultData"
          /> -->
        </v-card>
      </v-col>
    </v-row>
    <TruckNameChange
      v-if="changeTruckNameDialog"
      :company-id="companyId"
      :truck-id="truckId"
      @close="close"
    />
    <v-dialog v-model="journeyEdit" class="dialog" width="460">
      <v-card>
        <v-container>
          <v-row align="center">
            <v-col align="left" cols="6"><h3>Journey Name</h3></v-col>
            <v-col><v-spacer /></v-col>
            <v-col align="right" cols="3" class="pr-1">
              <v-btn icon @click="closeJourneyEdit()">
                <v-icon light>mdi-close-circle </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0" align="center" cols="12">
              <v-text-field
                v-model="journeyName"
                outlined
                label="Name"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0" align="center" cols="12"
              ><v-btn
                color="primary"
                style="color: black; width: 100%"
                :disabled="!journeyName"
                @click="changeJourneyName"
                >Save Change</v-btn
              ></v-col
            >
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Gantt from './GanttChart.vue';
import TruckNameChange from './TruckNameChange.vue';
import VehicleStatistics from './VehicleStatistics.vue';
import LocationCard from './LocationCard.vue';
import HereMap from '@/utilities/HereMap.vue';
import LiveFleetViewBanner from '@/utilities/LiveFleetViewBanner.vue';
import PieCard from './PieCard.vue';
import { getCompanyPreferences } from '@/api/external/company';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { permissions } from '@/api/permissions';
import { getJourney, updateJourneyName } from '@/api/external/journey';
import {
  constants as c,
  getCO2Reduction,
  getN2OReduction,
} from '@/utilities/constants.js';
import { getTimeZoneOffset } from '@/utilities/dateFunctions.js';

export default {
  name: 'JourneyDetails',
  components: {
    Gantt,
    TruckNameChange,
    VehicleStatistics,
    LocationCard,
    HereMap,
    LiveFleetViewBanner,
    PieCard,
  },
  props: {
    journeyData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      esgPreferences: null,
      hereMap: null,
      // toolbar values
      truckName: this.journeyData?.truck?.name,
      truckId: this.journeyData?.truck?.id,
      companyId: this.journeyData?.truck?.companyId,
      journey: {},
      journeyStartSegment: null,
      fuelEconomy: this.journeyData?.data?.statistics?.milesPerDGE,
      changeTruckNameDialog: false,
      timelineCategories: [
        'Drive Events',
        'Drive Modes',
        'eRetarder',
        'Cruise Control',
      ],
      faultCategories: [
        { name: 'Compressor', dtcName: 'eBrake Air Compressor Fault' },
        { name: 'eAxle', dtcName: 'eAxle Fault' },
        {
          name: 'Genset',
          dtcName: 'Engine Control Module Fault',
        },
        {
          name: 'Genset',
          dtcName: 'Fuel System Fault',
        },
        {
          name: 'Genset',
          dtcName: 'Generator Fault - Only EV Miles Available',
        },
        { name: 'Genset', dtcName: 'Genset Fault' },
        {
          name: 'Genset',
          dtcName: 'Genset Fault - Only EV Miles Available',
        },
        { name: 'High Voltage', dtcName: 'High Voltage System Fault' },
        { name: 'High Voltage', dtcName: 'High Voltage System Derated' },
        { name: 'HVAC', dtcName: 'HVAC System Fault' },
        {
          name: 'Hyliion Controller',
          dtcName: 'ADAS System Fault',
        },
        {
          name: 'Hyliion Controller',
          dtcName: 'Gateway Fault',
        },
        {
          name: 'Hyliion Controller',
          dtcName: 'Hyliion Control Unit Fault',
        },
        {
          name: 'Hyliion Controller',
          dtcName: 'Hyliion Drive Processor Fault',
        },
        {
          name: 'Hyliion Controller',
          dtcName: 'Power Up Fault',
        },
        { name: 'Low Voltage', dtcName: 'Low Voltage System Fault' },
        { name: 'Plug In Charging', dtcName: 'Charging System Fault' },
        { name: 'Power Steering', dtcName: 'ePower Steering System Fault' },
        { name: 'Thermal System', dtcName: 'Thermal System Fault' },
      ],
      faultCategoryData: [],
      faultData: [],
      timelineData: [],
      tzOffset: 0,
      liveView: false,
      journeyEdit: false,
      journeyName: '', // Assigns custom journey name to journey
      journeyType: 'automated',
      vehicleStats: {},
      driveEventsPie: null,
      driveModesPie: null,
      driveControlsPie: null,
      journeyFaults: null,
    };
  },
  computed: {
    ...mapGetters({
      spnfmiStates: 'getSpnfmiStates',
      userRoles: 'getUserRoles',
      selectedCompany: 'getSelectedLiveFleet',
      geozones: 'getGeozones',
      companyPreferences: 'getCompanyPreferences',
      userPreferences: 'getUserPreferences',
      companies: 'getCompanies',
    }),
    userCanUpdateTruckName() {
      return (
        this.userRoles &&
        (this.userRoles.includes(permissions.externalFleetConfig) ||
          this.userRoles.includes('admin'))
      );
    },
  },
  watch: {},
  async mounted() {
    await this.fetchFleetSettings();
    await this.getJourneyData();
    if (this.selectedCompany && this.selectedCompany.id !== 4)
      this.liveView = true;
    this.populateVehicleStats();
    this.populatePieCharts();
  },
  methods: {
    ...mapMutations(['updateTruckName', 'updateJourneyData']),
    ...mapActions(['updateSnack']),
    async getJourneyData() {
      try {
        if (this.companyPreferences?.journey_setting === 'destination') {
          this.journeyType = 'destination';
        }
        const data = this.journeyData?.data;
        const company = this.journeyData?.truck?.companyId;
        let checkGz = null;
        if (this.geozones.length && this.journeyType === 'destination') {
          checkGz = this.geozones.map((g) => g.id).join(',');
        }
        const { journeyInfos } = await getJourney(
          company,
          data?.start,
          data?.end,
          false,
          this.truckId,
          checkGz
        );
        this.journey = journeyInfos[0].journeys[0];
        // Checks if an assigned journey name exists
        if (this.journey.names.assignedName.length)
          this.journeyName = this.journey.names.assignedName;
      } catch {
        this.updateSnack({
          type: 'error',
          message: 'There was an issue retrieving journey details!',
        });
      }
      this.initGanttChartData();
      this.plotRoute();
    },
    initGanttChartData() {
      let segmentStartTime;
      let segmentEndTime;
      this.journeyFaults = 0;
      const tz = this.userPreferences.timeZonePref.canonical;
      this.journey.segments.forEach((s, i) => {
        let startDate = s.startLocation.telematics.time;
        let endDate = s.endLocation.telematics.time;
        if (i === 0) {
          // Retrieves the first segment id
          this.journeyStartSegment = s.id;
          this.tzOffset = getTimeZoneOffset(startDate, tz);
        } else {
          //Skip first index
          segmentStartTime = new Date(startDate).getTime();
          // 100 Millisecond difference
          if (segmentStartTime - segmentEndTime > 100) {
            // Adds offline event
            this.timelineData.push({
              name: 'Offline',
              start: segmentStartTime,
              end: segmentEndTime,
              color: '#FF6A33',
              y: 0, // Drive Events Index
            });
          }
        }
        segmentEndTime = new Date(endDate).getTime();
        //description: the event type - one of unknown -1, trip 0, idle 1,  accessory 2, break 3, off 4, idle_inactive 6, charging 7
        let { eventName, eventType, eventColor } = this.getEventType(s.type);
        if (eventName && eventColor) {
          // Drive Events
          this.timelineData.push({
            name: eventName,
            start: new Date(startDate).getTime(),
            end: new Date(endDate).getTime(),
            color: eventColor,
            y: eventType,
          });
        }
        // description: The event type - one of fuel 0, charge 1 , or dtc 2
        if (s.events.length) {
          s.events.forEach((e) => {
            let match = e?.event;
            if (e.type === 2) {
              let dtc = this.spnfmiStates.find(
                (d) => d.spnfmi === `${match.spn}-${match.fmi}`
              );
              if (dtc) {
                // Only increment dtc count if it is customer facing
                if (dtc.show_to_customer) {
                  this.journeyFaults += 1;
                }
                this.faultData.push({
                  name: dtc.dtc_name,
                  spnfmi: `${match.spn}-${match.fmi}`,
                  occurance_count: match.occurance_count,
                  start: new Date(match.timestamp).getTime(),
                  end: new Date(match.timestamp).getTime() + 1000 * 60,
                  color: '#F2B046',
                  y: this.matchFault(dtc),
                });
              } else {
                // When dtc does not match
              }
            } else {
              // Fuel/Charge Events, will add as we go
            }
          });
        }
        if (s.driveModes?.length) {
          s.driveModes.forEach((dm) => {
            let { dmName, dmType, dmColor } = this.getDriveMode(dm.mode);
            //Drive Modes
            if (dmName) {
              this.timelineData.push({
                name: dmName,
                start: dm.start,
                end: dm.end,
                color: dmColor,
                y: dmType,
              });
            }
          });
        }

        if (s.retarderLevels?.length) {
          s.retarderLevels.forEach((r) => {
            let { rName, rType, rColor } = this.getRetarderLevels(r.level);
            if (rName) {
              // Retarder
              this.timelineData.push({
                name: rName,
                start: r.start,
                end: r.end,
                color: rColor,
                y: rType,
              });
            }
          });
        }
        if (s.cruiseSections?.length) {
          s.cruiseSections.forEach((c) => {
            // Cruise Control
            this.timelineData.push({
              name: 'Cruise Control',
              start: c.start,
              end: c.end,
              color: '#FF9966',
              y: 3,
            });
          });
        }
      });
    },
    getEventType(type) {
      //description: the segment type - one of unknown -1, trip 0, idle 1,  accessory 2, break 3, off 4, idle_inactive 6, charging 7
      let eventName, eventType, eventColor;
      switch (type) {
        case 0:
          eventName = 'On The Road';
          eventType = 0;
          eventColor = '#006400';
          break;
        case 1:
          eventName = 'Idle';
          eventType = 0;
          eventColor = '#008080';
          break;
        case 2:
          eventName = 'Accessory';
          eventType = 0;
          eventColor = '#ADD8E6';
          break;
        case -1:
        case 3: // Break in data counts as offline
        case 4:
          eventName = 'Offline';
          eventType = 0;
          eventColor = '#FF6A33';
          break;
        case 6:
          eventName = 'Inactive Idle';
          eventType = 0;
          eventColor = '#E4B849';
          break;
        case 7:
          eventName = 'Charging';
          eventType = 0;
          eventColor = '#2CB42C';
          break;
        default:
          break;
      }
      return { eventName, eventType, eventColor };
    },
    getDriveMode(mode) {
      // description: The drive mode - one of ecoModeEng 0, ecoModeEV 1 , ecoChargePrep 2, or evMode 3
      let dmName, dmType, dmColor;
      switch (mode) {
        case 0:
        case 1:
          dmName = 'Automatic';
          dmType = 1;
          dmColor = '#3F3';
          break;
        case 2:
          dmName = 'Manual Charge';
          dmType = 1;
          dmColor = '#93F';
          break;
        case 3:
          dmName = 'Manual EV';
          dmType = 1;
          dmColor = '#6CF';
          break;
      }
      return { dmName, dmType, dmColor };
    },
    getRetarderLevels(level) {
      // description: The retarder level - one of Off 0, Level One 1 , or Level Two 2
      let rName, rType, rColor;
      switch (level) {
        case 0:
          rName = 'eRetarder - Nominal';
          rType = 2;
          rColor = '#D9D9D9';
          break;
        case 1:
          rName = 'eRetarder - Medium';
          rType = 2;
          rColor = '#396';
          break;
        case 2:
          rName = 'eRetarder - High';
          rType = 2;
          rColor = '#F91869';
          break;
      }
      return { rName, rType, rColor };
    },
    matchFault(dtc) {
      let y = -1;
      try {
        let fault = this.faultCategories.filter((f) =>
          f.dtcName.includes(dtc.dtc_name)
        );
        let index = this.faultCategoryData.findIndex(
          (f) => f === fault[0].name
        );
        if (index !== -1) {
          y = index;
        } else {
          this.faultCategoryData.push(
            this.faultCategories
              .filter((f) => f.dtcName.includes(dtc.dtc_name))
              .map((f) => f.name)[0]
          );
          y = this.faultCategoryData.length - 1;
        }
      } catch {
        // Will catch any unexpected data points from the API causing issues and just return -1
        console.error('Error Mapping DTC');
        return y;
      }
      return y;
    },
    goBack() {
      this.$emit('showScatter');
    },
    async changeJourneyName() {
      try {
        const metaData = {
          meta_data: {
            journey_name: this.journeyName,
          },
        };
        await updateJourneyName(this.journeyStartSegment, metaData);
        this.updateSnack({
          type: 'success',
          message: 'Successfully updated journey name!',
        });
        this.updateJourneyData([]); // Resets journey data so call will be made when navigating back to retrieve updated journey name
        this.journeyEdit = false;
      } catch {
        this.updateSnack({
          type: 'error',
          message: 'There was an issue updating the journey name!',
        });
        this.closeJourneyEdit();
      }
    },
    closeJourneyEdit() {
      this.journeyEdit = false;
      if (this.journey.names.assignedName.length) {
        this.journeyName = this.journey.names.assignedName;
      } else {
        this.journeyName = '';
      }
    },
    close(data) {
      if (data) {
        this.truckName = data.truck_name;
        this.updateTruckName(data);
      }
      this.changeTruckNameDialog = false;
    },
    // based on haversine formula
    findDistance(point1, point2) {
      // Convert latitude and longitude from degrees to radians
      const degToRad = (degrees) => degrees * (Math.PI / 180);
      const lat1 = degToRad(point1.lat);
      const lon1 = degToRad(point1.lng);
      const lat2 = degToRad(point2.lat);
      const lon2 = degToRad(point2.lng);
      const radius = 3958.8; // Earth's radius in mi
      const dLat = lat2 - lat1;
      const dLon = lon2 - lon1;
      const a =
        Math.sin(dLat / 2) ** 2 +
        Math.cos(lat1) * Math.cos(lat2) * Math.sin(dLon / 2) ** 2;
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const distance = radius * c;
      return distance; // miles
    },
    // does not connect lines more than 3 miles apart
    plotRoute() {
      if (this.journey?.route) {
        let polylineGroup = new window.H.map.Group();
        let lineString = new window.H.geo.LineString();
        let prevPoint;
        for (const point of this.journey?.route) {
          if (prevPoint) {
            let dist = this.findDistance(prevPoint, point);
            // arbitrary 3 mile cutoff
            if (dist > 3) {
              if (lineString.$.length > 3)
                polylineGroup.addObject(
                  new window.H.map.Polyline(lineString, {
                    style: { lineWidth: 4 },
                  })
                );
              lineString = new window.H.geo.LineString();
            }
          }
          lineString.pushPoint(point);
          prevPoint = point;
        }

        if (lineString.$.length > 3)
          polylineGroup.addObject(
            new window.H.map.Polyline(lineString, {
              style: { lineWidth: 4 },
            })
          );

        this.hereMap.addObject(polylineGroup);
        /* Zoom to fit polyline with updated bounds that prevent
            interference 
        
            bound box ex format:
              a:-97.80215 (left edge) (more neg => west)
              b:-97.79884 (right edge)
              c:30.50271 (top edge) (more pos => north)
              f:30.49913 (lower edge)
        */

        const bounds = polylineGroup.getBoundingBox();
        const latWidth = Math.abs(bounds.b - bounds.a);
        const lngHeight = Math.abs(bounds.c - bounds.f);
        bounds.a -= latWidth * (2 / 3);
        bounds.b += latWidth / 10; // extend right edge by 10%
        bounds.c += lngHeight / 2;
        bounds.f -= lngHeight / 10; // extend bottom edge by 10%
        this.hereMap.getViewModel().setLookAtData({
          bounds: bounds,
        });
      }
    },
    // https://hyliion.atlassian.net/wiki/spaces/HLS/pages/3309961230/Emissions+Calculations
    async populateVehicleStats() {
      let noxReduction, co2Reduction, fuelEconomy;
      const comparisonMPG = this.esgPreferences?.esg_fuel_mpg;
      const comparisonFuel = this.esgPreferences?.esg_fuel_used?.toLowerCase();
      // const currentFuel =  this.esgPreferences?.esg_fuel_used.toLowerCase() || 'cng' // add back once CNG/RNG selector is re-enabled
      const currentFuel = 'cng';
      const totalKm = this.journeyData?.data?.statistics?.totalKilometers;
      const kgFuel =
        this.journeyData?.data?.statistics?.totalKilometers /
        this.journeyData?.data?.statistics?.kmPerKg;

      // FUEL ECONOMY
      fuelEconomy =
        Math.round(this.journeyData?.data.statistics.milesPerDGE * 10) / 10;

      // NOX REDUCTION
      noxReduction = (
        Math.round(
          getN2OReduction(totalKm, currentFuel, comparisonFuel) * 100
        ) / 100
      ).toLocaleString();

      // CO2 REDUCTION
      co2Reduction = (
        Math.round(
          getCO2Reduction(
            totalKm,
            currentFuel,
            comparisonFuel,
            comparisonMPG,
            kgFuel
          ) * 10
        ) / 10
      ).toLocaleString();

      // JOURNEY FAULTS tallied in initGanttChart()

      this.vehicleStats = {
        fuelEconomy,
        noxReduction,
        co2Reduction,
        journeyFaults: this.journeyFaults,
      };
    },
    getCompanyID() {
      // Hyliion Users
      if (this.companies.length > 1 && this.companies.find((c) => c.id === 4)) {
        if (this.selectedCompany) {
          // If user has Live Fleet View selected
          return this.selectedCompany.id;
        } else {
          // Default to Hyliion Data
          return 4;
        }
      } else {
        // External Users
        return this.companies[0].id;
      }
    },
    populatePieCharts() {
      let driveEvents = [
        {
          name: 'On The Road',
          y: 0,
          color: this.getEventType(0).eventColor,
        },
        {
          name: 'Idle',
          y: 0,
          color: this.getEventType(1).eventColor,
        },
        {
          name: 'Charging',
          y: 0,
          color: this.getEventType(7).eventColor,
        },
        {
          name: 'Inactive Idle',
          y: 0,
          color: this.getEventType(6).eventColor,
        },
        {
          name: 'Accessory',
          y: 0,
          color: this.getEventType(2).eventColor,
        },
        {
          name: 'Offline',
          y: 0,
          color: this.getEventType(3).eventColor,
        },
      ];
      let driveModes = [
        {
          name: 'Automatic',
          y: 0,
          color: this.getDriveMode(1).dmColor,
        },
        {
          name: 'Manual Charge',
          y: 0,
          color: this.getDriveMode(2).dmColor,
        },
        {
          name: 'Manual EV',
          y: 0,
          color: this.getDriveMode(3).dmColor,
        },
      ];
      let driveControls = [
        {
          name: 'eRetarder - Nominal',
          y: 0,
          level: 0,
          color: this.getRetarderLevels(0).rColor,
        },
        {
          name: 'eRetarder - Medium',
          y: 0,
          level: 1,
          color: this.getRetarderLevels(1).rColor,
        },
        {
          name: 'eRetarder - High',
          y: 0,
          level: 2,
          color: this.getRetarderLevels(2).rColor,
        },
      ];
      this.journey.segments.forEach((s) => {
        // populate drive events
        const startDate = new Date(s.startLocation.telematics.time);
        const endDate = new Date(s.endLocation.telematics.time);
        const minDiff = Math.abs(endDate - startDate) / (1000 * 60);
        const { eventName } = this.getEventType(s.type);
        driveEvents.find((o) => o.name === eventName).y += minDiff;
        // populate drive modes
        if (s.driveModes?.length) {
          s.driveModes.forEach((dm) => {
            const startDate = new Date(dm.start);
            const endDate = new Date(dm.end);
            const minDiff = Math.abs(endDate - startDate) / (1000 * 60);
            let { dmName } = this.getDriveMode(dm.mode);
            driveModes.find((o) => o.name === dmName).y += minDiff;
          });
        }
        // populate drive controls
        if (s.retarderLevels?.length) {
          s.retarderLevels.forEach((r) => {
            const startDate = new Date(r.start);
            const endDate = new Date(r.end);
            const minDiff = Math.abs(endDate - startDate) / (1000 * 60);
            driveControls.find((o) => o.level === r.level).y += minDiff;
          });
        }
      });

      this.driveEventsPie = driveEvents;
      this.driveModesPie = driveModes;
      this.driveControlsPie = driveControls;
    },
    // todo: this should be optimized later; for now just call the api for fool-proof data
    async fetchFleetSettings() {
      const res = await getCompanyPreferences(this.companyId);
      // this.assignCompanyPreferences(res.data[0]);
      this.esgPreferences = res.data[0];
    },
  },
};
</script>

<style>
.cust-border {
  border-bottom: 1px solid rgba(224, 224, 224, 0.1) !important;
}

.v-expansion-panel--active .cust-border {
  min-height: 50px;
}

.cust-border .v-icon {
  color: #888888 !important;
}
</style>

<style scoped>
.lCard {
  position: absolute;
  right: 10px;
  margin-top: 24px;
  margin-right: 24px;
  z-index: 2;
}
.pieContainer {
  background-color: #272727;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}
</style>
