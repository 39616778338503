<template>
  <v-container fluid class="mt-3 mx-3">
    <v-row>
      <v-col>
        <h1 style="font-weight: 400">DTC Dashboard</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="mr-4"
        ><v-skeleton-loader
          v-if="initLoading"
          v-bind="attrs"
          type="table-heading, image, table-heading, image"
        ></v-skeleton-loader
        ><v-card v-else elevation="2" color="#272727">
          <!-- Dropdown Selections-->
          <v-row class="ml-3 mt-3">
            <v-col cols="3" class="pr-5">
              <v-select
                v-model="selectedCompany"
                :items="companies"
                item-text="name"
                label="Select a Fleet"
                single-line
                return-object
                solo
                @change="renderCharts('all')"
              ></v-select>
            </v-col>
            <v-col cols="3" class="pl-1">
              <v-select
                v-model="selectedTrucks"
                :items="truckList"
                :disabled="firstRowLoading"
                item-text="number"
                multiple
                return-object
                solo
                chips
              >
                <template v-slot:prepend-item>
                  <v-list-item ripple @mousedown.prevent @click="toggle">
                    <v-list-item-action>
                      <v-icon
                        :color="selectedTrucks.length > 0 ? 'primary' : ''"
                      >
                        {{ icon }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> Select All </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                  <v-divider class="mt-2"></v-divider> </template
              ></v-select>
            </v-col>
            <v-col cols="3"
              ><v-btn
                height="48"
                color="secondary"
                :disabled="firstRowLoading || !selectedTrucks.length"
                @click="renderCharts('charts')"
                >Refresh Data</v-btn
              ></v-col
            >
            <v-col><v-spacer /></v-col>
          </v-row>
          <!-- First Row of Charts-->
          <v-skeleton-loader
            v-if="firstRowLoading"
            v-bind="attrs"
            type="table-heading, image"
          ></v-skeleton-loader>
          <v-row v-else class="ml-3 mt-3 mr-6">
            <v-col cols="3">
              <div style="background-color: #1a1a1a">
                <PieChart name="Fleet Status" :data="fleetStatusPie" />
              </div>
            </v-col>
            <v-col cols="3" class="mr-0 pr-0"
              ><div style="background-color: #1a1a1a">
                <PieChart
                  :name="dateName('overview')"
                  :data="overviewPie"
                /></div
            ></v-col>
            <v-col
              cols="6"
              class="ml-0 pl-0"
              style="
                background-color: #1a1a1a;
                height: fit-content;
                margin-top: inherit;
              "
            >
              <v-row>
                <v-col><v-spacer /></v-col>
                <v-col
                  cols="4"
                  class="pb-0 mb-0 d-flex justify-end align-center"
                >
                  <p class="pb-3 pr-3">Date Selector:</p>
                  <v-divider
                    vertical
                    inset
                    style="margin-top: 4px; height: 48px"
                  ></v-divider>
                </v-col>
                <v-col cols="4" class="pb-0 mb-0 ml-0 pl-0">
                  <date-input
                    label="Date"
                    :model="singleDate"
                    :send-date="(d) => (singleDate = d)"
                    :show-guide="false"
                  ></date-input>
                </v-col>
              </v-row>
              <v-row>
                <v-col col="6" xl="6" lg="6" md="12" sm="12">
                  <BarChart
                    :name="dateName('topFive')"
                    :data="topFiveDTCs"
                    :categories="topFiveCategories"
                    :height="278"
                  />
                </v-col>
                <v-col cols="6" xl="6" lg="6" md="12" sm="12">
                  <BarChart
                    :name="dateName('topFiveCritical')"
                    :data="topFiveCritical"
                    :categories="topFiveCriticalCategories"
                    :height="278"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <!-- Second Row of Charts-->
          <v-skeleton-loader
            v-if="secondRowLoading"
            v-bind="attrs"
            type="table-heading, image"
          ></v-skeleton-loader>
          <v-row v-else class="mx-6">
            <v-col
              cols="12"
              class="ml-0 pl-0"
              style="
                background-color: #1a1a1a;
                height: fit-content;
                margin-top: inherit;
              "
            >
              <v-row>
                <v-col cols="2" md="3" class="mt-2 ml-3">
                  <h3>Top DTCs and MTBF</h3>
                </v-col>
                <v-col><v-spacer /></v-col>
                <v-col
                  cols="2"
                  class="pb-0 mb-0 d-flex justify-end align-center"
                >
                  <p class="pb-3 pr-3">Date Range:</p>
                  <v-divider
                    vertical
                    inset
                    style="margin-top: 4px; height: 48px"
                  ></v-divider>
                </v-col>
                <v-col cols="2" class="pb-0 mb-0 ml-0 pl-0">
                  <date-input
                    label="Start Date"
                    :model="startDate"
                    :send-date="(d) => (startDate = d)"
                    :show-guide="false"
                  ></date-input>
                </v-col>
                <v-col cols="2" class="pb-0 mb-0 ml-0 pl-0"
                  ><date-input
                    label="End Date"
                    :model="endDate"
                    :send-date="(d) => (endDate = d)"
                    :show-guide="false"
                  ></date-input
                ></v-col>
              </v-row>
              <v-row>
                <v-col col="6" xl="6" lg="6" md="12" sm="12">
                  <BarChart
                    :name="dateName('topTen')"
                    :data="topTenDTCs"
                    :categories="topTenCategories"
                  />
                </v-col>
                <v-col cols="6" xl="6" lg="6" md="12" sm="12">
                  <BarChart
                    :name="dateName('topTenCritical')"
                    :data="topTenCritical"
                    :categories="topTenCriticalCategories"
                  />
                </v-col>
              </v-row>
              <!-- Third Row of Charts-->
              <v-row>
                <v-col col="6" xl="6" lg="6" md="12" sm="12">
                  <GroupedBarChart
                    name="Mean Time Between Faults"
                    :data="meanTime"
                    :categories="meanTimeCategories"
                  />
                </v-col>
                <v-col cols="6" xl="6" lg="6" md="12" sm="12">
                  <GroupedBarChart
                    name="Mean Time Between Failure (Critical DTCs)"
                    :data="meanTimeCritical"
                    :categories="meanTimeCriticalCategories"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row> </v-card
      ></v-col>
    </v-row>
  </v-container>
</template>

<script>
import PieChart from './PieChart.vue';
import BarChart from './BarChart.vue';
import GroupedBarChart from './GroupedBarChart.vue';

import DateInput from '@/utilities/DateInput.vue';
import dateformat from 'dateformat';
import { mapActions } from 'vuex';

import { geInitAllCharts } from '@/api/dtcDashboard';
export default {
  name: 'DTCDashboard',
  components: { PieChart, BarChart, GroupedBarChart, DateInput },
  data() {
    return {
      singleDate: null,
      startDate: null,
      endDate: null,
      companies: [],
      trucks: [],
      truckList: [],
      selectedCompany: null,
      selectedTrucks: [],
      pieColors: {
        Active: '#FF8585',
        Pending: '#FFB266',
        // Healing: '#66CD00',
        Stored: '#7F7F7F',
      },
      dtcColors: [
        '#2E86C1',
        '#3498DB',
        '#5DADE2',
        '#85C1E9',
        '#AED6F1',
        '#48C9B0',
        '#73C6B6',
        '#A3E4D7',
        '#16A085',
        '#45B39D',
      ],
      criticalDTCColors: [
        '#16A085',
        '#28B485',
        '#3CC984',
        '#3CC984',
        '#69E897',
        '#80F2AB',
        '#96FCBF',
        '#ADFFD3',
        '#C4FFE6',
        '#DAFFFA',
      ],
      meanTimeColors: [
        '#7EE219',
        '#FFB58F',
        '#FF5757',
        '#5B738B',
        '#64EDD2',
        '#1B90FF',
        '#A0F',
      ],
      fleetStatusPie: [],
      overviewPie: [],
      topFiveDTCs: [],
      topFiveCategories: [],
      topFiveCritical: [],
      topFiveCriticalCategories: [],
      topTenDTCs: [],
      topTenCategories: [],
      topTenCritical: [],
      topTenCriticalCategories: [],
      meanTime: [],
      meanTimeCategories: [],
      meanTimeCritical: [],
      meanTimeCriticalCategories: [],
      initLoading: false,
      firstRowLoading: false,
      secondRowLoading: false,
      attrs: {
        class: 'mb-6',
        elevation: 2,
      },
    };
  },
  computed: {
    partialTrucksSelected() {
      return this.selectedTrucks.length > 0;
    },
    allTrucksSelected() {
      return this.selectedTrucks.length === this.truckList.length;
    },
    icon() {
      if (this.allTrucksSelected) return 'mdi-close-box';
      if (this.partialTrucksSelected) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    },
  },
  async mounted() {
    await this.init();
  },

  methods: {
    ...mapActions({
      updateSnack: 'updateSnack',
    }),
    async init() {
      this.initLoading = true;
      // Initialized all dates to start at 12:00 AM Central Time to avoid timezone date issues
      const t = new Date();
      const sd = new Date();
      const ed = new Date();
      t.setHours(0, 0, 0).toLocaleString('en-US', {
        timeZone: 'America/Chicago',
      });
      sd.setHours(0, 0, 0).toLocaleString('en-US', {
        timeZone: 'America/Chicago',
      });
      ed.setHours(0, 0, 0).toLocaleString('en-US', {
        timeZone: 'America/Chicago',
      });
      this.endDate = ed;
      this.singleDate = t;
      this.startDate = sd.setDate(sd.getDate() - 7); // Start 7 Days earlier

      try {
        const { data } = await geInitAllCharts(
          this.singleDate.getTime(), // Today
          this.startDate,
          this.endDate.getTime(), // Today
          'All' // Init with All Companies
        );
        this.companies = data.companies;
        this.selectedCompany = this.companies.find((c) => c.id === 'All');
        this.trucks = data.trucks;
        this.truckList = data.trucks;
        this.selectedTrucks = this.truckList;
        // Fleet Status charts should never change since it is always Today
        if (data.fleetStatus) {
          this.populateFleetStatus(data.fleetStatus);
        }
        this.renderCharts('all', data);
        this.initLoading = false;
      } catch (e) {
        this.updateSnack({
          type: 'error',
          message: 'There was an issue retrieving dtc data',
        });
      }
    },
    async renderCharts(charts, data = undefined) {
      this.resetCharts();
      let truckParams;
      if (!data) {
        this.firstRowLoading = true;
        this.secondRowLoading = true;
        // For Company selector and charts selector
        if (charts === 'charts') {
          // Charts Selector (Companies, Trucks, Dates)
          if (this.selectedTrucks !== this.truckList) {
            // If selected truck list has been changed
            truckParams = '&truck_id=';
            truckParams += this.selectedTrucks.map((t) => `${t.id}`).join();
          }
        }
        try {
          const res = await geInitAllCharts(
            new Date(this.singleDate).getTime(),
            new Date(this.startDate).getTime(),
            new Date(this.endDate).getTime(),
            this.selectedCompany.id,
            truckParams
          );
          data = res.data;
        } catch {
          this.updateSnack({
            type: 'error',
            message: 'There was an issue retrieving dtc data',
          });
        }
        if (charts !== 'charts') {
          this.truckList = data.trucks;
          this.selectedTrucks = this.truckList;
        }
      }
      if (charts === 'all' || charts === 'charts') {
        if (data.overview) {
          this.populateOverview(data.overview);
        }
        if (data.topFive.length) {
          this.populateTopFive(data.topFive);
        }
        if (data.topFiveCritical.length) {
          this.populateTopFiveCritical(data.topFiveCritical);
        }
        if (data.topTen.length) {
          this.populateTopTen(data.topTen);
        }
        if (data.topTenCritical.length) {
          this.populateTopTenCritical(data.topTenCritical);
        }
        if (data.mtbf) {
          this.populateMTBF(data.mtbf);
        }
        if (data.mtbfCritical) {
          this.populateMTBFCritical(data.mtbfCritical);
        }
      }
      this.firstRowLoading = false;
      this.secondRowLoading = false;
    },
    toggle() {
      this.$nextTick(() => {
        if (this.allTrucksSelected) {
          this.selectedTrucks = [];
        } else {
          this.selectedTrucks = this.truckList.slice();
        }
      });
    },
    dateName(name) {
      let chartName = '';
      let end = dateformat(this.endDate, 'mm/dd');
      let start = dateformat(this.startDate, 'mm/dd');
      let single = dateformat(this.singleDate, 'mm/dd');
      switch (name) {
        case 'overview':
          chartName = `Overview for ${dateformat(
            this.singleDate,
            'paddedShortDate'
          )}`;
          break;
        case 'topFive':
          chartName = `Top 5 (DTCs) for Fleet on ${single}`;
          break;
        case 'topFiveCritical':
          chartName = `Top 5 Critical (DTCs) for Fleet on ${single}`;
          break;
        case 'topTen':
          chartName = `Top 10 (DTCs) in Fleet from ${start}-${end}`;
          break;
        case 'topTenCritical':
          chartName = `Top 10  Critical (DTCs) in Fleet from ${start}-${end}`;
          break;
      }
      return chartName;
    },
    populateFleetStatus(data) {
      // Skipping Healing DTCs for now
      // data.forEach((fs) => {
      this.fleetStatusPie.push({
        name: data[0].name,
        y: data[0].count,
        color: this.pieColors[data[0].name],
      });
      // });
    },
    populateOverview(data) {
      // Skipping Healing DTCs for now
      // data.forEach((o) => {
      this.overviewPie.push(
        {
          name: data[0].name,
          y: data[0].count,
          color: this.pieColors[data[0].name],
        },
        {
          name: data[2].name,
          y: data[2].count,
          color: this.pieColors[data[2].name],
        }
      );
      // });
    },
    populateTopFive(data) {
      data.forEach((tf, i) => {
        this.topFiveDTCs.push({
          name: tf.name,
          spn: tf.spn,
          fmi: tf.fmi,
          y: tf.total_count,
          color: this.dtcColors[i],
        });
        this.topFiveCategories.push(tf.name ? tf.name : 'N/A');
      });
    },
    populateTopTen(data) {
      data.forEach((tt, i) => {
        this.topTenDTCs.push({
          name: tt.name,
          spn: tt.spn,
          fmi: tt.fmi,
          y: tt.total_count,
          color: this.dtcColors[i],
        });
        this.topTenCategories.push(tt.name ? tt.name : 'N/A');
      });
    },
    populateTopFiveCritical(data) {
      data.forEach((tf, i) => {
        this.topFiveCritical.push({
          name: tf.name,
          spn: tf.spn,
          fmi: tf.fmi,
          y: tf.total_count,
          color: this.criticalDTCColors[i],
        });
        this.topFiveCriticalCategories.push(tf.name ? tf.name : 'N/A');
      });
    },
    populateTopTenCritical(data) {
      data.forEach((tt, i) => {
        this.topTenCritical.push({
          name: tt.name,
          spn: tt.spn,
          fmi: tt.fmi,
          y: tt.total_count,
          color: this.criticalDTCColors[i],
        });
        this.topTenCriticalCategories.push(tt.name ? tt.name : 'N/A');
      });
    },
    populateMTBF(data) {
      let count = 0;
      data.series.forEach((mt) => {
        this.meanTime.push({
          name: dateformat(mt.name + ' ', 'd-mmm'),
          data: mt.data,
          color: this.meanTimeColors[count],
          borderRadius: 2,
          borderWidth: 0,
        });
        count++;
        if (count === 7) {
          count = 0; // Resets colors after 7 entries
        }
      });
      this.meanTimeCategories = data.trucks.map((t) => t.number);
    },
    populateMTBFCritical(data) {
      let count = 0;
      data.series.forEach((mt) => {
        this.meanTimeCritical.push({
          name: dateformat(mt.name + ' ', 'd-mmm'),
          data: mt.data,
          color: this.meanTimeColors[count],
          borderRadius: 2,
          borderWidth: 0,
        });
        count++;
        if (count === 7) {
          count = 0; // Resets colors after 7 entries
        }
      });
      this.meanTimeCriticalCategories = data.trucks.map((t) => t.number);
    },
    resetCharts() {
      this.overviewPie = [];
      this.topFiveDTCs = [];
      this.topFiveCategories = [];
      this.topFiveCritical = [];
      this.topFiveCriticalCategories = [];
      this.topTenDTCs = [];
      this.topTenCategories = [];
      this.topTenCritical = [];
      this.topTenCriticalCategories = [];
      this.meanTime = [];
      this.meanTimeCategories = [];
      this.meanTimeCritical = [];
      this.meanTimeCriticalCategories = [];
    },
  },
};
</script>

<style scoped></style>
