<template>
  <v-container fluid class="px-0 pt-0">
    <v-btn icon tile large class="toggleMap" @click="toggleDrawer"
      ><v-icon style="opacity: 0.6">{{ toggleMapIcon }}</v-icon></v-btn
    >
    <HereMap
      :cog-present="false"
      :set="setMap"
      :set-u-i="setUI"
      :map-height="mapHeight"
      :show-nav="!drawer"
      :show-traffic="false"
    ></HereMap>
    <bottom-drawer :model="drawer" :toggle="toggleDrawer">
      <template #icon><v-icon>mdi-map-minus</v-icon></template>
      <v-container fluid>
        <v-row>
          <v-col cols="3" class="ml-3 pb-0">
            <h1 style="font-weight: 400">Health</h1>
          </v-col>
          <v-col align="left"
            ><LiveFleetViewBanner v-if="liveView" :fleet-info="selectedCompany"
          /></v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <tab-view
              v-if="ready"
              :truck-data="healthTrucks"
              :toggle-details="toggleDetailsDrawer"
              :set-details="setTruckDetails"
            />
            <v-skeleton-loader
              v-else-if="!ready && !error"
              type="table-thead"
              elevation="2"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-container>
    </bottom-drawer>
    <bottom-drawer
      type="detail"
      :model="detailsDrawer"
      :toggle="toggleDetailsDrawer"
    >
      <v-container fluid>
        <truck-detail
          :truck="truckDetails"
          :map="hereMap"
          :map-trucks="mapTrucks"
        />
      </v-container>
    </bottom-drawer>
  </v-container>
</template>

<script>
import HereMap from '@/utilities/HereMap.vue';
import BottomDrawer from '@/utilities/BottomDrawer.vue';
import TabView from './TabView.vue';
import TruckDetail from './TruckDetail.vue';
import LiveFleetViewBanner from '@/utilities/LiveFleetViewBanner.vue';
import { mapTrucks, filterFaults } from '@/utilities/ExternalHealth';
import { convertTZ } from '@/utilities/dateFunctions.js';
import {
  getHealth,
  getDTC,
  getnearestSS,
  getSpnfmiStates,
} from '@/api/external/health';
import { getCompanyGeozones } from '@/api/external/company';
import dateFormat from 'dateformat';
import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {
  name: 'ExternalHealth',
  components: {
    HereMap,
    BottomDrawer,
    TabView,
    TruckDetail,
    LiveFleetViewBanner,
  },
  data() {
    return {
      truckDetails: null,
      detailsDrawer: false,
      drawer: true,
      hereMap: null,
      hereUI: null,
      truckData: [],
      dtcData: null,
      locationData: null,
      mapTrucks: [],
      healthTrucks: [],
      ready: false,
      error: false,
      company: null,
      liveView: false,
    };
  },
  computed: {
    ...mapGetters({
      spnfmiStates: 'getSpnfmiStates',
      companies: 'getCompanies',
      userPreferences: 'getUserPreferences',
      selectedCompany: 'getSelectedLiveFleet',
    }),
    mapHeight() {
      return window.innerHeight - 364;
    },
    toggleMapIcon() {
      if (this.drawer) {
        return 'mdi-map-plus';
      } else {
        return 'mdi-map-minus';
      }
    },
  },
  watch: {
    // Populates the map when data is ready
    ready() {
      if (this.ready) {
        this.populateMap();
        this.populateGeozones();
      }
    },
    drawer() {
      this.updateCurrentScreen({ mapShowing: !this.drawer });
    },
    detailsDrawer() {
      this.updateCurrentScreen({ truckDetails: this.detailsDrawer });
    },
  },
  async mounted() {
    // Hyliion Users
    if (this.companies.length > 1 && this.companies.find((c) => c.id === 4)) {
      if (this.selectedCompany) {
        // If user has Live Fleet View selected
        this.company = this.selectedCompany;
        if (this.selectedCompany.id !== 4) this.liveView = true;
      } else {
        // Defaults to Hyliion Data
        this.company = this.companies.find((c) => c.id === 4);
      }
    } else {
      // External Users
      this.company = {
        id: this.companies[0].id,
        name: this.companies[0].name,
      };
    }
    if (!this.spnfmiStates.length) {
      const { data } = await getSpnfmiStates();
      await this.asyncUpdateSpnfmiStates(data);
    }
    this.getData(); // Call this after company id has been established
    // TODO: Check to make sure all attrs. are correct for this component
    this.updateCurrentScreen({
      tab: 'Health',
      mapShowing: !this.drawer,
      healthTab: 'Faulted',
      scatterMetric: 'mpge',
      scatterRange: 'month',
      journeyDetails: false,
      truckDetails: this.detailsDrawer,
    });
  },
  methods: {
    ...mapActions(['asyncUpdateSpnfmiStates', 'updateSnack']),
    ...mapMutations(['updateCurrentScreen', 'assignTrucks']),
    async getData() {
      const vm = this;
      vm.ready = false;
      try {
        let trucks = [];
        [trucks, vm.dtcData, vm.locationData] = await Promise.all([
          getHealth(this.company.id),
          getDTC(this.company.id),
          getnearestSS(this.company.id),
        ]);
        if (trucks && trucks.length) {
          vm.truckData = trucks.filter((t) => t.owned === true);
          this.assignTrucks(trucks);
        }

        this.assignHealthTrucks();
      } catch {
        this.error = true;
        this.updateSnack({
          type: 'error',
          message: 'There was an issue loading data',
        });
      }
    },
    assignHealthTrucks() {
      if (this.truckData.length) {
        this.truckData.forEach((t) => {
          let tallied = false;
          let locationInfo = this.locationData.find((l) => l.truck_id === t.id);
          t.locationInfo = locationInfo; // Includes Service Station Information
          // Checks if there is no location for a truck or if the timestamp has exceeded 48 hours
          let timeDiff =
            (Date.now() - new Date(t.timestamp)) / (1000 * 60 * 60);
          if (timeDiff > 48 || (!t.latitude && !t.longitude)) {
            tallied = true;
            t.type = 'notInUse';
            return;
          }
          let checkDtc = this.dtcData.filter((d) => d.truck_id === t.id);
          if (checkDtc) {
            checkDtc = filterFaults(checkDtc, this.spnfmiStates);
          }
          // Checks if truck has a fault
          if (checkDtc && checkDtc.length > 0 && !tallied) {
            t.checkDtc = checkDtc;
            // Checks if truck is at a Service Station
            if (locationInfo && locationInfo.nearest_ss === 0) {
              t.type = 'inShop';
            } else {
              t.type = 'fault';
            }
          } else {
            t.type = 'normal';
          }
        });
        this.healthTrucks = mapTrucks(
          this.truckData,
          this.userPreferences.timeZonePref.canonical,
          this.spnfmiStates
        );
      }
      this.ready = true;
    },
    toggleDrawer() {
      this.drawer = !this.drawer;
      // auto close detail drawer when this is open
      if (this.drawer) this.detailsDrawer = false;
    },
    toggleDetailsDrawer(status = null) {
      // set explicit status if provided (ie not null or an event) else toggle
      if (status === true || status === false) {
        this.detailsDrawer = status;
      } else {
        this.detailsDrawer = !this.detailsDrawer;
      }
      // auto close main drawer when this is open; auto open main drawer when it closes
      if (this.detailsDrawer) this.drawer = false;
      else this.drawer = true;
    },
    setMap(obj) {
      this.hereMap = obj;
    },
    setUI(obj) {
      this.hereUI = obj;
    },
    populateMap() {
      this.clearMap();
      const H = window.H;
      let truckGroup = new H.map.Group();
      for (const truck of this.truckData) {
        if (!truck.latitude || !truck.longitude) continue;
        // set position, iconName, dtcStatus, healthColor
        const position = { lat: truck.latitude, lng: truck.longitude };
        let movingStatus = 'Idle';
        if (truck.current_speed > 0 && truck.type !== 'notInUse')
          movingStatus = 'Moving';
        const [iconStatus, dtcStatus, healthColor] = this.getStatusColor(truck);
        let iconName;
        // only overwrite with gray if truck has no problems
        if (truck.type === 'notInUse') iconName = 'Off-line';
        else iconName = `${iconStatus}_${movingStatus}`;
        let markerContent = `
            <div class="hymarker" style="display:block;position:relative;width:30px;height:43px;cursor:pointer;
              background-image:url(../images/icons/small/${iconName}.svg);z-index:10;background-size:cover;
              margin-left: -16px; margin-top: -36px;">
            </div>`;

        let convertedDate = convertTZ(
          truck.timestamp,
          this.userPreferences.timeZonePref.canonical
        );
        convertedDate = dateFormat(convertedDate, 'm/d/yy h:MM:ss Z');

        const infoWidth = this.getInfoWidth(
          truck.number,
          dtcStatus,
          convertedDate
        );
        // prettier-ignore
        const infoContent = `
          <div style="width: ${infoWidth}px; color: #ffffff; font-weight: 100">
            <span style="color: #757575"> Truck Name: </span> ${truck.number}<br>
            <span style="color: #757575"> DTC: </span> ${dtcStatus}<br>
            <span style="color: #757575"> Last Updated: </span> ${convertedDate}
          </div>`;
        // Add new marker to map
        const domIcon = new H.map.DomIcon(markerContent);
        const newMarker = new H.map.DomMarker(position, {
          icon: domIcon,
          data: {
            healthStatus: iconStatus,
            healthColor: healthColor,
            truck_id: truck.id,
            number: truck.number,
            movingStatus: movingStatus,
            info: infoContent,
            markerType: 'truck',
          },
        });
        this.mapTrucks.push(newMarker);
        truckGroup.addObject(newMarker);
      }
      // zoom to fit truckGroup if trucks in group
      let boundingBox = truckGroup.getBoundingBox();
      if (boundingBox) {
        boundingBox.c += 0.8; // default BB is a little too small; cuts off top icons
        boundingBox.f -= 0.1;
        this.hereMap.getViewModel().setLookAtData({
          bounds: boundingBox,
        });
      }

      this.hereMap.addObject(truckGroup);
      this.setupClickableMarkers();
    },
    getStatusColor(truck) {
      if (truck.type === 'normal') return ['Healthy', 'Normal', '#4caf50'];
      const truckMatch = this.healthTrucks.find((t) => t.truckID === truck.id);
      if (truckMatch.faultSeverity === 'Critical') {
        return ['Error', 'Critical', '#EF5350'];
      } else if (
        truckMatch.faultSeverity === 'Service' ||
        truckMatch.faultSeverity === 'Non-Hyliion Faults' // Default non-matched faults to Service
      ) {
        return ['Hazard', 'Service', '#F2B046'];
      } else {
        return ['Healthy', 'Normal', '#4caf50']; // Default for Offline
      }
    },
    setupClickableMarkers() {
      const vm = this;
      // click event listener for tooltip bubbles
      this.hereMap.addEventListener('tap', (event) => {
        // remove all open bubbles
        this.hereUI
          .getBubbles()
          .forEach((bub) => this.hereUI.removeBubble(bub));
        // if target has no data (i.e. is the map or a cluster) return
        let bubble;

        if (
          !event.target.getData ||
          (event.target.data.a && !event.target.data.a.data)
        ) {
          return;
        } else if (
          event.target.data.markerType === 'truck' ||
          (event.target.data.a &&
            event.target.data.a.data.markerType === 'truck')
        ) {
          vm.mapTruckClicked(event.target.data.truck_id);
          const info =
            event.target.data.markerType === 'truck'
              ? event.target.getData().info
              : event.target.data.a.data.info; // fixme: necessary here?

          bubble = new window.H.ui.InfoBubble(event.target.getGeometry(), {
            content: info,
          });
        } else if (
          event.target.data.markerType === 'serviceStation' ||
          (event.target.data.a &&
            event.target.data.a.data.markerType === 'serviceStation')
        ) {
          const info =
            event.target.data.markerType === 'serviceStation'
              ? event.target.getData()
              : event.target.data.a.data;

          bubble = new window.H.ui.InfoBubble(event.target.getGeometry(), {
            content: `
            <div style="width: 160px; color: #ffffff; font-weight: 100">
              <div style="color: #757575"> Name: </div>
              ${info.name}
              <div style="color: #757575"> Description: </div>
              ${info.description}
              <button class="customClose" style="display: block; margin: auto;
              padding: 8px; border-radius: 4px; background-color: #2B8F14;
              width: 100px; color: black; margin-top: 10px;">
                CLOSE
              </button>
            </div>
            `,
          });
        }

        bubble && this.hereUI.addBubble(bubble);
        if (event.target.data?.markerType === 'serviceStation') {
          // (below bubble method only works after bubble is added to UI)
          const bubbleHTML = bubble.getContentElement();
          // connect links to functions
          bubbleHTML
            .getElementsByClassName('customClose')[0]
            .addEventListener('click', closePressed);
        }
        async function closePressed() {
          vm.hereUI.removeBubble(bubble);
        }
      });

      // event listener for cursor: pointer on canvas drawn map markers
      this.hereMap.addEventListener(
        'pointermove',
        function (event) {
          if (event.target instanceof window.H.map.Marker) {
            vm.hereMap.getViewPort().element.style.cursor = 'pointer';
          } else {
            vm.hereMap.getViewPort().element.style.cursor = 'auto';
          }
        },
        false
      );
    },
    getInfoWidth(name, status, timestamp = null) {
      return Math.max(
        // + 26 to accomodate X in top right corner
        this.getBubbleTextWidth(`Truck Name: ${name}`),
        this.getBubbleTextWidth(`DTC: ${status}`),
        timestamp ? this.getBubbleTextWidth(`Last Updated: ${timestamp}`) : 0
      );
    },
    getBubbleTextWidth(string) {
      const font = '14px Lucida Grande, Arial, Helvetica';
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      context.font = font;
      const width = context.measureText(string.trim()).width;
      return Math.ceil(width);
    },
    setTruckDetails(details) {
      this.truckDetails = details;
    },
    clearMap() {
      if (this.hereMap) {
        this.hereMap.removeObjects(this.hereMap.getObjects());
      }
    },
    // get data needed to show truckDetails of this truck
    mapTruckClicked(truck_id) {
      const truckMatch = this.healthTrucks.find((t) => t.truckID === truck_id);
      this.truckDetails = truckMatch;
      this.toggleDetailsDrawer(true);
    },
    async populateGeozones() {
      const res = await getCompanyGeozones(this.company.id, true);
      const sstations = res.data;
      const icon = new window.H.map.Icon('../images/icons/service.png');
      for (const ss of sstations) {
        if (ss.ss_name) {
          // Filters out non-service stations
          const marker = new window.H.map.Marker(
            {
              lat: ss.latitude,
              lng: ss.longitude,
            },
            {
              icon: icon,
              data: {
                markerType: 'serviceStation',
                name: ss.ss_name,
                description: ss.description,
              },
            }
          );
          this.hereMap.addObject(marker);
        }
      }
    },
  },
};
</script>

<style scoped>
.toggleMap {
  position: absolute;
  margin-top: 20px;
  margin-left: 20px;
  width: 41px;
  height: 41px;
  background-color: #1a1a1a;
  border-radius: 5px;
  z-index: 1;
}
</style>
