<template>
  <v-container>
    <v-row class="mr-3">
      <v-col>
        <h3>Component Level Faults</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="12">
        <div>
          <v-data-table
            :headers="faultHeaders"
            :items="faultItems"
            class="elevation-1 secondary locked-table-style row-height-50"
            :items-per-page="rowCount"
          ></v-data-table>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from 'lodash';
export default {
  name: 'FaultsTable',
  props: {
    dialog: Boolean,
    faultItems: { type: Array, default: null, required: true },
  },
  data() {
    return {
      filteredItems: [],
      faultHeaders: [],
      rowCount: 100,
    };
  },
  mounted() {
    const vm = this;
    vm.rowCount = this.faultItems.length;
    let faultObj = this.faultItems[0];
    let fault_keys = _.keys(faultObj);
    _.forEach(fault_keys, function (o) {
      let hdObj = {
        text: '',
        align: 'center',
        sortable: true,
        value: '',
      };
      hdObj.text = o;
      hdObj.value = o;
      vm.faultHeaders.push(hdObj);
    });
  },
  methods: {},
};
</script>

<style scoped></style>
