<template>
  <v-row class="millionPage">
    <v-col cols="4">
      <v-row class="millionTitle">Hypertruck ERX Total Miles</v-row>
      <v-sheet v-if="loading" class="dailyInfo dailyInfoLoader">
        <v-skeleton-loader
          class="mx-auto"
          type="list-item-avatar-two-line, list-item-avatar-two-line, table-row"
        ></v-skeleton-loader>
      </v-sheet>
      <div v-else class="dailyInfo">
        <div class="dailyLineItem">
          <div class="iconDiv">
            <v-icon large class="icon">mdi-bus-multiple</v-icon>
          </div>
          <div class="dailyLabelWrapper">
            <div class="dailyLabel">Total Fleet Count</div>
            <div class="dailyValue">{{ fleetCount }}</div>
          </div>
        </div>
        <div class="dailyLineItem">
          <div class="iconDiv">
            <v-icon large class="icon">mdi-truck</v-icon>
          </div>
          <div class="dailyLabelWrapper">
            <div class="dailyLabel">Active Now / Today</div>
            <div class="dailyValue">{{ activeNow + ' / ' + activeTrucks }}</div>
          </div>
        </div>
        <div class="dailyLineItem">
          <div class="iconDiv">
            <v-icon large class="icon">mdi-road-variant</v-icon>
          </div>
          <div class="dailyLabelWrapper">
            <div class="dailyLabel">Miles Driven Today</div>
            <div class="dailyValue">
              <Odometer
                v-if="odomReady"
                style="font-size: 35px"
                theme="car3"
                format="( ddd).d"
                :value="dailyMiles"
                :min-integer-len="5"
                :duration="dailyDuration"
              ></Odometer>
            </div>
          </div>
        </div>
        <div class="tableLineItem">
          <div class="iconDiv">
            <v-icon large class="icon">mdi-chart-bar</v-icon>
          </div>
          <div class="tableLabel">Top 5 active trucks today</div>
        </div>
        <div style="width: 500px">
          <v-data-table
            :loading="loading"
            :headers="top5Headers"
            :items="top5"
            sort-desc.sync="true"
            sort-by.sync="odometer"
            :hide-default-footer="true"
            style="background-color: #1a1a1a"
          >
          </v-data-table>
        </div>
      </div>
    </v-col>
    <v-col class="rightHalf">
      <v-row>
        <img class="hexMap" src="../../public/images/assets/hexMap.svg" />

        <v-sheet
          v-if="totalLoading"
          class="pa-3"
          style="
            z-index: 1;
            height: 170px;
            display: block;
            margin: auto;
            margin-top: 30px;
            border-radius: 20px;
          "
        >
          <v-skeleton-loader
            class="mx-auto"
            type="table-row"
            style="display: flex; align-items: center; padding-top: 50px"
          ></v-skeleton-loader>
        </v-sheet>
        <Odometer
          v-else-if="totalOdomReady"
          style="
            display: block;
            margin: auto;
            margin-top: 30px;
            font-size: 100px;
          "
          theme="car2"
          format="( ddd)"
          :value="totalMiles"
          :min-integer-len="7"
          :duration="duration"
        ></Odometer>
      </v-row>
      <v-row
        style="
          position: relative;
          display: flex;
          justify-content: space-between;
          margin-top: 40px;
          z-index: 100;
        "
      >
        <v-col>
          <v-sheet
            v-if="loading"
            class="pa-3"
            style="border-radius: 5px; width: 320px"
          >
            <v-skeleton-loader
              class="mx-auto"
              type="image,list-item-avatar-three-line"
            ></v-skeleton-loader>
          </v-sheet>
          <AutoPieChart
            v-else
            title="Miles per Drive Mode"
            :data="driveMode"
            style="height: 491px"
          ></AutoPieChart>
        </v-col>
        <v-col>
          <v-sheet
            v-if="loading"
            class="pa-3"
            style="border-radius: 5px; width: 320px"
          >
            <v-skeleton-loader
              class="mx-auto"
              type="image,list-item-avatar-three-line"
            ></v-skeleton-loader>
          </v-sheet>
          <AutoPieChart
            v-else
            :data="speedData"
            title="Miles at Speed (Mph)"
            style="height: 491px; z-index: 5"
          ></AutoPieChart>
        </v-col>
        <v-col>
          <v-sheet
            v-if="loading"
            class="pa-3"
            style="border-radius: 5px; width: 320px"
          >
            <v-skeleton-loader
              class="mx-auto"
              type="image,list-item-avatar-three-line"
            ></v-skeleton-loader>
          </v-sheet>
          <AutoPieChart
            v-else
            :data="weightData"
            title="Miles at Weight (Lb)"
            style="height: 491px; z-index: 5"
          ></AutoPieChart>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import Odometer from '../utilities/Odometer';
import AutoPieChart from '../utilities/AutoPieChart.vue';
import { getMillion, getOffset, getOdometer } from '@/api/million.js';
import { getHealthTrucks } from '@/api/trucks';
import { mapGetters } from 'vuex';

export default {
  name: 'ERXMiles',
  components: { Odometer, AutoPieChart },
  data() {
    return {
      loading: true,
      totalLoading: true,
      odomReady: false,
      totalOdomReady: false,
      totalMiles: 0,
      dailyMiles: 0,
      targetTotalMiles: null,
      targetDailyMiles: null,
      prevTarTotalMi: null,
      prevTarDailyMi: null,
      duration: null,
      activeTrucks: null,
      activeNow: null,
      fleetCount: null,
      driveMode: null,
      weightData: null,
      speedData: null,
      truckLookup: null,
      intervalID: null,
      top5: [],
      top5Headers: [
        { text: 'Truck', value: 'name' },
        { text: 'Location', value: 'location' },
        { text: 'Miles Today', value: 'miToday' },
      ],
    };
  },
  beforeRouteLeave(to, from, next) {
    clearInterval(this.intervalID);
    next();
  },
  async mounted() {
    const interval = 60 * 60000; // default to 1 hr
    await this.getTruckData();
    await this.populateData();
    this.odomReady = true;

    this.dailyDuration = interval;
    this.duration = interval;
    const vm = this;

    this.intervalID = setInterval(async () => {
      // let res = await getMillion();
      let [res, offset] = await Promise.all([
        getMillion(this.userPreferences.timeZonePref.canonical),
        getOffset(),
      ]);

      vm.fleetCount = res[0].fleetSize;
      vm.activeTrucks = res[0].activeTrucks;
      vm.activeNow = res[0].activeNowCount;
      // It isn't very elegant, but the below code hides an issue where a whole number
      // like 130 will appear to be 13.0 due to the white number place on the odometer.
      // Sicne this issue is very minor and a "real" solution would require a very time
      // consuming overhaul to the odometer.js source code, I've opted to just employ the
      // hacky workaround below.
      if (
        Math.round((res[0].kmToday / 1.609) * 10) / 10 ===
          Math.round(res[0].kmToday / 1.609) &&
        Math.round((res[0].kmToday / 1.609) * 10) / 10 != 0
      ) {
        vm.dailyMiles = res[0].kmToday / 1.609 + 0.1;
      } else {
        vm.dailyMiles = res[0].kmToday / 1.609;
      }
      const new_total = Math.round(res[0].kmAdjustedOdometer / 1.609 + offset);
      vm.totalMiles = new_total;
      res[1].forEach((t) => {
        t.miToday = Math.round((t.kmToday / 1.609) * 10) / 10;
        if (vm.truckLookup[t.truck_id]) {
          t.location = vm.truckLookup[t.truck_id].location;
          t.name = vm.truckLookup[t.truck_id].name;
        } else {
          // this block will only ever be used on dev
          t.name = t.truck_id;
        }
      });
      vm.top5 = res[1];
    }, interval);
  },
  computed: {
    ...mapGetters({
      userPreferences: 'getUserPreferences',
    }),
  },
  methods: {
    async populateData() {
      const millionMilesResponse = await getOdometer();
      const offset = await getOffset();
      this.totalMiles = millionMilesResponse + offset;
      this.totalLoading = false;
      this.totalOdomReady = true;
      // call million/ & populate pie charts
      const res = await getMillion(this.userPreferences.timeZonePref.canonical);
      this.fleetCount = res[0].fleetSize;
      this.activeTrucks = res[0].activeTrucks;
      this.activeNow = res[0].activeNowCount;

      const vm = this;
      res[1].forEach((t) => {
        t.miToday = Math.round((t.kmToday / 1.609) * 10) / 10;
        if (vm.truckLookup[t.truck_id]) {
          t.location = vm.truckLookup[t.truck_id].location;
          t.name = vm.truckLookup[t.truck_id].name;
        } else {
          // this block will only ever be used on dev
          t.name = t.truck_id;
        }
      });
      this.top5 = res[1];

      this.driveMode = [
        {
          name: 'Auto Drive (Eng.)',
          value: Math.round(res[0].kmECOModeEng / 1.609),
          color: '#43b02a',
        },
        {
          name: 'Auto Drive (EV)',
          value: Math.round(res[0].kmECOModeEV / 1.609),
          color: '#5CABFF',
        },
        {
          name: 'Manual Charge',
          value: Math.round(res[0].kmECOChargePrep / 1.609),
          color: '#FB8C00',
        },
        {
          name: 'Manual EV',
          value: Math.round(res[0].kmEVMode / 1.609),
          color: '#E53935',
        },
      ];
      this.weightData = [
        {
          name: 'Heavy',
          moreInfo: '(>50k)',
          value: Math.round(res[0].kmHeavyLoad / 1.609),
          color: '#E53935',
        },
        {
          name: 'Medium',
          moreInfo: '(30k-50k)',
          value: Math.round(res[0].kmMediumLoad / 1.609),
          color: '#FB8C00',
        },
        {
          name: 'Light',
          moreInfo: '(<30k)',
          value: Math.round(res[0].kmLightLoad / 1.609),
          color: '#43b02a',
        },
      ];
      this.speedData = [
        {
          name: 'Over Speed',
          moreInfo: '(>75)',
          value: Math.round(res[0].kmOver120Speed / 1.609),
          color: '#5CABFF',
        },
        {
          name: 'High',
          moreInfo: '(>50)',
          value: Math.round(res[0].km80to120Speed / 1.609),
          color: '#43b02a',
        },
        {
          name: 'Medium',
          moreInfo: '(>25)',
          value: Math.round(res[0].km40to80Speed / 1.609),
          color: '#FB8C00',
        },
        {
          name: 'Slow',
          moreInfo: '(>0)',
          value: Math.round(res[0].km0to40Speed / 1.609),
          color: '#E53935',
        },
      ];
      // this.totalMiles = Math.round(res[0].kmAdjustedOdometer / 1.609 + offset); // km to mi + offset
      this.dailyMiles = Math.round((res[0].kmToday / 1.609) * 10) / 10; // km to mi
      this.loading = false;
    },
    async getTruckData() {
      const vm = this;
      const res = await getHealthTrucks(null, 'ERX');
      this.truckLookup = {};
      res.data.forEach(
        (t) =>
          (vm.truckLookup[t.id] = {
            location: t.address,
            company: t.company,
            name: t.number,
          })
      );
    },
  },
};
</script>

<style scoped>
.hexMap {
  position: absolute;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 100px;
  z-index: -0.9;
}
.millionTitle {
  margin-top: 30px;
  margin-left: 30px;
  font-family: 'Rawline';
  font-style: normal;
  font-weight: bold;
  font-size: 57px;
  line-height: 60px;
  letter-spacing: 0.5px;
  color: #babeca;
}
.iconDiv {
  background-color: #43b02a;
  width: 70px;
  height: 70px;
  border-radius: 20px;
  margin: 10px;
}
.icon {
  color: black;
  padding-left: 17px;
  padding-top: 17px;
}
.dailyInfo {
  margin-top: 50px;
  margin-left: 30px;
  margin-bottom: 60px;
}
.dailyInfoLoader {
  border-radius: 8px;
  padding: 10px;
  width: 300px;
}
.dailyLineItem {
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;
}
.tableLineItem {
  display: flex;
  flex-direction: row;
}
.dailyOdom {
  display: flex;
  margin-left: 5px;
}

.dailyLabelWrapper {
  margin-top: 3px;
  margin-left: 15px;
}
.dailyLabel {
  font-family: 'Rawline';
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
  opacity: 0.6;
}
.tableLabel {
  font-family: 'Rawline';
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
  opacity: 0.6;
  padding-top: 28px;
  padding-left: 15px;
}
.dailyValue {
  font-family: 'Rawline';
  font-weight: 800;
  font-size: 50px;
  line-height: 40px;
  align-items: center;
  letter-spacing: 0.25px;
  color: #babeca;
}
.rightHalf {
  margin-top: 70px;
}
</style>
