<template>
  <v-dialog v-model="removeDialog" persistent width="550">
    <loader v-if="loading" />
    <v-container>
      <v-row align="center" class="py-2">
        <v-col align="center" cols="6"
          ><h3>Edit Customer Permissions</h3></v-col
        >
        <v-col><v-spacer /></v-col>
        <v-col align="right" cols="2">
          <v-btn icon @click="$emit('closed', false)">
            <v-icon light>mdi-close-circle </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-divider />
      <CustomerPermissions
        :customer-permissions="customer.external_permissions"
        form-type="Edit"
        @cancel="$emit('closed', false)"
        @returnPermissions="updateCustomer"
      />
    </v-container>
  </v-dialog>
</template>

<script>
import CustomerPermissions from './CustomerPermissions.vue';
import { updateCompanyPermissions } from '@/api/company';
import loader from '@/utilities/loader';

import { mapActions } from 'vuex';

export default {
  name: 'RemoveCustomers',
  components: { loader, CustomerPermissions },
  props: { customer: { required: true, type: Object, default: () => {} } },
  data() {
    return {
      removeDialog: true,
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      updateSnack: 'updateSnack',
    }),
    async updateCustomer(permissions) {
      this.loading = true;
      try {
        await updateCompanyPermissions(this.customer.id, permissions);
        this.updateSnack({
          type: 'success',
          message: 'Customer permissions successfully updated!',
        });
        this.$emit('closed');
      } catch {
        this.updateSnack({
          type: 'error',
          message: 'There was an issue updating customer permissions!',
        });
        this.$emit('closed', false);
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped></style>
