import axios from 'axios';
const BASE_URL = process.env.VUE_APP_API_PATH_BASE + 'templates/';

async function getTemplates(visibility = null) {
  let queryString = '?';
  if (visibility) queryString += `visibility=${visibility}`;
  return await axios.get(BASE_URL + queryString);
}

async function postTemplate(body) {
  return await axios.post(BASE_URL, body);
}

async function putTemplate(body) {
  return await axios.put(BASE_URL, body);
}

async function deleteTemplate(id) {
  return await axios.delete(BASE_URL + `${id}`);
}

export { getTemplates, deleteTemplate, postTemplate, putTemplate };
