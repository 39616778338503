<template>
  <div id="trucks">
    <v-container fluid>
      <v-row>
        <v-col cols="4" justify="center">
          <v-text-field
            v-model="searchTextCompany"
            placeholder="Search"
            solo
            prepend-inner-icon="mdi-magnify"
            color="primary"
          ></v-text-field>
        </v-col>
        <v-col cols="8" align="right">
          <v-btn
            large
            color="primary"
            style="color: black"
            @click="showNewConfig = true"
          >
            <v-icon left>mdi-plus-circle-outline</v-icon>Add Customer</v-btn
          >
          <span>&nbsp;</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-0">
          <v-skeleton-loader
            v-if="loading"
            type="table"
            elevation="2"
          ></v-skeleton-loader>
          <v-data-table
            v-else
            :headers="headers"
            :items="masterCustomers"
            class="elevation-1"
            :search="searchTextCompany"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-menu style="top: 312px; left: 300px" offset-y>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-dots-vertical</v-icon>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(route, index) in actionItems"
                    :key="index"
                    :class="route.title"
                    @click="clickAction(item, route.title)"
                    >{{ route.title }}</v-list-item
                  >
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <add-customer
        v-if="showNewConfig"
        @closed="closeNewConfig"
        @refresh="refreshData"
      />
      <edit-customer
        v-if="showEditConfig"
        :customer="customerSelected"
        @closed="closeEditConfig"
        @refresh="refreshData"
      />
      <remove-customer
        v-if="showRemoveConfig"
        :customer="customerSelected"
        @closed="closeRemoveConfig"
        @refresh="refreshData"
      />
    </v-container>
  </div>
</template>

<script>
import _ from 'lodash';
import { getCompanies } from '@/api/company';
import AddCustomer from './AddCustomer.vue';
import RemoveCustomer from './RemoveCustomer.vue';
import EditCustomer from './EditCustomer.vue';

import { mapActions } from 'vuex';

export default {
  name: 'TruckView',
  components: {
    'add-customer': AddCustomer,
    'remove-customer': RemoveCustomer,
    'edit-customer': EditCustomer,
  },
  data() {
    return {
      loading: true,
      showNewConfig: false,
      showEditConfig: false,
      showRemoveConfig: false,
      searchTextCompany: '',
      actionItems: [
        { title: 'Edit Permissions' },
        { title: 'Deactivate Customer' },
      ],
      headers: [
        { text: 'ID', value: 'id', sortable: true },
        { text: 'Company', value: 'name', sortable: true },
        {
          text: 'Health Access',
          value: 'health',
          sortable: true,
        },
        {
          text: 'Performance Access',
          value: 'performance',
          sortable: true,
        },
        { text: 'Active', value: 'active', sortable: true },
        {
          text: 'Actions',
          width: '0.1%',
          value: 'actions',
          sortable: false,
          align: 'right',
          visible: false,
          hideable: false,
        },
      ],
      masterCustomers: null,
      customerList: [],
      customerSelected: {},
    };
  },
  computed: {},
  watch: {},
  async created() {
    await this.populateCustomerList();
  },
  ...mapActions({
    updateSnack: 'updateSnack',
  }),
  methods: {
    clickAction(customer, route) {
      this.customerSelected = customer;
      if (route === 'Edit Permissions') {
        this.showEditConfig = true;
      } else if (route === 'Deactivate Customer') {
        this.showRemoveConfig = true;
      }
    },
    populateCustomerList: async function () {
      const vm = this;
      vm.masterCustomers = [];
      try {
        const { data } = await getCompanies();
        const resLength = data.length;

        if (resLength > 0) {
          vm.loading = false;
          // Temp column adjustments until we get a bigger list of permissions in the future
          data.forEach((c) => {
            c.health = 'No';
            c.performance = 'No';
            if (c.external_permissions) {
              if (c.external_permissions?.health) c.health = 'Yes';
              if (c.external_permissions?.performance) c.performance = 'Yes';
            }
            c.active = c.active ? 'Yes' : 'No';
          });
          vm.masterCustomers = data;
        }
      } catch {
        this.updateSnack({
          type: 'error',
          message: 'Could not retrieve company data...',
        });
      }
    },
    closeNewConfig() {
      this.showNewConfig = false;
      this.refreshData();
    },
    closeEditConfig(refresh = true) {
      this.showEditConfig = false;
      if (refresh) this.refreshData();
    },
    closeRemoveConfig(refresh = true) {
      this.showRemoveConfig = false;
      if (refresh) this.refreshData();
    },
    async refreshData() {
      await this.populateCustomerList();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media only screen and (min-width: 960px) {
  .toolbar {
    margin-top: 10px;
  }
}
</style>
