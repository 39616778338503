<template>
  <v-container fluid>
    <v-data-table
      :headers="truckLabels"
      :items="truckData"
      :search="searchText"
      :loading="loading"
      loading-text="Loading... Please wait"
    >
    </v-data-table>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import { getCumminsTestingData } from '@/api/dataDictionary';
export default {
  name: 'CumminsTesting',
  components: {},
  props: {},
  data() {
    return {
      truckLabels: [
        {
          text: 'Date',
          align: 'center',
          value: 'summary_date',
          sortable: true,
        },
        {
          text: 'Vehicle/Asset Name',
          align: 'center',
          value: 'number',
          sortable: true,
        },
        {
          text: 'Vehicle Identification Number  (VIN)',
          align: 'center',
          value: 'vin',
          sortable: true,
        },
        {
          text: 'Vehicle Location (City, Name, State)',
          align: 'center',
          value: 'address',
          sortable: true,
        },
        {
          text: 'Vehicle Odometer Reading',
          align: 'center',
          value: 'max_odo',
          sortable: true,
        },
        {
          text: 'Total distance driven on the date  (miles)',
          align: 'center',
          value: 'total_distance',
          sortable: true,
        },
        {
          text: 'PSA Flag Active Time (hours)',
          align: 'center',
          value: 'psa_totaltime',
          sortable: true,
        },
        {
          text: 'Engine On Time (hours)',
          align: 'center',
          value: 'engine_totaltime',
          sortable: true,
        },
        {
          text: 'EV Run Time  (hours)',
          align: 'center',
          value: 'ev_totaltime',
          sortable: true,
        },
        {
          text: 'Percent Engine On Time (%)',
          align: 'center',
          value: 'perc_engineon',
          sortable: true,
        },
        {
          text: 'Percent EV Mode  (%)',
          align: 'center',
          value: 'perc_ev',
          sortable: true,
        },
        {
          text: 'Min GCW  (lbs)',
          align: 'center',
          value: 'min_gcw',
          sortable: true,
        },
        {
          text: 'Max GCW  (lbs)',
          align: 'center',
          value: 'max_gcw',
          sortable: true,
        },
        {
          text: 'Average GCW  (lbs)',
          align: 'center',
          value: 'avg_gcw',
          sortable: true,
        },
        {
          text: 'Avg. Speed (mph)',
          align: 'center',
          value: 'avg_speed',
          sortable: true,
        },
        {
          text: 'Start SOC (%)',
          align: 'center',
          value: 'soc_start',
          sortable: true,
        },
        {
          text: 'End SOC  (%)',
          align: 'center',
          value: 'soc_end',
          sortable: true,
        },
        {
          text: 'Min SOC  (%)',
          align: 'center',
          value: 'soc_min',
          sortable: true,
        },
        {
          text: 'Max SOC (%)',
          align: 'center',
          value: 'soc_max',
          sortable: true,
        },
        {
          text: 'Avg. SOC (%)',
          align: 'center',
          value: 'soc_avg',
          sortable: true,
        },
        {
          text: 'Total Fuel (%)',
          align: 'center',
          value: 'totalfuel',
          sortable: true,
        },
      ],
      truckData: [],
      loading: true,
      searchText: '',
    };
  },

  computed: {},
  async mounted() {
    try {
      let res = await getCumminsTestingData();
      this.truckData = res.data;
      this.$emit('updateCumminsData', this.truckData);
      this.loading = false;
    } catch (e) {
      this.updateSnack({
        type: 'error',
        message: 'There was an issue retrieving data!',
      });
      this.loading = false;
    }
  },
  async created() {},
  methods: {
    ...mapActions({
      updateSnack: 'updateSnack',
    }),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
