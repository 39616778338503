<template>
  <div>
    <v-row
      ><v-col>{{ items.length }} results</v-col
      ><v-col align="right">
        <v-btn icon @click="esgDrawer = !esgDrawer"
          ><v-icon>mdi mdi-dots-vertical</v-icon></v-btn
        >
      </v-col></v-row
    >
    <v-skeleton-loader
      v-if="loading"
      type="table"
      elevation="2"
    ></v-skeleton-loader>
    <v-data-table
      v-else
      :headers="headers"
      :items="items"
      :items-per-page="25"
      class="elevation-1 locked-table-style"
    >
      <template v-slot:[`item.companyNumber`]="{ item }">
        <th class="body-5">{{ item.truck_name }}</th>
        <div class="body-6">{{ item.company }}</div>
      </template>
      <template v-for="h in headers" v-slot:[`header.${h.value}`]="{ header }">
        <template v-if="header.hasOptions">
          <span :key="h.text">{{ checkUserPrefs(header) }}</span>
        </template>
        <template v-else
          ><span :key="h.text">{{ header.text }}</span></template
        >
      </template>
    </v-data-table>
    <v-navigation-drawer
      v-model="esgDrawer"
      class="shade"
      right
      temporary
      fixed
      :width="360"
    >
      <v-container>
        <v-row>
          <v-col cols="12">
            <template class="mx-auto column-list">
              <v-list>
                <v-subheader>Select to display ESG Columns</v-subheader>
                <v-list-item-group active-class="green--text" multiple>
                  <draggable v-model="headersDup" v-bind="dragOptions">
                    <template v-for="(column, index) in headersDup">
                      <v-list-item
                        v-if="column.text.length"
                        :key="column.value"
                        :disabled="!column.hideable"
                        @click="updateColumn(column, index)"
                      >
                        <v-list-item-action class="mr-5">
                          <v-icon v-if="!column.visible" color="grey">
                            mdi-square-outline
                          </v-icon>
                          <v-icon v-else color="primary">
                            mdi-checkbox-marked
                          </v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>{{
                            checkUserPrefs(column)
                          }}</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-icon v-if="!column.hideable"> mdi-lock </v-icon>
                          <v-icon v-else> mdi-menu </v-icon>
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                  </draggable>
                </v-list-item-group>
              </v-list>
              <v-divider></v-divider>
              <v-btn
                class="center mt-3"
                text
                color="primary"
                @click="resetESGDefaults"
                >Reset Defaults</v-btn
              >
            </template>
          </v-col>
        </v-row>
      </v-container>
    </v-navigation-drawer>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import { mapGetters } from 'vuex';
export default {
  components: { draggable },
  props: ['items', 'loading'],
  data() {
    return {
      esgDrawer: false,
      headers: [
        {
          text: 'Truck # / Customer',
          width: '1%',
          align: 'left',
          sortable: true,
          value: 'companyNumber',
          visible: true,
          hideable: true,
        },
        {
          text: 'Moving Diesel MT gCO2e',
          align: 'center',
          value: 'movingDieselMT',
          sortable: true,
          visible: true,
          hideable: true,
        },
        {
          text: 'Idle Diesel MT gCO2e',
          align: 'center',
          value: 'idleDieselMT',
          sortable: true,
          visible: true,
          hideable: true,
        },
        {
          text: 'Total Diesel MT gCO2e',
          align: 'center',
          value: 'dieselMT',
          sortable: true,
          visible: true,
          hideable: true,
        },
        {
          text: 'Electricity MT gCO2e',
          align: 'center',
          value: 'electricityMT',
          sortable: true,
          visible: true,
          hideable: true,
        },
        {
          text: 'Moving Hydrogen MT gCO2e',
          align: 'center',
          value: 'movingHydrogenMT',
          sortable: true,
          visible: true,
          hideable: true,
        },
        {
          text: 'Idle Hydrogen MT gCO2e',
          align: 'center',
          value: 'idleHydrogenMT',
          sortable: true,
          visible: true,
          hideable: true,
        },
        {
          text: 'Total Hydrogen MT gCO2e',
          align: 'center',
          value: 'hydrogenMT',
          sortable: true,
          visible: true,
          hideable: true,
        },
        {
          text: 'Fuel Used kg',
          altText: 'Fuel Used lb',
          hasOptions: true,
          type: 'regionPref',
          align: 'center',
          value: 'kgFuelUsed',
          sortable: true,
          visible: true,
          hideable: true,
        },
        {
          text: 'Grid and SOC Adjusted Fuel kg',
          altText: 'Grid and SOC Adjusted Fuel lb',
          hasOptions: true,
          type: 'regionPref',
          align: 'center',
          value: 'kgGridAndSOCAdjustedDeltaFuel',
          sortable: true,
          visible: true,
          hideable: true,
        },
        {
          text: 'SOC Adjusted Fuel kg',
          altText: 'SOC Adjusted Fuel lb',
          hasOptions: true,
          type: 'regionPref',
          align: 'center',
          value: 'kgSOCAdjustedDeltaFuel',
          sortable: true,
          visible: true,
          hideable: true,
        },
        {
          text: 'Start Odometer km',
          altText: 'Start Odometer mi',
          hasOptions: true,
          type: 'regionPref',
          align: 'center',
          value: 'startOdometer',
          sortable: true,
          visible: true,
          hideable: true,
        },
        {
          text: 'End Odometer km',
          altText: 'End Odometer mi',
          hasOptions: true,
          type: 'regionPref',
          align: 'center',
          value: 'endOdometer',
          sortable: true,
          visible: true,
          hideable: true,
        },
        {
          text: 'Delta Distance km',
          altText: 'Delta Distance mi',
          hasOptions: true,
          type: 'regionPref',
          align: 'center',
          value: 'kmDeltaDistance',
          sortable: true,
          visible: true,
          hideable: true,
        },
        {
          text: 'EV Distance km',
          altText: 'EV Distance mi',
          hasOptions: true,
          type: 'regionPref',
          align: 'center',
          value: 'kmEVDistance',
          sortable: true,
          visible: true,
          hideable: true,
        },
        {
          text: 'km EV Per Kwh',
          altText: 'mi EV Per Kwh',
          hasOptions: true,
          type: 'regionPref',
          align: 'center',
          value: 'kmEVPerKwh',
          sortable: true,
          visible: true,
          hideable: true,
        },
        {
          text: 'km EV per SOC %',
          altText: 'mi EV per SOC %',
          hasOptions: true,
          type: 'regionPref',
          align: 'center',
          value: 'kmEVPerSOCPct',
          sortable: true,
          visible: true,
          hideable: true,
        },
        {
          text: 'km per DGE',
          altText: 'mi per DGE',
          hasOptions: true,
          type: 'regionPref',
          align: 'center',
          value: 'kmPerDGE',
          sortable: true,
          visible: true,
          hideable: true,
        },
        {
          text: 'km Per Grid and SOC Adjusted DGE',
          altText: 'mi Per Grid and SOC Adjusted DGE',
          hasOptions: true,
          type: 'regionPref',
          align: 'center',
          value: 'kmPerGridAndSOCAdjustedDGE',
          sortable: true,
          visible: true,
          hideable: true,
        },
        {
          text: 'km Per Grid and SOC Adjusted DGE kg',
          altText: 'mi Per Grid and SOC Adjusted DGE lb',
          hasOptions: true,
          type: 'regionPref',
          align: 'center',
          value: 'kmPerGridAndSOCAdjustedKg',
          sortable: true,
          visible: true,
          hideable: true,
        },
        {
          text: 'km Per kg',
          altText: 'mi Per lb',
          hasOptions: true,
          type: 'regionPref',
          align: 'center',
          value: 'kmPerKg',
          sortable: true,
          visible: true,
          hideable: true,
        },
        {
          text: 'km Per SOC Adjusted DGE',
          altText: 'mi Per SOC Adjusted DGE',
          hasOptions: true,
          type: 'regionPref',
          align: 'center',
          value: 'kmPerSOCAdjustedDGE',
          sortable: true,
          visible: true,
          hideable: true,
        },
        {
          text: 'km Per SOC Adjusted kg',
          altText: 'mi Per SOC Adjusted lb',
          hasOptions: true,
          type: 'regionPref',
          align: 'center',
          value: 'kmPerSOCAdjustedKg',
          sortable: true,
          visible: true,
          hideable: true,
        },
        {
          text: 'Delta SOC %',
          align: 'center',
          value: 'pctDeltaSOC',
          sortable: true,
          visible: true,
          hideable: true,
        },
        {
          text: 'Total EV SOC %',
          align: 'center',
          value: 'totalEVSOCPct',
          sortable: true,
          visible: true,
          hideable: true,
        },
        {
          text: 'Total Grid Kwh',
          align: 'center',
          value: 'totalGridKwh',
          sortable: true,
          visible: true,
          hideable: true,
        },
        {
          text: 'Total Grid SOC',
          align: 'center',
          value: 'totalGridSOC',
          sortable: true,
          visible: true,
          hideable: true,
        },
        {
          text: 'Total Grid SOC %',
          align: 'center',
          value: 'totalGridSOCPct',
          sortable: true,
          visible: true,
          hideable: true,
        },
        // hide this column until we have data feeding into it
        // {
        //   text: 'Est. CO2',
        //   align: 'center',
        //   value: 'est_co2',
        //   sortable: true,
        //   visible: true,
        //   hideable: true,
        // },
      ],
      headersDup: [],
    };
  },
  computed: {
    ...mapGetters({
      userRoles: 'getUserRoles',
      userPreferences: 'getUserPreferences',
    }),
    dragOptions() {
      return {
        animation: 100,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      };
    },
  },
  async created() {
    this.headersDup = [...this.headers];
  },
  methods: {
    changeTab(tab) {
      this.switchTab = tab;
    },
    updateColumn: function (column, index) {
      this.headersDup[index].visible = !column.visible;
      this.updateHeaders(column, column.visible, this.headersDup, this.Headers);
    },
    updateHeaders: function (column, visible, dataHeadersDup, dataHeaders) {
      if (visible) {
        const length = dataHeaders.length;
        const label = dataHeadersDup.find((lb) => lb.text === column.text);
        const index = dataHeadersDup.findIndex((lb) => lb.text === column.text);
        if (index >= length) {
          // Keeps menu at the end of the columns
          dataHeaders.splice(dataHeaders.length - 1, 0, label);
        } else {
          dataHeaders.splice(index, 0, label);
        }
      } else {
        dataHeaders = dataHeaders.filter((hl) => hl.text !== column.text);
      }
      this.headersDup = dataHeadersDup;
      this.Headers = dataHeaders;
    },
    resetESGDefaults() {
      this.headersDup.forEach((o) => {
        o.visible = true;
      });
      this.Headers = this.headersDup;
    },
    checkUserPrefs(h) {
      if (h.type === 'regionPref') {
        if (this.userPreferences.regionPref === 'metric') {
          return h.text;
        } else {
          return h.altText;
        }
      } else {
        return h.text;
      }
    },
  },
};
</script>
