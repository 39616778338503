<template>
  <v-container fluid>
    <v-row>
      <v-col cols="6" sm="4" md="4" lg="3" xl="3" align="left">
        <span class="secondary-text">Truck Name</span><br /><b>{{
          exData.number || ''
        }}</b>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="3" xl="3" align="left">
        <span class="secondary-text">Current Odometer</span>
        <br /><b>{{ exData.odometer }} {{ displayDistanceUnits() }}</b>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="3" xl="3" align="left">
        <span class="secondary-text">Active DTC(s)</span>
        <br /><b>{{ exData.dtc || 0 }}</b>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="3" xl="3" align="left">
        <span class="secondary-text">APU</span>
        <br /><b>{{ exData.APU }}</b>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" sm="4" md="4" lg="3" xl="3" align="left">
        <span class="secondary-text">Customer</span><br /><b>{{
          exData.company
        }}</b>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="3" xl="3" align="left">
        <span class="secondary-text">Ignition</span>
        <br /><b>{{ exData.ignition }}</b>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="3" xl="3" align="left">
        <span class="secondary-text">Current Speed</span>
        <br /><b>{{ exData.current_speed }} {{ displaySpeedUnits() }}</b>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="3" xl="3" align="left">
        <span class="secondary-text">General Temp.</span>
        <br /><b>{{ exData.generalTemp }} {{ displayTempUnits() }}</b>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" sm="4" md="4" lg="3" xl="3" align="left">
        <span class="secondary-text">VIN</span> <br /><b>{{ exData.vin }}</b>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="3" xl="3" align="left">
        <span class="secondary-text">Position</span>
        <br /><b>{{ exData.position }}</b>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="3" xl="3" align="left">
        <span class="secondary-text">APU</span> <br /><b>{{ exData.APU }}</b>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="3" xl="3" align="left">
        <span class="secondary-text">Motor Temp.</span>
        <br /><b>{{ exData.motorTemp }} {{ displayTempUnits() }}</b>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" sm="4" md="4" lg="3" xl="3" align="left">
        <span class="secondary-text">Fuel Type</span>
        <br /><b>{{ exData.fuel_type }}</b>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="3" xl="3" align="left">
        <span class="secondary-text">Current Address</span>
        <br /><b>{{ exData.address }}</b>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="3" xl="3" align="left">
        <span class="secondary-text">Ignition</span> <br /><b>{{
          exData.ignition
        }}</b>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="3" xl="3" align="left">
        <span class="secondary-text">Compressor Temp.</span>
        <br /><b>{{ exData.compressorTemp }} {{ displayTempUnits() }}</b>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" sm="4" md="4" lg="3" xl="3" align="left">
        <span class="secondary-text">Truck Type</span>
        <br /><b>{{ exData.truck_type }}</b>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="3" xl="3" align="left">
        <span class="secondary-text">Last Look Ahead Call</span>
        <br /><b>{{ exData.laTimeUpdated }}</b>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="3" xl="3" align="left">
        <span class="secondary-text">Last Terrain Ahead Call</span>
        <br /><b>{{ exData.taTimeUpdated }}</b>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="3" xl="3" align="left">
        <span class="secondary-text">Last 10 Hz Ping</span>
        <br /><b>{{ exData.ten_hz_time || '—' }}</b>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" sm="4" md="4" lg="3" xl="3" align="left">
        <span class="secondary-text">Current SOC</span>
        <br /><b>{{ exData.SOC }}</b>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="3" xl="3" align="left">
        <span class="secondary-text">Serial Number</span>
        <br /><b>{{ exData.serial_number }}</b>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="3" xl="3" align="left">
        <span class="secondary-text">Current Engine Speed</span>
        <br /><b>{{ exData.engSpeed }} rpm</b>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="3" xl="3" align="left">
        <span class="secondary-text">Battery Min Temp.</span>
        <br /><b>{{ exData.batteryMinTemp }} {{ displayTempUnits() }}</b>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" sm="4" md="4" lg="3" xl="3" align="left">
        <span class="secondary-text">Last Error Shut Down</span>
        <br /><b>{{ exData.last_error_shut_down }}</b>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="3" xl="3" align="left">
        <span class="secondary-text">Last One Minute Ping</span>
        <br /><b>{{ exData.timeUpdated }}</b>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="3" xl="3" align="left">
        <span class="secondary-text">Last IoT Update</span>
        <br /><b>{{ exData.iotUpdate }}</b>
      </v-col>
      <v-col cols="6" sm="4" md="4" lg="3" xl="3" align="left">
        <span class="secondary-text">Battery Max Temp.</span>
        <br /><b>{{ exData.batteryMaxTemp }} {{ displayTempUnits() }}</b>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  name: 'EXDetails',
  props: {
    exData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      userPreferences: 'getUserPreferences',
    }),
  },
  methods: {
    displayDistanceUnits() {
      if (this.userPreferences.regionPref === 'us/imperial') {
        return 'mi';
      } else {
        return 'km';
      }
    },
    displayWeightUnits() {
      if (this.userPreferences.regionPref === 'us/imperial') {
        return 'lbs';
      } else {
        return 'kg';
      }
    },
    displaySpeedUnits() {
      if (this.userPreferences.regionPref === 'us/imperial') {
        return 'mph';
      } else {
        return 'km/h';
      }
    },
    displayTempUnits() {
      if (this.userPreferences.regionPref === 'us/imperial') {
        return '°F';
      } else {
        return '°C';
      }
    },
  },
};
</script>
<style scoped>
.secondary-text {
  font-weight: 100;
  opacity: 0.7;
}
</style>
