<template>
  <div class="plan-block">
    <v-card>
      <v-card-title class="justify-center">Add Test Plan</v-card-title>
      <v-card-text class="text-center"
        >Fill in all fields to add a new plan</v-card-text
      >
      <v-divider></v-divider>
      <v-form ref="form" v-model="formValid" lazy-validation>
        <v-container>
          <v-row>
            <v-col sm="3" class="formLabel">Plan Name</v-col>
            <v-col sm="9">
              <v-text-field
                v-model="name"
                class="formInput"
                :rules="nameRules"
                outlined
                label="Add Name"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="3" class="formLabel">Description</v-col>
            <v-col sm="9">
              <v-text-field
                v-model="description"
                class="formInput"
                outlined
                label="Add Description (not required)"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="3" class="formLabel">Truck: </v-col>
            <v-col>
              <v-select
                v-model="selectedTruck"
                :items="truckItems"
                class="formInput"
                outlined
                label="Choose Truck"
                required
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="3" class="formLabel">Delivery Date: </v-col>
            <v-col sm="9">
              <v-text-field
                v-model="deliveryDate"
                v-mask="'##/##/####'"
                :rules="devDateRules"
                label="Delivery Date (MM/DD/YYYY)"
                class="formInput"
                type="text"
                outlined
                append-icon="mdi-calendar"
                required
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="cancel"> Cancel </v-btn>
          <v-btn :disabled="!formValid" color="primary" @click="exportPlan">
            Add Test Plan
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import _ from 'lodash';
import { getAllTrucks } from '@/api/trucks';
import { currentDateInput } from '@/utilities/dateFunctions';

export default {
  name: 'AddTest',
  props: {
    testsDialog: Boolean,
    addTestPlan: { type: Function, default: null, required: true },
    toggle: { type: Function, default: null, required: true },
  },
  data() {
    return {
      addDisabled: true,
      activityDate: null,
      deliveryDate: '',
      description: '',
      formValid: false,
      lastTester: 'N/A',
      name: '',
      nameRules: [
        (v) => !!v || 'Name is required',
        (v) =>
          (v && v.length >= 2) || 'Name must be at least 2 or more characters',
      ],
      devDateRules: [
        (v) => !!v || 'Date is required',
        (v) =>
          (v && v.length === 10) || 'Delivery date must be mm/dd/yyyy (10)',
      ],
      selectedStep: '',
      selectedTruck: '',
      stage_id: 1,
      status: '',
      statusItems: ['Passed', 'Failed', 'Untested'],
      stepItems: [],
      truckItems: [],
      truckRules: [(v) => !!v || 'Required!'],
      trucks: [],
    };
  },
  watch: {
    deliveryDate(val) {
      this.deliveryDate = currentDateInput(val);
    },
    formValid: function () {
      this.formValid =
        this.name !== '' &&
        this.deliveryDate !== '' &&
        this.selectedTruck !== '';
    },
  },
  mounted() {
    this.getTrucks();
  },
  methods: {
    ...mapActions({
      updateSnack: 'updateSnack',
    }),
    cancel() {
      this.deliveryDate = '';
      this.$refs.form.reset();
      this.toggle();
    },
    convertEpochTime: function (setDate) {
      const newTime = new Date(setDate).getTime() / 1000;
      return _.round(newTime, 0);
    },
    exportPlan: async function () {
      if (!this.$refs.form.validate()) {
        return;
      }
      let dataObj = {};
      const aDate = new Date();
      const truckInfo = await this.matchTruckInfo();
      dataObj.stage_id = this.stage_id; // default to Pending
      dataObj.activity_date = await this.convertEpochTime(aDate);
      dataObj.delivery_date = await this.convertEpochTime(this.deliveryDate);
      dataObj.truck_id = truckInfo.id;
      dataObj.truck_number = this.selectedTruck;
      dataObj.step_count = 0;
      dataObj.test_count = 0;
      dataObj.failed_tests = 0;
      dataObj.name = this.name;
      dataObj.description = this.description;
      this.addTestPlan(dataObj);
      this.cancel();
    },
    getTrucks: async function () {
      const vm = this;
      try {
        const res = await getAllTrucks();
        vm.trucks = res.data;
        _.forEach(res.data, function (o) {
          vm.truckItems.push(o.name);
        });
      } catch (e) {
        vm.updateSnack({
          type: 'error',
          message: 'Axios error on getting trucks ==> ' + e,
        });
      }
    },
    matchTruckInfo: function () {
      const vm = this;
      return _.find(vm.trucks, function (o) {
        return o.name === vm.selectedTruck;
      });
    },
  },
};
</script>

<style scoped>
.plan-block {
  display: block;
  position: relative;
  width: 60%;
  margin: 0 auto;
}
.formLabel {
  display: block;
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;
}
.formInput {
  margin-bottom: -20px;
  /* padding: -10px; */
}
</style>
