<template>
  <v-container fluid>
    <v-row class="pa-0"
      ><v-col class="pa-0">{{ workOrders.length }} result(s)</v-col></v-row
    >
    <v-row>
      <v-col class="pa-0" cols="12">
        <v-data-table
          :loading="loading"
          :no-data-text="noDataMsg"
          :headers="labels"
          :items="workOrders"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-menu style="top: 312px; left: 300px" offset-y>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">mdi-dots-vertical</v-icon>
              </template>
              <v-list dense>
                <v-list-item-group>
                  <v-list-item
                    v-for="(action, index) in actionItems"
                    :key="index"
                    :class="action.title"
                    @click="setAction(item, action.title)"
                    ><v-list-item-icon class="mr-2">
                      <v-icon v-text="action.icon"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        class="pr-2"
                        v-text="action.title"
                      ></v-list-item-title> </v-list-item-content
                  ></v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu> </template
        ></v-data-table> </v-col
    ></v-row>
    <v-dialog v-model="openDetails" class="dialog" width="750">
      <v-card>
        <v-card-text>
          <v-row v-for="(value, propertyName, index) in workOrder" :key="index">
            <v-col
              ><strong>{{ propertyName }}</strong
              >: {{ value }}</v-col
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import dateFormat from 'dateformat';
import { getWorkOrders } from '@/api/salesforce';
export default {
  name: 'WorkOrders',
  props: {
    vin: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      labels: [
        { text: 'WorkOrder #', value: 'WorkOrderNumber' },
        { text: 'Type', value: 'WorkType.Name' },
        { text: 'Priority', value: 'Priority' },
        { text: 'Status', value: 'Status' },
        { text: 'Subject', value: 'Subject' },
        { text: 'Start Date', value: 'StartDate' },
        { text: 'End Date', value: 'EndDate' },
        { text: 'Actions', value: 'actions', align: 'right' },
      ],
      actionItems: [
        { title: 'Work Order Details', icon: 'mdi-list-box-outline' },
      ],
      workOrders: [],
      workOrder: {},
      loading: true,
      openDetails: false,
      noDataMsg: 'There are no recent work orders for this truck',
    };
  },
  watch: {},
  async mounted() {
    try {
      let res = await getWorkOrders(this.vin);
      if (res.data.length) {
        res.data.forEach((d) => {
          d.StartDate = dateFormat(
            new Date(d.StartDate),
            'mm/dd/yyyy hh:MM TT'
          );
          d.EndDate = dateFormat(new Date(d.EndDate), 'mm/dd/yyyy hh:MM TT');
        });
        this.workOrders = res.data;
      }
      this.loading = false;
    } catch (e) {
      console.log('There was an issue retrieving work orders');
      this.loading = false;
    }
  },
  methods: {
    async setAction(item, title) {
      if (title === 'Work Order Details') {
        this.workOrder = item;
        this.openDetails = true;
      }
    },
  },
};
</script>
<style scoped>
.dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  height: auto;
  width: auto;
  z-index: 1;
  transform: translate(-50%, -50%);
}
@media only screen and (min-width: 960px) {
  .padding {
    padding: 1rem;
  }
  .margin {
    margin: 0.2rem;
  }
}
</style>
