/**
 * User roles/permissions:
 *      config.assign           /  EX-(DEPRECATED) - Assign configs
 *      config.delete           /  EX-(DEPRECATED) - Delete configs
 *      config.promote          /  EX-(DEPRECATED) - Promote configs (unsupported -> supported)
 *      unsupported_config.read /  EX-(DEPRECATED) - Read unsupported configs
 *      config.asset            /  Allows user to view truck(Add Truck) and Company(Add/Delete Company) tabs inside of the Configuration route
 *      config.package          /  Allows user to view Packages(Add) tab inside of the Configuration route
 *      config.write            /  ERX - Able to upload, deprecate unsupported packages, see all packages
 *      config.support          /  ERX - Able to promote from unsupported to supported, supported to unsupported, deprecate unsupported, see all, deploy Hyliion Only
 *      config.verify           /  ERX - Able to promote supported to verified, verified to supported, deprecate supported or verified, see Supported, Verified, and Available, deploy Hyliion Only
 *      config.release          /  ERX - Able to promote verified to available, available to verified, see(Verified,Available), deploy Hyliion Only
 *      config.assign_ext       /  ERX - Able to see Available, deploy Hyliion and Customer
 *      truck.writer            /  Write to Health Truck Table or Truck Details
 *      user-management.add     /  Allows user to Add new users
 *      eol.writer              /  Allows user to run end of line tests
 *      eol.reader              /  Allows user to see end of line tests that have been run
 *      truck_usage.writer      /  Allows user to add/update truck usage info
 *      external.fleet_config   /  Allows user to update company preferences for external Dashboard
 *      truck.reassign          /  Allows the user to move a truck from one company to another
 *      feature.live_fleet_view /  Gives the user access to Live Fleet View accessibility
 */

const permissions = {
  assign: 'config.assign',
  assignExt: 'config.assign_ext',
  delete: 'config.delete',
  promote: 'config.promote',
  create: 'config.write',
  support: 'config.support',
  unsupported: 'unsupported_config.read',
  verify: 'config.verify',
  release: 'config.release',
  configWrite: 'config.write',
  write: 'truck.writer',
  reassign: 'truck.reassign',
  addUser: 'user-management.add',
  eolWriter: 'eol.writer',
  eolReader: 'eol.reader',
  truckUsageWriter: 'truck_usage.writer',
  configAsset: 'config.asset',
  configPackage: 'config.package',
  externalFleetConfig: 'external.fleet_config',
  liveFleetView: 'feature.live_fleet_view',
};

export { permissions };
