import axios from 'axios';
const BASE_URL = process.env.VUE_APP_API_PATH_BASE;

async function getTripReport(
  rowDef,
  start = null,
  end = null,
  company_id = null,
  truck_list = null,
  truck_type = 'EX',
  graph = false,
  tz
) {
  let queryString = `?rowDef=${rowDef}`;
  queryString += start ? `&esgStart=${start}` : '';
  queryString += end ? `&esgEnd=${end}` : '';
  queryString += company_id ? `&company_id=${company_id}` : '';
  truck_list &&
    truck_list.forEach((truck) => (queryString += `&truck_id=${truck}`));
  graph === true && (queryString += '&graph=true');
  queryString += `&tz=${tz}`;
  if (truck_type.toUpperCase() === 'EX') {
    return await axios.get(BASE_URL + `trips/exTripReport${queryString}`);
  } else {
    return await axios.get(BASE_URL + `trips/erxTripReport${queryString}`);
  }
}

async function getTripGraph(
  rowDef = 0,
  start = null,
  end = null,
  company_id = null,
  truck_list = null,
  tz
) {
  let queryString = `?rowDef=${rowDef}`;
  queryString += start ? `&esgStart=${start}` : '';
  queryString += end ? `&esgEnd=${end}` : '';
  truck_list &&
    truck_list.forEach((truck) => (queryString += `&truck_id=${truck}`));
  queryString += company_id ? `&company_id=${company_id}` : '';
  queryString += `&tz=${tz}`;
  return await axios.get(BASE_URL + `trips/tripGraph${queryString}`);
}

async function getCompanyFleets(id = null) {
  let queryString = id ? `?company_id=${id}` : '';
  return await axios.get(BASE_URL + `trips/companyFleetTypes${queryString}`);
}

export { getTripReport, getTripGraph, getCompanyFleets };
