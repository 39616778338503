import routeBasePath from './routeBasePath';
export default function hasPermission({ to, next, store }) {
  if (!store.state.user?.authenticated) {
    store.dispatch('asyncUpdateUserRolesFromMSAL').then(() => {
      return checkPermissions(to, next, store);
    });
  } else {
    return checkPermissions(to, next, store);
  }
}

function checkPermissions(to, next, store) {
  if (
    !store.state.user.roles.includes(`${to.meta.role}`) &&
    to.meta.role !== '*'
  ) {
    return routeBasePath({ next, store });
  } else {
    return next();
  }
}
