import axios from 'axios';

const BASE_URL = process.env.VUE_APP_API_PATH_BASE;

async function getERXCTrucks(company_id) {
  return await axios.get(
    BASE_URL + `dtc_dashboard/erx-trucks?company_id=${company_id}`
  );
}

async function geInitAllCharts(
  selected_date,
  start_date,
  end_date,
  company_id,
  truck_ids = ''
) {
  return await axios.get(
    BASE_URL +
      `dtc_dashboard/all?selected_date=${selected_date}&start_date=${start_date}&end_date=${end_date}&company_id=${company_id}&tz=America/Chicago${truck_ids}`
  );
}

export { getERXCTrucks, geInitAllCharts };
