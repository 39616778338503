var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "packages" } },
    [
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "pb-0",
                  attrs: { cols: "4", justify: "center" },
                },
                [
                  _c("v-text-field", {
                    attrs: {
                      placeholder: "Search",
                      solo: "",
                      "prepend-inner-icon": "mdi-magnify",
                      color: "primary",
                    },
                    model: {
                      value: _vm.searchText,
                      callback: function ($$v) {
                        _vm.searchText = $$v
                      },
                      expression: "searchText",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { align: "right" } },
                [
                  _vm.userCanUploadPackage
                    ? _c(
                        "v-btn",
                        {
                          staticStyle: { color: "black" },
                          attrs: { large: "", color: "primary" },
                          on: {
                            click: function ($event) {
                              _vm.showNewPackage = true
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("mdi-plus-circle-outline"),
                          ]),
                          _vm._v("Upload Package"),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "py-0",
                  attrs: {
                    cols: "4",
                    xs: "12",
                    sm: "12",
                    md: "12",
                    lg: "4",
                    xl: "4",
                  },
                },
                [
                  _c(
                    "v-tabs",
                    {
                      attrs: {
                        "fixed-tabs": "",
                        "background-color": "secondary",
                      },
                    },
                    [_c("v-tab", [_vm._v("ERX Packages")])],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "py-0" },
                [
                  _vm.loading
                    ? _c("v-skeleton-loader", {
                        attrs: { type: "table", elevation: "2" },
                      })
                    : [
                        _vm.switchTab === "ERX"
                          ? _c("erxpackages", {
                              attrs: {
                                "erx-packages": _vm.ERXPackages,
                                "search-text": _vm.searchText,
                              },
                              on: { refresh: _vm.refreshData },
                            })
                          : _vm._e(),
                      ],
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.showNewPackage
        ? _c("add-package", {
            on: { closed: _vm.closeNewPackage, refresh: _vm.refreshData },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }