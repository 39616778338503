<template>
  <v-container fluid>
    <v-row>
      <v-col lg="9">
        <h1 style="font-weight: 400">Alerts</h1>
      </v-col>
      <v-col align="right" lg="3">
        <v-row style="display: block; margin-top: 7px; margin-bottom: 7px">
          <!-- hide cog until we have functionality to add to nav drawer -->
          <!-- <v-btn
            icon
            tile
            large
            style="
              margin-right: 0.7rem;
              margin-left: 0.5rem;
              background-color: #1a1a1a;
              border-radius: 5px;
            "
            @click="drawer = true"
            ><v-icon style="opacity: 0.6">mdi-cog</v-icon></v-btn
          > -->
        </v-row>
      </v-col>
    </v-row>
    <v-card>
      <v-tabs v-model="tab" background-color="Secondary" dark>
        <v-tab v-for="item in tabItems" :key="item.tab">
          {{ item.tab }}
        </v-tab>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="item in tabItems" :key="item.tab">
            <v-card flat>
              <!-- HISTORY TAB -->
              <history v-if="item.content === 'history'" />
              <!-- SUBSCRIBE TAB -->
              <subscribe v-if="item.content === 'subscribe'" />
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-card>
    <v-navigation-drawer
      v-model="drawer"
      right
      temporary
      fixed
      width="360"
      class="shade"
    >
      <v-container>
        <v-row align="center">
          <v-col align="right" class="mb-2">
            <v-btn icon @click="drawer = false">
              <v-icon light>mdi-close-circle </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-expansion-panels>
          <v-expansion-panel class="shade">
            <v-expansion-panel-header> Preferences </v-expansion-panel-header>
            <v-expansion-panel-content>
              TBD
              <!-- Email vs SMS can go here -->
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-container>
    </v-navigation-drawer>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
import SubscribeAlerts from './SubscribeAlerts';
import HistoryAlerts from './HistoryAlerts';
export default {
  name: 'Alerts',
  components: {
    history: HistoryAlerts,
    subscribe: SubscribeAlerts,
  },
  data() {
    return {
      drawer: false,
      tab: null,
      tabItems: [
        { tab: 'History', content: 'history' },
        { tab: 'Subscribe', content: 'subscribe' },
      ],
    };
  },
  computed: {
    ...mapGetters({
      userRoles: 'getUserRoles',
    }),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
