<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" xl="4" lg="4">
        <h1
          class="transition-swing"
          style="font-weight: 400"
          v-text="'Data  Dictionary'"
        ></h1>
      </v-col>
      <v-col><v-spacer /></v-col>
      <v-col align="right" cols="12" xl="4" lg="4">
        <v-select
          v-if="tabItems[tab] === 'Signals'"
          :value="selectedDb"
          :items="dbTypes"
          item-text="state"
          item-value="abbr"
          label="DB Type"
          placeholder="DB Type"
          persistent-hint
          return-object
          outlined
          class="'select'"
          @change="setDataDictionaryDb"
        ></v-select>
      </v-col>
    </v-row>
    <v-row class="mt-0 mb-1">
      <v-col><v-spacer /></v-col>
      <v-col class="pt-0" align="right" cols="12" xl="6" lg="6">
        <v-btn
          v-if="tabItems[tab] === 'Signals'"
          color="primary"
          style="color: black"
          @click="exportCSV"
        >
          <download-csv id="csv" name="table.csv" :data="sourceDownload">
            <v-icon id="csv2" class="pr-1">mdi-download</v-icon>
          </download-csv>
          Export
        </v-btn>
        <v-btn
          v-if="tabItems[tab] === 'Cummins Testing'"
          :disabled="!cumminsDowload.length"
          color="primary"
          style="color: black"
          @click="exportCumminsCSV"
        >
          <download-csv
            id="csv_c"
            name="cummins_testing_data.csv"
            :data="cumminsDowload"
          >
            <v-icon id="csv_c2" class="pr-1">mdi-download</v-icon>
          </download-csv>
          Export
        </v-btn>
        <v-btn
          v-if="tabItems[tab] === 'Hybrid DTC'"
          class="mr-1"
          color="secondary"
          @click="uploadDTCSOpen = true"
        >
          <v-icon class="pr-1">mdi-upload</v-icon>
          Upload DTCS
        </v-btn>
        <v-btn
          v-if="tabItems[tab] === 'ERX DTC'"
          class="mr-1"
          color="secondary"
          @click="findDTCSOpen = true"
        >
          <v-icon class="pr-1">mdi-magnify</v-icon>
          Find Service Guide
        </v-btn>
        <v-btn
          v-if="tabItems[tab] === 'Signals'"
          color="primary"
          class="mx-1"
          style="color: black"
          @click="
            () => {
              this.$router.push(`/AddSignal`);
            }
          "
        >
          Add Signal
        </v-btn>
        <v-btn
          v-if="tabItems[tab] === 'Signals'"
          color="secondary"
          @click="setUploadModalOpen(true)"
        >
          <v-icon class="pr-1">mdi-upload</v-icon>
          Upload
        </v-btn>
        <v-btn
          v-if="tabItems[tab] === 'ERX DTC'"
          color="primary"
          class="mr-2"
          @click="$refs.erxDtcTable.addLinksToRows()"
          >Populate Links</v-btn
        >
        <v-btn
          v-if="tabItems[tab] === 'Signals'"
          icon
          tile
          large
          class="mx-1"
          style="
            margin-right: 0.7rem;
            margin-left: 0.5rem;
            background-color: #1a1a1a;
            border-radius: 5px;
          "
          @click="drawer = true"
          ><v-icon style="opacity: 0.6">mdi-cog</v-icon></v-btn
        >
      </v-col>
    </v-row>
    <v-col class="pa-2" cols="8" xs="12" sm="12" md="12" lg="10" xl="10">
      <v-tabs v-model="tab" background-color="secondary" fixed-tabs>
        <v-tab v-for="item in tabItems" :key="item">
          {{ item }}
        </v-tab>
      </v-tabs>
    </v-col>
    <v-card v-if="tabItems[tab] === 'ADX'">
      <adx-data-view />
    </v-card>
    <v-card v-if="tabItems[tab] === 'Cummins Testing'">
      <cummins-testing @updateCumminsData="updateCumminsData" />
    </v-card>
    <v-card v-if="tabItems[tab] === 'Documentation'">
      <workbook-table />
    </v-card>
    <v-card v-if="tabItems[tab] === 'ERX DTC'">
      <v-card flat>
        <erx-dtc-table ref="erxDtcTable" />
      </v-card>
    </v-card>
    <v-card v-if="tabItems[tab] === 'Hybrid DTC'">
      <v-card flat>
        <dtc-table />
      </v-card>
    </v-card>
    <v-card v-if="tabItems[tab] === 'Signals'">
      <signals
        :selected-db="selectedDb"
        :source="source"
        :data-headers="dataHeaders"
      />
    </v-card>
    <upload-dtcs v-if="uploadDTCSOpen" @closed="uploadDTCSOpen = false" />
    <find-excel v-if="findDTCSOpen" @closed="findDTCSOpen = false" />
    <v-dialog
      v-model="uploadModalOpen"
      width="500"
      content-class="alertdialog"
      @click:outside="setUploadModalOpen(false)"
    >
      <v-card>
        <v-card-title class="">
          {{ 'Upload JSON' }}
        </v-card-title>
        <v-divider></v-divider>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{
              'Number Added: ' + numberAdded
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{
              'Number Updated: ' + numberUpdated
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{
              'Number Skipped: ' + numberSkipped
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{
              'Number Unchanged: ' + numberUnchanged
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-card-actions>
          <v-col>
            <v-col>
              <v-file-input
                accept="application/JSON"
                outlined
                solo
                truncate-length="30"
                label="Upload JSON Configuration"
                @change="jsonFileSelected"
              ></v-file-input>
            </v-col>
            <v-col align="right">
              <v-btn
                color="primary"
                class="secondary-button"
                text
                @click="setUploadModalOpen(false)"
              >
                CANCEL
              </v-btn>
              <v-btn
                color="secondary"
                class="primary-button"
                text
                align="right"
                @click="
                  uploadJson(sourceTypes, signalTypes, unitsTypes, config)
                "
              >
                Upload
              </v-btn>
            </v-col>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-navigation-drawer
      v-model="drawer"
      disable-click-watcher
      right
      temporary
      fixed
      width="360"
      class="shade"
    >
      <v-row align="center" class="my-2 pl-5 pr-3">
        <v-col align="left" cols="9">Customize Columns:</v-col>
        <v-col align="right" cols="3">
          <v-btn icon @click="drawer = false">
            <v-icon light>mdi-close-circle </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-divider />
      <v-row>
        <v-col cols="12">
          <template class="mx-auto column-list">
            <v-list>
              <v-subheader>Select to display Columns</v-subheader>
              <v-list-item-group active-class="green--text" multiple>
                <template v-for="(column, index) in dataHeadersDup">
                  <v-list-item
                    v-if="column.text.length"
                    :key="column.value"
                    :disabled="!column.hideable"
                    @click="updateColumn(column, index)"
                  >
                    <v-list-item-action class="mr-5">
                      <v-icon v-if="!column.visible" color="grey">
                        mdi-square-outline
                      </v-icon>
                      <v-icon v-else color="primary">
                        mdi-checkbox-marked
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>{{ column.text }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon v-if="!column.hideable"> mdi-lock </v-icon>
                      <v-icon v-else> mdi-menu </v-icon>
                    </v-list-item-action>
                  </v-list-item>
                </template>
              </v-list-item-group>
            </v-list>
            <v-row class="pt-6">
              <v-col cols="5" class="py-0 pr-0 pl-7">
                <v-checkbox label="Make Preset"></v-checkbox>
              </v-col>
              <v-col cols="7">
                <v-btn
                  color="primary"
                  class="mr-2"
                  outlined
                  @click="drawer = false"
                  >Cancel</v-btn
                >
                <v-btn
                  color="primary"
                  style="color: black"
                  @click="drawer = false"
                  >Save</v-btn
                >
              </v-col>
            </v-row>
          </template>
        </v-col>
      </v-row>
    </v-navigation-drawer>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

import {
  getSourceDictionary,
  getDropdown,
  addSignal,
  putSignal,
} from '@/api/dataDictionary';

import UploadDTCS from './UploadDTCS';
import DtcTable from './DtcTable';
import ErxDtcTable from './ERXDtcTable';
import ADXDataView from './ADXDataView';
import FindDTCSheet from './FindDTCSheet';
import WorkbookTable from './WorkbookTable';
import CumminsTesting from './CumminsTesting';
import Signals from './Signals';

export default {
  name: 'DataDictionary',
  components: {
    'upload-dtcs': UploadDTCS,
    'dtc-table': DtcTable,
    'erx-dtc-table': ErxDtcTable,
    'adx-data-view': ADXDataView,
    'find-excel': FindDTCSheet,
    'workbook-table': WorkbookTable,
    'cummins-testing': CumminsTesting,
    signals: Signals,
  },
  props: [],
  data() {
    return {
      selectedDb: { state: 'Sample B Data', abbr: 'sampleBData' },
      drawer: false,
      tab: 0,
      tabItems: [
        'ADX',
        'Cummins Testing',
        'Documentation',
        'ERX DTC',
        'Hybrid DTC',
        'Signals',
      ],
      actionItems: [{ title: 'Delete' }, { title: 'Edit' }],
      dbTypes: [
        { state: 'Sample B Data', abbr: 'sampleBData' },
        { state: 'EX One Minute', abbr: 'exOneMinute' },
        { state: 'Ex Telemetry', abbr: 'ExTelemetry' },
      ],
      dataHeaders: [
        {
          text: 'Column Name',
          value: 'column_name',
          align: 'center',
          sortable: true,
          visible: true,
          hideable: true,
        },
        {
          text: 'Signal Name',
          value: 'signal',
          align: 'center',
          sortable: true,
          visible: true,
          hideable: true,
        },
        {
          text: 'Comment',
          value: 'comment',
          align: 'center',
          sortable: true,
          visible: true,
          hideable: true,
        },
        {
          text: 'Type',
          value: 'signal_type_id',
          align: 'center',
          sortable: true,
          visible: true,
          hideable: true,
        },
        {
          text: 'Units',
          value: 'units_id',
          align: 'center',
          sortable: true,
          visible: true,
          hideable: true,
        },
        {
          text: 'Max',
          value: 'max',
          align: 'center',
          sortable: true,
          visible: true,
          hideable: true,
        },
        {
          text: 'Min',
          value: 'min',
          align: 'center',
          sortable: true,
          visible: true,
          hideable: true,
        },
        {
          text: 'Choices',
          value: 'choicesString',
          align: 'center',
          sortable: true,
          visible: true,
          hideable: true,
        },
        {
          text: 'Actions',
          width: '0.1%',
          value: 'actions',
          sortable: false,
          align: 'right',
          visible: false,
          hideable: false,
        },
      ],
      dataHeadersDup: [],
      findDTCSOpen: false,
      uploadDTCSOpen: false,
      uploadModalOpen: false,
      dropdown: null,
      breadcrumbs: [
        {
          text: 'Data Dictionary',
          disabled: true,
          href: '/DataDictionary',
        },
      ],
      source: [],
      signal: [],
      sourceDownload: [],
      cumminsDowload: [],
      sourceObject: {},
      controllerState: [],
      bitField: [],
      sourceTypes: [],
      unitsTypes: [],
      statusTypes: [],
      signalTypes: [],
      commentLength: 0,
      numberAdded: 0,
      numberUpdated: 0,
      numberSkipped: 0,
      numberUnchanged: 0,
      displayConfig: '',
      config: {
        config_data: {},
      },
      fileImported: false,
    };
  },
  computed: {
    ...mapGetters({
      userRoles: 'getUserRoles',
      storeSelectedDb: 'getDataDictionaryDb',
      storedSignal: 'getSignal',
      isEditSignal: 'getIsEditSignal',
      storedTruckType: 'getTruckType',
    }),
  },
  async mounted() {
    this.getData();
  },
  async created() {
    this.dataHeadersDup = [...this.dataHeaders];
  },
  methods: {
    ...mapMutations(['updateDataDictionaryDb']),
    setDataDictionaryDb: async function (event) {
      try {
        this.updateDataDictionaryDb(event);
        this.getData();
      } catch (e) {
        console.log(e);
      }
    },
    mapDropdowns(dropdown) {
      return dropdown.map((ele) => {
        return { state: ele.name, abbr: ele.id };
      });
    },
    setUploadModalOpen(open) {
      if (open) {
        this.numberAdded = 0;
        this.numberUpdated = 0;
        this.numberSkipped = 0;
        this.numberUnchanged = 0;
      }
      this.uploadModalOpen = open;
    },
    setDropdown(dropdown) {
      this.dropdown = dropdown;
      this.signalTypes = this.mapDropdowns(dropdown.signalType);
      this.sourceTypes = this.mapDropdowns(dropdown.source);
      this.unitsTypes = this.mapDropdowns(dropdown.units);
    },
    findSourceStatusUnits(source, units, type) {
      let dropdownValues = {
        source: this.sourceTypes.find((ele) => source == ele.abbr),
        type: this.signalTypes.find((ele) => type == ele.abbr),
        units: this.unitsTypes.find((ele) => units == ele.abbr),
      };
      return dropdownValues;
    },
    updateColumn: function (column, index) {
      this.dataHeadersDup[index].visible = !column.visible;
      this.updateHeaders(column, column.visible);
    },
    updateHeaders: function (column, visible) {
      if (visible) {
        const length = this.dataHeaders.length;
        const label = this.dataHeadersDup.find((lb) => lb.text === column.text);
        const index = this.dataHeadersDup.findIndex(
          (lb) => lb.text === column.text
        );
        if (index >= length) {
          // Keeps menu at the end of the columns
          this.dataHeaders.splice(this.dataHeaders.length - 1, 0, label);
        } else {
          this.dataHeaders.splice(index, 0, label);
        }
      } else {
        this.dataHeaders = this.dataHeaders.filter(
          (hl) => hl.text !== column.text
        );
      }
    },
    jsonFileSelected: function (file) {
      if (!file) {
        this.displayConfig = '';
        this.config.config_data = {};
      } else {
        const fr = new FileReader();

        fr.readAsText(file);
        fr.onload = () => {
          const formatted = fr.result;
          this.fileImported = true;
          this.config.config_data = JSON.parse(formatted);
          this.displayConfig = formatted;
        };
      }
    },
    uploadJson: async function (sourceTypes, signalTypes, unitsTypes, config) {
      try {
        let commentLength = 0;
        this.numberAdded = 0;

        this.numberUpdated = 0;

        this.numberSkipped = 0;

        this.numberUnchanged = 0;
        config.config_data.columns.forEach((elem) => {
          let sourceType = elem.src_name
            ? sourceTypes.find((ele) => {
                elem.src_name.toLowerCase() == ele.state;
              })
            : null;
          let signal_type_id = signalTypes.find((ele) => {
            if (elem.type === 'DOUBLE') {
              //map double to real
              elem.type = 'REAL';
            }
            return elem.type.toLowerCase() == ele.state.toLowerCase();
          });
          let units_id = elem.units
            ? unitsTypes.find((ele) => {
                return elem.units.toLowerCase() == ele.state.toLowerCase();
              })
            : null;
          let choicesArray = [];
          if (elem.choices) {
            for (const key in elem.choices) {
              if (Object.hasOwnProperty.call(elem.choices, key)) {
                const element = elem.choices[key];
                choicesArray.push({
                  key: key,
                  value: element,
                });
              }
            }
          }
          if (elem.comment && elem.comment.length > commentLength) {
            commentLength = elem.comment.length;
          }
          let column = {
            src_id: sourceType?.abbr,
            column_name: elem.column,
            signal: elem.signal,
            comment: elem.comment,
            adx_db_table: this.selectedDb.abbr,
            signal_type_id: signal_type_id?.abbr,
            units_id: units_id?.abbr,
            restricted: true,
            choices: choicesArray,
            max: elem.max ? elem.max : null,
            min: elem.min ? elem.min : null,
            publish: elem.publish,
            store: elem.store,
            truck_type: 'ERX',
          };
          if (elem.choices?.length > 0 && elem.type === 'INT') {
            //default column to STATE type under these conditions
            column.type === 3;
          }
          if (this.sourceObject[column.signal]) {
            let originalColumn = this.sourceObject[column.signal];

            if (
              originalColumn.min === column.min &&
              originalColumn.max === column.max &&
              originalColumn.comment === column.comment &&
              JSON.stringify(originalColumn.choices) ==
                JSON.stringify(column.choices)
            ) {
              // console.log('lets not do a put', column.signal);

              if (originalColumn.type !== column.type) {
                this.numberSkipped += 1;
              } else {
                this.numberUnchanged += 1;
              }
            } else {
              putSignal(column, originalColumn.id);
              this.numberUpdated += 1;
            }
          } else {
            addSignal(column);
            this.numberAdded += 1;
          }
        });
      } catch (e) {
        console.log(
          e,
          'Error: something went wrong while trying to save the Signal'
        );
      }
    },
    getData: async function () {
      try {
        this.selectedDb = { ...this.storedSignal.adx_db_table };
        const dropdown = await getDropdown();
        this.setDropdown(dropdown.data);
        const source = await getSourceDictionary(this.selectedDb.abbr);
        this.source = source.data.filter((truck) => {
          return truck.truck_type === this.storedTruckType.abbr;
        });
        this.sourceDownload = source.data.map((ele) => {
          let dropdownValues = this.findSourceStatusUnits(
            ele.src_id,
            ele.units_id,
            ele.signal_type_id
          );
          ele.src_id = dropdownValues.source?.state;
          ele.units_id = dropdownValues.units?.state;
          ele.signal_type_id = dropdownValues.type?.state;
          ele.choicesString = '';
          this.sourceObject[ele.signal] = ele;
          if (ele.choices.length > 0) {
            let string = '';
            ele.choices.map((choice, i) => {
              if (ele.choices && ele.choices.length === i + 1) {
                string += choice.key + ': ' + choice.value;
              } else {
                string += choice.key + ': ' + choice.value + ', ';
              }
            });
            ele.choicesString = string;
          }
          return ele;
        });
      } catch (e) {
        console.log(e, 'error in retrieving the source or signal dictionary');
      }
    },
    exportCSV(event) {
      if (event.target.id != 'csv' && event.target.id != 'csv2') {
        document.getElementById('csv').click();
      }
    },
    exportCumminsCSV(event) {
      if (event.target.id != 'csv_c' && event.target.id != 'csv_c2') {
        document.getElementById('csv_c').click();
      }
    },
    updateCumminsData(data) {
      this.cumminsDowload = data;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media only screen and (min-width: 960px) {
  .select {
    max-width: 60px;
    max-height: 60px;
    font-size: 11px;
  }
  .primary-button {
    background-color: #48ff48;
  }
  .secondary-button {
    border-color: #48ff48;
  }
}
</style>
