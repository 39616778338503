import axios from 'axios';

const BASE_URL = process.env.VUE_APP_API_PATH_BASE;

async function getAllTrucks() {
  return await axios.get(BASE_URL + 'trucks/configuration');
}

async function getHealthTrucks(id, truck_type = null) {
  let queryString = '?removeUninstalled=true';
  queryString += id ? `&id=${id}` : '';
  queryString += truck_type ? `&truck_type=${truck_type}` : '';
  return await axios.get(BASE_URL + `trucks/health${queryString}`);
}

// DEPRECATED
async function getMapTrucks() {
  return await axios.get(BASE_URL + 'trucks/td?removeUninstalled=true');
}

async function getCloudTrucks() {
  return await axios.get(BASE_URL + 'trucks');
}

async function getInvervalTrucks(maxTime) {
  return await axios.get(
    BASE_URL + `trucks/td?removeUninstalled=true&queryTime=${maxTime}`
  );
}

async function getADXHealthData(id) {
  const truck_id = `?truck_id=${id}`;
  return await axios.get(BASE_URL + `trucks/adx_health${id ? truck_id : ''}`);
}
//DEPRECATED
async function getErxFuelInfo(id) {
  return await axios.get(BASE_URL + `trucks/erx_fuelinfo/${id ? id : ''}`);
}

async function getADXHealthERXData(id, company) {
  const truck_id = id ? `?truck_id=${id}` : '';
  const company_id = company ? `&company_id=${company}` : '';
  return await axios.get(
    BASE_URL + `trucks/adx_health_erx${truck_id}${company_id}`
  );
}

async function getADXLastAhead(truck_id) {
  const truck = truck_id ? truck_id : '';
  return await axios.get(BASE_URL + `trucks/lastaheadtime/truck/${truck}`);
}

async function getADXLastPing() {
  return await axios.get(BASE_URL + 'trucks/lastping');
}

async function getLastSpeed(truck_type = null) {
  if (truck_type && truck_type.toUpperCase() === 'ERX') {
    return await axios.get(BASE_URL + 'trucks/lastspeed_erx');
  } else {
    return await axios.get(BASE_URL + 'trucks/lastspeed');
  }
}

async function getADXLastOdometer(truck_id) {
  return await axios.get(BASE_URL + `trucks/lastodometer/${truck_id}`);
}

async function getTruckSIM(sim) {
  return await axios.get(BASE_URL + `trucks/sim_usage?sim=${sim}`);
}

async function getTruckTireWear(truck_id) {
  return await axios.get(BASE_URL + `tire_wear/truck/${truck_id}`);
}

async function getLastScan(truck_id) {
  return await axios.get(BASE_URL + `tire_wear/truck/${truck_id}/scan/`);
}

//DEPRECATED
// async function updateTruckLicensePlate(truck_id, license_plate) {
//   return await axios.patch(BASE_URL + `configurations/license_plate`, {
//     truck_id,
//     license_plate,
//   });
// }

async function getTruckHistory(truck_id) {
  return await axios.get(
    BASE_URL + `configurations/truck_history?id=${truck_id}`
  );
}

async function getTruckTypes() {
  return await axios.get(BASE_URL + 'registration/truck_types');
}

async function setNewTrucks(config) {
  return await axios.post(BASE_URL + 'registration/register_unit', config);
}

async function checkVin(vinVal) {
  const query = `https://vpic.nhtsa.dot.gov/api/vehicles/decodevinvalues/${vinVal}?format=json`;
  return await axios.get(query);
}

// DEPRECATED
async function getTruckDTCs(id, count) {
  const countCheck = count ? `?count=${count}` : '';
  return await axios.get(BASE_URL + `trucks/dtc/truck/${id}${countCheck}`);
}

async function getActiveDTCs() {
  return await axios.get(BASE_URL + `telemetry/dtc/active`);
}

async function getLatestDTCs(truck_id) {
  const truck = truck_id ? truck_id : '';
  return await axios.get(BASE_URL + `telemetry/dtc/latest/${truck}`);
}

// DEPRECATED
async function updateTruckStatus(status_string, truck_id, data_ops) {
  return await axios.patch(
    BASE_URL + `trucks/${status_string}/${truck_id}`,
    data_ops
  );
}

async function updateUninstalledStatus(uninstalled, truck_id) {
  return await axios.patch(
    BASE_URL + `trucks/uninstalled/${truck_id}?uninstalled=${uninstalled}`
  );
}

async function getHistoryDTCs(truck_id) {
  const truck = truck_id ? truck_id : '';
  return await axios.get(BASE_URL + `telemetry/dtc/history/${truck}`);
}

// DEPRECATED
async function updateTruckAction(truck_id, action) {
  return await axios.patch(BASE_URL + `trucks/action/${truck_id}`, {
    action: action,
  });
}

// DEPRECATED
async function updateTruckIssue(truck_id, issue) {
  return await axios.patch(BASE_URL + `trucks/issue/${truck_id}`, {
    issue: issue,
  });
}

async function getTruckData(inputDate, endDate) {
  return await axios.get(
    BASE_URL + `telemetry/getTruckData/${inputDate}/${endDate}`
  );
}

async function getPastYear(truck_id) {
  return await axios.get(BASE_URL + `trucks/pastyear/${truck_id}`);
}

async function getERXDetails(truck_id) {
  return await axios.get(BASE_URL + `trucks/erxTruckDetails/${truck_id}`);
}

async function getADXLocations(truck_id = null) {
  let queryString = truck_id ? `?truck_id=${truck_id}` : '';
  return await axios.get(BASE_URL + `trucks/adx_locations${queryString}`);
}

async function getEndOfLine(truck_id, startDate, endDate, ex) {
  startDate = Date.parse(startDate);
  endDate = Date.parse(endDate);
  const queryString = truck_id
    ? `?start=${startDate}&end=${endDate}&truck_id=${truck_id}`
    : '';
  if (ex) {
    return await axios.post(
      BASE_URL + `trucks/health/end_of_line/ex/${queryString}`
    );
  } else {
    return await axios.post(
      BASE_URL + `trucks/health/end_of_line/erx/${queryString}`
    );
  }
}

async function getEndOfLineHistory(id) {
  const truck_id = `?truck_id=${id}`;
  return await axios.get(BASE_URL + `trucks/end_of_line_history${truck_id}`);
}

async function getTruckUsage(truck_id) {
  return await axios.get(BASE_URL + `trucks/truck_usage?id=${truck_id}`);
}

async function addTruckUsage(data) {
  return await axios.post(BASE_URL + `trucks/truck_usage`, data);
}
async function getERXDTCs() {
  return await axios.get(BASE_URL + `dtc/active/erx`);
}
async function dtcLookup(dtc) {
  return await axios.get(BASE_URL + `dtc/dtcLookup?spnfmi=${dtc}`);
}

//DEPRECATED
async function getErxDtcHistory(truck_id, start, end) {
  let queryString = `?truck_id=${truck_id}`;
  start && (queryString += `&start=${start}`);
  end && (queryString += `&end=${end}`);
  return await axios.get(BASE_URL + `dtc/active_history/erx${queryString}`);
}

async function getStatusGraph(onlyTally = true) {
  return await axios.get(BASE_URL + `dtc/statusGraph?onlyTally=${onlyTally}`);
}

async function reassignCompany(truck_id, company_id) {
  return await axios.patch(BASE_URL + `trucks/company/${truck_id}`, {
    company_id,
  });
}

export {
  getAllTrucks,
  getHealthTrucks,
  getMapTrucks,
  getCloudTrucks,
  getInvervalTrucks,
  getADXHealthData,
  getADXHealthERXData,
  getADXLastAhead,
  getADXLastPing,
  getADXLastOdometer,
  getActiveDTCs,
  getLatestDTCs,
  getHistoryDTCs,
  getTruckSIM,
  getTruckTireWear,
  getLastScan,
  // updateTruckLicensePlate,
  updateUninstalledStatus,
  getTruckTypes,
  setNewTrucks,
  checkVin,
  getTruckHistory,
  getTruckDTCs,
  updateTruckStatus,
  updateTruckAction,
  updateTruckIssue,
  getLastSpeed,
  getTruckData,
  getPastYear,
  getERXDetails,
  getADXLocations,
  getEndOfLine,
  getEndOfLineHistory,
  getTruckUsage,
  addTruckUsage,
  getERXDTCs,
  dtcLookup,
  getErxDtcHistory,
  getStatusGraph,
  getErxFuelInfo,
  reassignCompany,
};
