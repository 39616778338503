var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c("v-col", [
            _c("h3", [_vm._v(_vm._s(_vm.dtcsLength || 0) + " Results")]),
          ]),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c(
                "v-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "v-row",
                    {
                      staticStyle: {
                        "background-color": "#272727",
                        "border-radius": "5px",
                      },
                    },
                    _vm._l(_vm.icons, function (item, index) {
                      return _c(
                        "v-col",
                        { key: index },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { right: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c(
                                          "td",
                                          { staticClass: "mx-0 px-0" },
                                          [
                                            item.icon
                                              ? _c("icon-loader", {
                                                  staticClass: "px-0 mx-0",
                                                  attrs: {
                                                    "icon-show": item.icon,
                                                    on: on,
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [_c("span", [_vm._v(_vm._s(item.tooltip))])]
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _vm.loading
                ? _c("v-skeleton-loader", {
                    attrs: { type: "table", elevation: "2" },
                  })
                : _c("v-data-table", {
                    staticClass: "elevation-1",
                    attrs: {
                      headers: _vm.headers,
                      items: _vm.dtcs,
                      "items-per-page": 15,
                      expanded: _vm.expanded,
                      "item-key": "id",
                      "show-expand": "",
                    },
                    on: {
                      "update:expanded": function ($event) {
                        _vm.expanded = $event
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "item.name",
                        fn: function ({ item }) {
                          return [
                            item.workbook_link !== "---"
                              ? _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: item.workbook_link,
                                      target: "_blank",
                                    },
                                  },
                                  [_vm._v(_vm._s(item.name))]
                                )
                              : _vm._e(),
                            item.workbook_link === "---"
                              ? _c("div", [_vm._v(_vm._s(item.name))])
                              : _vm._e(),
                          ]
                        },
                      },
                      {
                        key: "expanded-item",
                        fn: function ({ item }) {
                          return [
                            _c(
                              "td",
                              {
                                staticStyle: {
                                  "background-color": "#272727",
                                  padding: "0",
                                },
                                attrs: { colspan: _vm.headers.length + 1 },
                              },
                              [
                                _c("v-data-table", {
                                  staticClass: "elevation-1 shade",
                                  attrs: {
                                    headers: _vm.expandedHeaders,
                                    items: item.causes,
                                    "hide-default-footer": true,
                                    "items-per-page": -1,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }