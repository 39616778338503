<template>
  <div id="trucks">
    <loader v-if="loading" />
    <v-container fluid>
      <v-row>
        <v-col class="py-0">
          <v-data-table
            :headers="headers"
            :items="erxTrucks"
            class="elevation-1 secondary locked-table-style row-height-50"
            :search="searchText"
          >
            <template v-slot:[`item.name`]="props">
              <th class="body-5">{{ props.item.number }}</th>
              <div class="body-6">{{ props.item.company }}</div>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-menu style="top: 312px; left: 300px" offset-y>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-dots-vertical</v-icon>
                </template>
                <v-list dense>
                  <v-list-item-group>
                    <v-list-item
                      v-for="(action, index) in actionItems"
                      :key="index"
                      :class="action.title"
                      @click="setAction(item, action.title)"
                      ><v-list-item-icon class="mr-2">
                        <v-icon>{{ action.icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title class="pr-2"></v-list-item-title
                        >{{ action.title }}
                      </v-list-item-content></v-list-item
                    >
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <!-- Assign Package Dialog-->
      <v-dialog v-model="assignPackageModal" width="500" persistent>
        <v-card class="pa-5 text-center">
          <v-row>
            <v-col>
              <v-select
                v-model="stateSelected"
                class="ma-1"
                :items="availableStates"
                return-object
                clearable
                hide-selected
                outlined
                label="Select State"
                @change="filterPackages"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                v-model="selectedPackage"
                class="ma-1"
                :items="packagesDup"
                item-text="config_name"
                item-value="id"
                return-object
                clearable
                hide-selected
                outlined
                no-data-text="No Packages Available"
                label="Select Package"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                color="primary"
                class="mr-2"
                outlined
                @click="assignPackageModal = false"
                >Dismiss</v-btn
              >
            </v-col>
            <v-col>
              <v-btn
                color="primary"
                style="color: black"
                :disabled="!selectedPackage || submitClicked"
                @click="assignPackage()"
                >Confirm</v-btn
              >
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
      <!-- Re-Assign Company Dialog-->
      <v-dialog v-model="reassignCompanyDialog" width="530" persistent>
        <v-card class="pa-5 text-center">
          <v-row
            ><v-col> <h3>Re-Assign Company</h3> </v-col></v-row
          >
          <v-row>
            <v-col class="pb-0">
              <v-select
                v-model="selectedCompany"
                class="ma-1"
                :items="companyList"
                item-text="name"
                item-value="id"
                clearable
                hide-selected
                outlined
                label="Select New Company"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span class="bold Warning"
                >WARNING: This change will affect the data flow for this truck
                and will no longer report data for the current company it is
                assigned to!</span
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                color="primary"
                class="mr-2"
                outlined
                @click="reassignCompanyDialog = false"
                >Dismiss</v-btn
              >
            </v-col>
            <v-col>
              <v-btn
                color="primary"
                style="color: black"
                :disabled="!selectedCompany || submitClicked"
                @click="reassignCompany()"
                >Confirm</v-btn
              >
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  getAllConfigurations,
  assignTrucksToConfiguration,
} from '@/api/configurations';
import { getCompanies } from '@/api/company';
import { reassignCompany } from '@/api/trucks';
import loader from '@/utilities/loader';
export default {
  name: 'ERXTrucks',
  components: { loader },
  props: {
    erxTrucks: {
      type: Array,
      required: true,
    },
    searchText: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      actionItems: [{ title: 'View Package History', icon: 'mdi-history' }],
      headers: [
        { text: 'Truck # / Customer', value: 'name' },
        { text: 'Truck Type', value: 'truck_type' },
        { text: 'VIN', value: 'vin' },
        { text: 'Software Status', value: 'software_status' },
        { text: 'HDP Version', value: 'hdp_version' },
        { text: 'HCU Version', value: 'hcu_version' },
        { text: 'Gateway Version', value: 'sgw_version' },
        { text: 'Target Package', value: 'target_package' },
        {
          text: '',
          value: 'actions',
          sortable: false,
          align: 'right',
        },
      ],
      loading: false,
      submitClicked: false,
      availableStates: [],
      stateSelected: 'supported',
      assignPackageModal: false,
      reassignCompanyDialog: false,
      packages: [],
      packagesDup: [],
      companyList: [],
      selectedCompany: null,
      selectedPackage: null,
      selectedTruck: null,
    };
  },
  computed: {
    ...mapGetters({
      userRoles: 'getUserRoles',
    }),
  },
  watch: {},
  async created() {
    // add assign package role to actions
    if (
      this.userRoles.includes('config.support') ||
      this.userRoles.includes('config.verify') ||
      this.userRoles.includes('config.release') ||
      this.userRoles.includes('config.assign_ext')
    ) {
      this.actionItems.unshift({
        title: 'Assign Package',
        icon: 'mdi-cloud-upload',
      });
    }
    // filter state options based on roles
    if (
      this.userRoles.includes('config.support') ||
      this.userRoles.includes('config.verify')
    ) {
      this.availableStates.push('supported', 'verified', 'available');
    } else if (this.userRoles.includes('config.release')) {
      this.availableStates.push('verified', 'available');
    } else if (this.userRoles.includes('config.assign_ext')) {
      this.availableStates.push('available');
    }

    // re-assign custom on truck
    if (
      this.userRoles.includes('admin') ||
      this.userRoles.includes('reassign')
    ) {
      this.actionItems.push({
        title: 'Re-Assign Company',
        icon: 'mdi-truck-delivery-outline',
      });
    }
  },
  methods: {
    ...mapActions({
      updateSnack: 'updateSnack',
    }),
    filterPackages() {
      if (this.stateSelected) {
        this.packagesDup = this.packages.filter(
          (p) => p.status === this.stateSelected
        );
      }
    },
    async setAction(truck, action) {
      this.selectedTruck = truck.id;
      if (action === 'Assign Package') {
        this.loading = true;
        try {
          // External Assignment Permission
          if (
            truck.company_id !== 4 &&
            !this.userRoles.includes('config.assign_ext')
          ) {
            this.updateSnack({
              type: 'error',
              message:
                'You do not have permission to assign a package to this truck!',
            });
            this.loading = false;
            return;
          }
          const res = await getAllConfigurations();
          this.packages = res.data.filter(
            (p) =>
              this.availableStates.includes(p.status) &&
              p.truck_type === truck.truck_type.toLowerCase()
          );
          this.filterPackages();
          this.assignPackageModal = true;
          this.loading = false;
        } catch (e) {
          this.updateSnack({
            type: 'error',
            message: 'Error retrieving Packages',
          });
        }
      } else if (action === 'View Update History') {
        // Todo: Add View Update History modal
      } else if (action === 'Re-Assign Company') {
        // Open modal
        if (!this.companyList.length) {
          const { data } = await getCompanies();
          this.companyList = data.filter((t) => t.id !== truck.id);
        }
        this.reassignCompanyDialog = true;
      }
    },
    async assignPackage() {
      this.loading = true;
      this.submitClicked = true;
      try {
        let iotStatus = await assignTrucksToConfiguration({
          config_id: this.selectedPackage.id,
          truck_ids: [this.selectedTruck],
        });
        iotStatus = iotStatus.data;
        if (iotStatus.succeededCount === 1) {
          this.updateSnack({
            type: 'success',
            message: 'Package assigned successfully!',
          });
          this.$emit('refresh');
        }
        if (iotStatus.failedCount === 1) {
          this.updateSnack({
            type: 'error',
            message: 'Error assigning package to truck!',
          });
        }
        if (iotStatus.deviceCount === 0) {
          this.updateSnack({
            type: 'error',
            message: 'There are no associated IoT devices!',
          });
        }
        if (iotStatus.pendingCount > 0 || iotStatus.runningCount > 0) {
          this.updateSnack({
            type: 'warning',
            message: 'IoT Hub updates are still pending',
          });
        }
      } catch (e) {
        this.updateSnack({
          type: 'error',
          message: 'Error assigning package to truck!',
        });
      }
      this.assignPackageModal = false;
      this.loading = false;
      this.submitClicked = false;
    },
    async reassignCompany() {
      this.loading = true;
      this.submitClicked = true;
      try {
        await reassignCompany(this.selectedTruck, this.selectedCompany);
        this.updateSnack({
          type: 'success',
          message: 'Truck re-assigned successfully!',
        });
        this.$emit('refresh');
      } catch {
        this.updateSnack({
          type: 'error',
          message: 'Error re-assigning Truck!',
        });
      }
      this.loading = false;
      this.submitClicked = false;
      this.reassignCompanyDialog = false;
    },
  },
};
</script>
<style scoped>
.bold {
  font-weight: bold;
}
</style>
