var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-data-table", {
    staticClass: "dark-background",
    attrs: {
      "no-data-text": _vm.noDataMsg,
      headers: _vm.labels,
      items: _vm.trucks,
    },
    scopedSlots: _vm._u(
      [
        {
          key: `item.status`,
          fn: function ({ item }) {
            return [
              _c(
                "v-tooltip",
                {
                  attrs: { left: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on }) {
                          return [
                            _c(
                              "td",
                              {
                                class: item.status,
                                staticStyle: { color: "#888888" },
                              },
                              [
                                _c(
                                  "v-icon",
                                  _vm._g(
                                    {
                                      attrs: {
                                        id: "icon",
                                        color: "#888888",
                                        large: "",
                                      },
                                    },
                                    on
                                  ),
                                  [_vm._v(" mdi-truck")]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                },
                [_c("span", [_vm._v("Offline")])]
              ),
            ]
          },
        },
        {
          key: `item.actions`,
          fn: function ({ item }) {
            return [
              _c(
                "td",
                {
                  staticStyle: { color: "#43b02a", cursor: "pointer" },
                  on: { click: () => _vm.viewTruck(item) },
                },
                [_vm._v(" VIEW ")]
              ),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }