const constants = {
  DGE_PER_KG: 0.35274,
  DGE_PER_GGE: 0.88,
  KM_PER_MI: 1.60934,
  KG_CO2_PER_DGE: 7.59,
  KG_CO2_PER_DG: 10.21,
  KG_CO2_PER_DG_B5: 10.17,
  LB_PER_KG: 2.20462,
  KG_CO2_PER_KG_CNG: 2.276,
  G_N20_PER_MI: 0.0431,
};

const sourceAddresses = [
  { source: '0', component: 'Engine', display: true },
  { source: '3', component: 'Hyliion Powertrain', display: true },
  { source: '5', component: 'Stalk Shifter', display: true },
  { source: 'B', component: 'Bendix - ESP', display: true },
  { source: '12', component: 'Fuel System', display: true },
  { source: '17', component: 'Instrument Cluster', display: true },
  { source: '19', component: 'Peterbilt HVAC', display: true },
  { source: '2A', component: 'Bendix - Radar', display: true },
  { source: '27', component: 'Body Controller', display: true },
  { source: '30', component: 'Pneumatic - System Controller', display: true },
  { source: '33', component: 'Tire Pressure Controller', display: true },
  { source: '4D', component: 'Bendix Smart Wheel', display: true },
  { source: '80', component: 'EVCC', display: false },
  { source: '8A', component: 'EMP Pump', display: false },
  { source: '8B', component: 'Fan1', display: false },
  { source: '8C', component: 'Fan2', display: false },
  { source: '8D', component: 'Fan3', display: false },
  { source: '8E', component: 'Fan4', display: false },
  { source: 'D1', component: 'Bendix SmartWay Controller', display: true },
  { source: 'E8', component: 'Bendix - FLC', display: true },
  { source: 'EC', component: 'Door Controller #1', display: true },
  { source: 'ED', component: 'Door Controller #2', display: true },
];

function getCO2EmissionsFactor(fuel) {
  let emissions_factor;
  switch (fuel.toLowerCase()) {
    default:
    case 'diesel':
      emissions_factor = 22.51; // lb CO2 / gallon
      break;
    case 'biodiesel b5':
      emissions_factor = 22.42; // lb CO2 / gallon
      break;
    case 'cng':
      emissions_factor = 16.73; // lb CO2 / DGE
      break;
    case 'rng':
      emissions_factor = 0; // lb CO2 / DGE
      break;
  }
  return emissions_factor;
}

function getNOxEmissionsFactor(fuel) {
  let lb_N2O_PER_MI;
  switch (fuel) {
    default:
    case 'diesel':
      lb_N2O_PER_MI = 0.000095019;
      break;
    case 'biodiesel b5':
      lb_N2O_PER_MI = 0.000095082;
      break;
    case 'cng':
      lb_N2O_PER_MI = 0.000002204;
      break;
    case 'rng':
      lb_N2O_PER_MI = 0;
      break;
  }
  return lb_N2O_PER_MI;
}

function getN2OReduction(
  kmTraveled,
  currentFuel,
  comparisonFuel,
  c = constants
) {
  if (kmTraveled != null && currentFuel != null && comparisonFuel != null) {
    const lb_N2O_PER_MI = getNOxEmissionsFactor(currentFuel);
    const comp_lb_N2O_PER_MI = getNOxEmissionsFactor(comparisonFuel);
    const n2o_emitted = (kmTraveled / c.KM_PER_MI) * lb_N2O_PER_MI;
    const comp_n2o_emitted = (kmTraveled / c.KM_PER_MI) * comp_lb_N2O_PER_MI;
    return comp_n2o_emitted - n2o_emitted;
  }
  return null;
}

function getCO2Reduction(
  kmTraveled,
  currentFuel,
  comparisonFuel,
  comparisonMPG,
  kgFuel,
  c = constants
) {
  if (kgFuel && currentFuel != null && comparisonFuel != null) {
    const emissions_factor = getCO2EmissionsFactor(currentFuel);
    const comparison_emissions_factor = getCO2EmissionsFactor(comparisonFuel);
    const gallonsFuel = kgFuel * c.DGE_PER_KG;
    const cng_lb_co2 = gallonsFuel * emissions_factor;
    const comparison_gallons = kmTraveled / c.KM_PER_MI / comparisonMPG;
    const comparison_lb_co2 = comparison_gallons * comparison_emissions_factor;
    return comparison_lb_co2 - cng_lb_co2;
  }
  return null;
}

export { constants, sourceAddresses, getCO2Reduction, getN2OReduction };
