var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c("v-col", [_vm._v(_vm._s(_vm.items.length) + " results")]),
          _c(
            "v-col",
            { attrs: { align: "right" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function ($event) {
                      _vm.esgDrawer = !_vm.esgDrawer
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi mdi-dots-vertical")])],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.loading
        ? _c("v-skeleton-loader", { attrs: { type: "table", elevation: "2" } })
        : _c("v-data-table", {
            staticClass: "elevation-1 locked-table-style",
            attrs: {
              headers: _vm.headers,
              items: _vm.items,
              "items-per-page": 25,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: `item.companyNumber`,
                  fn: function ({ item }) {
                    return [
                      _c("th", { staticClass: "body-5" }, [
                        _vm._v(_vm._s(item.truck_name)),
                      ]),
                      _c("div", { staticClass: "body-6" }, [
                        _vm._v(_vm._s(item.company)),
                      ]),
                    ]
                  },
                },
                _vm._l(_vm.headers, function (h) {
                  return {
                    key: `header.${h.value}`,
                    fn: function ({ header }) {
                      return [
                        header.hasOptions
                          ? [
                              _c("span", { key: h.text }, [
                                _vm._v(_vm._s(_vm.checkUserPrefs(header))),
                              ]),
                            ]
                          : [
                              _c("span", { key: h.text }, [
                                _vm._v(_vm._s(header.text)),
                              ]),
                            ],
                      ]
                    },
                  }
                }),
              ],
              null,
              true
            ),
          }),
      _c(
        "v-navigation-drawer",
        {
          staticClass: "shade",
          attrs: { right: "", temporary: "", fixed: "", width: 360 },
          model: {
            value: _vm.esgDrawer,
            callback: function ($$v) {
              _vm.esgDrawer = $$v
            },
            expression: "esgDrawer",
          },
        },
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      [
                        _c(
                          "v-list",
                          [
                            _c("v-subheader", [
                              _vm._v("Select to display ESG Columns"),
                            ]),
                            _c(
                              "v-list-item-group",
                              {
                                attrs: {
                                  "active-class": "green--text",
                                  multiple: "",
                                },
                              },
                              [
                                _c(
                                  "draggable",
                                  _vm._b(
                                    {
                                      model: {
                                        value: _vm.headersDup,
                                        callback: function ($$v) {
                                          _vm.headersDup = $$v
                                        },
                                        expression: "headersDup",
                                      },
                                    },
                                    "draggable",
                                    _vm.dragOptions,
                                    false
                                  ),
                                  [
                                    _vm._l(
                                      _vm.headersDup,
                                      function (column, index) {
                                        return [
                                          column.text.length
                                            ? _c(
                                                "v-list-item",
                                                {
                                                  key: column.value,
                                                  attrs: {
                                                    disabled: !column.hideable,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.updateColumn(
                                                        column,
                                                        index
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-list-item-action",
                                                    { staticClass: "mr-5" },
                                                    [
                                                      !column.visible
                                                        ? _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                color: "grey",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " mdi-square-outline "
                                                              ),
                                                            ]
                                                          )
                                                        : _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "primary",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " mdi-checkbox-marked "
                                                              ),
                                                            ]
                                                          ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-list-item-content",
                                                    [
                                                      _c("v-list-item-title", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.checkUserPrefs(
                                                              column
                                                            )
                                                          )
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-list-item-action",
                                                    [
                                                      !column.hideable
                                                        ? _c("v-icon", [
                                                            _vm._v(
                                                              " mdi-lock "
                                                            ),
                                                          ])
                                                        : _c("v-icon", [
                                                            _vm._v(
                                                              " mdi-menu "
                                                            ),
                                                          ]),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("v-divider"),
                        _c(
                          "v-btn",
                          {
                            staticClass: "center mt-3",
                            attrs: { text: "", color: "primary" },
                            on: { click: _vm.resetESGDefaults },
                          },
                          [_vm._v("Reset Defaults")]
                        ),
                      ],
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }