<template>
  <v-dialog v-model="openDialog" persistent width="750">
    <loader v-if="loading" />
    <v-card>
      <v-container fluid>
        <v-form ref="form" v-model="valid" lazy-validation>
          <h3>New Trigger</h3>
          <v-text-field
            v-model="name"
            placeholder="Name"
            :rules="nameRules"
            required
            outlined
            style="margin-top: 15px; margin-bottom: -25px"
          >
            Name</v-text-field
          >
          <v-radio-group v-model="publicOrPrivate" row mandatory required>
            <v-radio label="Public" value="public"></v-radio>
            <v-radio label="Private" value="private"></v-radio>
          </v-radio-group>
          <v-textarea
            v-model="description"
            label="Description"
            outlined
          ></v-textarea>
          <v-row>
            <v-col class="pb-0" cols="12" xl="6" lg="6" md="6" sm="6"
              ><v-select
                :items="signalDBNames"
                label="Left Signal DB Name"
                outlined
                @change="filterSignals"
              ></v-select
            ></v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col class="pt-0" cols="12" xl="4" lg="4" md="4" sm="4"
              ><v-combobox
                v-model="leftSignal"
                :items="signals"
                item-text="signal"
                label="Left Signal"
                outlined
                required
                :rules="[(v) => !!v || 'Required!']"
                return-object
                @change="filterData"
              ></v-combobox
            ></v-col>
            <v-col class="pt-0" cols="12" xl="4" lg="4" md="4" sm="4"
              ><v-select
                v-model="operator"
                :items="operators"
                item-text="name"
                label="Operator"
                outlined
                required
                :rules="[(v) => !!v || 'Required!']"
                return-object
              ></v-select
            ></v-col>
            <v-col class="pt-0" cols="12" xl="4" lg="4" md="4" sm="4">
              <v-combobox
                v-if="rightFieldType === 'signal'"
                v-model="rightSignal"
                :items="signals"
                item-text="signal"
                label="Right Signal"
                outlined
                required
                return-object
                :rules="[(v) => !!v || 'Required!']"
              ></v-combobox>
              <v-combobox
                v-else-if="rightFieldType === 'enums'"
                v-model="rightEnums"
                :items="signalEnums"
                :item-text="(signal) => `${signal.value} - ${signal.key}`"
                label="Enums"
                outlined
                required
                return-object
                :rules="[(v) => !!v || 'Required!']"
              ></v-combobox>
              <v-text-field
                v-else-if="rightFieldType === 'value'"
                v-model="rightValue"
                placeholder="Value"
                outlined
                required
                :rules="[(v) => !!v || 'Required!']"
              ></v-text-field>
              <v-text-field
                v-else-if="rightFieldType === 'distance_m'"
                v-model="rightValue"
                placeholder="Distance(m)"
                outlined
                required
                :rules="[(v) => !!v || 'Required!']"
              ></v-text-field>
              <v-text-field
                v-else-if="rightFieldType === 'duration_ms'"
                v-model="rightValue"
                placeholder="Duration(ms)"
                outlined
                required
                :rules="[(v) => !!v || 'Required!']"
              ></v-text-field>
              <v-radio-group
                v-model="rightFieldType"
                style="margin-top: 0; padding-top: 0"
                row
                mandatory
                py="0"
                my="0"
              >
                <v-row class="pt-3">
                  <v-col class="pt-0" cols="6">
                    <v-radio
                      v-if="hasEnums"
                      label="Enums"
                      value="enums"
                      @click="resetField('enums')"
                    ></v-radio>
                    <v-radio
                      label="Signal"
                      value="signal"
                      @click="resetField('signal')"
                    ></v-radio>
                    <v-radio
                      label="Value"
                      value="value"
                      @click="resetField('value')"
                    ></v-radio>
                  </v-col>
                  <v-col class="pt-0" cols="6">
                    <v-radio
                      label="Distance"
                      value="distance_m"
                      @click="resetField('distance_m')"
                    ></v-radio>
                    <v-radio
                      label="Duration"
                      value="duration_ms"
                      @click="resetField('duration_ms')"
                    ></v-radio>
                  </v-col> </v-row></v-radio-group
            ></v-col>
          </v-row>
          <div style="display: flex; justify-content: right; margin-top: 20px">
            <v-btn class="mr-2" @click="openDialog = false">Cancel</v-btn
            ><v-btn color="primary" :disabled="!valid" @click="submitTrigger()"
              >Save</v-btn
            >
          </div>
        </v-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import loader from '@/utilities/loader';
import { addTrigger, getTriggerOperators, getTriggerTypes } from '@/api/study';
import { getSignals, getSignalEnums } from '@/api/dataDictionary';
import { mapActions } from 'vuex';

export default {
  name: 'AddTrigger',
  components: { loader },
  data() {
    return {
      loading: false,
      openDialog: false,
      publicOrPrivate: null,
      valid: false,
      name: null,
      nameRules: [(v) => !!v || 'Name is required'],
      description: null,
      leftSignal: null,
      operator: null,
      rightFieldType: null,
      rightSignal: null,
      rightValue: null,
      rightEnums: null,
      signals: [],
      signalsDup: [],
      operators: [],
      triggerOperators: [],
      triggerTypes: [],
      selectedTriggerType: null,
      signalEnums: [],
      signalDBNames: [],
      hasEnums: false,
    };
  },
  watch: {
    // Watches for the change in openDialog variable to emit the false variable back to parent to reset
    openDialog: function (newValue) {
      if (!newValue) {
        this.openDialog = false;
        this.$emit('closed', false);
      }
    },
  },
  async mounted() {
    this.openDialog = true;
    try {
      await this.getFormData();
    } catch (e) {
      console.log(e);
    }
  },
  methods: {
    ...mapActions({
      updateSnack: 'updateSnack',
    }),
    async getFormData() {
      await Promise.allSettled([
        getSignals(),
        getTriggerOperators(),
        getTriggerTypes(),
      ]).then((results) => {
        results[0].status === 'fulfilled'
          ? (this.signals = results[0].value.data)
          : console.log(results[0].reason);
        results[1].status === 'fulfilled'
          ? (this.triggerOperators = results[1].value.data)
          : console.log(results[1].reason);
        results[2].status === 'fulfilled'
          ? (this.triggerTypes = results[2].value.data)
          : console.log(results[2].reason);
      });
      this.signalsDup = [...this.signals];
      this.signalDBNames = [
        ...new Set(this.signals.map((e) => e.adx_db_table)),
      ].filter((t) => t !== null);
    },
    async filterData(signal) {
      this.operator = null;
      this.hasEnums = false;
      if (!signal) return;
      if (signal.signal_type === 'Bitfield' || signal.signal_type === 'State') {
        this.hasEnums = true;
        this.rightFieldType = signal.signal_type;
        try {
          const { data } = await getSignalEnums(signal.id);
          this.signalEnums = data;
        } catch (e) {
          console.log('Could not retrieve signal enums!');
        }
      }
      this.operators = this.triggerOperators.filter(
        (t) => t.signal_type_id === signal.signal_type_id
      );
    },
    filterSignals(type) {
      this.signals = [...this.signalsDup];
      this.signals = this.signals.filter((s) => s.adx_db_table === type);
    },
    async submitTrigger() {
      // Checks validation
      if (!this.$refs.form.validate()) {
        return;
      }
      if (this.rightEnums) this.rightValue = this.rightEnums.value;
      const fields = {
        name: this.name,
        description: this.description,
        triggerType: this.selectedTriggerType
          ? parseInt(this.selectedTriggerType)
          : 1,
        leftSignalId: this.leftSignal.id,
        operator: this.operator.id,
        rightSignalId: this.rightSignal ? this.rightSignal.id : null,
        rightValue: this.rightValue,
        private: this.publicOrPrivate === 'private' ? true : false,
      };
      try {
        await addTrigger(fields);
        this.$emit('refresh');
        this.updateSnack({
          type: 'success',
          message: 'Successfully added trigger!',
        });
      } catch (e) {
        this.updateSnack({
          type: 'error',
          message: 'error adding trigger!',
        });
      }
    },
    resetField(name) {
      switch (name) {
        case 'enums':
          this.rightSignal = null;
          this.rightValue = null;
          break;
        case 'value':
        case 'distance_m':
        case 'duration_ms':
          this.rightSignal = null;
          this.rightEnums = null;
          break;
        case 'signal':
          this.rightValue = null;
          this.rightEnums = null;
          break;
      }
      this.selectedTriggerType = this.triggerTypes
        .filter((t) => t.name === name)
        .map((t) => t.id)
        .join('');
    },
  },
};
</script>

<style scoped>
.dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  height: auto;
  width: auto;
  z-index: 1;
  transform: translate(-50%, -50%);
}
</style>
