var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [_c("v-spacer", { staticClass: "hidden-sm-and-down" })],
            1
          ),
          _c("v-col", [
            _c("h1", { staticClass: "centerText" }, [
              _vm._v("Customer Trucks"),
            ]),
          ]),
          _c(
            "v-col",
            [_c("v-spacer", { staticClass: "hidden-sm-and-down" })],
            1
          ),
        ],
        1
      ),
      _c("br"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }