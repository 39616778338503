var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.data.length === 0
      ? _c("div", { staticStyle: { "text-align": "center" } }, [
          _vm._v(" No Data for " + _vm._s(_vm.title) + " "),
        ])
      : _c("div", {
          staticClass: "absTime",
          attrs: { id: `absoluteTime${_vm.id}` },
        }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }