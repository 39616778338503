var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-snackbar",
    {
      attrs: { top: "", color: _vm.snack.type, timeout: -1 },
      scopedSlots: _vm._u([
        {
          key: "action",
          fn: function ({ attrs }) {
            return [
              _c(
                "v-btn",
                _vm._b(
                  { attrs: { text: "" }, on: { click: _vm.closeSnack } },
                  "v-btn",
                  attrs,
                  false
                ),
                [_vm._v(" Close ")]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.snack.model,
        callback: function ($$v) {
          _vm.$set(_vm.snack, "model", $$v)
        },
        expression: "snack.model",
      },
    },
    [
      _c("v-icon", { staticClass: "snackIcon" }, [
        _vm._v(_vm._s(_vm.feedbackIcon)),
      ]),
      _vm._v(" " + _vm._s(_vm.snack.message) + " "),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }