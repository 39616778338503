<template>
  <v-list>
    <v-list-group
      v-for="item in categoryTestItems"
      :key="item.name"
      v-model="item.active"
      no-action
    >
      <template v-slot:activator>
        <v-list-item-content>
          <v-list-item-title style="font-weight: bold; font-size: 1.2em">{{
            item.name
          }}</v-list-item-title>
        </v-list-item-content>
      </template>

      <v-list-item>
        <v-list-item-content>
          <v-data-table
            :key="randomKey"
            :headers="testHeaders"
            :items="item.tests"
            class="elevation-1 secondary locked-table-style row-height-50"
            :search="searchText"
            item-key="id"
            @click:row="loadTestResults"
          ></v-data-table>
        </v-list-item-content>
      </v-list-item>
    </v-list-group>
  </v-list>
</template>

<script>
export default {
  name: 'CategoryTable',
  props: {
    // eslint-disable-next-line
    categoryTestItems: Array,
    // eslint-disable-next-line
    loadTestResults: Function,
    // eslint-disable-next-line
    randomKey: Number,
  },
  data() {
    return {
      name: '',
      searchText: '',
      testHeaders: [
        {
          text: 'Test ID',
          align: 'center',
          sortable: true,
          value: 'id',
        },
        {
          text: 'Name',
          align: 'center',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Status',
          align: 'center',
          sortable: true,
          value: 'status',
        },
        {
          text: 'Tester',
          align: 'center',
          sortable: true,
          value: 'recent_tester',
        },
        {
          text: 'Activity Date',
          align: 'center',
          sortable: true,
          value: 'activityDate',
        },
      ],
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped></style>
