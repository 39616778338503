<template>
  <v-card>
    <v-card-title>{{ headerName }}</v-card-title>
    <v-card-text></v-card-text>
    <v-divider></v-divider>

    <v-container>
      <v-row class="mr-3">
        <v-col>
          <h3>Test Description</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="3" class="formLabel">Pre Conditions: </v-col>
        <v-col sm="9">
          <v-textarea
            v-model="preConditions"
            outlined
            name="input-7-4"
            value=""
            disabled
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="3" class="formLabel">Procedure: </v-col>
        <v-col sm="9">
          <v-textarea
            v-model="testProcedure"
            outlined
            name="input-7-4"
            value=""
            disabled
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="3" class="formLabel">Expected Results: </v-col>
        <v-col sm="9">
          <v-textarea
            v-model="expResults"
            outlined
            name="input-7-4"
            value=""
            disabled
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row class="mr-3">
        <v-col>
          <h3>Previous Results</h3>
        </v-col>
      </v-row>
      <v-row class="mr-3">
        <v-col sm="12">
          <v-data-table
            :key="dataKey"
            :headers="previousHeaders"
            :items="previousTests"
            class="elevation-1 secondary locked-table-style row-height-50"
            item-key="id"
            @click:row="loadResult"
          >
          </v-data-table>
        </v-col>
      </v-row>
      <v-row class="mr-3">
        <v-col>
          <h3>Current Test Results</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="3" class="formLabel">Start Date: </v-col>
        <v-col sm="9">
          <v-text-field
            v-model="startDate"
            v-mask="'##/##/####'"
            :rules="startDateRules"
            label="Start Date (MM/DD/YYYY)"
            class="formInput"
            type="text"
            outlined
            append-icon="mdi-calendar"
            required
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="3" class="formLabel">Status: </v-col>
        <v-col sm="9">
          <v-select
            v-model="selectedStatus"
            :items="statusItems"
            class="formInput"
            outlined
            label="Choose Test Status"
            required
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="3" class="formLabel">Additional Info: </v-col>
        <v-col sm="9">
          <v-textarea v-model="additional_info" outlined value=""></v-textarea>
        </v-col>
      </v-row>
    </v-container>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="red" text @click="cancel"> Close </v-btn>
      <v-btn :color="btnSaveColor" @click="saveTestResults">{{
        btnSaveText
      }}</v-btn>
      <v-btn color="blue" @click="clearForm">Reset</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';
import _ from 'lodash';
import { currentDateInput } from '@/utilities/dateFunctions';
import {
  addTestResults,
  getStatus,
  getTestResults,
  updateTestResults,
} from '@/api/manufacturing';
export default {
  name: 'TestDetails',
  props: {
    planId: { type: Number, default: null, required: true },
    testData: { type: Object, default: null, required: true },
    testResultsData: { type: Array, default: null, required: true },
    reloadSteps: { type: Function, default: null, required: true },
    toggle: { type: Function, default: null, required: true },
  },
  data() {
    return {
      addEditTitleText: 'Add Test Details',
      additional_info: '',
      assignedStep: '',
      btnSaveColor: 'primary',
      btnSaveText: 'Add Test Results',
      closedDate: null,
      currStatusID: 0,
      dataKey: 3,
      descHeaders: [
        {
          text: 'Pre-Conditions',
          align: 'center',
          sortable: true,
          value: 'pre_conditions',
        },
        {
          text: 'Procedure',
          align: 'center',
          sortable: true,
          value: 'test_procedure',
        },
        {
          text: 'Expected Results',
          align: 'center',
          sortable: true,
          value: 'exp_results',
        },
      ],
      drawer: false,
      expResults: null,
      headerName: '',
      preConditions: null,
      previousHeaders: [
        {
          text: 'ID',
          align: 'center',
          sortable: true,
          value: 'id',
        },
        {
          text: 'Plan ID',
          align: 'center',
          sortable: true,
          value: 'plan_id',
        },
        {
          text: 'Status',
          align: 'center',
          sortable: true,
          value: 'item_status',
        },
        {
          text: 'Start Date',
          align: 'center',
          sortable: true,
          value: 'start_date_read',
        },
        {
          text: 'Last Activity',
          align: 'center',
          sortable: true,
          value: 'last_activity',
        },
        {
          text: 'Additional Info',
          align: 'center',
          sortable: true,
          value: 'additional_info',
        },
      ],
      previousTests: [],
      resultsID: 1,
      selectedResults: '',
      selectedStatus: '',
      selectedResultID: null,
      startDate: '',
      startDateRules: [
        (v) => !!v || 'Date is required',
        (v) =>
          (v && v.length === 10) || 'Delivery date must be mm/dd/yyyy (10)',
      ],
      statusItems: [],
      statusList: [],
      testID: null,
      testProcedure: '',
      testerName: '',
      updateDetails: false,
      updateResult: false,
    };
  },
  watch: {
    startDate(val) {
      this.startDate = currentDateInput(val);
    },
    testResultsData() {
      this.populateFields();
    },
    updateResult(val) {
      this.btnSaveText = val ? 'Update Test Result' : 'Add Test Result';
      this.btnSaveColor = val ? 'orange' : 'primary';
    },
  },
  async mounted() {
    this.dataKey = Math.floor(Math.random() * (90000 - 1) + 1);
    await this.getStatusItems();
    await this.populateFields();
    this.$forceUpdate();
  },
  methods: {
    ...mapActions({
      updateSnack: 'updateSnack',
    }),
    cancel() {
      this.resetFields();
      this.previousTests = [];
      this.updateResult = false;
      this.reloadSteps();
      this.toggle();
    },
    clearForm() {
      this.resetFields();
      this.updateResult = false;
    },
    convertEpochDateTime: function (unix_time) {
      const new_time = unix_time * 1000;
      let options = { year: 'numeric', month: '2-digit', day: '2-digit' };
      return new Date(new_time).toLocaleDateString('en-US', options);
    },
    convertEpochTime: function (setDate) {
      const newTime = new Date(setDate).getTime() / 1000;
      return _.round(newTime, 0);
    },
    findStatusID: function () {
      const vm = this;
      let tmpItem = _.find(vm.statusList, function (o) {
        return o.status_name === vm.selectedStatus;
      });
      return tmpItem ? tmpItem.id : 0;
    },
    findStatusName: function (status_id) {
      const vm = this;
      let tmpItem = _.find(vm.statusList, function (o) {
        return o.id === status_id;
      });
      return tmpItem ? tmpItem.status_name : '';
    },
    getStatusItems: async function () {
      const vm = this;
      const res = await getStatus();
      if (res && res?.data) {
        vm.statusList = res.data;
        _.forEach(res.data, function (o) {
          vm.statusItems.push(o.status_name);
        });
      }
    },
    loadResult: async function (rowData) {
      this.resetFields();
      this.updateResult = true;
      this.selectedStatus = this.findStatusName(rowData.status_id);
      this.additional_info = rowData.additional_info;
      this.startDate = rowData.start_date_read;
      this.selectedResultID = rowData.id;
    },
    async populateFields() {
      if (this.testResultsData.length > 0) {
        this.resultsID = this.testResultsData[0].id;
        this.additional_info = this.testResultsData[0].additional_info;
      }
      this.testID = this.testData.id;
      this.preConditions = this.testData.pre_conditions;
      this.testProcedure = this.testData.test_procedure;
      this.expResults = this.testData.exp_results;
      this.headerName = this.testData.name;
      // get table data
      this.previousTests = [];
      const res = await getTestResults(this.planId);
      if (res && res?.data) {
        for (const o of res.data) {
          if (this.testID === o.test_id) {
            o.item_status = this.findStatusName(o.status_id);
            o.start_date_read = this.convertEpochDateTime(o.start_date);
            o.last_activity = this.convertEpochDateTime(o.activity_date);
            this.previousTests.push(o);
          }
        }
        this.dataKey = Math.floor(Math.random() * (90000 - 1) + 1);
      }
    },
    resetFields() {
      this.additional_info = '';
      this.selectedStatus = '';
      this.startDate = '';
    },
    async saveTestResults() {
      const currentDateTime = Math.floor(new Date().getTime() / 1000);
      const testResults = {
        plan_id: this.planId,
        test_id: this.testID,
        status_id: this.findStatusID(),
        additional_info: this.additional_info,
        activity_date: currentDateTime,
      };
      testResults.closed_date =
        testResults.status_id > 1 ? currentDateTime : null;
      if (this.updateResult) {
        try {
          const res = await updateTestResults(
            testResults,
            this.selectedResultID
          );
          if (res.status === 204) {
            await this.updateSnack({
              type: 'success',
              message: 'Test Result Item Updated.',
            });
          } else {
            await this.updateSnack({
              type: 'warning',
              message: 'Status code returned - ' + res.status,
            });
          }
        } catch (e) {
          await this.updateSnack({
            type: 'error',
            message: 'Error on updating result entry ==> ' + e,
          });
        }
      } else {
        testResults.start_date = this.convertEpochTime(this.startDate);
        try {
          const res = await addTestResults(testResults);
          if (res.status === 201) {
            await this.updateSnack({
              type: 'success',
              message: 'Test Result Item Added.',
            });
          } else {
            await this.updateSnack({
              type: 'warning',
              message: 'Status code returned - ' + res.status,
            });
          }
        } catch (e) {
          await this.updateSnack({
            type: 'error',
            message: 'Error on adding test results to DB ==> ' + e,
          });
        }
      }
      this.cancel();
    },
  },
};
</script>

<style scoped></style>
