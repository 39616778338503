import axios from 'axios';

const BASE_URL = process.env.VUE_APP_API_PATH_BASE;

async function postGeozone(payload) {
  return await axios.post(BASE_URL + 'geozones/', payload);
}

async function getGeozones() {
  return await axios.get(BASE_URL + `geozones/`);
}

async function updateGeozone(id, payload) {
  return await axios.put(BASE_URL + `geozones/${id}`, payload);
}

async function deleteGeozone(id, ss_id) {
  return await axios.delete(BASE_URL + `geozones/${id}/${ss_id}`);
}

export { postGeozone, getGeozones, updateGeozone, deleteGeozone };
