var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-card",
        {
          staticStyle: { "background-color": "#272727" },
          attrs: { elevation: "0" },
        },
        [
          _c("v-card-title", { staticClass: "text-h5" }, [_vm._v("Fleet")]),
          _vm.loading
            ? _c("v-skeleton-loader", {
                staticStyle: { padding: "17px" },
                attrs: { type: "article, article, article, article, article " },
              })
            : _c("div", { staticClass: "fleetSettingContent" }, [
                _c("div", { staticClass: "setting" }, [
                  _c("div", { staticClass: "settingText" }, [
                    _c("div", { staticClass: "settingTitle" }, [
                      _vm._v("Natural Gas Fuel Cost"),
                    ]),
                    _c("div", { staticClass: "settingDescription" }, [
                      _vm._v(
                        " Denote the average price you pay for Natural Gas. This is used to calculate fuel savings. "
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "fieldContainer" },
                    [
                      _c("v-text-field", {
                        staticClass: "tfield",
                        attrs: {
                          disabled: _vm.readOnly,
                          outlined: "",
                          label: "Price ($)",
                        },
                        model: {
                          value: _vm.ng_price,
                          callback: function ($$v) {
                            _vm.ng_price = $$v
                          },
                          expression: "ng_price",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  { staticStyle: { margin: "17px" } },
                  [
                    _c(
                      "v-expansion-panels",
                      {
                        attrs: { flat: "" },
                        model: {
                          value: _vm.panels,
                          callback: function ($$v) {
                            _vm.panels = $$v
                          },
                          expression: "panels",
                        },
                      },
                      [
                        _c(
                          "v-expansion-panel",
                          { staticStyle: { "background-color": "#1a1a1a" } },
                          [
                            _c(
                              "v-expansion-panel-header",
                              {
                                staticClass: "settingTitle",
                                staticStyle: { "margin-left": "-8px" },
                              },
                              [_vm._v("Natural Gas Energy Conversion Factor")]
                            ),
                            _c(
                              "v-expansion-panel-content",
                              {
                                staticClass: "settingDescription",
                                staticStyle: { "margin-left": "-8px" },
                              },
                              [
                                _vm._v(
                                  " Select whether your Natural Gas fuel cost is based on a DGE or GGE conversion. "
                                ),
                                _c(
                                  "v-radio-group",
                                  {
                                    attrs: { disabled: _vm.readOnly },
                                    model: {
                                      value: _vm.esg_ge,
                                      callback: function ($$v) {
                                        _vm.esg_ge = $$v
                                      },
                                      expression: "esg_ge",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "radioLine" },
                                      [
                                        _c("v-radio", {
                                          key: "0",
                                          attrs: { value: "dge" },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "label",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "settingDescription",
                                                    },
                                                    [
                                                      _c("strong", [
                                                        _vm._v("$ / DGE"),
                                                      ]),
                                                      _c("br"),
                                                      _vm._v(
                                                        " Diesel Gallon Equivalent "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "radioLine" },
                                      [
                                        _c("v-radio", {
                                          key: "1",
                                          attrs: { value: "gge" },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "label",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "settingDescription",
                                                    },
                                                    [
                                                      _c("strong", [
                                                        _vm._v("$ / GGE"),
                                                      ]),
                                                      _c("br"),
                                                      _vm._v(
                                                        " Gasoline Gallon Equivalent "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "setting" }, [
                  _c("div", { staticClass: "settingText" }, [
                    _c("div", { staticClass: "settingTitle" }, [
                      _vm._v("Comparison Fuel Used"),
                    ]),
                    _c("div", { staticClass: "settingDescription" }, [
                      _vm._v(
                        " Select the fuel you currently use from the drop down. This will be used as a comparison in emissions calculations. "
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "fieldContainer" },
                    [
                      _c("v-select", {
                        staticClass: "tfield",
                        attrs: {
                          disabled: _vm.readOnly,
                          items: _vm.fuelItems,
                          outlined: "",
                          label: "Fuel Used",
                        },
                        model: {
                          value: _vm.esg_fuel_used,
                          callback: function ($$v) {
                            _vm.esg_fuel_used = $$v
                          },
                          expression: "esg_fuel_used",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "setting" }, [
                  _c("div", { staticClass: "settingText" }, [
                    _c("div", { staticClass: "settingTitle" }, [
                      _vm._v("Comparison Fuel Cost ($/gal)"),
                    ]),
                    _c("div", { staticClass: "settingDescription" }, [
                      _vm._v(
                        " Enter the average price per gallon for the comparison fuel selected above. This will be used in fuel savings calculations. "
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "fieldContainer" },
                    [
                      _c("v-text-field", {
                        staticClass: "tfield",
                        attrs: {
                          disabled: _vm.readOnly,
                          outlined: "",
                          label: "Price ($)",
                        },
                        model: {
                          value: _vm.esg_fuel_cost,
                          callback: function ($$v) {
                            _vm.esg_fuel_cost = $$v
                          },
                          expression: "esg_fuel_cost",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "setting" }, [
                  _c("div", { staticClass: "settingText" }, [
                    _c("div", { staticClass: "settingTitle" }, [
                      _vm._v("Comparison Fuel MPG"),
                    ]),
                    _c("div", { staticClass: "settingDescription" }, [
                      _vm._v(
                        " Enter the average mpg for the comparison fuel selected above. This will be used in fuel savings calculations. "
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "fieldContainer" },
                    [
                      _c("v-text-field", {
                        staticClass: "tfield",
                        attrs: {
                          disabled: _vm.readOnly,
                          outlined: "",
                          label: "MPG",
                        },
                        model: {
                          value: _vm.esg_fuel_mpg,
                          callback: function ($$v) {
                            _vm.esg_fuel_mpg = $$v
                          },
                          expression: "esg_fuel_mpg",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  { staticStyle: { margin: "17px" } },
                  [
                    _c(
                      "v-expansion-panels",
                      {
                        attrs: { flat: "" },
                        model: {
                          value: _vm.panels2,
                          callback: function ($$v) {
                            _vm.panels2 = $$v
                          },
                          expression: "panels2",
                        },
                      },
                      [
                        _c(
                          "v-expansion-panel",
                          { staticStyle: { "background-color": "#1a1a1a" } },
                          [
                            _c(
                              "v-expansion-panel-header",
                              {
                                staticClass: "settingTitle",
                                staticStyle: { "margin-left": "-8px" },
                              },
                              [_vm._v("Journey Definition")]
                            ),
                            _c(
                              "v-expansion-panel-content",
                              {
                                staticClass: "settingDescription",
                                staticStyle: { "margin-left": "-8px" },
                              },
                              [
                                _vm._v(
                                  " Select the definition of a journey that most aligns to your business model. If you change the selection here, the information presented in the Performance screen will update to align. "
                                ),
                                !_vm.hasGeozones
                                  ? _c(
                                      "v-banner",
                                      { staticClass: "pl-0" },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: {
                                              slot: "icon",
                                              color: "#FB8C00",
                                              size: "30",
                                            },
                                            slot: "icon",
                                          },
                                          [_vm._v(" mdi-help-circle-outline ")]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "journeySwitchText" },
                                          [
                                            _vm._v(
                                              "Switching journey definitions not available"
                                            ),
                                          ]
                                        ),
                                        _c("br"),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "learnMoreText",
                                            on: {
                                              click: function ($event) {
                                                _vm.journeySwitching = true
                                              },
                                            },
                                          },
                                          [_vm._v("Learn More")]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "v-radio-group",
                                  {
                                    attrs: { disabled: _vm.readOnly },
                                    model: {
                                      value: _vm.journey_setting,
                                      callback: function ($$v) {
                                        _vm.journey_setting = $$v
                                      },
                                      expression: "journey_setting",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "radioLine" },
                                      [
                                        _c("v-radio", {
                                          key: "0",
                                          attrs: { value: "automated" },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "label",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "settingDescription",
                                                    },
                                                    [
                                                      _c("strong", [
                                                        _vm._v("Automated"),
                                                      ]),
                                                      _c("br"),
                                                      _vm._v(
                                                        " An automated journey ends when the ERX truck is offline for more than 10 hours. This setting is most appropriate for long-haul journeys that do not have a consistently defined route. "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "radioLine" },
                                      [
                                        _c("v-radio", {
                                          key: "1",
                                          class: [
                                            !_vm.hasGeozones ? "disabled" : "",
                                          ],
                                          attrs: {
                                            value: "destination",
                                            disabled: !_vm.hasGeozones,
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "label",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "settingDescription",
                                                    },
                                                    [
                                                      _c("strong", [
                                                        _vm._v("Destination"),
                                                      ]),
                                                      _c("br"),
                                                      _vm._v(
                                                        " A destination journey starts when the ERX truck leaves one geozone and ends when it enters another geozone. This setting is most appropriate for journeys that involve multiple pick-ups and drop-offs, such as deliveries to different customers. "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ]),
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "actionBtns" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticStyle: {
                          "margin-right": "20px",
                          color: "#2b8f14",
                        },
                        attrs: { disabled: _vm.readOnly },
                        on: { click: _vm.syncSettings },
                      },
                      [_vm._v("Cancel")]
                    ),
                    _c(
                      "v-btn",
                      {
                        staticStyle: { color: "black" },
                        attrs: { color: "primary", disabled: _vm.readOnly },
                        on: { click: _vm.saveChanges },
                      },
                      [_vm._v("Save changes")]
                    ),
                  ],
                  1
                ),
                _c("div"),
              ]),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "400" },
          model: {
            value: _vm.journeySwitching,
            callback: function ($$v) {
              _vm.journeySwitching = $$v
            },
            expression: "journeySwitching",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    { staticClass: "pl-4", attrs: { align: "center" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "1" } },
                        [_c("v-icon", [_vm._v("mdi-help-circle-outline")])],
                        1
                      ),
                      _c("v-col", { attrs: { align: "left", cols: "10" } }, [
                        _vm._v("Journey Switching Unavailable"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "1" } },
                        [_c("v-spacer")],
                        1
                      ),
                      _c("v-col", [
                        _vm._v(
                          " We cannot switch to Destination journey because you do not have any geozones created. Geozones are required for this journey definition. "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "my-2" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "hidden-xs-and-down" },
                        [_c("v-spacer")],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "mr-2",
                          attrs: { align: "center", cols: "3" },
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticStyle: { color: "black" },
                              attrs: { color: "primary" },
                              on: {
                                click: function ($event) {
                                  _vm.journeySwitching = false
                                },
                              },
                            },
                            [_vm._v("Close")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }