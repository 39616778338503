<template>
  <div id="erxTable">
    <v-navigation-drawer
      v-model="drawer"
      right
      temporary
      fixed
      width="360"
      class="shade"
    >
      <v-row align="center" class="my-2 pl-5 pr-3">
        <v-col align="left" cols="9">Customize Columns:</v-col>
        <v-col align="right" cols="3">
          <v-btn icon @click="drawer = false">
            <v-icon light>mdi-close-circle </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-divider />
      <v-row>
        <v-col cols="12">
          <template class="mx-auto column-list">
            <v-list class="drawer-list">
              <v-subheader>Select to display Columns</v-subheader>
              <v-list-item-group v-model="labels" multiple>
                <draggable
                  v-model="healthLabelsDup"
                  v-bind="dragOptions"
                  @change="dragLabels"
                >
                  <template v-for="(column, index) in healthLabelsDup">
                    <v-list-item
                      v-if="column.text.length && column.text !== 'Actions'"
                      :key="column.value"
                      :disabled="!column.hideable"
                      @click="updateColumn(column, index)"
                    >
                      <v-list-item-action class="mr-5">
                        <v-icon v-if="!column.visible" color="grey">
                          mdi-square-outline
                        </v-icon>
                        <v-icon v-else color="primary">
                          mdi-checkbox-marked
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title class="light-text">{{
                          checkUserPrefs(column)
                        }}</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-icon v-if="!column.hideable" class="light-text">
                          mdi-lock
                        </v-icon>
                        <v-icon v-else class="light-text"> mdi-menu </v-icon>
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                </draggable>
              </v-list-item-group>
            </v-list>
            <v-divider />
            <v-row class="pt-6">
              <v-col cols="5" class="py-0 pr-0 pl-7">
                <v-spacer />
              </v-col>
              <v-col>
                <v-btn color="primary" outlined @click="resetColumns()"
                  >Reset Default</v-btn
                >
              </v-col>
            </v-row>
          </template>
        </v-col>
      </v-row>
    </v-navigation-drawer>
    <div class="d-flex justify-start align-content-center pb-3">
      <v-checkbox
        v-model="erxF"
        class="pr-2 mt-0"
        label="Founders"
        hide-details
        @change="healthTableCheckBoxChange"
      >
      </v-checkbox>
      <v-checkbox
        v-model="erxC"
        class="px-2 mt-0"
        label="Sample C"
        hide-details
        @change="healthTableCheckBoxChange"
      >
      </v-checkbox>
      <v-checkbox
        v-model="erxB"
        class="px-2 mt-0"
        label="Sample B"
        hide-details
        @change="healthTableCheckBoxChange"
      >
      </v-checkbox>
      <v-checkbox
        v-model="simulated"
        class="px-2 mt-0"
        label="Simulated"
        hide-details
        @change="healthTableCheckBoxChange"
      >
      </v-checkbox>
    </div>
    <v-container fluid>
      <v-row class="pa-0"
        ><v-col class="pa-0">{{ truckDataDup.length }} results</v-col
        ><v-col class="pa-0" align="right">
          <v-btn icon @click="drawer = !drawer"
            ><v-icon>mdi mdi-dots-vertical</v-icon></v-btn
          >
        </v-col></v-row
      >
      <v-row>
        <v-col class="pa-0">
          <!-- <context-menu id="context-menu" ref="ctxMenu">
            <v-list>
              <v-list-item
                v-for="(route, index) in actionItems"
                :key="index"
                :class="route.title"
                @click="setRoute(truckInfo, route.title)"
                >{{ route.title }}</v-list-item
              >
            </v-list>
          </context-menu> -->
          <v-skeleton-loader
            v-if="loading"
            type="table"
            elevation="2"
          ></v-skeleton-loader>
          <v-data-table
            v-else
            :headers="healthLabels"
            :items="truckDataDup"
            class="elevation-1 secondary locked-table-style"
            :search="searchText"
            item-key="id"
            :custom-sort="healthTableCustomSort"
            @click:row="setTruckDetails"
          >
            <template
              v-for="h in healthLabels"
              v-slot:[`header.${h.value}`]="{ header }"
            >
              <template v-if="header.hasOptions">
                <span :key="h.text">{{ checkUserPrefs(header) }}</span>
              </template>
              <template v-else
                ><span :key="h.text">{{ header.text }}</span></template
              >
            </template>
            <template v-slot:[`item.name`]="{ item }">
              <th class="body-5">{{ item.number }}</th>
              <div class="body-6">{{ item.company }}</div>
            </template>
            <template v-slot:[`item.alias`]="{ item }">
              <td class="text-xs-center narrow-col">
                {{ item.alias }}
              </td>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <td class="text-xs-center narrow-col">
                <button :class="statusColor(item.status)">
                  {{ item.status }}
                </button>
              </td>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-menu style="top: 312px; left: 300px" offset-y>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-dots-vertical</v-icon>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(route, index) in actionItems"
                    :key="index"
                    :class="route.title"
                    @click="setRoute(item, route.title)"
                    >{{ route.title }}</v-list-item
                  >
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { getPref, putPref } from '@/api/preferences';
import { mapGetters, mapMutations } from 'vuex';
import { compareDates } from '@/utilities/dateFunctions';
// import contextMenu from 'vue-context-menu';
import draggable from 'vuedraggable';

export default {
  name: 'ERXTable',
  components: { draggable },
  props: {
    truckData: { type: Array, required: true },
    loading: { type: Boolean, default: true, required: true },
    searchText: { type: String, default: null, required: false },
  },
  data() {
    return {
      erxF: true,
      erxC: false,
      erxB: false,
      simulated: false,
      truckDataDup: [],
      actionItems: [],
      drawer: false,
      healthLabels: [
        {
          text: 'Truck # / Customer',
          width: '220px',
          align: 'left',
          sortable: true,
          value: 'name',
          visible: false,
          hideable: false,
        },
        {
          text: 'Alias',
          width: '150px',
          align: 'left',
          sortable: true,
          value: 'alias',
          visible: false,
          hideable: false,
        },
        {
          text: 'Status',
          width: '100px',
          align: 'left',
          sortable: true,
          value: 'status',
          visible: true,
          hideable: true,
        },
        {
          text: 'Location',
          width: '200px',
          align: 'left',
          value: 'address',
          sortable: false,
          visible: true,
          hideable: true,
        },
        {
          text: 'Odometer km',
          altText: 'Odometer mi',
          hasOptions: true,
          type: 'regionPref',
          width: '130px',
          align: 'right',
          value: 'odometer',
          sortable: true,
          visible: true,
          hideable: true,
        },
        {
          text: 'Speed kph',
          altText: 'Speed mph',
          hasOptions: true,
          type: 'regionPref',
          width: '115px',
          align: 'right',
          value: 'current_speed',
          sortable: true,
          visible: true,
          hideable: true,
        },
        {
          text: 'Time Last File Recieved',
          width: '225px',
          align: 'left',
          value: 'timeUpdated',
          sortable: true,
          visible: true,
          hideable: true,
        },
        {
          text: 'Fuel Level %',
          width: '135px',
          align: 'right',
          value: 'fuel_lvl',
          sortable: true,
          visible: true,
          hideable: true,
        },
        {
          text: 'Ignition',
          width: '120px',
          align: 'right',
          value: 'ignition_state',
          sortable: true,
          visible: true,
          hideable: true,
        },
        {
          text: 'SOC %',
          width: '100px',
          align: 'right',
          value: 'SOC',
          sortable: true,
          visible: true,
          hideable: true,
        },
        {
          text: 'Weight kg',
          altText: 'Weight lb',
          hasOptions: true,
          type: 'regionPref',
          width: '110px',
          align: 'right',
          value: 'weight',
          sortable: true,
          visible: true,
          hideable: true,
        },
        {
          text: 'cActive',
          width: '100px',
          align: 'right',
          value: 'cruisectrlactive',
          sortable: true,
          visible: true,
          hideable: true,
        },
        {
          text: 'cSetSpeed kph',
          altText: 'cSetSpeed mph',
          hasOptions: true,
          type: 'regionPref',
          width: '140px',
          align: 'right',
          value: 'cruisectrlsetspeed',
          sortable: true,
          visible: true,
          hideable: true,
        },
        {
          text: 'Plugged-In',
          width: '115px',
          align: 'left',
          value: 'plugged_in',
          sortable: true,
          visible: true,
          hideable: true,
        },
        {
          text: 'RtrdLvl',
          width: '90px',
          align: 'right',
          value: 'retarder_level',
          sortable: true,
          visible: true,
          hideable: true,
        },
        {
          text: 'Fuel',
          width: '75px',
          align: 'right',
          value: 'fuel_type',
          sortable: true,
          visible: true,
          hideable: true,
        },
        {
          text: 'EV Range',
          width: '120px',
          align: 'right',
          value: 'ev_range',
          sortable: true,
          visible: true,
          hideable: true,
        },
        {
          text: 'Fuel Range',
          width: '120px',
          align: 'right',
          value: 'fuel_range',
          sortable: true,
          visible: true,
          hideable: true,
        },
        {
          text: 'Hotel Hours',
          width: '120px',
          align: 'right',
          value: 'hotel_hours',
          sortable: true,
          visible: true,
          hideable: true,
        },
        {
          text: 'Engine Hours',
          width: '125px',
          align: 'right',
          value: 'engtotalhoursofoperation',
          sortable: true,
          visible: true,
          hideable: true,
        },
        // {
        //   text: 'Primary Contact',
        //   width: '160px',
        //   align: 'left',
        //   value: 'primary_email',
        //   sortable: true,
        //   visible: true,
        //   hideable: true,
        // },
        // {
        //   text: 'Secondary Contact',
        //   width: '160px',
        //   align: 'left',
        //   value: 'secondary_email',
        //   sortable: true,
        //   visible: true,
        //   hideable: true,
        // },
        // {
        //   text: 'Intended Usage',
        //   width: '140px',
        //   align: 'left',
        //   value: 'intended_usage',
        //   sortable: true,
        //   visible: true,
        //   hideable: true,
        // },
        // {
        //   text: 'Last Note',
        //   width: '125px',
        //   align: 'left',
        //   value: 'note',
        //   sortable: true,
        //   visible: true,
        //   hideable: true,
        // },
        // {
        //   text: 'Last Usage Update',
        //   width: '225px',
        //   align: 'left',
        //   value: 'last_usage_update',
        //   sortable: true,
        //   visible: true,
        //   hideable: true,
        // },
        {
          text: 'Vin',
          width: '150px',
          align: 'left',
          sortable: true,
          value: 'vin',
          visible: true,
          hideable: true,
        },
        // {
        //   text: 'Model',
        //   width: '100px',
        //   align: 'left',
        //   value: 'truck_type',
        //   sortable: true,
        //   visible: true,
        //   hideable: true,
        // },
        // {
        //   text: 'In Service',
        //   width: '105px',
        //   align: 'left',
        //   sortable: true,
        //   value: 'in_service',
        //   visible: false,
        //   hideable: true,
        // },
        {
          text: 'Version',
          width: '70px',
          align: 'right',
          value: 'version',
          sortable: false,
          visible: true,
          hideable: true,
        },
        {
          text: 'HCU Version',
          width: '120px',
          align: 'right',
          value: 'hcu_version',
          sortable: false,
          visible: true,
          hideable: true,
        },
        {
          text: 'HDP Version',
          width: '150px',
          align: 'right',
          value: 'hdp_version',
          sortable: false,
          visible: true,
          hideable: true,
        },
        {
          text: 'Gateway Version',
          width: '150px',
          align: 'right',
          value: 'sgw_version',
          sortable: false,
          visible: true,
          hideable: true,
        },
        // {
        //   text: 'License Plate',
        //   width: '125px',
        //   align: 'left',
        //   value: 'license_plate',
        //   sortable: false,
        //   visible: true,
        //   hideable: true,
        // },
        {
          text: 'Registration Date',
          width: '225px',
          align: 'left',
          value: 'created_at',
          sortable: true,
          visible: true,
          hideable: true,
        },
        {
          text: 'Last Battery ReCal',
          width: '225px',
          align: 'left',
          value: 'mostRecentBatteryRecal',
          sortable: false,
          visible: false,
          hideable: true,
        },
        {
          text: 'Actions',
          width: '0.1%',
          value: 'actions',
          sortable: false,
          align: 'right',
          visible: false,
          hideable: false,
        },
      ],
      healthLabelsDup: [],
      labels: [],
      prefVisible: {
        name: true,
        alias: true,
        status: true,
        address: true,
        timeUpdated: true,
        current_speed: true,
        vin: true,
        created_at: true,
        odometer: true,
        fuel_lvl: true,
        ignition_state: true,
        SOC: true,
        weight: true,
        cruisectrlactive: true,
        cruisectrlsetspeed: true,
        plugged_in: true,
        retarder_level: true,
        actions: true,
      },
      prefDup: {},
      presetChecked: false,
      truckInfo: null,
    };
  },
  computed: {
    ...mapGetters({
      userRoles: 'getUserRoles',
      userPreferences: 'getUserPreferences',
    }),
    dragOptions() {
      return {
        animation: 100,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      };
    },
  },
  watch: {
    loading() {
      // On initial load, update table data set to selected checkbox
      this.healthTableCheckBoxChange();
    },
  },
  async mounted() {
    this.healthLabelsDup = [...this.healthLabels];
    if (!this.loading) this.healthTableCheckBoxChange();
    this.matchColumns();
    this.userRoles.map((role) => {
      if (role === 'route.health') {
        this.actionItems.unshift({ title: 'Truck Detail' });
        this.actionItems.unshift({ title: 'DTC Details' });
      } else if (role === 'route.trip-reports') {
        this.actionItems.unshift({ title: 'Trip Details' });
      } else if (role === 'route.map') {
        this.actionItems.unshift({ title: 'Map View' });
      } else if (role === 'route.configuration') {
        this.actionItems.unshift({ title: 'Truck Configuration' });
      }
    });
  },
  methods: {
    ...mapMutations(['updateHealthTruckType', 'assignHealthTruck']),
    checkTruckType(truck) {
      if (this.simulated && truck.is_sim) {
        return true;
      } else if (
        this.erxB &&
        truck.truck_type.includes('ERX-B') &&
        !truck.is_sim
      ) {
        return true;
      } else if (
        this.erxC &&
        truck.truck_type.includes('ERX-C') &&
        !truck.is_sim
      ) {
        return true;
      } else if (
        this.erxF &&
        truck.truck_type.includes('ERX-F') &&
        !truck.is_sim
      ) {
        return true;
      } else {
        return false;
      }
    },
    dragLabels: function () {
      this.healthLabels = this.healthLabelsDup;
      this.matchColumns();
    },
    matchColumns: async function () {
      try {
        let result = await getPref('health_erx');
        this.prefDup = { ...this.prefVisible };
        this.prefVisible = result.data?.preferences || this.prefVisible;
      } catch (error) {
        this.$emit(
          'emitAlert',
          'error',
          'There was an issue getting user preferences!'
        );
      }

      this.healthLabels = this.healthLabels.filter(
        (label) => label.value in this.prefVisible
      );

      this.healthLabelsDup.forEach((label) => {
        if (label.value in this.prefVisible) {
          label.visible = true;
        } else {
          label.visible = false;
        }
      });
    },
    async updateColumn(column, index) {
      if (column.value in this.prefVisible) {
        delete this.prefVisible[column.value];
      } else {
        this.prefVisible[column.value] = true;
      }
      this.healthLabelsDup[index].visible = !column.visible;
      try {
        await putPref('health_erx', this.prefVisible);

        this.updateHeaders(this.healthLabelsDup);
      } catch (error) {
        this.updateHeaders(this.healthLabelsDup);
      }
    },
    updateHeaders: function (columns) {
      const tempArr = [];
      columns.forEach((c) => {
        if (c.visible) {
          tempArr.push(c);
        }
      });
      // If the actions menu doesn't exist, pushes action menu at the end of the array
      if (!columns.find((c) => c.value === 'actions')) {
        tempArr.push({
          text: '',
          value: 'actions',
          sortable: false,
          align: 'right',
          visible: false,
          hideable: false,
        });
      }
      this.healthLabels = [...tempArr];
    },
    async resetColumns() {
      this.healthLabels = this.healthLabels.filter(
        (label) => label.value in this.prefDup
      );

      this.healthLabelsDup.forEach((label) => {
        if (label.value in this.prefDup) {
          label.visible = true;
        } else {
          label.visible = false;
        }
      });

      try {
        await putPref('health_erx', this.prefDup);
      } catch (e) {
        console.log('There was an error updating erx health columns');
      }
    },
    healthTableCheckBoxChange: function () {
      this.truckDataDup = [
        ...this.truckData.filter((truck) => {
          return this.checkTruckType(truck);
        }),
      ];
    },
    healthTableCustomSort: function (items, index, isDesc) {
      // Implementation sample https://codepen.io/mmia/pen/jOPyXad
      items.sort((a, b) => {
        // Sorts out the hyphen(Em dash) for empty row data
        if (b[index] === '—') {
          return -1;
        }
        if (a[index] === '—') {
          return 1;
        }
        if (!index.length) {
          if (a.status === b.status) {
            return a.name < b.name ? -1 : 1;
          } else {
            return a.status < b.status ? -1 : 1;
          }
        }
        if (index[0] === 'created_at') {
          if (!isDesc[0]) {
            return compareDates(a.created_at, b.created_at);
          } else {
            return compareDates(b.created_at, a.created_at);
          }
        } else if (index[0] === 'timeUpdated') {
          const name = index[0];
          if (!b[name].length) return -1;
          if (!a[name].length) return 1;
          const aTime = a[name].split('  ');
          const bTime = b[name].split('  ');
          const adays = parseInt(aTime[0].match(/\d/g).join(''));
          const bdays = parseInt(bTime[0].match(/\d/g).join(''));

          if (!isDesc[0]) {
            if ((adays !== 0 || bdays !== 0) && adays !== bdays) {
              return adays < bdays ? -1 : 1;
            }
            const ahours = parseInt(aTime[1].match(/\d/g).join(''));
            const bhours = parseInt(bTime[1].match(/\d/g).join(''));
            if (ahours !== 0 || (bhours !== 0 && ahours !== bhours)) {
              return ahours < bhours ? -1 : 1;
            }
            const amins = parseInt(aTime[2].match(/\d/g).join(''));
            const bmins = parseInt(bTime[2].match(/\d/g).join(''));

            return amins < bmins ? -1 : 1;
          } else {
            if ((adays !== 0 || bdays !== 0) && adays !== bdays) {
              return bdays < adays ? -1 : 1;
            }
            const ahours = parseInt(aTime[1].match(/\d/g).join(''));
            const bhours = parseInt(bTime[1].match(/\d/g).join(''));
            if ((ahours !== 0 || bhours !== 0) && ahours !== bhours) {
              return bhours < ahours ? -1 : 1;
            }
            const amins = parseInt(aTime[2].match(/\d/g).join(''));
            const bmins = parseInt(bTime[2].match(/\d/g).join(''));

            return bmins < amins ? -1 : 1;
          }
        } else if (
          index[0] === 'odometer' ||
          index[0] === 'weight' ||
          index[0] === 'fuel_level_1' ||
          index[0] === 'fuel_level_2' ||
          index[0] === 'cruisectrlsetspeed' ||
          index[0] === 'retarder_level'
        ) {
          if (!isDesc[0]) {
            return parseInt(a[index]) < parseInt(b[index]) ? -1 : 1;
          } else {
            return parseInt(b[index]) < parseInt(a[index]) ? -1 : 1;
          }
        } else if (index[0] === 'current_speed') {
          if (!isDesc[0]) {
            return a[index].toString() < b[index].toString() ? -1 : 1;
          } else {
            return b[index].toString() < a[index].toString() ? -1 : 1;
          }
        } else if (a[index] && b[index]) {
          if (!isDesc[0]) {
            return a[index].toString() < b[index].toString() ? -1 : 1;
          } else {
            return b[index].toString() < a[index].toString() ? -1 : 1;
          }
        }
      });
      return items;
    },
    setTruckDetails: async function (truck) {
      this.updateHealthTruckType('ERX');
      this.assignHealthTruck(truck);
      this.$router.push(`health/${truck.id}/details`);
    },
    setRoute: async function (truck, route) {
      if (route === 'DTC Details') {
        this.$router.push(`health/${truck.id}/dtc`);
      } else if (route === 'Truck Detail') {
        this.updateHealthTruckType('ERX');
        this.$router.push(`health/${truck.id}/details`);
      } else if (route === 'Map View') {
        this.$router.push(`health/${truck.id}/map`);
      } else if (route === 'Truck Configuration') {
        this.$router.push('configuration-management');
      } else if (route === 'Trip Details') {
        this.$router.push(`trips/${truck.id}`);
      }
    },
    // showMenu(truck) {
    //   this.truckInfo = truck;
    //   this.$refs.ctxMenu.open();
    // },
    statusColor: function (status) {
      let code = '';
      switch (status) {
        case 'Normal':
          code = 'color-green';
          break;
        case 'Warning':
          code = 'color-yellow';
          break;
        case 'FAULT':
        case 'TRUCK':
          code = 'color-red';
          break;
        default:
          code = 'color-green';
      }
      return code;
    },
    checkUserPrefs(h) {
      if (h.type === 'regionPref') {
        if (this.userPreferences.regionPref === 'metric') {
          return h.text;
        } else {
          return h.altText;
        }
      } else {
        return h.text;
      }
    },
  },
};
</script>

<style scoped>
.center {
  display: block;
  margin: auto;
}
</style>
