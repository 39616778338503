<template>
  <highcharts :options="getChartData()"></highcharts>
</template>

<script>
export default {
  name: 'PieChart',
  props: {
    name: {
      required: true,
      type: String,
      defualt: '',
    },
    data: {
      required: true,
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  methods: {
    getChartData() {
      return {
        chart: {
          type: 'pie',
          backgroundColor: '#1a1a1a',
        },
        title: {
          text: this.name,
          style: {
            color: '#FFFFFF',
            fontFamily: 'Rawline',
            fontWeight: 'bold',
          },
        },
        tooltip: {
          useHTML: true,
          formatter: function () {
            return this.key + 'DTCs - ' + this.point.y;
          },
        },
        plotOptions: {
          pie: {
            size: '75%',
            dataLabels: {
              enabled: false,
            },
            showInLegend: true,
            borderColor: null,
          },
        },
        series: [
          {
            name: 'Prosentase',
            colorByPoint: true,
            innerSize: '83%',
            data: this.data,
          },
        ],
        legend: {
          enabled: true,
          itemStyle: {
            color: '#FFFFFF',
            fontWeight: 'bold',
          },
        },
        credits: {
          enabled: false,
        },
      };
    },
  },
};
</script>
