var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-data-table", {
    staticClass: "dark-background",
    attrs: {
      "no-data-text": _vm.noDataMsg,
      headers: _vm.labels,
      "item-key": "truckNumber",
      items: _vm.trucks,
    },
    scopedSlots: _vm._u(
      [
        {
          key: `item.status`,
          fn: function ({ item }) {
            return [
              _c(
                "v-tooltip",
                {
                  attrs: { left: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on }) {
                          return [
                            _c(
                              "td",
                              { style: _vm.severityColor(item.faultSeverity) },
                              [
                                _c(
                                  "v-icon",
                                  _vm._g(
                                    {
                                      style: _vm.severityColor(
                                        item.faultSeverity
                                      ),
                                      attrs: { large: "" },
                                    },
                                    on
                                  ),
                                  [_vm._v(_vm._s(_vm.getIcon(item.status)))]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                },
                [_c("span", [_vm._v(_vm._s(item.status))])]
              ),
            ]
          },
        },
        {
          key: `item.closestServiceLocation`,
          fn: function ({ item }) {
            return [
              _c("td", [
                _c("p", { staticClass: "mb-0 mt-1" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        item.miles >= 0 ? item.closestServiceLocation : "—"
                      ) +
                      " "
                  ),
                ]),
                _c("p", { staticStyle: { color: "#888888", margin: "0" } }, [
                  _vm._v(
                    " " +
                      _vm._s(item.miles >= 0 ? item.miles + " miles" : "") +
                      " "
                  ),
                ]),
              ]),
            ]
          },
        },
        {
          key: `item.actions`,
          fn: function ({ item }) {
            return [
              _c(
                "td",
                {
                  staticStyle: { color: "#43b02a", cursor: "pointer" },
                  on: { click: () => _vm.viewTruck(item) },
                },
                [_vm._v(" VIEW ")]
              ),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }