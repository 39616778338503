<template>
  <v-container fluid class="ml-3">
    <template v-if="scatterView">
      <v-row>
        <v-col cols="3" class="ml-3 pb-0">
          <h1 style="font-weight: 400">Performance</h1>
        </v-col>
        <v-col align="left"
          ><LiveFleetViewBanner
            v-if="liveView"
            :fleet-info="selectedCompany"
            @closed="liveView = false" /></v-col
      ></v-row>
      <v-row>
        <v-col cols="12">
          <div class="perfBar">
            <v-row style="padding: 14px">
              <v-col class="perfCol">
                <div class="label">Total Fuel Economy</div>
                <div class="perfValue">
                  {{ mpge }} MPGe
                  <v-tooltip bottom max-width="222" color="#1a1a1a">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="infoBtn"
                        v-bind="attrs"
                        small
                        style="margin-top: -12px"
                        v-on="on"
                        >mdi-information</v-icon
                      >
                    </template>
                    <span>
                      Calculated using fuel burned and miles traveled. Updates
                      if date ranges below are changed.
                    </span>
                  </v-tooltip>
                </div>
              </v-col>
              <v-col class="perfCol">
                <div class="label">Fuel Savings</div>
                <div class="perfValue">
                  $ {{ fuelSavings }}
                  <v-tooltip bottom max-width="222" color="#1a1a1a">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="infoBtn"
                        v-bind="attrs"
                        small
                        style="margin-top: -12px"
                        v-on="on"
                        >mdi-information</v-icon
                      >
                    </template>
                    <span>
                      Calculated fuel savings for Natural Gas compared to user
                      selected comparison fuel based on fuel costs and diesel
                      mpg defined in the settings. Updates if date ranges below
                      are changed.
                    </span>
                  </v-tooltip>
                </div>
              </v-col>
              <v-col class="perfCol">
                <div class="label">CO<sub>2</sub> Reduction</div>
                <div class="perfValue">
                  {{ carbon !== null ? carbon : '—' }} lb

                  <v-tooltip bottom max-width="222" color="#1a1a1a">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="infoBtn"
                        v-bind="attrs"
                        small
                        style="margin-top: -12px"
                        v-on="on"
                        >mdi-information</v-icon
                      >
                    </template>
                    <span>
                      Calculated using the GHG Protocol and presenting the
                      carbon reduction compared to user selected comparison
                      fuel. Updates if date ranges below are changed.
                    </span>
                  </v-tooltip>
                </div>
              </v-col>
              <v-col class="perfCol">
                <div class="label">N<sub>2</sub>0 Reduction</div>
                <div class="perfValue">
                  {{ nox !== null ? nox : '—' }} lb

                  <v-tooltip bottom max-width="222" color="#1a1a1a">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="infoBtn"
                        v-bind="attrs"
                        small
                        style="margin-top: -12px"
                        v-on="on"
                        >mdi-information</v-icon
                      >
                    </template>
                    <span>
                      Calculated based on miles traveled and presenting the
                      N<sub>2</sub>0 reduction compared to user selected
                      comparison fuel. Updates if date ranges below are changed.
                    </span>
                  </v-tooltip>
                </div>
              </v-col>
              <v-col class="perfCol">
                <div class="label">Journeys Taken</div>
                <div class="perfValue">
                  {{ journeyCount != null ? journeyCount : '—' }}
                  <v-tooltip bottom max-width="222" color="#1a1a1a">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="infoBtn"
                        v-bind="attrs"
                        small
                        style="margin-top: -12px"
                        v-on="on"
                        >mdi-information</v-icon
                      >
                    </template>
                    <span
                      >The total number of completed journeys. Updates if date
                      ranges below are changed.</span
                    >
                  </v-tooltip>
                </div>
              </v-col>
              <v-col class="perfCol">
                <div class="label">Active Days</div>
                <div class="perfValue">
                  {{ activeDays != null ? activeDays : '—' }}
                  <v-tooltip bottom max-width="222" color="#1a1a1a">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="infoBtn"
                        v-bind="attrs"
                        small
                        style="margin-top: -12px"
                        v-on="on"
                        >mdi-information</v-icon
                      >
                    </template>
                    <span
                      >The total number of days any number of trucks are active
                      in your fleet. If 5 are active for 1 day, it is 5. If 5
                      are active for 5 days, it is 25. Updates if date ranges
                      below are changed.</span
                    >
                  </v-tooltip>
                </div>
              </v-col>
              <v-btn
                v-if="userCanUpdateFleetPreferences"
                icon
                tile
                large
                class="cog"
                style="display: block; margin: auto; background-color: #1a1a1a"
                @click="openFleetSettings"
                ><v-icon style="opacity: 0.6">mdi-cog</v-icon></v-btn
              >
            </v-row>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <scatter
            v-if="ready"
            :company="company"
            :set-journeys="setJourneys"
            :send-extremes="setExtremes"
            :set-trucks="setVisibleTrucks"
            @journeySelected="showJourney"
          />
          <v-skeleton-loader
            v-else
            type="table-thead"
            elevation="2"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <!-- Journey Details -->
      <v-row>
        <v-col class="mx-0">
          <JourneyDetails
            :journey-data="journeyData"
            @showScatter="showScatter"
          />
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import LiveFleetViewBanner from '@/utilities/LiveFleetViewBanner.vue';
import ScatterPlot from './ScatterPlot.vue';
import JourneyDetails from './JourneyDetails/JourneyDetails.vue';
import { getHealth, getSpnfmiStates } from '@/api/external/health';
import {
  getCompanyPreferences,
  getCompanyGeozones,
} from '@/api/external/company';
import { getJourney } from '@/api/external/journey';
import { permissions } from '@/api/permissions';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import {
  constants as c,
  getCO2Reduction,
  getN2OReduction,
} from '@/utilities/constants.js';
import dateFormat from 'dateformat';
export default {
  name: 'Performance',
  components: {
    scatter: ScatterPlot,
    JourneyDetails,
    LiveFleetViewBanner,
  },
  data() {
    return {
      company: null,
      trucks: [],
      preferences: false,
      scatterView: true,
      journeys: null,
      journeyCount: null,
      journeyData: [],
      fuelEcon: null,
      fuelUsedDisplay: null,
      savings: null,
      carbon: null,
      nox: null,
      activeDays: null,
      healthTab: 0,
      scatterExtremes: [0, Date.now()], // [min, max]
      trucksOnScatter: new Set(),
      esgPreferences: {
        esg_fuel_cost: null,
        esg_fuel_mpg: null,
        esg_fuel_used: '',
        esg_ge: 'dge',
      },
      totalFuelUsedKg: null,
      totalKmDriven: null,
      ready: false,
      liveView: false,
    };
  },
  computed: {
    ...mapGetters({
      userRoles: 'getUserRoles',
      spnfmiStates: 'getSpnfmiStates',
      companies: 'getCompanies',
      selectedCompany: 'getSelectedLiveFleet',
      companyPreferences: 'getCompanyPreferences',
    }),
    userCanUpdateFleetPreferences() {
      return (
        this.userRoles &&
        this.userRoles.includes(permissions.externalFleetConfig)
      );
    },
    mpge() {
      return this.fuelEcon != null && !isNaN(this.fuelEcon)
        ? this.fuelEcon
        : '—';
    },
    fuelSavings() {
      return this.savings === null || this.savings === 'NaN'
        ? '—'
        : this.savings;
    },
    cngCarbon() {
      if (!this.esgPreferences || this.esgPreferences?.esg_fuel_used === '') {
        return null;
      }
      return Math.round(c.KG_CO2_PER_DGE * c.LB_PER_KG * 100) / 100;
    },
    // constant is different for Diesel and Biodiesel B5
    dieselCarbon() {
      const vm = this;
      if (!vm.esgPreferences || vm.esgPreferences?.esg_fuel_used === '') {
        vm.fuelUsedDisplay = null;
        return null;
      }
      vm.fuelUsedDisplay = vm.esgPreferences?.esg_fuel_used;
      let KG_CO2_PER_DG = c.KG_CO2_PER_DG;
      if (vm.esgPreferences?.esg_fuel_used === 'Biodiesel B5')
        KG_CO2_PER_DG = c.KG_CO2_PER_DG_B5;
      return Math.round(KG_CO2_PER_DG * c.LB_PER_KG * 100) / 100;
    },
  },
  watch: {
    // recalculate when constants change
    esgPreferences() {
      this.calculateBar();
    },
    scatterExtremes() {
      this.aggregateBar();
    },
    trucksOnScatter() {
      this.aggregateBar();
    },
  },
  async mounted() {
    // Hyliion Users
    if (this.companies.length > 1 && this.companies.find((c) => c.id === 4)) {
      if (this.selectedCompany) {
        this.company = this.selectedCompany;
        // If user has Live Fleet View selected
        if (this.selectedCompany.id !== 4) this.liveView = true;
      } else {
        // Defaults to Hyliion Data
        this.company = this.companies.find((c) => c.id === 4);
      }
    } else {
      // External Users
      this.company = {
        id: this.companies[0].id,
        name: this.companies[0].name,
      };
    }
    if (!this.spnfmiStates.length) {
      const { data } = await getSpnfmiStates();
      await this.asyncUpdateSpnfmiStates(data);
    }
    await this.getData();
    await this.fetchPreferences();
    // TODO: Check to make sure all attrs. are correct for this component
    this.updateCurrentScreen({
      tab: 'Performance',
      mapShowing: false,
      healthTab: 'Faulted',
      scatterMetric: 'mpge',
      scatterRange: 'month',
      journeyDetails: false,
      truckDetails: false,
    });
    this.populateBar();
    this.ready = true;
  },
  methods: {
    ...mapActions(['asyncUpdateSpnfmiStates', 'updateSnack']),
    ...mapMutations([
      'updateCurrentScreen',
      'updateJourneyData',
      'assignTrucks',
      'assignCompanyPreferences',
      'assignGeozones',
    ]),
    async getData() {
      try {
        let trucks = [];
        trucks = await getHealth(this.company.id);
        if (trucks && trucks.length) {
          this.assignTrucks(trucks);
          this.trucks = trucks;
        }
      } catch {
        this.updateSnack({
          type: 'error',
          message: 'There was an issue loading data',
        });
      }
    },
    togglePreferences() {
      this.preferences = !this.preferences;
    },
    showScatter() {
      this.ready = false;
      this.scatterView = true;
      this.showPerformanceDetails = false;
      this.journeyData = [];
      this.updateCurrentScreen({ journeyDetails: false });
      this.ready = true; // Re-renders scatterplot
    },
    showJourney(value) {
      this.showPerformanceDetails = true;
      this.scatterView = false;
      this.journeyData = value;
      this.updateCurrentScreen({ journeyDetails: true });
    },
    async fetchPreferences() {
      const res = await getCompanyPreferences(this.company.id);
      this.assignCompanyPreferences(res.data[0]);
      this.esgPreferences = res.data[0];
      let journeyType = res.data[0]?.journey_setting;
      let geozones;
      if (journeyType && journeyType === 'destination') {
        // If journey type is a destination journey, retrieve geozones
        const { data } = await getCompanyGeozones(this.company.id, true);
        if (data.length) {
          geozones = data;
          this.assignGeozones(data); // Assigns Geozones to Vuex State
        }
      }
      // Journey API Call
      const now = Date.now();
      let checkGz = null;
      if (geozones && journeyType === 'destination') {
        checkGz = geozones.map((g) => g.id).join(',');
      }
      const { journeyInfos } = await getJourney(
        this.company.id,
        1672531200000,
        now,
        true,
        null, // Empty for truck_id
        checkGz
      ); // Start Date: 1/1/2023 12:00:00 AM UTC
      // Filters all trucks that are not currently assigned to the Company
      // or are simulated trucks
      this.journeys = journeyInfos.filter((j) => {
        let truckFound = this.trucks.find((t) => t.id === parseInt(j.truckId));
        if (truckFound) {
          return j;
        }
      });
      this.updateJourneyData(this.journeys); // Assign journeys to state
    },
    async populateBar() {
      this.aggregateBar();
    },
    // aggregate relevant values to get fuelUsed and kmDriven values for currently visible journeys
    aggregateBar() {
      let [totalFuelKg, totalKm] = [0, 0];
      let activeDays = new Set();
      const [min, max] = [this.scatterExtremes[0], this.scatterExtremes[1]];
      let journeyCount = 0;
      if (this.journeys.length) {
        this.journeys.forEach((j) => {
          const truck = j.truckId;
          j.journeys.forEach((jj) => {
            // Filters out any journey that is a pre journey(3) or an unfinished journey(4)
            if (jj.type === 3 || jj.type === 4) {
              return;
            }
            const stats = jj.statistics;
            // Only counts journeys within the min and max constraints of the scatterplot
            if (jj.start >= min && jj.start <= max) {
              journeyCount++;
              // Timezone Pref here
              let formatDay = dateFormat(jj.start, 'mm/dd/yyyy', true);

              let setItem = `${truck}_${formatDay}`;
              if (!activeDays.has(setItem)) activeDays.add(setItem);

              totalKm += stats.totalKilometers;
              if (stats.kmPerKg > 0) {
                totalFuelKg += stats.totalKilometers / stats.kmPerKg;
              }
            }
          });
        });
        this.journeyCount = journeyCount;
      }

      this.activeDays = activeDays.size;
      this.totalFuelUsedKg = totalFuelKg;
      this.totalKmDriven = totalKm;
      // recaculate since aggregations may have changed
      this.calculateBar();
    },
    startOfDay(date) {
      const startOfDay = new Date(date);
      startOfDay.setHours(0, 0, 0, 0); // Set to midnight
      return startOfDay.getTime(); // Return epoch milliseconds
    },
    endOfDay(date) {
      const startOfDay = new Date(date);
      startOfDay.setHours(23, 59, 59, 999); // Set to 1 ms before midnight
      return startOfDay.getTime(); // Return epoch milliseconds
    },
    setExtremes(min, max) {
      this.scatterExtremes = [min, max];
    },
    setVisibleTrucks(inputSet) {
      // Updates the performance bar based on trucks selected/deselected
      // Disabled until Beta 1.2 improvements
      this.trucksOnScatter = inputSet;
    },
    // https://hyliion.atlassian.net/wiki/spaces/HLS/pages/3309961230/Emissions+Calculations
    calculateBar() {
      // declare constants
      const dge = this.totalFuelUsedKg * c.DGE_PER_KG;
      const gge = dge * c.DGE_PER_GGE;
      let ge = dge; // default to dge
      if (this.esgPreferences?.esg_ge === 'gge') ge = gge;
      let totalMilesDriven = null;
      if (this.totalKmDriven)
        totalMilesDriven = this.totalKmDriven / c.KM_PER_MI;

      // CALCULATE total fuel economy
      if (this.totalKmDriven && ge) {
        this.fuelEcon = Math.round((totalMilesDriven / ge) * 10) / 10;
      } else if (this.totalKmDriven === 0 || ge === 0) {
        let temp = 0.0; // By default javascript sets 0.0 to 0
        this.fuelEcon = temp.toFixed(1);
      }
      // CALCULATE fuel savings
      let cngCost, currentGallons, currentCost;
      if (
        this.esgPreferences?.cng_price &&
        totalMilesDriven &&
        this.esgPreferences?.esg_fuel_mpg &&
        this.esgPreferences?.esg_fuel_cost
      ) {
        cngCost = ge * this.esgPreferences?.cng_price;
        currentGallons = totalMilesDriven / this.esgPreferences?.esg_fuel_mpg;
        currentCost = currentGallons * this.esgPreferences?.esg_fuel_cost;
        this.savings = Math.round(currentCost - cngCost).toLocaleString();
      }

      // todo: not in current scope, but above code could use some cleaning up
      const comparisonMPG = this.esgPreferences?.esg_fuel_mpg;
      const comparisonFuel = this.esgPreferences?.esg_fuel_used?.toLowerCase();
      // const currentFuel =  this.esgPreferences?.esg_fuel_used.toLowerCase() || 'cng' // add back once CNG/RNG selector is re-enabled
      const currentFuel = 'cng';

      // CALCULATE carbon reduction
      this.carbon = Math.round(
        getCO2Reduction(
          this.totalKmDriven,
          currentFuel,
          comparisonFuel,
          comparisonMPG,
          this.totalFuelUsedKg
        )
      ).toLocaleString();

      // CALCULATE NOx reduction
      this.nox = (
        Math.round(
          getN2OReduction(this.totalKmDriven, currentFuel, comparisonFuel) * 100
        ) / 100
      ).toFixed(2);
    },
    setJourneys(total) {
      this.journeyCount = total;
    },
    openFleetSettings() {
      this.$router.push('/customer/settings');
    },
  },
};
</script>

<style scoped>
.label {
  /* font-weight: bold; */
  /* letter-spacing: 0.8px; */
  color: var(--dark-main-color, #e0e0e0);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Rawline;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 120% */
  letter-spacing: 0.563px;
}
.perfValue {
  /* font-family: 'Rawline';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 48px;
  letter-spacing: 1px;
  color: #e0e0e0; */
  color: var(--dark-main-color, #e0e0e0);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Rawline;
  font-size: 27px;
  font-style: normal;
  font-weight: 800;
  line-height: 36px; /* 133.333% */
  letter-spacing: 0.75px;
}
.perfStatus {
  font-size: 13px;
  display: inline-block;
  cursor: default;
  text-decoration: none;
  color: #8ecf7f;
  border: 2px solid #8ecf7f;
  border-radius: 10px;
  padding: 2px 10px;
  margin: 4px 2px;
}
.infoBtn {
  padding-left: 4px;
  color: #979797;
  margin-top: -5px;
  cursor: pointer;
}
.cog {
  margin-right: 0.7rem;
  margin-left: 0.5rem;
  border-radius: 5px;
}
.perfInfoValue {
  display: flex;
  justify-content: space-between;
  max-width: 255px;
  color: #e0e0e0;
  opacity: 0.38;
}
.perfBar {
  background-color: #272727;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
}
.firstCol {
  margin-left: 20px;
}
.perfCol {
  padding: 0 !important;
  display: block;
  margin: auto;
}
@media only screen and (max-width: 766px) {
  .perfCol {
    margin-left: 20px;
    margin-right: 20px;
  }
}
@media only screen and (min-width: 767px) {
  .perfBar {
    padding: 20px;
  }
}
</style>
