<template>
  <div id="service" class="mx-3">
    <v-container fluid>
      <v-row class="pb-0">
        <v-col cols="12">
          <v-breadcrumbs
            style="padding: 5px 0 0 5px"
            :items="addCrumb"
            divider="/"
          ></v-breadcrumbs>
        </v-col>
        <v-col><v-spacer /></v-col>
      </v-row>
      <v-row class="mt-0 pb-1">
        <v-col>
          <h1 style="font-weight: 400">{{ truckData.number }}</h1>
        </v-col>
        <v-col align="right">
          <v-btn
            icon
            tile
            large
            style="
              margin-right: 0.7rem;
              margin-left: 0.5rem;
              background-color: #1a1a1a;
              border-radius: 5px;
            "
            @click="drawer = true"
            ><v-icon style="opacity: 0.6">mdi-cog</v-icon></v-btn
          >
        </v-col>
      </v-row>

      <v-row class="mt-0">
        <v-col cols="8" md="12" sm="12">
          <v-tabs v-model="tab" background-color="secondary">
            <v-tab v-for="item in tabItems" :key="item.id">
              {{ item.name }}
            </v-tab>
          </v-tabs>
        </v-col>
        <!-- <v-col
          v-if="tabItems[tab].id === -4"
          md="12"
          lg="3"
          align="right"
          style="margin-top: -10px"
        >
          <v-btn
            v-if="userCanRunEndOfLineTests"
            style="color: black"
            large
            color="primary"
            @click="testModal = true"
          >
            <v-icon style="opacity: 0.6">mdi-refresh</v-icon>Run Tests
          </v-btn>
        </v-col> -->
        <v-col
          v-if="
            tabItems[tab] === 'Operations' &&
            truckData &&
            truckData.truck_type?.includes('EX')
          "
          md="12"
          lg="3"
          align="right"
        >
          <v-select
            v-model="operationGraphsSelected"
            :items="viewableOperationGraphs"
            label="View Operations Charts"
            multiple
            chips
            height="68px"
            outlined
          >
            <template v-slot:prepend-item>
              <v-list-item ripple @mousedown.prevent @click="toggleSelectAll">
                <v-list-item-action>
                  <v-icon :color="allSelected ? 'primary' : ''">
                    {{ icon }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title> Select All </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
            </template>
            <template v-slot:selection="{ item }">
              <v-chip readonly @click="chipClicked(item)" @click.stop>{{
                item
              }}</v-chip>
            </template>
          </v-select>
        </v-col>
        <!-- Operations -->
        <v-col
          v-if="
            tabItems[tab].id === -1 &&
            truckData &&
            truckData.truck_type?.includes('ERX')
          "
          md="12"
          lg="3"
          align="right"
          style="margin-top: -10px"
        >
          <v-select
            v-model="erxOperationGraphsSelected"
            :items="erxViewableOperationGraphs"
            label="View Operations Charts"
            multiple
            chips
            height="68px"
            outlined
          >
            <template v-slot:prepend-item>
              <v-list-item
                ripple
                @mousedown.prevent
                @click="erxToggleSelectAll"
              >
                <v-list-item-action>
                  <v-icon :color="erxAllSelected ? 'primary' : ''">
                    {{ erxIcon }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title> Select All </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
            </template>
            <template v-slot:selection="{ item }">
              <v-chip readonly @click="chipClicked(item)" @click.stop>{{
                item
              }}</v-chip>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pr-0">
          <v-tabs-items v-model="tab">
            <v-tab-item v-if="truckData.truck_type?.includes('EX')">
              <EXOperations :truck="truck" :graphs="operationGraphsSelected" />
            </v-tab-item>
            <v-tab-item v-if="truckData.truck_type?.includes('ERX')">
              <TripGraphERX
                :graphs="new Set(erxOperationGraphsSelected)"
                :loading="erxGraphLoading"
                :data="erxGraphData"
              />
            </v-tab-item>
            <!-- <v-tab-item v-if="truckData.truck_type">
              <EndOfLine
                :truck-type="truckData.truck_type"
                :truck="truck"
                :test-modal="testModal"
                :close-test-modal="closeTestModal"
              />
            </v-tab-item> -->
            <v-tab-item v-if="truckData.vin">
              <WorkOrders :vin="truckData.vin" />
            </v-tab-item>
            <v-tab-item
              v-if="truckData.truck_type?.includes('ERX') && truckData.vin"
            >
              <Cases :vin="truckData.vin" />
            </v-tab-item>
            <v-tab-item v-for="template in templates" :key="template.id">
              <Study
                v-if="truckData.truck_type?.includes('ERX')"
                :template="template"
                :truck-date="truckDate"
                :in-view="template === templates[tab - 3]"
                :truck-names="truckNames"
              />
            </v-tab-item>
          </v-tabs-items>
          <!-- <TruckUsage
            v-if="tabItems[tab] === 'Truck Usage' && truckData.id"
            :truck="truckData"
            @refresh="refreshData"
          /> -->
          <!-- <TireData v-else-if="tabItems[tab] === 'Tire Wear'" :truck="truck" /> -->
        </v-col>
      </v-row>
    </v-container>
    <v-navigation-drawer
      v-model="drawer"
      right
      temporary
      fixed
      width="360"
      class="shade"
    >
      <v-container>
        <v-row align="center">
          <v-col align="right" class="mb-2">
            <v-btn icon @click="drawer = false">
              <v-icon light>mdi-close-circle </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-expansion-panels v-model="panel" multiple>
          <v-expansion-panel class="shade">
            <v-expansion-panel-header>Date Selection</v-expansion-panel-header>
            <v-expansion-panel-content>
              <date-input
                label="Start Date"
                :model="studyStart"
                :send-date="(d) => (studyStart = d)"
              ></date-input>
              <date-input
                label="End Date"
                :model="studyEnd"
                :send-date="(d) => (studyEnd = d)"
              ></date-input>
              <br />
              <div style="display: block; margin-left: auto">
                <v-btn
                  style="margin-right: 15px"
                  :disabled="updateDisabled"
                  @click="cancelSelection"
                  >Cancel</v-btn
                >
                <v-btn
                  color="primary"
                  :disabled="updateDisabled"
                  @click="updateTruckDate"
                  >Update</v-btn
                >
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-container>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { permissions } from '@/api/permissions';
import Cases from './Cases';
import WorkOrders from './WorkOrders';
// import EndOfLine from './EndOfLine';
// import AuditLogs from './AuditLogs';
// import TruckUsage from './TruckUsage';
// import TireData from './TireData';
import Study from '../../Study2/Study';
import EXOperations from './EXOperations';
import { getServiceStudies } from '@/api/study';
import { getHealthTrucks } from '@/api/trucks';
import { getTripReport } from '@/api/trips';
import { getTemplates } from '@/api/templates';
import Health from '../health';
import TripGraphERX from '../../Trips/TripGraphERX';
import DateInput from '@/utilities/DateInput.vue';
import _ from 'lodash';

export default {
  name: 'TruckDetails',
  components: {
    // AuditLogs,
    // TruckUsage,
    // TireData,
    // EndOfLine,
    Cases,
    WorkOrders,
    Study,
    EXOperations,
    TripGraphERX,
    DateInput,
  },
  data() {
    return {
      panel: [0],
      truckDate: {
        start: null,
        end: null,
        trucks: null,
      },
      drawer: false,
      truckNames: {},
      templates: null,
      studyStart: Date.now() - 3600000 * 24, // past 24 hours
      studyEnd: Date.now(),
      erxGraphData: null,
      erxGraphLoading: true,
      testModal: false,
      tab: 0,
      truck: null,
      truckData: {},
      tabItems: [
        // 'Truck Usage',
        // 'Tire Wear',
        { id: -1, name: 'Operations' },
        { id: 0, name: 'Work Orders' },
      ],
      operationGraphsSelected: ['Truck Usage'],
      viewableOperationGraphs: ['Truck Usage'],
      erxOperationGraphsSelected: ['Usage'],
      erxViewableOperationGraphs: [
        'Usage',
        'Cruise Control',
        'BMS',
        'Axle',
        'Retarder',
      ],
      studyGraphsSelected: '',
      viewableStudyGraphs: [],
      truckStudies: null,
    };
  },
  computed: {
    ...mapGetters({
      userRoles: 'getUserRoles',
      userPreferences: 'getUserPreferences',
      storeHealthTrucks: 'getHealthTrucks',
    }),
    userCanRunEndOfLineTests() {
      return this.userRoles && this.userRoles.includes(permissions.eolWriter);
    },
    userCanReadEndOfLineTests() {
      return this.userRoles && this.userRoles.includes(permissions.eolReader);
    },
    addCrumb: function () {
      return [
        {
          text: 'Health',
          disabled: false,
          exact: true,
          link: true,
          to: {
            name: 'Health',
            params: {
              paramName: Health,
            },
          },
        },
        {
          text: `${this.truckData?.number || ''}`,
          disabled: true,
          href: `/health/${this.truckData?.number || ''}`,
        },
      ];
    },
    allSelected() {
      return (
        this.operationGraphsSelected.length ===
        this.viewableOperationGraphs.length
      );
    },
    erxAllSelected() {
      return (
        this.erxOperationGraphsSelected.length ===
        this.erxViewableOperationGraphs.length
      );
    },
    icon() {
      if (this.allSelected) return 'mdi-close-box';
      else return 'mdi-checkbox-blank-outline';
    },
    erxIcon() {
      if (this.erxAllSelected) return 'mdi-close-box';
      else return 'mdi-checkbox-blank-outline';
    },
    updateDisabled() {
      return (
        this.truckDate.start === this.studyStart &&
        this.truckDate.end === this.studyEnd
      );
    },
  },
  created() {
    this.truck = parseInt(this.$route.params.id);
    // Removing the End Of Line view for now HIGH-2502
    // if (this.userCanReadEndOfLineTests) {
    //   this.tabItems.push({id: -4, name: 'End Of Line'});
    // }
  },
  async mounted() {
    if (isNaN(this.$route.params.id)) {
      this.$router.push(`/health`); //If the route param is not a number, it will redirect user back to health page
    } else {
      await this.getTruckData();
      this.updateTruckDate();
    }

    this.setupStudyTabs();
    this.getGraphData();
  },
  methods: {
    ...mapActions(['updateSnack']),
    async getTruckData() {
      const vm = this;
      let res;
      try {
        // filter health trucks if already defined in store, otherwise just call for current truck
        if (this.storeHealthTrucks) {
          res = _.cloneDeep(
            this.storeHealthTrucks.filter((t) => t.id === vm.truck)[0]
          );
        } else {
          res = await getHealthTrucks(this.truck);
          res = res.data[0];
        }
        this.truckData = this.formatData(res);
        let { data } = await getServiceStudies(this.truckData.truck_type);
        this.truckStudies = data;
        this.viewableStudyGraphs = data.map((s) => s.name);
      } catch (e) {
        this.updateSnack({
          type: 'error',
          message: 'There was an error retrieving the truck/studies',
        });
      }
    },
    closeTestModal() {
      this.testModal = false;
    },
    formatData(truck) {
      if (!truck) {
        //If the truck has no data, user will be redirected to the health page
        this.$router.push(`/health`);
      }
      truck.fuel_type = truck.fuel_type.toUpperCase();
      truck.address = truck.address ? truck.address : '—';
      truck.position = truck.position
        ? `${truck.position.lat},${truck.position.lng}`
        : '—';
      truck.truck_type?.includes('EX')
        ? (truck.truck_type = 'EX')
        : (truck.truck_type = 'ERX');

      if (truck.truck_type === 'ERX') {
        this.tabItems.push({ id: -3, name: 'Cases' });
      }

      return truck;
    },
    toHealthView() {
      this.$router.push('/health');
    },
    toggleSelectAll() {
      if (this.allSelected) {
        this.operationGraphsSelected = [];
      } else {
        this.operationGraphsSelected = this.viewableOperationGraphs.slice();
      }
    },
    erxToggleSelectAll() {
      if (this.erxAllSelected) {
        this.erxOperationGraphsSelected = [];
      } else {
        this.erxOperationGraphsSelected =
          this.erxViewableOperationGraphs.slice();
      }
    },
    chipClicked(chip) {
      if (chip === 'Truck Usage') chip = 'milesTraveled';
      else if (chip === 'Usage') chip = 'chart1';
      else if (chip === 'Cruise Control') chip = 'chart8';
      else if (chip === 'BMS') chip = 'chart11';
      else if (chip === 'Axle') chip = 'chart14';
      else if (chip === 'Retarder') chip = 'chart17';
      document.getElementById(chip).scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    },
    async getGraphData() {
      const yearAgo = Date.parse(new Date(new Date().getFullYear(), 0, 1));
      if (this.truckData.truck_type.toUpperCase() === 'ERX') {
        this.erxGraphLoading = true;
        const res = await getTripReport(
          0,
          yearAgo,
          Date.now(),
          null,
          [this.truck],
          'ERX',
          true,
          this.userPreferences.timeZonePref.canonical
        );
        this.erxGraphData = res.data;
        this.erxGraphLoading = false;
      }
    },
    async refreshData() {
      // Resets all data back to original format before re-calling the truck data
      this.truckData = {};
      this.truckStudies = null;
      this.viewableStudyGraphs = [];
      //Removing these options from view
      (this.tabItems = [
        // 'Truck Usage',
        // 'Tire Wear',
        { id: -1, name: 'Operations' },
        { id: 0, name: 'Work Orders' },
      ]),
        await this.getTruckData();
    },
    setStudyStart(start) {
      this.studyStart = start;
    },
    setStudyEnd(end) {
      this.studyEnd = end;
    },
    async setupStudyTabs() {
      if (this.truckData.truck_type.includes('ERX')) {
        this.truckNames[this.truckData.id] = this.truckData.number;
        const res = await getTemplates('health');
        this.templates = res.data;
        for (const template of res.data) {
          this.tabItems.push({
            id: template.id,
            name: template.name,
            template: template,
          });
        }
      }
    },
    updateTruckDate() {
      this.truckDate = {
        start: this.studyStart,
        end: this.studyEnd,
        trucks: [this.truckData.id],
      };
    },
    cancelSelection() {
      this.studyStart = this.truckDate.start;
      this.studyEnd = this.truckDate.end;
    },
  },
};
</script>

<style scoped>
.padding {
  padding: 1rem;
}
.secondary-text {
  font-weight: 100;
  opacity: 0.7;
}
.border {
  border-bottom: 1px solid #e0e0e0;
}
</style>

<style>
.v-chip {
  overflow: initial;
}
.v-select__selections {
  overflow: hidden;
  flex-wrap: nowrap;
}
</style>
