<template>
  <div>
    <v-overlay :absolute="absolute" :opacity="opacity" :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <highcharts
      id="allMiles"
      ref="allMilesChart"
      :options="milesChartOptions"
    ></highcharts>
  </div>
</template>

<script>
import _ from 'lodash';
import { getMillionGraph } from '@/api/million.js';

export default {
  name: 'LineChartsVue',
  props: {},
  data() {
    return {
      oneDaySeconds: 86400,
      currentDateTime: 0,
      absolute: true,
      opacity: 0.85,
      overlay: true,
      milesChartOptions: {
        chart: {
          backgroundColor: '#272727',
          type: 'line',
          zoomType: 'x',
          name: '',
          height: 300,
        },
        rangeSelector: {
          selected: 1,
        },
        title: {
          text: 'All ERX Miles Traveled',
          align: 'left',
          style: {
            color: '#FFFFFF',
            fontFamily: 'RawlineRegular',
          },
        },
        tooltip: {
          shared: true,
          backgroundColor: '#363636',
          borderRadius: 8,
          borderColor: '#363636',
          style: {
            color: '#FFFFFF',
          },
        },
        legend: {
          enabled: false,
        },
        xAxis: {
          type: 'datetime',
          title: {
            text: 'Time',
            style: {
              color: '#FFFFFF',
            },
          },
          labels: {
            step: 1,
            style: {
              color: '#FFFFFF',
            },
          },
          gridLineColor: 'red',
          lineColor: 'rgba(255, 255, 255, 0.12)',
        },
        yAxis: [
          {
            labels: {
              format: '{value}',
              style: {
                color: 'white',
              },
            },
            title: {
              text: 'Miles',
            },
            gridLineColor: 'rgba(255, 255, 255, 0.12)',
          },
        ],
        setOptions: {
          width: '100%',
        },
        series: [],
      },
    };
  },
  computed: {},
  async mounted() {
    const vm = this;
    vm.currentDateTime = _.round(new Date().getTime() / 1000, 0);
    vm.allMilesCharts = vm.$refs.allMilesChart.chart;
    await vm.populateSeries();
  },
  methods: {
    async populateSeries() {
      let formattedData = [];
      const res = await getMillionGraph();
      // repeat previous odom reading if day is missing from data
      // convert km to mi
      formattedData.push([
        Date.parse(res[0].timestamp),
        Math.round(res[0].odometer / 1.609),
      ]);
      for (let i = 1; i < res.length; i++) {
        let timestamp = Date.parse(res[i].timestamp);
        let prevTimestamp = Date.parse(res[i - 1].timestamp);
        let prevDay = timestamp - 86400000;
        while (prevTimestamp != prevDay) {
          prevTimestamp += 86400000;
          formattedData.push([
            prevTimestamp,
            Math.round(res[i - 1].odometer / 1.609),
          ]);
        }
        formattedData.push([timestamp, Math.round(res[i].odometer / 1.609)]);
      }
      let mileSettings = {
        name: 'Total Miles',
        color: '#43b02a',
        data: formattedData,
      };
      this.allMilesCharts.addSeries(mileSettings);
      this.overlay = false;
    },
  },
};
</script>

<style scoped></style>
