<template>
  <v-card flat>
    <v-row>
      <v-col cols="3" xs="12" sm="12" md="12" lg="3" xl="3">
        <v-text-field
          v-model="searchText"
          placeholder="Search"
          solo
          prepend-inner-icon="mdi-magnify"
          background-color="shade"
        ></v-text-field>
      </v-col>
    </v-row>
    <loader v-if="loading" />
    <v-data-table
      :headers="dataHeaders"
      :items="dtcRows"
      :items-per-page="5"
      :loading="loading"
      :search="searchText"
      item-key="id"
      class="elevation-1"
    >
      <template v-slot:item.workbook_link="{ item }">
        <a
          v-if="item.workbook_link !== '---'"
          :href="item.workbook_link"
          target="_blank"
          >{{ item.link_title }}</a
        >
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';
import { getdtcErxTable, updateDtcTableLinks } from '@/api/dtc';
import _ from 'lodash';
import loader from '@/utilities/loader';
export default {
  name: 'ERXDtcTable',
  components: { loader },
  props: {},
  data() {
    return {
      dataHeaders: [
        {
          text: 'SPNFMI',
          value: 'spnfmi',
          align: 'center',
          sortable: true,
          visible: true,
          hideable: true,
        },
        {
          text: 'Name',
          value: 'dtc_name',
          align: 'center',
          sortable: true,
          visible: true,
          hideable: true,
        },
        {
          text: 'Description',
          value: 'description',
          align: 'center',
          sortable: true,
          visible: true,
          hideable: true,
        },
        {
          text: 'Component',
          value: 'component',
          align: 'center',
          sortable: true,
          visible: true,
          hideable: true,
        },
        {
          text: 'Link',
          value: 'workbook_link',
          align: 'center',
          sortable: true,
          visible: true,
          hideable: true,
        },
      ],
      searchText: '',
      dtcRows: [],
      loading: false,
    };
  },
  async mounted() {
    await this.populateDtcRows();
  },
  methods: {
    ...mapActions({
      updateSnack: 'updateSnack',
    }),
    addLinksToRows: async function () {
      const vm = this;
      const currentTime = Math.floor(Date.now() / 1000);
      let populateDtcRowsTime = localStorage.getItem('populateDtcRowsTime');
      if (populateDtcRowsTime) {
        populateDtcRowsTime = parseInt(populateDtcRowsTime, 10);
        // three hours in seconds
        if (currentTime - populateDtcRowsTime < 86400) {
          this.updateSnack({
            type: 'warning',
            message: 'ERX DTC Table already populated less than 24 hours ago.',
          });
          return;
        }
      }
      this.updateSnack({
        type: 'info',
        message:
          'ERX DTC Table Population in Progress... Please do not refresh the page.',
      });
      vm.loading = true;
      const res = await updateDtcTableLinks();
      if (res) {
        this.dtcRows = [];
        localStorage.setItem('populateDtcRowsTime', currentTime);
        this.updateSnack({
          type: 'info',
          message: 'ERX DTC Table Population Completed.',
        });
        vm.populateDtcRows();
        vm.loading = false;
      } else {
        this.updateSnack({
          type: 'error',
          message: 'Error updating ERX DTC Table. Please try again later.',
        });
      }
    },
    isNullOrUndefined: function (obj) {
      return obj === null || obj === undefined;
    },
    populateDtcRows: async function () {
      this.loading = true;
      // create try/catch block here to get response from api
      try {
        const res = await getdtcErxTable();
        this.dtcRows = !this.isNullOrUndefined(res) ? res.data : [];
      } catch (error) {
        this.updateSnack({
          type: 'error',
          message: 'Error populating ERX DTC Table: ' + error,
        });
      }
      this.loading = false;
    },
  },
};
</script>
<style scoped></style>
