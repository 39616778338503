var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "checkListTable" } },
    [
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "pa-0", attrs: { cols: "12" } },
                [
                  _vm.loading
                    ? _c("v-skeleton-loader", {
                        attrs: { type: "table", elevation: "2" },
                      })
                    : _c("v-data-table", {
                        key: _vm.tableKey,
                        staticClass:
                          "elevation-1 secondary locked-table-style row-height-50 row-pointer",
                        attrs: {
                          "single-select": "",
                          headers: _vm.planHeaders,
                          items: _vm.planData,
                          search: _vm.searchText,
                          "item-key": "id",
                        },
                        on: { "click:row": _vm.getRowData },
                      }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }