import axios from 'axios';
const BASE_URL = process.env.VUE_APP_API_PATH_BASE;

async function getCompanies(active = true) {
  return await axios.get(BASE_URL + `external/company?active=${active}`);
}

async function getCompanyPreferences(id) {
  return await axios.get(BASE_URL + `external/company/preferences/${id}`);
}

async function getCompanyGeozones(id, include_ss = false) {
  return await axios.get(
    BASE_URL +
      `external/company/geozones?company_id=${id}&include_ss=${include_ss}`
  );
}

async function patchCompanyPreferences(body) {
  return await axios.patch(BASE_URL + `external/company/preferences`, body);
}

export {
  getCompanies,
  getCompanyPreferences,
  getCompanyGeozones,
  patchCompanyPreferences,
};
