<template>
  <div id="issuesTable">
    <v-container fluid>
      <v-row>
        <v-col class="pa-0" cols="12">
          <v-data-table
            :key="tableKey"
            single-select
            :headers="issuesHeaders"
            :items="issues"
            class="elevation-1 secondary locked-table-style row-height-50 row-pointer"
            item-key="id"
            @click:row="loadUpdateIssue"
          ></v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'IssuesTable',
  components: {},
  props: {
    issues: { type: Array, default: null, required: true },
    loadIssueFromTable: { type: Function, required: true },
  },
  data() {
    return {
      actionItems: [],
      drawer: false,
      issuesHeaders: [
        {
          text: 'Issue Number',
          align: 'center',
          sortable: true,
          value: 'issue_number',
        },
        {
          text: 'Plan',
          align: 'center',
          sortable: true,
          value: 'plan_name',
        },
        {
          text: 'Date Found',
          align: 'center',
          sortable: true,
          value: 'found_dt',
        },
        {
          text: 'Date Resolved',
          align: 'center',
          sortable: true,
          value: 'resolved_dt',
        },
        {
          text: 'Vehicle',
          align: 'center',
          sortable: true,
          value: 'vehicle',
        },
        {
          text: 'HW/SW',
          align: 'center',
          sortable: true,
          value: 'hw_sw',
        },
        {
          text: 'Title',
          align: 'center',
          sortable: true,
          value: 'title',
        },
        {
          text: 'Details',
          align: 'center',
          sortable: true,
          value: 'details',
        },
        {
          text: 'Status',
          align: 'center',
          sortable: true,
          value: 'status_conv',
        },
        {
          text: 'Next Steps',
          align: 'center',
          sortable: true,
          value: 'next_steps',
        },
        {
          text: 'Comments',
          align: 'center',
          sortable: true,
          value: 'issue_comments',
        },
        {
          text: 'Found By',
          align: 'center',
          sortable: true,
          value: 'found_by',
        },
        {
          text: 'JIRA Link',
          align: 'center',
          sortable: true,
          value: 'jira_link',
        },
      ],
      issueItems: [],
      selectedID: 0,
      tableKey: 1,
    };
  },
  watch: {},
  mounted() {
    this.tableKey = Math.round(Math.random() * (9000 - 1) + 1);
  },
  methods: {
    loadUpdateIssue: function (rowData) {
      this.loadIssueFromTable(rowData);
    },
  },
};
</script>

<style scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
